import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BlockIcon from "@material-ui/icons/Block";
import ReStoreIcon from "@material-ui/icons/Restore";
import moment from "moment";
import Combobox from "react-widgets/Combobox";
import Drawer from 'react-modern-drawer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Dialog from "react-dialog";
import "../../utils/DeleteBlockcss.css";
import { stringLength } from "../../ENUM/enum";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CompanyRecord(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const {
    companies,
    inputChange,
    saveCompany,
    handleClose,
    handleShow,
    state,
    editCompany,
    deleteCompany,
    blockCompany,
    plzs,
    setPlz,
    getPLZDetail,
    selectedPlz,
    handleSort,
    UserRole,
    handleMoveBack,
    handleMoveNext,
    handlePopUp,
    confirm
  } = props;

  let isLogedin = !!localStorage.getItem("token");
  let getIsUser = localStorage.getItem("isUser");
  let isUser = JSON.parse(getIsUser);
  const [visible, setVisible] = useState(isUser.roleId !== UserRole.User ? true : false);
  return (
    <div className="mt-10">
      <div className="d-flex justify-content-between">
        <div>
          <input
            className="form-control mt-1"
            placeholder={isUser.roleId === UserRole.User ? (i18n.t('Company.SearchCompany')) : (i18n.t("Company.company_search_companies"))}
            id="search"
            style={{ width: "400px" }}
            onChange={(e) => {
              inputChange(e, setVisible)
            }}
            value={state.search}
          />
        </div>
        {isUser.roleId !== UserRole.User && (
          <>
            <Button
              onClick={() => handleShow()}
              className={[classes.button, "Button-bg-Color"]}
              startIcon={<AddIcon />}
            >
              {i18n.t('Company.company_add_company')}
            </Button>
          </>
        )}
      </div>
      <div className="d-flex justify-content-between">
        {
          state.search.length < 3 ?
              <label htmlFor="search" className="text-muted">{i18n.t('Company.MinSearchTxt')}</label> 
          : null
        }
      </div>
      <Drawer open={state.show} size={400} className="drawer" direction='right' enableOverlay={false}>
        <div className="ml-1 d-flex justify-content-between mt-5 divHeight" >
          {state.isEdit === false ?
            <h5 className="mt-4 ml-2" >{i18n.t('Company.CreateCompany')}</h5>
            :
            <h5 className="mt-4 ml-2" >{i18n.t('Company.UpdateCompany')}</h5>
          }

          <FontAwesomeIcon
            icon={faTimes}
            className="mr-3 mt-4 pt-1 awesomeIconSize"
            onClick={() => handleClose()}
          />
        </div>
        <hr className="hrStyle" />
        {
          state.buttonShow === true ? null :
            <div className="row mx-1">
              <div className="col-sm-12 ml-1 d-flex justify-content-between">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className=" mt-0 awesomeIconSizeRight"
                  onClick={() => handleMoveBack(state.indexx)}
                />
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="mt-0 awesomeIconSizeRight"
                  onClick={() => handleMoveNext(state.indexx)}
                />
              </div>
            </div>
        }
        <div className="row mx-1">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control mt-1"
              placeholder={i18n.t('Company.i18n_company_name')}
              id="name"
              onChange={(e) => inputChange(e)}
              value={state.name}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control mt-1"
              placeholder={i18n.t('Company.street&number')}
              id="address"
              onChange={(e) => inputChange(e)}
              value={state.address}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-6">
            <Combobox
              style={{ marginTop: "5px" }}
              hideCaret
              hideEmptyPopup
              data={plzs}
              defaultValue={state.plz}
              value={selectedPlz}
              placeholder={i18n.t('Company.searchZipcode')}
              onSelect={(value) => setPlz(value)}
              onChange={(value) => getPLZDetail(value)}
            />
          </div>
          <div className="col-sm-3 pr-0 pl-0">
            <input
              type="text"
              className="form-control mt-1"
              placeholder={i18n.t('Company.location')}
              id="ort"
              onChange={(e) => inputChange(e)}
              value={state.ort}
              autoComplete="new-password"
              readOnly
            />
          </div>
          <div className="col-sm-3 pl-1">
            <input
              type="text"
              className="form-control mt-1"
              placeholder={i18n.t('Company.i18n_district')}
              id="canton"
              onChange={(e) => inputChange(e)}
              value={state.canton}
              autoComplete="new-password"
              readOnly
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Email Addresse"
              id="email"
              onChange={(e) => inputChange(e)}
              value={state.email}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Email Domain"
              id="emailDomain"
              onChange={(e) => inputChange(e)}
              value={state.emailDomain}
              autoComplete="new-password"
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control mt-1"
              placeholder={i18n.t('Company.i18n_domain')}
              id="branch"
              onChange={(e) => inputChange(e)}
              value={state.branch}
              autoComplete="new-password"
            />
          </div>
        </div>


        <div className="row mx-1">
          <div className="col-sm-12 mt-1 d-flex justify-content-end">
            <Button
              className={[classes.button, "Button-bg-Color"]}
              onClick={() => saveCompany()}
            >
              {i18n.t('Company.save')}
            </Button>
          </div>
        </div>
      </Drawer>
      {
        state.isDialogOpen === true ?
          <Dialog
            modal={true}
            buttons={
              [
                {
                  text: "yes",
                  className: "firstbutton",
                  onClick: () => confirm(state.confirmId, state.confirmIsBlock)
                },
                {
                  text: "Close",
                  onClick: () => handlePopUp()
                },
              ]
            }>

            <div className="TextDiv">
              <p className="textContainer">{'Why do you want to ' + state.message + ' this ' + state.type + ' ?'}</p>
            </div>
            <div>
              <input
                className="form-control mt-1"
                id="deleteBlock"
                onChange={(e) => inputChange(e)}
                value={state.reason}
                placeholder={i18n.t('candidate.reason')}
              />
            </div>
          </Dialog>
          :
          null
      }

      {visible ?
        (<TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              {companies.length !== stringLength.tableViewCond ?
                (<TableRow>
                  <TableCell onClick={() => handleSort("COMPANY_NAME")} className={"sizeOfCels"}>
                    {i18n.t('Company.i18n_company_name')}
                    <FontAwesomeIcon icon={state.columnToSort ==="COMPANY_NAME" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  <TableCell onClick={() => handleSort("BRANCH")}>
                    {i18n.t('Company.i18n_domain')}
                    <FontAwesomeIcon icon={state.columnToSort ==="BRANCH" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  <TableCell onClick={() => handleSort("CANTON")}>
                  {i18n.t('Company.i18n_district')}
                  <FontAwesomeIcon icon={state.columnToSort ==="CANTON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  <TableCell onClick={() => handleSort("EMAIL_ADDRESS")}>
                    {i18n.t('Company.i18n_email')}
                    <FontAwesomeIcon icon={state.columnToSort ==="EMAIL_ADDRESS" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  <TableCell onClick={() => handleSort("CREATED_ON")}>
                    {i18n.t('Company.i18n_created_at')}
                    <FontAwesomeIcon icon={state.columnToSort ==="CREATED_ON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  <TableCell onClick={() => handleSort("UPDATED_ON")}>
                    {i18n.t('Company.i18n_updated_on')}
                    <FontAwesomeIcon icon={state.columnToSort ==="UPDATED_ON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                  </TableCell>
                  {!isLogedin && <TableCell>{i18n.t('Company.Status')}</TableCell>}
                  {isUser.roleId !== UserRole.User && <TableCell>{i18n.t('Company.i18n_action')}</TableCell>}
                </TableRow>) : null}
            </TableHead>
            <TableBody>
              {companies
                ? companies.map((row) => (
                  <TableRow
                    key={row.id}
                    className={`${row.isBlocked ? "blockedrow" : ""}`}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.branch}</TableCell>
                    <TableCell>{row.canton}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {moment(row.createdOn).format("yyyy-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {moment(row.updatedOn).format("yyyy-MM-DD")}
                    </TableCell>
                    {!isLogedin && (
                      <TableCell>
                        {row.isBlocked ? "blockiert" : "aktiv"}
                      </TableCell>
                    )}
                    {isUser.roleId !== UserRole.User && (
                      <TableCell>
                        <Button
                          title={"Edit " + row.name}
                          className={[classes.button, "Button-edit-bg-Color"]}
                          onClick={() => editCompany(row)}
                        >
                          <EditIcon />
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          title={
                            `${row.isBlocked ? "Unblock" : "Block"}` +
                            " " +
                            row.name
                          }
                          className={row.isBlocked ? [classes.button, "Button-bg-Color"] : [classes.button, "Button-delete-bg-Color"]}

                          onClick={() => blockCompany(row.id, !row.isBlocked)}
                        >
                          {row.isBlocked ? <ReStoreIcon /> : <BlockIcon />}
                        </Button>
                        {isUser.roleId !== UserRole.User && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                              title={"Delete " + row.name}
                              className={[classes.button, "Button-delete-bg-Color"]} onClick={() => deleteCompany(row.id)}
                            >
                              <DeleteForeverIcon />
                            </Button>
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))
                : []}
            </TableBody>
          </Table>
        </TableContainer>) : <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} className="mt-2">{state.search.length >= 3 ? "No companies found" : " "}</div>}
    </div>
  )
}
