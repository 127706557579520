export const roleStatus = {
    Admin: 1,
    Maanager: 2,
    User: 3
};

export const stringLength = {
    searchedTerm: 3,
    tableViewCond: 0,
    minLength: 49
}