import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import { roleStatus } from "../../ENUM/enum";
const user_id =JSON.parse(localStorage.getItem("isUser"))

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const options = [
  { value: 'ger', label: 'German' },
  { value: 'en', label: 'English' },
];
export default function BackToTop(props) {
  const { logOut, goto, tokenInput } = props;
  let isLogedin = !!localStorage.getItem("token");
  let getIsUser = localStorage.getItem("isUser");
  let pathName = localStorage.getItem("pathName");
  let isUser = JSON.parse(getIsUser)
  const [currentLanguage, setLanguage] = useState(localStorage.getItem('Language') !== null ? JSON.parse(localStorage.getItem("Language")):{ value: 'en', label: 'English' });
  const [isActive, setActive] = useState(pathName);
  const { t, i18n } = useTranslation();
  const changeLanguage = (item) => {
    let url = baseURL + "/api/users/settings/" + user_id.id
    i18n.changeLanguage(item.value)
      .then(() => {
        setLanguage(item)
        localStorage.setItem("Language" , JSON.stringify(item))
        let payload = {
          language: item.value
        }
        Axios.post(url, payload, httpConfig )
       })
      .catch(err => console.log(err));
  };
  const handleHover = (item) => {
    setActive(item.route)
  }
  const items = [
    {
      route: "/company",
      profile: "Profile.Companies"
    },
    {
      route: "/candidate",
      profile: "Profile.Candidate"
    },
    {
      route: "/user",
      profile: "Profile.menu_user"
    },
    {
      route: "/my-leads",
      profile: "Profile.myLeads"
    },
  ]

  const Useritems = [
    {
      route: "/company",
      profile: "Profile.Companies"
    },
    {
      route: "/candidate",
      profile: "Profile.Candidate"
    },

    {
      route: "/my-leads",
      profile: "Profile.myLeads"
    },
  ]
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar className="Button-bg-Color a:hover header-fonts-colors">
        <Toolbar className="d-flex">
          <Typography variant="h5">{t("Profile.DataIntelligence")} </Typography>
          {isUser.roleId === roleStatus.User ?
            <React.Fragment>
              {
                Useritems.map((item) => (
                  <label
                    onClick={() => {
                      goto(item.route);
                      handleHover(item);
                    }
                    }
                    className={item.route === isActive ?
                      "cursor-pointer  Active-State ml-3 mt-2" :
                      "cursor-pointer  Background-color header-fonts-colors ml-3 mt-2"
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {t(item.profile)}
                  </label>
                ))}
            </React.Fragment>
            :
            <React.Fragment>
              {
                items.map((item, idx) => (
                  <label
                    onClick={() => {
                      goto(item.route);
                      handleHover(item);
                    }
                    }
                    className={item.route === isActive ?
                      "cursor-pointer  Active-State ml-3 mt-2" :
                      "cursor-pointer  Background-color header-fonts-colors ml-3 mt-2"
                    }
                    style={{ cursor: "pointer" }}
                  >

                    {t(item.profile)}
                  </label>
                ))}
            </React.Fragment>}

          {!isLogedin ? (
            <label
              onClick={() => goto("/login")}
              className="cursor-pointer ml-auto mt-2"
              style={{ cursor: "pointer" }}
            >
              Login
            </label>
          ) : (
            <div className="cursor-pointer ml-auto row">
              <div className="col-sm-12 d-flex  justify-content-between divPlace" >
                <FontAwesomeIcon
                  title="TokenIput"
                  icon={faShieldAlt}
                  className="mt-1 mr-2 text-white awesomeIconSize"
                  onClick={() => tokenInput()}
                />
                <Select
                  placeholder="Select Language"
                  className="fs-6  mr-1"
                  value={currentLanguage}
                  onChange={changeLanguage}
                  options={options}
                  size={'small'}
                />
                <FontAwesomeIcon
                  title="Logout"
                  icon={faSignOutAlt}
                  className="mt-1 text-white awesomeIconSize"
                  onClick={() => logOut()}
                />
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}