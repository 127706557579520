import React, { Component } from "react";
import { showLoading, hideLoading } from "../../../src/utils/Loaders";
import CompanyRecord from "../Component/CompanyRecord";
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import { showSnakerAlert } from "../../utils/Alerts";
import _ from "lodash";
import Pagination from "react-bootstrap/Pagination";
import { SwissPlz } from "../../utils/SwissData";
import { withTranslation } from 'react-i18next';
import { roleStatus, stringLength } from "../../ENUM/enum"

const invertDirection = {
  ASC: "DESC",
  DESC: "ASC",
};

class Company extends Component {

  state = {
    companies: [],
    count: 0,
    activePage: 1,
    activePageSize: 50,
    id: 0,
    name: "",
    branch: "",
    canton: "",
    email: "",
    emailDomain: "",
    firm: "",
    address: "",
    plz: "",
    ort: "",
    isUpdate: false,
    show: false,
    search: "",
    plzs: [],
    selectedPlz: "",
    searchedTerm: "",
    columnToSort: "",
    sortDirection: "ASC",
    plzsData: [],
    buttonShow: true,
    indexx: 0,
    countIndex: 0,
    pag: true,
    isEdit: false,
    isDialogOpen: false,
    message: "",
    type: "",
    reason: "",
    confirmIsBlock: false,
    confirmId: null,
    currentUserType: null,
    sortBy:"",
    type:""

  };
  fromPag = (e) => {
    if (e === false) {
      this.setState({ show: false, pag: true });
    }
  }

  inputChange = (event, callback = (e) => { }) => {
    const { id, value } = event.target;

    if (id === "search" && (value.length >= 3 || value.length === 0)) {
      this.getAllCompanies(
        value,
        this.state.activePage,
        this.state.activePageSize,
        this.state.sortBy,
        this.state.type,
        callback
      );
      this.setState({ searchedTerm: value });
      this.handleClose()
    }
    if (id === "deleteBlock") {
      this.setState({ reason: value });
    }

    if (id === "email") {
      let domain = value.split("@")[1];
      this.setState({ emailDomain: domain });
    }

    this.setState({ [id]: value });
  };

  setPlz = (value) => {
    if (value !== "") {
      let record = value.split("-");
      let plz = record[0];
      let canton = record[1];
      let ort = record[2];

      this.setState({ plz, canton, ort, selectedPlz: plz });
    }
  };

  saveCompany = () => {
    const { id, name, branch, canton, email, emailDomain, address, plz, ort } =
      this.state;

    let payload = {
      name,
      branch,
      canton,
      email,
      emailDomain,
      address,
      plz: parseInt(plz),
      ort,
      firm: name,
    };

    showLoading();
    if (this.state.isUpdate === true) {
      let url = baseURL + "/api/company/update/" + id;
      Axios.patch(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.setState({ show: false });
            this.getAllCompanies(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type,
            );
            this.resetField();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
        });
    } else {
      let url = baseURL + "/api/company/create";
      Axios.put(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.setState({ show: false });
            this.getAllCompanies(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type,
            );
            this.resetField();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  resetField = () => {
    this.setState({
      id: 0,
      name: "",
      branch: "",
      canton: "",
      email: "",
      emailDomain: "",
      firm: "",
      address: "",
      plz: 0,
      ort: "",
      isUpdate: false,
    });
  };
  handleMoveBack = (index) => {
    this.setState({ pag: false })
    if (index === 1) {
      const { activePage, searchedTerm, activePageSize , sortBy , type} = this.state;
      if (activePage !== 1) {
        this.getAllCompanies(
          searchedTerm,
          activePage - 1,
          activePageSize,
          sortBy,
          type,
        )
      }
    } else {
      this.state.companies.forEach(elementToSend => {
        if (elementToSend.index === index - 1) {
          this.editCompany(elementToSend)
        }
      });
    }

  }
  handleMoveNext = (index) => {
    this.setState({ pag: false })
    if (this.state.companies.length === index) {
      const { activePage, searchedTerm, activePageSize, sortBy, type} = this.state;
      if (this.state.companies.length >= activePageSize - 1) {
        this.getAllCompanies(searchedTerm, activePage + 1, activePageSize , sortBy , type)
      }
    } else {
      this.state.companies.forEach(elementToSend => {
        if (elementToSend.index === index + 1) {
          this.editCompany(elementToSend)
        }
      });
    }
  }
  editCompany = (company) => {
    let {
      id,
      name,
      branch,
      canton,
      email,
      emailDomain,
      address,
      plz,
      ort,
      firm,
      isUpdate,
      selectedPlz,
      indexx,
      isEdit
    } = this.state;

    // Mapping
    id = company.id;
    name = company.name;
    branch = company.branch;
    email = company.email;
    emailDomain = company.emailDomain;
    canton = company.canton;
    plz = company.plz;
    ort = company.ort;
    firm = company.firm;
    selectedPlz = plz;
    address = company.address;
    isUpdate = true;
    indexx = company.index;
    isEdit = true
    this.setState({
      id,
      name,
      branch,
      canton,
      email,
      emailDomain,
      address,
      plz,
      ort,
      firm,
      selectedPlz,
      isUpdate,
      show: true,
      buttonShow: false,
      indexx,
      next: true,
      isEdit
    });
  };

  confirmDeleteCompany = (id) => {
    if (this.state.reason !== "") {
      const { reason } =
        this.state;

        let payload = {
          ...httpConfig,
          data: {reason},
        };
        
      showLoading();
      const url = baseURL + "/api/company/delete/" + id;
      Axios.delete(url, payload)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.getAllCompanies(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type
            );
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
          this.handlePopUp()
        });
    } else {
      showSnakerAlert(false, "Please enter the reason");
    }
  };
  deleteCompany = (id) => {
    this.setState({ isDialogOpen: true, message: "delete", type: "company", confirmId: id });
  };

  handlePopUp = () => {
    this.setState({ reason: "", isDialogOpen: false, message: "" });
  }
  blockCompany = (id, isBlocked) => {
    var getMessage = ""
    if (isBlocked === true) {
      getMessage = "block"
    } else {
      getMessage = "unblock"
    }
    this.setState({ isDialogOpen: true, message: getMessage, type: "company", confirmId: id, confirmIsBlock: isBlocked });
  }
  confirm = (id, isBlocked) => {
    if (this.state.message === "delete") {
      this.confirmDeleteCompany(id)
    }
    if (this.state.message === "block" || this.state.message === "unblock") {
      this.confirmBlockCompany(id, isBlocked)
    }

  };

  confirmBlockCompany = (id, isBlocked) => {
    if (this.state.reason !== "") {
      const { reason } =
        this.state;

      let payload = {
        reason
      };
      showLoading();
      const url =
        baseURL +
        "/api/company/changeActiveStatus/" +
        id +
        `?isBlocked=${isBlocked}`;

      Axios.patch(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.getAllCompanies(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
          this.state.type
            );
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
          this.handlePopUp()
        });
    } else {
      showSnakerAlert(false, "Please enter the reason");
    }
  }
  componentDidMount() {
    let isLogedin = !!localStorage.getItem("token");
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser);
    if (!isLogedin) {
      localStorage.clear();
      window.location.href = "/";
    }
    this.setState({ plzsData: SwissPlz, currentUserType: isUser.roleId });
    this.getAllCompanies(
      this.state.searchedTerm,
      this.state.activePage,
      this.state.activePageSize,
      this.state.sortBy,
      this.state.type
    );
  }

  getAllCompanies = (term = "", pageNo = 1, pageSize = 50, sortBy, type, callback = (e) => { }) => {
    this.setState({ activePage: pageNo, activePageSize: pageSize, sortBy : sortBy, type: type});
    showLoading();
    Axios.get(
      baseURL +
      "/api/company/all?term=" +
      term +
      "&pageNo=" +
      pageNo +
      "&pageSize=" +
      pageSize+
      "&sortBy=" +
      sortBy +
      "&type="+
      type,
      httpConfig
    )
      .then((res) => {

        this.state.countIndex = 0
        res.data.companies.forEach(element => {
          this.state.countIndex = this.state.countIndex + 1
          element["index"] = this.state.countIndex
        });
        const { companies, count } = res.data;
        if (companies?.length > 0 && (term.length >= 3 || (term.length === 0 && this.state.currentUserType !== roleStatus.User))) callback(true)
        else callback(false);
        this.setState({ companies, count });
        if (this.state.pag === false) {
          if (this.state.indexx === this.state.countIndex) {
            this.state.indexx = 0
            this.handleMoveNext(this.state.indexx)
          } else if (this.state.indexx === 1) {
            this.state.indexx = this.state.countIndex + 1
            this.handleMoveBack(this.state.indexx)
          }

        }
      })
      .finally(hideLoading());
  };

  getPLZDetail = (plz) => {
    const { plzsData } = this.state;
    if (plz.indexOf("-") < 0) {
      this.setState({ selectedPlz: plz });
    }
    if (plz !== 0) {
      const data = plzsData.filter((d) => String(d.npa).includes(String(plz)));
      const plzs = [];
      for (let item of data) {
        plzs.push(`${item.npa}-${item.canton}-${item.location}`);
      }

      this.setState({ plzs });
    }
  };

  handleClose = () => {
    this.setState({ show: false, isUpdate: false, pag: true, isEdit: false });
    this.resetField();
  };

  handleShow = () => this.setState({ show: true, buttonShow: true });

  handleSort = (columnName) => {
    let sortDirection =
      this.state.columnToSort === columnName
        ? invertDirection[this.state.sortDirection]
        : "ASC";

    // const companies = _.orderBy(
    //   this.state.companies,
    //   [`${columnName}`],
    //   [`${sortDirection}`]
    // );
    this.setState({ columnToSort: columnName, sortDirection, sortBy: columnName, type: sortDirection });
    this.getAllCompanies("", this.state.activePage,  this.state.activePageSize, columnName, sortDirection);
  };

  pagination = (c, m) => {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  onPageSizeChange = (e) => {
    const activePageSize = e.target.value;
    const { searchedTerm , sortBy , type} = this.state;
    this.getAllCompanies(searchedTerm, 1, activePageSize, sortBy, type);
    this.setState({ activePageSize, activePage: 1 });
  };

  render() {
    const { activePage, searchedTerm, count, activePageSize, sortBy, type} = this.state;
    const pages = Math.ceil(count / this.state.activePageSize);
    const paginationArray = this.pagination(activePage, pages);
    let items = [];
    let countj = 0;

    for (const number of paginationArray) {
      countj++;
      if (typeof number === "number") {
        items.push(
          <Pagination.Item
            key={number}
            className="Button-bg-Color"
            active={number === activePage}
            onClick={() =>
              this.getAllCompanies(searchedTerm, number, activePageSize, sortBy, type)
            }
          >
            {number}
          </Pagination.Item>
        );
      } else {
        items.push(<Pagination.Ellipsis key={"Ellipsis" + countj} />);
      }
    }

    return (
      <div>
        <CompanyRecord
          handleSort={this.handleSort}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
          handleMoveBack={this.handleMoveBack}
          handleMoveNext={this.handleMoveNext}
          state={this.state}
          companies={this.state.companies}
          inputChange={this.inputChange}
          saveCompany={this.saveCompany}
          editCompany={this.editCompany}
          deleteCompany={this.deleteCompany}
          blockCompany={this.blockCompany}
          plzs={this.state.plzs}
          setPlz={this.setPlz}
          getPLZDetail={this.getPLZDetail}
          selectedPlz={this.state.selectedPlz}
          UserRole={roleStatus}
          handlePopUp={this.handlePopUp}
          confirm={this.confirm}
        />
        <br />
        <div className="d-flex justify-content-between">
          {this.state.currentUserType !== roleStatus.User && this.state.companies.length > stringLength.minLength ?
            <> <Pagination>
              {activePage !== 1 && (
                <>
                  <Pagination.First
                    onClick={() => {
                      this.fromPag(false);

                      this.getAllCompanies(searchedTerm, 1, activePageSize, sortBy, type)
                    }}

                  />
                  <Pagination.Prev
                    onClick={() => {
                      this.fromPag(false);
                      this.getAllCompanies(
                        searchedTerm,
                        activePage - 1,
                        activePageSize,
                        sortBy,
                        type
                      )
                    }}
                  />
                </>
              )}
              {items}
              {activePage !== pages && (
                <>
                  <Pagination.Next
                    onClick={() => {
                      this.fromPag(false)
                      this.getAllCompanies(
                        searchedTerm,
                        activePage + 1,
                        activePageSize,
                        sortBy,
                        type
                      )
                    }}
                  />
                  <Pagination.Last
                    onClick={() => {
                      this.fromPag(false);
                      this.getAllCompanies(searchedTerm, pages, activePageSize, sortBy, type)
                    }}
                  />
                </>
              )}
            </Pagination>
              <select
                className="form-control"
                style={{ width: "100px" }}
                onChange={(e) => { this.fromPag(false); this.onPageSizeChange(e) }}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select></> : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Company);
