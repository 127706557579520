import React from "react";
import "./SpinnerLoader.css";

export const LoadingSpinner = () => (
  <div
    id="full-screen-spinner-loader"
    style={{ display: "none", zIndex: "10000" }}
    className="fullScreenLoading"
  >
    Loading&#8230;
  </div>
);

export const showLoading = () => {
  const elem = document.getElementById("full-screen-spinner-loader");
  if (elem) {
    elem.style.display = "block";
  }
};

export const hideLoading = () => {
  const elem = document.getElementById("full-screen-spinner-loader");
  if (elem) {
    elem.style.display = "none";
  }
};
