import React, { Component } from "react";
import { showLoading, hideLoading } from "../../../src/utils/Loaders";
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import _ from "lodash";
import "react-modern-drawer/dist/index.css";
import { withTranslation } from "react-i18next";
import { showSnakerAlert } from "../../utils/Alerts";
import { roleStatus } from "../../ENUM/enum";
import MyLeadRecord from "../Component/MyLeadRecord";
import { SwissPlz } from "../../utils/SwissData";

const invertDirection = {
  ASC: "DESC",
  DESC: "ASC",
};

class MyLeads extends Component {
  state = {
    count: 0,
    loaded: false,
    datePickerRef: "",
    selectedDay: "",
    setdate: " ",
    openDrawer: false,
    withdatePicer: false,
    intrestField: "",
    cellData: "",
    notIntrest: "",
    intrest: "",
    headerData: "",
    myLeads: [],
    columnToSort: "",
    sortDirection: "ASC",
    show: false,
    countIndex: 0,
    plzs: [],
    selectedPlz: "",
    indexx: 0,
    disable: true,
    searchedTerm: "",
    search: "",
    activePage: 1,
    activePageSize: 50,
    message: "",
    type: "",
    reason: "",
    confirmId: null,
    defaultDate: "",
    rowData : [],
    sortBy:"",
    type:""
  };
  getAllAssignedCandidates = (term = "", pageNo = 1, pageSize = 50, sortBy, type) => {
    this.setState({ activePage: pageNo, activePageSize: pageSize, sortBy : sortBy, type: type});
    showLoading();
    Axios.get(
      baseURL +
        "/api/lead?" +
        "term=" +
        term +
        "&pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize + 
        "&sortBy=" +
        sortBy +
        "&type="+
        type,
      httpConfig
    )
      .then((res) => {
        if (res.data.success === false) {
          this.setState({ loaded: false });
        } else {
          // const { myLeads, count  } =
          this.state.countIndex = 0;
          res.data.candidates.forEach((element) => {
            this.state.countIndex = this.state.countIndex + 1;
            element["index"] = this.state.countIndex;
          });
          this.setState({ myLeads: res.data.candidates });
        }
      })
      .catch((err) => {
        showSnakerAlert(false, "Internal Server Error");
      })
      .finally(hideLoading());
  };

  componentDidMount() {
    let isLogedin = !!localStorage.getItem("token");
    if (!isLogedin) {
      localStorage.clear();
      window.location.href = "/";
    }
    this.setState({ plzsData: SwissPlz });
    this.getAllAssignedCandidates(
      this.state.searchedTerm,
      this.state.activePage,
      this.state.activePageSize,
      this.state.sortBy,
      this.state.type
    );
    const today = new Date();
    const defaultDate = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
    const formattedDate = `${defaultDate.getDate()}/${
      defaultDate.getMonth() + 1
    }/${defaultDate.getFullYear()}`;
    this.setState({ selectedDay: formattedDate, defaultDate: formattedDate });
  }
  toggleDrawer = (opration, rowData) => {
    if (opration === "1") {
      this.setState({
        openDrawer: true,
        withdatePicer: false,
        intrestField: opration,
        headerData: "intrest",
        rowData : rowData
      });
    } else if (opration === "2") {
      if (rowData.hasOwnProperty("defaultDate") === false) {
        if (rowData.defaultDate === null|| rowData.defaultDate === undefined) {
          rowData["defaultDate"] = this.state.defaultDate;
          this.setState({rowData : rowData , selectedDay : this.state.defaultDate})
          console.log(this.state.myLeads)
        }
      }else{
          this.state.selectedDay = rowData.defaultDate 
          this.setState({rowData : rowData })
          console.log(this.state.rowData)
         }
      this.setState({
        openDrawer: true,
        withdatePicer: true,
        intrestField: opration,
        headerData: "recall",
      });
    } else {
      this.setState({
        openDrawer: true,
        withdatePicer: false,
        intrestField: opration,
        headerData: "notintrest",
        rowData : rowData
      });
    }
  };

  handleSort = (columnName) => {
    let sortDirection =
      this.state.columnToSort === columnName
        ? invertDirection[this.state.sortDirection]
        : "ASC";

    // const myLeads = _.orderBy(
    //   this.state.myLeads,
    //   [`${columnName}`],
    //   [`${sortDirection}`]
    // );

    this.setState({ columnToSort: columnName, sortDirection, sortBy:columnName, type:sortDirection });
    this.getAllAssignedCandidates("", this.state.activePage,  this.state.activePageSize, columnName, sortDirection);

  };
  submitForm = (item ) => {
    if (item === "2") {
      let url = baseURL + `/api/lead/recall/${this.state.rowData.id}?recalledAt=${this.state.setdate}`
      showLoading();
      Axios.post(url, null, httpConfig )
      .then((res) => {
        if (res.data.success === true) {
          this.state.rowData.defaultDate = this.state.selectedDay
          this.setState({
            datePickerRef: this.state.selectedDay,
            withdatePicer: false,
            openDrawer: false,
            intrestField: "",
            selectedDay : this.state.defaultDate
           });
          this.getAllAssignedCandidates(
            this.state.searchedTerm,
            this.state.activePage,
            this.state.activePageSize,
            this.state.sortBy,
            this.state.type
          );
        }
      })
      .catch((err) =>{
        showSnakerAlert(false, "Internal Server Error");
      })
      .finally(hideLoading())
    } else {
      if (this.state.intrestField === "1") {
        let url = baseURL + `/api/lead/changeInterestStatus/${this.state.rowData.id}?isInterested=${true}`
        showLoading();
        Axios.patch(url, null, httpConfig )
        .then((res) => {
          if (res.data.success === true) {
          this.setState({
            intrest: this.state.cellData,
            datePickerRef: this.state.selectedDay,
            withdatePicer: false,
            openDrawer: false,
            intrestField: "",
          });
          this.getAllAssignedCandidates(
            this.state.searchedTerm,
            this.state.activePage,
            this.state.activePageSize,
            this.state.sortBy,
            this.state.type
          );}
        })
        .catch((err) =>{
          showSnakerAlert(false, "Internal Server Error");
        })
      .finally(hideLoading())
      } else {
        let url = baseURL + `/api/lead/changeInterestStatus/${this.state.rowData.id}?isInterested=${false}`
        showLoading();
        Axios.patch(url, null, httpConfig )
        .then((res) => {
          if (res.data.success === true) {
          this.setState({
            notIntrest: this.state.cellData,
            datePickerRef: this.state.selectedDay,
            withdatePicer: false,
            openDrawer: false,
            intrestField: "",
          });
          this.getAllAssignedCandidates(
            this.state.searchedTerm,
            this.state.activePage,
            this.state.activePageSize,
            this.state.sortBy,
            this.state.type
          );}
        })
        .catch((err) =>{
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(hideLoading())
      }
      this.setState({});
    }
  };
  setDate = (e) => {
    let SelectedDate = e.day + "/" + e.month + "/" + e.year;
    let apiDate = new Date()
    let apiInputDate = e.year + "-" + (e.month < 10 ? "0" + e.month : e.month ) + "-" + e.day + apiDate.toJSON().slice(10)
    this.setState({ setdate: apiInputDate, selectedDay: SelectedDate });
  };
  goBack = (actionType) => {
    if (actionType === "2") {
      let date = this.state.defaultDate;
      this.setState({
        openDrawer: false,
        withdatePicer: false,
        intrestField: "",
        show: false,
        selectedDay: date,
      });
    } else {
      this.setState({
        openDrawer: false,
        withdatePicer: false,
        intrestField: "",
        show: false,
      });
    }
  };
  renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="Select Date"
      type="text"
      className="form-control mx-2"
      value={this.state.selectedDay}
      style={{
        width: "285px",
      }}
    />
  );
  seeAssignee = (candidate) => {
    let {
      cand_address,
      cand_canton,
      cand_city,
      cand_description,
      cand_drivinglicence,
      cand_firstname,
      cand_gender,
      cand_lastname,
      cand_mobile,
      cand_nationality,
      cand_zipcode,
      date_since,
      id,
      industry,
      is_deleted,
      job_avamcode,
      job_avamlabel,
      job_lastjob,
      job_wanted,
      isUpdate,
      selectedPlz,
      indexx,
    } = this.state;

    // Mapping
    cand_firstname = candidate.cand_firstname;
    cand_lastname = candidate.cand_lastname;
    cand_address = candidate.cand_address;
    selectedPlz = candidate.cand_zipcode;
    cand_canton = candidate.cand_canton;
    cand_description = candidate.cand_description;
    job_avamcode = candidate.job_avamcode;
    cand_city = candidate.cand_city;
    cand_drivinglicence = candidate.cand_drivinglicence;
    cand_gender = candidate.cand_gender;
    cand_mobile = candidate.cand_mobile;
    cand_nationality = candidate.cand_nationality;
    date_since = candidate.date_since;
    id = candidate.id;
    industry = candidate.industry;
    is_deleted = candidate.is_deleted;
    job_avamlabel = candidate.job_avamlabel;
    job_lastjob = candidate.job_lastjob;
    job_wanted = candidate.job_wanted;
    isUpdate = true;
    indexx = candidate.index;

    this.setState({
      id,
      cand_address,
      cand_canton,
      cand_city,
      cand_description,
      cand_drivinglicence,
      cand_firstname,
      cand_gender,
      cand_lastname,
      cand_mobile,
      cand_nationality,
      cand_zipcode,
      date_since,
      industry,
      is_deleted,
      job_avamcode,
      job_avamlabel,
      job_lastjob,
      job_wanted,
      selectedPlz,
      isUpdate,
      show: true,
      next: true,
      indexx,
    });
  };

  handleMoveBack = (index) => {
    if ((index) => 1) {
      this.state.myLeads.forEach((elementToSend) => {
        if (elementToSend.index === index - 1) {
          this.seeAssignee(elementToSend);
        }
      });
    }
  };
  handleMoveNext = (index) => {
    if (index <= this.state.myLeads.length) {
      this.state.myLeads.forEach((elementToSend) => {
        if (elementToSend.index === index + 1) {
          this.seeAssignee(elementToSend);
        }
      });
    }
  };
  inputChange = (event) => {
    const { id, value } = event.target;

    if (id === "search") {
      this.getAllAssignedCandidates(
        value,
        this.state.activePage,
        this.state.activePageSize,
        this.state.sortBy,
        this.state.type
      );
      this.setState({ searchedTerm: value });
      this.setState({ [id]: value });
    }
    if (id === "deleteBlock") {
      this.setState({ reason: value });
    }
  };
  handlePopUp = () => {
    this.setState({ reason: "", isDialogOpen: false, message: "" });
  };
  confirm = (id) => {
    this.confirmDeleteLeads(id);
  };
  deleteAssignee = (id) => {
    this.setState({
      isDialogOpen: true,
      message: "delete",
      type: "my leads",
      confirmId: id,
    });
  };
  confirmDeleteLeads = (id) => {
    if (this.state.reason !== "") {
      const { reason } = this.state;

      let payload = {
        ...httpConfig,
        data: {reason},
      };
      showLoading();
      const url = baseURL + "/api/lead/" + id;
      Axios.delete(url,payload )
        .then((res) => {
          const { data } = res;
          if (data.success) {
            showSnakerAlert(data.success, data.message);
            this.getAllAssignedCandidates(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type
            );
          }
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
          this.handlePopUp();
        });
    } else {
      showSnakerAlert(false, "Please enter the reason");
    }
  };
  render() {
    return (
      <div>
        <MyLeadRecord
          state={this.state}
          myLeads={this.state.myLeads}
          goBack={this.goBack}
          UserRole={roleStatus}
          toggleDrawer={this.toggleDrawer}
          setDate={this.setDate}
          renderCustomInput={this.renderCustomInput}
          submitForm={this.submitForm}
          handleSort={this.handleSort}
          deleteAssignee={this.deleteAssignee}
          seeAssignee={this.seeAssignee}
          handleMoveBack={this.handleMoveBack}
          handleMoveNext={this.handleMoveNext}
          plzs={this.state.plzs}
          selectedPlz={this.state.selectedPlz}
          inputChange={this.inputChange}
          handlePopUp={this.handlePopUp}
          confirm={this.confirm}
        />
      </div>
    );
  }
}

export default withTranslation()(MyLeads);
