import React, { Component } from "react";
import { showLoading, hideLoading } from "../../../src/utils/Loaders";
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import { showSnakerAlert } from "../../utils/Alerts";
import UserRecord from "../Component/UserRecord";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Drawer from 'react-modern-drawer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import { roleStatus } from "../../ENUM/enum";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Dialog from "react-dialog";
import "../../utils/DeleteBlockcss.css";

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      id: 0,
      username: "",
      email: "",
      name: "",
      password: "",
      file: [],
      show: false,
      isUpdate: false,
      isReadOnly: false,
      search: "",
      roleId: "",
      countIndex: 0,
      indexx: 0,
      isEdit: false,
      message: "",
      type: "",
      reason: "",
      confirmId: null
    };
  }

  inputChange = (event) => {
    const { id, value } = event.target;
    if (id === "search") {
      this.getAllUsers(value);
    }
    if ((id === "roleId")) {
      this.setState({ [id]: parseFloat(value) });
    } else if (id === "deleteBlock") {
      this.setState({ reason: value });
    } else {
      this.setState({ [id]: value });
    }

  };

  inputFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    this.setState({ file });
  };
  checkChanged = (e) => {

    debugger;
    let isReadOnly = e.target.checked;
    this.setState({ isReadOnly });
  };
  saveUser = () => {
    const { id, username, password, name, email, phone, isReadOnly, file } = this.state;
    var { roleId } = this.state
    debugger;
    if (roleId === "") {
      roleId = roleStatus.User
    }
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("IsReadOnly", isReadOnly);
    formData.append("RoleId", roleId);

    if (!this.state.isUpdate && file.length !== 0)
      formData.append("ProfileImage", file, file.name);

    showLoading();
    if (this.state.isUpdate === true) {
      let url = baseURL + "/api/users/" + id;
      Axios.patch(url, formData, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.setState({ show: false });
            this.getAllUsers();
            this.resetField();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
        });
    } else {
      let url = baseURL + "/api/users/";
      Axios.put(url, formData, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.setState({ show: false });
            this.getAllUsers();
            this.resetField();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
        });
    }

  };

  resetField = () => {
    this.setState({
      id: 0,
      username: "",
      email: "",
      name: "",
      password: "",
      file: [],
      isUpdate: false,
      isReadOnly: false,
      phone: "",
      roleId: "",
    });
  };

  editUser = (user) => {
    let { id, username, password, name, email, phone, isReadOnly, isUpdate, roleId, indexx, isEdit } = this.state;

    // Mapping
    id = user.id;
    username = user.username;
    password = user.password;
    name = user.name;
    email = user.email;
    phone = user.phone;
    isReadOnly = user.isReadOnly;
    isUpdate = true;
    roleId = user.roleId;
    indexx = user.index;
    isEdit = true
    this.setState({
      id,
      username,
      password,
      name,
      email,
      isUpdate,
      phone,
      isReadOnly,
      show: true,
      roleId,
      indexx,
      isEdit
    });
  };

  confirmDeleteUser = (id) => {
    if (this.state.reason !== "") {
      const { reason } =
        this.state;
        let payload = {
          ...httpConfig,
          data: {reason},
        };
      showLoading();
      const url = baseURL + "/api/users/" + id;
      Axios.delete(url, payload)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.getAllUsers();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
          this.handlePopUp()
        });
    } else {
      showSnakerAlert(false, "Please enter the reason");
    }

  };

  componentDidMount() {
    let isLogedin = !!localStorage.getItem("token");
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser);
    if (!isLogedin) {
      localStorage.clear();
      window.location.href = "/";
    }

 if(isUser.roleId=== roleStatus.User){
  alert("you are not authorized to access this page")
  this.props.history.push("/company") 
 }else{
  this.getAllUsers();
 }
  }

  getAllUsers = (term = "") => {
    showLoading();
    Axios.get(baseURL + `/api/users?term=` + term, httpConfig)
      .then((res) => {
        this.state.countIndex = 0
        res.data.users.forEach(element => {
          this.state.countIndex = this.state.countIndex + 1
          element["index"] = this.state.countIndex
        });
        const { users } = res.data;
        this.setState({ users });
      })
      .finally(hideLoading());
  };

  handleClose = () => {
    this.setState({ show: false, isUpdate: false, isEdit: false });
    this.resetField();
  };

  handleShow = () => this.setState({ show: true });

  handleMoveNext = (index) => {
    if (index !== this.state.users.length) {
      this.state.users.forEach(elementToSend => {
        if (elementToSend.index === index + 1) {
          this.editUser(elementToSend)
        }
      });
    }
  }
  handleMoveBack = (index) => {
    if (index !== 1) {
      this.state.users.forEach(elementToSend => {
        if (elementToSend.index === index - 1) {
          this.editUser(elementToSend)
        }
      });
    }

  }
  handlePopUp = () => {
    this.setState({ reason: "", isDialogOpen: false, message: "" });
  }
  deleteUser = (id) => {
    this.setState({ isDialogOpen: true, message: "delete", type: "user", confirmId: id, });
  }
  confirm = (id) => {
    this.confirmDeleteUser(id)
  }
  render() {
    let isLogedin = !!localStorage.getItem("token");
    let isAdmin = localStorage.getItem("isAdmin") === "true";
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser)
    const { i18n } = this.props;
    const useStyles = makeStyles({
      table: {
        minWidth: 650,
      },
    });

    const {
      state,
      inputChange,
      inputFileChange,
      checkChanged,
      handleShow,
      handleClose,
      saveUser,
      handleMoveNext,
      handleMoveBack,
      handlePopUp,
      confirm
    } = this;
    return (
      <div>
        <h4 className="mb-2 mt-5"> {i18n.t('Profile.menu_user')}</h4>
        <div className="d-flex justify-content-between">
          <input
            className="form-control mt-1"
            placeholder={i18n.t('user.user_search_user')}
            id="search"
            style={{ width: "300px" }}
            onChange={(e) => inputChange(e)}
            value={state.search}
          />

          {isLogedin && isAdmin && isUser.roleId !== roleStatus.User && (
            <>
              <Button
                onClick={() => handleShow()}
                className={[useStyles.button, "Button-bg-Color"]}
                startIcon={<AddIcon />}
              >
                {i18n.t('user.user_add_user')}
              </Button>
            </>
          )}
        </div>
        <Drawer open={state.show} size={400} className="drawer" direction='right' enableOverlay={false}>
          <div className="mx-1 d-flex justify-content-between mt-5 divHeight" >
            {state.isEdit === false ?
              <h5 className="mt-4 ml-2" >{i18n.t('user.CreateUser')}</h5>
              :
              <h5 className="mt-4 ml-2" >{i18n.t('user.UpdateUser')}</h5>
            }
            <FontAwesomeIcon
              icon={faTimes}
              className="mr-3 mt-4 pt-1 awesomeIconSize"
              onClick={() => handleClose()}
            />
          </div>
          <hr className="hrStyle" />
          <div className="row mx-1">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 ml-1 d-flex justify-content-between">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="mt-0 awesomeIconSizeRight"
                    onClick={() => handleMoveBack(state.indexx)}
                  />
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="mt-0 awesomeIconSizeRight"
                    onClick={() => handleMoveNext(state.indexx)}
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control mt-1 mx-1"
                placeholder="Name"
                id="name"
                onChange={(e) => inputChange(e)}
                value={state.name}
              />
              <input
                type="email"
                className="form-control mt-1 mx-1"
                placeholder="Email"
                id="email"
                onChange={(e) => inputChange(e)}
                value={state.email}
              />
              <input
                type="number"
                className="form-control mt-1 mx-1"
                placeholder="Phone"
                id="phone"
                onChange={(e) => inputChange(e)}
                value={state.phone}
              />
              <input
                type="text"
                className="form-control mt-1 mx-1"
                placeholder={i18n.t('user.username')}
                id="username"
                onChange={(e) => inputChange(e)}
                value={state.username}
                autoComplete="new-password"
              />
              <input
                type="password"
                className="form-control mt-1 mx-1"
                placeholder={i18n.t('user.password')}
                id="password"
                onChange={(e) => inputChange(e)}
                value={state.password}
                autoComplete="new-password"
              />

              <div className="row ">
                <div className="col-sm-5 ml-1">
                  <select className="form-control mt-1"
                    onChange={(e) => inputChange(e)}
                    value={state.roleId} id="roleId">
                    <option id="roleId" value="" >Select Role</option>
                    <option id="roleId" value="1">Admin</option>
                    <option id="roleId" value="2">Manager</option>
                    <option id="roleId" value="3">User</option>
                  </select>
                </div>

                <div className="col-sm-6 mt-2">
                  <input
                    type="checkbox"
                    className="mt-1 mx-1"
                    id="isReadOnly"
                    checked={state.isReadOnly}
                    onChange={(e) => checkChanged(e)}
                  />
                  <label for="isReadOnly">{i18n.t('user.readonly')}</label>
                </div>
              </div>

              {!state.isUpdate && (
                <input
                  type="file"
                  className="form-control mt-1 mx-1"
                  placeholder={i18n.t('user.ProfilePicture')}
                  id="file"
                  accept="image/*"
                  onChange={(e) => inputFileChange(e)}
                />
              )}
              <div className="row ">
                <div className="col-sm-12 mt-1 d-flex justify-content-end">
                  <Button
                    className={[useStyles.button, "Button-bg-Color"]}
                    onClick={() => saveUser()}
                  >
                    {i18n.t('Company.save')}
                  </Button>
                </div>
                <div className="col-sm-6 ml-2 mt-0   d-flex justify-content-end">
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        {
          state.isDialogOpen === true ?
            <Dialog
              modal={true}
              buttons={
                [
                  {
                    text: "yes",
                    className: "firstbutton",
                    onClick: () => confirm(state.confirmId)
                  },
                  {
                    text: "Close",
                    onClick: () => handlePopUp()
                  },
                ]
              }>

              <div className="TextDiv">
                <p className="textContainer">{'Why do you want to ' + state.message + ' this ' + state.type + ' ?'}</p>
              </div>
              <div>
                <input
                  className="form-control mt-1"
                  id="deleteBlock"
                  placeholder={i18n.t('candidate.reason')}
                  onChange={(e) => inputChange(e)}
                  value={state.reason}
                />
              </div>
            </Dialog>
            :
            null
        }
        <div className="mb-2">
          <UserRecord
            users={this.state.users}
            editUser={this.editUser}
            deleteUser={this.deleteUser}
            isAdmin={isAdmin}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(User);
