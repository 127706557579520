export const baseURL = window["env"]["apiRoot"];

export const httpConfig = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export const zipCodes = [
  {
    country_code: "CH",
    zipcode: "5000",
    place: "Aarau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Aarau",
    community_code: "4001",
    latitude: "47.3925",
    longitude: "8.0442",
  },
  {
    country_code: "CH",
    zipcode: "5001",
    place: "Aarau 1",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Aarau",
    community_code: "4001",
    latitude: "47.3888",
    longitude: "8.0483",
  },
  {
    country_code: "CH",
    zipcode: "5004",
    place: "Aarau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Aarau",
    community_code: "4001",
    latitude: "47.3925",
    longitude: "8.0442",
  },
  {
    country_code: "CH",
    zipcode: "5017",
    place: "Barmelweid",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Erlinsbach (AG)",
    community_code: "4005",
    latitude: "47.4159",
    longitude: "7.9764",
  },
  {
    country_code: "CH",
    zipcode: "5018",
    place: "Erlinsbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Erlinsbach (AG)",
    community_code: "4005",
    latitude: "47.4052",
    longitude: "8.0151",
  },
  {
    country_code: "CH",
    zipcode: "5022",
    place: "Rombach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Küttigen",
    community_code: "4008",
    latitude: "47.4047",
    longitude: "8.0459",
  },
  {
    country_code: "CH",
    zipcode: "5023",
    place: "Biberstein",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Biberstein",
    community_code: "4002",
    latitude: "47.4164",
    longitude: "8.0851",
  },
  {
    country_code: "CH",
    zipcode: "5024",
    place: "Küttigen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Küttigen",
    community_code: "4008",
    latitude: "47.4157",
    longitude: "8.0477",
  },
  {
    country_code: "CH",
    zipcode: "5025",
    place: "Asp",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Densbüren",
    community_code: "4004",
    latitude: "47.445",
    longitude: "8.0503",
  },
  {
    country_code: "CH",
    zipcode: "5026",
    place: "Densbüren",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Densbüren",
    community_code: "4004",
    latitude: "47.4526",
    longitude: "8.0533",
  },
  {
    country_code: "CH",
    zipcode: "5032",
    place: "Aarau Rohr",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Aarau",
    community_code: "4001",
    latitude: "47.4017",
    longitude: "8.0796",
  },
  {
    country_code: "CH",
    zipcode: "5033",
    place: "Buchs AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Buchs (AG)",
    community_code: "4003",
    latitude: "47.3936",
    longitude: "8.0823",
  },
  {
    country_code: "CH",
    zipcode: "5034",
    place: "Suhr",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Suhr",
    community_code: "4012",
    latitude: "47.3717",
    longitude: "8.0797",
  },
  {
    country_code: "CH",
    zipcode: "5035",
    place: "Unterentfelden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Unterentfelden",
    community_code: "4013",
    latitude: "47.3681",
    longitude: "8.0502",
  },
  {
    country_code: "CH",
    zipcode: "5036",
    place: "Oberentfelden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Oberentfelden",
    community_code: "4010",
    latitude: "47.3564",
    longitude: "8.0459",
  },
  {
    country_code: "CH",
    zipcode: "5037",
    place: "Muhen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Muhen",
    community_code: "4009",
    latitude: "47.3367",
    longitude: "8.0541",
  },
  {
    country_code: "CH",
    zipcode: "5042",
    place: "Hirschthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Hirschthal",
    community_code: "4007",
    latitude: "47.3187",
    longitude: "8.0564",
  },
  {
    country_code: "CH",
    zipcode: "5722",
    place: "Gränichen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Aarau",
    province_code: "1901",
    community: "Gränichen",
    community_code: "4006",
    latitude: "47.3593",
    longitude: "8.1024",
  },
  {
    country_code: "CH",
    zipcode: "5300",
    place: "Turgi",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Turgi",
    community_code: "4042",
    latitude: "47.492",
    longitude: "8.2541",
  },
  {
    country_code: "CH",
    zipcode: "5301",
    place: "Siggenthal Station",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Untersiggenthal",
    community_code: "4044",
    latitude: "47.5174",
    longitude: "8.2395",
  },
  {
    country_code: "CH",
    zipcode: "5303",
    place: "Würenlingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Würenlingen",
    community_code: "4047",
    latitude: "47.5336",
    longitude: "8.2567",
  },
  {
    country_code: "CH",
    zipcode: "5400",
    place: "Baden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.4733",
    longitude: "8.3059",
  },
  {
    country_code: "CH",
    zipcode: "5401",
    place: "Baden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.4651",
    longitude: "8.2896",
  },
  {
    country_code: "CH",
    zipcode: "5402",
    place: "Baden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.4651",
    longitude: "8.2896",
  },
  {
    country_code: "CH",
    zipcode: "5404",
    place: "Baden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.4733",
    longitude: "8.3059",
  },
  {
    country_code: "CH",
    zipcode: "5405",
    place: "Dättwil AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.4551",
    longitude: "8.2847",
  },
  {
    country_code: "CH",
    zipcode: "5406",
    place: "Rütihof",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Baden",
    community_code: "4021",
    latitude: "47.44",
    longitude: "8.2712",
  },
  {
    country_code: "CH",
    zipcode: "5408",
    place: "Ennetbaden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Ennetbaden",
    community_code: "4026",
    latitude: "47.4804",
    longitude: "8.3235",
  },
  {
    country_code: "CH",
    zipcode: "5412",
    place: "Vogelsang AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Gebenstorf",
    community_code: "4029",
    latitude: "47.4943",
    longitude: "8.2382",
  },
  {
    country_code: "CH",
    zipcode: "5412",
    place: "Gebenstorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Gebenstorf",
    community_code: "4029",
    latitude: "47.4814",
    longitude: "8.2395",
  },
  {
    country_code: "CH",
    zipcode: "5413",
    place: "Birmenstorf AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Birmenstorf (AG)",
    community_code: "4024",
    latitude: "47.4615",
    longitude: "8.2482",
  },
  {
    country_code: "CH",
    zipcode: "5415",
    place: "Nussbaumen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Obersiggenthal",
    community_code: "4038",
    latitude: "47.4872",
    longitude: "8.2917",
  },
  {
    country_code: "CH",
    zipcode: "5415",
    place: "Hertenstein AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Obersiggenthal",
    community_code: "4038",
    latitude: "47.4871",
    longitude: "8.3115",
  },
  {
    country_code: "CH",
    zipcode: "5415",
    place: "Rieden AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Obersiggenthal",
    community_code: "4038",
    latitude: "47.4846",
    longitude: "8.3018",
  },
  {
    country_code: "CH",
    zipcode: "5416",
    place: "Kirchdorf AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Obersiggenthal",
    community_code: "4038",
    latitude: "47.4966",
    longitude: "8.2754",
  },
  {
    country_code: "CH",
    zipcode: "5417",
    place: "Untersiggenthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Untersiggenthal",
    community_code: "4044",
    latitude: "47.5021",
    longitude: "8.2555",
  },
  {
    country_code: "CH",
    zipcode: "5420",
    place: "Ehrendingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Ehrendingen",
    community_code: "4049",
    latitude: "47.5025",
    longitude: "8.3473",
  },
  {
    country_code: "CH",
    zipcode: "5423",
    place: "Freienwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Freienwil",
    community_code: "4028",
    latitude: "47.503",
    longitude: "8.3277",
  },
  {
    country_code: "CH",
    zipcode: "5430",
    place: "Wettingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Wettingen",
    community_code: "4045",
    latitude: "47.4705",
    longitude: "8.3164",
  },
  {
    country_code: "CH",
    zipcode: "5432",
    place: "Neuenhof",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Neuenhof",
    community_code: "4034",
    latitude: "47.4526",
    longitude: "8.3258",
  },
  {
    country_code: "CH",
    zipcode: "5436",
    place: "Würenlos",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Würenlos",
    community_code: "4048",
    latitude: "47.4421",
    longitude: "8.3644",
  },
  {
    country_code: "CH",
    zipcode: "5442",
    place: "Fislisbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Fislisbach",
    community_code: "4027",
    latitude: "47.439",
    longitude: "8.2969",
  },
  {
    country_code: "CH",
    zipcode: "5443",
    place: "Niederrohrdorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Niederrohrdorf",
    community_code: "4035",
    latitude: "47.4235",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "5444",
    place: "Künten",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Künten",
    community_code: "4031",
    latitude: "47.3889",
    longitude: "8.331",
  },
  {
    country_code: "CH",
    zipcode: "5452",
    place: "Oberrohrdorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Oberrohrdorf",
    community_code: "4037",
    latitude: "47.4183",
    longitude: "8.3198",
  },
  {
    country_code: "CH",
    zipcode: "5453",
    place: "Remetschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Remetschwil",
    community_code: "4039",
    latitude: "47.4083",
    longitude: "8.3319",
  },
  {
    country_code: "CH",
    zipcode: "5454",
    place: "Bellikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Bellikon",
    community_code: "4022",
    latitude: "47.3916",
    longitude: "8.3493",
  },
  {
    country_code: "CH",
    zipcode: "5506",
    place: "Mägenwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Mägenwil",
    community_code: "4032",
    latitude: "47.4125",
    longitude: "8.233",
  },
  {
    country_code: "CH",
    zipcode: "5507",
    place: "Mellingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Mellingen",
    community_code: "4033",
    latitude: "47.419",
    longitude: "8.2733",
  },
  {
    country_code: "CH",
    zipcode: "5512",
    place: "Wohlenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Wohlenschwil",
    community_code: "4046",
    latitude: "47.4137",
    longitude: "8.2573",
  },
  {
    country_code: "CH",
    zipcode: "5608",
    place: "Stetten AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Stetten (AG)",
    community_code: "4041",
    latitude: "47.4017",
    longitude: "8.3019",
  },
  {
    country_code: "CH",
    zipcode: "8109",
    place: "Kloster Fahr",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Würenlos",
    community_code: "4048",
    latitude: "47.4473",
    longitude: "8.3637",
  },
  {
    country_code: "CH",
    zipcode: "8956",
    place: "Killwangen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Killwangen",
    community_code: "4030",
    latitude: "47.4318",
    longitude: "8.3481",
  },
  {
    country_code: "CH",
    zipcode: "8957",
    place: "Spreitenbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Spreitenbach",
    community_code: "4040",
    latitude: "47.4202",
    longitude: "8.363",
  },
  {
    country_code: "CH",
    zipcode: "8962",
    place: "Bergdietikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Baden",
    province_code: "1902",
    community: "Bergdietikon",
    community_code: "4023",
    latitude: "47.3892",
    longitude: "8.3862",
  },
  {
    country_code: "CH",
    zipcode: "5445",
    place: "Eggenwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Eggenwil",
    community_code: "4066",
    latitude: "47.3704",
    longitude: "8.3395",
  },
  {
    country_code: "CH",
    zipcode: "5522",
    place: "Tägerig",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Tägerig",
    community_code: "4077",
    latitude: "47.4056",
    longitude: "8.2768",
  },
  {
    country_code: "CH",
    zipcode: "5524",
    place: "Niederwil AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Niederwil (AG)",
    community_code: "4072",
    latitude: "47.3897",
    longitude: "8.2914",
  },
  {
    country_code: "CH",
    zipcode: "5524",
    place: "Nesselnbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Niederwil (AG)",
    community_code: "4072",
    latitude: "47.3897",
    longitude: "8.2914",
  },
  {
    country_code: "CH",
    zipcode: "5525",
    place: "Fischbach-Göslikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Fischbach-Göslikon",
    community_code: "4067",
    latitude: "47.3691",
    longitude: "8.3111",
  },
  {
    country_code: "CH",
    zipcode: "5605",
    place: "Dottikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Dottikon",
    community_code: "4065",
    latitude: "47.3844",
    longitude: "8.2398",
  },
  {
    country_code: "CH",
    zipcode: "5607",
    place: "Hägglingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Hägglingen",
    community_code: "4068",
    latitude: "47.3885",
    longitude: "8.2532",
  },
  {
    country_code: "CH",
    zipcode: "5610",
    place: "Wohlen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Wohlen (AG)",
    community_code: "4082",
    latitude: "47.3524",
    longitude: "8.2788",
  },
  {
    country_code: "CH",
    zipcode: "5611",
    place: "Anglikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Wohlen (AG)",
    community_code: "4082",
    latitude: "47.3664",
    longitude: "8.2633",
  },
  {
    country_code: "CH",
    zipcode: "5612",
    place: "Villmergen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Villmergen",
    community_code: "4080",
    latitude: "47.3492",
    longitude: "8.2458",
  },
  {
    country_code: "CH",
    zipcode: "5613",
    place: "Hilfikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Villmergen",
    community_code: "4080",
    latitude: "47.3313",
    longitude: "8.2424",
  },
  {
    country_code: "CH",
    zipcode: "5614",
    place: "Sarmenstorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Sarmenstorf",
    community_code: "4076",
    latitude: "47.3102",
    longitude: "8.2495",
  },
  {
    country_code: "CH",
    zipcode: "5619",
    place: "Uezwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Uezwil",
    community_code: "4078",
    latitude: "47.3169",
    longitude: "8.2716",
  },
  {
    country_code: "CH",
    zipcode: "5619",
    place: "Büttikon AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Büttikon",
    community_code: "4064",
    latitude: "47.3286",
    longitude: "8.2695",
  },
  {
    country_code: "CH",
    zipcode: "5620",
    place: "Bremgarten AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Bremgarten (AG)",
    community_code: "4063",
    latitude: "47.3511",
    longitude: "8.3421",
  },
  {
    country_code: "CH",
    zipcode: "5621",
    place: "Zufikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Zufikon",
    community_code: "4083",
    latitude: "47.3447",
    longitude: "8.3582",
  },
  {
    country_code: "CH",
    zipcode: "5626",
    place: "Hermetschwil-Staffeln",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Bremgarten (AG)",
    community_code: "4063",
    latitude: "47.3303",
    longitude: "8.3413",
  },
  {
    country_code: "CH",
    zipcode: "8905",
    place: "Islisberg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Islisberg",
    community_code: "4084",
    latitude: "47.322",
    longitude: "8.4414",
  },
  {
    country_code: "CH",
    zipcode: "8905",
    place: "Arni AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Arni (AG)",
    community_code: "4061",
    latitude: "47.3182",
    longitude: "8.4247",
  },
  {
    country_code: "CH",
    zipcode: "8916",
    place: "Jonen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Jonen",
    community_code: "4071",
    latitude: "47.2974",
    longitude: "8.3934",
  },
  {
    country_code: "CH",
    zipcode: "8917",
    place: "Oberlunkhofen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Oberlunkhofen",
    community_code: "4073",
    latitude: "47.3115",
    longitude: "8.3914",
  },
  {
    country_code: "CH",
    zipcode: "8918",
    place: "Unterlunkhofen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Unterlunkhofen",
    community_code: "4079",
    latitude: "47.3212",
    longitude: "8.381",
  },
  {
    country_code: "CH",
    zipcode: "8964",
    place: "Rudolfstetten",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Rudolfstetten-Friedlisberg",
    community_code: "4075",
    latitude: "47.371",
    longitude: "8.3808",
  },
  {
    country_code: "CH",
    zipcode: "8965",
    place: "Berikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Berikon",
    community_code: "4062",
    latitude: "47.3514",
    longitude: "8.3721",
  },
  {
    country_code: "CH",
    zipcode: "8966",
    place: "Oberwil-Lieli",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Oberwil-Lieli",
    community_code: "4074",
    latitude: "47.3338",
    longitude: "8.3812",
  },
  {
    country_code: "CH",
    zipcode: "8967",
    place: "Widen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Bremgarten",
    province_code: "1903",
    community: "Widen",
    community_code: "4081",
    latitude: "47.3692",
    longitude: "8.3635",
  },
  {
    country_code: "CH",
    zipcode: "5076",
    place: "Bözen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Bözen",
    community_code: "4094",
    latitude: "47.4778",
    longitude: "8.1646",
  },
  {
    country_code: "CH",
    zipcode: "5077",
    place: "Elfingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Elfingen",
    community_code: "4097",
    latitude: "47.5085",
    longitude: "8.0995",
  },
  {
    country_code: "CH",
    zipcode: "5078",
    place: "Effingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Effingen",
    community_code: "4096",
    latitude: "47.4887",
    longitude: "8.1029",
  },
  {
    country_code: "CH",
    zipcode: "5105",
    place: "Auenstein",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Auenstein",
    community_code: "4091",
    latitude: "47.4156",
    longitude: "8.1345",
  },
  {
    country_code: "CH",
    zipcode: "5106",
    place: "Veltheim AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Veltheim (AG)",
    community_code: "4120",
    latitude: "47.438",
    longitude: "8.1472",
  },
  {
    country_code: "CH",
    zipcode: "5107",
    place: "Schinznach Dorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Schinznach",
    community_code: "4125",
    latitude: "47.4465",
    longitude: "8.1409",
  },
  {
    country_code: "CH",
    zipcode: "5108",
    place: "Oberflachs",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Schinznach",
    community_code: "4125",
    latitude: "47.4402",
    longitude: "8.1252",
  },
  {
    country_code: "CH",
    zipcode: "5112",
    place: "Thalheim AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Thalheim (AG)",
    community_code: "4117",
    latitude: "47.4353",
    longitude: "8.1003",
  },
  {
    country_code: "CH",
    zipcode: "5116",
    place: "Schinznach Bad",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Schinznach-Bad",
    community_code: "4114",
    latitude: "47.4499",
    longitude: "8.1683",
  },
  {
    country_code: "CH",
    zipcode: "5200",
    place: "Brugg AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Brugg",
    community_code: "4095",
    latitude: "47.481",
    longitude: "8.2087",
  },
  {
    country_code: "CH",
    zipcode: "5201",
    place: "Brugg AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Brugg",
    community_code: "4095",
    latitude: "47.4863",
    longitude: "8.211",
  },
  {
    country_code: "CH",
    zipcode: "5210",
    place: "Windisch",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Windisch",
    community_code: "4123",
    latitude: "47.479",
    longitude: "8.2184",
  },
  {
    country_code: "CH",
    zipcode: "5212",
    place: "Hausen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Hausen (AG)",
    community_code: "4100",
    latitude: "47.464",
    longitude: "8.2099",
  },
  {
    country_code: "CH",
    zipcode: "5213",
    place: "Villnachern",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Villnachern",
    community_code: "4122",
    latitude: "47.471",
    longitude: "8.1598",
  },
  {
    country_code: "CH",
    zipcode: "5222",
    place: "Umiken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Brugg",
    community_code: "4095",
    latitude: "47.4829",
    longitude: "8.1875",
  },
  {
    country_code: "CH",
    zipcode: "5223",
    place: "Riniken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Riniken",
    community_code: "4111",
    latitude: "47.494",
    longitude: "8.1867",
  },
  {
    country_code: "CH",
    zipcode: "5225",
    place: "Bözberg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Bözberg",
    community_code: "4124",
    latitude: "47.4955",
    longitude: "8.1514",
  },
  {
    country_code: "CH",
    zipcode: "5232",
    place: "Villigen PSI",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Villigen",
    community_code: "4121",
    latitude: "47.5296",
    longitude: "8.2072",
  },
  {
    country_code: "CH",
    zipcode: "5233",
    place: "Stilli",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Villigen",
    community_code: "4121",
    latitude: "47.5172",
    longitude: "8.2316",
  },
  {
    country_code: "CH",
    zipcode: "5234",
    place: "Villigen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Villigen",
    community_code: "4121",
    latitude: "47.5268",
    longitude: "8.2149",
  },
  {
    country_code: "CH",
    zipcode: "5235",
    place: "Rüfenach AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Rüfenach",
    community_code: "4112",
    latitude: "47.5071",
    longitude: "8.209",
  },
  {
    country_code: "CH",
    zipcode: "5236",
    place: "Remigen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Remigen",
    community_code: "4110",
    latitude: "47.5186",
    longitude: "8.185",
  },
  {
    country_code: "CH",
    zipcode: "5237",
    place: "Mönthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Mönthal",
    community_code: "4106",
    latitude: "47.517",
    longitude: "8.1404",
  },
  {
    country_code: "CH",
    zipcode: "5242",
    place: "Lupfig",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Lupfig",
    community_code: "4104",
    latitude: "47.4407",
    longitude: "8.2038",
  },
  {
    country_code: "CH",
    zipcode: "5242",
    place: "Birr",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Birr",
    community_code: "4092",
    latitude: "47.4359",
    longitude: "8.208",
  },
  {
    country_code: "CH",
    zipcode: "5243",
    place: "Mülligen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Mülligen",
    community_code: "4107",
    latitude: "47.4558",
    longitude: "8.2392",
  },
  {
    country_code: "CH",
    zipcode: "5244",
    place: "Birrhard",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Birrhard",
    community_code: "4093",
    latitude: "47.4328",
    longitude: "8.245",
  },
  {
    country_code: "CH",
    zipcode: "5245",
    place: "Habsburg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Habsburg",
    community_code: "4099",
    latitude: "47.4623",
    longitude: "8.185",
  },
  {
    country_code: "CH",
    zipcode: "5246",
    place: "Scherz",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Scherz",
    community_code: "4113",
    latitude: "47.4469",
    longitude: "8.1842",
  },
  {
    country_code: "CH",
    zipcode: "5318",
    place: "Mandach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Brugg",
    province_code: "1904",
    community: "Mandach",
    community_code: "4105",
    latitude: "47.5478",
    longitude: "8.189",
  },
  {
    country_code: "CH",
    zipcode: "5040",
    place: "Schöftland",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Schöftland",
    community_code: "4144",
    latitude: "47.3057",
    longitude: "8.0514",
  },
  {
    country_code: "CH",
    zipcode: "5043",
    place: "Holziken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Holziken",
    community_code: "4136",
    latitude: "47.3195",
    longitude: "8.0343",
  },
  {
    country_code: "CH",
    zipcode: "5044",
    place: "Schlossrued",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Schlossrued",
    community_code: "4142",
    latitude: "47.2912",
    longitude: "8.0884",
  },
  {
    country_code: "CH",
    zipcode: "5046",
    place: "Walde AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Schmiedrued",
    community_code: "4143",
    latitude: "47.2626",
    longitude: "8.1118",
  },
  {
    country_code: "CH",
    zipcode: "5046",
    place: "Schmiedrued",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Schmiedrued",
    community_code: "4143",
    latitude: "47.2626",
    longitude: "8.1118",
  },
  {
    country_code: "CH",
    zipcode: "5708",
    place: "Birrwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Birrwil",
    community_code: "4132",
    latitude: "47.2886",
    longitude: "8.2",
  },
  {
    country_code: "CH",
    zipcode: "5712",
    place: "Beinwil am See",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Beinwil am See",
    community_code: "4131",
    latitude: "47.2606",
    longitude: "8.2051",
  },
  {
    country_code: "CH",
    zipcode: "5723",
    place: "Teufenthal AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Teufenthal (AG)",
    community_code: "4145",
    latitude: "47.3286",
    longitude: "8.1207",
  },
  {
    country_code: "CH",
    zipcode: "5724",
    place: "Dürrenäsch",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Dürrenäsch",
    community_code: "4134",
    latitude: "47.3181",
    longitude: "8.1585",
  },
  {
    country_code: "CH",
    zipcode: "5725",
    place: "Leutwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Leutwil",
    community_code: "4138",
    latitude: "47.3083",
    longitude: "8.1741",
  },
  {
    country_code: "CH",
    zipcode: "5726",
    place: "Unterkulm",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Unterkulm",
    community_code: "4146",
    latitude: "47.31",
    longitude: "8.1137",
  },
  {
    country_code: "CH",
    zipcode: "5727",
    place: "Oberkulm",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Oberkulm",
    community_code: "4140",
    latitude: "47.3078",
    longitude: "8.1442",
  },
  {
    country_code: "CH",
    zipcode: "5728",
    place: "Gontenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Gontenschwil",
    community_code: "4135",
    latitude: "47.2717",
    longitude: "8.144",
  },
  {
    country_code: "CH",
    zipcode: "5732",
    place: "Zetzwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Zetzwil",
    community_code: "4147",
    latitude: "47.2882",
    longitude: "8.1509",
  },
  {
    country_code: "CH",
    zipcode: "5733",
    place: "Leimbach AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Leimbach (AG)",
    community_code: "4137",
    latitude: "47.2749",
    longitude: "8.1701",
  },
  {
    country_code: "CH",
    zipcode: "5734",
    place: "Reinach AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Reinach (AG)",
    community_code: "4141",
    latitude: "47.2573",
    longitude: "8.1809",
  },
  {
    country_code: "CH",
    zipcode: "5736",
    place: "Burg AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Burg (AG)",
    community_code: "4133",
    latitude: "47.2339",
    longitude: "8.178",
  },
  {
    country_code: "CH",
    zipcode: "5737",
    place: "Menziken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Kulm",
    province_code: "1905",
    community: "Menziken",
    community_code: "4139",
    latitude: "47.2425",
    longitude: "8.1905",
  },
  {
    country_code: "CH",
    zipcode: "4333",
    place: "Münchwilen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Münchwilen (AG)",
    community_code: "4172",
    latitude: "47.5357",
    longitude: "7.9641",
  },
  {
    country_code: "CH",
    zipcode: "4334",
    place: "Sisseln AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Sisseln",
    community_code: "4177",
    latitude: "47.5531",
    longitude: "7.9917",
  },
  {
    country_code: "CH",
    zipcode: "5027",
    place: "Herznach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Herznach",
    community_code: "4166",
    latitude: "47.4715",
    longitude: "8.0475",
  },
  {
    country_code: "CH",
    zipcode: "5028",
    place: "Ueken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Ueken",
    community_code: "4179",
    latitude: "47.4845",
    longitude: "8.0444",
  },
  {
    country_code: "CH",
    zipcode: "5062",
    place: "Oberhof",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Oberhof",
    community_code: "4173",
    latitude: "47.4487",
    longitude: "8.0027",
  },
  {
    country_code: "CH",
    zipcode: "5063",
    place: "Wölflinswil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Wölflinswil",
    community_code: "4182",
    latitude: "47.4607",
    longitude: "7.9984",
  },
  {
    country_code: "CH",
    zipcode: "5064",
    place: "Wittnau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Wittnau",
    community_code: "4181",
    latitude: "47.4814",
    longitude: "7.9758",
  },
  {
    country_code: "CH",
    zipcode: "5070",
    place: "Frick",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Frick",
    community_code: "4163",
    latitude: "47.5117",
    longitude: "8.0247",
  },
  {
    country_code: "CH",
    zipcode: "5072",
    place: "Oeschgen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Oeschgen",
    community_code: "4175",
    latitude: "47.5231",
    longitude: "8.019",
  },
  {
    country_code: "CH",
    zipcode: "5073",
    place: "Gipf-Oberfrick",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Gipf-Oberfrick",
    community_code: "4165",
    latitude: "47.4988",
    longitude: "8.005",
  },
  {
    country_code: "CH",
    zipcode: "5074",
    place: "Eiken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Eiken",
    community_code: "4161",
    latitude: "47.5339",
    longitude: "7.9888",
  },
  {
    country_code: "CH",
    zipcode: "5075",
    place: "Hornussen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Hornussen",
    community_code: "4167",
    latitude: "47.5003",
    longitude: "8.0613",
  },
  {
    country_code: "CH",
    zipcode: "5079",
    place: "Zeihen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Zeihen",
    community_code: "4183",
    latitude: "47.4754",
    longitude: "8.0831",
  },
  {
    country_code: "CH",
    zipcode: "5080",
    place: "Laufenburg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Laufenburg",
    community_code: "4170",
    latitude: "47.5598",
    longitude: "8.0622",
  },
  {
    country_code: "CH",
    zipcode: "5082",
    place: "Kaisten",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Kaisten",
    community_code: "4169",
    latitude: "47.5416",
    longitude: "8.0434",
  },
  {
    country_code: "CH",
    zipcode: "5083",
    place: "Ittenthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Kaisten",
    community_code: "4169",
    latitude: "47.5183",
    longitude: "8.0596",
  },
  {
    country_code: "CH",
    zipcode: "5084",
    place: "Rheinsulz",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Laufenburg",
    community_code: "4170",
    latitude: "47.5556",
    longitude: "8.0899",
  },
  {
    country_code: "CH",
    zipcode: "5085",
    place: "Sulz AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Laufenburg",
    community_code: "4170",
    latitude: "47.536",
    longitude: "8.0963",
  },
  {
    country_code: "CH",
    zipcode: "5272",
    place: "Gansingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Gansingen",
    community_code: "4164",
    latitude: "47.5429",
    longitude: "8.1352",
  },
  {
    country_code: "CH",
    zipcode: "5273",
    place: "Oberhofen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Mettauertal",
    community_code: "4184",
    latitude: "47.5538",
    longitude: "8.1332",
  },
  {
    country_code: "CH",
    zipcode: "5274",
    place: "Mettau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Mettauertal",
    community_code: "4184",
    latitude: "47.5636",
    longitude: "8.1293",
  },
  {
    country_code: "CH",
    zipcode: "5275",
    place: "Etzgen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Mettauertal",
    community_code: "4184",
    latitude: "47.5713",
    longitude: "8.1106",
  },
  {
    country_code: "CH",
    zipcode: "5276",
    place: "Wil AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Mettauertal",
    community_code: "4184",
    latitude: "47.5606",
    longitude: "8.1525",
  },
  {
    country_code: "CH",
    zipcode: "5277",
    place: "Hottwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Mettauertal",
    community_code: "4184",
    latitude: "47.5484",
    longitude: "8.1617",
  },
  {
    country_code: "CH",
    zipcode: "5326",
    place: "Schwaderloch",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Laufenburg",
    province_code: "1906",
    community: "Schwaderloch",
    community_code: "4176",
    latitude: "47.5854",
    longitude: "8.1446",
  },
  {
    country_code: "CH",
    zipcode: "5102",
    place: "Rupperswil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Rupperswil",
    community_code: "4206",
    latitude: "47.4013",
    longitude: "8.1288",
  },
  {
    country_code: "CH",
    zipcode: "5103",
    place: "Möriken AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Möriken-Wildegg",
    community_code: "4203",
    latitude: "47.4156",
    longitude: "8.1845",
  },
  {
    country_code: "CH",
    zipcode: "5103",
    place: "Wildegg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Möriken-Wildegg",
    community_code: "4203",
    latitude: "47.4147",
    longitude: "8.1636",
  },
  {
    country_code: "CH",
    zipcode: "5113",
    place: "Holderbank AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Holderbank (AG)",
    community_code: "4199",
    latitude: "47.4309",
    longitude: "8.1707",
  },
  {
    country_code: "CH",
    zipcode: "5502",
    place: "Hunzenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Hunzenschwil",
    community_code: "4200",
    latitude: "47.3842",
    longitude: "8.1229",
  },
  {
    country_code: "CH",
    zipcode: "5503",
    place: "Schafisheim",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Schafisheim",
    community_code: "4207",
    latitude: "47.3753",
    longitude: "8.1408",
  },
  {
    country_code: "CH",
    zipcode: "5504",
    place: "Othmarsingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Othmarsingen",
    community_code: "4205",
    latitude: "47.4015",
    longitude: "8.2185",
  },
  {
    country_code: "CH",
    zipcode: "5505",
    place: "Brunegg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Brunegg",
    community_code: "4193",
    latitude: "47.4212",
    longitude: "8.2158",
  },
  {
    country_code: "CH",
    zipcode: "5600",
    place: "Lenzburg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Lenzburg",
    community_code: "4201",
    latitude: "47.3885",
    longitude: "8.175",
  },
  {
    country_code: "CH",
    zipcode: "5600",
    place: "Ammerswil AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Ammerswil",
    community_code: "4191",
    latitude: "47.3682",
    longitude: "8.2074",
  },
  {
    country_code: "CH",
    zipcode: "5603",
    place: "Staufen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Staufen",
    community_code: "4210",
    latitude: "47.3837",
    longitude: "8.1661",
  },
  {
    country_code: "CH",
    zipcode: "5604",
    place: "Hendschiken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Hendschiken",
    community_code: "4198",
    latitude: "47.3865",
    longitude: "8.2178",
  },
  {
    country_code: "CH",
    zipcode: "5606",
    place: "Dintikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Dintikon",
    community_code: "4194",
    latitude: "47.3652",
    longitude: "8.2302",
  },
  {
    country_code: "CH",
    zipcode: "5615",
    place: "Fahrwangen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Fahrwangen",
    community_code: "4196",
    latitude: "47.2948",
    longitude: "8.2421",
  },
  {
    country_code: "CH",
    zipcode: "5616",
    place: "Meisterschwanden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Meisterschwanden",
    community_code: "4202",
    latitude: "47.2949",
    longitude: "8.2287",
  },
  {
    country_code: "CH",
    zipcode: "5617",
    place: "Tennwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Meisterschwanden",
    community_code: "4202",
    latitude: "47.3081",
    longitude: "8.2227",
  },
  {
    country_code: "CH",
    zipcode: "5702",
    place: "Niederlenz",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Niederlenz",
    community_code: "4204",
    latitude: "47.4008",
    longitude: "8.1764",
  },
  {
    country_code: "CH",
    zipcode: "5703",
    place: "Seon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Seon",
    community_code: "4209",
    latitude: "47.3449",
    longitude: "8.1561",
  },
  {
    country_code: "CH",
    zipcode: "5704",
    place: "Egliswil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Egliswil",
    community_code: "4195",
    latitude: "47.3495",
    longitude: "8.188",
  },
  {
    country_code: "CH",
    zipcode: "5705",
    place: "Hallwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Hallwil",
    community_code: "4197",
    latitude: "47.3265",
    longitude: "8.1779",
  },
  {
    country_code: "CH",
    zipcode: "5706",
    place: "Boniswil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Boniswil",
    community_code: "4192",
    latitude: "47.3173",
    longitude: "8.1896",
  },
  {
    country_code: "CH",
    zipcode: "5707",
    place: "Seengen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Lenzburg",
    province_code: "1907",
    community: "Seengen",
    community_code: "4208",
    latitude: "47.3285",
    longitude: "8.2051",
  },
  {
    country_code: "CH",
    zipcode: "5618",
    place: "Bettwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Bettwil",
    community_code: "4227",
    latitude: "47.2906",
    longitude: "8.2679",
  },
  {
    country_code: "CH",
    zipcode: "5622",
    place: "Waltenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Waltenschwil",
    community_code: "4240",
    latitude: "47.3349",
    longitude: "8.3034",
  },
  {
    country_code: "CH",
    zipcode: "5623",
    place: "Boswil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Boswil",
    community_code: "4228",
    latitude: "47.3038",
    longitude: "8.3088",
  },
  {
    country_code: "CH",
    zipcode: "5624",
    place: "Waldhäusern AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Bünzen",
    community_code: "4229",
    latitude: "47.3283",
    longitude: "8.3162",
  },
  {
    country_code: "CH",
    zipcode: "5624",
    place: "Bünzen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Bünzen",
    community_code: "4229",
    latitude: "47.3098",
    longitude: "8.3238",
  },
  {
    country_code: "CH",
    zipcode: "5625",
    place: "Kallern",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Kallern",
    community_code: "4233",
    latitude: "47.3016",
    longitude: "8.2813",
  },
  {
    country_code: "CH",
    zipcode: "5627",
    place: "Besenbüren",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Besenbüren",
    community_code: "4226",
    latitude: "47.3144",
    longitude: "8.3459",
  },
  {
    country_code: "CH",
    zipcode: "5628",
    place: "Aristau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Aristau",
    community_code: "4222",
    latitude: "47.2869",
    longitude: "8.3636",
  },
  {
    country_code: "CH",
    zipcode: "5630",
    place: "Muri AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Muri (AG)",
    community_code: "4236",
    latitude: "47.2743",
    longitude: "8.3385",
  },
  {
    country_code: "CH",
    zipcode: "5632",
    place: "Buttwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Buttwil",
    community_code: "4230",
    latitude: "47.2683",
    longitude: "8.3106",
  },
  {
    country_code: "CH",
    zipcode: "5634",
    place: "Merenschwand",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Merenschwand",
    community_code: "4234",
    latitude: "47.2587",
    longitude: "8.3753",
  },
  {
    country_code: "CH",
    zipcode: "5636",
    place: "Benzenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Merenschwand",
    community_code: "4234",
    latitude: "47.248",
    longitude: "8.3651",
  },
  {
    country_code: "CH",
    zipcode: "5637",
    place: "Geltwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Geltwil",
    community_code: "4232",
    latitude: "47.249",
    longitude: "8.326",
  },
  {
    country_code: "CH",
    zipcode: "5637",
    place: "Beinwil (Freiamt)",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Beinwil (Freiamt)",
    community_code: "4224",
    latitude: "47.2296",
    longitude: "8.3392",
  },
  {
    country_code: "CH",
    zipcode: "5642",
    place: "Mühlau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Mühlau",
    community_code: "4235",
    latitude: "47.2304",
    longitude: "8.3896",
  },
  {
    country_code: "CH",
    zipcode: "5643",
    place: "Meienberg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.1978",
    longitude: "8.3762",
  },
  {
    country_code: "CH",
    zipcode: "5643",
    place: "Alikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.2005",
    longitude: "8.3604",
  },
  {
    country_code: "CH",
    zipcode: "5643",
    place: "Sins",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.1922",
    longitude: "8.3958",
  },
  {
    country_code: "CH",
    zipcode: "5643",
    place: "Sins",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.1922",
    longitude: "8.3958",
  },
  {
    country_code: "CH",
    zipcode: "5644",
    place: "Auw",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Auw",
    community_code: "4223",
    latitude: "47.2108",
    longitude: "8.3658",
  },
  {
    country_code: "CH",
    zipcode: "5645",
    place: "Aettenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.1836",
    longitude: "8.3718",
  },
  {
    country_code: "CH",
    zipcode: "5645",
    place: "Fenkrieden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Sins",
    community_code: "4239",
    latitude: "47.1609",
    longitude: "8.3702",
  },
  {
    country_code: "CH",
    zipcode: "5646",
    place: "Abtwil AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Abtwil",
    community_code: "4221",
    latitude: "47.1733",
    longitude: "8.3577",
  },
  {
    country_code: "CH",
    zipcode: "5647",
    place: "Oberrüti",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Oberrüti",
    community_code: "4237",
    latitude: "47.1667",
    longitude: "8.3944",
  },
  {
    country_code: "CH",
    zipcode: "6042",
    place: "Dietwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Dietwil",
    community_code: "4231",
    latitude: "47.1466",
    longitude: "8.3936",
  },
  {
    country_code: "CH",
    zipcode: "8919",
    place: "Rottenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Muri",
    province_code: "1908",
    community: "Rottenschwil",
    community_code: "4238",
    latitude: "47.3137",
    longitude: "8.3614",
  },
  {
    country_code: "CH",
    zipcode: "4303",
    place: "Kaiseraugst",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Kaiseraugst",
    community_code: "4252",
    latitude: "47.5397",
    longitude: "7.726",
  },
  {
    country_code: "CH",
    zipcode: "4305",
    place: "Olsberg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Olsberg",
    community_code: "4257",
    latitude: "47.5233",
    longitude: "7.7837",
  },
  {
    country_code: "CH",
    zipcode: "4310",
    place: "Rheinfelden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Rheinfelden",
    community_code: "4258",
    latitude: "47.5544",
    longitude: "7.794",
  },
  {
    country_code: "CH",
    zipcode: "4312",
    place: "Magden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Magden",
    community_code: "4253",
    latitude: "47.5287",
    longitude: "7.8113",
  },
  {
    country_code: "CH",
    zipcode: "4313",
    place: "Möhlin",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Möhlin",
    community_code: "4254",
    latitude: "47.5592",
    longitude: "7.8433",
  },
  {
    country_code: "CH",
    zipcode: "4314",
    place: "Zeiningen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Zeiningen",
    community_code: "4263",
    latitude: "47.5414",
    longitude: "7.8718",
  },
  {
    country_code: "CH",
    zipcode: "4315",
    place: "Zuzgen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Zuzgen",
    community_code: "4264",
    latitude: "47.5251",
    longitude: "7.8999",
  },
  {
    country_code: "CH",
    zipcode: "4316",
    place: "Hellikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Hellikon",
    community_code: "4251",
    latitude: "47.5094",
    longitude: "7.9242",
  },
  {
    country_code: "CH",
    zipcode: "4317",
    place: "Wegenstetten",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Wegenstetten",
    community_code: "4262",
    latitude: "47.4979",
    longitude: "7.9316",
  },
  {
    country_code: "CH",
    zipcode: "4322",
    place: "Mumpf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Mumpf",
    community_code: "4255",
    latitude: "47.5456",
    longitude: "7.9212",
  },
  {
    country_code: "CH",
    zipcode: "4323",
    place: "Wallbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Wallbach",
    community_code: "4261",
    latitude: "47.5598",
    longitude: "7.9029",
  },
  {
    country_code: "CH",
    zipcode: "4324",
    place: "Obermumpf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Obermumpf",
    community_code: "4256",
    latitude: "47.5294",
    longitude: "7.9376",
  },
  {
    country_code: "CH",
    zipcode: "4325",
    place: "Schupfart",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Schupfart",
    community_code: "4259",
    latitude: "47.5137",
    longitude: "7.9658",
  },
  {
    country_code: "CH",
    zipcode: "4332",
    place: "Stein AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Rheinfelden",
    province_code: "1909",
    community: "Stein (AG)",
    community_code: "4260",
    latitude: "47.544",
    longitude: "7.9526",
  },
  {
    country_code: "CH",
    zipcode: "4663",
    place: "Aarburg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Aarburg",
    community_code: "4271",
    latitude: "47.3207",
    longitude: "7.8999",
  },
  {
    country_code: "CH",
    zipcode: "4665",
    place: "Oftringen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Oftringen",
    community_code: "4280",
    latitude: "47.3138",
    longitude: "7.9253",
  },
  {
    country_code: "CH",
    zipcode: "4800",
    place: "Zofingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2878",
    longitude: "7.9459",
  },
  {
    country_code: "CH",
    zipcode: "4801",
    place: "Zofingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2888",
    longitude: "7.9633",
  },
  {
    country_code: "CH",
    zipcode: "4802",
    place: "Strengelbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Strengelbach",
    community_code: "4285",
    latitude: "47.2792",
    longitude: "7.929",
  },
  {
    country_code: "CH",
    zipcode: "4803",
    place: "Vordemwald",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Vordemwald",
    community_code: "4287",
    latitude: "47.2759",
    longitude: "7.9011",
  },
  {
    country_code: "CH",
    zipcode: "4805",
    place: "Brittnau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Brittnau",
    community_code: "4274",
    latitude: "47.2595",
    longitude: "7.9469",
  },
  {
    country_code: "CH",
    zipcode: "4807",
    place: "Zofingen PF",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2888",
    longitude: "7.9633",
  },
  {
    country_code: "CH",
    zipcode: "4808",
    place: "Zofingen PF",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2888",
    longitude: "7.9633",
  },
  {
    country_code: "CH",
    zipcode: "4809",
    place: "Zofingen PF UBS",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2888",
    longitude: "7.9633",
  },
  {
    country_code: "CH",
    zipcode: "4809",
    place: "Zofingen PF",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.2888",
    longitude: "7.9633",
  },
  {
    country_code: "CH",
    zipcode: "4812",
    place: "Mühlethal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Zofingen",
    community_code: "4289",
    latitude: "47.3028",
    longitude: "7.9792",
  },
  {
    country_code: "CH",
    zipcode: "4813",
    place: "Uerkheim",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Uerkheim",
    community_code: "4286",
    latitude: "47.3029",
    longitude: "8.0237",
  },
  {
    country_code: "CH",
    zipcode: "4814",
    place: "Bottenwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Bottenwil",
    community_code: "4273",
    latitude: "47.2854",
    longitude: "8.0046",
  },
  {
    country_code: "CH",
    zipcode: "4852",
    place: "Rothrist",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Rothrist",
    community_code: "4282",
    latitude: "47.3051",
    longitude: "7.892",
  },
  {
    country_code: "CH",
    zipcode: "4853",
    place: "Riken AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Murgenthal",
    community_code: "4279",
    latitude: "47.278",
    longitude: "7.8508",
  },
  {
    country_code: "CH",
    zipcode: "4853",
    place: "Murgenthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Murgenthal",
    community_code: "4279",
    latitude: "47.2677",
    longitude: "7.8358",
  },
  {
    country_code: "CH",
    zipcode: "4853",
    place: "Murgenthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Murgenthal",
    community_code: "4279",
    latitude: "47.2677",
    longitude: "7.8358",
  },
  {
    country_code: "CH",
    zipcode: "4856",
    place: "Glashütten",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Murgenthal",
    community_code: "4279",
    latitude: "47.2588",
    longitude: "7.8446",
  },
  {
    country_code: "CH",
    zipcode: "5053",
    place: "Staffelbach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Staffelbach",
    community_code: "4284",
    latitude: "47.2839",
    longitude: "8.0421",
  },
  {
    country_code: "CH",
    zipcode: "5053",
    place: "Wittwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Staffelbach",
    community_code: "4284",
    latitude: "47.2919",
    longitude: "8.0364",
  },
  {
    country_code: "CH",
    zipcode: "5054",
    place: "Kirchleerau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Kirchleerau",
    community_code: "4275",
    latitude: "47.2758",
    longitude: "8.0658",
  },
  {
    country_code: "CH",
    zipcode: "5054",
    place: "Moosleerau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Moosleerau",
    community_code: "4277",
    latitude: "47.269",
    longitude: "8.0641",
  },
  {
    country_code: "CH",
    zipcode: "5056",
    place: "Attelwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Attelwil",
    community_code: "4272",
    latitude: "47.2634",
    longitude: "8.0397",
  },
  {
    country_code: "CH",
    zipcode: "5057",
    place: "Reitnau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Reitnau",
    community_code: "4281",
    latitude: "47.2522",
    longitude: "8.0422",
  },
  {
    country_code: "CH",
    zipcode: "5058",
    place: "Wiliberg",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Wiliberg",
    community_code: "4288",
    latitude: "47.2687",
    longitude: "8.0214",
  },
  {
    country_code: "CH",
    zipcode: "5742",
    place: "Kölliken",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Kölliken",
    community_code: "4276",
    latitude: "47.3388",
    longitude: "8.0264",
  },
  {
    country_code: "CH",
    zipcode: "5745",
    place: "Safenwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zofingen",
    province_code: "1910",
    community: "Safenwil",
    community_code: "4283",
    latitude: "47.3214",
    longitude: "7.9812",
  },
  {
    country_code: "CH",
    zipcode: "5304",
    place: "Endingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Endingen",
    community_code: "4305",
    latitude: "47.5389",
    longitude: "8.2903",
  },
  {
    country_code: "CH",
    zipcode: "5305",
    place: "Unterendingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Endingen",
    community_code: "4305",
    latitude: "47.5486",
    longitude: "8.2917",
  },
  {
    country_code: "CH",
    zipcode: "5306",
    place: "Tegerfelden",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Tegerfelden",
    community_code: "4320",
    latitude: "47.5581",
    longitude: "8.2891",
  },
  {
    country_code: "CH",
    zipcode: "5312",
    place: "Döttingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Döttingen",
    community_code: "4304",
    latitude: "47.5709",
    longitude: "8.2585",
  },
  {
    country_code: "CH",
    zipcode: "5313",
    place: "Klingnau",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Klingnau",
    community_code: "4309",
    latitude: "47.5836",
    longitude: "8.2488",
  },
  {
    country_code: "CH",
    zipcode: "5314",
    place: "Kleindöttingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Böttstein",
    community_code: "4303",
    latitude: "47.5709",
    longitude: "8.2465",
  },
  {
    country_code: "CH",
    zipcode: "5315",
    place: "Böttstein",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Böttstein",
    community_code: "4303",
    latitude: "47.5554",
    longitude: "8.2226",
  },
  {
    country_code: "CH",
    zipcode: "5316",
    place: "Leuggern",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Leuggern",
    community_code: "4313",
    latitude: "47.5819",
    longitude: "8.2195",
  },
  {
    country_code: "CH",
    zipcode: "5317",
    place: "Hettenschwil",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Leuggern",
    community_code: "4313",
    latitude: "47.5786",
    longitude: "8.2003",
  },
  {
    country_code: "CH",
    zipcode: "5322",
    place: "Koblenz",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Koblenz",
    community_code: "4310",
    latitude: "47.6097",
    longitude: "8.2375",
  },
  {
    country_code: "CH",
    zipcode: "5323",
    place: "Rietheim",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Rietheim",
    community_code: "4316",
    latitude: "47.6",
    longitude: "8.2833",
  },
  {
    country_code: "CH",
    zipcode: "5324",
    place: "Full-Reuenthal",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Full-Reuenthal",
    community_code: "4307",
    latitude: "47.6087",
    longitude: "8.2027",
  },
  {
    country_code: "CH",
    zipcode: "5325",
    place: "Leibstadt",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Leibstadt",
    community_code: "4311",
    latitude: "47.5879",
    longitude: "8.1761",
  },
  {
    country_code: "CH",
    zipcode: "5330",
    place: "Bad Zurzach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Bad Zurzach",
    community_code: "4323",
    latitude: "47.5876",
    longitude: "8.2936",
  },
  {
    country_code: "CH",
    zipcode: "5332",
    place: "Rekingen AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Rekingen (AG)",
    community_code: "4315",
    latitude: "47.5719",
    longitude: "8.3178",
  },
  {
    country_code: "CH",
    zipcode: "5333",
    place: "Baldingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Baldingen",
    community_code: "4301",
    latitude: "47.5556",
    longitude: "8.3167",
  },
  {
    country_code: "CH",
    zipcode: "5334",
    place: "Böbikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Böbikon",
    community_code: "4302",
    latitude: "47.5556",
    longitude: "8.3347",
  },
  {
    country_code: "CH",
    zipcode: "5425",
    place: "Schneisingen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Schneisingen",
    community_code: "4318",
    latitude: "47.5194",
    longitude: "8.3667",
  },
  {
    country_code: "CH",
    zipcode: "5426",
    place: "Lengnau AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Lengnau (AG)",
    community_code: "4312",
    latitude: "47.5222",
    longitude: "8.3306",
  },
  {
    country_code: "CH",
    zipcode: "5462",
    place: "Siglistorf",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Siglistorf",
    community_code: "4319",
    latitude: "47.5453",
    longitude: "8.3806",
  },
  {
    country_code: "CH",
    zipcode: "5463",
    place: "Wislikofen",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Wislikofen",
    community_code: "4322",
    latitude: "47.5583",
    longitude: "8.3639",
  },
  {
    country_code: "CH",
    zipcode: "5464",
    place: "Rümikon AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Rümikon",
    community_code: "4317",
    latitude: "47.5656",
    longitude: "8.3754",
  },
  {
    country_code: "CH",
    zipcode: "5465",
    place: "Mellikon",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Mellikon",
    community_code: "4314",
    latitude: "47.5694",
    longitude: "8.3542",
  },
  {
    country_code: "CH",
    zipcode: "5466",
    place: "Kaiserstuhl AG",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Kaiserstuhl",
    community_code: "4308",
    latitude: "47.5684",
    longitude: "8.418",
  },
  {
    country_code: "CH",
    zipcode: "5467",
    place: "Fisibach",
    state: "Kanton Aargau",
    state_code: "AG",
    province: "Bezirk Zurzach",
    province_code: "1911",
    community: "Fisibach",
    community_code: "4306",
    latitude: "47.5639",
    longitude: "8.4097",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell Eggerstanden",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Rüte",
    community_code: "3103",
    latitude: "47.3324",
    longitude: "9.4686",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell Schlatt",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Schlatt-Haslen",
    community_code: "3104",
    latitude: "47.3531",
    longitude: "9.3623",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell Meistersrüte",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Appenzell",
    community_code: "3101",
    latitude: "47.3446",
    longitude: "9.4324",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Appenzell",
    community_code: "3101",
    latitude: "47.331",
    longitude: "9.41",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell Steinegg",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Rüte",
    community_code: "3103",
    latitude: "47.322",
    longitude: "9.4315",
  },
  {
    country_code: "CH",
    zipcode: "9050",
    place: "Appenzell Enggenhütten",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Schlatt-Haslen",
    community_code: "3104",
    latitude: "47.3531",
    longitude: "9.3623",
  },
  {
    country_code: "CH",
    zipcode: "9054",
    place: "Haslen AI",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Schlatt-Haslen",
    community_code: "3104",
    latitude: "47.3693",
    longitude: "9.3675",
  },
  {
    country_code: "CH",
    zipcode: "9057",
    place: "Weissbad",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Rüte",
    community_code: "3103",
    latitude: "47.3089",
    longitude: "9.4347",
  },
  {
    country_code: "CH",
    zipcode: "9057",
    place: "Schwende",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Schwende",
    community_code: "3105",
    latitude: "47.303",
    longitude: "9.4367",
  },
  {
    country_code: "CH",
    zipcode: "9057",
    place: "Wasserauen",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Schwende",
    community_code: "3105",
    latitude: "47.2834",
    longitude: "9.4258",
  },
  {
    country_code: "CH",
    zipcode: "9058",
    place: "Brülisau",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Rüte",
    community_code: "3103",
    latitude: "47.2986",
    longitude: "9.457",
  },
  {
    country_code: "CH",
    zipcode: "9108",
    place: "Gontenbad",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Gonten",
    community_code: "3102",
    latitude: "47.3331",
    longitude: "9.3737",
  },
  {
    country_code: "CH",
    zipcode: "9108",
    place: "Gonten",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Gonten",
    community_code: "3102",
    latitude: "47.3272",
    longitude: "9.347",
  },
  {
    country_code: "CH",
    zipcode: "9108",
    place: "Jakobsbad",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Gonten",
    community_code: "3102",
    latitude: "47.3196",
    longitude: "9.3263",
  },
  {
    country_code: "CH",
    zipcode: "9413",
    place: "Oberegg",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Oberegg",
    community_code: "3111",
    latitude: "47.4253",
    longitude: "9.5513",
  },
  {
    country_code: "CH",
    zipcode: "9413",
    place: "Oberegg",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Oberegg",
    community_code: "3111",
    latitude: "47.4253",
    longitude: "9.5513",
  },
  {
    country_code: "CH",
    zipcode: "9442",
    place: "Büriswilen",
    state: "Kanton Appenzell Innerrhoden",
    state_code: "AI",
    province: "Appenzell Inner Rhodes",
    province_code: "1600",
    community: "Oberegg",
    community_code: "3111",
    latitude: "47.4349",
    longitude: "9.6058",
  },
  {
    country_code: "CH",
    zipcode: "9063",
    place: "Stein AR",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Stein (AR)",
    community_code: "3005",
    latitude: "47.3713",
    longitude: "9.3435",
  },
  {
    country_code: "CH",
    zipcode: "9064",
    place: "Hundwil",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Hundwil",
    community_code: "3002",
    latitude: "47.3646",
    longitude: "9.3185",
  },
  {
    country_code: "CH",
    zipcode: "9100",
    place: "Herisau",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Herisau",
    community_code: "3001",
    latitude: "47.3861",
    longitude: "9.2792",
  },
  {
    country_code: "CH",
    zipcode: "9102",
    place: "Herisau",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Herisau",
    community_code: "3001",
    latitude: "47.3829",
    longitude: "9.2741",
  },
  {
    country_code: "CH",
    zipcode: "9103",
    place: "Schwellbrunn",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Schwellbrunn",
    community_code: "3004",
    latitude: "47.3525",
    longitude: "9.2489",
  },
  {
    country_code: "CH",
    zipcode: "9104",
    place: "Waldstatt",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Waldstatt",
    community_code: "3007",
    latitude: "47.3563",
    longitude: "9.2834",
  },
  {
    country_code: "CH",
    zipcode: "9105",
    place: "Schönengrund",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Schönengrund",
    community_code: "3003",
    latitude: "47.3259",
    longitude: "9.2269",
  },
  {
    country_code: "CH",
    zipcode: "9107",
    place: "Urnäsch",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Urnäsch",
    community_code: "3006",
    latitude: "47.3167",
    longitude: "9.2795",
  },
  {
    country_code: "CH",
    zipcode: "9112",
    place: "Schachen b. Herisau",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Hinterland",
    province_code: "1501",
    community: "Herisau",
    community_code: "3001",
    latitude: "47.3865",
    longitude: "9.2445",
  },
  {
    country_code: "CH",
    zipcode: "9037",
    place: "Speicherschwendi",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Speicher",
    community_code: "3023",
    latitude: "47.4265",
    longitude: "9.4378",
  },
  {
    country_code: "CH",
    zipcode: "9042",
    place: "Speicher",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Speicher",
    community_code: "3023",
    latitude: "47.4109",
    longitude: "9.4433",
  },
  {
    country_code: "CH",
    zipcode: "9043",
    place: "Trogen",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Trogen",
    community_code: "3025",
    latitude: "47.4078",
    longitude: "9.465",
  },
  {
    country_code: "CH",
    zipcode: "9052",
    place: "Niederteufen",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Teufen (AR)",
    community_code: "3024",
    latitude: "47.394",
    longitude: "9.3667",
  },
  {
    country_code: "CH",
    zipcode: "9053",
    place: "Teufen AR",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Teufen (AR)",
    community_code: "3024",
    latitude: "47.3908",
    longitude: "9.3864",
  },
  {
    country_code: "CH",
    zipcode: "9055",
    place: "Bühler",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Bühler",
    community_code: "3021",
    latitude: "47.3735",
    longitude: "9.4251",
  },
  {
    country_code: "CH",
    zipcode: "9056",
    place: "Gais",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Gais",
    community_code: "3022",
    latitude: "47.3615",
    longitude: "9.4536",
  },
  {
    country_code: "CH",
    zipcode: "9062",
    place: "Lustmühle",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Mittelland",
    province_code: "1502",
    community: "Teufen (AR)",
    community_code: "3024",
    latitude: "47.3991",
    longitude: "9.3585",
  },
  {
    country_code: "CH",
    zipcode: "9035",
    place: "Grub AR",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Grub (AR)",
    community_code: "3031",
    latitude: "47.4477",
    longitude: "9.5097",
  },
  {
    country_code: "CH",
    zipcode: "9038",
    place: "Rehetobel",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Rehetobel",
    community_code: "3034",
    latitude: "47.4261",
    longitude: "9.483",
  },
  {
    country_code: "CH",
    zipcode: "9044",
    place: "Wald AR",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Wald (AR)",
    community_code: "3036",
    latitude: "47.4156",
    longitude: "9.491",
  },
  {
    country_code: "CH",
    zipcode: "9405",
    place: "Wienacht-Tobel",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Lutzenberg",
    community_code: "3033",
    latitude: "47.4648",
    longitude: "9.5334",
  },
  {
    country_code: "CH",
    zipcode: "9410",
    place: "Heiden",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Heiden",
    community_code: "3032",
    latitude: "47.4425",
    longitude: "9.5329",
  },
  {
    country_code: "CH",
    zipcode: "9411",
    place: "Schachen b. Reute",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Reute (AR)",
    community_code: "3035",
    latitude: "47.4268",
    longitude: "9.5618",
  },
  {
    country_code: "CH",
    zipcode: "9411",
    place: "Reute AR",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Reute (AR)",
    community_code: "3035",
    latitude: "47.4201",
    longitude: "9.5731",
  },
  {
    country_code: "CH",
    zipcode: "9426",
    place: "Lutzenberg",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Lutzenberg",
    community_code: "3033",
    latitude: "47.4613",
    longitude: "9.5761",
  },
  {
    country_code: "CH",
    zipcode: "9427",
    place: "Wolfhalden",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Wolfhalden",
    community_code: "3038",
    latitude: "47.4527",
    longitude: "9.5481",
  },
  {
    country_code: "CH",
    zipcode: "9428",
    place: "Walzenhausen",
    state: "Kanton Appenzell Ausserrhoden",
    state_code: "AR",
    province: "Bezirk Vorderland",
    province_code: "1503",
    community: "Walzenhausen",
    community_code: "3037",
    latitude: "47.4487",
    longitude: "9.605",
  },
  {
    country_code: "CH",
    zipcode: "2333",
    place: "La Ferrière",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "La Ferrière",
    community_code: "435",
    latitude: "47.143",
    longitude: "6.8922",
  },
  {
    country_code: "CH",
    zipcode: "2345",
    place: "Le Cerneux-Veusil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saint-Imier",
    community_code: "443",
    latitude: "47.1794",
    longitude: "6.9669",
  },
  {
    country_code: "CH",
    zipcode: "2515",
    place: "Prêles",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Plateau de Diesse",
    community_code: "726",
    latitude: "47.0993",
    longitude: "7.1302",
  },
  {
    country_code: "CH",
    zipcode: "2516",
    place: "Lamboing",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Plateau de Diesse",
    community_code: "726",
    latitude: "47.1168",
    longitude: "7.1348",
  },
  {
    country_code: "CH",
    zipcode: "2517",
    place: "Diesse",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Plateau de Diesse",
    community_code: "726",
    latitude: "47.1134",
    longitude: "7.1169",
  },
  {
    country_code: "CH",
    zipcode: "2518",
    place: "Nods",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Nods",
    community_code: "724",
    latitude: "47.1149",
    longitude: "7.0801",
  },
  {
    country_code: "CH",
    zipcode: "2520",
    place: "La Neuveville",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "La Neuveville",
    community_code: "723",
    latitude: "47.0659",
    longitude: "7.0972",
  },
  {
    country_code: "CH",
    zipcode: "2534",
    place: "Orvin",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Orvin",
    community_code: "438",
    latitude: "47.1607",
    longitude: "7.2137",
  },
  {
    country_code: "CH",
    zipcode: "2534",
    place: "Les Prés-d'Orvin",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Orvin",
    community_code: "438",
    latitude: "47.157",
    longitude: "7.176",
  },
  {
    country_code: "CH",
    zipcode: "2535",
    place: "Frinvillier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sauge",
    community_code: "449",
    latitude: "47.1691",
    longitude: "7.2549",
  },
  {
    country_code: "CH",
    zipcode: "2536",
    place: "Plagne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sauge",
    community_code: "449",
    latitude: "47.1883",
    longitude: "7.2871",
  },
  {
    country_code: "CH",
    zipcode: "2537",
    place: "Vauffelin",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sauge",
    community_code: "449",
    latitude: "47.1873",
    longitude: "7.3001",
  },
  {
    country_code: "CH",
    zipcode: "2538",
    place: "Romont BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Romont (BE)",
    community_code: "442",
    latitude: "47.1886",
    longitude: "7.3406",
  },
  {
    country_code: "CH",
    zipcode: "2603",
    place: "Péry",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Péry-La Heutte",
    community_code: "450",
    latitude: "47.194",
    longitude: "7.2491",
  },
  {
    country_code: "CH",
    zipcode: "2604",
    place: "La Heutte",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Péry-La Heutte",
    community_code: "450",
    latitude: "47.1907",
    longitude: "7.2261",
  },
  {
    country_code: "CH",
    zipcode: "2605",
    place: "Sonceboz-Sombeval",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sonceboz-Sombeval",
    community_code: "444",
    latitude: "47.1915",
    longitude: "7.1785",
  },
  {
    country_code: "CH",
    zipcode: "2606",
    place: "Corgémont",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Corgémont",
    community_code: "431",
    latitude: "47.1946",
    longitude: "7.1452",
  },
  {
    country_code: "CH",
    zipcode: "2607",
    place: "Cortébert",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Cortébert",
    community_code: "433",
    latitude: "47.1883",
    longitude: "7.1089",
  },
  {
    country_code: "CH",
    zipcode: "2608",
    place: "Courtelary",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Courtelary",
    community_code: "434",
    latitude: "47.1782",
    longitude: "7.0724",
  },
  {
    country_code: "CH",
    zipcode: "2608",
    place: "Montagne-de-Courtelary",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Cortébert",
    community_code: "433",
    latitude: "47.1757",
    longitude: "7.1111",
  },
  {
    country_code: "CH",
    zipcode: "2610",
    place: "Les Pontins",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saint-Imier",
    community_code: "443",
    latitude: "47.1312",
    longitude: "6.9989",
  },
  {
    country_code: "CH",
    zipcode: "2610",
    place: "Mont-Soleil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saint-Imier",
    community_code: "443",
    latitude: "47.1597",
    longitude: "6.9868",
  },
  {
    country_code: "CH",
    zipcode: "2610",
    place: "St-Imier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saint-Imier",
    community_code: "443",
    latitude: "47.1582",
    longitude: "7.0066",
  },
  {
    country_code: "CH",
    zipcode: "2610",
    place: "Mont-Crosin",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Cormoret",
    community_code: "432",
    latitude: "47.1835",
    longitude: "7.0343",
  },
  {
    country_code: "CH",
    zipcode: "2612",
    place: "Cormoret",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Cormoret",
    community_code: "432",
    latitude: "47.1708",
    longitude: "7.0542",
  },
  {
    country_code: "CH",
    zipcode: "2613",
    place: "Villeret",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Villeret",
    community_code: "448",
    latitude: "47.1584",
    longitude: "7.0189",
  },
  {
    country_code: "CH",
    zipcode: "2615",
    place: "Sonvilier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sonvilier",
    community_code: "445",
    latitude: "47.1388",
    longitude: "6.964",
  },
  {
    country_code: "CH",
    zipcode: "2615",
    place: "Montagne-de-Sonvilier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sonvilier",
    community_code: "445",
    latitude: "47.1388",
    longitude: "6.964",
  },
  {
    country_code: "CH",
    zipcode: "2616",
    place: "Renan BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Renan (BE)",
    community_code: "441",
    latitude: "47.1269",
    longitude: "6.9283",
  },
  {
    country_code: "CH",
    zipcode: "2710",
    place: "Tavannes",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Tavannes",
    community_code: "713",
    latitude: "47.2208",
    longitude: "7.1976",
  },
  {
    country_code: "CH",
    zipcode: "2712",
    place: "Le Fuet",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saicourt",
    community_code: "706",
    latitude: "47.2448",
    longitude: "7.184",
  },
  {
    country_code: "CH",
    zipcode: "2713",
    place: "Bellelay",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saicourt",
    community_code: "706",
    latitude: "47.2633",
    longitude: "7.1666",
  },
  {
    country_code: "CH",
    zipcode: "2715",
    place: "Monible",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.277",
    longitude: "7.2033",
  },
  {
    country_code: "CH",
    zipcode: "2715",
    place: "Châtelat",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.2628",
    longitude: "7.1885",
  },
  {
    country_code: "CH",
    zipcode: "2716",
    place: "Sornetan",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.2746",
    longitude: "7.2169",
  },
  {
    country_code: "CH",
    zipcode: "2717",
    place: "Rebévelier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Rebévelier",
    community_code: "715",
    latitude: "47.2914",
    longitude: "7.1923",
  },
  {
    country_code: "CH",
    zipcode: "2717",
    place: "Fornet-Dessous",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.2914",
    longitude: "7.1923",
  },
  {
    country_code: "CH",
    zipcode: "2720",
    place: "Tramelan",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Tramelan",
    community_code: "446",
    latitude: "47.2239",
    longitude: "7.0995",
  },
  {
    country_code: "CH",
    zipcode: "2720",
    place: "La Tanne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Tavannes",
    community_code: "713",
    latitude: "47.2221",
    longitude: "7.1533",
  },
  {
    country_code: "CH",
    zipcode: "2722",
    place: "Les Reussilles",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Tramelan",
    community_code: "446",
    latitude: "47.2259",
    longitude: "7.0846",
  },
  {
    country_code: "CH",
    zipcode: "2723",
    place: "Mont-Tramelan",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Mont-Tramelan",
    community_code: "437",
    latitude: "47.21",
    longitude: "7.0648",
  },
  {
    country_code: "CH",
    zipcode: "2732",
    place: "Loveresse",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Loveresse",
    community_code: "696",
    latitude: "47.2439",
    longitude: "7.2388",
  },
  {
    country_code: "CH",
    zipcode: "2732",
    place: "Saicourt",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saicourt",
    community_code: "706",
    latitude: "47.2428",
    longitude: "7.2075",
  },
  {
    country_code: "CH",
    zipcode: "2732",
    place: "Reconvilier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Reconvilier",
    community_code: "703",
    latitude: "47.2343",
    longitude: "7.2224",
  },
  {
    country_code: "CH",
    zipcode: "2732",
    place: "Saules BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Saules (BE)",
    community_code: "707",
    latitude: "47.2457",
    longitude: "7.2218",
  },
  {
    country_code: "CH",
    zipcode: "2733",
    place: "Pontenet",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Valbirse",
    community_code: "717",
    latitude: "47.2433",
    longitude: "7.2546",
  },
  {
    country_code: "CH",
    zipcode: "2735",
    place: "Malleray",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Valbirse",
    community_code: "717",
    latitude: "47.2384",
    longitude: "7.2729",
  },
  {
    country_code: "CH",
    zipcode: "2735",
    place: "Bévilard",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Valbirse",
    community_code: "717",
    latitude: "47.2371",
    longitude: "7.2832",
  },
  {
    country_code: "CH",
    zipcode: "2735",
    place: "Champoz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Champoz",
    community_code: "683",
    latitude: "47.2558",
    longitude: "7.2953",
  },
  {
    country_code: "CH",
    zipcode: "2736",
    place: "Sorvilier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Sorvilier",
    community_code: "711",
    latitude: "47.2393",
    longitude: "7.3037",
  },
  {
    country_code: "CH",
    zipcode: "2738",
    place: "Court",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Court",
    community_code: "690",
    latitude: "47.2396",
    longitude: "7.3365",
  },
  {
    country_code: "CH",
    zipcode: "2740",
    place: "Moutier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Moutier",
    community_code: "700",
    latitude: "47.2782",
    longitude: "7.3695",
  },
  {
    country_code: "CH",
    zipcode: "2742",
    place: "Perrefitte",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Perrefitte",
    community_code: "701",
    latitude: "47.2757",
    longitude: "7.3416",
  },
  {
    country_code: "CH",
    zipcode: "2743",
    place: "Eschert",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Eschert",
    community_code: "692",
    latitude: "47.2753",
    longitude: "7.4013",
  },
  {
    country_code: "CH",
    zipcode: "2744",
    place: "Belprahon",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Belprahon",
    community_code: "681",
    latitude: "47.2834",
    longitude: "7.4058",
  },
  {
    country_code: "CH",
    zipcode: "2745",
    place: "Grandval",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Grandval",
    community_code: "694",
    latitude: "47.2828",
    longitude: "7.425",
  },
  {
    country_code: "CH",
    zipcode: "2746",
    place: "Crémines",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Crémines",
    community_code: "691",
    latitude: "47.2833",
    longitude: "7.4403",
  },
  {
    country_code: "CH",
    zipcode: "2747",
    place: "Corcelles BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Corcelles (BE)",
    community_code: "687",
    latitude: "47.2849",
    longitude: "7.4524",
  },
  {
    country_code: "CH",
    zipcode: "2747",
    place: "Seehof",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Seehof",
    community_code: "709",
    latitude: "47.3032",
    longitude: "7.5209",
  },
  {
    country_code: "CH",
    zipcode: "2748",
    place: "Souboz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.2749",
    longitude: "7.2445",
  },
  {
    country_code: "CH",
    zipcode: "2748",
    place: "Les Ecorcheresses",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Petit-Val",
    community_code: "716",
    latitude: "47.2773",
    longitude: "7.2803",
  },
  {
    country_code: "CH",
    zipcode: "2762",
    place: "Roches BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Roches (BE)",
    community_code: "704",
    latitude: "47.302",
    longitude: "7.3813",
  },
  {
    country_code: "CH",
    zipcode: "2827",
    place: "Schelten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Jura bernois",
    province_code: "241",
    community: "Schelten",
    community_code: "708",
    latitude: "47.3343",
    longitude: "7.5517",
  },
  {
    country_code: "CH",
    zipcode: "2500",
    place: "Biel/Bienne 4",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1492",
    longitude: "7.2611",
  },
  {
    country_code: "CH",
    zipcode: "2500",
    place: "Biel/Bienne 6",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1492",
    longitude: "7.2611",
  },
  {
    country_code: "CH",
    zipcode: "2500",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1492",
    longitude: "7.2611",
  },
  {
    country_code: "CH",
    zipcode: "2501",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1492",
    longitude: "7.2611",
  },
  {
    country_code: "CH",
    zipcode: "2502",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1371",
    longitude: "7.2461",
  },
  {
    country_code: "CH",
    zipcode: "2503",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1371",
    longitude: "7.2461",
  },
  {
    country_code: "CH",
    zipcode: "2504",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1371",
    longitude: "7.2461",
  },
  {
    country_code: "CH",
    zipcode: "2505",
    place: "Biel/Bienne",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Biel/Bienne",
    community_code: "371",
    latitude: "47.1371",
    longitude: "7.2461",
  },
  {
    country_code: "CH",
    zipcode: "2512",
    place: "Tüscherz-Alfermée",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Twann-Tüscherz",
    community_code: "756",
    latitude: "47.1141",
    longitude: "7.1942",
  },
  {
    country_code: "CH",
    zipcode: "2513",
    place: "Twann",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Twann-Tüscherz",
    community_code: "756",
    latitude: "47.0942",
    longitude: "7.157",
  },
  {
    country_code: "CH",
    zipcode: "2514",
    place: "Ligerz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Ligerz",
    community_code: "740",
    latitude: "47.0837",
    longitude: "7.1348",
  },
  {
    country_code: "CH",
    zipcode: "2532",
    place: "Macolin",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Evilard",
    community_code: "372",
    latitude: "47.139",
    longitude: "7.2141",
  },
  {
    country_code: "CH",
    zipcode: "2533",
    place: "Evilard",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Evilard",
    community_code: "372",
    latitude: "47.1505",
    longitude: "7.239",
  },
  {
    country_code: "CH",
    zipcode: "2542",
    place: "Pieterlen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Pieterlen",
    community_code: "392",
    latitude: "47.175",
    longitude: "7.3379",
  },
  {
    country_code: "CH",
    zipcode: "2543",
    place: "Lengnau BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Lengnau (BE)",
    community_code: "387",
    latitude: "47.1815",
    longitude: "7.3681",
  },
  {
    country_code: "CH",
    zipcode: "2552",
    place: "Orpund",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Orpund",
    community_code: "744",
    latitude: "47.1389",
    longitude: "7.3078",
  },
  {
    country_code: "CH",
    zipcode: "2553",
    place: "Safnern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Safnern",
    community_code: "746",
    latitude: "47.15",
    longitude: "7.3231",
  },
  {
    country_code: "CH",
    zipcode: "2554",
    place: "Meinisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Meinisberg",
    community_code: "390",
    latitude: "47.1597",
    longitude: "7.348",
  },
  {
    country_code: "CH",
    zipcode: "2555",
    place: "Brügg BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Brügg",
    community_code: "733",
    latitude: "47.1237",
    longitude: "7.2789",
  },
  {
    country_code: "CH",
    zipcode: "2556",
    place: "Scheuren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Scheuren",
    community_code: "747",
    latitude: "47.1347",
    longitude: "7.3209",
  },
  {
    country_code: "CH",
    zipcode: "2556",
    place: "Schwadernau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Schwadernau",
    community_code: "748",
    latitude: "47.1278",
    longitude: "7.3078",
  },
  {
    country_code: "CH",
    zipcode: "2558",
    place: "Aegerten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Aegerten",
    community_code: "731",
    latitude: "47.1211",
    longitude: "7.2916",
  },
  {
    country_code: "CH",
    zipcode: "2560",
    place: "Nidau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Nidau",
    community_code: "743",
    latitude: "47.1255",
    longitude: "7.2403",
  },
  {
    country_code: "CH",
    zipcode: "2562",
    place: "Port",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Port",
    community_code: "745",
    latitude: "47.1141",
    longitude: "7.2589",
  },
  {
    country_code: "CH",
    zipcode: "2563",
    place: "Ipsach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Ipsach",
    community_code: "739",
    latitude: "47.1139",
    longitude: "7.2303",
  },
  {
    country_code: "CH",
    zipcode: "2564",
    place: "Bellmund",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Bellmund",
    community_code: "732",
    latitude: "47.1085",
    longitude: "7.2461",
  },
  {
    country_code: "CH",
    zipcode: "2572",
    place: "Sutz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Sutz-Lattrigen",
    community_code: "750",
    latitude: "47.1034",
    longitude: "7.2208",
  },
  {
    country_code: "CH",
    zipcode: "2572",
    place: "Mörigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Biel/Bienne District",
    province_code: "242",
    community: "Mörigen",
    community_code: "742",
    latitude: "47.0851",
    longitude: "7.2141",
  },
  {
    country_code: "CH",
    zipcode: "2557",
    place: "Studen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Studen (BE)",
    community_code: "749",
    latitude: "47.113",
    longitude: "7.3032",
  },
  {
    country_code: "CH",
    zipcode: "2565",
    place: "Jens",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Jens",
    community_code: "738",
    latitude: "47.0963",
    longitude: "7.2636",
  },
  {
    country_code: "CH",
    zipcode: "2575",
    place: "Täuffelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Täuffelen",
    community_code: "751",
    latitude: "47.0652",
    longitude: "7.197",
  },
  {
    country_code: "CH",
    zipcode: "2575",
    place: "Gerolfingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Täuffelen",
    community_code: "751",
    latitude: "47.0771",
    longitude: "7.1989",
  },
  {
    country_code: "CH",
    zipcode: "2575",
    place: "Hagneck",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Hagneck",
    community_code: "736",
    latitude: "47.057",
    longitude: "7.1853",
  },
  {
    country_code: "CH",
    zipcode: "2576",
    place: "Lüscherz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Lüscherz",
    community_code: "497",
    latitude: "47.0446",
    longitude: "7.1527",
  },
  {
    country_code: "CH",
    zipcode: "2577",
    place: "Finsterhennen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Finsterhennen",
    community_code: "493",
    latitude: "47.0248",
    longitude: "7.1754",
  },
  {
    country_code: "CH",
    zipcode: "2577",
    place: "Siselen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Siselen",
    community_code: "499",
    latitude: "47.0324",
    longitude: "7.1888",
  },
  {
    country_code: "CH",
    zipcode: "3035",
    place: "Frieswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Seedorf (BE)",
    community_code: "312",
    latitude: "46.9951",
    longitude: "7.2866",
  },
  {
    country_code: "CH",
    zipcode: "3036",
    place: "Detligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Radelfingen",
    community_code: "309",
    latitude: "47.0031",
    longitude: "7.2736",
  },
  {
    country_code: "CH",
    zipcode: "3053",
    place: "Lätti",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.0378",
    longitude: "7.4322",
  },
  {
    country_code: "CH",
    zipcode: "3054",
    place: "Schüpfen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Schüpfen",
    community_code: "311",
    latitude: "47.0366",
    longitude: "7.3772",
  },
  {
    country_code: "CH",
    zipcode: "3225",
    place: "Müntschemier",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Müntschemier",
    community_code: "498",
    latitude: "46.9955",
    longitude: "7.1463",
  },
  {
    country_code: "CH",
    zipcode: "3226",
    place: "Treiten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Treiten",
    community_code: "500",
    latitude: "47.0086",
    longitude: "7.1603",
  },
  {
    country_code: "CH",
    zipcode: "3232",
    place: "Ins",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Ins",
    community_code: "496",
    latitude: "47.0058",
    longitude: "7.1061",
  },
  {
    country_code: "CH",
    zipcode: "3233",
    place: "Tschugg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Tschugg",
    community_code: "501",
    latitude: "47.0253",
    longitude: "7.081",
  },
  {
    country_code: "CH",
    zipcode: "3234",
    place: "Vinelz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Vinelz",
    community_code: "502",
    latitude: "47.034",
    longitude: "7.1161",
  },
  {
    country_code: "CH",
    zipcode: "3235",
    place: "Erlach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Erlach",
    community_code: "492",
    latitude: "47.0422",
    longitude: "7.0973",
  },
  {
    country_code: "CH",
    zipcode: "3236",
    place: "Gampelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Gampelen",
    community_code: "495",
    latitude: "47.012",
    longitude: "7.0577",
  },
  {
    country_code: "CH",
    zipcode: "3237",
    place: "Brüttelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Brüttelen",
    community_code: "491",
    latitude: "47.0227",
    longitude: "7.1479",
  },
  {
    country_code: "CH",
    zipcode: "3238",
    place: "Gals",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Gals",
    community_code: "494",
    latitude: "47.0284",
    longitude: "7.0518",
  },
  {
    country_code: "CH",
    zipcode: "3250",
    place: "Lyss",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Lyss",
    community_code: "306",
    latitude: "47.0741",
    longitude: "7.3066",
  },
  {
    country_code: "CH",
    zipcode: "3251",
    place: "Ruppoldsried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.0891",
    longitude: "7.4265",
  },
  {
    country_code: "CH",
    zipcode: "3251",
    place: "Wengi b. Büren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Wengi",
    community_code: "394",
    latitude: "47.0862",
    longitude: "7.3954",
  },
  {
    country_code: "CH",
    zipcode: "3252",
    place: "Worben",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Worben",
    community_code: "755",
    latitude: "47.1028",
    longitude: "7.2952",
  },
  {
    country_code: "CH",
    zipcode: "3255",
    place: "Rapperswil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.0632",
    longitude: "7.4109",
  },
  {
    country_code: "CH",
    zipcode: "3256",
    place: "Seewil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.0495",
    longitude: "7.4098",
  },
  {
    country_code: "CH",
    zipcode: "3256",
    place: "Dieterswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.0565",
    longitude: "7.4259",
  },
  {
    country_code: "CH",
    zipcode: "3256",
    place: "Bangerten b. Dieterswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rapperswil (BE)",
    community_code: "310",
    latitude: "47.053",
    longitude: "7.4178",
  },
  {
    country_code: "CH",
    zipcode: "3257",
    place: "Grossaffoltern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Grossaffoltern",
    community_code: "303",
    latitude: "47.0659",
    longitude: "7.3623",
  },
  {
    country_code: "CH",
    zipcode: "3257",
    place: "Ammerzwil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Grossaffoltern",
    community_code: "303",
    latitude: "47.0704",
    longitude: "7.3418",
  },
  {
    country_code: "CH",
    zipcode: "3262",
    place: "Suberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Grossaffoltern",
    community_code: "303",
    latitude: "47.061",
    longitude: "7.3373",
  },
  {
    country_code: "CH",
    zipcode: "3263",
    place: "Büetigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Büetigen",
    community_code: "382",
    latitude: "47.106",
    longitude: "7.338",
  },
  {
    country_code: "CH",
    zipcode: "3264",
    place: "Diessbach b. Büren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Diessbach bei Büren",
    community_code: "385",
    latitude: "47.1078",
    longitude: "7.3612",
  },
  {
    country_code: "CH",
    zipcode: "3266",
    place: "Wiler b. Seedorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Seedorf (BE)",
    community_code: "312",
    latitude: "47.0499",
    longitude: "7.321",
  },
  {
    country_code: "CH",
    zipcode: "3267",
    place: "Seedorf BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Seedorf (BE)",
    community_code: "312",
    latitude: "47.0345",
    longitude: "7.3125",
  },
  {
    country_code: "CH",
    zipcode: "3268",
    place: "Lobsigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Seedorf (BE)",
    community_code: "312",
    latitude: "47.0282",
    longitude: "7.2945",
  },
  {
    country_code: "CH",
    zipcode: "3270",
    place: "Aarberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Aarberg",
    community_code: "301",
    latitude: "47.0444",
    longitude: "7.2758",
  },
  {
    country_code: "CH",
    zipcode: "3271",
    place: "Radelfingen b. Aarberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Radelfingen",
    community_code: "309",
    latitude: "47.0215",
    longitude: "7.2718",
  },
  {
    country_code: "CH",
    zipcode: "3272",
    place: "Epsach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Epsach",
    community_code: "735",
    latitude: "47.0705",
    longitude: "7.2217",
  },
  {
    country_code: "CH",
    zipcode: "3272",
    place: "Walperswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Walperswil",
    community_code: "754",
    latitude: "47.0602",
    longitude: "7.2296",
  },
  {
    country_code: "CH",
    zipcode: "3273",
    place: "Kappelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Kappelen",
    community_code: "305",
    latitude: "47.0602",
    longitude: "7.2686",
  },
  {
    country_code: "CH",
    zipcode: "3274",
    place: "Bühl b. Aarberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Bühl",
    community_code: "734",
    latitude: "47.0708",
    longitude: "7.2454",
  },
  {
    country_code: "CH",
    zipcode: "3274",
    place: "Hermrigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Hermrigen",
    community_code: "737",
    latitude: "47.0817",
    longitude: "7.2416",
  },
  {
    country_code: "CH",
    zipcode: "3274",
    place: "Merzligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Merzligen",
    community_code: "741",
    latitude: "47.0873",
    longitude: "7.2538",
  },
  {
    country_code: "CH",
    zipcode: "3282",
    place: "Bargen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Bargen (BE)",
    community_code: "302",
    latitude: "47.0375",
    longitude: "7.2629",
  },
  {
    country_code: "CH",
    zipcode: "3283",
    place: "Kallnach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Kallnach",
    community_code: "304",
    latitude: "47.0203",
    longitude: "7.2355",
  },
  {
    country_code: "CH",
    zipcode: "3283",
    place: "Niederried b. Kallnach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Kallnach",
    community_code: "304",
    latitude: "47.0112",
    longitude: "7.2501",
  },
  {
    country_code: "CH",
    zipcode: "3292",
    place: "Busswil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Lyss",
    community_code: "306",
    latitude: "47.0997",
    longitude: "7.3222",
  },
  {
    country_code: "CH",
    zipcode: "3293",
    place: "Dotzigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Dotzigen",
    community_code: "386",
    latitude: "47.1217",
    longitude: "7.3456",
  },
  {
    country_code: "CH",
    zipcode: "3294",
    place: "Meienried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Meienried",
    community_code: "389",
    latitude: "47.1387",
    longitude: "7.3414",
  },
  {
    country_code: "CH",
    zipcode: "3294",
    place: "Büren an der Aare",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Büren an der Aare",
    community_code: "383",
    latitude: "47.1398",
    longitude: "7.3722",
  },
  {
    country_code: "CH",
    zipcode: "3295",
    place: "Rüti b. Büren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Rüti bei Büren",
    community_code: "393",
    latitude: "47.1513",
    longitude: "7.4113",
  },
  {
    country_code: "CH",
    zipcode: "3296",
    place: "Arch",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Arch",
    community_code: "381",
    latitude: "47.1653",
    longitude: "7.4314",
  },
  {
    country_code: "CH",
    zipcode: "3297",
    place: "Leuzigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Leuzigen",
    community_code: "388",
    latitude: "47.1746",
    longitude: "7.4578",
  },
  {
    country_code: "CH",
    zipcode: "3298",
    place: "Oberwil b. Büren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Seeland District",
    province_code: "243",
    community: "Oberwil bei Büren",
    community_code: "391",
    latitude: "47.1307",
    longitude: "7.4114",
  },
  {
    country_code: "CH",
    zipcode: "3360",
    place: "Herzogenbuchsee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Herzogenbuchsee",
    community_code: "979",
    latitude: "47.1879",
    longitude: "7.7062",
  },
  {
    country_code: "CH",
    zipcode: "3362",
    place: "Niederönz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Niederönz",
    community_code: "982",
    latitude: "47.1842",
    longitude: "7.6906",
  },
  {
    country_code: "CH",
    zipcode: "3363",
    place: "Oberönz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Herzogenbuchsee",
    community_code: "979",
    latitude: "47.1779",
    longitude: "7.6949",
  },
  {
    country_code: "CH",
    zipcode: "3365",
    place: "Seeberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Seeberg",
    community_code: "988",
    latitude: "47.1559",
    longitude: "7.6657",
  },
  {
    country_code: "CH",
    zipcode: "3365",
    place: "Grasswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Seeberg",
    community_code: "988",
    latitude: "47.1441",
    longitude: "7.6701",
  },
  {
    country_code: "CH",
    zipcode: "3366",
    place: "Bollodingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Bettenhausen",
    community_code: "973",
    latitude: "47.1662",
    longitude: "7.7056",
  },
  {
    country_code: "CH",
    zipcode: "3366",
    place: "Bettenhausen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Bettenhausen",
    community_code: "973",
    latitude: "47.1715",
    longitude: "7.7157",
  },
  {
    country_code: "CH",
    zipcode: "3367",
    place: "Ochlenberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Ochlenberg",
    community_code: "985",
    latitude: "47.1495",
    longitude: "7.736",
  },
  {
    country_code: "CH",
    zipcode: "3367",
    place: "Thörigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Thörigen",
    community_code: "989",
    latitude: "47.1731",
    longitude: "7.7289",
  },
  {
    country_code: "CH",
    zipcode: "3368",
    place: "Bleienbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Bleienbach",
    community_code: "324",
    latitude: "47.1849",
    longitude: "7.7563",
  },
  {
    country_code: "CH",
    zipcode: "3372",
    place: "Wanzwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Heimenhausen",
    community_code: "977",
    latitude: "47.2003",
    longitude: "7.6932",
  },
  {
    country_code: "CH",
    zipcode: "3373",
    place: "Röthenbach Herzogenbuchsee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Heimenhausen",
    community_code: "977",
    latitude: "47.2064",
    longitude: "7.6816",
  },
  {
    country_code: "CH",
    zipcode: "3373",
    place: "Heimenhausen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Heimenhausen",
    community_code: "977",
    latitude: "47.209",
    longitude: "7.6996",
  },
  {
    country_code: "CH",
    zipcode: "3374",
    place: "Wangenried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wangenried",
    community_code: "993",
    latitude: "47.2171",
    longitude: "7.6556",
  },
  {
    country_code: "CH",
    zipcode: "3375",
    place: "Inkwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Inkwil",
    community_code: "980",
    latitude: "47.201",
    longitude: "7.6712",
  },
  {
    country_code: "CH",
    zipcode: "3376",
    place: "Berken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Berken",
    community_code: "972",
    latitude: "47.2258",
    longitude: "7.7059",
  },
  {
    country_code: "CH",
    zipcode: "3376",
    place: "Graben",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Graben",
    community_code: "976",
    latitude: "47.2153",
    longitude: "7.7178",
  },
  {
    country_code: "CH",
    zipcode: "3377",
    place: "Walliswil b. Wangen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Walliswil bei Wangen",
    community_code: "991",
    latitude: "47.2288",
    longitude: "7.6829",
  },
  {
    country_code: "CH",
    zipcode: "3380",
    place: "Wangen an der Aare",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wangen an der Aare",
    community_code: "992",
    latitude: "47.232",
    longitude: "7.6545",
  },
  {
    country_code: "CH",
    zipcode: "3380",
    place: "Walliswil b. Niederbipp",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Walliswil bei Niederbipp",
    community_code: "990",
    latitude: "47.2362",
    longitude: "7.6953",
  },
  {
    country_code: "CH",
    zipcode: "3464",
    place: "Schmidigen-Mühleweg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Walterswil (BE)",
    community_code: "959",
    latitude: "47.0943",
    longitude: "7.7459",
  },
  {
    country_code: "CH",
    zipcode: "3475",
    place: "Hermiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Seeberg",
    community_code: "988",
    latitude: "47.1422",
    longitude: "7.683",
  },
  {
    country_code: "CH",
    zipcode: "3475",
    place: "Riedtwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Seeberg",
    community_code: "988",
    latitude: "47.1437",
    longitude: "7.6982",
  },
  {
    country_code: "CH",
    zipcode: "3476",
    place: "Oschwand",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Ochlenberg",
    community_code: "985",
    latitude: "47.1408",
    longitude: "7.7129",
  },
  {
    country_code: "CH",
    zipcode: "4536",
    place: "Attiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Attiswil",
    community_code: "971",
    latitude: "47.2467",
    longitude: "7.6135",
  },
  {
    country_code: "CH",
    zipcode: "4537",
    place: "Wiedlisbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wiedlisbach",
    community_code: "995",
    latitude: "47.2519",
    longitude: "7.6461",
  },
  {
    country_code: "CH",
    zipcode: "4538",
    place: "Oberbipp",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Oberbipp",
    community_code: "983",
    latitude: "47.2607",
    longitude: "7.6636",
  },
  {
    country_code: "CH",
    zipcode: "4539",
    place: "Rumisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Rumisberg",
    community_code: "987",
    latitude: "47.2641",
    longitude: "7.6403",
  },
  {
    country_code: "CH",
    zipcode: "4539",
    place: "Farnern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Farnern",
    community_code: "975",
    latitude: "47.2669",
    longitude: "7.6178",
  },
  {
    country_code: "CH",
    zipcode: "4704",
    place: "Niederbipp",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Niederbipp",
    community_code: "981",
    latitude: "47.2661",
    longitude: "7.6946",
  },
  {
    country_code: "CH",
    zipcode: "4704",
    place: "Wolfisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wolfisberg",
    community_code: "996",
    latitude: "47.2748",
    longitude: "7.6599",
  },
  {
    country_code: "CH",
    zipcode: "4900",
    place: "Langenthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Langenthal",
    community_code: "329",
    latitude: "47.2153",
    longitude: "7.7961",
  },
  {
    country_code: "CH",
    zipcode: "4901",
    place: "Langenthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Langenthal",
    community_code: "329",
    latitude: "47.2158",
    longitude: "7.7945",
  },
  {
    country_code: "CH",
    zipcode: "4911",
    place: "Schwarzhäusern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Schwarzhäusern",
    community_code: "341",
    latitude: "47.2517",
    longitude: "7.7655",
  },
  {
    country_code: "CH",
    zipcode: "4912",
    place: "Aarwangen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Aarwangen",
    community_code: "321",
    latitude: "47.2385",
    longitude: "7.7685",
  },
  {
    country_code: "CH",
    zipcode: "4913",
    place: "Bannwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Bannwil",
    community_code: "323",
    latitude: "47.234",
    longitude: "7.731",
  },
  {
    country_code: "CH",
    zipcode: "4914",
    place: "Roggwil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Roggwil (BE)",
    community_code: "337",
    latitude: "47.2412",
    longitude: "7.8214",
  },
  {
    country_code: "CH",
    zipcode: "4916",
    place: "Untersteckholz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Langenthal",
    community_code: "329",
    latitude: "47.2076",
    longitude: "7.8445",
  },
  {
    country_code: "CH",
    zipcode: "4917",
    place: "Melchnau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Melchnau",
    community_code: "333",
    latitude: "47.1821",
    longitude: "7.8513",
  },
  {
    country_code: "CH",
    zipcode: "4917",
    place: "Busswil b. Melchnau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Busswil bei Melchnau",
    community_code: "325",
    latitude: "47.1876",
    longitude: "7.8295",
  },
  {
    country_code: "CH",
    zipcode: "4919",
    place: "Reisiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Reisiswil",
    community_code: "336",
    latitude: "47.1651",
    longitude: "7.8463",
  },
  {
    country_code: "CH",
    zipcode: "4922",
    place: "Thunstetten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Thunstetten",
    community_code: "342",
    latitude: "47.2027",
    longitude: "7.7575",
  },
  {
    country_code: "CH",
    zipcode: "4922",
    place: "Bützberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Thunstetten",
    community_code: "342",
    latitude: "47.2141",
    longitude: "7.7431",
  },
  {
    country_code: "CH",
    zipcode: "4923",
    place: "Wynau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wynau",
    community_code: "345",
    latitude: "47.2557",
    longitude: "7.8163",
  },
  {
    country_code: "CH",
    zipcode: "4924",
    place: "Obersteckholz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Obersteckholz",
    community_code: "334",
    latitude: "47.1993",
    longitude: "7.8171",
  },
  {
    country_code: "CH",
    zipcode: "4932",
    place: "Lotzwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Lotzwil",
    community_code: "331",
    latitude: "47.1913",
    longitude: "7.791",
  },
  {
    country_code: "CH",
    zipcode: "4932",
    place: "Gutenburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Madiswil",
    community_code: "332",
    latitude: "47.1838",
    longitude: "7.7948",
  },
  {
    country_code: "CH",
    zipcode: "4933",
    place: "Rütschelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Rütschelen",
    community_code: "340",
    latitude: "47.1711",
    longitude: "7.7709",
  },
  {
    country_code: "CH",
    zipcode: "4934",
    place: "Madiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Madiswil",
    community_code: "332",
    latitude: "47.1646",
    longitude: "7.7986",
  },
  {
    country_code: "CH",
    zipcode: "4935",
    place: "Leimiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Madiswil",
    community_code: "332",
    latitude: "47.1508",
    longitude: "7.7634",
  },
  {
    country_code: "CH",
    zipcode: "4936",
    place: "Kleindietwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Madiswil",
    community_code: "332",
    latitude: "47.1457",
    longitude: "7.7896",
  },
  {
    country_code: "CH",
    zipcode: "4937",
    place: "Ursenbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Ursenbach",
    community_code: "344",
    latitude: "47.137",
    longitude: "7.7719",
  },
  {
    country_code: "CH",
    zipcode: "4938",
    place: "Rohrbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Rohrbach",
    community_code: "338",
    latitude: "47.1352",
    longitude: "7.8133",
  },
  {
    country_code: "CH",
    zipcode: "4938",
    place: "Rohrbachgraben",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Rohrbachgraben",
    community_code: "339",
    latitude: "47.1193",
    longitude: "7.8004",
  },
  {
    country_code: "CH",
    zipcode: "4942",
    place: "Walterswil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Walterswil (BE)",
    community_code: "959",
    latitude: "47.1122",
    longitude: "7.778",
  },
  {
    country_code: "CH",
    zipcode: "4943",
    place: "Oeschenbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Oeschenbach",
    community_code: "335",
    latitude: "47.1029",
    longitude: "7.7476",
  },
  {
    country_code: "CH",
    zipcode: "4944",
    place: "Auswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Auswil",
    community_code: "322",
    latitude: "47.1436",
    longitude: "7.8454",
  },
  {
    country_code: "CH",
    zipcode: "4950",
    place: "Huttwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Huttwil",
    community_code: "954",
    latitude: "47.115",
    longitude: "7.8621",
  },
  {
    country_code: "CH",
    zipcode: "4952",
    place: "Eriswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Eriswil",
    community_code: "953",
    latitude: "47.0782",
    longitude: "7.8515",
  },
  {
    country_code: "CH",
    zipcode: "4953",
    place: "Schwarzenbach (Huttwil)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Huttwil",
    community_code: "954",
    latitude: "47.108",
    longitude: "7.8303",
  },
  {
    country_code: "CH",
    zipcode: "4954",
    place: "Wyssachen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Wyssachen",
    community_code: "960",
    latitude: "47.0785",
    longitude: "7.8292",
  },
  {
    country_code: "CH",
    zipcode: "4955",
    place: "Gondiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Oberaargau",
    province_code: "244",
    community: "Gondiswil",
    community_code: "326",
    latitude: "47.1468",
    longitude: "7.8714",
  },
  {
    country_code: "CH",
    zipcode: "3309",
    place: "Kernenried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Kernenried",
    community_code: "411",
    latitude: "47.0697",
    longitude: "7.5468",
  },
  {
    country_code: "CH",
    zipcode: "3315",
    place: "Bätterkinden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Bätterkinden",
    community_code: "533",
    latitude: "47.1336",
    longitude: "7.541",
  },
  {
    country_code: "CH",
    zipcode: "3315",
    place: "Kräiligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Bätterkinden",
    community_code: "533",
    latitude: "47.1504",
    longitude: "7.5343",
  },
  {
    country_code: "CH",
    zipcode: "3324",
    place: "Mötschwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Mötschwil",
    community_code: "416",
    latitude: "47.0411",
    longitude: "7.5756",
  },
  {
    country_code: "CH",
    zipcode: "3324",
    place: "Hindelbank",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Hindelbank",
    community_code: "409",
    latitude: "47.0427",
    longitude: "7.5414",
  },
  {
    country_code: "CH",
    zipcode: "3325",
    place: "Hettiswil b. Hindelbank",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Krauchthal",
    community_code: "414",
    latitude: "47.027",
    longitude: "7.5382",
  },
  {
    country_code: "CH",
    zipcode: "3326",
    place: "Krauchthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Krauchthal",
    community_code: "414",
    latitude: "47.0096",
    longitude: "7.5664",
  },
  {
    country_code: "CH",
    zipcode: "3400",
    place: "Burgdorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Burgdorf",
    community_code: "404",
    latitude: "47.059",
    longitude: "7.6279",
  },
  {
    country_code: "CH",
    zipcode: "3401",
    place: "Burgdorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Burgdorf",
    community_code: "404",
    latitude: "47.0537",
    longitude: "7.6191",
  },
  {
    country_code: "CH",
    zipcode: "3412",
    place: "Heimiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Heimiswil",
    community_code: "407",
    latitude: "47.0675",
    longitude: "7.6667",
  },
  {
    country_code: "CH",
    zipcode: "3413",
    place: "Kaltacker",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Heimiswil",
    community_code: "407",
    latitude: "47.0778",
    longitude: "7.6701",
  },
  {
    country_code: "CH",
    zipcode: "3414",
    place: "Oberburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Oberburg",
    community_code: "418",
    latitude: "47.0366",
    longitude: "7.6275",
  },
  {
    country_code: "CH",
    zipcode: "3415",
    place: "Schafhausen im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Hasle bei Burgdorf",
    community_code: "406",
    latitude: "47.0174",
    longitude: "7.6577",
  },
  {
    country_code: "CH",
    zipcode: "3415",
    place: "Hasle b. Burgdorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Hasle bei Burgdorf",
    community_code: "406",
    latitude: "47.0144",
    longitude: "7.6511",
  },
  {
    country_code: "CH",
    zipcode: "3415",
    place: "Rüegsauschachen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüegsau",
    community_code: "956",
    latitude: "47.0205",
    longitude: "7.6644",
  },
  {
    country_code: "CH",
    zipcode: "3416",
    place: "Affoltern im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Affoltern im Emmental",
    community_code: "951",
    latitude: "47.0653",
    longitude: "7.7347",
  },
  {
    country_code: "CH",
    zipcode: "3417",
    place: "Rüegsau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüegsau",
    community_code: "956",
    latitude: "47.0381",
    longitude: "7.691",
  },
  {
    country_code: "CH",
    zipcode: "3418",
    place: "Rüegsbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüegsau",
    community_code: "956",
    latitude: "47.0354",
    longitude: "7.6903",
  },
  {
    country_code: "CH",
    zipcode: "3419",
    place: "Biembach im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Hasle bei Burgdorf",
    community_code: "406",
    latitude: "47.0006",
    longitude: "7.6224",
  },
  {
    country_code: "CH",
    zipcode: "3421",
    place: "Lyssach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Oberburg",
    community_code: "418",
    latitude: "47.023",
    longitude: "7.6065",
  },
  {
    country_code: "CH",
    zipcode: "3421",
    place: "Rüti b. Lyssach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüti bei Lyssach",
    community_code: "422",
    latitude: "47.0544",
    longitude: "7.5824",
  },
  {
    country_code: "CH",
    zipcode: "3421",
    place: "Lyssach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lyssach",
    community_code: "415",
    latitude: "47.0645",
    longitude: "7.5823",
  },
  {
    country_code: "CH",
    zipcode: "3422",
    place: "Rüdtligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüdtligen-Alchenflüh",
    community_code: "420",
    latitude: "47.0887",
    longitude: "7.5707",
  },
  {
    country_code: "CH",
    zipcode: "3422",
    place: "Alchenflüh",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüdtligen-Alchenflüh",
    community_code: "420",
    latitude: "47.0847",
    longitude: "7.5811",
  },
  {
    country_code: "CH",
    zipcode: "3422",
    place: "Kirchberg BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Kirchberg (BE)",
    community_code: "412",
    latitude: "47.0854",
    longitude: "7.5829",
  },
  {
    country_code: "CH",
    zipcode: "3423",
    place: "Ersigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Ersigen",
    community_code: "405",
    latitude: "47.0937",
    longitude: "7.5951",
  },
  {
    country_code: "CH",
    zipcode: "3424",
    place: "Niederösch",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Ersigen",
    community_code: "405",
    latitude: "47.0976",
    longitude: "7.6042",
  },
  {
    country_code: "CH",
    zipcode: "3424",
    place: "Oberösch",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Ersigen",
    community_code: "405",
    latitude: "47.1096",
    longitude: "7.608",
  },
  {
    country_code: "CH",
    zipcode: "3425",
    place: "Willadingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Willadingen",
    community_code: "423",
    latitude: "47.1467",
    longitude: "7.6111",
  },
  {
    country_code: "CH",
    zipcode: "3425",
    place: "Koppigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Koppigen",
    community_code: "413",
    latitude: "47.1338",
    longitude: "7.5987",
  },
  {
    country_code: "CH",
    zipcode: "3426",
    place: "Aefligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Aefligen",
    community_code: "401",
    latitude: "47.0957",
    longitude: "7.5517",
  },
  {
    country_code: "CH",
    zipcode: "3427",
    place: "Utzenstorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Utzenstorf",
    community_code: "552",
    latitude: "47.1298",
    longitude: "7.5584",
  },
  {
    country_code: "CH",
    zipcode: "3428",
    place: "Wiler b. Utzenstorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Wiler bei Utzenstorf",
    community_code: "554",
    latitude: "47.1506",
    longitude: "7.5566",
  },
  {
    country_code: "CH",
    zipcode: "3429",
    place: "Hellsau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Hellsau",
    community_code: "408",
    latitude: "47.1437",
    longitude: "7.6483",
  },
  {
    country_code: "CH",
    zipcode: "3429",
    place: "Höchstetten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Höchstetten",
    community_code: "410",
    latitude: "47.1438",
    longitude: "7.6334",
  },
  {
    country_code: "CH",
    zipcode: "3432",
    place: "Lützelflüh-Goldbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lützelflüh",
    community_code: "955",
    latitude: "47.0076",
    longitude: "7.6917",
  },
  {
    country_code: "CH",
    zipcode: "3433",
    place: "Schwanden im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüderswil",
    community_code: "905",
    latitude: "46.988",
    longitude: "7.6952",
  },
  {
    country_code: "CH",
    zipcode: "3435",
    place: "Ramsei",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lützelflüh",
    community_code: "955",
    latitude: "46.9983",
    longitude: "7.7118",
  },
  {
    country_code: "CH",
    zipcode: "3436",
    place: "Zollbrück",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüderswil",
    community_code: "905",
    latitude: "46.9745",
    longitude: "7.7438",
  },
  {
    country_code: "CH",
    zipcode: "3437",
    place: "Rüderswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rüderswil",
    community_code: "905",
    latitude: "46.9837",
    longitude: "7.7217",
  },
  {
    country_code: "CH",
    zipcode: "3438",
    place: "Lauperswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lauperswil",
    community_code: "903",
    latitude: "46.9656",
    longitude: "7.7421",
  },
  {
    country_code: "CH",
    zipcode: "3439",
    place: "Ranflüh",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lützelflüh",
    community_code: "955",
    latitude: "46.9876",
    longitude: "7.7402",
  },
  {
    country_code: "CH",
    zipcode: "3452",
    place: "Grünenmatt",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lützelflüh",
    community_code: "955",
    latitude: "47.009",
    longitude: "7.7234",
  },
  {
    country_code: "CH",
    zipcode: "3453",
    place: "Heimisbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Trachselwald",
    community_code: "958",
    latitude: "47.0125",
    longitude: "7.7605",
  },
  {
    country_code: "CH",
    zipcode: "3454",
    place: "Sumiswald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Sumiswald",
    community_code: "957",
    latitude: "47.0275",
    longitude: "7.7453",
  },
  {
    country_code: "CH",
    zipcode: "3455",
    place: "Grünen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Sumiswald",
    community_code: "957",
    latitude: "47.0266",
    longitude: "7.7432",
  },
  {
    country_code: "CH",
    zipcode: "3456",
    place: "Trachselwald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Trachselwald",
    community_code: "958",
    latitude: "47.017",
    longitude: "7.7364",
  },
  {
    country_code: "CH",
    zipcode: "3457",
    place: "Wasen im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Sumiswald",
    community_code: "957",
    latitude: "47.0427",
    longitude: "7.7972",
  },
  {
    country_code: "CH",
    zipcode: "3462",
    place: "Weier im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Affoltern im Emmental",
    community_code: "951",
    latitude: "47.0624",
    longitude: "7.7506",
  },
  {
    country_code: "CH",
    zipcode: "3463",
    place: "Häusernmoos im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Affoltern im Emmental",
    community_code: "951",
    latitude: "47.0807",
    longitude: "7.7502",
  },
  {
    country_code: "CH",
    zipcode: "3465",
    place: "Dürrenroth",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Dürrenroth",
    community_code: "952",
    latitude: "47.0882",
    longitude: "7.7913",
  },
  {
    country_code: "CH",
    zipcode: "3472",
    place: "Rumendingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Rumendingen",
    community_code: "421",
    latitude: "47.1059",
    longitude: "7.6394",
  },
  {
    country_code: "CH",
    zipcode: "3472",
    place: "Wynigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Wynigen",
    community_code: "424",
    latitude: "47.1059",
    longitude: "7.6668",
  },
  {
    country_code: "CH",
    zipcode: "3473",
    place: "Alchenstorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Alchenstorf",
    community_code: "402",
    latitude: "47.1254",
    longitude: "7.6379",
  },
  {
    country_code: "CH",
    zipcode: "3474",
    place: "Rüedisbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Wynigen",
    community_code: "424",
    latitude: "47.1208",
    longitude: "7.7044",
  },
  {
    country_code: "CH",
    zipcode: "3534",
    place: "Signau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Signau",
    community_code: "907",
    latitude: "46.9194",
    longitude: "7.7242",
  },
  {
    country_code: "CH",
    zipcode: "3535",
    place: "Schüpbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Signau",
    community_code: "907",
    latitude: "46.9261",
    longitude: "7.74",
  },
  {
    country_code: "CH",
    zipcode: "3536",
    place: "Aeschau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Eggiwil",
    community_code: "901",
    latitude: "46.9095",
    longitude: "7.7618",
  },
  {
    country_code: "CH",
    zipcode: "3537",
    place: "Eggiwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Eggiwil",
    community_code: "901",
    latitude: "46.8757",
    longitude: "7.7957",
  },
  {
    country_code: "CH",
    zipcode: "3538",
    place: "Röthenbach im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Röthenbach im Emmental",
    community_code: "904",
    latitude: "46.8542",
    longitude: "7.7425",
  },
  {
    country_code: "CH",
    zipcode: "3543",
    place: "Emmenmatt",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Lauperswil",
    community_code: "903",
    latitude: "46.9487",
    longitude: "7.7493",
  },
  {
    country_code: "CH",
    zipcode: "3550",
    place: "Langnau im Emmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Langnau im Emmental",
    community_code: "902",
    latitude: "46.9394",
    longitude: "7.7874",
  },
  {
    country_code: "CH",
    zipcode: "3551",
    place: "Oberfrittenbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Langnau im Emmental",
    community_code: "902",
    latitude: "46.9297",
    longitude: "7.7963",
  },
  {
    country_code: "CH",
    zipcode: "3552",
    place: "Bärau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Langnau im Emmental",
    community_code: "902",
    latitude: "46.9301",
    longitude: "7.8114",
  },
  {
    country_code: "CH",
    zipcode: "3553",
    place: "Gohl",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Langnau im Emmental",
    community_code: "902",
    latitude: "46.9554",
    longitude: "7.8062",
  },
  {
    country_code: "CH",
    zipcode: "3555",
    place: "Trubschachen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Trubschachen",
    community_code: "909",
    latitude: "46.9223",
    longitude: "7.8452",
  },
  {
    country_code: "CH",
    zipcode: "3556",
    place: "Trub",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Trub",
    community_code: "908",
    latitude: "46.9417",
    longitude: "7.88",
  },
  {
    country_code: "CH",
    zipcode: "3557",
    place: "Fankhaus (Trub)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Trub",
    community_code: "908",
    latitude: "46.9706",
    longitude: "7.9174",
  },
  {
    country_code: "CH",
    zipcode: "3618",
    place: "Süderen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Röthenbach im Emmental",
    community_code: "904",
    latitude: "46.9885",
    longitude: "7.7386",
  },
  {
    country_code: "CH",
    zipcode: "4564",
    place: "Zielebach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Zielebach",
    community_code: "556",
    latitude: "47.158",
    longitude: "7.5729",
  },
  {
    country_code: "CH",
    zipcode: "6197",
    place: "Schangnau",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Emmental District",
    province_code: "245",
    community: "Schangnau",
    community_code: "906",
    latitude: "46.8278",
    longitude: "7.8599",
  },
  {
    country_code: "CH",
    zipcode: "1595",
    place: "Clavaleyres",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Clavaleyres",
    community_code: "661",
    latitude: "46.8991",
    longitude: "7.0928",
  },
  {
    country_code: "CH",
    zipcode: "1738",
    place: "Sangernboden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Guggisberg",
    community_code: "852",
    latitude: "46.7133",
    longitude: "7.3519",
  },
  {
    country_code: "CH",
    zipcode: "1797",
    place: "Münchenwiler",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Münchenwiler",
    community_code: "669",
    latitude: "46.9133",
    longitude: "7.1256",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 94",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 60 UPD",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 65",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 14",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 9",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 6",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 5",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 13",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 31",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 8",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 22",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3000",
    place: "Bern 15",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3001",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3002",
    place: "Bern PostFinance",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3003",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3004",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3005",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3006",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3007",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3008",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3010",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3011",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3011",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3012",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3013",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3014",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3015",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3018",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3019",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3020",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3024",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3027",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9481",
    longitude: "7.4474",
  },
  {
    country_code: "CH",
    zipcode: "3029",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3030",
    place: "Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3032",
    place: "Hinterkappelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9673",
    longitude: "7.3779",
  },
  {
    country_code: "CH",
    zipcode: "3033",
    place: "Wohlen b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9877",
    longitude: "7.3481",
  },
  {
    country_code: "CH",
    zipcode: "3034",
    place: "Murzelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9831",
    longitude: "7.3155",
  },
  {
    country_code: "CH",
    zipcode: "3037",
    place: "Herrenschwanden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kirchlindach",
    community_code: "354",
    latitude: "46.9776",
    longitude: "7.4175",
  },
  {
    country_code: "CH",
    zipcode: "3038",
    place: "Kirchlindach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kirchlindach",
    community_code: "354",
    latitude: "46.9997",
    longitude: "7.4173",
  },
  {
    country_code: "CH",
    zipcode: "3039",
    place: "Bern PF OC",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3040",
    place: "Bern Verarb.zentr.",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3041",
    place: "Bern UBS",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3042",
    place: "Ortschwaben",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Meikirch",
    community_code: "307",
    latitude: "46.9917",
    longitude: "7.4018",
  },
  {
    country_code: "CH",
    zipcode: "3043",
    place: "Uettligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9873",
    longitude: "7.3856",
  },
  {
    country_code: "CH",
    zipcode: "3044",
    place: "Innerberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9975",
    longitude: "7.3073",
  },
  {
    country_code: "CH",
    zipcode: "3045",
    place: "Meikirch",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Meikirch",
    community_code: "307",
    latitude: "47.0072",
    longitude: "7.3664",
  },
  {
    country_code: "CH",
    zipcode: "3046",
    place: "Wahlendorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Meikirch",
    community_code: "307",
    latitude: "47.0091",
    longitude: "7.3388",
  },
  {
    country_code: "CH",
    zipcode: "3047",
    place: "Bremgarten b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bremgarten bei Bern",
    community_code: "353",
    latitude: "46.978",
    longitude: "7.4385",
  },
  {
    country_code: "CH",
    zipcode: "3048",
    place: "Worblaufen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ittigen",
    community_code: "362",
    latitude: "46.9797",
    longitude: "7.4581",
  },
  {
    country_code: "CH",
    zipcode: "3049",
    place: "Säriswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wohlen bei Bern",
    community_code: "360",
    latitude: "46.9918",
    longitude: "7.3342",
  },
  {
    country_code: "CH",
    zipcode: "3050",
    place: "Bern Swisscom",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bern",
    community_code: "351",
    latitude: "46.9476",
    longitude: "7.4065",
  },
  {
    country_code: "CH",
    zipcode: "3052",
    place: "Zollikofen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Zollikofen",
    community_code: "361",
    latitude: "46.999",
    longitude: "7.4581",
  },
  {
    country_code: "CH",
    zipcode: "3053",
    place: "Münchenbuchsee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Münchenbuchsee",
    community_code: "546",
    latitude: "47.0218",
    longitude: "7.4504",
  },
  {
    country_code: "CH",
    zipcode: "3053",
    place: "Deisswil b. Münchenbuchsee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Deisswil bei Münchenbuchsee",
    community_code: "535",
    latitude: "47.0372",
    longitude: "7.4533",
  },
  {
    country_code: "CH",
    zipcode: "3053",
    place: "Wiggiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wiggiswil",
    community_code: "553",
    latitude: "47.0313",
    longitude: "7.4709",
  },
  {
    country_code: "CH",
    zipcode: "3053",
    place: "Diemerswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Diemerswil",
    community_code: "536",
    latitude: "47.0191",
    longitude: "7.4245",
  },
  {
    country_code: "CH",
    zipcode: "3063",
    place: "Ittigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ittigen",
    community_code: "362",
    latitude: "46.9743",
    longitude: "7.4828",
  },
  {
    country_code: "CH",
    zipcode: "3065",
    place: "Bolligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bolligen",
    community_code: "352",
    latitude: "46.9751",
    longitude: "7.497",
  },
  {
    country_code: "CH",
    zipcode: "3066",
    place: "Stettlen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Stettlen",
    community_code: "358",
    latitude: "46.9583",
    longitude: "7.5251",
  },
  {
    country_code: "CH",
    zipcode: "3067",
    place: "Boll",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Vechigen",
    community_code: "359",
    latitude: "46.9542",
    longitude: "7.5486",
  },
  {
    country_code: "CH",
    zipcode: "3068",
    place: "Utzigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Vechigen",
    community_code: "359",
    latitude: "46.9621",
    longitude: "7.5657",
  },
  {
    country_code: "CH",
    zipcode: "3071",
    place: "Ostermundigen KATA",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ostermundigen",
    community_code: "363",
    latitude: "46.9559",
    longitude: "7.4956",
  },
  {
    country_code: "CH",
    zipcode: "3072",
    place: "Ostermundigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ostermundigen",
    community_code: "363",
    latitude: "46.9569",
    longitude: "7.4902",
  },
  {
    country_code: "CH",
    zipcode: "3073",
    place: "Gümligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Muri bei Bern",
    community_code: "356",
    latitude: "46.9345",
    longitude: "7.5093",
  },
  {
    country_code: "CH",
    zipcode: "3074",
    place: "Muri b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Muri bei Bern",
    community_code: "356",
    latitude: "46.9312",
    longitude: "7.4866",
  },
  {
    country_code: "CH",
    zipcode: "3075",
    place: "Rüfenacht BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Worb",
    community_code: "627",
    latitude: "46.9308",
    longitude: "7.5323",
  },
  {
    country_code: "CH",
    zipcode: "3075",
    place: "Vielbringen b. Worb",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Worb",
    community_code: "627",
    latitude: "46.9308",
    longitude: "7.5323",
  },
  {
    country_code: "CH",
    zipcode: "3076",
    place: "Worb",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Worb",
    community_code: "627",
    latitude: "46.9298",
    longitude: "7.5631",
  },
  {
    country_code: "CH",
    zipcode: "3077",
    place: "Enggistein",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Worb",
    community_code: "627",
    latitude: "46.9335",
    longitude: "7.5979",
  },
  {
    country_code: "CH",
    zipcode: "3078",
    place: "Richigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Worb",
    community_code: "627",
    latitude: "46.9173",
    longitude: "7.5842",
  },
  {
    country_code: "CH",
    zipcode: "3082",
    place: "Schlosswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schlosswil",
    community_code: "624",
    latitude: "46.9087",
    longitude: "7.6117",
  },
  {
    country_code: "CH",
    zipcode: "3083",
    place: "Trimstein",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Münsingen",
    community_code: "616",
    latitude: "46.9048",
    longitude: "7.5835",
  },
  {
    country_code: "CH",
    zipcode: "3084",
    place: "Wabern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9299",
    longitude: "7.4508",
  },
  {
    country_code: "CH",
    zipcode: "3085",
    place: "Wabern Weihnachten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9043",
    longitude: "7.3978",
  },
  {
    country_code: "CH",
    zipcode: "3086",
    place: "Englisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wald (BE)",
    community_code: "888",
    latitude: "46.8992",
    longitude: "7.4693",
  },
  {
    country_code: "CH",
    zipcode: "3086",
    place: "Zimmerwald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wald (BE)",
    community_code: "888",
    latitude: "46.8809",
    longitude: "7.4771",
  },
  {
    country_code: "CH",
    zipcode: "3087",
    place: "Niedermuhlern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Niedermuhlern",
    community_code: "877",
    latitude: "46.8576",
    longitude: "7.4618",
  },
  {
    country_code: "CH",
    zipcode: "3088",
    place: "Oberbütschel",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüeggisberg",
    community_code: "880",
    latitude: "46.8389",
    longitude: "7.4641",
  },
  {
    country_code: "CH",
    zipcode: "3088",
    place: "Rüeggisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüeggisberg",
    community_code: "880",
    latitude: "46.8222",
    longitude: "7.4389",
  },
  {
    country_code: "CH",
    zipcode: "3089",
    place: "Hinterfultigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüeggisberg",
    community_code: "880",
    latitude: "46.8398",
    longitude: "7.4009",
  },
  {
    country_code: "CH",
    zipcode: "3095",
    place: "Spiegel b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9248",
    longitude: "7.4361",
  },
  {
    country_code: "CH",
    zipcode: "3096",
    place: "Oberbalm",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberbalm",
    community_code: "357",
    latitude: "46.8736",
    longitude: "7.4028",
  },
  {
    country_code: "CH",
    zipcode: "3097",
    place: "Liebefeld",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9258",
    longitude: "7.4145",
  },
  {
    country_code: "CH",
    zipcode: "3098",
    place: "Schliern b. Köniz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9244",
    longitude: "7.4146",
  },
  {
    country_code: "CH",
    zipcode: "3098",
    place: "Köniz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9244",
    longitude: "7.4146",
  },
  {
    country_code: "CH",
    zipcode: "3099",
    place: "Rüti b. Riggisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Riggisberg",
    community_code: "879",
    latitude: "46.7824",
    longitude: "7.4602",
  },
  {
    country_code: "CH",
    zipcode: "3110",
    place: "Münsingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Münsingen",
    community_code: "616",
    latitude: "46.873",
    longitude: "7.561",
  },
  {
    country_code: "CH",
    zipcode: "3111",
    place: "Tägertschi",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Münsingen",
    community_code: "616",
    latitude: "46.8841",
    longitude: "7.5657",
  },
  {
    country_code: "CH",
    zipcode: "3112",
    place: "Allmendingen b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Allmendingen",
    community_code: "630",
    latitude: "46.9147",
    longitude: "7.5244",
  },
  {
    country_code: "CH",
    zipcode: "3113",
    place: "Rubigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rubigen",
    community_code: "623",
    latitude: "46.8987",
    longitude: "7.5446",
  },
  {
    country_code: "CH",
    zipcode: "3114",
    place: "Wichtrach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wichtrach",
    community_code: "632",
    latitude: "46.8501",
    longitude: "7.5775",
  },
  {
    country_code: "CH",
    zipcode: "3115",
    place: "Gerzensee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Gerzensee",
    community_code: "866",
    latitude: "46.8402",
    longitude: "7.545",
  },
  {
    country_code: "CH",
    zipcode: "3116",
    place: "Kirchdorf BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kirchdorf (BE)",
    community_code: "872",
    latitude: "46.8209",
    longitude: "7.5485",
  },
  {
    country_code: "CH",
    zipcode: "3116",
    place: "Noflen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Noflen",
    community_code: "878",
    latitude: "46.8056",
    longitude: "7.5433",
  },
  {
    country_code: "CH",
    zipcode: "3116",
    place: "Mühledorf BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mühledorf (BE)",
    community_code: "875",
    latitude: "46.828",
    longitude: "7.5299",
  },
  {
    country_code: "CH",
    zipcode: "3122",
    place: "Kehrsatz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kehrsatz",
    community_code: "870",
    latitude: "46.9103",
    longitude: "7.471",
  },
  {
    country_code: "CH",
    zipcode: "3123",
    place: "Belp",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Belp",
    community_code: "861",
    latitude: "46.8913",
    longitude: "7.4983",
  },
  {
    country_code: "CH",
    zipcode: "3124",
    place: "Belpberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Belp",
    community_code: "861",
    latitude: "46.8673",
    longitude: "7.5213",
  },
  {
    country_code: "CH",
    zipcode: "3125",
    place: "Toffen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Toffen",
    community_code: "884",
    latitude: "46.8603",
    longitude: "7.4922",
  },
  {
    country_code: "CH",
    zipcode: "3126",
    place: "Kaufdorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kaufdorf",
    community_code: "869",
    latitude: "46.8403",
    longitude: "7.4968",
  },
  {
    country_code: "CH",
    zipcode: "3126",
    place: "Gelterfingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Gelterfingen",
    community_code: "865",
    latitude: "46.8445",
    longitude: "7.5156",
  },
  {
    country_code: "CH",
    zipcode: "3127",
    place: "Mühlethurnen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mühlethurnen",
    community_code: "876",
    latitude: "46.8135",
    longitude: "7.5088",
  },
  {
    country_code: "CH",
    zipcode: "3127",
    place: "Lohnstorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Lohnstorf",
    community_code: "874",
    latitude: "46.8077",
    longitude: "7.5099",
  },
  {
    country_code: "CH",
    zipcode: "3128",
    place: "Rümligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rümligen",
    community_code: "881",
    latitude: "46.8217",
    longitude: "7.4772",
  },
  {
    country_code: "CH",
    zipcode: "3128",
    place: "Kirchenthurnen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kirchenthurnen",
    community_code: "873",
    latitude: "46.8238",
    longitude: "7.5005",
  },
  {
    country_code: "CH",
    zipcode: "3132",
    place: "Riggisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Riggisberg",
    community_code: "879",
    latitude: "46.8103",
    longitude: "7.4801",
  },
  {
    country_code: "CH",
    zipcode: "3144",
    place: "Gasel",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9022",
    longitude: "7.4018",
  },
  {
    country_code: "CH",
    zipcode: "3145",
    place: "Niederscherli",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.8851",
    longitude: "7.3938",
  },
  {
    country_code: "CH",
    zipcode: "3147",
    place: "Mittelhäusern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.8778",
    longitude: "7.3691",
  },
  {
    country_code: "CH",
    zipcode: "3148",
    place: "Lanzenhäusern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schwarzenburg",
    community_code: "855",
    latitude: "46.8436",
    longitude: "7.349",
  },
  {
    country_code: "CH",
    zipcode: "3150",
    place: "Schwarzenburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schwarzenburg",
    community_code: "855",
    latitude: "46.8181",
    longitude: "7.3421",
  },
  {
    country_code: "CH",
    zipcode: "3152",
    place: "Mamishaus",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schwarzenburg",
    community_code: "855",
    latitude: "46.8101",
    longitude: "7.3796",
  },
  {
    country_code: "CH",
    zipcode: "3153",
    place: "Rüschegg Gambach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüschegg",
    community_code: "853",
    latitude: "46.7789",
    longitude: "7.3786",
  },
  {
    country_code: "CH",
    zipcode: "3154",
    place: "Rüschegg Heubach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüschegg",
    community_code: "853",
    latitude: "46.7832",
    longitude: "7.4083",
  },
  {
    country_code: "CH",
    zipcode: "3155",
    place: "Helgisried-Rohrbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Rüeggisberg",
    community_code: "880",
    latitude: "46.8176",
    longitude: "7.4284",
  },
  {
    country_code: "CH",
    zipcode: "3156",
    place: "Riffenmatt",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Guggisberg",
    community_code: "852",
    latitude: "46.7662",
    longitude: "7.3529",
  },
  {
    country_code: "CH",
    zipcode: "3157",
    place: "Milken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schwarzenburg",
    community_code: "855",
    latitude: "46.7937",
    longitude: "7.359",
  },
  {
    country_code: "CH",
    zipcode: "3158",
    place: "Guggisberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Guggisberg",
    community_code: "852",
    latitude: "46.7676",
    longitude: "7.3295",
  },
  {
    country_code: "CH",
    zipcode: "3159",
    place: "Riedstätt",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Guggisberg",
    community_code: "852",
    latitude: "46.7841",
    longitude: "7.3194",
  },
  {
    country_code: "CH",
    zipcode: "3172",
    place: "Niederwangen b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9043",
    longitude: "7.3977",
  },
  {
    country_code: "CH",
    zipcode: "3173",
    place: "Oberwangen b. Bern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.9043",
    longitude: "7.3977",
  },
  {
    country_code: "CH",
    zipcode: "3174",
    place: "Thörishaus",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Köniz",
    community_code: "355",
    latitude: "46.8939",
    longitude: "7.3513",
  },
  {
    country_code: "CH",
    zipcode: "3176",
    place: "Neuenegg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Neuenegg",
    community_code: "670",
    latitude: "46.8949",
    longitude: "7.3047",
  },
  {
    country_code: "CH",
    zipcode: "3177",
    place: "Laupen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Laupen",
    community_code: "667",
    latitude: "46.9021",
    longitude: "7.2397",
  },
  {
    country_code: "CH",
    zipcode: "3179",
    place: "Kriechenwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kriechenwil",
    community_code: "666",
    latitude: "46.9097",
    longitude: "7.2196",
  },
  {
    country_code: "CH",
    zipcode: "3183",
    place: "Albligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Schwarzenburg",
    community_code: "855",
    latitude: "46.8523",
    longitude: "7.3181",
  },
  {
    country_code: "CH",
    zipcode: "3202",
    place: "Frauenkappelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Frauenkappelen",
    community_code: "663",
    latitude: "46.9542",
    longitude: "7.3384",
  },
  {
    country_code: "CH",
    zipcode: "3203",
    place: "Mühleberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mühleberg",
    community_code: "668",
    latitude: "46.9547",
    longitude: "7.261",
  },
  {
    country_code: "CH",
    zipcode: "3204",
    place: "Rosshäusern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mühleberg",
    community_code: "668",
    latitude: "46.9332",
    longitude: "7.2762",
  },
  {
    country_code: "CH",
    zipcode: "3205",
    place: "Gümmenen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mühleberg",
    community_code: "668",
    latitude: "46.9456",
    longitude: "7.2409",
  },
  {
    country_code: "CH",
    zipcode: "3206",
    place: "Ferenbalm",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ferenbalm",
    community_code: "662",
    latitude: "46.9488",
    longitude: "7.2112",
  },
  {
    country_code: "CH",
    zipcode: "3206",
    place: "Gammen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ferenbalm",
    community_code: "662",
    latitude: "46.9208",
    longitude: "7.228",
  },
  {
    country_code: "CH",
    zipcode: "3206",
    place: "Rizenbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ferenbalm",
    community_code: "662",
    latitude: "46.944",
    longitude: "7.2232",
  },
  {
    country_code: "CH",
    zipcode: "3206",
    place: "Biberen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Ferenbalm",
    community_code: "662",
    latitude: "46.9404",
    longitude: "7.213",
  },
  {
    country_code: "CH",
    zipcode: "3207",
    place: "Golaten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Golaten",
    community_code: "664",
    latitude: "46.9844",
    longitude: "7.2352",
  },
  {
    country_code: "CH",
    zipcode: "3207",
    place: "Wileroltigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Wileroltigen",
    community_code: "671",
    latitude: "46.9709",
    longitude: "7.2392",
  },
  {
    country_code: "CH",
    zipcode: "3208",
    place: "Gurbrü",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Gurbrü",
    community_code: "665",
    latitude: "46.9648",
    longitude: "7.2153",
  },
  {
    country_code: "CH",
    zipcode: "3302",
    place: "Moosseedorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Moosseedorf",
    community_code: "544",
    latitude: "47.0168",
    longitude: "7.4839",
  },
  {
    country_code: "CH",
    zipcode: "3303",
    place: "Jegenstorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Jegenstorf",
    community_code: "540",
    latitude: "47.048",
    longitude: "7.5079",
  },
  {
    country_code: "CH",
    zipcode: "3303",
    place: "Münchringen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Jegenstorf",
    community_code: "540",
    latitude: "47.0477",
    longitude: "7.5247",
  },
  {
    country_code: "CH",
    zipcode: "3303",
    place: "Ballmoos",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Jegenstorf",
    community_code: "540",
    latitude: "47.0405",
    longitude: "7.4759",
  },
  {
    country_code: "CH",
    zipcode: "3303",
    place: "Zuzwil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Zuzwil (BE)",
    community_code: "557",
    latitude: "47.0511",
    longitude: "7.4681",
  },
  {
    country_code: "CH",
    zipcode: "3305",
    place: "Scheunen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Jegenstorf",
    community_code: "540",
    latitude: "47.0693",
    longitude: "7.4583",
  },
  {
    country_code: "CH",
    zipcode: "3305",
    place: "Iffwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Iffwil",
    community_code: "541",
    latitude: "47.064",
    longitude: "7.4829",
  },
  {
    country_code: "CH",
    zipcode: "3306",
    place: "Etzelkofen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.0845",
    longitude: "7.4806",
  },
  {
    country_code: "CH",
    zipcode: "3308",
    place: "Grafenried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.0765",
    longitude: "7.5147",
  },
  {
    country_code: "CH",
    zipcode: "3309",
    place: "Zauggenried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.0727",
    longitude: "7.5355",
  },
  {
    country_code: "CH",
    zipcode: "3312",
    place: "Fraubrunnen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.0862",
    longitude: "7.5273",
  },
  {
    country_code: "CH",
    zipcode: "3313",
    place: "Büren zum Hof",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.0977",
    longitude: "7.5135",
  },
  {
    country_code: "CH",
    zipcode: "3314",
    place: "Schalunen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.1105",
    longitude: "7.5263",
  },
  {
    country_code: "CH",
    zipcode: "3317",
    place: "Limpach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.1077",
    longitude: "7.4979",
  },
  {
    country_code: "CH",
    zipcode: "3317",
    place: "Mülchi",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Fraubrunnen",
    community_code: "538",
    latitude: "47.1018",
    longitude: "7.4736",
  },
  {
    country_code: "CH",
    zipcode: "3321",
    place: "Schönbühl EKZ",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Moosseedorf",
    community_code: "544",
    latitude: "47.0168",
    longitude: "7.4839",
  },
  {
    country_code: "CH",
    zipcode: "3322",
    place: "Mattstetten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mattstetten",
    community_code: "543",
    latitude: "47.0282",
    longitude: "7.5189",
  },
  {
    country_code: "CH",
    zipcode: "3322",
    place: "Urtenen-Schönbühl",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Urtenen-Schönbühl",
    community_code: "551",
    latitude: "47.0209",
    longitude: "7.4999",
  },
  {
    country_code: "CH",
    zipcode: "3323",
    place: "Bäriswil BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bäriswil",
    community_code: "403",
    latitude: "47.0195",
    longitude: "7.5271",
  },
  {
    country_code: "CH",
    zipcode: "3434",
    place: "Obergoldbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Landiswil",
    community_code: "613",
    latitude: "46.962",
    longitude: "7.6739",
  },
  {
    country_code: "CH",
    zipcode: "3434",
    place: "Landiswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Landiswil",
    community_code: "613",
    latitude: "46.9582",
    longitude: "7.6803",
  },
  {
    country_code: "CH",
    zipcode: "3503",
    place: "Gysenstein",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Konolfingen",
    community_code: "612",
    latitude: "46.8915",
    longitude: "7.5924",
  },
  {
    country_code: "CH",
    zipcode: "3504",
    place: "Oberhünigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberhünigen",
    community_code: "629",
    latitude: "46.881",
    longitude: "7.6598",
  },
  {
    country_code: "CH",
    zipcode: "3504",
    place: "Niederhünigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Niederhünigen",
    community_code: "617",
    latitude: "46.879",
    longitude: "7.6386",
  },
  {
    country_code: "CH",
    zipcode: "3506",
    place: "Grosshöchstetten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Grosshöchstetten",
    community_code: "608",
    latitude: "46.9067",
    longitude: "7.6378",
  },
  {
    country_code: "CH",
    zipcode: "3507",
    place: "Biglen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Biglen",
    community_code: "603",
    latitude: "46.9263",
    longitude: "7.6251",
  },
  {
    country_code: "CH",
    zipcode: "3508",
    place: "Arni BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Arni (BE)",
    community_code: "602",
    latitude: "46.9352",
    longitude: "7.6647",
  },
  {
    country_code: "CH",
    zipcode: "3510",
    place: "Freimettigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Freimettigen",
    community_code: "607",
    latitude: "46.8664",
    longitude: "7.6311",
  },
  {
    country_code: "CH",
    zipcode: "3510",
    place: "Konolfingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Konolfingen",
    community_code: "612",
    latitude: "46.8791",
    longitude: "7.6201",
  },
  {
    country_code: "CH",
    zipcode: "3510",
    place: "Häutligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Häutligen",
    community_code: "609",
    latitude: "46.8576",
    longitude: "7.6067",
  },
  {
    country_code: "CH",
    zipcode: "3512",
    place: "Walkringen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Walkringen",
    community_code: "626",
    latitude: "46.9486",
    longitude: "7.6204",
  },
  {
    country_code: "CH",
    zipcode: "3513",
    place: "Bigenthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Walkringen",
    community_code: "626",
    latitude: "46.9661",
    longitude: "7.624",
  },
  {
    country_code: "CH",
    zipcode: "3531",
    place: "Oberthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberthal",
    community_code: "620",
    latitude: "46.9184",
    longitude: "7.6781",
  },
  {
    country_code: "CH",
    zipcode: "3532",
    place: "Zäziwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Zäziwil",
    community_code: "628",
    latitude: "46.902",
    longitude: "7.6618",
  },
  {
    country_code: "CH",
    zipcode: "3532",
    place: "Mirchel",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Mirchel",
    community_code: "615",
    latitude: "46.8946",
    longitude: "7.6499",
  },
  {
    country_code: "CH",
    zipcode: "3533",
    place: "Bowil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Bowil",
    community_code: "605",
    latitude: "46.893",
    longitude: "7.6976",
  },
  {
    country_code: "CH",
    zipcode: "3629",
    place: "Jaberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Jaberg",
    community_code: "868",
    latitude: "46.818",
    longitude: "7.5692",
  },
  {
    country_code: "CH",
    zipcode: "3629",
    place: "Kiesen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Kiesen",
    community_code: "611",
    latitude: "46.8196",
    longitude: "7.584",
  },
  {
    country_code: "CH",
    zipcode: "3629",
    place: "Oppligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oppligen",
    community_code: "622",
    latitude: "46.8184",
    longitude: "7.6007",
  },
  {
    country_code: "CH",
    zipcode: "3671",
    place: "Brenzikofen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Brenzikofen",
    community_code: "606",
    latitude: "46.8181",
    longitude: "7.6171",
  },
  {
    country_code: "CH",
    zipcode: "3671",
    place: "Herbligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Herbligen",
    community_code: "610",
    latitude: "46.8299",
    longitude: "7.6063",
  },
  {
    country_code: "CH",
    zipcode: "3672",
    place: "Oberdiessbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberdiessbach",
    community_code: "619",
    latitude: "46.8395",
    longitude: "7.6196",
  },
  {
    country_code: "CH",
    zipcode: "3672",
    place: "Aeschlen b. Oberdiessbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberdiessbach",
    community_code: "619",
    latitude: "46.8395",
    longitude: "7.6196",
  },
  {
    country_code: "CH",
    zipcode: "3673",
    place: "Linden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Linden",
    community_code: "614",
    latitude: "46.8487",
    longitude: "7.6749",
  },
  {
    country_code: "CH",
    zipcode: "3674",
    place: "Bleiken b. Oberdiessbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Bern-Mittelland District",
    province_code: "246",
    community: "Oberdiessbach",
    community_code: "619",
    latitude: "46.8341",
    longitude: "7.6295",
  },
  {
    country_code: "CH",
    zipcode: "3600",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7512",
    longitude: "7.6217",
  },
  {
    country_code: "CH",
    zipcode: "3602",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7466",
    longitude: "7.6222",
  },
  {
    country_code: "CH",
    zipcode: "3603",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7512",
    longitude: "7.6217",
  },
  {
    country_code: "CH",
    zipcode: "3604",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7512",
    longitude: "7.6217",
  },
  {
    country_code: "CH",
    zipcode: "3607",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7466",
    longitude: "7.6222",
  },
  {
    country_code: "CH",
    zipcode: "3608",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7512",
    longitude: "7.6217",
  },
  {
    country_code: "CH",
    zipcode: "3609",
    place: "Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.7466",
    longitude: "7.6222",
  },
  {
    country_code: "CH",
    zipcode: "3612",
    place: "Steffisburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Steffisburg",
    community_code: "939",
    latitude: "46.7781",
    longitude: "7.6325",
  },
  {
    country_code: "CH",
    zipcode: "3613",
    place: "Steffisburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Steffisburg",
    community_code: "939",
    latitude: "46.7781",
    longitude: "7.6325",
  },
  {
    country_code: "CH",
    zipcode: "3614",
    place: "Unterlangenegg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Unterlangenegg",
    community_code: "945",
    latitude: "46.7993",
    longitude: "7.7012",
  },
  {
    country_code: "CH",
    zipcode: "3615",
    place: "Heimenschwand",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Buchholterberg",
    community_code: "923",
    latitude: "46.8287",
    longitude: "7.6947",
  },
  {
    country_code: "CH",
    zipcode: "3616",
    place: "Schwarzenegg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Unterlangenegg",
    community_code: "945",
    latitude: "46.7953",
    longitude: "7.7168",
  },
  {
    country_code: "CH",
    zipcode: "3617",
    place: "Fahrni b. Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Fahrni",
    community_code: "925",
    latitude: "46.794",
    longitude: "7.655",
  },
  {
    country_code: "CH",
    zipcode: "3619",
    place: "Eriz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Eriz",
    community_code: "924",
    latitude: "46.7877",
    longitude: "7.771",
  },
  {
    country_code: "CH",
    zipcode: "3619",
    place: "Innereriz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Eriz",
    community_code: "924",
    latitude: "46.7883",
    longitude: "7.8239",
  },
  {
    country_code: "CH",
    zipcode: "3622",
    place: "Homberg b. Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Homberg",
    community_code: "931",
    latitude: "46.7733",
    longitude: "7.6768",
  },
  {
    country_code: "CH",
    zipcode: "3623",
    place: "Horrenbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Horrenbach-Buchen",
    community_code: "932",
    latitude: "46.7748",
    longitude: "7.7824",
  },
  {
    country_code: "CH",
    zipcode: "3623",
    place: "Teuffenthal b. Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Teuffenthal (BE)",
    community_code: "940",
    latitude: "46.7658",
    longitude: "7.7127",
  },
  {
    country_code: "CH",
    zipcode: "3623",
    place: "Buchen BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Horrenbach-Buchen",
    community_code: "932",
    latitude: "46.7748",
    longitude: "7.7824",
  },
  {
    country_code: "CH",
    zipcode: "3624",
    place: "Goldiwil (Thun)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thun",
    community_code: "942",
    latitude: "46.764",
    longitude: "7.6706",
  },
  {
    country_code: "CH",
    zipcode: "3624",
    place: "Schwendibach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Schwendibach",
    community_code: "937",
    latitude: "46.7699",
    longitude: "7.6619",
  },
  {
    country_code: "CH",
    zipcode: "3625",
    place: "Heiligenschwendi",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Heiligenschwendi",
    community_code: "927",
    latitude: "46.7511",
    longitude: "7.6839",
  },
  {
    country_code: "CH",
    zipcode: "3626",
    place: "Hünibach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Hilterfingen",
    community_code: "929",
    latitude: "46.7462",
    longitude: "7.6503",
  },
  {
    country_code: "CH",
    zipcode: "3627",
    place: "Heimberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Heimberg",
    community_code: "928",
    latitude: "46.7948",
    longitude: "7.6043",
  },
  {
    country_code: "CH",
    zipcode: "3628",
    place: "Uttigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Uttigen",
    community_code: "885",
    latitude: "46.7944",
    longitude: "7.5779",
  },
  {
    country_code: "CH",
    zipcode: "3631",
    place: "Höfen b. Thun",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Stocken-Höfen",
    community_code: "770",
    latitude: "46.7101",
    longitude: "7.5636",
  },
  {
    country_code: "CH",
    zipcode: "3632",
    place: "Niederstocken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Stocken-Höfen",
    community_code: "770",
    latitude: "46.7106",
    longitude: "7.5721",
  },
  {
    country_code: "CH",
    zipcode: "3632",
    place: "Oberstocken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Stocken-Höfen",
    community_code: "770",
    latitude: "46.7175",
    longitude: "7.5547",
  },
  {
    country_code: "CH",
    zipcode: "3633",
    place: "Amsoldingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Amsoldingen",
    community_code: "921",
    latitude: "46.7275",
    longitude: "7.5825",
  },
  {
    country_code: "CH",
    zipcode: "3634",
    place: "Thierachern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Thierachern",
    community_code: "941",
    latitude: "46.7532",
    longitude: "7.5744",
  },
  {
    country_code: "CH",
    zipcode: "3635",
    place: "Uebeschi",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Uebeschi",
    community_code: "943",
    latitude: "46.7395",
    longitude: "7.5473",
  },
  {
    country_code: "CH",
    zipcode: "3636",
    place: "Längenbühl",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Forst-Längenbühl",
    community_code: "948",
    latitude: "46.7647",
    longitude: "7.5233",
  },
  {
    country_code: "CH",
    zipcode: "3636",
    place: "Forst b. Längenbühl",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Forst-Längenbühl",
    community_code: "948",
    latitude: "46.7647",
    longitude: "7.5233",
  },
  {
    country_code: "CH",
    zipcode: "3638",
    place: "Pohlern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Pohlern",
    community_code: "936",
    latitude: "46.7252",
    longitude: "7.5353",
  },
  {
    country_code: "CH",
    zipcode: "3638",
    place: "Blumenstein",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Blumenstein",
    community_code: "922",
    latitude: "46.7421",
    longitude: "7.5214",
  },
  {
    country_code: "CH",
    zipcode: "3645",
    place: "Zwieselberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Zwieselberg",
    community_code: "947",
    latitude: "46.7101",
    longitude: "7.6167",
  },
  {
    country_code: "CH",
    zipcode: "3647",
    place: "Reutigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Reutigen",
    community_code: "767",
    latitude: "46.6936",
    longitude: "7.621",
  },
  {
    country_code: "CH",
    zipcode: "3652",
    place: "Hilterfingen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Hilterfingen",
    community_code: "929",
    latitude: "46.7352",
    longitude: "7.6618",
  },
  {
    country_code: "CH",
    zipcode: "3653",
    place: "Oberhofen am Thunersee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Oberhofen am Thunersee",
    community_code: "934",
    latitude: "46.7315",
    longitude: "7.669",
  },
  {
    country_code: "CH",
    zipcode: "3654",
    place: "Gunten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.7119",
    longitude: "7.7024",
  },
  {
    country_code: "CH",
    zipcode: "3655",
    place: "Sigriswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.7166",
    longitude: "7.7133",
  },
  {
    country_code: "CH",
    zipcode: "3656",
    place: "Ringoldswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.7347",
    longitude: "7.6955",
  },
  {
    country_code: "CH",
    zipcode: "3656",
    place: "Aeschlen ob Gunten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.72",
    longitude: "7.7006",
  },
  {
    country_code: "CH",
    zipcode: "3656",
    place: "Tschingel ob Gunten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.7273",
    longitude: "7.6981",
  },
  {
    country_code: "CH",
    zipcode: "3657",
    place: "Schwanden (Sigriswil)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.7364",
    longitude: "7.7178",
  },
  {
    country_code: "CH",
    zipcode: "3658",
    place: "Merligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Sigriswil",
    community_code: "938",
    latitude: "46.6975",
    longitude: "7.7387",
  },
  {
    country_code: "CH",
    zipcode: "3661",
    place: "Uetendorf",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Uetendorf",
    community_code: "944",
    latitude: "46.7739",
    longitude: "7.5725",
  },
  {
    country_code: "CH",
    zipcode: "3662",
    place: "Seftigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Seftigen",
    community_code: "883",
    latitude: "46.7876",
    longitude: "7.5394",
  },
  {
    country_code: "CH",
    zipcode: "3663",
    place: "Gurzelen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Gurzelen",
    community_code: "867",
    latitude: "46.7764",
    longitude: "7.5426",
  },
  {
    country_code: "CH",
    zipcode: "3664",
    place: "Burgistein",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Burgistein",
    community_code: "863",
    latitude: "46.7846",
    longitude: "7.4999",
  },
  {
    country_code: "CH",
    zipcode: "3665",
    place: "Wattenwil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Thun District",
    province_code: "247",
    community: "Wattenwil",
    community_code: "886",
    latitude: "46.7697",
    longitude: "7.5083",
  },
  {
    country_code: "CH",
    zipcode: "1657",
    place: "Abländschen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.5724",
    longitude: "7.2892",
  },
  {
    country_code: "CH",
    zipcode: "3766",
    place: "Boltigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Boltigen",
    community_code: "791",
    latitude: "46.6289",
    longitude: "7.3911",
  },
  {
    country_code: "CH",
    zipcode: "3770",
    place: "Zweisimmen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Zweisimmen",
    community_code: "794",
    latitude: "46.5554",
    longitude: "7.373",
  },
  {
    country_code: "CH",
    zipcode: "3771",
    place: "Blankenburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Zweisimmen",
    community_code: "794",
    latitude: "46.5333",
    longitude: "7.3833",
  },
  {
    country_code: "CH",
    zipcode: "3772",
    place: "St. Stephan",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "St. Stephan",
    community_code: "793",
    latitude: "46.5083",
    longitude: "7.3956",
  },
  {
    country_code: "CH",
    zipcode: "3773",
    place: "Matten (St. Stephan)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "St. Stephan",
    community_code: "793",
    latitude: "46.4959",
    longitude: "7.4245",
  },
  {
    country_code: "CH",
    zipcode: "3775",
    place: "Lenk im Simmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Lenk",
    community_code: "792",
    latitude: "46.4583",
    longitude: "7.443",
  },
  {
    country_code: "CH",
    zipcode: "3776",
    place: "Oeschseite",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Zweisimmen",
    community_code: "794",
    latitude: "46.5306",
    longitude: "7.3511",
  },
  {
    country_code: "CH",
    zipcode: "3777",
    place: "Saanenmöser",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.5164",
    longitude: "7.3094",
  },
  {
    country_code: "CH",
    zipcode: "3778",
    place: "Schönried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.5039",
    longitude: "7.2888",
  },
  {
    country_code: "CH",
    zipcode: "3780",
    place: "Gstaad",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.4722",
    longitude: "7.2869",
  },
  {
    country_code: "CH",
    zipcode: "3781",
    place: "Turbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.4786",
    longitude: "7.3312",
  },
  {
    country_code: "CH",
    zipcode: "3782",
    place: "Lauenen b. Gstaad",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Lauenen",
    community_code: "842",
    latitude: "46.4244",
    longitude: "7.3217",
  },
  {
    country_code: "CH",
    zipcode: "3783",
    place: "Grund b. Gstaad",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.4516",
    longitude: "7.2778",
  },
  {
    country_code: "CH",
    zipcode: "3784",
    place: "Feutersoey",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Gsteig",
    community_code: "841",
    latitude: "46.4156",
    longitude: "7.271",
  },
  {
    country_code: "CH",
    zipcode: "3785",
    place: "Gsteig b. Gstaad",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Gsteig",
    community_code: "841",
    latitude: "46.3859",
    longitude: "7.2675",
  },
  {
    country_code: "CH",
    zipcode: "3792",
    place: "Saanen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Obersimmental-Saanen District",
    province_code: "248",
    community: "Saanen",
    community_code: "843",
    latitude: "46.4894",
    longitude: "7.26",
  },
  {
    country_code: "CH",
    zipcode: "3645",
    place: "Gwatt (Thun)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Spiez",
    community_code: "768",
    latitude: "46.7204",
    longitude: "7.6223",
  },
  {
    country_code: "CH",
    zipcode: "3646",
    place: "Einigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Spiez",
    community_code: "768",
    latitude: "46.708",
    longitude: "7.6483",
  },
  {
    country_code: "CH",
    zipcode: "3700",
    place: "Spiez",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Spiez",
    community_code: "768",
    latitude: "46.6847",
    longitude: "7.6911",
  },
  {
    country_code: "CH",
    zipcode: "3702",
    place: "Hondrich",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Spiez",
    community_code: "768",
    latitude: "46.672",
    longitude: "7.6811",
  },
  {
    country_code: "CH",
    zipcode: "3703",
    place: "Aeschiried",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Aeschi bei Spiez",
    community_code: "562",
    latitude: "46.6462",
    longitude: "7.7258",
  },
  {
    country_code: "CH",
    zipcode: "3703",
    place: "Aeschi b. Spiez",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Aeschi bei Spiez",
    community_code: "562",
    latitude: "46.6462",
    longitude: "7.7258",
  },
  {
    country_code: "CH",
    zipcode: "3704",
    place: "Krattigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Krattigen",
    community_code: "566",
    latitude: "46.6614",
    longitude: "7.7278",
  },
  {
    country_code: "CH",
    zipcode: "3705",
    place: "Faulensee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Spiez",
    community_code: "768",
    latitude: "46.6742",
    longitude: "7.7023",
  },
  {
    country_code: "CH",
    zipcode: "3711",
    place: "Emdthal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Aeschi bei Spiez",
    community_code: "562",
    latitude: "46.6543",
    longitude: "7.685",
  },
  {
    country_code: "CH",
    zipcode: "3711",
    place: "Mülenen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Reichenbach im Kandertal",
    community_code: "567",
    latitude: "46.6394",
    longitude: "7.6941",
  },
  {
    country_code: "CH",
    zipcode: "3713",
    place: "Reichenbach im Kandertal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Reichenbach im Kandertal",
    community_code: "567",
    latitude: "46.6254",
    longitude: "7.6936",
  },
  {
    country_code: "CH",
    zipcode: "3714",
    place: "Frutigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Frutigen",
    community_code: "563",
    latitude: "46.5872",
    longitude: "7.6494",
  },
  {
    country_code: "CH",
    zipcode: "3714",
    place: "Wengi b. Frutigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Reichenbach im Kandertal",
    community_code: "567",
    latitude: "46.5753",
    longitude: "7.7369",
  },
  {
    country_code: "CH",
    zipcode: "3715",
    place: "Adelboden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Adelboden",
    community_code: "561",
    latitude: "46.4914",
    longitude: "7.5603",
  },
  {
    country_code: "CH",
    zipcode: "3716",
    place: "Kandergrund",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Kandergrund",
    community_code: "564",
    latitude: "46.5621",
    longitude: "7.659",
  },
  {
    country_code: "CH",
    zipcode: "3717",
    place: "Blausee-Mitholz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Kandergrund",
    community_code: "564",
    latitude: "46.5332",
    longitude: "7.6641",
  },
  {
    country_code: "CH",
    zipcode: "3718",
    place: "Kandersteg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Kandersteg",
    community_code: "565",
    latitude: "46.4947",
    longitude: "7.6733",
  },
  {
    country_code: "CH",
    zipcode: "3722",
    place: "Scharnachtal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Reichenbach im Kandertal",
    community_code: "567",
    latitude: "46.6162",
    longitude: "7.698",
  },
  {
    country_code: "CH",
    zipcode: "3723",
    place: "Kiental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Reichenbach im Kandertal",
    community_code: "567",
    latitude: "46.5877",
    longitude: "7.7245",
  },
  {
    country_code: "CH",
    zipcode: "3724",
    place: "Ried (Frutigen)",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Frutigen",
    community_code: "563",
    latitude: "46.5668",
    longitude: "7.6172",
  },
  {
    country_code: "CH",
    zipcode: "3725",
    place: "Achseten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Frutigen",
    community_code: "563",
    latitude: "46.5277",
    longitude: "7.5967",
  },
  {
    country_code: "CH",
    zipcode: "3752",
    place: "Wimmis",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Wimmis",
    community_code: "769",
    latitude: "46.6759",
    longitude: "7.6397",
  },
  {
    country_code: "CH",
    zipcode: "3753",
    place: "Oey",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Diemtigen",
    community_code: "762",
    latitude: "46.6592",
    longitude: "7.5768",
  },
  {
    country_code: "CH",
    zipcode: "3754",
    place: "Diemtigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Diemtigen",
    community_code: "762",
    latitude: "46.6493",
    longitude: "7.5648",
  },
  {
    country_code: "CH",
    zipcode: "3755",
    place: "Horboden",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Diemtigen",
    community_code: "762",
    latitude: "46.6316",
    longitude: "7.5621",
  },
  {
    country_code: "CH",
    zipcode: "3756",
    place: "Zwischenflüh",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Diemtigen",
    community_code: "762",
    latitude: "46.6072",
    longitude: "7.5155",
  },
  {
    country_code: "CH",
    zipcode: "3757",
    place: "Schwenden im Diemtigtal",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Diemtigen",
    community_code: "762",
    latitude: "46.5801",
    longitude: "7.4906",
  },
  {
    country_code: "CH",
    zipcode: "3758",
    place: "Latterbach",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Erlenbach im Simmental",
    community_code: "763",
    latitude: "46.6656",
    longitude: "7.5768",
  },
  {
    country_code: "CH",
    zipcode: "3762",
    place: "Erlenbach im Simmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Erlenbach im Simmental",
    community_code: "763",
    latitude: "46.6602",
    longitude: "7.5545",
  },
  {
    country_code: "CH",
    zipcode: "3763",
    place: "Därstetten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Därstetten",
    community_code: "761",
    latitude: "46.6595",
    longitude: "7.4911",
  },
  {
    country_code: "CH",
    zipcode: "3764",
    place: "Weissenburg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Därstetten",
    community_code: "761",
    latitude: "46.6582",
    longitude: "7.476",
  },
  {
    country_code: "CH",
    zipcode: "3765",
    place: "Oberwil im Simmental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Frutigen-Niedersimmental District",
    province_code: "249",
    community: "Oberwil im Simmental",
    community_code: "766",
    latitude: "46.6569",
    longitude: "7.4351",
  },
  {
    country_code: "CH",
    zipcode: "3706",
    place: "Leissigen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Leissigen",
    community_code: "585",
    latitude: "46.6546",
    longitude: "7.7755",
  },
  {
    country_code: "CH",
    zipcode: "3707",
    place: "Därligen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Därligen",
    community_code: "575",
    latitude: "46.6618",
    longitude: "7.8081",
  },
  {
    country_code: "CH",
    zipcode: "3800",
    place: "Matten b. Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Matten bei Interlaken",
    community_code: "587",
    latitude: "46.6783",
    longitude: "7.8689",
  },
  {
    country_code: "CH",
    zipcode: "3800",
    place: "Sundlauenen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Beatenberg",
    community_code: "571",
    latitude: "46.6863",
    longitude: "7.7931",
  },
  {
    country_code: "CH",
    zipcode: "3800",
    place: "Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Gündlischwand",
    community_code: "578",
    latitude: "46.652",
    longitude: "7.9337",
  },
  {
    country_code: "CH",
    zipcode: "3800",
    place: "Unterseen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Unterseen",
    community_code: "593",
    latitude: "46.6853",
    longitude: "7.8472",
  },
  {
    country_code: "CH",
    zipcode: "3800",
    place: "Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Interlaken",
    community_code: "581",
    latitude: "46.6839",
    longitude: "7.8664",
  },
  {
    country_code: "CH",
    zipcode: "3802",
    place: "Interlaken Ost",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Interlaken",
    community_code: "581",
    latitude: "46.6871",
    longitude: "7.8649",
  },
  {
    country_code: "CH",
    zipcode: "3803",
    place: "Beatenberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Beatenberg",
    community_code: "571",
    latitude: "46.699",
    longitude: "7.7943",
  },
  {
    country_code: "CH",
    zipcode: "3804",
    place: "Habkern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Habkern",
    community_code: "579",
    latitude: "46.7264",
    longitude: "7.863",
  },
  {
    country_code: "CH",
    zipcode: "3805",
    place: "Goldswil b. Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Ringgenberg (BE)",
    community_code: "590",
    latitude: "46.6967",
    longitude: "7.8781",
  },
  {
    country_code: "CH",
    zipcode: "3806",
    place: "Bönigen b. Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Bönigen",
    community_code: "572",
    latitude: "46.6874",
    longitude: "7.8935",
  },
  {
    country_code: "CH",
    zipcode: "3807",
    place: "Iseltwald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Iseltwald",
    community_code: "582",
    latitude: "46.7108",
    longitude: "7.9642",
  },
  {
    country_code: "CH",
    zipcode: "3812",
    place: "Wilderswil",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Wilderswil",
    community_code: "594",
    latitude: "46.6637",
    longitude: "7.8617",
  },
  {
    country_code: "CH",
    zipcode: "3813",
    place: "Saxeten",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Saxeten",
    community_code: "591",
    latitude: "46.6366",
    longitude: "7.8309",
  },
  {
    country_code: "CH",
    zipcode: "3814",
    place: "Gsteigwiler",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Gsteigwiler",
    community_code: "577",
    latitude: "46.6554",
    longitude: "7.8719",
  },
  {
    country_code: "CH",
    zipcode: "3815",
    place: "Gündlischwand",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Gündlischwand",
    community_code: "578",
    latitude: "46.6322",
    longitude: "7.9127",
  },
  {
    country_code: "CH",
    zipcode: "3815",
    place: "Zweilütschinen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Gündlischwand",
    community_code: "578",
    latitude: "46.6314",
    longitude: "7.9015",
  },
  {
    country_code: "CH",
    zipcode: "3816",
    place: "Burglauenen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Grindelwald",
    community_code: "576",
    latitude: "46.6372",
    longitude: "7.9764",
  },
  {
    country_code: "CH",
    zipcode: "3816",
    place: "Lütschental",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lütschental",
    community_code: "586",
    latitude: "46.6378",
    longitude: "7.947",
  },
  {
    country_code: "CH",
    zipcode: "3818",
    place: "Grindelwald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Grindelwald",
    community_code: "576",
    latitude: "46.624",
    longitude: "8.036",
  },
  {
    country_code: "CH",
    zipcode: "3822",
    place: "Isenfluh",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.6198",
    longitude: "7.8948",
  },
  {
    country_code: "CH",
    zipcode: "3822",
    place: "Lauterbrunnen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5931",
    longitude: "7.9094",
  },
  {
    country_code: "CH",
    zipcode: "3823",
    place: "Wengen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.606",
    longitude: "7.9201",
  },
  {
    country_code: "CH",
    zipcode: "3823",
    place: "Eigergletscher",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5749",
    longitude: "7.9738",
  },
  {
    country_code: "CH",
    zipcode: "3823",
    place: "Kleine Scheidegg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5861",
    longitude: "7.9613",
  },
  {
    country_code: "CH",
    zipcode: "3824",
    place: "Stechelberg",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5431",
    longitude: "7.9015",
  },
  {
    country_code: "CH",
    zipcode: "3825",
    place: "Mürren",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5588",
    longitude: "7.8929",
  },
  {
    country_code: "CH",
    zipcode: "3826",
    place: "Gimmelwald",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Lauterbrunnen",
    community_code: "584",
    latitude: "46.5455",
    longitude: "7.8914",
  },
  {
    country_code: "CH",
    zipcode: "3852",
    place: "Ringgenberg BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Ringgenberg (BE)",
    community_code: "590",
    latitude: "46.7011",
    longitude: "7.8944",
  },
  {
    country_code: "CH",
    zipcode: "3853",
    place: "Niederried b. Interlaken",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Niederried bei Interlaken",
    community_code: "588",
    latitude: "46.7187",
    longitude: "7.9324",
  },
  {
    country_code: "CH",
    zipcode: "3854",
    place: "Oberried am Brienzersee",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Oberried am Brienzersee",
    community_code: "589",
    latitude: "46.7363",
    longitude: "7.9593",
  },
  {
    country_code: "CH",
    zipcode: "3855",
    place: "Brienz BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Brienz (BE)",
    community_code: "573",
    latitude: "46.7545",
    longitude: "8.0385",
  },
  {
    country_code: "CH",
    zipcode: "3855",
    place: "Schwanden b. Brienz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Schwanden bei Brienz",
    community_code: "592",
    latitude: "46.7711",
    longitude: "8.0557",
  },
  {
    country_code: "CH",
    zipcode: "3855",
    place: "Axalp",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Brienz (BE)",
    community_code: "573",
    latitude: "46.7252",
    longitude: "8.0382",
  },
  {
    country_code: "CH",
    zipcode: "3856",
    place: "Brienzwiler",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Brienzwiler",
    community_code: "574",
    latitude: "46.7508",
    longitude: "8.1014",
  },
  {
    country_code: "CH",
    zipcode: "3857",
    place: "Unterbach BE",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Meiringen",
    community_code: "785",
    latitude: "46.7374",
    longitude: "8.1101",
  },
  {
    country_code: "CH",
    zipcode: "3858",
    place: "Hofstetten b. Brienz",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Hofstetten bei Brienz",
    community_code: "580",
    latitude: "46.7535",
    longitude: "8.0749",
  },
  {
    country_code: "CH",
    zipcode: "3860",
    place: "Rosenlaui",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Schattenhalb",
    community_code: "786",
    latitude: "46.6796",
    longitude: "8.1539",
  },
  {
    country_code: "CH",
    zipcode: "3860",
    place: "Meiringen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Meiringen",
    community_code: "785",
    latitude: "46.7271",
    longitude: "8.1872",
  },
  {
    country_code: "CH",
    zipcode: "3860",
    place: "Schattenhalb",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Schattenhalb",
    community_code: "786",
    latitude: "46.719",
    longitude: "8.1907",
  },
  {
    country_code: "CH",
    zipcode: "3860",
    place: "Brünig",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Meiringen",
    community_code: "785",
    latitude: "46.7186",
    longitude: "8.1342",
  },
  {
    country_code: "CH",
    zipcode: "3862",
    place: "Innertkirchen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Innertkirchen",
    community_code: "784",
    latitude: "46.7047",
    longitude: "8.2288",
  },
  {
    country_code: "CH",
    zipcode: "3863",
    place: "Gadmen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Innertkirchen",
    community_code: "784",
    latitude: "46.7373",
    longitude: "8.3522",
  },
  {
    country_code: "CH",
    zipcode: "3864",
    place: "Guttannen",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Guttannen",
    community_code: "782",
    latitude: "46.6551",
    longitude: "8.2895",
  },
  {
    country_code: "CH",
    zipcode: "6083",
    place: "Hasliberg Hohfluh",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Hasliberg",
    community_code: "783",
    latitude: "46.7526",
    longitude: "8.1705",
  },
  {
    country_code: "CH",
    zipcode: "6084",
    place: "Hasliberg Wasserwendi",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Hasliberg",
    community_code: "783",
    latitude: "46.7471",
    longitude: "8.1994",
  },
  {
    country_code: "CH",
    zipcode: "6085",
    place: "Hasliberg Goldern",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Hasliberg",
    community_code: "783",
    latitude: "46.7408",
    longitude: "8.2001",
  },
  {
    country_code: "CH",
    zipcode: "6086",
    place: "Hasliberg Reuti",
    state: "Canton de Berne",
    state_code: "BE",
    province: "Interlaken-Oberhasli District",
    province_code: "250",
    community: "Hasliberg",
    community_code: "783",
    latitude: "46.7319",
    longitude: "8.2107",
  },
  {
    country_code: "CH",
    zipcode: "4040",
    place: "Basel",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Münchenstein",
    community_code: "2769",
    latitude: "47.5186",
    longitude: "7.6174",
  },
  {
    country_code: "CH",
    zipcode: "4042",
    place: "Basel PF OC",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Münchenstein",
    community_code: "2769",
    latitude: "47.5186",
    longitude: "7.6174",
  },
  {
    country_code: "CH",
    zipcode: "4101",
    place: "Bruderholz",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Binningen",
    community_code: "2765",
    latitude: "47.5366",
    longitude: "7.5679",
  },
  {
    country_code: "CH",
    zipcode: "4102",
    place: "Binningen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Binningen",
    community_code: "2765",
    latitude: "47.5402",
    longitude: "7.5693",
  },
  {
    country_code: "CH",
    zipcode: "4103",
    place: "Bottmingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Bottmingen",
    community_code: "2767",
    latitude: "47.5234",
    longitude: "7.5721",
  },
  {
    country_code: "CH",
    zipcode: "4104",
    place: "Oberwil BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Oberwil (BL)",
    community_code: "2771",
    latitude: "47.5141",
    longitude: "7.5579",
  },
  {
    country_code: "CH",
    zipcode: "4105",
    place: "Biel-Benken BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Biel-Benken",
    community_code: "2764",
    latitude: "47.5078",
    longitude: "7.5258",
  },
  {
    country_code: "CH",
    zipcode: "4106",
    place: "Therwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Therwil",
    community_code: "2775",
    latitude: "47.5012",
    longitude: "7.5529",
  },
  {
    country_code: "CH",
    zipcode: "4107",
    place: "Ettingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Ettingen",
    community_code: "2768",
    latitude: "47.4827",
    longitude: "7.5498",
  },
  {
    country_code: "CH",
    zipcode: "4123",
    place: "Allschwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Allschwil",
    community_code: "2762",
    latitude: "47.5507",
    longitude: "7.536",
  },
  {
    country_code: "CH",
    zipcode: "4124",
    place: "Schönenbuch",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Schönenbuch",
    community_code: "2774",
    latitude: "47.5385",
    longitude: "7.5057",
  },
  {
    country_code: "CH",
    zipcode: "4127",
    place: "Birsfelden",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Birsfelden",
    community_code: "2766",
    latitude: "47.5529",
    longitude: "7.6232",
  },
  {
    country_code: "CH",
    zipcode: "4132",
    place: "Muttenz",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Muttenz",
    community_code: "2770",
    latitude: "47.5227",
    longitude: "7.6451",
  },
  {
    country_code: "CH",
    zipcode: "4142",
    place: "Münchenstein",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Münchenstein",
    community_code: "2769",
    latitude: "47.5185",
    longitude: "7.6097",
  },
  {
    country_code: "CH",
    zipcode: "4144",
    place: "Arlesheim",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Arlesheim",
    community_code: "2763",
    latitude: "47.4941",
    longitude: "7.6198",
  },
  {
    country_code: "CH",
    zipcode: "4147",
    place: "Aesch BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Aesch (BL)",
    community_code: "2761",
    latitude: "47.471",
    longitude: "7.5973",
  },
  {
    country_code: "CH",
    zipcode: "4148",
    place: "Pfeffingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Pfeffingen",
    community_code: "2772",
    latitude: "47.4598",
    longitude: "7.5898",
  },
  {
    country_code: "CH",
    zipcode: "4153",
    place: "Reinach BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Arlesheim",
    province_code: "1301",
    community: "Reinach (BL)",
    community_code: "2773",
    latitude: "47.497",
    longitude: "7.5917",
  },
  {
    country_code: "CH",
    zipcode: "2814",
    place: "Roggenburg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Roggenburg",
    community_code: "2790",
    latitude: "47.431",
    longitude: "7.3406",
  },
  {
    country_code: "CH",
    zipcode: "4117",
    place: "Burg im Leimental",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Burg im Leimental",
    community_code: "2783",
    latitude: "47.4596",
    longitude: "7.4381",
  },
  {
    country_code: "CH",
    zipcode: "4202",
    place: "Duggingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Duggingen",
    community_code: "2785",
    latitude: "47.4518",
    longitude: "7.6051",
  },
  {
    country_code: "CH",
    zipcode: "4203",
    place: "Grellingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Grellingen",
    community_code: "2786",
    latitude: "47.4423",
    longitude: "7.5891",
  },
  {
    country_code: "CH",
    zipcode: "4222",
    place: "Zwingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Zwingen",
    community_code: "2793",
    latitude: "47.4382",
    longitude: "7.5303",
  },
  {
    country_code: "CH",
    zipcode: "4223",
    place: "Blauen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Blauen",
    community_code: "2781",
    latitude: "47.4502",
    longitude: "7.5185",
  },
  {
    country_code: "CH",
    zipcode: "4224",
    place: "Nenzlingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Nenzlingen",
    community_code: "2789",
    latitude: "47.4474",
    longitude: "7.557",
  },
  {
    country_code: "CH",
    zipcode: "4225",
    place: "Brislach",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Brislach",
    community_code: "2782",
    latitude: "47.4176",
    longitude: "7.5434",
  },
  {
    country_code: "CH",
    zipcode: "4242",
    place: "Laufen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Laufen",
    community_code: "2787",
    latitude: "47.4219",
    longitude: "7.4995",
  },
  {
    country_code: "CH",
    zipcode: "4243",
    place: "Dittingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Dittingen",
    community_code: "2784",
    latitude: "47.442",
    longitude: "7.4962",
  },
  {
    country_code: "CH",
    zipcode: "4244",
    place: "Röschenz",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Röschenz",
    community_code: "2791",
    latitude: "47.4237",
    longitude: "7.4802",
  },
  {
    country_code: "CH",
    zipcode: "4246",
    place: "Wahlen b. Laufen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Wahlen",
    community_code: "2792",
    latitude: "47.398",
    longitude: "7.5115",
  },
  {
    country_code: "CH",
    zipcode: "4253",
    place: "Liesberg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Liesberg",
    community_code: "2788",
    latitude: "47.404",
    longitude: "7.4279",
  },
  {
    country_code: "CH",
    zipcode: "4254",
    place: "Liesberg Dorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Laufen",
    province_code: "1302",
    community: "Liesberg",
    community_code: "2788",
    latitude: "47.4033",
    longitude: "7.4341",
  },
  {
    country_code: "CH",
    zipcode: "4133",
    place: "Pratteln",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Pratteln",
    community_code: "2831",
    latitude: "47.5207",
    longitude: "7.6936",
  },
  {
    country_code: "CH",
    zipcode: "4302",
    place: "Augst BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Augst",
    community_code: "2822",
    latitude: "47.5356",
    longitude: "7.7147",
  },
  {
    country_code: "CH",
    zipcode: "4304",
    place: "Giebenach",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Giebenach",
    community_code: "2826",
    latitude: "47.523",
    longitude: "7.7397",
  },
  {
    country_code: "CH",
    zipcode: "4402",
    place: "Frenkendorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Frenkendorf",
    community_code: "2824",
    latitude: "47.5069",
    longitude: "7.7165",
  },
  {
    country_code: "CH",
    zipcode: "4410",
    place: "Liestal",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Liestal",
    community_code: "2829",
    latitude: "47.4846",
    longitude: "7.7345",
  },
  {
    country_code: "CH",
    zipcode: "4411",
    place: "Seltisberg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Seltisberg",
    community_code: "2833",
    latitude: "47.4595",
    longitude: "7.7173",
  },
  {
    country_code: "CH",
    zipcode: "4414",
    place: "Füllinsdorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Füllinsdorf",
    community_code: "2825",
    latitude: "47.5069",
    longitude: "7.7313",
  },
  {
    country_code: "CH",
    zipcode: "4415",
    place: "Lausen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Lausen",
    community_code: "2828",
    latitude: "47.4714",
    longitude: "7.7603",
  },
  {
    country_code: "CH",
    zipcode: "4416",
    place: "Bubendorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Bubendorf",
    community_code: "2823",
    latitude: "47.4459",
    longitude: "7.7376",
  },
  {
    country_code: "CH",
    zipcode: "4417",
    place: "Ziefen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Ziefen",
    community_code: "2834",
    latitude: "47.4292",
    longitude: "7.7053",
  },
  {
    country_code: "CH",
    zipcode: "4419",
    place: "Lupsingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Lupsingen",
    community_code: "2830",
    latitude: "47.4463",
    longitude: "7.6961",
  },
  {
    country_code: "CH",
    zipcode: "4422",
    place: "Arisdorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Arisdorf",
    community_code: "2821",
    latitude: "47.5132",
    longitude: "7.7651",
  },
  {
    country_code: "CH",
    zipcode: "4423",
    place: "Hersberg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Hersberg",
    community_code: "2827",
    latitude: "47.4921",
    longitude: "7.7831",
  },
  {
    country_code: "CH",
    zipcode: "4433",
    place: "Ramlinsburg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Liestal",
    province_code: "1303",
    community: "Ramlinsburg",
    community_code: "2832",
    latitude: "47.4491",
    longitude: "7.7696",
  },
  {
    country_code: "CH",
    zipcode: "4441",
    place: "Thürnen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Thürnen",
    community_code: "2864",
    latitude: "47.4531",
    longitude: "7.8288",
  },
  {
    country_code: "CH",
    zipcode: "4442",
    place: "Diepflingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Diepflingen",
    community_code: "2845",
    latitude: "47.4444",
    longitude: "7.8364",
  },
  {
    country_code: "CH",
    zipcode: "4443",
    place: "Wittinsburg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Wittinsburg",
    community_code: "2867",
    latitude: "47.4252",
    longitude: "7.8413",
  },
  {
    country_code: "CH",
    zipcode: "4444",
    place: "Rümlingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Rümlingen",
    community_code: "2859",
    latitude: "47.424",
    longitude: "7.8479",
  },
  {
    country_code: "CH",
    zipcode: "4445",
    place: "Häfelfingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Häfelfingen",
    community_code: "2847",
    latitude: "47.4149",
    longitude: "7.866",
  },
  {
    country_code: "CH",
    zipcode: "4446",
    place: "Buckten",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Buckten",
    community_code: "2843",
    latitude: "47.4099",
    longitude: "7.8448",
  },
  {
    country_code: "CH",
    zipcode: "4447",
    place: "Känerkinden",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Känerkinden",
    community_code: "2850",
    latitude: "47.4119",
    longitude: "7.8372",
  },
  {
    country_code: "CH",
    zipcode: "4448",
    place: "Läufelfingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Läufelfingen",
    community_code: "2852",
    latitude: "47.3946",
    longitude: "7.8558",
  },
  {
    country_code: "CH",
    zipcode: "4450",
    place: "Sissach",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Sissach",
    community_code: "2861",
    latitude: "47.4641",
    longitude: "7.8089",
  },
  {
    country_code: "CH",
    zipcode: "4451",
    place: "Wintersingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Wintersingen",
    community_code: "2866",
    latitude: "47.4941",
    longitude: "7.8241",
  },
  {
    country_code: "CH",
    zipcode: "4452",
    place: "Itingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Itingen",
    community_code: "2849",
    latitude: "47.4665",
    longitude: "7.785",
  },
  {
    country_code: "CH",
    zipcode: "4453",
    place: "Nusshof",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Nusshof",
    community_code: "2854",
    latitude: "47.4914",
    longitude: "7.8007",
  },
  {
    country_code: "CH",
    zipcode: "4455",
    place: "Zunzgen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Zunzgen",
    community_code: "2869",
    latitude: "47.4492",
    longitude: "7.8079",
  },
  {
    country_code: "CH",
    zipcode: "4456",
    place: "Tenniken",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Tenniken",
    community_code: "2863",
    latitude: "47.4371",
    longitude: "7.8115",
  },
  {
    country_code: "CH",
    zipcode: "4460",
    place: "Gelterkinden",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Gelterkinden",
    community_code: "2846",
    latitude: "47.465",
    longitude: "7.8517",
  },
  {
    country_code: "CH",
    zipcode: "4461",
    place: "Böckten",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Böckten",
    community_code: "2842",
    latitude: "47.4654",
    longitude: "7.8326",
  },
  {
    country_code: "CH",
    zipcode: "4462",
    place: "Rickenbach BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Rickenbach (BL)",
    community_code: "2857",
    latitude: "47.486",
    longitude: "7.8474",
  },
  {
    country_code: "CH",
    zipcode: "4463",
    place: "Buus",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Buus",
    community_code: "2844",
    latitude: "47.5054",
    longitude: "7.8648",
  },
  {
    country_code: "CH",
    zipcode: "4464",
    place: "Maisprach",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Maisprach",
    community_code: "2853",
    latitude: "47.5246",
    longitude: "7.847",
  },
  {
    country_code: "CH",
    zipcode: "4465",
    place: "Hemmiken",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Hemmiken",
    community_code: "2848",
    latitude: "47.4888",
    longitude: "7.8938",
  },
  {
    country_code: "CH",
    zipcode: "4466",
    place: "Ormalingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Ormalingen",
    community_code: "2856",
    latitude: "47.4694",
    longitude: "7.8725",
  },
  {
    country_code: "CH",
    zipcode: "4467",
    place: "Rothenfluh",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Rothenfluh",
    community_code: "2858",
    latitude: "47.4626",
    longitude: "7.9163",
  },
  {
    country_code: "CH",
    zipcode: "4469",
    place: "Anwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Anwil",
    community_code: "2841",
    latitude: "47.453",
    longitude: "7.9419",
  },
  {
    country_code: "CH",
    zipcode: "4492",
    place: "Tecknau",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Tecknau",
    community_code: "2862",
    latitude: "47.4513",
    longitude: "7.884",
  },
  {
    country_code: "CH",
    zipcode: "4493",
    place: "Wenslingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Wenslingen",
    community_code: "2865",
    latitude: "47.4407",
    longitude: "7.9075",
  },
  {
    country_code: "CH",
    zipcode: "4494",
    place: "Oltingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Oltingen",
    community_code: "2855",
    latitude: "47.4302",
    longitude: "7.9372",
  },
  {
    country_code: "CH",
    zipcode: "4495",
    place: "Zeglingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Zeglingen",
    community_code: "2868",
    latitude: "47.4163",
    longitude: "7.9048",
  },
  {
    country_code: "CH",
    zipcode: "4496",
    place: "Kilchberg BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Kilchberg (BL)",
    community_code: "2851",
    latitude: "47.4249",
    longitude: "7.894",
  },
  {
    country_code: "CH",
    zipcode: "4497",
    place: "Rünenberg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Sissach",
    province_code: "1304",
    community: "Rünenberg",
    community_code: "2860",
    latitude: "47.4348",
    longitude: "7.8829",
  },
  {
    country_code: "CH",
    zipcode: "4207",
    place: "Bretzwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Bretzwil",
    community_code: "2883",
    latitude: "47.3978",
    longitude: "7.6522",
  },
  {
    country_code: "CH",
    zipcode: "4418",
    place: "Reigoldswil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Reigoldswil",
    community_code: "2893",
    latitude: "47.3982",
    longitude: "7.6872",
  },
  {
    country_code: "CH",
    zipcode: "4424",
    place: "Arboldswil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Arboldswil",
    community_code: "2881",
    latitude: "47.4144",
    longitude: "7.7176",
  },
  {
    country_code: "CH",
    zipcode: "4425",
    place: "Titterten",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Titterten",
    community_code: "2894",
    latitude: "47.4021",
    longitude: "7.7162",
  },
  {
    country_code: "CH",
    zipcode: "4426",
    place: "Lauwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Lauwil",
    community_code: "2889",
    latitude: "47.39",
    longitude: "7.6749",
  },
  {
    country_code: "CH",
    zipcode: "4431",
    place: "Bennwil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Bennwil",
    community_code: "2882",
    latitude: "47.4032",
    longitude: "7.7799",
  },
  {
    country_code: "CH",
    zipcode: "4432",
    place: "Lampenberg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Lampenberg",
    community_code: "2887",
    latitude: "47.4256",
    longitude: "7.7588",
  },
  {
    country_code: "CH",
    zipcode: "4434",
    place: "Hölstein",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Hölstein",
    community_code: "2886",
    latitude: "47.4251",
    longitude: "7.7714",
  },
  {
    country_code: "CH",
    zipcode: "4435",
    place: "Niederdorf",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Niederdorf",
    community_code: "2891",
    latitude: "47.4095",
    longitude: "7.7496",
  },
  {
    country_code: "CH",
    zipcode: "4436",
    place: "Oberdorf BL",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Oberdorf (BL)",
    community_code: "2892",
    latitude: "47.3931",
    longitude: "7.7512",
  },
  {
    country_code: "CH",
    zipcode: "4436",
    place: "Liedertswil",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Liedertswil",
    community_code: "2890",
    latitude: "47.3905",
    longitude: "7.7207",
  },
  {
    country_code: "CH",
    zipcode: "4437",
    place: "Waldenburg",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Waldenburg",
    community_code: "2895",
    latitude: "47.3833",
    longitude: "7.75",
  },
  {
    country_code: "CH",
    zipcode: "4438",
    place: "Langenbruck",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Langenbruck",
    community_code: "2888",
    latitude: "47.3492",
    longitude: "7.768",
  },
  {
    country_code: "CH",
    zipcode: "4457",
    place: "Diegten",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Diegten",
    community_code: "2884",
    latitude: "47.4138",
    longitude: "7.8109",
  },
  {
    country_code: "CH",
    zipcode: "4458",
    place: "Eptingen",
    state: "Kanton Basel-Landschaft",
    state_code: "BL",
    province: "Bezirk Waldenburg",
    province_code: "1305",
    community: "Eptingen",
    community_code: "2885",
    latitude: "47.3847",
    longitude: "7.819",
  },
  {
    country_code: "CH",
    zipcode: "4000",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4001",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4002",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4005",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4009",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4010",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4018",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4019",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4020",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4030",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4031",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4039",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4041",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4051",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4052",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4052",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4053",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4054",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4055",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4056",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4057",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4058",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4059",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5584",
    longitude: "7.5733",
  },
  {
    country_code: "CH",
    zipcode: "4070",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4075",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4089",
    place: "Basel SPI GLS",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4089",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4091",
    place: "Basel",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Basel",
    community_code: "2701",
    latitude: "47.5577",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "4125",
    place: "Riehen",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Riehen",
    community_code: "2703",
    latitude: "47.5788",
    longitude: "7.6468",
  },
  {
    country_code: "CH",
    zipcode: "4126",
    place: "Bettingen",
    state: "Kanton Basel-Stadt",
    state_code: "BS",
    province: "Basel-Stadt",
    province_code: "1200",
    community: "Bettingen",
    community_code: "2702",
    latitude: "47.5704",
    longitude: "7.6643",
  },
  {
    country_code: "CH",
    zipcode: "1410",
    place: "Prévondavaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Prévondavaux",
    community_code: "2038",
    latitude: "46.7296",
    longitude: "6.7956",
  },
  {
    country_code: "CH",
    zipcode: "1468",
    place: "Cheyres",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cheyres-Châbles",
    community_code: "2055",
    latitude: "46.8144",
    longitude: "6.7868",
  },
  {
    country_code: "CH",
    zipcode: "1470",
    place: "Estavayer-le-Lac",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8488",
    longitude: "6.8465",
  },
  {
    country_code: "CH",
    zipcode: "1470",
    place: "Lully FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Lully (FR)",
    community_code: "2025",
    latitude: "46.834",
    longitude: "6.8453",
  },
  {
    country_code: "CH",
    zipcode: "1470",
    place: "Bollion",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Lully (FR)",
    community_code: "2025",
    latitude: "46.8192",
    longitude: "6.828",
  },
  {
    country_code: "CH",
    zipcode: "1470",
    place: "Seiry",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Lully (FR)",
    community_code: "2025",
    latitude: "46.8141",
    longitude: "6.8313",
  },
  {
    country_code: "CH",
    zipcode: "1473",
    place: "Font",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8375",
    longitude: "6.8221",
  },
  {
    country_code: "CH",
    zipcode: "1473",
    place: "Châtillon FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Châtillon (FR)",
    community_code: "2008",
    latitude: "46.8305",
    longitude: "6.8315",
  },
  {
    country_code: "CH",
    zipcode: "1474",
    place: "Châbles FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cheyres-Châbles",
    community_code: "2055",
    latitude: "46.8245",
    longitude: "6.8108",
  },
  {
    country_code: "CH",
    zipcode: "1475",
    place: "Montbrelloz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8548",
    longitude: "6.8876",
  },
  {
    country_code: "CH",
    zipcode: "1475",
    place: "Autavaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8666",
    longitude: "6.8782",
  },
  {
    country_code: "CH",
    zipcode: "1475",
    place: "Forel FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8774",
    longitude: "6.8891",
  },
  {
    country_code: "CH",
    zipcode: "1482",
    place: "Cugy FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cugy (FR)",
    community_code: "2011",
    latitude: "46.8148",
    longitude: "6.8889",
  },
  {
    country_code: "CH",
    zipcode: "1483",
    place: "Frasses",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Les Montets",
    community_code: "2050",
    latitude: "46.8282",
    longitude: "6.8644",
  },
  {
    country_code: "CH",
    zipcode: "1483",
    place: "Vesin",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cugy (FR)",
    community_code: "2011",
    latitude: "46.8105",
    longitude: "6.8752",
  },
  {
    country_code: "CH",
    zipcode: "1483",
    place: "Montet (Broye)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Les Montets",
    community_code: "2050",
    latitude: "46.8177",
    longitude: "6.8679",
  },
  {
    country_code: "CH",
    zipcode: "1484",
    place: "Granges-de-Vesin",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Les Montets",
    community_code: "2050",
    latitude: "46.7989",
    longitude: "6.8503",
  },
  {
    country_code: "CH",
    zipcode: "1484",
    place: "Aumont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Les Montets",
    community_code: "2050",
    latitude: "46.7989",
    longitude: "6.8503",
  },
  {
    country_code: "CH",
    zipcode: "1485",
    place: "Nuvilly",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Nuvilly",
    community_code: "2035",
    latitude: "46.7834",
    longitude: "6.8278",
  },
  {
    country_code: "CH",
    zipcode: "1486",
    place: "Vuissens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.7334",
    longitude: "6.7662",
  },
  {
    country_code: "CH",
    zipcode: "1489",
    place: "Murist",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.7902",
    longitude: "6.8071",
  },
  {
    country_code: "CH",
    zipcode: "1527",
    place: "Villeneuve FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Surpierre",
    community_code: "2044",
    latitude: "46.7421",
    longitude: "6.8498",
  },
  {
    country_code: "CH",
    zipcode: "1528",
    place: "Surpierre",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Surpierre",
    community_code: "2044",
    latitude: "46.7421",
    longitude: "6.8498",
  },
  {
    country_code: "CH",
    zipcode: "1528",
    place: "Praratoud",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Surpierre",
    community_code: "2044",
    latitude: "46.7353",
    longitude: "6.8489",
  },
  {
    country_code: "CH",
    zipcode: "1529",
    place: "Cheiry",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cheiry",
    community_code: "2009",
    latitude: "46.7504",
    longitude: "6.8355",
  },
  {
    country_code: "CH",
    zipcode: "1532",
    place: "Fétigny",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Fétigny",
    community_code: "2016",
    latitude: "46.7982",
    longitude: "6.9117",
  },
  {
    country_code: "CH",
    zipcode: "1533",
    place: "Ménières",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Ménières",
    community_code: "2027",
    latitude: "46.7827",
    longitude: "6.8801",
  },
  {
    country_code: "CH",
    zipcode: "1534",
    place: "Chapelle (Broye)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Cheiry",
    community_code: "2009",
    latitude: "46.7714",
    longitude: "6.8524",
  },
  {
    country_code: "CH",
    zipcode: "1541",
    place: "Morens FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8436",
    longitude: "6.907",
  },
  {
    country_code: "CH",
    zipcode: "1541",
    place: "Bussy FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.8354",
    longitude: "6.8886",
  },
  {
    country_code: "CH",
    zipcode: "1541",
    place: "Sévaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Sévaz",
    community_code: "2043",
    latitude: "46.8398",
    longitude: "6.877",
  },
  {
    country_code: "CH",
    zipcode: "1542",
    place: "Rueyres-les-Prés",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Estavayer",
    community_code: "2054",
    latitude: "46.857",
    longitude: "6.9111",
  },
  {
    country_code: "CH",
    zipcode: "1544",
    place: "Gletterens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Gletterens",
    community_code: "2022",
    latitude: "46.8949",
    longitude: "6.9369",
  },
  {
    country_code: "CH",
    zipcode: "1563",
    place: "Dompierre FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Belmont-Broye",
    community_code: "2053",
    latitude: "46.8521",
    longitude: "6.9907",
  },
  {
    country_code: "CH",
    zipcode: "1564",
    place: "Domdidier",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Belmont-Broye",
    community_code: "2053",
    latitude: "46.8672",
    longitude: "7.0134",
  },
  {
    country_code: "CH",
    zipcode: "1565",
    place: "Vallon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Vallon",
    community_code: "2045",
    latitude: "46.8838",
    longitude: "6.9554",
  },
  {
    country_code: "CH",
    zipcode: "1566",
    place: "Les Friques",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Saint-Aubin (FR)",
    community_code: "2041",
    latitude: "46.9043",
    longitude: "6.9872",
  },
  {
    country_code: "CH",
    zipcode: "1566",
    place: "St-Aubin FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Saint-Aubin (FR)",
    community_code: "2041",
    latitude: "46.89",
    longitude: "6.9805",
  },
  {
    country_code: "CH",
    zipcode: "1567",
    place: "Delley",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Delley-Portalban",
    community_code: "2051",
    latitude: "46.9198",
    longitude: "6.9577",
  },
  {
    country_code: "CH",
    zipcode: "1568",
    place: "Portalban",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Delley-Portalban",
    community_code: "2051",
    latitude: "46.9189",
    longitude: "6.9563",
  },
  {
    country_code: "CH",
    zipcode: "1773",
    place: "Russy",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Belmont-Broye",
    community_code: "2053",
    latitude: "46.8413",
    longitude: "7.0035",
  },
  {
    country_code: "CH",
    zipcode: "1773",
    place: "Chandon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Belmont-Broye",
    community_code: "2053",
    latitude: "46.8405",
    longitude: "7.0489",
  },
  {
    country_code: "CH",
    zipcode: "1773",
    place: "Léchelles",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Belmont-Broye",
    community_code: "2053",
    latitude: "46.829",
    longitude: "7.0162",
  },
  {
    country_code: "CH",
    zipcode: "1774",
    place: "Montagny-les-Monts",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.8107",
    longitude: "6.9915",
  },
  {
    country_code: "CH",
    zipcode: "1774",
    place: "Cousset",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.8192",
    longitude: "6.9793",
  },
  {
    country_code: "CH",
    zipcode: "1774",
    place: "Cousset",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.8192",
    longitude: "6.9793",
  },
  {
    country_code: "CH",
    zipcode: "1775",
    place: "Mannens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.7937",
    longitude: "6.9689",
  },
  {
    country_code: "CH",
    zipcode: "1775",
    place: "Grandsivaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.785",
    longitude: "6.9808",
  },
  {
    country_code: "CH",
    zipcode: "1776",
    place: "Montagny-la-Ville",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Broye District",
    province_code: "1001",
    community: "Montagny (FR)",
    community_code: "2029",
    latitude: "46.8175",
    longitude: "6.9957",
  },
  {
    country_code: "CH",
    zipcode: "1553",
    place: "Châtonnaye",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Châtonnaye",
    community_code: "2068",
    latitude: "46.7545",
    longitude: "6.938",
  },
  {
    country_code: "CH",
    zipcode: "1608",
    place: "Chapelle (Glâne)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Chapelle (Glâne)",
    community_code: "2066",
    latitude: "46.5887",
    longitude: "6.8372",
  },
  {
    country_code: "CH",
    zipcode: "1670",
    place: "Esmonts",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6432",
    longitude: "6.8451",
  },
  {
    country_code: "CH",
    zipcode: "1670",
    place: "Ursy",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6335",
    longitude: "6.8371",
  },
  {
    country_code: "CH",
    zipcode: "1670",
    place: "Bionnens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6351",
    longitude: "6.8555",
  },
  {
    country_code: "CH",
    zipcode: "1673",
    place: "Rue",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Rue",
    community_code: "2097",
    latitude: "46.6192",
    longitude: "6.8223",
  },
  {
    country_code: "CH",
    zipcode: "1673",
    place: "Promasens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Rue",
    community_code: "2097",
    latitude: "46.6028",
    longitude: "6.8238",
  },
  {
    country_code: "CH",
    zipcode: "1673",
    place: "Auboranges",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Auboranges",
    community_code: "2061",
    latitude: "46.5846",
    longitude: "6.8045",
  },
  {
    country_code: "CH",
    zipcode: "1673",
    place: "Gillarens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Rue",
    community_code: "2097",
    latitude: "46.5889",
    longitude: "6.8281",
  },
  {
    country_code: "CH",
    zipcode: "1673",
    place: "Ecublens FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ecublens (FR)",
    community_code: "2072",
    latitude: "46.6074",
    longitude: "6.8089",
  },
  {
    country_code: "CH",
    zipcode: "1674",
    place: "Morlens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6542",
    longitude: "6.8374",
  },
  {
    country_code: "CH",
    zipcode: "1674",
    place: "Vuarmarens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6478",
    longitude: "6.8287",
  },
  {
    country_code: "CH",
    zipcode: "1674",
    place: "Montet (Glâne)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Montet (Glâne)",
    community_code: "2089",
    latitude: "46.6413",
    longitude: "6.813",
  },
  {
    country_code: "CH",
    zipcode: "1675",
    place: "Blessens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Rue",
    community_code: "2097",
    latitude: "46.612",
    longitude: "6.8388",
  },
  {
    country_code: "CH",
    zipcode: "1675",
    place: "Vauderens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6228",
    longitude: "6.8507",
  },
  {
    country_code: "CH",
    zipcode: "1675",
    place: "Mossel",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Ursy",
    community_code: "2102",
    latitude: "46.6086",
    longitude: "6.8516",
  },
  {
    country_code: "CH",
    zipcode: "1676",
    place: "Chavannes-les-Forts",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Siviriez",
    community_code: "2099",
    latitude: "46.653",
    longitude: "6.8985",
  },
  {
    country_code: "CH",
    zipcode: "1677",
    place: "Prez-vers-Siviriez",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Siviriez",
    community_code: "2099",
    latitude: "46.6397",
    longitude: "6.8748",
  },
  {
    country_code: "CH",
    zipcode: "1678",
    place: "Siviriez",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Siviriez",
    community_code: "2099",
    latitude: "46.6585",
    longitude: "6.8777",
  },
  {
    country_code: "CH",
    zipcode: "1679",
    place: "Villaraboud",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Siviriez",
    community_code: "2099",
    latitude: "46.6607",
    longitude: "6.9135",
  },
  {
    country_code: "CH",
    zipcode: "1680",
    place: "Berlens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Mézières (FR)",
    community_code: "2087",
    latitude: "46.6959",
    longitude: "6.9541",
  },
  {
    country_code: "CH",
    zipcode: "1680",
    place: "Romont FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Romont (FR)",
    community_code: "2096",
    latitude: "46.6965",
    longitude: "6.919",
  },
  {
    country_code: "CH",
    zipcode: "1681",
    place: "Billens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Billens-Hennens",
    community_code: "2063",
    latitude: "46.6922",
    longitude: "6.9008",
  },
  {
    country_code: "CH",
    zipcode: "1681",
    place: "Hennens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Billens-Hennens",
    community_code: "2063",
    latitude: "46.6837",
    longitude: "6.8864",
  },
  {
    country_code: "CH",
    zipcode: "1684",
    place: "Mézières FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Mézières (FR)",
    community_code: "2087",
    latitude: "46.6796",
    longitude: "6.9263",
  },
  {
    country_code: "CH",
    zipcode: "1685",
    place: "Villariaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6662",
    longitude: "6.9409",
  },
  {
    country_code: "CH",
    zipcode: "1686",
    place: "La Neirigue",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.683",
    longitude: "6.9525",
  },
  {
    country_code: "CH",
    zipcode: "1686",
    place: "Grangettes-près-Romont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Grangettes",
    community_code: "2079",
    latitude: "46.6717",
    longitude: "6.9753",
  },
  {
    country_code: "CH",
    zipcode: "1687",
    place: "Estévenens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6703",
    longitude: "6.9551",
  },
  {
    country_code: "CH",
    zipcode: "1687",
    place: "Vuisternens-devant-Romont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6478",
    longitude: "6.9411",
  },
  {
    country_code: "CH",
    zipcode: "1687",
    place: "La Magne",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6347",
    longitude: "6.9323",
  },
  {
    country_code: "CH",
    zipcode: "1688",
    place: "Sommentier",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6397",
    longitude: "6.9131",
  },
  {
    country_code: "CH",
    zipcode: "1688",
    place: "Lieffrens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.638",
    longitude: "6.9017",
  },
  {
    country_code: "CH",
    zipcode: "1689",
    place: "Le Châtelard-près-Romont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Le Châtelard",
    community_code: "2067",
    latitude: "46.6804",
    longitude: "6.9792",
  },
  {
    country_code: "CH",
    zipcode: "1690",
    place: "Villaz-St-Pierre",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Villaz-Saint-Pierre",
    community_code: "2111",
    latitude: "46.7207",
    longitude: "6.9564",
  },
  {
    country_code: "CH",
    zipcode: "1690",
    place: "Lussy FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "La Folliaz",
    community_code: "2116",
    latitude: "46.7173",
    longitude: "6.948",
  },
  {
    country_code: "CH",
    zipcode: "1691",
    place: "Villarimboud",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "La Folliaz",
    community_code: "2116",
    latitude: "46.7417",
    longitude: "6.9652",
  },
  {
    country_code: "CH",
    zipcode: "1692",
    place: "Massonnens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Massonnens",
    community_code: "2086",
    latitude: "46.6966",
    longitude: "6.9762",
  },
  {
    country_code: "CH",
    zipcode: "1694",
    place: "Orsonnens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Villorsonnens",
    community_code: "2114",
    latitude: "46.7157",
    longitude: "6.995",
  },
  {
    country_code: "CH",
    zipcode: "1694",
    place: "Villarsiviriaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Villorsonnens",
    community_code: "2114",
    latitude: "46.6994",
    longitude: "7.0103",
  },
  {
    country_code: "CH",
    zipcode: "1694",
    place: "Villargiroud",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Villorsonnens",
    community_code: "2114",
    latitude: "46.7055",
    longitude: "7.0022",
  },
  {
    country_code: "CH",
    zipcode: "1694",
    place: "Chavannes-sous-Orsonnens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Villorsonnens",
    community_code: "2114",
    latitude: "46.7273",
    longitude: "6.9917",
  },
  {
    country_code: "CH",
    zipcode: "1697",
    place: "La Joux FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.6306",
    longitude: "6.9401",
  },
  {
    country_code: "CH",
    zipcode: "1697",
    place: "Les Ecasseys",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Vuisternens-devant-Romont",
    community_code: "2113",
    latitude: "46.623",
    longitude: "6.9108",
  },
  {
    country_code: "CH",
    zipcode: "1748",
    place: "Torny-le-Grand",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Torny",
    community_code: "2115",
    latitude: "46.772",
    longitude: "6.9667",
  },
  {
    country_code: "CH",
    zipcode: "1749",
    place: "Middes",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Glâne District",
    province_code: "1002",
    community: "Torny",
    community_code: "2115",
    latitude: "46.7692",
    longitude: "6.9502",
  },
  {
    country_code: "CH",
    zipcode: "1625",
    place: "Maules",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sâles",
    community_code: "2152",
    latitude: "46.64",
    longitude: "6.9921",
  },
  {
    country_code: "CH",
    zipcode: "1625",
    place: "Sâles (Gruyère)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sâles",
    community_code: "2152",
    latitude: "46.6347",
    longitude: "6.9734",
  },
  {
    country_code: "CH",
    zipcode: "1626",
    place: "Treyfayes",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sâles",
    community_code: "2152",
    latitude: "46.6541",
    longitude: "6.9533",
  },
  {
    country_code: "CH",
    zipcode: "1626",
    place: "Romanens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sâles",
    community_code: "2152",
    latitude: "46.652",
    longitude: "6.9714",
  },
  {
    country_code: "CH",
    zipcode: "1626",
    place: "Rueyres-Treyfayes",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sâles",
    community_code: "2152",
    latitude: "46.653",
    longitude: "6.9623",
  },
  {
    country_code: "CH",
    zipcode: "1627",
    place: "Vaulruz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Vaulruz",
    community_code: "2155",
    latitude: "46.6216",
    longitude: "6.9882",
  },
  {
    country_code: "CH",
    zipcode: "1628",
    place: "Vuadens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Vuadens",
    community_code: "2160",
    latitude: "46.6155",
    longitude: "7.0173",
  },
  {
    country_code: "CH",
    zipcode: "1630",
    place: "Bulle",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Bulle",
    community_code: "2125",
    latitude: "46.618",
    longitude: "7.0569",
  },
  {
    country_code: "CH",
    zipcode: "1632",
    place: "Riaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Riaz",
    community_code: "2148",
    latitude: "46.6422",
    longitude: "7.0618",
  },
  {
    country_code: "CH",
    zipcode: "1633",
    place: "Marsens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Marsens",
    community_code: "2140",
    latitude: "46.6564",
    longitude: "7.0595",
  },
  {
    country_code: "CH",
    zipcode: "1633",
    place: "Vuippens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Marsens",
    community_code: "2140",
    latitude: "46.66",
    longitude: "7.0746",
  },
  {
    country_code: "CH",
    zipcode: "1634",
    place: "La Roche FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "La Roche",
    community_code: "2149",
    latitude: "46.6962",
    longitude: "7.1372",
  },
  {
    country_code: "CH",
    zipcode: "1635",
    place: "La Tour-de-Trême",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Bulle",
    community_code: "2125",
    latitude: "46.6106",
    longitude: "7.065",
  },
  {
    country_code: "CH",
    zipcode: "1636",
    place: "Broc",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Broc",
    community_code: "2124",
    latitude: "46.6051",
    longitude: "7.0989",
  },
  {
    country_code: "CH",
    zipcode: "1637",
    place: "Charmey (Gruyère)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Val-de-Charmey",
    community_code: "2163",
    latitude: "46.6196",
    longitude: "7.1649",
  },
  {
    country_code: "CH",
    zipcode: "1638",
    place: "Morlon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Morlon",
    community_code: "2143",
    latitude: "46.6256",
    longitude: "7.0838",
  },
  {
    country_code: "CH",
    zipcode: "1642",
    place: "Sorens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Sorens",
    community_code: "2153",
    latitude: "46.6691",
    longitude: "7.0525",
  },
  {
    country_code: "CH",
    zipcode: "1643",
    place: "Gumefens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Pont-en-Ogoz",
    community_code: "2122",
    latitude: "46.6779",
    longitude: "7.0766",
  },
  {
    country_code: "CH",
    zipcode: "1644",
    place: "Avry-devant-Pont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Pont-en-Ogoz",
    community_code: "2122",
    latitude: "46.6871",
    longitude: "7.0876",
  },
  {
    country_code: "CH",
    zipcode: "1645",
    place: "Le Bry",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Pont-en-Ogoz",
    community_code: "2122",
    latitude: "46.7046",
    longitude: "7.0825",
  },
  {
    country_code: "CH",
    zipcode: "1646",
    place: "Echarlens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Echarlens",
    community_code: "2131",
    latitude: "46.6478",
    longitude: "7.0742",
  },
  {
    country_code: "CH",
    zipcode: "1647",
    place: "Corbières",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Corbières",
    community_code: "2129",
    latitude: "46.6595",
    longitude: "7.101",
  },
  {
    country_code: "CH",
    zipcode: "1648",
    place: "Hauteville",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Hauteville",
    community_code: "2137",
    latitude: "46.6701",
    longitude: "7.1104",
  },
  {
    country_code: "CH",
    zipcode: "1649",
    place: "Pont-la-Ville",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Pont-la-Ville",
    community_code: "2147",
    latitude: "46.7",
    longitude: "7.1",
  },
  {
    country_code: "CH",
    zipcode: "1651",
    place: "Villarvolard",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Corbières",
    community_code: "2129",
    latitude: "46.6453",
    longitude: "7.1074",
  },
  {
    country_code: "CH",
    zipcode: "1652",
    place: "Botterens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Botterens",
    community_code: "2123",
    latitude: "46.616",
    longitude: "7.1117",
  },
  {
    country_code: "CH",
    zipcode: "1652",
    place: "Villarbeney",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Botterens",
    community_code: "2123",
    latitude: "46.6302",
    longitude: "7.1095",
  },
  {
    country_code: "CH",
    zipcode: "1653",
    place: "Châtel-sur-Montsalvens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Châtel-sur-Montsalvens",
    community_code: "2128",
    latitude: "46.6165",
    longitude: "7.1327",
  },
  {
    country_code: "CH",
    zipcode: "1653",
    place: "Crésuz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Crésuz",
    community_code: "2130",
    latitude: "46.6206",
    longitude: "7.1426",
  },
  {
    country_code: "CH",
    zipcode: "1654",
    place: "Cerniat FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Val-de-Charmey",
    community_code: "2163",
    latitude: "46.6333",
    longitude: "7.1573",
  },
  {
    country_code: "CH",
    zipcode: "1656",
    place: "Jaun",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Jaun",
    community_code: "2138",
    latitude: "46.6113",
    longitude: "7.2759",
  },
  {
    country_code: "CH",
    zipcode: "1656",
    place: "Im Fang",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Jaun",
    community_code: "2138",
    latitude: "46.5988",
    longitude: "7.2362",
  },
  {
    country_code: "CH",
    zipcode: "1661",
    place: "Le Pâquier-Montbarry",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Le Pâquier (FR)",
    community_code: "2145",
    latitude: "46.5948",
    longitude: "7.0533",
  },
  {
    country_code: "CH",
    zipcode: "1663",
    place: "Pringy",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Gruyères",
    community_code: "2135",
    latitude: "46.5823",
    longitude: "7.0733",
  },
  {
    country_code: "CH",
    zipcode: "1663",
    place: "Epagny",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Gruyères",
    community_code: "2135",
    latitude: "46.5896",
    longitude: "7.0856",
  },
  {
    country_code: "CH",
    zipcode: "1663",
    place: "Gruyères",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Gruyères",
    community_code: "2135",
    latitude: "46.5834",
    longitude: "7.0821",
  },
  {
    country_code: "CH",
    zipcode: "1663",
    place: "Moléson-sur-Gruyères",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Gruyères",
    community_code: "2135",
    latitude: "46.5851",
    longitude: "7.0803",
  },
  {
    country_code: "CH",
    zipcode: "1665",
    place: "Estavannens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Bas-Intyamon",
    community_code: "2162",
    latitude: "46.5605",
    longitude: "7.1019",
  },
  {
    country_code: "CH",
    zipcode: "1666",
    place: "Grandvillard",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Grandvillard",
    community_code: "2134",
    latitude: "46.539",
    longitude: "7.0857",
  },
  {
    country_code: "CH",
    zipcode: "1666",
    place: "Villars-sous-Mont",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Bas-Intyamon",
    community_code: "2162",
    latitude: "46.5412",
    longitude: "7.0704",
  },
  {
    country_code: "CH",
    zipcode: "1667",
    place: "Enney",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Bas-Intyamon",
    community_code: "2162",
    latitude: "46.5667",
    longitude: "7.0842",
  },
  {
    country_code: "CH",
    zipcode: "1669",
    place: "Neirivue",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Haut-Intyamon",
    community_code: "2121",
    latitude: "46.525",
    longitude: "7.0582",
  },
  {
    country_code: "CH",
    zipcode: "1669",
    place: "Albeuve",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Haut-Intyamon",
    community_code: "2121",
    latitude: "46.5173",
    longitude: "7.0567",
  },
  {
    country_code: "CH",
    zipcode: "1669",
    place: "Lessoc",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Haut-Intyamon",
    community_code: "2121",
    latitude: "46.5048",
    longitude: "7.0621",
  },
  {
    country_code: "CH",
    zipcode: "1669",
    place: "Montbovon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Haut-Intyamon",
    community_code: "2121",
    latitude: "46.4882",
    longitude: "7.0437",
  },
  {
    country_code: "CH",
    zipcode: "1669",
    place: "Les Sciernes-d'Albeuve",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Gruyère District",
    province_code: "1003",
    community: "Haut-Intyamon",
    community_code: "2121",
    latitude: "46.4958",
    longitude: "7.0337",
  },
  {
    country_code: "CH",
    zipcode: "1695",
    place: "Villarlod",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7066",
    longitude: "7.0218",
  },
  {
    country_code: "CH",
    zipcode: "1695",
    place: "Estavayer-le-Gibloux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7241",
    longitude: "7.0263",
  },
  {
    country_code: "CH",
    zipcode: "1695",
    place: "Villarsel-le-Gibloux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.713",
    longitude: "7.0177",
  },
  {
    country_code: "CH",
    zipcode: "1695",
    place: "Rueyres-St-Laurent",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7129",
    longitude: "7.0361",
  },
  {
    country_code: "CH",
    zipcode: "1696",
    place: "Vuisternens-en-Ogoz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7081",
    longitude: "7.054",
  },
  {
    country_code: "CH",
    zipcode: "1700",
    place: "Fribourg",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Fribourg",
    community_code: "2196",
    latitude: "46.8024",
    longitude: "7.1513",
  },
  {
    country_code: "CH",
    zipcode: "1720",
    place: "Corminboeuf",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Corminboeuf",
    community_code: "2183",
    latitude: "46.8103",
    longitude: "7.1054",
  },
  {
    country_code: "CH",
    zipcode: "1720",
    place: "Chésopelloz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Corminboeuf",
    community_code: "2183",
    latitude: "46.8022",
    longitude: "7.0963",
  },
  {
    country_code: "CH",
    zipcode: "1722",
    place: "Bourguillon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Fribourg",
    community_code: "2196",
    latitude: "46.8013",
    longitude: "7.1777",
  },
  {
    country_code: "CH",
    zipcode: "1723",
    place: "Pierrafortscha",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Pierrafortscha",
    community_code: "2216",
    latitude: "46.7748",
    longitude: "7.1858",
  },
  {
    country_code: "CH",
    zipcode: "1723",
    place: "Marly",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Marly",
    community_code: "2206",
    latitude: "46.7761",
    longitude: "7.1646",
  },
  {
    country_code: "CH",
    zipcode: "1723",
    place: "Villarsel-sur-Marly",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Villarsel-sur-Marly",
    community_code: "2230",
    latitude: "46.7618",
    longitude: "7.172",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Bonnefontaine",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.7414",
    longitude: "7.2011",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Zénauva",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.735",
    longitude: "7.182",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Essert FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.7392",
    longitude: "7.1645",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Le Mouret",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.7318",
    longitude: "7.1875",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Senèdes",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Senèdes",
    community_code: "2225",
    latitude: "46.7418",
    longitude: "7.1419",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Oberried FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.7381",
    longitude: "7.1867",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Montévraz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Le Mouret",
    community_code: "2220",
    latitude: "46.7263",
    longitude: "7.1759",
  },
  {
    country_code: "CH",
    zipcode: "1724",
    place: "Ferpicloz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Ferpicloz",
    community_code: "2194",
    latitude: "46.7482",
    longitude: "7.1622",
  },
  {
    country_code: "CH",
    zipcode: "1725",
    place: "Posieux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Hauterive (FR)",
    community_code: "2233",
    latitude: "46.7623",
    longitude: "7.0971",
  },
  {
    country_code: "CH",
    zipcode: "1726",
    place: "Posat",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7389",
    longitude: "7.0584",
  },
  {
    country_code: "CH",
    zipcode: "1726",
    place: "Grenilles",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7285",
    longitude: "7.0503",
  },
  {
    country_code: "CH",
    zipcode: "1726",
    place: "Farvagny-le-Petit",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7287",
    longitude: "7.0727",
  },
  {
    country_code: "CH",
    zipcode: "1726",
    place: "Farvagny",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7277",
    longitude: "7.0663",
  },
  {
    country_code: "CH",
    zipcode: "1727",
    place: "Corpataux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7433",
    longitude: "7.0971",
  },
  {
    country_code: "CH",
    zipcode: "1727",
    place: "Magnedens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7435",
    longitude: "7.0823",
  },
  {
    country_code: "CH",
    zipcode: "1728",
    place: "Rossens FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Gibloux",
    community_code: "2236",
    latitude: "46.7201",
    longitude: "7.1034",
  },
  {
    country_code: "CH",
    zipcode: "1730",
    place: "Ecuvillens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Hauterive (FR)",
    community_code: "2233",
    latitude: "46.7578",
    longitude: "7.0828",
  },
  {
    country_code: "CH",
    zipcode: "1731",
    place: "Ependes FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Ependes (FR)",
    community_code: "2189",
    latitude: "46.7537",
    longitude: "7.1461",
  },
  {
    country_code: "CH",
    zipcode: "1732",
    place: "Arconciel",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Arconciel",
    community_code: "2171",
    latitude: "46.7473",
    longitude: "7.1215",
  },
  {
    country_code: "CH",
    zipcode: "1733",
    place: "Treyvaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Treyvaux",
    community_code: "2226",
    latitude: "46.728",
    longitude: "7.1377",
  },
  {
    country_code: "CH",
    zipcode: "1740",
    place: "Neyruz FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Neyruz (FR)",
    community_code: "2211",
    latitude: "46.7681",
    longitude: "7.0669",
  },
  {
    country_code: "CH",
    zipcode: "1741",
    place: "Cottens FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Cottens (FR)",
    community_code: "2186",
    latitude: "46.7522",
    longitude: "7.0313",
  },
  {
    country_code: "CH",
    zipcode: "1742",
    place: "Autigny",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Autigny",
    community_code: "2173",
    latitude: "46.7366",
    longitude: "7.02",
  },
  {
    country_code: "CH",
    zipcode: "1744",
    place: "Chénens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Chénens",
    community_code: "2177",
    latitude: "46.7416",
    longitude: "7.004",
  },
  {
    country_code: "CH",
    zipcode: "1745",
    place: "Lentigny",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Brillaz",
    community_code: "2234",
    latitude: "46.7611",
    longitude: "7.0029",
  },
  {
    country_code: "CH",
    zipcode: "1746",
    place: "Prez-vers-Noréaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Prez-vers-Noréaz",
    community_code: "2221",
    latitude: "46.7854",
    longitude: "7.0158",
  },
  {
    country_code: "CH",
    zipcode: "1747",
    place: "Corserey",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Corserey",
    community_code: "2185",
    latitude: "46.7731",
    longitude: "6.9887",
  },
  {
    country_code: "CH",
    zipcode: "1752",
    place: "Villars-sur-Glâne",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Villars-sur-Glâne",
    community_code: "2228",
    latitude: "46.7905",
    longitude: "7.1172",
  },
  {
    country_code: "CH",
    zipcode: "1753",
    place: "Matran",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Matran",
    community_code: "2208",
    latitude: "46.7859",
    longitude: "7.0977",
  },
  {
    country_code: "CH",
    zipcode: "1754",
    place: "Avry-sur-Matran",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Avry",
    community_code: "2174",
    latitude: "46.7875",
    longitude: "7.0673",
  },
  {
    country_code: "CH",
    zipcode: "1754",
    place: "Corjolens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Avry",
    community_code: "2174",
    latitude: "46.7869",
    longitude: "7.0465",
  },
  {
    country_code: "CH",
    zipcode: "1754",
    place: "Rosé",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Avry",
    community_code: "2174",
    latitude: "46.784",
    longitude: "7.0629",
  },
  {
    country_code: "CH",
    zipcode: "1756",
    place: "Onnens FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Brillaz",
    community_code: "2234",
    latitude: "46.7747",
    longitude: "7.0371",
  },
  {
    country_code: "CH",
    zipcode: "1756",
    place: "Lovens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Brillaz",
    community_code: "2234",
    latitude: "46.7741",
    longitude: "7.0211",
  },
  {
    country_code: "CH",
    zipcode: "1757",
    place: "Noréaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Noréaz",
    community_code: "2213",
    latitude: "46.8015",
    longitude: "7.0278",
  },
  {
    country_code: "CH",
    zipcode: "1762",
    place: "Givisiez",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Givisiez",
    community_code: "2197",
    latitude: "46.812",
    longitude: "7.1264",
  },
  {
    country_code: "CH",
    zipcode: "1763",
    place: "Granges-Paccot",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Granges-Paccot",
    community_code: "2198",
    latitude: "46.8267",
    longitude: "7.1425",
  },
  {
    country_code: "CH",
    zipcode: "1772",
    place: "Grolley",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Grolley",
    community_code: "2200",
    latitude: "46.8336",
    longitude: "7.0712",
  },
  {
    country_code: "CH",
    zipcode: "1772",
    place: "Ponthaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Ponthaux",
    community_code: "2217",
    latitude: "46.8153",
    longitude: "7.0414",
  },
  {
    country_code: "CH",
    zipcode: "1772",
    place: "Nierlet-les-Bois",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Ponthaux",
    community_code: "2217",
    latitude: "46.8213",
    longitude: "7.0585",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "La Corbaz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Sonnaz",
    community_code: "2235",
    latitude: "46.8404",
    longitude: "7.1159",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "Cormagens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Sonnaz",
    community_code: "2235",
    latitude: "46.8359",
    longitude: "7.1359",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "Belfaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Belfaux",
    community_code: "2175",
    latitude: "46.8217",
    longitude: "7.1067",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "Lossy",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Sonnaz",
    community_code: "2235",
    latitude: "46.8351",
    longitude: "7.1095",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "Autafond",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "Belfaux",
    community_code: "2175",
    latitude: "46.8288",
    longitude: "7.095",
  },
  {
    country_code: "CH",
    zipcode: "1782",
    place: "Formangueires",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sarine District",
    province_code: "1004",
    community: "La Sonnaz",
    community_code: "2235",
    latitude: "46.8272",
    longitude: "7.1166",
  },
  {
    country_code: "CH",
    zipcode: "1583",
    place: "Villarepos",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8643",
    longitude: "7.1264",
  },
  {
    country_code: "CH",
    zipcode: "1721",
    place: "Misery",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Misery-Courtion",
    community_code: "2272",
    latitude: "46.8524",
    longitude: "7.0639",
  },
  {
    country_code: "CH",
    zipcode: "1721",
    place: "Cournillens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Misery-Courtion",
    community_code: "2272",
    latitude: "46.8587",
    longitude: "7.1026",
  },
  {
    country_code: "CH",
    zipcode: "1721",
    place: "Cormérod",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Misery-Courtion",
    community_code: "2272",
    latitude: "46.8671",
    longitude: "7.0896",
  },
  {
    country_code: "CH",
    zipcode: "1721",
    place: "Courtion",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Misery-Courtion",
    community_code: "2272",
    latitude: "46.8572",
    longitude: "7.0689",
  },
  {
    country_code: "CH",
    zipcode: "1783",
    place: "Barberêche",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8643",
    longitude: "7.1264",
  },
  {
    country_code: "CH",
    zipcode: "1783",
    place: "Pensier",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8643",
    longitude: "7.1264",
  },
  {
    country_code: "CH",
    zipcode: "1784",
    place: "Courtepin",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8655",
    longitude: "7.1231",
  },
  {
    country_code: "CH",
    zipcode: "1784",
    place: "Wallenried",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8643",
    longitude: "7.1264",
  },
  {
    country_code: "CH",
    zipcode: "1785",
    place: "Cressier FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Cressier (FR)",
    community_code: "2257",
    latitude: "46.8979",
    longitude: "7.1407",
  },
  {
    country_code: "CH",
    zipcode: "1786",
    place: "Sugiez",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Mont-Vully",
    community_code: "2284",
    latitude: "46.9619",
    longitude: "7.1129",
  },
  {
    country_code: "CH",
    zipcode: "1787",
    place: "Mur (Vully) FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Mont-Vully",
    community_code: "2284",
    latitude: "46.9461",
    longitude: "7.0644",
  },
  {
    country_code: "CH",
    zipcode: "1787",
    place: "Môtier (Vully)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Mont-Vully",
    community_code: "2284",
    latitude: "46.9495",
    longitude: "7.084",
  },
  {
    country_code: "CH",
    zipcode: "1788",
    place: "Praz (Vully)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Mont-Vully",
    community_code: "2284",
    latitude: "46.9531",
    longitude: "7.098",
  },
  {
    country_code: "CH",
    zipcode: "1789",
    place: "Lugnorre",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Mont-Vully",
    community_code: "2284",
    latitude: "46.951",
    longitude: "7.0749",
  },
  {
    country_code: "CH",
    zipcode: "1791",
    place: "Courtaman",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courtepin",
    community_code: "2254",
    latitude: "46.8734",
    longitude: "7.1316",
  },
  {
    country_code: "CH",
    zipcode: "1792",
    place: "Guschelmuth",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.8866",
    longitude: "7.1433",
  },
  {
    country_code: "CH",
    zipcode: "1792",
    place: "Cordast",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.876",
    longitude: "7.1521",
  },
  {
    country_code: "CH",
    zipcode: "1793",
    place: "Jeuss",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9365",
    longitude: "7.1412",
  },
  {
    country_code: "CH",
    zipcode: "1794",
    place: "Salvenach",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9365",
    longitude: "7.1412",
  },
  {
    country_code: "CH",
    zipcode: "1795",
    place: "Courlevon",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9365",
    longitude: "7.1412",
  },
  {
    country_code: "CH",
    zipcode: "1796",
    place: "Courgevaux",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Courgevaux",
    community_code: "2250",
    latitude: "46.9065",
    longitude: "7.1121",
  },
  {
    country_code: "CH",
    zipcode: "3206",
    place: "Wallenbuch",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.9295",
    longitude: "7.2254",
  },
  {
    country_code: "CH",
    zipcode: "3210",
    place: "Kerzers",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Kerzers",
    community_code: "2265",
    latitude: "46.9759",
    longitude: "7.1957",
  },
  {
    country_code: "CH",
    zipcode: "3212",
    place: "Gurmels",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.8941",
    longitude: "7.1719",
  },
  {
    country_code: "CH",
    zipcode: "3212",
    place: "Kleingurmels",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.8862",
    longitude: "7.1874",
  },
  {
    country_code: "CH",
    zipcode: "3213",
    place: "Liebistorf",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gurmels",
    community_code: "2262",
    latitude: "46.9088",
    longitude: "7.1964",
  },
  {
    country_code: "CH",
    zipcode: "3213",
    place: "Kleinbösingen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Kleinbösingen",
    community_code: "2266",
    latitude: "46.893",
    longitude: "7.2024",
  },
  {
    country_code: "CH",
    zipcode: "3214",
    place: "Ulmiz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Ulmiz",
    community_code: "2278",
    latitude: "46.9319",
    longitude: "7.1978",
  },
  {
    country_code: "CH",
    zipcode: "3215",
    place: "Gempenach",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Gempenach",
    community_code: "2260",
    latitude: "46.943",
    longitude: "7.1992",
  },
  {
    country_code: "CH",
    zipcode: "3215",
    place: "Büchslen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9459",
    longitude: "7.1816",
  },
  {
    country_code: "CH",
    zipcode: "3215",
    place: "Lurtigen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9284",
    longitude: "7.1733",
  },
  {
    country_code: "CH",
    zipcode: "3216",
    place: "Agriswil",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Ried bei Kerzers",
    community_code: "2276",
    latitude: "46.9555",
    longitude: "7.1978",
  },
  {
    country_code: "CH",
    zipcode: "3216",
    place: "Ried b. Kerzers",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Ried bei Kerzers",
    community_code: "2276",
    latitude: "46.9555",
    longitude: "7.1978",
  },
  {
    country_code: "CH",
    zipcode: "3280",
    place: "Meyriez",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Meyriez",
    community_code: "2271",
    latitude: "46.9237",
    longitude: "7.1066",
  },
  {
    country_code: "CH",
    zipcode: "3280",
    place: "Murten",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Murten",
    community_code: "2275",
    latitude: "46.9283",
    longitude: "7.1171",
  },
  {
    country_code: "CH",
    zipcode: "3280",
    place: "Greng",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Greng",
    community_code: "2261",
    latitude: "46.9134",
    longitude: "7.094",
  },
  {
    country_code: "CH",
    zipcode: "3284",
    place: "Fräschels",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Fräschels",
    community_code: "2258",
    latitude: "46.9979",
    longitude: "7.2081",
  },
  {
    country_code: "CH",
    zipcode: "3285",
    place: "Galmiz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Galmiz",
    community_code: "2259",
    latitude: "46.9488",
    longitude: "7.158",
  },
  {
    country_code: "CH",
    zipcode: "3286",
    place: "Muntelier",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Lake District",
    province_code: "1005",
    community: "Muntelier",
    community_code: "2274",
    latitude: "46.9352",
    longitude: "7.1236",
  },
  {
    country_code: "CH",
    zipcode: "1712",
    place: "Tafers",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Tafers",
    community_code: "2306",
    latitude: "46.8148",
    longitude: "7.2185",
  },
  {
    country_code: "CH",
    zipcode: "1713",
    place: "St. Antoni",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "St. Antoni",
    community_code: "2302",
    latitude: "46.8221",
    longitude: "7.2609",
  },
  {
    country_code: "CH",
    zipcode: "1714",
    place: "Heitenried",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Heitenried",
    community_code: "2296",
    latitude: "46.8276",
    longitude: "7.2994",
  },
  {
    country_code: "CH",
    zipcode: "1715",
    place: "Alterswil FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Alterswil",
    community_code: "2291",
    latitude: "46.7959",
    longitude: "7.2588",
  },
  {
    country_code: "CH",
    zipcode: "1716",
    place: "Oberschrot",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Plaffeien",
    community_code: "2299",
    latitude: "46.7413",
    longitude: "7.2815",
  },
  {
    country_code: "CH",
    zipcode: "1716",
    place: "Schwarzsee",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Plaffeien",
    community_code: "2299",
    latitude: "46.671",
    longitude: "7.2818",
  },
  {
    country_code: "CH",
    zipcode: "1716",
    place: "Plaffeien",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Plaffeien",
    community_code: "2299",
    latitude: "46.742",
    longitude: "7.2867",
  },
  {
    country_code: "CH",
    zipcode: "1717",
    place: "St. Ursen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "St. Ursen",
    community_code: "2304",
    latitude: "46.7869",
    longitude: "7.2243",
  },
  {
    country_code: "CH",
    zipcode: "1718",
    place: "Rechthalten",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Rechthalten",
    community_code: "2301",
    latitude: "46.7677",
    longitude: "7.2403",
  },
  {
    country_code: "CH",
    zipcode: "1719",
    place: "Brünisried",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Brünisried",
    community_code: "2292",
    latitude: "46.7578",
    longitude: "7.2785",
  },
  {
    country_code: "CH",
    zipcode: "1719",
    place: "Zumholz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Plaffeien",
    community_code: "2299",
    latitude: "46.6871",
    longitude: "7.3155",
  },
  {
    country_code: "CH",
    zipcode: "1734",
    place: "Tentlingen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Tentlingen",
    community_code: "2307",
    latitude: "46.7692",
    longitude: "7.1977",
  },
  {
    country_code: "CH",
    zipcode: "1735",
    place: "Giffers",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Giffers",
    community_code: "2294",
    latitude: "46.7623",
    longitude: "7.2085",
  },
  {
    country_code: "CH",
    zipcode: "1736",
    place: "St. Silvester",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "St. Silvester",
    community_code: "2303",
    latitude: "46.7425",
    longitude: "7.224",
  },
  {
    country_code: "CH",
    zipcode: "1737",
    place: "Plasselb",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Plasselb",
    community_code: "2300",
    latitude: "46.7349",
    longitude: "7.2512",
  },
  {
    country_code: "CH",
    zipcode: "3175",
    place: "Flamatt",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Wünnewil-Flamatt",
    community_code: "2309",
    latitude: "46.8899",
    longitude: "7.322",
  },
  {
    country_code: "CH",
    zipcode: "3178",
    place: "Bösingen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Bösingen",
    community_code: "2295",
    latitude: "46.8923",
    longitude: "7.2277",
  },
  {
    country_code: "CH",
    zipcode: "3182",
    place: "Ueberstorf",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Ueberstorf",
    community_code: "2308",
    latitude: "46.8659",
    longitude: "7.31",
  },
  {
    country_code: "CH",
    zipcode: "3184",
    place: "Wünnewil",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Wünnewil-Flamatt",
    community_code: "2309",
    latitude: "46.874",
    longitude: "7.2773",
  },
  {
    country_code: "CH",
    zipcode: "3185",
    place: "Schmitten FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Schmitten (FR)",
    community_code: "2305",
    latitude: "46.8575",
    longitude: "7.2503",
  },
  {
    country_code: "CH",
    zipcode: "3186",
    place: "Düdingen",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Sense District",
    province_code: "1006",
    community: "Düdingen",
    community_code: "2293",
    latitude: "46.8492",
    longitude: "7.1885",
  },
  {
    country_code: "CH",
    zipcode: "1609",
    place: "Fiaugères",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Saint-Martin (FR)",
    community_code: "2335",
    latitude: "46.5873",
    longitude: "6.8927",
  },
  {
    country_code: "CH",
    zipcode: "1609",
    place: "Besencens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Saint-Martin (FR)",
    community_code: "2335",
    latitude: "46.5875",
    longitude: "6.8687",
  },
  {
    country_code: "CH",
    zipcode: "1609",
    place: "St-Martin FR",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Saint-Martin (FR)",
    community_code: "2335",
    latitude: "46.5763",
    longitude: "6.8698",
  },
  {
    country_code: "CH",
    zipcode: "1609",
    place: "Fiaugères",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Saint-Martin (FR)",
    community_code: "2335",
    latitude: "46.5873",
    longitude: "6.8927",
  },
  {
    country_code: "CH",
    zipcode: "1611",
    place: "Le Crêt-près-Semsales",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "La Verrerie",
    community_code: "2338",
    latitude: "46.609",
    longitude: "6.9097",
  },
  {
    country_code: "CH",
    zipcode: "1614",
    place: "Granges (Veveyse)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Granges (Veveyse)",
    community_code: "2328",
    latitude: "46.5261",
    longitude: "6.83",
  },
  {
    country_code: "CH",
    zipcode: "1615",
    place: "Bossonnens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Bossonnens",
    community_code: "2323",
    latitude: "46.5285",
    longitude: "6.8525",
  },
  {
    country_code: "CH",
    zipcode: "1616",
    place: "Attalens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Attalens",
    community_code: "2321",
    latitude: "46.5056",
    longitude: "6.8504",
  },
  {
    country_code: "CH",
    zipcode: "1617",
    place: "Tatroz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Attalens",
    community_code: "2321",
    latitude: "46.527",
    longitude: "6.8669",
  },
  {
    country_code: "CH",
    zipcode: "1617",
    place: "Remaufens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Remaufens",
    community_code: "2333",
    latitude: "46.5287",
    longitude: "6.8826",
  },
  {
    country_code: "CH",
    zipcode: "1618",
    place: "Châtel-St-Denis",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Châtel-Saint-Denis",
    community_code: "2325",
    latitude: "46.5269",
    longitude: "6.9008",
  },
  {
    country_code: "CH",
    zipcode: "1619",
    place: "Les Paccots",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Châtel-Saint-Denis",
    community_code: "2325",
    latitude: "46.5241",
    longitude: "6.9463",
  },
  {
    country_code: "CH",
    zipcode: "1623",
    place: "Semsales",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Semsales",
    community_code: "2336",
    latitude: "46.5732",
    longitude: "6.9295",
  },
  {
    country_code: "CH",
    zipcode: "1624",
    place: "La Verrerie",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "La Verrerie",
    community_code: "2338",
    latitude: "46.5995",
    longitude: "6.9203",
  },
  {
    country_code: "CH",
    zipcode: "1624",
    place: "Progens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "La Verrerie",
    community_code: "2338",
    latitude: "46.5848",
    longitude: "6.9111",
  },
  {
    country_code: "CH",
    zipcode: "1624",
    place: "Grattavache",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "La Verrerie",
    community_code: "2338",
    latitude: "46.596",
    longitude: "6.9134",
  },
  {
    country_code: "CH",
    zipcode: "1624",
    place: "Progens",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Semsales",
    community_code: "2336",
    latitude: "46.5699",
    longitude: "6.9557",
  },
  {
    country_code: "CH",
    zipcode: "1699",
    place: "Porsel",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Le Flon",
    community_code: "2337",
    latitude: "46.6015",
    longitude: "6.8658",
  },
  {
    country_code: "CH",
    zipcode: "1699",
    place: "Bouloz",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Le Flon",
    community_code: "2337",
    latitude: "46.6124",
    longitude: "6.8827",
  },
  {
    country_code: "CH",
    zipcode: "1699",
    place: "Pont (Veveyse)",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Le Flon",
    community_code: "2337",
    latitude: "46.5885",
    longitude: "6.8513",
  },
  {
    country_code: "CH",
    zipcode: "1699",
    place: "Porsel",
    state: "Canton de Fribourg",
    state_code: "FR",
    province: "Veveyse District",
    province_code: "1007",
    community: "Le Flon",
    community_code: "2337",
    latitude: "46.6015",
    longitude: "6.8658",
  },
  {
    country_code: "CH",
    zipcode: "1200",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1201",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1202",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1203",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1204",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1205",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1206",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1207",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1208",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1209",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2022",
    longitude: "6.1457",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 73",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 70",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 84 Votation",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 71 CS CP",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 3",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 14",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 23",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Meyrin",
    community_code: "6630",
    latitude: "46.2322",
    longitude: "6.0791",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 5",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Le Grand-Saconnex",
    community_code: "6623",
    latitude: "46.2358",
    longitude: "6.1192",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 28",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 12",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 10",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 8",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 4",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 13",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 6",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 1",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 22",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 2",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 27",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Pregny-Chambésy",
    community_code: "6634",
    latitude: "46.2379",
    longitude: "6.1424",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 26",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Lancy",
    community_code: "6628",
    latitude: "46.1897",
    longitude: "6.1158",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 20",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 19",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1211",
    place: "Genève 11",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1212",
    place: "Grand-Lancy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Lancy",
    community_code: "6628",
    latitude: "46.1788",
    longitude: "6.1229",
  },
  {
    country_code: "CH",
    zipcode: "1213",
    place: "Petit-Lancy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Lancy",
    community_code: "6628",
    latitude: "46.1965",
    longitude: "6.1136",
  },
  {
    country_code: "CH",
    zipcode: "1213",
    place: "Onex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Onex",
    community_code: "6631",
    latitude: "46.184",
    longitude: "6.1024",
  },
  {
    country_code: "CH",
    zipcode: "1214",
    place: "Vernier",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vernier",
    community_code: "6643",
    latitude: "46.217",
    longitude: "6.085",
  },
  {
    country_code: "CH",
    zipcode: "1215",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Le Grand-Saconnex",
    community_code: "6623",
    latitude: "46.2358",
    longitude: "6.1192",
  },
  {
    country_code: "CH",
    zipcode: "1216",
    place: "Cointrin",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Meyrin",
    community_code: "6630",
    latitude: "46.2267",
    longitude: "6.1055",
  },
  {
    country_code: "CH",
    zipcode: "1217",
    place: "Meyrin",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Meyrin",
    community_code: "6630",
    latitude: "46.2342",
    longitude: "6.0802",
  },
  {
    country_code: "CH",
    zipcode: "1218",
    place: "Le Grand-Saconnex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Le Grand-Saconnex",
    community_code: "6623",
    latitude: "46.2319",
    longitude: "6.1209",
  },
  {
    country_code: "CH",
    zipcode: "1219",
    place: "Châtelaine",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vernier",
    community_code: "6643",
    latitude: "46.2114",
    longitude: "6.1089",
  },
  {
    country_code: "CH",
    zipcode: "1219",
    place: "Le Lignon",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vernier",
    community_code: "6643",
    latitude: "46.2074",
    longitude: "6.0984",
  },
  {
    country_code: "CH",
    zipcode: "1219",
    place: "Aïre",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vernier",
    community_code: "6643",
    latitude: "46.1951",
    longitude: "6.0995",
  },
  {
    country_code: "CH",
    zipcode: "1220",
    place: "Les Avanchets",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vernier",
    community_code: "6643",
    latitude: "46.2217",
    longitude: "6.1081",
  },
  {
    country_code: "CH",
    zipcode: "1222",
    place: "Vésenaz",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Collonge-Bellerive",
    community_code: "6616",
    latitude: "46.2403",
    longitude: "6.1975",
  },
  {
    country_code: "CH",
    zipcode: "1223",
    place: "Cologny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Cologny",
    community_code: "6617",
    latitude: "46.2167",
    longitude: "6.1804",
  },
  {
    country_code: "CH",
    zipcode: "1224",
    place: "Chêne-Bougeries",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Chêne-Bougeries",
    community_code: "6612",
    latitude: "46.1984",
    longitude: "6.1864",
  },
  {
    country_code: "CH",
    zipcode: "1225",
    place: "Chêne-Bourg",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Chêne-Bourg",
    community_code: "6613",
    latitude: "46.1953",
    longitude: "6.1941",
  },
  {
    country_code: "CH",
    zipcode: "1226",
    place: "Thônex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Thônex",
    community_code: "6640",
    latitude: "46.1881",
    longitude: "6.199",
  },
  {
    country_code: "CH",
    zipcode: "1227",
    place: "Les Acacias",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.1917",
    longitude: "6.1361",
  },
  {
    country_code: "CH",
    zipcode: "1227",
    place: "Carouge GE",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Carouge (GE)",
    community_code: "6608",
    latitude: "46.1917",
    longitude: "6.1361",
  },
  {
    country_code: "CH",
    zipcode: "1228",
    place: "Plan-les-Ouates",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Plan-les-Ouates",
    community_code: "6633",
    latitude: "46.1679",
    longitude: "6.1166",
  },
  {
    country_code: "CH",
    zipcode: "1231",
    place: "Conches",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Chêne-Bougeries",
    community_code: "6612",
    latitude: "46.185",
    longitude: "6.1739",
  },
  {
    country_code: "CH",
    zipcode: "1232",
    place: "Confignon",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Confignon",
    community_code: "6618",
    latitude: "46.1734",
    longitude: "6.0844",
  },
  {
    country_code: "CH",
    zipcode: "1233",
    place: "Bernex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Bernex",
    community_code: "6607",
    latitude: "46.1765",
    longitude: "6.0754",
  },
  {
    country_code: "CH",
    zipcode: "1234",
    place: "Vessy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Veyrier",
    community_code: "6645",
    latitude: "46.1831",
    longitude: "6.1668",
  },
  {
    country_code: "CH",
    zipcode: "1236",
    place: "Cartigny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Cartigny",
    community_code: "6609",
    latitude: "46.1741",
    longitude: "6.0198",
  },
  {
    country_code: "CH",
    zipcode: "1237",
    place: "Avully",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Avully",
    community_code: "6603",
    latitude: "46.1701",
    longitude: "6.0046",
  },
  {
    country_code: "CH",
    zipcode: "1239",
    place: "Collex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Collex-Bossy",
    community_code: "6615",
    latitude: "46.2715",
    longitude: "6.1233",
  },
  {
    country_code: "CH",
    zipcode: "1240",
    place: "Genève",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genève",
    community_code: "6621",
    latitude: "46.2058",
    longitude: "6.1416",
  },
  {
    country_code: "CH",
    zipcode: "1241",
    place: "Puplinge",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Puplinge",
    community_code: "6636",
    latitude: "46.2104",
    longitude: "6.2311",
  },
  {
    country_code: "CH",
    zipcode: "1242",
    place: "Satigny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Satigny",
    community_code: "6638",
    latitude: "46.2146",
    longitude: "6.0355",
  },
  {
    country_code: "CH",
    zipcode: "1243",
    place: "Presinge",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Presinge",
    community_code: "6635",
    latitude: "46.2198",
    longitude: "6.2552",
  },
  {
    country_code: "CH",
    zipcode: "1244",
    place: "Choulex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Choulex",
    community_code: "6614",
    latitude: "46.2263",
    longitude: "6.2238",
  },
  {
    country_code: "CH",
    zipcode: "1245",
    place: "Collonge-Bellerive",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Collonge-Bellerive",
    community_code: "6616",
    latitude: "46.2504",
    longitude: "6.1959",
  },
  {
    country_code: "CH",
    zipcode: "1246",
    place: "Corsier GE",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Corsier (GE)",
    community_code: "6619",
    latitude: "46.263",
    longitude: "6.2246",
  },
  {
    country_code: "CH",
    zipcode: "1247",
    place: "Anières",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Anières",
    community_code: "6602",
    latitude: "46.2767",
    longitude: "6.222",
  },
  {
    country_code: "CH",
    zipcode: "1248",
    place: "Hermance",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Hermance",
    community_code: "6625",
    latitude: "46.3014",
    longitude: "6.2433",
  },
  {
    country_code: "CH",
    zipcode: "1251",
    place: "Gy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Gy",
    community_code: "6624",
    latitude: "46.2532",
    longitude: "6.2591",
  },
  {
    country_code: "CH",
    zipcode: "1252",
    place: "Meinier",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Meinier",
    community_code: "6629",
    latitude: "46.2471",
    longitude: "6.2342",
  },
  {
    country_code: "CH",
    zipcode: "1253",
    place: "Vandoeuvres",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Vandoeuvres",
    community_code: "6642",
    latitude: "46.2218",
    longitude: "6.2029",
  },
  {
    country_code: "CH",
    zipcode: "1254",
    place: "Jussy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Jussy",
    community_code: "6626",
    latitude: "46.2359",
    longitude: "6.267",
  },
  {
    country_code: "CH",
    zipcode: "1255",
    place: "Veyrier",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Veyrier",
    community_code: "6645",
    latitude: "46.167",
    longitude: "6.1844",
  },
  {
    country_code: "CH",
    zipcode: "1256",
    place: "Troinex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Troinex",
    community_code: "6641",
    latitude: "46.1631",
    longitude: "6.1475",
  },
  {
    country_code: "CH",
    zipcode: "1257",
    place: "La Croix-de-Rozon",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Bardonnex",
    community_code: "6605",
    latitude: "46.1454",
    longitude: "6.1364",
  },
  {
    country_code: "CH",
    zipcode: "1258",
    place: "Perly",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Perly-Certoux",
    community_code: "6632",
    latitude: "46.157",
    longitude: "6.0923",
  },
  {
    country_code: "CH",
    zipcode: "1281",
    place: "Russin",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Russin",
    community_code: "6637",
    latitude: "46.187",
    longitude: "6.0138",
  },
  {
    country_code: "CH",
    zipcode: "1283",
    place: "La Plaine",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Dardagny",
    community_code: "6620",
    latitude: "46.1773",
    longitude: "6.0011",
  },
  {
    country_code: "CH",
    zipcode: "1283",
    place: "Dardagny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Dardagny",
    community_code: "6620",
    latitude: "46.1956",
    longitude: "5.995",
  },
  {
    country_code: "CH",
    zipcode: "1284",
    place: "Chancy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Chancy",
    community_code: "6611",
    latitude: "46.15",
    longitude: "5.9715",
  },
  {
    country_code: "CH",
    zipcode: "1285",
    place: "Athenaz (Avusy)",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Avusy",
    community_code: "6604",
    latitude: "46.1518",
    longitude: "6.0021",
  },
  {
    country_code: "CH",
    zipcode: "1286",
    place: "Soral",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Soral",
    community_code: "6639",
    latitude: "46.1437",
    longitude: "6.0428",
  },
  {
    country_code: "CH",
    zipcode: "1287",
    place: "Laconnex",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Laconnex",
    community_code: "6627",
    latitude: "46.157",
    longitude: "6.0313",
  },
  {
    country_code: "CH",
    zipcode: "1288",
    place: "Aire-la-Ville",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Aire-la-Ville",
    community_code: "6601",
    latitude: "46.1906",
    longitude: "6.0429",
  },
  {
    country_code: "CH",
    zipcode: "1290",
    place: "Versoix",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Versoix",
    community_code: "6644",
    latitude: "46.2838",
    longitude: "6.1621",
  },
  {
    country_code: "CH",
    zipcode: "1292",
    place: "Chambésy",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Pregny-Chambésy",
    community_code: "6634",
    latitude: "46.2441",
    longitude: "6.1444",
  },
  {
    country_code: "CH",
    zipcode: "1293",
    place: "Bellevue",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Bellevue",
    community_code: "6606",
    latitude: "46.2574",
    longitude: "6.1547",
  },
  {
    country_code: "CH",
    zipcode: "1294",
    place: "Genthod",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Genthod",
    community_code: "6622",
    latitude: "46.2672",
    longitude: "6.1556",
  },
  {
    country_code: "CH",
    zipcode: "1298",
    place: "Céligny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Céligny",
    community_code: "6610",
    latitude: "46.3507",
    longitude: "6.195",
  },
  {
    country_code: "CH",
    zipcode: "1298",
    place: "Céligny",
    state: "Genève",
    state_code: "GE",
    province: "Geneva",
    province_code: "2500",
    community: "Céligny",
    community_code: "6610",
    latitude: "46.3507",
    longitude: "6.195",
  },
  {
    country_code: "CH",
    zipcode: "8750",
    place: "Glarus",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.0406",
    longitude: "9.068",
  },
  {
    country_code: "CH",
    zipcode: "8750",
    place: "Riedern",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.0254",
    longitude: "9.0055",
  },
  {
    country_code: "CH",
    zipcode: "8750",
    place: "Klöntal",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.0262",
    longitude: "8.9419",
  },
  {
    country_code: "CH",
    zipcode: "8752",
    place: "Näfels",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.0978",
    longitude: "9.0636",
  },
  {
    country_code: "CH",
    zipcode: "8753",
    place: "Mollis",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.0888",
    longitude: "9.0724",
  },
  {
    country_code: "CH",
    zipcode: "8754",
    place: "Netstal",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.0634",
    longitude: "9.0573",
  },
  {
    country_code: "CH",
    zipcode: "8755",
    place: "Ennenda",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.0336",
    longitude: "9.0789",
  },
  {
    country_code: "CH",
    zipcode: "8756",
    place: "Mitlödi",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "47.0123",
    longitude: "9.0802",
  },
  {
    country_code: "CH",
    zipcode: "8757",
    place: "Filzbach",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.119",
    longitude: "9.1324",
  },
  {
    country_code: "CH",
    zipcode: "8758",
    place: "Obstalden",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.1173",
    longitude: "9.1487",
  },
  {
    country_code: "CH",
    zipcode: "8759",
    place: "Netstal",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus",
    community_code: "1632",
    latitude: "47.021",
    longitude: "8.979",
  },
  {
    country_code: "CH",
    zipcode: "8762",
    place: "Schwanden GL",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9954",
    longitude: "9.0701",
  },
  {
    country_code: "CH",
    zipcode: "8762",
    place: "Sool",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "47.0007",
    longitude: "9.086",
  },
  {
    country_code: "CH",
    zipcode: "8762",
    place: "Schwändi b. Schwanden",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9981",
    longitude: "9.0781",
  },
  {
    country_code: "CH",
    zipcode: "8765",
    place: "Engi",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9824",
    longitude: "9.1531",
  },
  {
    country_code: "CH",
    zipcode: "8766",
    place: "Matt",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9595",
    longitude: "9.1712",
  },
  {
    country_code: "CH",
    zipcode: "8767",
    place: "Elm",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.919",
    longitude: "9.1724",
  },
  {
    country_code: "CH",
    zipcode: "8772",
    place: "Nidfurn",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9867",
    longitude: "9.0547",
  },
  {
    country_code: "CH",
    zipcode: "8773",
    place: "Haslen GL",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9815",
    longitude: "9.058",
  },
  {
    country_code: "CH",
    zipcode: "8774",
    place: "Leuggelbach",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9763",
    longitude: "9.0459",
  },
  {
    country_code: "CH",
    zipcode: "8775",
    place: "Hätzingen",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9664",
    longitude: "9.0371",
  },
  {
    country_code: "CH",
    zipcode: "8775",
    place: "Luchsingen",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9664",
    longitude: "9.0371",
  },
  {
    country_code: "CH",
    zipcode: "8777",
    place: "Betschwanden",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9463",
    longitude: "9.0276",
  },
  {
    country_code: "CH",
    zipcode: "8777",
    place: "Diesbach GL",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9491",
    longitude: "9.0309",
  },
  {
    country_code: "CH",
    zipcode: "8782",
    place: "Rüti GL",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9378",
    longitude: "9.0169",
  },
  {
    country_code: "CH",
    zipcode: "8783",
    place: "Linthal",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9213",
    longitude: "8.998",
  },
  {
    country_code: "CH",
    zipcode: "8784",
    place: "Braunwald",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Süd",
    community_code: "1631",
    latitude: "46.9416",
    longitude: "8.9964",
  },
  {
    country_code: "CH",
    zipcode: "8865",
    place: "Bilten",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.15",
    longitude: "9.0255",
  },
  {
    country_code: "CH",
    zipcode: "8867",
    place: "Niederurnen",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.126",
    longitude: "9.0543",
  },
  {
    country_code: "CH",
    zipcode: "8868",
    place: "Oberurnen",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.1141",
    longitude: "9.0587",
  },
  {
    country_code: "CH",
    zipcode: "8874",
    place: "Mühlehorn",
    state: "Kanton Glarus",
    state_code: "GL",
    province: "Glarus",
    province_code: "800",
    community: "Glarus Nord",
    community_code: "1630",
    latitude: "47.1176",
    longitude: "9.1724",
  },
  {
    country_code: "CH",
    zipcode: "7077",
    place: "Valbella",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Vaz/Obervaz",
    community_code: "3506",
    latitude: "46.7473",
    longitude: "9.5542",
  },
  {
    country_code: "CH",
    zipcode: "7078",
    place: "Lenzerheide/Lai",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Vaz/Obervaz",
    community_code: "3506",
    latitude: "46.7222",
    longitude: "9.559",
  },
  {
    country_code: "CH",
    zipcode: "7082",
    place: "Vaz/Obervaz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Vaz/Obervaz",
    community_code: "3506",
    latitude: "46.717",
    longitude: "9.5413",
  },
  {
    country_code: "CH",
    zipcode: "7083",
    place: "Lantsch/Lenz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Lantsch/Lenz",
    community_code: "3513",
    latitude: "46.6841",
    longitude: "9.5627",
  },
  {
    country_code: "CH",
    zipcode: "7084",
    place: "Brienz/Brinzauls GR",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6683",
    longitude: "9.5951",
  },
  {
    country_code: "CH",
    zipcode: "7450",
    place: "Tiefencastel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Vaz/Obervaz",
    community_code: "3506",
    latitude: "46.7257",
    longitude: "9.5415",
  },
  {
    country_code: "CH",
    zipcode: "7450",
    place: "Tiefencastel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6601",
    longitude: "9.5788",
  },
  {
    country_code: "CH",
    zipcode: "7451",
    place: "Alvaschein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6753",
    longitude: "9.5506",
  },
  {
    country_code: "CH",
    zipcode: "7452",
    place: "Cunter",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.6096",
    longitude: "9.5898",
  },
  {
    country_code: "CH",
    zipcode: "7453",
    place: "Tinizong",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.5824",
    longitude: "9.6173",
  },
  {
    country_code: "CH",
    zipcode: "7454",
    place: "Rona",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.5613",
    longitude: "9.6253",
  },
  {
    country_code: "CH",
    zipcode: "7455",
    place: "Mulegns",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.5242",
    longitude: "9.6209",
  },
  {
    country_code: "CH",
    zipcode: "7456",
    place: "Marmorera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.4972",
    longitude: "9.6435",
  },
  {
    country_code: "CH",
    zipcode: "7456",
    place: "Sur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.5703",
    longitude: "9.3659",
  },
  {
    country_code: "CH",
    zipcode: "7457",
    place: "Bivio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.4685",
    longitude: "9.6509",
  },
  {
    country_code: "CH",
    zipcode: "7458",
    place: "Mon",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6498",
    longitude: "9.5647",
  },
  {
    country_code: "CH",
    zipcode: "7459",
    place: "Stierva",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6637",
    longitude: "9.5423",
  },
  {
    country_code: "CH",
    zipcode: "7460",
    place: "Savognin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.5973",
    longitude: "9.5982",
  },
  {
    country_code: "CH",
    zipcode: "7462",
    place: "Salouf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.6244",
    longitude: "9.5761",
  },
  {
    country_code: "CH",
    zipcode: "7463",
    place: "Riom",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.6093",
    longitude: "9.582",
  },
  {
    country_code: "CH",
    zipcode: "7463",
    place: "Riom",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.6093",
    longitude: "9.582",
  },
  {
    country_code: "CH",
    zipcode: "7464",
    place: "Parsonz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Surses",
    community_code: "3543",
    latitude: "46.6111",
    longitude: "9.573",
  },
  {
    country_code: "CH",
    zipcode: "7472",
    place: "Surava",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6661",
    longitude: "9.613",
  },
  {
    country_code: "CH",
    zipcode: "7473",
    place: "Alvaneu Bad",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6696",
    longitude: "9.6473",
  },
  {
    country_code: "CH",
    zipcode: "7477",
    place: "Filisur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Filisur",
    community_code: "3522",
    latitude: "46.673",
    longitude: "9.6859",
  },
  {
    country_code: "CH",
    zipcode: "7482",
    place: "Stugl/Stuls",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Bergün/Bravuogn",
    community_code: "3521",
    latitude: "46.6506",
    longitude: "9.7317",
  },
  {
    country_code: "CH",
    zipcode: "7482",
    place: "Bergün/Bravuogn",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Bergün/Bravuogn",
    community_code: "3521",
    latitude: "46.6293",
    longitude: "9.7476",
  },
  {
    country_code: "CH",
    zipcode: "7482",
    place: "Preda",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Bergün/Bravuogn",
    community_code: "3521",
    latitude: "46.5886",
    longitude: "9.7765",
  },
  {
    country_code: "CH",
    zipcode: "7484",
    place: "Latsch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Bergün/Bravuogn",
    community_code: "3521",
    latitude: "46.6342",
    longitude: "9.7521",
  },
  {
    country_code: "CH",
    zipcode: "7492",
    place: "Alvaneu Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Albula/Alvra",
    community_code: "3542",
    latitude: "46.6788",
    longitude: "9.6463",
  },
  {
    country_code: "CH",
    zipcode: "7493",
    place: "Schmitten (Albula)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Albula",
    province_code: "1841",
    community: "Schmitten (GR)",
    community_code: "3514",
    latitude: "46.6869",
    longitude: "9.6732",
  },
  {
    country_code: "CH",
    zipcode: "7710",
    place: "Alp Grüm",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.3746",
    longitude: "10.0313",
  },
  {
    country_code: "CH",
    zipcode: "7710",
    place: "Ospizio Bernina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.4115",
    longitude: "10.0219",
  },
  {
    country_code: "CH",
    zipcode: "7741",
    place: "S. Carlo (Poschiavo)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.343",
    longitude: "10.0614",
  },
  {
    country_code: "CH",
    zipcode: "7742",
    place: "La Rösa",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.4011",
    longitude: "10.0669",
  },
  {
    country_code: "CH",
    zipcode: "7742",
    place: "Poschiavo",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.3244",
    longitude: "10.0582",
  },
  {
    country_code: "CH",
    zipcode: "7742",
    place: "Sfazù",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.389",
    longitude: "10.081",
  },
  {
    country_code: "CH",
    zipcode: "7743",
    place: "Miralago",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.2732",
    longitude: "10.1007",
  },
  {
    country_code: "CH",
    zipcode: "7743",
    place: "Brusio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Brusio",
    community_code: "3551",
    latitude: "46.2595",
    longitude: "10.1238",
  },
  {
    country_code: "CH",
    zipcode: "7744",
    place: "Campocologno",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Brusio",
    community_code: "3551",
    latitude: "46.2325",
    longitude: "10.1427",
  },
  {
    country_code: "CH",
    zipcode: "7745",
    place: "Li Curt",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.3099",
    longitude: "10.0625",
  },
  {
    country_code: "CH",
    zipcode: "7746",
    place: "Le Prese",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Poschiavo",
    community_code: "3561",
    latitude: "46.2931",
    longitude: "10.0789",
  },
  {
    country_code: "CH",
    zipcode: "7747",
    place: "Viano",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Brusio",
    community_code: "3551",
    latitude: "46.2533",
    longitude: "10.1394",
  },
  {
    country_code: "CH",
    zipcode: "7748",
    place: "Campascio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Bernina",
    province_code: "1842",
    community: "Brusio",
    community_code: "3551",
    latitude: "46.247",
    longitude: "10.1294",
  },
  {
    country_code: "CH",
    zipcode: "7527",
    place: "Brail",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Zernez",
    community_code: "3746",
    latitude: "46.6555",
    longitude: "10.0351",
  },
  {
    country_code: "CH",
    zipcode: "7530",
    place: "Zernez",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Zernez",
    community_code: "3746",
    latitude: "46.6986",
    longitude: "10.0927",
  },
  {
    country_code: "CH",
    zipcode: "7532",
    place: "Tschierv",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.6261",
    longitude: "10.3388",
  },
  {
    country_code: "CH",
    zipcode: "7533",
    place: "Fuldera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.6089",
    longitude: "10.3715",
  },
  {
    country_code: "CH",
    zipcode: "7534",
    place: "Lü",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.623",
    longitude: "10.3689",
  },
  {
    country_code: "CH",
    zipcode: "7535",
    place: "Valchava",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.6008",
    longitude: "10.4069",
  },
  {
    country_code: "CH",
    zipcode: "7536",
    place: "Sta. Maria Val Müstair",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.6012",
    longitude: "10.423",
  },
  {
    country_code: "CH",
    zipcode: "7537",
    place: "Müstair",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Val Müstair",
    community_code: "3847",
    latitude: "46.6268",
    longitude: "10.4462",
  },
  {
    country_code: "CH",
    zipcode: "7542",
    place: "Susch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Zernez",
    community_code: "3746",
    latitude: "46.6714",
    longitude: "10.1217",
  },
  {
    country_code: "CH",
    zipcode: "7543",
    place: "Lavin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Zernez",
    community_code: "3746",
    latitude: "46.6714",
    longitude: "10.1217",
  },
  {
    country_code: "CH",
    zipcode: "7545",
    place: "Guarda",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7546",
    place: "Ardez",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7550",
    place: "Scuol",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7967",
    longitude: "10.298",
  },
  {
    country_code: "CH",
    zipcode: "7551",
    place: "Ftan",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7552",
    place: "Vulpera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7863",
    longitude: "10.2835",
  },
  {
    country_code: "CH",
    zipcode: "7553",
    place: "Tarasp",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7554",
    place: "Crusch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7554",
    place: "Sent",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Scuol",
    community_code: "3762",
    latitude: "46.7346",
    longitude: "10.3264",
  },
  {
    country_code: "CH",
    zipcode: "7556",
    place: "Ramosch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.8339",
    longitude: "10.3815",
  },
  {
    country_code: "CH",
    zipcode: "7556",
    place: "Ramosch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.8339",
    longitude: "10.3815",
  },
  {
    country_code: "CH",
    zipcode: "7557",
    place: "Vnà",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.844",
    longitude: "10.3648",
  },
  {
    country_code: "CH",
    zipcode: "7558",
    place: "Strada",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.8638",
    longitude: "10.4364",
  },
  {
    country_code: "CH",
    zipcode: "7559",
    place: "Tschlin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.8698",
    longitude: "10.4255",
  },
  {
    country_code: "CH",
    zipcode: "7560",
    place: "Martina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Valsot",
    community_code: "3764",
    latitude: "46.8846",
    longitude: "10.4632",
  },
  {
    country_code: "CH",
    zipcode: "7562",
    place: "Samnaun-Compatsch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Samnaun",
    community_code: "3752",
    latitude: "46.9596",
    longitude: "10.4002",
  },
  {
    country_code: "CH",
    zipcode: "7563",
    place: "Samnaun Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Engiadina Bassa / Val Müstair",
    province_code: "1843",
    community: "Samnaun",
    community_code: "3752",
    latitude: "46.9437",
    longitude: "10.3606",
  },
  {
    country_code: "CH",
    zipcode: "7012",
    place: "Felsberg",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Felsberg",
    community_code: "3731",
    latitude: "46.8442",
    longitude: "9.4768",
  },
  {
    country_code: "CH",
    zipcode: "7013",
    place: "Domat/Ems",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Domat/Ems",
    community_code: "3722",
    latitude: "46.8348",
    longitude: "9.4508",
  },
  {
    country_code: "CH",
    zipcode: "7014",
    place: "Trin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Trin",
    community_code: "3734",
    latitude: "46.8283",
    longitude: "9.3616",
  },
  {
    country_code: "CH",
    zipcode: "7015",
    place: "Tamins",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Tamins",
    community_code: "3733",
    latitude: "46.8296",
    longitude: "9.4065",
  },
  {
    country_code: "CH",
    zipcode: "7016",
    place: "Trin Mulin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Trin",
    community_code: "3734",
    latitude: "46.8337",
    longitude: "9.3411",
  },
  {
    country_code: "CH",
    zipcode: "7017",
    place: "Flims Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Flims",
    community_code: "3732",
    latitude: "46.8371",
    longitude: "9.2846",
  },
  {
    country_code: "CH",
    zipcode: "7018",
    place: "Flims Waldhaus",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Flims",
    community_code: "3732",
    latitude: "46.8288",
    longitude: "9.2898",
  },
  {
    country_code: "CH",
    zipcode: "7019",
    place: "Fidaz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Flims",
    community_code: "3732",
    latitude: "46.8404",
    longitude: "9.3088",
  },
  {
    country_code: "CH",
    zipcode: "7402",
    place: "Bonaduz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Bonaduz",
    community_code: "3721",
    latitude: "46.811",
    longitude: "9.3982",
  },
  {
    country_code: "CH",
    zipcode: "7403",
    place: "Rhäzüns",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Imboden",
    province_code: "1844",
    community: "Rhäzüns",
    community_code: "3723",
    latitude: "46.7989",
    longitude: "9.3976",
  },
  {
    country_code: "CH",
    zipcode: "7202",
    place: "Says",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Trimmis",
    community_code: "3945",
    latitude: "46.9074",
    longitude: "9.5824",
  },
  {
    country_code: "CH",
    zipcode: "7203",
    place: "Trimmis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Trimmis",
    community_code: "3945",
    latitude: "46.9008",
    longitude: "9.5612",
  },
  {
    country_code: "CH",
    zipcode: "7204",
    place: "Untervaz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Untervaz",
    community_code: "3946",
    latitude: "46.9275",
    longitude: "9.5342",
  },
  {
    country_code: "CH",
    zipcode: "7205",
    place: "Zizers",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Zizers",
    community_code: "3947",
    latitude: "46.9358",
    longitude: "9.5649",
  },
  {
    country_code: "CH",
    zipcode: "7206",
    place: "Igis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Landquart",
    community_code: "3955",
    latitude: "46.9453",
    longitude: "9.5722",
  },
  {
    country_code: "CH",
    zipcode: "7208",
    place: "Malans GR",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Malans",
    community_code: "3954",
    latitude: "46.981",
    longitude: "9.5753",
  },
  {
    country_code: "CH",
    zipcode: "7302",
    place: "Landquart",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Landquart",
    community_code: "3955",
    latitude: "46.95",
    longitude: "9.5667",
  },
  {
    country_code: "CH",
    zipcode: "7303",
    place: "Mastrils",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Landquart",
    community_code: "3955",
    latitude: "46.9697",
    longitude: "9.5446",
  },
  {
    country_code: "CH",
    zipcode: "7304",
    place: "Maienfeld",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Maienfeld",
    community_code: "3953",
    latitude: "47.0047",
    longitude: "9.5312",
  },
  {
    country_code: "CH",
    zipcode: "7306",
    place: "Fläsch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Fläsch",
    community_code: "3951",
    latitude: "47.0257",
    longitude: "9.5136",
  },
  {
    country_code: "CH",
    zipcode: "7307",
    place: "Jenins",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Landquart",
    province_code: "1845",
    community: "Jenins",
    community_code: "3952",
    latitude: "47.0015",
    longitude: "9.5566",
  },
  {
    country_code: "CH",
    zipcode: "7500",
    place: "St. Moritz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "St. Moritz",
    community_code: "3787",
    latitude: "46.4994",
    longitude: "9.8433",
  },
  {
    country_code: "CH",
    zipcode: "7502",
    place: "Bever",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bever",
    community_code: "3781",
    latitude: "46.55",
    longitude: "9.8833",
  },
  {
    country_code: "CH",
    zipcode: "7502",
    place: "Bever",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bever",
    community_code: "3781",
    latitude: "46.55",
    longitude: "9.8833",
  },
  {
    country_code: "CH",
    zipcode: "7503",
    place: "Samedan",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Samedan",
    community_code: "3786",
    latitude: "46.534",
    longitude: "9.8728",
  },
  {
    country_code: "CH",
    zipcode: "7504",
    place: "Pontresina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Pontresina",
    community_code: "3784",
    latitude: "46.4955",
    longitude: "9.9013",
  },
  {
    country_code: "CH",
    zipcode: "7505",
    place: "Celerina/Schlarigna",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Celerina/Schlarigna",
    community_code: "3782",
    latitude: "46.5122",
    longitude: "9.8579",
  },
  {
    country_code: "CH",
    zipcode: "7512",
    place: "Champfèr",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Silvaplana",
    community_code: "3790",
    latitude: "46.4763",
    longitude: "9.81",
  },
  {
    country_code: "CH",
    zipcode: "7513",
    place: "Silvaplana",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Silvaplana",
    community_code: "3790",
    latitude: "46.4581",
    longitude: "9.7951",
  },
  {
    country_code: "CH",
    zipcode: "7513",
    place: "Silvaplana-Surlej",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Silvaplana",
    community_code: "3790",
    latitude: "46.458",
    longitude: "9.8114",
  },
  {
    country_code: "CH",
    zipcode: "7514",
    place: "Fex",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Sils im Engadin/Segl",
    community_code: "3789",
    latitude: "46.4137",
    longitude: "9.7644",
  },
  {
    country_code: "CH",
    zipcode: "7514",
    place: "Sils/Segl Maria",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Sils im Engadin/Segl",
    community_code: "3789",
    latitude: "46.4289",
    longitude: "9.7636",
  },
  {
    country_code: "CH",
    zipcode: "7515",
    place: "Sils/Segl Baselgia",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Sils im Engadin/Segl",
    community_code: "3789",
    latitude: "46.4347",
    longitude: "9.7569",
  },
  {
    country_code: "CH",
    zipcode: "7516",
    place: "Maloja",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.4039",
    longitude: "9.6949",
  },
  {
    country_code: "CH",
    zipcode: "7517",
    place: "Plaun da Lej",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Sils im Engadin/Segl",
    community_code: "3789",
    latitude: "46.4214",
    longitude: "9.7263",
  },
  {
    country_code: "CH",
    zipcode: "7522",
    place: "La Punt-Chamues-ch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "La Punt-Chamues-ch",
    community_code: "3785",
    latitude: "46.5789",
    longitude: "9.9201",
  },
  {
    country_code: "CH",
    zipcode: "7523",
    place: "Madulain",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Madulain",
    community_code: "3783",
    latitude: "46.5877",
    longitude: "9.9395",
  },
  {
    country_code: "CH",
    zipcode: "7523",
    place: "Madulain",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Madulain",
    community_code: "3783",
    latitude: "46.5877",
    longitude: "9.9395",
  },
  {
    country_code: "CH",
    zipcode: "7524",
    place: "Zuoz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Zuoz",
    community_code: "3791",
    latitude: "46.6021",
    longitude: "9.9597",
  },
  {
    country_code: "CH",
    zipcode: "7524",
    place: "Zuoz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Zuoz",
    community_code: "3791",
    latitude: "46.6021",
    longitude: "9.9597",
  },
  {
    country_code: "CH",
    zipcode: "7525",
    place: "S-chanf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "S-chanf",
    community_code: "3788",
    latitude: "46.6125",
    longitude: "9.9846",
  },
  {
    country_code: "CH",
    zipcode: "7526",
    place: "Cinuos-chel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "S-chanf",
    community_code: "3788",
    latitude: "46.643",
    longitude: "10.0231",
  },
  {
    country_code: "CH",
    zipcode: "7602",
    place: "Casaccia",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.391",
    longitude: "9.6661",
  },
  {
    country_code: "CH",
    zipcode: "7603",
    place: "Vicosoprano",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3511",
    longitude: "9.6208",
  },
  {
    country_code: "CH",
    zipcode: "7604",
    place: "Borgonovo",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3476",
    longitude: "9.6041",
  },
  {
    country_code: "CH",
    zipcode: "7605",
    place: "Stampa",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3429",
    longitude: "9.5907",
  },
  {
    country_code: "CH",
    zipcode: "7606",
    place: "Promontogno",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3394",
    longitude: "9.5576",
  },
  {
    country_code: "CH",
    zipcode: "7606",
    place: "Bondo",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3354",
    longitude: "9.5541",
  },
  {
    country_code: "CH",
    zipcode: "7608",
    place: "Castasegna",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.3339",
    longitude: "9.5179",
  },
  {
    country_code: "CH",
    zipcode: "7610",
    place: "Soglio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Maloja",
    province_code: "1846",
    community: "Bregaglia",
    community_code: "3792",
    latitude: "46.342",
    longitude: "9.5393",
  },
  {
    country_code: "CH",
    zipcode: "6534",
    place: "S. Vittore",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "San Vittore",
    community_code: "3835",
    latitude: "46.2371",
    longitude: "9.1068",
  },
  {
    country_code: "CH",
    zipcode: "6534",
    place: "S. Vittore",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "San Vittore",
    community_code: "3835",
    latitude: "46.2371",
    longitude: "9.1068",
  },
  {
    country_code: "CH",
    zipcode: "6535",
    place: "Roveredo GR",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Roveredo (GR)",
    community_code: "3834",
    latitude: "46.2365",
    longitude: "9.1275",
  },
  {
    country_code: "CH",
    zipcode: "6537",
    place: "Grono",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Grono",
    community_code: "3832",
    latitude: "46.2483",
    longitude: "9.1483",
  },
  {
    country_code: "CH",
    zipcode: "6538",
    place: "Verdabbio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Grono",
    community_code: "3832",
    latitude: "46.2326",
    longitude: "9.1815",
  },
  {
    country_code: "CH",
    zipcode: "6538",
    place: "Verdabbio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Grono",
    community_code: "3832",
    latitude: "46.2326",
    longitude: "9.1815",
  },
  {
    country_code: "CH",
    zipcode: "6540",
    place: "Castaneda",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Castaneda",
    community_code: "3805",
    latitude: "46.2581",
    longitude: "9.1387",
  },
  {
    country_code: "CH",
    zipcode: "6541",
    place: "Sta. Maria in Calanca",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Santa Maria in Calanca",
    community_code: "3810",
    latitude: "46.2629",
    longitude: "9.1448",
  },
  {
    country_code: "CH",
    zipcode: "6542",
    place: "Buseno",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Buseno",
    community_code: "3804",
    latitude: "46.2738",
    longitude: "9.1073",
  },
  {
    country_code: "CH",
    zipcode: "6543",
    place: "Arvigo",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Calanca",
    community_code: "3837",
    latitude: "46.3021",
    longitude: "9.113",
  },
  {
    country_code: "CH",
    zipcode: "6544",
    place: "Braggio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Calanca",
    community_code: "3837",
    latitude: "46.3028",
    longitude: "9.1238",
  },
  {
    country_code: "CH",
    zipcode: "6545",
    place: "Selma",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Calanca",
    community_code: "3837",
    latitude: "46.3204",
    longitude: "9.12",
  },
  {
    country_code: "CH",
    zipcode: "6546",
    place: "Cauco",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Calanca",
    community_code: "3837",
    latitude: "46.3354",
    longitude: "9.1213",
  },
  {
    country_code: "CH",
    zipcode: "6547",
    place: "Augio",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Rossa",
    community_code: "3808",
    latitude: "46.3658",
    longitude: "9.1278",
  },
  {
    country_code: "CH",
    zipcode: "6548",
    place: "Rossa",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Rossa",
    community_code: "3808",
    latitude: "46.3714",
    longitude: "9.1236",
  },
  {
    country_code: "CH",
    zipcode: "6549",
    place: "Laura",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Roveredo (GR)",
    community_code: "3834",
    latitude: "46.2125",
    longitude: "9.1047",
  },
  {
    country_code: "CH",
    zipcode: "6556",
    place: "Leggia",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Grono",
    community_code: "3832",
    latitude: "46.2326",
    longitude: "9.1815",
  },
  {
    country_code: "CH",
    zipcode: "6557",
    place: "Cama",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Cama",
    community_code: "3831",
    latitude: "46.278",
    longitude: "9.1722",
  },
  {
    country_code: "CH",
    zipcode: "6558",
    place: "Lostallo",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Lostallo",
    community_code: "3821",
    latitude: "46.313",
    longitude: "9.1966",
  },
  {
    country_code: "CH",
    zipcode: "6562",
    place: "Soazza",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Soazza",
    community_code: "3823",
    latitude: "46.3664",
    longitude: "9.222",
  },
  {
    country_code: "CH",
    zipcode: "6563",
    place: "Mesocco",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Mesocco",
    community_code: "3822",
    latitude: "46.3939",
    longitude: "9.2333",
  },
  {
    country_code: "CH",
    zipcode: "6565",
    place: "S. Bernardino",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Moesa",
    province_code: "1847",
    community: "Mesocco",
    community_code: "3822",
    latitude: "46.3801",
    longitude: "9.2277",
  },
  {
    country_code: "CH",
    zipcode: "7000",
    place: "Chur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Chur",
    community_code: "3901",
    latitude: "46.8499",
    longitude: "9.5329",
  },
  {
    country_code: "CH",
    zipcode: "7001",
    place: "Chur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Chur",
    community_code: "3901",
    latitude: "46.8521",
    longitude: "9.5296",
  },
  {
    country_code: "CH",
    zipcode: "7004",
    place: "Chur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Chur",
    community_code: "3901",
    latitude: "46.8521",
    longitude: "9.5296",
  },
  {
    country_code: "CH",
    zipcode: "7006",
    place: "Chur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Chur",
    community_code: "3901",
    latitude: "46.8521",
    longitude: "9.5296",
  },
  {
    country_code: "CH",
    zipcode: "7007",
    place: "Chur",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Chur",
    community_code: "3901",
    latitude: "46.8521",
    longitude: "9.5296",
  },
  {
    country_code: "CH",
    zipcode: "7023",
    place: "Haldenstein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Haldenstein",
    community_code: "3941",
    latitude: "46.8787",
    longitude: "9.5262",
  },
  {
    country_code: "CH",
    zipcode: "7026",
    place: "Maladers",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Maladers",
    community_code: "3926",
    latitude: "46.8358",
    longitude: "9.56",
  },
  {
    country_code: "CH",
    zipcode: "7027",
    place: "Lüen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8331",
    longitude: "9.6142",
  },
  {
    country_code: "CH",
    zipcode: "7027",
    place: "Calfreisen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8363",
    longitude: "9.6089",
  },
  {
    country_code: "CH",
    zipcode: "7027",
    place: "Castiel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8394",
    longitude: "9.6036",
  },
  {
    country_code: "CH",
    zipcode: "7028",
    place: "Pagig",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.833",
    longitude: "9.6509",
  },
  {
    country_code: "CH",
    zipcode: "7028",
    place: "St. Peter",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.833",
    longitude: "9.6509",
  },
  {
    country_code: "CH",
    zipcode: "7029",
    place: "Peist",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8333",
    longitude: "9.6741",
  },
  {
    country_code: "CH",
    zipcode: "7050",
    place: "Arosa",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.7779",
    longitude: "9.6762",
  },
  {
    country_code: "CH",
    zipcode: "7056",
    place: "Molinis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8261",
    longitude: "9.6574",
  },
  {
    country_code: "CH",
    zipcode: "7057",
    place: "Langwies",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.8192",
    longitude: "9.7094",
  },
  {
    country_code: "CH",
    zipcode: "7058",
    place: "Litzirüti",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Arosa",
    community_code: "3921",
    latitude: "46.7994",
    longitude: "9.7046",
  },
  {
    country_code: "CH",
    zipcode: "7062",
    place: "Passugg",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Churwalden",
    community_code: "3911",
    latitude: "46.8297",
    longitude: "9.548",
  },
  {
    country_code: "CH",
    zipcode: "7063",
    place: "Praden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Tschiertschen-Praden",
    community_code: "3932",
    latitude: "46.8243",
    longitude: "9.5815",
  },
  {
    country_code: "CH",
    zipcode: "7064",
    place: "Tschiertschen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Tschiertschen-Praden",
    community_code: "3932",
    latitude: "46.8177",
    longitude: "9.6064",
  },
  {
    country_code: "CH",
    zipcode: "7074",
    place: "Malix",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Churwalden",
    community_code: "3911",
    latitude: "46.8125",
    longitude: "9.532",
  },
  {
    country_code: "CH",
    zipcode: "7075",
    place: "Churwalden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Churwalden",
    community_code: "3911",
    latitude: "46.7814",
    longitude: "9.5438",
  },
  {
    country_code: "CH",
    zipcode: "7076",
    place: "Parpan",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Plessur",
    province_code: "1848",
    community: "Churwalden",
    community_code: "3911",
    latitude: "46.7601",
    longitude: "9.5571",
  },
  {
    country_code: "CH",
    zipcode: "7212",
    place: "Seewis-Pardisla",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Seewis im Prättigau",
    community_code: "3972",
    latitude: "46.9811",
    longitude: "9.6359",
  },
  {
    country_code: "CH",
    zipcode: "7212",
    place: "Seewis-Schmitten",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Seewis im Prättigau",
    community_code: "3972",
    latitude: "46.9804",
    longitude: "9.6407",
  },
  {
    country_code: "CH",
    zipcode: "7212",
    place: "Seewis Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Seewis im Prättigau",
    community_code: "3972",
    latitude: "46.9902",
    longitude: "9.6377",
  },
  {
    country_code: "CH",
    zipcode: "7213",
    place: "Valzeina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Grüsch",
    community_code: "3961",
    latitude: "46.9502",
    longitude: "9.6027",
  },
  {
    country_code: "CH",
    zipcode: "7214",
    place: "Grüsch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Grüsch",
    community_code: "3961",
    latitude: "46.9796",
    longitude: "9.6464",
  },
  {
    country_code: "CH",
    zipcode: "7215",
    place: "Fanas",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Grüsch",
    community_code: "3961",
    latitude: "46.9849",
    longitude: "9.6667",
  },
  {
    country_code: "CH",
    zipcode: "7220",
    place: "Schiers",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9697",
    longitude: "9.6872",
  },
  {
    country_code: "CH",
    zipcode: "7220",
    place: "Schiers",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9697",
    longitude: "9.6872",
  },
  {
    country_code: "CH",
    zipcode: "7222",
    place: "Lunden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9513",
    longitude: "9.7143",
  },
  {
    country_code: "CH",
    zipcode: "7223",
    place: "Buchen im Prättigau",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9418",
    longitude: "9.7184",
  },
  {
    country_code: "CH",
    zipcode: "7224",
    place: "Putz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9261",
    longitude: "9.747",
  },
  {
    country_code: "CH",
    zipcode: "7226",
    place: "Stels",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9712",
    longitude: "9.7019",
  },
  {
    country_code: "CH",
    zipcode: "7226",
    place: "Stels",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9712",
    longitude: "9.7019",
  },
  {
    country_code: "CH",
    zipcode: "7226",
    place: "Fajauna",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9712",
    longitude: "9.7019",
  },
  {
    country_code: "CH",
    zipcode: "7228",
    place: "Pusserein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9856",
    longitude: "9.708",
  },
  {
    country_code: "CH",
    zipcode: "7228",
    place: "Schuders",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Schiers",
    community_code: "3962",
    latitude: "46.9913",
    longitude: "9.7379",
  },
  {
    country_code: "CH",
    zipcode: "7231",
    place: "Pragg-Jenaz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Jenaz",
    community_code: "3863",
    latitude: "46.9402",
    longitude: "9.7053",
  },
  {
    country_code: "CH",
    zipcode: "7232",
    place: "Furna",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Furna",
    community_code: "3862",
    latitude: "46.9369",
    longitude: "9.6787",
  },
  {
    country_code: "CH",
    zipcode: "7233",
    place: "Jenaz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Jenaz",
    community_code: "3863",
    latitude: "46.9289",
    longitude: "9.7128",
  },
  {
    country_code: "CH",
    zipcode: "7235",
    place: "Fideris",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Fideris",
    community_code: "3861",
    latitude: "46.9163",
    longitude: "9.7415",
  },
  {
    country_code: "CH",
    zipcode: "7240",
    place: "Küblis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Küblis",
    community_code: "3882",
    latitude: "46.9145",
    longitude: "9.7793",
  },
  {
    country_code: "CH",
    zipcode: "7241",
    place: "Conters im Prättigau",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Conters im Prättigau",
    community_code: "3881",
    latitude: "46.9",
    longitude: "9.7833",
  },
  {
    country_code: "CH",
    zipcode: "7242",
    place: "Luzein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9196",
    longitude: "9.7608",
  },
  {
    country_code: "CH",
    zipcode: "7243",
    place: "Pany",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9289",
    longitude: "9.7715",
  },
  {
    country_code: "CH",
    zipcode: "7244",
    place: "Gadenstätt",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9452",
    longitude: "9.7945",
  },
  {
    country_code: "CH",
    zipcode: "7245",
    place: "Ascharina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9516",
    longitude: "9.8054",
  },
  {
    country_code: "CH",
    zipcode: "7246",
    place: "St. Antönien",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Luzein",
    community_code: "3891",
    latitude: "46.9466",
    longitude: "9.7618",
  },
  {
    country_code: "CH",
    zipcode: "7247",
    place: "Saas im Prättigau",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Klosters-Serneus",
    community_code: "3871",
    latitude: "46.8548",
    longitude: "9.9537",
  },
  {
    country_code: "CH",
    zipcode: "7249",
    place: "Serneus",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Klosters-Serneus",
    community_code: "3871",
    latitude: "46.8892",
    longitude: "9.8383",
  },
  {
    country_code: "CH",
    zipcode: "7250",
    place: "Klosters",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Klosters-Serneus",
    community_code: "3871",
    latitude: "46.8827",
    longitude: "9.8761",
  },
  {
    country_code: "CH",
    zipcode: "7252",
    place: "Klosters Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Klosters-Serneus",
    community_code: "3871",
    latitude: "46.8827",
    longitude: "9.8761",
  },
  {
    country_code: "CH",
    zipcode: "7260",
    place: "Davos Dorf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.8083",
    longitude: "9.8393",
  },
  {
    country_code: "CH",
    zipcode: "7265",
    place: "Davos Wolfgang",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.8334",
    longitude: "9.8537",
  },
  {
    country_code: "CH",
    zipcode: "7270",
    place: "Davos Platz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7961",
    longitude: "9.8176",
  },
  {
    country_code: "CH",
    zipcode: "7272",
    place: "Davos Clavadel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7688",
    longitude: "9.8152",
  },
  {
    country_code: "CH",
    zipcode: "7276",
    place: "Davos Frauenkirch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7658",
    longitude: "9.7975",
  },
  {
    country_code: "CH",
    zipcode: "7277",
    place: "Davos Glaris",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7452",
    longitude: "9.776",
  },
  {
    country_code: "CH",
    zipcode: "7278",
    place: "Davos Monstein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7116",
    longitude: "9.7725",
  },
  {
    country_code: "CH",
    zipcode: "7494",
    place: "Davos Wiesen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Prättigau / Davos",
    province_code: "1849",
    community: "Davos",
    community_code: "3851",
    latitude: "46.7039",
    longitude: "9.7139",
  },
  {
    country_code: "CH",
    zipcode: "7031",
    place: "Laax GR",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Laax",
    community_code: "3575",
    latitude: "46.8065",
    longitude: "9.5375",
  },
  {
    country_code: "CH",
    zipcode: "7032",
    place: "Laax GR 2",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Laax",
    community_code: "3575",
    latitude: "46.846",
    longitude: "9.2279",
  },
  {
    country_code: "CH",
    zipcode: "7104",
    place: "Versam",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7921",
    longitude: "9.3378",
  },
  {
    country_code: "CH",
    zipcode: "7104",
    place: "Versam",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7921",
    longitude: "9.3378",
  },
  {
    country_code: "CH",
    zipcode: "7104",
    place: "Arezen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7823",
    longitude: "9.3344",
  },
  {
    country_code: "CH",
    zipcode: "7106",
    place: "Tenna",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7477",
    longitude: "9.3395",
  },
  {
    country_code: "CH",
    zipcode: "7107",
    place: "Safien Platz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.6826",
    longitude: "9.3157",
  },
  {
    country_code: "CH",
    zipcode: "7109",
    place: "Thalkirch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.638",
    longitude: "9.2804",
  },
  {
    country_code: "CH",
    zipcode: "7110",
    place: "Peiden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.7207",
    longitude: "9.1961",
  },
  {
    country_code: "CH",
    zipcode: "7111",
    place: "Pitasch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7315",
    longitude: "9.2182",
  },
  {
    country_code: "CH",
    zipcode: "7112",
    place: "Duvin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.715",
    longitude: "9.2121",
  },
  {
    country_code: "CH",
    zipcode: "7113",
    place: "Camuns",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.7049",
    longitude: "9.1996",
  },
  {
    country_code: "CH",
    zipcode: "7114",
    place: "Uors (Lumnezia)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.702",
    longitude: "9.1834",
  },
  {
    country_code: "CH",
    zipcode: "7115",
    place: "Surcasti",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.6972",
    longitude: "9.1785",
  },
  {
    country_code: "CH",
    zipcode: "7116",
    place: "Tersnaus",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.6932",
    longitude: "9.1858",
  },
  {
    country_code: "CH",
    zipcode: "7116",
    place: "St. Martin (Lugnez)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Vals",
    community_code: "3603",
    latitude: "46.5736",
    longitude: "9.1308",
  },
  {
    country_code: "CH",
    zipcode: "7122",
    place: "Carrera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7885",
    longitude: "9.2961",
  },
  {
    country_code: "CH",
    zipcode: "7122",
    place: "Valendas",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Safiental",
    community_code: "3672",
    latitude: "46.7878",
    longitude: "9.2826",
  },
  {
    country_code: "CH",
    zipcode: "7126",
    place: "Castrisch",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7773",
    longitude: "9.2312",
  },
  {
    country_code: "CH",
    zipcode: "7127",
    place: "Sevgein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7655",
    longitude: "9.22",
  },
  {
    country_code: "CH",
    zipcode: "7128",
    place: "Riein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7445",
    longitude: "9.2328",
  },
  {
    country_code: "CH",
    zipcode: "7130",
    place: "Ilanz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7741",
    longitude: "9.2046",
  },
  {
    country_code: "CH",
    zipcode: "7130",
    place: "Schnaus",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7761",
    longitude: "9.1797",
  },
  {
    country_code: "CH",
    zipcode: "7130",
    place: "Schnaus",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7761",
    longitude: "9.1797",
  },
  {
    country_code: "CH",
    zipcode: "7132",
    place: "Vals",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Vals",
    community_code: "3603",
    latitude: "46.6165",
    longitude: "9.1803",
  },
  {
    country_code: "CH",
    zipcode: "7134",
    place: "Obersaxen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Obersaxen Mundaun",
    community_code: "3988",
    latitude: "46.75",
    longitude: "9.1",
  },
  {
    country_code: "CH",
    zipcode: "7137",
    place: "Flond",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Obersaxen Mundaun",
    community_code: "3988",
    latitude: "46.7677",
    longitude: "9.1644",
  },
  {
    country_code: "CH",
    zipcode: "7138",
    place: "Surcuolm",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Obersaxen Mundaun",
    community_code: "3988",
    latitude: "46.7585",
    longitude: "9.1452",
  },
  {
    country_code: "CH",
    zipcode: "7141",
    place: "Luven",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7611",
    longitude: "9.199",
  },
  {
    country_code: "CH",
    zipcode: "7142",
    place: "Cumbel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.7258",
    longitude: "9.1926",
  },
  {
    country_code: "CH",
    zipcode: "7143",
    place: "Morissen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.729",
    longitude: "9.1813",
  },
  {
    country_code: "CH",
    zipcode: "7144",
    place: "Vella",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.718",
    longitude: "9.1722",
  },
  {
    country_code: "CH",
    zipcode: "7145",
    place: "Degen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.7095",
    longitude: "9.1698",
  },
  {
    country_code: "CH",
    zipcode: "7146",
    place: "Vattiz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.7073",
    longitude: "9.1599",
  },
  {
    country_code: "CH",
    zipcode: "7147",
    place: "Vignogn",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.6996",
    longitude: "9.1561",
  },
  {
    country_code: "CH",
    zipcode: "7148",
    place: "Lumbrein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.6838",
    longitude: "9.1366",
  },
  {
    country_code: "CH",
    zipcode: "7149",
    place: "Vrin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Lumnezia",
    community_code: "3618",
    latitude: "46.6555",
    longitude: "9.0995",
  },
  {
    country_code: "CH",
    zipcode: "7151",
    place: "Schluein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Schluein",
    community_code: "3582",
    latitude: "46.7882",
    longitude: "9.226",
  },
  {
    country_code: "CH",
    zipcode: "7152",
    place: "Sagogn",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sagogn",
    community_code: "3581",
    latitude: "46.7937",
    longitude: "9.257",
  },
  {
    country_code: "CH",
    zipcode: "7153",
    place: "Falera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Falera",
    community_code: "3572",
    latitude: "46.8013",
    longitude: "9.2309",
  },
  {
    country_code: "CH",
    zipcode: "7154",
    place: "Ruschein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7853",
    longitude: "9.1925",
  },
  {
    country_code: "CH",
    zipcode: "7155",
    place: "Ladir",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7903",
    longitude: "9.2014",
  },
  {
    country_code: "CH",
    zipcode: "7155",
    place: "Ladir",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7903",
    longitude: "9.2014",
  },
  {
    country_code: "CH",
    zipcode: "7156",
    place: "Rueun",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7778",
    longitude: "9.1484",
  },
  {
    country_code: "CH",
    zipcode: "7156",
    place: "Pigniu",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7778",
    longitude: "9.1484",
  },
  {
    country_code: "CH",
    zipcode: "7157",
    place: "Siat",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Ilanz/Glion",
    community_code: "3619",
    latitude: "46.7908",
    longitude: "9.1624",
  },
  {
    country_code: "CH",
    zipcode: "7158",
    place: "Waltensburg/Vuorz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Waltensburg/Vuorz",
    community_code: "3616",
    latitude: "46.7737",
    longitude: "9.1085",
  },
  {
    country_code: "CH",
    zipcode: "7159",
    place: "Andiast",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Andiast",
    community_code: "3611",
    latitude: "46.7843",
    longitude: "9.1137",
  },
  {
    country_code: "CH",
    zipcode: "7162",
    place: "Tavanasa",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Breil/Brigels",
    community_code: "3981",
    latitude: "46.7546",
    longitude: "9.0623",
  },
  {
    country_code: "CH",
    zipcode: "7163",
    place: "Danis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Breil/Brigels",
    community_code: "3981",
    latitude: "46.7551",
    longitude: "9.055",
  },
  {
    country_code: "CH",
    zipcode: "7164",
    place: "Dardin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Breil/Brigels",
    community_code: "3981",
    latitude: "46.7591",
    longitude: "9.0485",
  },
  {
    country_code: "CH",
    zipcode: "7165",
    place: "Breil/Brigels",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Breil/Brigels",
    community_code: "3981",
    latitude: "46.7699",
    longitude: "9.0604",
  },
  {
    country_code: "CH",
    zipcode: "7166",
    place: "Trun",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Trun",
    community_code: "3987",
    latitude: "46.7429",
    longitude: "8.9872",
  },
  {
    country_code: "CH",
    zipcode: "7167",
    place: "Zignau",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Trun",
    community_code: "3987",
    latitude: "46.74",
    longitude: "9.0056",
  },
  {
    country_code: "CH",
    zipcode: "7168",
    place: "Schlans",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Trun",
    community_code: "3987",
    latitude: "46.7536",
    longitude: "9.0157",
  },
  {
    country_code: "CH",
    zipcode: "7172",
    place: "Rabius",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sumvitg",
    community_code: "3985",
    latitude: "46.7343",
    longitude: "8.9588",
  },
  {
    country_code: "CH",
    zipcode: "7173",
    place: "Surrein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sumvitg",
    community_code: "3985",
    latitude: "46.7231",
    longitude: "8.9491",
  },
  {
    country_code: "CH",
    zipcode: "7174",
    place: "S. Benedetg",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sumvitg",
    community_code: "3985",
    latitude: "46.7356",
    longitude: "8.9398",
  },
  {
    country_code: "CH",
    zipcode: "7175",
    place: "Sumvitg",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sumvitg",
    community_code: "3985",
    latitude: "46.7284",
    longitude: "8.9388",
  },
  {
    country_code: "CH",
    zipcode: "7176",
    place: "Cumpadials",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Sumvitg",
    community_code: "3985",
    latitude: "46.7241",
    longitude: "8.9238",
  },
  {
    country_code: "CH",
    zipcode: "7180",
    place: "Disentis/Mustér",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Disentis/Mustér",
    community_code: "3982",
    latitude: "46.7034",
    longitude: "8.8509",
  },
  {
    country_code: "CH",
    zipcode: "7182",
    place: "Cavardiras",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Disentis/Mustér",
    community_code: "3982",
    latitude: "46.7112",
    longitude: "8.8856",
  },
  {
    country_code: "CH",
    zipcode: "7183",
    place: "Mumpé Medel",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Disentis/Mustér",
    community_code: "3982",
    latitude: "46.6844",
    longitude: "8.8209",
  },
  {
    country_code: "CH",
    zipcode: "7184",
    place: "Curaglia",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Medel (Lucmagn)",
    community_code: "3983",
    latitude: "46.6734",
    longitude: "8.8579",
  },
  {
    country_code: "CH",
    zipcode: "7185",
    place: "Platta",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Medel (Lucmagn)",
    community_code: "3983",
    latitude: "46.6582",
    longitude: "8.8539",
  },
  {
    country_code: "CH",
    zipcode: "7186",
    place: "Segnas",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Disentis/Mustér",
    community_code: "3982",
    latitude: "46.6948",
    longitude: "8.829",
  },
  {
    country_code: "CH",
    zipcode: "7187",
    place: "Camischolas",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Tujetsch",
    community_code: "3986",
    latitude: "46.6806",
    longitude: "8.7646",
  },
  {
    country_code: "CH",
    zipcode: "7188",
    place: "Sedrun",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Tujetsch",
    community_code: "3986",
    latitude: "46.6793",
    longitude: "8.7731",
  },
  {
    country_code: "CH",
    zipcode: "7189",
    place: "Rueras",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Surselva",
    province_code: "1850",
    community: "Tujetsch",
    community_code: "3986",
    latitude: "46.6739",
    longitude: "8.7521",
  },
  {
    country_code: "CH",
    zipcode: "7404",
    place: "Feldis/Veulden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7942",
    longitude: "9.432",
  },
  {
    country_code: "CH",
    zipcode: "7405",
    place: "Rothenbrunnen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Rothenbrunnen",
    community_code: "3637",
    latitude: "46.7677",
    longitude: "9.427",
  },
  {
    country_code: "CH",
    zipcode: "7407",
    place: "Trans",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7649",
    longitude: "9.4613",
  },
  {
    country_code: "CH",
    zipcode: "7408",
    place: "Cazis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7194",
    longitude: "9.4327",
  },
  {
    country_code: "CH",
    zipcode: "7408",
    place: "Realta",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7319",
    longitude: "9.4277",
  },
  {
    country_code: "CH",
    zipcode: "7411",
    place: "Sils im Domleschg",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Sils im Domleschg",
    community_code: "3640",
    latitude: "46.7004",
    longitude: "9.454",
  },
  {
    country_code: "CH",
    zipcode: "7412",
    place: "Scharans",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Scharans",
    community_code: "3638",
    latitude: "46.7181",
    longitude: "9.459",
  },
  {
    country_code: "CH",
    zipcode: "7413",
    place: "Fürstenaubruck",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Fürstenau",
    community_code: "3633",
    latitude: "46.7119",
    longitude: "9.4533",
  },
  {
    country_code: "CH",
    zipcode: "7414",
    place: "Fürstenau",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Fürstenau",
    community_code: "3633",
    latitude: "46.7119",
    longitude: "9.4533",
  },
  {
    country_code: "CH",
    zipcode: "7415",
    place: "Pratval",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.732",
    longitude: "9.4485",
  },
  {
    country_code: "CH",
    zipcode: "7415",
    place: "Rodels",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7372",
    longitude: "9.4447",
  },
  {
    country_code: "CH",
    zipcode: "7416",
    place: "Almens",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7381",
    longitude: "9.4592",
  },
  {
    country_code: "CH",
    zipcode: "7417",
    place: "Paspels",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7488",
    longitude: "9.4435",
  },
  {
    country_code: "CH",
    zipcode: "7418",
    place: "Tumegl/Tomils",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7618",
    longitude: "9.4424",
  },
  {
    country_code: "CH",
    zipcode: "7419",
    place: "Scheid",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Domleschg",
    community_code: "3673",
    latitude: "46.7772",
    longitude: "9.4485",
  },
  {
    country_code: "CH",
    zipcode: "7421",
    place: "Summaprada",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7147",
    longitude: "9.4331",
  },
  {
    country_code: "CH",
    zipcode: "7422",
    place: "Tartar",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7176",
    longitude: "9.4186",
  },
  {
    country_code: "CH",
    zipcode: "7423",
    place: "Portein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7105",
    longitude: "9.4066",
  },
  {
    country_code: "CH",
    zipcode: "7423",
    place: "Sarn",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7223",
    longitude: "9.4063",
  },
  {
    country_code: "CH",
    zipcode: "7424",
    place: "Dalin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7364",
    longitude: "9.4056",
  },
  {
    country_code: "CH",
    zipcode: "7424",
    place: "Präz",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Cazis",
    community_code: "3661",
    latitude: "46.7419",
    longitude: "9.4054",
  },
  {
    country_code: "CH",
    zipcode: "7425",
    place: "Masein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Masein",
    community_code: "3663",
    latitude: "46.7036",
    longitude: "9.4267",
  },
  {
    country_code: "CH",
    zipcode: "7426",
    place: "Flerden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Flerden",
    community_code: "3662",
    latitude: "46.7033",
    longitude: "9.4082",
  },
  {
    country_code: "CH",
    zipcode: "7426",
    place: "Flerden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Flerden",
    community_code: "3662",
    latitude: "46.7033",
    longitude: "9.4082",
  },
  {
    country_code: "CH",
    zipcode: "7427",
    place: "Urmein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Urmein",
    community_code: "3670",
    latitude: "46.6918",
    longitude: "9.4008",
  },
  {
    country_code: "CH",
    zipcode: "7428",
    place: "Glaspass",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Tschappina",
    community_code: "3669",
    latitude: "46.677",
    longitude: "9.3474",
  },
  {
    country_code: "CH",
    zipcode: "7428",
    place: "Tschappina",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Tschappina",
    community_code: "3669",
    latitude: "46.6869",
    longitude: "9.3834",
  },
  {
    country_code: "CH",
    zipcode: "7430",
    place: "Rongellen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Rongellen",
    community_code: "3711",
    latitude: "46.6744",
    longitude: "9.4418",
  },
  {
    country_code: "CH",
    zipcode: "7430",
    place: "Thusis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Thusis",
    community_code: "3668",
    latitude: "46.6972",
    longitude: "9.4394",
  },
  {
    country_code: "CH",
    zipcode: "7431",
    place: "Mutten",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Mutten",
    community_code: "3503",
    latitude: "46.6781",
    longitude: "9.5",
  },
  {
    country_code: "CH",
    zipcode: "7431",
    place: "Obermutten",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Mutten",
    community_code: "3503",
    latitude: "46.6709",
    longitude: "9.4863",
  },
  {
    country_code: "CH",
    zipcode: "7432",
    place: "Zillis",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Zillis-Reischen",
    community_code: "3712",
    latitude: "46.6341",
    longitude: "9.4423",
  },
  {
    country_code: "CH",
    zipcode: "7433",
    place: "Mathon",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Mathon",
    community_code: "3708",
    latitude: "46.636",
    longitude: "9.4138",
  },
  {
    country_code: "CH",
    zipcode: "7433",
    place: "Donat",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Donat",
    community_code: "3705",
    latitude: "46.6322",
    longitude: "9.4257",
  },
  {
    country_code: "CH",
    zipcode: "7433",
    place: "Lohn GR",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Lohn (GR)",
    community_code: "3707",
    latitude: "46.6574",
    longitude: "9.4232",
  },
  {
    country_code: "CH",
    zipcode: "7433",
    place: "Wergenstein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Casti-Wergenstein",
    community_code: "3703",
    latitude: "46.6265",
    longitude: "9.4069",
  },
  {
    country_code: "CH",
    zipcode: "7433",
    place: "Farden",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Donat",
    community_code: "3705",
    latitude: "46.6327",
    longitude: "9.4241",
  },
  {
    country_code: "CH",
    zipcode: "7434",
    place: "Sufers",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Sufers",
    community_code: "3695",
    latitude: "46.5703",
    longitude: "9.3659",
  },
  {
    country_code: "CH",
    zipcode: "7435",
    place: "Splügen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Splügen",
    community_code: "3694",
    latitude: "46.5518",
    longitude: "9.3222",
  },
  {
    country_code: "CH",
    zipcode: "7436",
    place: "Medels im Rheinwald",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Splügen",
    community_code: "3694",
    latitude: "46.5469",
    longitude: "9.2941",
  },
  {
    country_code: "CH",
    zipcode: "7437",
    place: "Nufenen",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Nufenen",
    community_code: "3693",
    latitude: "46.5398",
    longitude: "9.2451",
  },
  {
    country_code: "CH",
    zipcode: "7438",
    place: "Hinterrhein",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Hinterrhein",
    community_code: "3691",
    latitude: "46.5333",
    longitude: "9.2",
  },
  {
    country_code: "CH",
    zipcode: "7440",
    place: "Andeer",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Andeer",
    community_code: "3701",
    latitude: "46.6034",
    longitude: "9.4261",
  },
  {
    country_code: "CH",
    zipcode: "7442",
    place: "Clugin",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Andeer",
    community_code: "3701",
    latitude: "46.6165",
    longitude: "9.4256",
  },
  {
    country_code: "CH",
    zipcode: "7443",
    place: "Pignia",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Andeer",
    community_code: "3701",
    latitude: "46.6143",
    longitude: "9.4381",
  },
  {
    country_code: "CH",
    zipcode: "7444",
    place: "Ausserferrera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Ferrera",
    community_code: "3713",
    latitude: "46.5567",
    longitude: "9.4396",
  },
  {
    country_code: "CH",
    zipcode: "7445",
    place: "Innerferrera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Ferrera",
    community_code: "3713",
    latitude: "46.5212",
    longitude: "9.4431",
  },
  {
    country_code: "CH",
    zipcode: "7445",
    place: "Innerferrera",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Ferrera",
    community_code: "3713",
    latitude: "46.5212",
    longitude: "9.4431",
  },
  {
    country_code: "CH",
    zipcode: "7446",
    place: "Campsut-Cröt",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Avers",
    community_code: "3681",
    latitude: "46.4869",
    longitude: "9.4782",
  },
  {
    country_code: "CH",
    zipcode: "7447",
    place: "Cresta (Avers)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Avers",
    community_code: "3681",
    latitude: "46.4468",
    longitude: "9.5306",
  },
  {
    country_code: "CH",
    zipcode: "7447",
    place: "Am Bach (Avers)",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Avers",
    community_code: "3681",
    latitude: "46.4586",
    longitude: "9.5374",
  },
  {
    country_code: "CH",
    zipcode: "7448",
    place: "Juf",
    state: "Kanton Graubünden",
    state_code: "GR",
    province: "Region Viamala",
    province_code: "1851",
    community: "Avers",
    community_code: "3681",
    latitude: "46.4453",
    longitude: "9.5795",
  },
  {
    country_code: "CH",
    zipcode: "2800",
    place: "Delémont",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Delémont",
    community_code: "6711",
    latitude: "47.3649",
    longitude: "7.3445",
  },
  {
    country_code: "CH",
    zipcode: "2802",
    place: "Develier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Develier",
    community_code: "6712",
    latitude: "47.3577",
    longitude: "7.2984",
  },
  {
    country_code: "CH",
    zipcode: "2803",
    place: "Bourrignon",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Bourrignon",
    community_code: "6703",
    latitude: "47.3955",
    longitude: "7.2454",
  },
  {
    country_code: "CH",
    zipcode: "2805",
    place: "Soyhières",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Soyhières",
    community_code: "6724",
    latitude: "47.3911",
    longitude: "7.3698",
  },
  {
    country_code: "CH",
    zipcode: "2806",
    place: "Mettembert",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Mettembert",
    community_code: "6716",
    latitude: "47.3967",
    longitude: "7.3205",
  },
  {
    country_code: "CH",
    zipcode: "2807",
    place: "Pleigne",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Pleigne",
    community_code: "6719",
    latitude: "47.4076",
    longitude: "7.2906",
  },
  {
    country_code: "CH",
    zipcode: "2807",
    place: "Lucelle",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Pleigne",
    community_code: "6719",
    latitude: "47.4076",
    longitude: "7.2906",
  },
  {
    country_code: "CH",
    zipcode: "2812",
    place: "Movelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Movelier",
    community_code: "6718",
    latitude: "47.4095",
    longitude: "7.3172",
  },
  {
    country_code: "CH",
    zipcode: "2813",
    place: "Ederswiler",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Ederswiler",
    community_code: "6713",
    latitude: "47.4239",
    longitude: "7.331",
  },
  {
    country_code: "CH",
    zipcode: "2822",
    place: "Courroux",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Courroux",
    community_code: "6709",
    latitude: "47.3607",
    longitude: "7.3737",
  },
  {
    country_code: "CH",
    zipcode: "2823",
    place: "Courcelon",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Courroux",
    community_code: "6709",
    latitude: "47.363",
    longitude: "7.3918",
  },
  {
    country_code: "CH",
    zipcode: "2824",
    place: "Vicques",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Val Terbi",
    community_code: "6730",
    latitude: "47.351",
    longitude: "7.4027",
  },
  {
    country_code: "CH",
    zipcode: "2825",
    place: "Courchapoix",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Courchapoix",
    community_code: "6706",
    latitude: "47.3473",
    longitude: "7.4514",
  },
  {
    country_code: "CH",
    zipcode: "2826",
    place: "Corban",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Corban",
    community_code: "6705",
    latitude: "47.3462",
    longitude: "7.4769",
  },
  {
    country_code: "CH",
    zipcode: "2827",
    place: "Mervelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Mervelier",
    community_code: "6715",
    latitude: "47.3435",
    longitude: "7.4997",
  },
  {
    country_code: "CH",
    zipcode: "2828",
    place: "Montsevelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Val Terbi",
    community_code: "6730",
    latitude: "47.3591",
    longitude: "7.5104",
  },
  {
    country_code: "CH",
    zipcode: "2829",
    place: "Vermes",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Val Terbi",
    community_code: "6730",
    latitude: "47.3295",
    longitude: "7.4752",
  },
  {
    country_code: "CH",
    zipcode: "2830",
    place: "Vellerat",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Vellerat",
    community_code: "6728",
    latitude: "47.3203",
    longitude: "7.37",
  },
  {
    country_code: "CH",
    zipcode: "2830",
    place: "Courrendlin",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Courrendlin",
    community_code: "6708",
    latitude: "47.3385",
    longitude: "7.3724",
  },
  {
    country_code: "CH",
    zipcode: "2832",
    place: "Rebeuvelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Rebeuvelier",
    community_code: "6720",
    latitude: "47.3248",
    longitude: "7.4094",
  },
  {
    country_code: "CH",
    zipcode: "2842",
    place: "Rossemaison",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Rossemaison",
    community_code: "6721",
    latitude: "47.3443",
    longitude: "7.3409",
  },
  {
    country_code: "CH",
    zipcode: "2843",
    place: "Châtillon JU",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Châtillon (JU)",
    community_code: "6704",
    latitude: "47.3264",
    longitude: "7.3448",
  },
  {
    country_code: "CH",
    zipcode: "2852",
    place: "Courtételle",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Courtételle",
    community_code: "6710",
    latitude: "47.3407",
    longitude: "7.3183",
  },
  {
    country_code: "CH",
    zipcode: "2853",
    place: "Courfaivre",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Haute-Sorne",
    community_code: "6729",
    latitude: "47.335",
    longitude: "7.282",
  },
  {
    country_code: "CH",
    zipcode: "2854",
    place: "Bassecourt",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Haute-Sorne",
    community_code: "6729",
    latitude: "47.3383",
    longitude: "7.2449",
  },
  {
    country_code: "CH",
    zipcode: "2855",
    place: "Glovelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Haute-Sorne",
    community_code: "6729",
    latitude: "47.3347",
    longitude: "7.2054",
  },
  {
    country_code: "CH",
    zipcode: "2856",
    place: "Boécourt",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Boécourt",
    community_code: "6702",
    latitude: "47.3511",
    longitude: "7.216",
  },
  {
    country_code: "CH",
    zipcode: "2857",
    place: "Montavon",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Boécourt",
    community_code: "6702",
    latitude: "47.3739",
    longitude: "7.2369",
  },
  {
    country_code: "CH",
    zipcode: "2863",
    place: "Undervelier",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Haute-Sorne",
    community_code: "6729",
    latitude: "47.3031",
    longitude: "7.2227",
  },
  {
    country_code: "CH",
    zipcode: "2864",
    place: "Soulce",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Haute-Sorne",
    community_code: "6729",
    latitude: "47.3043",
    longitude: "7.2713",
  },
  {
    country_code: "CH",
    zipcode: "2873",
    place: "Saulcy",
    state: "Jura",
    state_code: "JU",
    province: "Delémont District",
    province_code: "2601",
    community: "Saulcy",
    community_code: "6722",
    latitude: "47.3024",
    longitude: "7.154",
  },
  {
    country_code: "CH",
    zipcode: "2336",
    place: "Les Bois",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Les Bois",
    community_code: "6742",
    latitude: "47.1771",
    longitude: "6.905",
  },
  {
    country_code: "CH",
    zipcode: "2338",
    place: "Muriaux",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Muriaux",
    community_code: "6753",
    latitude: "47.2458",
    longitude: "6.9791",
  },
  {
    country_code: "CH",
    zipcode: "2338",
    place: "Les Emibois",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Muriaux",
    community_code: "6753",
    latitude: "47.2332",
    longitude: "6.9867",
  },
  {
    country_code: "CH",
    zipcode: "2340",
    place: "Le Noirmont",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Le Noirmont",
    community_code: "6754",
    latitude: "47.2257",
    longitude: "6.9573",
  },
  {
    country_code: "CH",
    zipcode: "2345",
    place: "Les Breuleux",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Les Breuleux",
    community_code: "6743",
    latitude: "47.211",
    longitude: "7.0079",
  },
  {
    country_code: "CH",
    zipcode: "2345",
    place: "La Chaux-des-Breuleux",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "La Chaux-des-Breuleux",
    community_code: "6744",
    latitude: "47.2221",
    longitude: "7.0283",
  },
  {
    country_code: "CH",
    zipcode: "2350",
    place: "Saignelégier",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Saignelégier",
    community_code: "6757",
    latitude: "47.2562",
    longitude: "6.9965",
  },
  {
    country_code: "CH",
    zipcode: "2353",
    place: "Les Pommerats",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Saignelégier",
    community_code: "6757",
    latitude: "47.2716",
    longitude: "6.9849",
  },
  {
    country_code: "CH",
    zipcode: "2354",
    place: "Goumois",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Saignelégier",
    community_code: "6757",
    latitude: "47.2611",
    longitude: "6.9524",
  },
  {
    country_code: "CH",
    zipcode: "2360",
    place: "Le Bémont JU",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Le Bémont (JU)",
    community_code: "6741",
    latitude: "47.263",
    longitude: "7.0152",
  },
  {
    country_code: "CH",
    zipcode: "2362",
    place: "Montfavergier",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Montfaucon",
    community_code: "6751",
    latitude: "47.3088",
    longitude: "7.0881",
  },
  {
    country_code: "CH",
    zipcode: "2362",
    place: "Montfaucon",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Montfaucon",
    community_code: "6751",
    latitude: "47.2821",
    longitude: "7.0512",
  },
  {
    country_code: "CH",
    zipcode: "2363",
    place: "Les Enfers",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Les Enfers",
    community_code: "6745",
    latitude: "47.2893",
    longitude: "7.043",
  },
  {
    country_code: "CH",
    zipcode: "2364",
    place: "St-Brais",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Saint-Brais",
    community_code: "6758",
    latitude: "47.3061",
    longitude: "7.1137",
  },
  {
    country_code: "CH",
    zipcode: "2714",
    place: "Les Genevez JU",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Les Genevez (JU)",
    community_code: "6748",
    latitude: "47.2577",
    longitude: "7.1092",
  },
  {
    country_code: "CH",
    zipcode: "2714",
    place: "Le Prédame",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Les Genevez (JU)",
    community_code: "6748",
    latitude: "47.2577",
    longitude: "7.1092",
  },
  {
    country_code: "CH",
    zipcode: "2718",
    place: "Lajoux JU",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Lajoux (JU)",
    community_code: "6750",
    latitude: "47.2789",
    longitude: "7.1373",
  },
  {
    country_code: "CH",
    zipcode: "2718",
    place: "Fornet-Dessus",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Lajoux (JU)",
    community_code: "6750",
    latitude: "47.2789",
    longitude: "7.1373",
  },
  {
    country_code: "CH",
    zipcode: "2882",
    place: "St-Ursanne",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Saint-Brais",
    community_code: "6758",
    latitude: "47.2495",
    longitude: "7.0172",
  },
  {
    country_code: "CH",
    zipcode: "2887",
    place: "Soubey",
    state: "Jura",
    state_code: "JU",
    province: "Franches-Montagnes District",
    province_code: "2602",
    community: "Soubey",
    community_code: "6759",
    latitude: "47.3093",
    longitude: "7.0489",
  },
  {
    country_code: "CH",
    zipcode: "2882",
    place: "St-Ursanne",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3647",
    longitude: "7.1544",
  },
  {
    country_code: "CH",
    zipcode: "2883",
    place: "Montmelon",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3525",
    longitude: "7.1779",
  },
  {
    country_code: "CH",
    zipcode: "2884",
    place: "Montenol",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3518",
    longitude: "7.1487",
  },
  {
    country_code: "CH",
    zipcode: "2885",
    place: "Epauvillers",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3363",
    longitude: "7.1194",
  },
  {
    country_code: "CH",
    zipcode: "2886",
    place: "Epiquerez",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3318",
    longitude: "7.0897",
  },
  {
    country_code: "CH",
    zipcode: "2888",
    place: "Seleute",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3667",
    longitude: "7.1115",
  },
  {
    country_code: "CH",
    zipcode: "2889",
    place: "Ocourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Clos du Doubs",
    community_code: "6808",
    latitude: "47.3522",
    longitude: "7.0783",
  },
  {
    country_code: "CH",
    zipcode: "2900",
    place: "Porrentruy",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Porrentruy",
    community_code: "6800",
    latitude: "47.4153",
    longitude: "7.0752",
  },
  {
    country_code: "CH",
    zipcode: "2902",
    place: "Fontenais",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Fontenais",
    community_code: "6790",
    latitude: "47.4048",
    longitude: "7.0819",
  },
  {
    country_code: "CH",
    zipcode: "2903",
    place: "Villars-sur-Fontenais",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Fontenais",
    community_code: "6790",
    latitude: "47.389",
    longitude: "7.0811",
  },
  {
    country_code: "CH",
    zipcode: "2904",
    place: "Bressaucourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Fontenais",
    community_code: "6790",
    latitude: "47.3881",
    longitude: "7.0352",
  },
  {
    country_code: "CH",
    zipcode: "2905",
    place: "Courtedoux",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Courtedoux",
    community_code: "6785",
    latitude: "47.4082",
    longitude: "7.0428",
  },
  {
    country_code: "CH",
    zipcode: "2906",
    place: "Chevenez",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Haute-Ajoie",
    community_code: "6809",
    latitude: "47.3917",
    longitude: "7",
  },
  {
    country_code: "CH",
    zipcode: "2907",
    place: "Rocourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Rocourt",
    community_code: "6803",
    latitude: "47.3905",
    longitude: "6.9566",
  },
  {
    country_code: "CH",
    zipcode: "2908",
    place: "Grandfontaine",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Grandfontaine",
    community_code: "6792",
    latitude: "47.3917",
    longitude: "6.9395",
  },
  {
    country_code: "CH",
    zipcode: "2912",
    place: "Réclère",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Haute-Ajoie",
    community_code: "6809",
    latitude: "47.3756",
    longitude: "6.919",
  },
  {
    country_code: "CH",
    zipcode: "2912",
    place: "Roche-d'Or",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Haute-Ajoie",
    community_code: "6809",
    latitude: "47.3665",
    longitude: "6.9553",
  },
  {
    country_code: "CH",
    zipcode: "2914",
    place: "Damvant",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Haute-Ajoie",
    community_code: "6809",
    latitude: "47.3725",
    longitude: "6.8973",
  },
  {
    country_code: "CH",
    zipcode: "2915",
    place: "Bure",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Bure",
    community_code: "6778",
    latitude: "47.4417",
    longitude: "7.0075",
  },
  {
    country_code: "CH",
    zipcode: "2916",
    place: "Fahy",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Fahy",
    community_code: "6789",
    latitude: "47.4182",
    longitude: "6.95",
  },
  {
    country_code: "CH",
    zipcode: "2922",
    place: "Courchavon",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Courchavon",
    community_code: "6783",
    latitude: "47.4406",
    longitude: "7.0584",
  },
  {
    country_code: "CH",
    zipcode: "2923",
    place: "Courtemaîche",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Basse-Allaine",
    community_code: "6807",
    latitude: "47.4572",
    longitude: "7.0483",
  },
  {
    country_code: "CH",
    zipcode: "2924",
    place: "Montignez",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Basse-Allaine",
    community_code: "6807",
    latitude: "47.4871",
    longitude: "7.0566",
  },
  {
    country_code: "CH",
    zipcode: "2925",
    place: "Buix",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Basse-Allaine",
    community_code: "6807",
    latitude: "47.4811",
    longitude: "7.0305",
  },
  {
    country_code: "CH",
    zipcode: "2926",
    place: "Boncourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Boncourt",
    community_code: "6774",
    latitude: "47.4956",
    longitude: "7.0142",
  },
  {
    country_code: "CH",
    zipcode: "2932",
    place: "Coeuve",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Coeuve",
    community_code: "6781",
    latitude: "47.4529",
    longitude: "7.0979",
  },
  {
    country_code: "CH",
    zipcode: "2933",
    place: "Damphreux",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Damphreux",
    community_code: "6787",
    latitude: "47.476",
    longitude: "7.1069",
  },
  {
    country_code: "CH",
    zipcode: "2933",
    place: "Lugnez",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Lugnez",
    community_code: "6793",
    latitude: "47.4851",
    longitude: "7.0975",
  },
  {
    country_code: "CH",
    zipcode: "2935",
    place: "Beurnevésin",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Beurnevésin",
    community_code: "6773",
    latitude: "47.4925",
    longitude: "7.1337",
  },
  {
    country_code: "CH",
    zipcode: "2942",
    place: "Alle",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Alle",
    community_code: "6771",
    latitude: "47.4262",
    longitude: "7.1291",
  },
  {
    country_code: "CH",
    zipcode: "2943",
    place: "Vendlincourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Vendlincourt",
    community_code: "6806",
    latitude: "47.4519",
    longitude: "7.1469",
  },
  {
    country_code: "CH",
    zipcode: "2944",
    place: "Bonfol",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Bonfol",
    community_code: "6775",
    latitude: "47.4774",
    longitude: "7.1514",
  },
  {
    country_code: "CH",
    zipcode: "2946",
    place: "Miécourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "La Baroche",
    community_code: "6810",
    latitude: "47.4274",
    longitude: "7.1759",
  },
  {
    country_code: "CH",
    zipcode: "2947",
    place: "Charmoille",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "La Baroche",
    community_code: "6810",
    latitude: "47.4238",
    longitude: "7.2076",
  },
  {
    country_code: "CH",
    zipcode: "2950",
    place: "Courgenay",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Courgenay",
    community_code: "6784",
    latitude: "47.4036",
    longitude: "7.1242",
  },
  {
    country_code: "CH",
    zipcode: "2950",
    place: "Courtemautruy",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Courgenay",
    community_code: "6784",
    latitude: "47.396",
    longitude: "7.1393",
  },
  {
    country_code: "CH",
    zipcode: "2952",
    place: "Cornol",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "Cornol",
    community_code: "6782",
    latitude: "47.4075",
    longitude: "7.1625",
  },
  {
    country_code: "CH",
    zipcode: "2953",
    place: "Pleujouse",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "La Baroche",
    community_code: "6810",
    latitude: "47.413",
    longitude: "7.2108",
  },
  {
    country_code: "CH",
    zipcode: "2953",
    place: "Fregiécourt",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "La Baroche",
    community_code: "6810",
    latitude: "47.4122",
    longitude: "7.1985",
  },
  {
    country_code: "CH",
    zipcode: "2954",
    place: "Asuel",
    state: "Jura",
    state_code: "JU",
    province: "Porrentruy District",
    province_code: "2603",
    community: "La Baroche",
    community_code: "6810",
    latitude: "47.4036",
    longitude: "7.2108",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 16",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 30 AAL",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 14",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 15",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 7",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6000",
    place: "Luzern 6",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6002",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6003",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6004",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6005",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6006",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6007",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0471",
    longitude: "8.3252",
  },
  {
    country_code: "CH",
    zipcode: "6014",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6015",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Stadt District",
    province_code: "311",
    community: "Luzern",
    community_code: "1061",
    latitude: "47.0505",
    longitude: "8.3064",
  },
  {
    country_code: "CH",
    zipcode: "6005",
    place: "St. Niklausen LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Horw",
    community_code: "1058",
    latitude: "47.0202",
    longitude: "8.3407",
  },
  {
    country_code: "CH",
    zipcode: "6009",
    place: "Luzern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Kriens",
    community_code: "1059",
    latitude: "47.0208",
    longitude: "8.2589",
  },
  {
    country_code: "CH",
    zipcode: "6010",
    place: "Kriens",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Kriens",
    community_code: "1059",
    latitude: "47.0311",
    longitude: "8.2855",
  },
  {
    country_code: "CH",
    zipcode: "6011",
    place: "Kriens",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Kriens",
    community_code: "1059",
    latitude: "47.0208",
    longitude: "8.2589",
  },
  {
    country_code: "CH",
    zipcode: "6012",
    place: "Obernau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Kriens",
    community_code: "1059",
    latitude: "47.0333",
    longitude: "8.2544",
  },
  {
    country_code: "CH",
    zipcode: "6013",
    place: "Eigenthal",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Schwarzenberg",
    community_code: "1066",
    latitude: "47.0107",
    longitude: "8.2126",
  },
  {
    country_code: "CH",
    zipcode: "6030",
    place: "Ebikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Ebikon",
    community_code: "1054",
    latitude: "47.0794",
    longitude: "8.3404",
  },
  {
    country_code: "CH",
    zipcode: "6031",
    place: "Ebikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Ebikon",
    community_code: "1054",
    latitude: "47.0783",
    longitude: "8.3315",
  },
  {
    country_code: "CH",
    zipcode: "6033",
    place: "Buchrain",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Buchrain",
    community_code: "1052",
    latitude: "47.0962",
    longitude: "8.3473",
  },
  {
    country_code: "CH",
    zipcode: "6035",
    place: "Perlen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Buchrain",
    community_code: "1052",
    latitude: "47.1094",
    longitude: "8.362",
  },
  {
    country_code: "CH",
    zipcode: "6036",
    place: "Dierikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Dierikon",
    community_code: "1053",
    latitude: "47.0968",
    longitude: "8.3695",
  },
  {
    country_code: "CH",
    zipcode: "6037",
    place: "Root",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Root",
    community_code: "1065",
    latitude: "47.1146",
    longitude: "8.3902",
  },
  {
    country_code: "CH",
    zipcode: "6038",
    place: "Gisikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Gisikon",
    community_code: "1055",
    latitude: "47.1255",
    longitude: "8.4036",
  },
  {
    country_code: "CH",
    zipcode: "6038",
    place: "Honau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Honau",
    community_code: "1057",
    latitude: "47.1325",
    longitude: "8.4081",
  },
  {
    country_code: "CH",
    zipcode: "6039",
    place: "Root D4",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Root",
    community_code: "1065",
    latitude: "47.1073",
    longitude: "8.3634",
  },
  {
    country_code: "CH",
    zipcode: "6043",
    place: "Adligenswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Adligenswil",
    community_code: "1051",
    latitude: "47.0652",
    longitude: "8.3612",
  },
  {
    country_code: "CH",
    zipcode: "6044",
    place: "Udligenswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Udligenswil",
    community_code: "1067",
    latitude: "47.09",
    longitude: "8.4034",
  },
  {
    country_code: "CH",
    zipcode: "6045",
    place: "Meggen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Meggen",
    community_code: "1063",
    latitude: "47.0469",
    longitude: "8.3747",
  },
  {
    country_code: "CH",
    zipcode: "6047",
    place: "Kastanienbaum",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Horw",
    community_code: "1058",
    latitude: "47.0082",
    longitude: "8.34",
  },
  {
    country_code: "CH",
    zipcode: "6048",
    place: "Horw",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Horw",
    community_code: "1058",
    latitude: "47.0169",
    longitude: "8.3096",
  },
  {
    country_code: "CH",
    zipcode: "6102",
    place: "Malters",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Malters",
    community_code: "1062",
    latitude: "47.0363",
    longitude: "8.1819",
  },
  {
    country_code: "CH",
    zipcode: "6103",
    place: "Schwarzenberg LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Schwarzenberg",
    community_code: "1066",
    latitude: "47.0171",
    longitude: "8.1726",
  },
  {
    country_code: "CH",
    zipcode: "6344",
    place: "Meierskappel",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Meierskappel",
    community_code: "1064",
    latitude: "47.1242",
    longitude: "8.4444",
  },
  {
    country_code: "CH",
    zipcode: "6353",
    place: "Weggis",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Weggis",
    community_code: "1069",
    latitude: "47.0321",
    longitude: "8.4322",
  },
  {
    country_code: "CH",
    zipcode: "6354",
    place: "Vitznau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Vitznau",
    community_code: "1068",
    latitude: "47.0101",
    longitude: "8.4842",
  },
  {
    country_code: "CH",
    zipcode: "6356",
    place: "Rigi Kaltbad",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Weggis",
    community_code: "1069",
    latitude: "47.0442",
    longitude: "8.4652",
  },
  {
    country_code: "CH",
    zipcode: "6404",
    place: "Greppen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Lucerne-Land District",
    province_code: "312",
    community: "Greppen",
    community_code: "1056",
    latitude: "47.0551",
    longitude: "8.4303",
  },
  {
    country_code: "CH",
    zipcode: "6020",
    place: "Emmenbrücke",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Emmen",
    community_code: "1024",
    latitude: "47.0728",
    longitude: "8.289",
  },
  {
    country_code: "CH",
    zipcode: "6021",
    place: "Emmenbrücke 1",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Emmen",
    community_code: "1024",
    latitude: "47.0866",
    longitude: "8.2862",
  },
  {
    country_code: "CH",
    zipcode: "6023",
    place: "Rothenburg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Rothenburg",
    community_code: "1040",
    latitude: "47.0957",
    longitude: "8.2723",
  },
  {
    country_code: "CH",
    zipcode: "6026",
    place: "Rain",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Rain",
    community_code: "1037",
    latitude: "47.1297",
    longitude: "8.2579",
  },
  {
    country_code: "CH",
    zipcode: "6027",
    place: "Römerswil LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Römerswil",
    community_code: "1039",
    latitude: "47.1681",
    longitude: "8.2464",
  },
  {
    country_code: "CH",
    zipcode: "6028",
    place: "Herlisberg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Römerswil",
    community_code: "1039",
    latitude: "47.1994",
    longitude: "8.2312",
  },
  {
    country_code: "CH",
    zipcode: "6032",
    place: "Emmen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Emmen",
    community_code: "1024",
    latitude: "47.0861",
    longitude: "8.3005",
  },
  {
    country_code: "CH",
    zipcode: "6034",
    place: "Inwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Inwil",
    community_code: "1033",
    latitude: "47.1253",
    longitude: "8.3488",
  },
  {
    country_code: "CH",
    zipcode: "6274",
    place: "Eschenbach LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Eschenbach (LU)",
    community_code: "1026",
    latitude: "47.1323",
    longitude: "8.3196",
  },
  {
    country_code: "CH",
    zipcode: "6275",
    place: "Ballwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Ballwil",
    community_code: "1023",
    latitude: "47.1549",
    longitude: "8.3214",
  },
  {
    country_code: "CH",
    zipcode: "6276",
    place: "Hohenrain",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hohenrain",
    community_code: "1032",
    latitude: "47.1808",
    longitude: "8.318",
  },
  {
    country_code: "CH",
    zipcode: "6277",
    place: "Lieli LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hohenrain",
    community_code: "1032",
    latitude: "47.2077",
    longitude: "8.2971",
  },
  {
    country_code: "CH",
    zipcode: "6277",
    place: "Kleinwangen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hohenrain",
    community_code: "1032",
    latitude: "47.2077",
    longitude: "8.2971",
  },
  {
    country_code: "CH",
    zipcode: "6280",
    place: "Hochdorf",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hochdorf",
    community_code: "1031",
    latitude: "47.1684",
    longitude: "8.2918",
  },
  {
    country_code: "CH",
    zipcode: "6280",
    place: "Urswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hochdorf",
    community_code: "1031",
    latitude: "47.1523",
    longitude: "8.2949",
  },
  {
    country_code: "CH",
    zipcode: "6281",
    place: "Hochdorf",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hochdorf",
    community_code: "1031",
    latitude: "47.1664",
    longitude: "8.2923",
  },
  {
    country_code: "CH",
    zipcode: "6283",
    place: "Baldegg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hochdorf",
    community_code: "1031",
    latitude: "47.1833",
    longitude: "8.2808",
  },
  {
    country_code: "CH",
    zipcode: "6284",
    place: "Gelfingen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.2145",
    longitude: "8.2654",
  },
  {
    country_code: "CH",
    zipcode: "6284",
    place: "Sulz LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.22",
    longitude: "8.2857",
  },
  {
    country_code: "CH",
    zipcode: "6285",
    place: "Retschwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.1929",
    longitude: "8.2539",
  },
  {
    country_code: "CH",
    zipcode: "6285",
    place: "Hitzkirch",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.224",
    longitude: "8.2643",
  },
  {
    country_code: "CH",
    zipcode: "6286",
    place: "Altwis",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Altwis",
    community_code: "1022",
    latitude: "47.2385",
    longitude: "8.2478",
  },
  {
    country_code: "CH",
    zipcode: "6287",
    place: "Aesch LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Aesch (LU)",
    community_code: "1021",
    latitude: "47.2564",
    longitude: "8.2409",
  },
  {
    country_code: "CH",
    zipcode: "6288",
    place: "Schongau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Schongau",
    community_code: "1041",
    latitude: "47.2685",
    longitude: "8.2651",
  },
  {
    country_code: "CH",
    zipcode: "6289",
    place: "Hämikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.2384",
    longitude: "8.2764",
  },
  {
    country_code: "CH",
    zipcode: "6289",
    place: "Müswangen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.2383",
    longitude: "8.2891",
  },
  {
    country_code: "CH",
    zipcode: "6289",
    place: "Hämikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.2384",
    longitude: "8.2764",
  },
  {
    country_code: "CH",
    zipcode: "6294",
    place: "Ermensee",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Ermensee",
    community_code: "1025",
    latitude: "47.2278",
    longitude: "8.2363",
  },
  {
    country_code: "CH",
    zipcode: "6295",
    place: "Mosen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Hochdorf District",
    province_code: "313",
    community: "Hitzkirch",
    community_code: "1030",
    latitude: "47.2449",
    longitude: "8.2263",
  },
  {
    country_code: "CH",
    zipcode: "5735",
    place: "Pfeffikon LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Rickenbach (LU)",
    community_code: "1097",
    latitude: "47.2276",
    longitude: "8.1481",
  },
  {
    country_code: "CH",
    zipcode: "6016",
    place: "Hellbühl",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Neuenkirch",
    community_code: "1093",
    latitude: "47.0708",
    longitude: "8.1991",
  },
  {
    country_code: "CH",
    zipcode: "6017",
    place: "Ruswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Ruswil",
    community_code: "1098",
    latitude: "47.0843",
    longitude: "8.1264",
  },
  {
    country_code: "CH",
    zipcode: "6018",
    place: "Buttisholz",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Buttisholz",
    community_code: "1083",
    latitude: "47.1144",
    longitude: "8.0943",
  },
  {
    country_code: "CH",
    zipcode: "6019",
    place: "Sigigen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Ruswil",
    community_code: "1098",
    latitude: "47.0654",
    longitude: "8.1342",
  },
  {
    country_code: "CH",
    zipcode: "6022",
    place: "Grosswangen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Grosswangen",
    community_code: "1086",
    latitude: "47.1328",
    longitude: "8.0478",
  },
  {
    country_code: "CH",
    zipcode: "6024",
    place: "Hildisrieden",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Hildisrieden",
    community_code: "1088",
    latitude: "47.1507",
    longitude: "8.2258",
  },
  {
    country_code: "CH",
    zipcode: "6025",
    place: "Neudorf",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Beromünster",
    community_code: "1081",
    latitude: "47.177",
    longitude: "8.2091",
  },
  {
    country_code: "CH",
    zipcode: "6203",
    place: "Sempach Station",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Neuenkirch",
    community_code: "1093",
    latitude: "47.1169",
    longitude: "8.1947",
  },
  {
    country_code: "CH",
    zipcode: "6204",
    place: "Sempach",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Sempach",
    community_code: "1102",
    latitude: "47.1358",
    longitude: "8.1915",
  },
  {
    country_code: "CH",
    zipcode: "6205",
    place: "Eich",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Eich",
    community_code: "1084",
    latitude: "47.1512",
    longitude: "8.167",
  },
  {
    country_code: "CH",
    zipcode: "6206",
    place: "Neuenkirch",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Neuenkirch",
    community_code: "1093",
    latitude: "47.0999",
    longitude: "8.2042",
  },
  {
    country_code: "CH",
    zipcode: "6207",
    place: "Nottwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Nottwil",
    community_code: "1094",
    latitude: "47.1357",
    longitude: "8.1371",
  },
  {
    country_code: "CH",
    zipcode: "6208",
    place: "Oberkirch LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Oberkirch",
    community_code: "1095",
    latitude: "47.1564",
    longitude: "8.1157",
  },
  {
    country_code: "CH",
    zipcode: "6210",
    place: "Sursee",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Sursee",
    community_code: "1103",
    latitude: "47.1709",
    longitude: "8.1111",
  },
  {
    country_code: "CH",
    zipcode: "6212",
    place: "Kaltbach",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Mauensee",
    community_code: "1091",
    latitude: "47.184",
    longitude: "8.0623",
  },
  {
    country_code: "CH",
    zipcode: "6212",
    place: "St. Erhard",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Knutwil",
    community_code: "1089",
    latitude: "47.1843",
    longitude: "8.0751",
  },
  {
    country_code: "CH",
    zipcode: "6213",
    place: "Knutwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Knutwil",
    community_code: "1089",
    latitude: "47.1995",
    longitude: "8.0732",
  },
  {
    country_code: "CH",
    zipcode: "6214",
    place: "Schenkon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Schenkon",
    community_code: "1099",
    latitude: "47.1683",
    longitude: "8.1438",
  },
  {
    country_code: "CH",
    zipcode: "6215",
    place: "Beromünster",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Beromünster",
    community_code: "1081",
    latitude: "47.2061",
    longitude: "8.1926",
  },
  {
    country_code: "CH",
    zipcode: "6215",
    place: "Schwarzenbach LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Beromünster",
    community_code: "1081",
    latitude: "47.2333",
    longitude: "8.2126",
  },
  {
    country_code: "CH",
    zipcode: "6216",
    place: "Mauensee",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Mauensee",
    community_code: "1091",
    latitude: "47.1685",
    longitude: "8.0662",
  },
  {
    country_code: "CH",
    zipcode: "6221",
    place: "Rickenbach LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Rickenbach (LU)",
    community_code: "1097",
    latitude: "47.2174",
    longitude: "8.1549",
  },
  {
    country_code: "CH",
    zipcode: "6222",
    place: "Gunzwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Beromünster",
    community_code: "1081",
    latitude: "47.2107",
    longitude: "8.1793",
  },
  {
    country_code: "CH",
    zipcode: "6231",
    place: "Schlierbach",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Schlierbach",
    community_code: "1100",
    latitude: "47.2235",
    longitude: "8.111",
  },
  {
    country_code: "CH",
    zipcode: "6232",
    place: "Geuensee",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Geuensee",
    community_code: "1085",
    latitude: "47.1997",
    longitude: "8.1069",
  },
  {
    country_code: "CH",
    zipcode: "6233",
    place: "Büron",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Büron",
    community_code: "1082",
    latitude: "47.2121",
    longitude: "8.0942",
  },
  {
    country_code: "CH",
    zipcode: "6234",
    place: "Triengen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Triengen",
    community_code: "1104",
    latitude: "47.2337",
    longitude: "8.0773",
  },
  {
    country_code: "CH",
    zipcode: "6234",
    place: "Kulmerau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Triengen",
    community_code: "1104",
    latitude: "47.2546",
    longitude: "8.0892",
  },
  {
    country_code: "CH",
    zipcode: "6235",
    place: "Winikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Triengen",
    community_code: "1104",
    latitude: "47.2386",
    longitude: "8.0506",
  },
  {
    country_code: "CH",
    zipcode: "6236",
    place: "Wilihof",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Sursee District",
    province_code: "314",
    community: "Triengen",
    community_code: "1104",
    latitude: "47.2276",
    longitude: "8.0656",
  },
  {
    country_code: "CH",
    zipcode: "4806",
    place: "Wikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Wikon",
    community_code: "1147",
    latitude: "47.2634",
    longitude: "7.968",
  },
  {
    country_code: "CH",
    zipcode: "4915",
    place: "St. Urban",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Pfaffnau",
    community_code: "1139",
    latitude: "47.2318",
    longitude: "7.8417",
  },
  {
    country_code: "CH",
    zipcode: "6122",
    place: "Menznau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Menznau",
    community_code: "1136",
    latitude: "47.0836",
    longitude: "8.0397",
  },
  {
    country_code: "CH",
    zipcode: "6123",
    place: "Geiss",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Menznau",
    community_code: "1136",
    latitude: "47.092",
    longitude: "8.0569",
  },
  {
    country_code: "CH",
    zipcode: "6125",
    place: "Menzberg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Menznau",
    community_code: "1136",
    latitude: "47.041",
    longitude: "7.998",
  },
  {
    country_code: "CH",
    zipcode: "6126",
    place: "Daiwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Willisau",
    community_code: "1151",
    latitude: "47.0984",
    longitude: "8.0139",
  },
  {
    country_code: "CH",
    zipcode: "6130",
    place: "Willisau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Willisau",
    community_code: "1151",
    latitude: "47.1218",
    longitude: "7.9942",
  },
  {
    country_code: "CH",
    zipcode: "6132",
    place: "Rohrmatt",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Willisau",
    community_code: "1151",
    latitude: "47.0829",
    longitude: "7.9851",
  },
  {
    country_code: "CH",
    zipcode: "6133",
    place: "Hergiswil b. Willisau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Hergiswil bei Willisau",
    community_code: "1132",
    latitude: "47.085",
    longitude: "7.958",
  },
  {
    country_code: "CH",
    zipcode: "6142",
    place: "Gettnau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Gettnau",
    community_code: "1130",
    latitude: "47.1406",
    longitude: "7.9701",
  },
  {
    country_code: "CH",
    zipcode: "6143",
    place: "Ohmstal",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Schötz",
    community_code: "1143",
    latitude: "47.1623",
    longitude: "7.9527",
  },
  {
    country_code: "CH",
    zipcode: "6144",
    place: "Zell LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Zell (LU)",
    community_code: "1150",
    latitude: "47.1367",
    longitude: "7.9249",
  },
  {
    country_code: "CH",
    zipcode: "6145",
    place: "Fischbach LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Fischbach",
    community_code: "1129",
    latitude: "47.1545",
    longitude: "7.905",
  },
  {
    country_code: "CH",
    zipcode: "6146",
    place: "Grossdietwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Grossdietwil",
    community_code: "1131",
    latitude: "47.1684",
    longitude: "7.8914",
  },
  {
    country_code: "CH",
    zipcode: "6147",
    place: "Altbüron",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Altbüron",
    community_code: "1122",
    latitude: "47.1812",
    longitude: "7.8847",
  },
  {
    country_code: "CH",
    zipcode: "6152",
    place: "Hüswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Zell (LU)",
    community_code: "1150",
    latitude: "47.1287",
    longitude: "7.907",
  },
  {
    country_code: "CH",
    zipcode: "6153",
    place: "Ufhusen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Ufhusen",
    community_code: "1145",
    latitude: "47.117",
    longitude: "7.8961",
  },
  {
    country_code: "CH",
    zipcode: "6154",
    place: "Hofstatt",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Luthern",
    community_code: "1135",
    latitude: "47.086",
    longitude: "7.9161",
  },
  {
    country_code: "CH",
    zipcode: "6156",
    place: "Luthern",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Luthern",
    community_code: "1135",
    latitude: "47.0575",
    longitude: "7.9169",
  },
  {
    country_code: "CH",
    zipcode: "6156",
    place: "Luthern Bad",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Luthern",
    community_code: "1135",
    latitude: "47.0575",
    longitude: "7.9169",
  },
  {
    country_code: "CH",
    zipcode: "6211",
    place: "Buchs LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Dagmersellen",
    community_code: "1125",
    latitude: "47.2006",
    longitude: "8.0308",
  },
  {
    country_code: "CH",
    zipcode: "6217",
    place: "Kottwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Ettiswil",
    community_code: "1128",
    latitude: "47.1645",
    longitude: "8.0464",
  },
  {
    country_code: "CH",
    zipcode: "6218",
    place: "Ettiswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Ettiswil",
    community_code: "1128",
    latitude: "47.1503",
    longitude: "8.0176",
  },
  {
    country_code: "CH",
    zipcode: "6242",
    place: "Wauwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Wauwil",
    community_code: "1146",
    latitude: "47.1846",
    longitude: "8.021",
  },
  {
    country_code: "CH",
    zipcode: "6243",
    place: "Egolzwil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Egolzwil",
    community_code: "1127",
    latitude: "47.1843",
    longitude: "8.0075",
  },
  {
    country_code: "CH",
    zipcode: "6244",
    place: "Nebikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Nebikon",
    community_code: "1137",
    latitude: "47.1926",
    longitude: "7.9781",
  },
  {
    country_code: "CH",
    zipcode: "6245",
    place: "Ebersecken",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Ebersecken",
    community_code: "1126",
    latitude: "47.1803",
    longitude: "7.9389",
  },
  {
    country_code: "CH",
    zipcode: "6246",
    place: "Altishofen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Altishofen",
    community_code: "1123",
    latitude: "47.1992",
    longitude: "7.9696",
  },
  {
    country_code: "CH",
    zipcode: "6247",
    place: "Schötz",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Schötz",
    community_code: "1143",
    latitude: "47.169",
    longitude: "7.9887",
  },
  {
    country_code: "CH",
    zipcode: "6248",
    place: "Alberswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Alberswil",
    community_code: "1121",
    latitude: "47.15",
    longitude: "8.0031",
  },
  {
    country_code: "CH",
    zipcode: "6252",
    place: "Dagmersellen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Dagmersellen",
    community_code: "1125",
    latitude: "47.2137",
    longitude: "7.9847",
  },
  {
    country_code: "CH",
    zipcode: "6253",
    place: "Uffikon",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Dagmersellen",
    community_code: "1125",
    latitude: "47.211",
    longitude: "8.0181",
  },
  {
    country_code: "CH",
    zipcode: "6260",
    place: "Mehlsecken",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Reiden",
    community_code: "1140",
    latitude: "47.2445",
    longitude: "7.9572",
  },
  {
    country_code: "CH",
    zipcode: "6260",
    place: "Hintermoos",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Wikon",
    community_code: "1147",
    latitude: "47.2701",
    longitude: "8.0039",
  },
  {
    country_code: "CH",
    zipcode: "6260",
    place: "Reiden",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Reiden",
    community_code: "1140",
    latitude: "47.2472",
    longitude: "7.9714",
  },
  {
    country_code: "CH",
    zipcode: "6260",
    place: "Reidermoos",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Reiden",
    community_code: "1140",
    latitude: "47.2535",
    longitude: "7.9914",
  },
  {
    country_code: "CH",
    zipcode: "6262",
    place: "Langnau b. Reiden",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Reiden",
    community_code: "1140",
    latitude: "47.23",
    longitude: "7.9631",
  },
  {
    country_code: "CH",
    zipcode: "6263",
    place: "Richenthal",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Reiden",
    community_code: "1140",
    latitude: "47.2176",
    longitude: "7.9446",
  },
  {
    country_code: "CH",
    zipcode: "6264",
    place: "Pfaffnau",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Pfaffnau",
    community_code: "1139",
    latitude: "47.2277",
    longitude: "7.8972",
  },
  {
    country_code: "CH",
    zipcode: "6265",
    place: "Roggliswil",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Willisau District",
    province_code: "315",
    community: "Roggliswil",
    community_code: "1142",
    latitude: "47.2135",
    longitude: "7.8837",
  },
  {
    country_code: "CH",
    zipcode: "6105",
    place: "Schachen LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Werthenstein",
    community_code: "1009",
    latitude: "47.0375",
    longitude: "8.1395",
  },
  {
    country_code: "CH",
    zipcode: "6106",
    place: "Werthenstein",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Werthenstein",
    community_code: "1009",
    latitude: "47.0558",
    longitude: "8.1018",
  },
  {
    country_code: "CH",
    zipcode: "6110",
    place: "Wolhusen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Wolhusen",
    community_code: "1107",
    latitude: "47.0598",
    longitude: "8.0739",
  },
  {
    country_code: "CH",
    zipcode: "6110",
    place: "Fontannen b. Wolhusen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Wolhusen",
    community_code: "1107",
    latitude: "47.0532",
    longitude: "8.0566",
  },
  {
    country_code: "CH",
    zipcode: "6112",
    place: "Doppleschwand",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Doppleschwand",
    community_code: "1001",
    latitude: "47.0183",
    longitude: "8.055",
  },
  {
    country_code: "CH",
    zipcode: "6113",
    place: "Romoos",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Romoos",
    community_code: "1007",
    latitude: "47.0109",
    longitude: "8.0254",
  },
  {
    country_code: "CH",
    zipcode: "6114",
    place: "Steinhuserberg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Wolhusen",
    community_code: "1107",
    latitude: "47.0418",
    longitude: "8.0498",
  },
  {
    country_code: "CH",
    zipcode: "6160",
    place: "Entlebuch",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Entlebuch",
    community_code: "1002",
    latitude: "46.9762",
    longitude: "8.1079",
  },
  {
    country_code: "CH",
    zipcode: "6162",
    place: "Finsterwald b. Entlebuch",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Entlebuch",
    community_code: "1002",
    latitude: "46.9998",
    longitude: "8.0772",
  },
  {
    country_code: "CH",
    zipcode: "6162",
    place: "Rengg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Entlebuch",
    community_code: "1002",
    latitude: "47.0041",
    longitude: "8.0908",
  },
  {
    country_code: "CH",
    zipcode: "6162",
    place: "Entlebuch",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Entlebuch",
    community_code: "1002",
    latitude: "46.9956",
    longitude: "8.0635",
  },
  {
    country_code: "CH",
    zipcode: "6163",
    place: "Ebnet",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Entlebuch",
    community_code: "1002",
    latitude: "47.0231",
    longitude: "8.0782",
  },
  {
    country_code: "CH",
    zipcode: "6166",
    place: "Hasle LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Hasle (LU)",
    community_code: "1005",
    latitude: "46.9779",
    longitude: "8.0533",
  },
  {
    country_code: "CH",
    zipcode: "6167",
    place: "Bramboden",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Romoos",
    community_code: "1007",
    latitude: "46.9793",
    longitude: "7.9851",
  },
  {
    country_code: "CH",
    zipcode: "6170",
    place: "Schüpfheim",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Schüpfheim",
    community_code: "1008",
    latitude: "46.9516",
    longitude: "8.0172",
  },
  {
    country_code: "CH",
    zipcode: "6173",
    place: "Flühli LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Flühli",
    community_code: "1004",
    latitude: "46.8839",
    longitude: "8.0156",
  },
  {
    country_code: "CH",
    zipcode: "6174",
    place: "Sörenberg",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Flühli",
    community_code: "1004",
    latitude: "46.8222",
    longitude: "8.0374",
  },
  {
    country_code: "CH",
    zipcode: "6182",
    place: "Escholzmatt",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Escholzmatt-Marbach",
    community_code: "1010",
    latitude: "46.9135",
    longitude: "7.9343",
  },
  {
    country_code: "CH",
    zipcode: "6192",
    place: "Wiggen",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Escholzmatt-Marbach",
    community_code: "1010",
    latitude: "46.8963",
    longitude: "7.9088",
  },
  {
    country_code: "CH",
    zipcode: "6196",
    place: "Marbach LU",
    state: "Kanton Luzern",
    state_code: "LU",
    province: "Entlebuch District",
    province_code: "316",
    community: "Escholzmatt-Marbach",
    community_code: "1010",
    latitude: "46.8544",
    longitude: "7.8997",
  },
  {
    country_code: "CH",
    zipcode: "2012",
    place: "Auvernier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Milvignes",
    community_code: "6416",
    latitude: "46.9755",
    longitude: "6.879",
  },
  {
    country_code: "CH",
    zipcode: "2013",
    place: "Colombier NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Milvignes",
    community_code: "6416",
    latitude: "46.9663",
    longitude: "6.8648",
  },
  {
    country_code: "CH",
    zipcode: "2014",
    place: "Bôle",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Milvignes",
    community_code: "6416",
    latitude: "46.966",
    longitude: "6.842",
  },
  {
    country_code: "CH",
    zipcode: "2015",
    place: "Areuse",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Boudry",
    community_code: "6404",
    latitude: "46.9535",
    longitude: "6.8538",
  },
  {
    country_code: "CH",
    zipcode: "2016",
    place: "Cortaillod",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Cortaillod",
    community_code: "6408",
    latitude: "46.9431",
    longitude: "6.8444",
  },
  {
    country_code: "CH",
    zipcode: "2017",
    place: "Boudry",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Boudry",
    community_code: "6404",
    latitude: "46.9499",
    longitude: "6.8376",
  },
  {
    country_code: "CH",
    zipcode: "2019",
    place: "Chambrelien",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.9723",
    longitude: "6.8158",
  },
  {
    country_code: "CH",
    zipcode: "2019",
    place: "Rochefort",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.9793",
    longitude: "6.8146",
  },
  {
    country_code: "CH",
    zipcode: "2022",
    place: "Bevaix",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Bevaix",
    community_code: "6402",
    latitude: "46.9296",
    longitude: "6.8147",
  },
  {
    country_code: "CH",
    zipcode: "2023",
    place: "Gorgier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Gorgier",
    community_code: "6410",
    latitude: "46.9014",
    longitude: "6.7799",
  },
  {
    country_code: "CH",
    zipcode: "2024",
    place: "St-Aubin-Sauges",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Saint-Aubin-Sauges",
    community_code: "6414",
    latitude: "46.8942",
    longitude: "6.7725",
  },
  {
    country_code: "CH",
    zipcode: "2025",
    place: "Chez-le-Bart",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Gorgier",
    community_code: "6410",
    latitude: "46.9021",
    longitude: "6.7857",
  },
  {
    country_code: "CH",
    zipcode: "2027",
    place: "Montalchez",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Montalchez",
    community_code: "6411",
    latitude: "46.8982",
    longitude: "6.7446",
  },
  {
    country_code: "CH",
    zipcode: "2027",
    place: "Fresens",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Fresens",
    community_code: "6409",
    latitude: "46.8886",
    longitude: "6.7478",
  },
  {
    country_code: "CH",
    zipcode: "2028",
    place: "Vaumarcus",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Vaumarcus",
    community_code: "6415",
    latitude: "46.8791",
    longitude: "6.7573",
  },
  {
    country_code: "CH",
    zipcode: "2034",
    place: "Peseux",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Peseux",
    community_code: "6412",
    latitude: "46.987",
    longitude: "6.889",
  },
  {
    country_code: "CH",
    zipcode: "2035",
    place: "Corcelles NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Corcelles-Cormondrèche",
    community_code: "6407",
    latitude: "46.9864",
    longitude: "6.8775",
  },
  {
    country_code: "CH",
    zipcode: "2036",
    place: "Cormondrèche",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Corcelles-Cormondrèche",
    community_code: "6407",
    latitude: "46.9864",
    longitude: "6.8775",
  },
  {
    country_code: "CH",
    zipcode: "2037",
    place: "Montezillon",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.9866",
    longitude: "6.8366",
  },
  {
    country_code: "CH",
    zipcode: "2149",
    place: "Brot-Dessous",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.9865",
    longitude: "6.8038",
  },
  {
    country_code: "CH",
    zipcode: "2149",
    place: "Fretereules",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.9865",
    longitude: "6.8038",
  },
  {
    country_code: "CH",
    zipcode: "2149",
    place: "Champ-du-Moulin",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Boudry District",
    province_code: "2401",
    community: "Rochefort",
    community_code: "6413",
    latitude: "46.959",
    longitude: "6.7579",
  },
  {
    country_code: "CH",
    zipcode: "2300",
    place: "La Cibourg",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.0927",
    longitude: "6.8169",
  },
  {
    country_code: "CH",
    zipcode: "2300",
    place: "La Chaux-de-Fonds",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.0999",
    longitude: "6.8259",
  },
  {
    country_code: "CH",
    zipcode: "2301",
    place: "La Chaux-de-Fonds",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.1102",
    longitude: "6.8334",
  },
  {
    country_code: "CH",
    zipcode: "2303",
    place: "La Chaux-de-Fonds",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.1102",
    longitude: "6.8334",
  },
  {
    country_code: "CH",
    zipcode: "2304",
    place: "La Chaux-de-Fonds",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.1102",
    longitude: "6.8334",
  },
  {
    country_code: "CH",
    zipcode: "2314",
    place: "La Sagne NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Sagne",
    community_code: "6423",
    latitude: "47.0459",
    longitude: "6.8095",
  },
  {
    country_code: "CH",
    zipcode: "2322",
    place: "Le Crêt-du-Locle",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.0771",
    longitude: "6.7871",
  },
  {
    country_code: "CH",
    zipcode: "2325",
    place: "Les Planchettes",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "Les Planchettes",
    community_code: "6422",
    latitude: "47.1062",
    longitude: "6.7702",
  },
  {
    country_code: "CH",
    zipcode: "2333",
    place: "La Cibourg",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.0927",
    longitude: "6.8169",
  },
  {
    country_code: "CH",
    zipcode: "2616",
    place: "La Cibourg",
    state: "Neuchâtel",
    state_code: "NE",
    province: "La Chaux-de-Fonds District",
    province_code: "2402",
    community: "La Chaux-de-Fonds",
    community_code: "6421",
    latitude: "47.0927",
    longitude: "6.8169",
  },
  {
    country_code: "CH",
    zipcode: "2316",
    place: "Les Ponts-de-Martel",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Les Ponts-de-Martel",
    community_code: "6437",
    latitude: "46.9974",
    longitude: "6.7306",
  },
  {
    country_code: "CH",
    zipcode: "2316",
    place: "Petit-Martel",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Les Ponts-de-Martel",
    community_code: "6437",
    latitude: "47.0121",
    longitude: "6.7563",
  },
  {
    country_code: "CH",
    zipcode: "2318",
    place: "Brot-Plamboz",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Brot-Plamboz",
    community_code: "6433",
    latitude: "46.9854",
    longitude: "6.7478",
  },
  {
    country_code: "CH",
    zipcode: "2400",
    place: "Le Prévoux",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Le Locle",
    community_code: "6436",
    latitude: "47.0383",
    longitude: "6.7033",
  },
  {
    country_code: "CH",
    zipcode: "2400",
    place: "Le Locle",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Le Locle",
    community_code: "6436",
    latitude: "47.0562",
    longitude: "6.7491",
  },
  {
    country_code: "CH",
    zipcode: "2400",
    place: "Le Locle",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Le Locle",
    community_code: "6436",
    latitude: "47.0562",
    longitude: "6.7491",
  },
  {
    country_code: "CH",
    zipcode: "2405",
    place: "La Chaux-du-Milieu",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "La Chaux-du-Milieu",
    community_code: "6435",
    latitude: "47.0133",
    longitude: "6.7012",
  },
  {
    country_code: "CH",
    zipcode: "2406",
    place: "Le Brouillet",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "La Brévine",
    community_code: "6432",
    latitude: "46.9499",
    longitude: "6.534",
  },
  {
    country_code: "CH",
    zipcode: "2406",
    place: "La Brévine",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "La Brévine",
    community_code: "6432",
    latitude: "46.9805",
    longitude: "6.6064",
  },
  {
    country_code: "CH",
    zipcode: "2406",
    place: "Les Taillères",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "La Brévine",
    community_code: "6432",
    latitude: "46.9706",
    longitude: "6.5762",
  },
  {
    country_code: "CH",
    zipcode: "2406",
    place: "La Châtagne",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "La Brévine",
    community_code: "6432",
    latitude: "46.9902",
    longitude: "6.6421",
  },
  {
    country_code: "CH",
    zipcode: "2414",
    place: "Le Cerneux-Péquignot",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Le Cerneux-Péquignot",
    community_code: "6434",
    latitude: "47.0159",
    longitude: "6.6707",
  },
  {
    country_code: "CH",
    zipcode: "2416",
    place: "Les Brenets",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Le Locle District",
    province_code: "2403",
    community: "Les Brenets",
    community_code: "6431",
    latitude: "47.0677",
    longitude: "6.7048",
  },
  {
    country_code: "CH",
    zipcode: "2000",
    place: "Neuchâtel",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Neuchâtel",
    community_code: "6458",
    latitude: "46.9918",
    longitude: "6.931",
  },
  {
    country_code: "CH",
    zipcode: "2001",
    place: "Neuchâtel 1",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Neuchâtel",
    community_code: "6458",
    latitude: "47.0094",
    longitude: "6.9381",
  },
  {
    country_code: "CH",
    zipcode: "2002",
    place: "Neuchâtel 2",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Neuchâtel",
    community_code: "6458",
    latitude: "47.0094",
    longitude: "6.9381",
  },
  {
    country_code: "CH",
    zipcode: "2010",
    place: "Neuchâtel OFS",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Neuchâtel",
    community_code: "6458",
    latitude: "47.0094",
    longitude: "6.9381",
  },
  {
    country_code: "CH",
    zipcode: "2067",
    place: "Chaumont",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Neuchâtel",
    community_code: "6458",
    latitude: "47.0282",
    longitude: "6.9562",
  },
  {
    country_code: "CH",
    zipcode: "2068",
    place: "Hauterive NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Hauterive (NE)",
    community_code: "6454",
    latitude: "47.0123",
    longitude: "6.9761",
  },
  {
    country_code: "CH",
    zipcode: "2072",
    place: "St-Blaise",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Saint-Blaise",
    community_code: "6459",
    latitude: "47.0151",
    longitude: "6.9883",
  },
  {
    country_code: "CH",
    zipcode: "2073",
    place: "Enges",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Enges",
    community_code: "6453",
    latitude: "47.0667",
    longitude: "7.0166",
  },
  {
    country_code: "CH",
    zipcode: "2074",
    place: "Marin-Epagnier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "La Tène",
    community_code: "6461",
    latitude: "47.0102",
    longitude: "6.9994",
  },
  {
    country_code: "CH",
    zipcode: "2075",
    place: "Wavre",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "La Tène",
    community_code: "6461",
    latitude: "47.0215",
    longitude: "7.0292",
  },
  {
    country_code: "CH",
    zipcode: "2075",
    place: "Thielle",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "La Tène",
    community_code: "6461",
    latitude: "47.0215",
    longitude: "7.0292",
  },
  {
    country_code: "CH",
    zipcode: "2087",
    place: "Cornaux NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Cornaux",
    community_code: "6451",
    latitude: "47.0396",
    longitude: "7.0187",
  },
  {
    country_code: "CH",
    zipcode: "2088",
    place: "Cressier NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Cressier (NE)",
    community_code: "6452",
    latitude: "47.0491",
    longitude: "7.0346",
  },
  {
    country_code: "CH",
    zipcode: "2523",
    place: "Lignières",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Lignières",
    community_code: "6456",
    latitude: "47.0833",
    longitude: "7.0659",
  },
  {
    country_code: "CH",
    zipcode: "2525",
    place: "Le Landeron",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Neuchâtel District",
    province_code: "2404",
    community: "Le Landeron",
    community_code: "6455",
    latitude: "47.057",
    longitude: "7.0705",
  },
  {
    country_code: "CH",
    zipcode: "2037",
    place: "Montmollin",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "46.9928",
    longitude: "6.8465",
  },
  {
    country_code: "CH",
    zipcode: "2042",
    place: "Valangin",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Valangin",
    community_code: "6485",
    latitude: "47.0161",
    longitude: "6.9067",
  },
  {
    country_code: "CH",
    zipcode: "2043",
    place: "Boudevilliers",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0272",
    longitude: "6.8891",
  },
  {
    country_code: "CH",
    zipcode: "2046",
    place: "Fontaines NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0436",
    longitude: "6.9035",
  },
  {
    country_code: "CH",
    zipcode: "2052",
    place: "Fontainemelon",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0549",
    longitude: "6.8868",
  },
  {
    country_code: "CH",
    zipcode: "2052",
    place: "La Vue-des-Alpes",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0736",
    longitude: "6.8713",
  },
  {
    country_code: "CH",
    zipcode: "2053",
    place: "Cernier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0588",
    longitude: "6.9004",
  },
  {
    country_code: "CH",
    zipcode: "2054",
    place: "Chézard-St-Martin",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0721",
    longitude: "6.9293",
  },
  {
    country_code: "CH",
    zipcode: "2054",
    place: "Les Vieux-Prés",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0721",
    longitude: "6.9293",
  },
  {
    country_code: "CH",
    zipcode: "2056",
    place: "Dombresson",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0719",
    longitude: "6.9592",
  },
  {
    country_code: "CH",
    zipcode: "2057",
    place: "Villiers",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0751",
    longitude: "6.9715",
  },
  {
    country_code: "CH",
    zipcode: "2058",
    place: "Le Pâquier NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0984",
    longitude: "6.9864",
  },
  {
    country_code: "CH",
    zipcode: "2063",
    place: "Vilars NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.033",
    longitude: "6.9278",
  },
  {
    country_code: "CH",
    zipcode: "2063",
    place: "Fenin",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0267",
    longitude: "6.923",
  },
  {
    country_code: "CH",
    zipcode: "2063",
    place: "Engollon",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0391",
    longitude: "6.92",
  },
  {
    country_code: "CH",
    zipcode: "2063",
    place: "Saules",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0397",
    longitude: "6.9407",
  },
  {
    country_code: "CH",
    zipcode: "2065",
    place: "Savagnier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.05",
    longitude: "6.95",
  },
  {
    country_code: "CH",
    zipcode: "2206",
    place: "Les Geneveys-sur-Coffrane",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0153",
    longitude: "6.8513",
  },
  {
    country_code: "CH",
    zipcode: "2207",
    place: "Coffrane",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0101",
    longitude: "6.8632",
  },
  {
    country_code: "CH",
    zipcode: "2208",
    place: "Les Hauts-Geneveys",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Ruz District",
    province_code: "2405",
    community: "Val-de-Ruz",
    community_code: "6487",
    latitude: "47.0498",
    longitude: "6.873",
  },
  {
    country_code: "CH",
    zipcode: "2103",
    place: "Noiraigue",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9562",
    longitude: "6.7246",
  },
  {
    country_code: "CH",
    zipcode: "2105",
    place: "Travers",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9402",
    longitude: "6.676",
  },
  {
    country_code: "CH",
    zipcode: "2108",
    place: "Couvet",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9253",
    longitude: "6.6327",
  },
  {
    country_code: "CH",
    zipcode: "2112",
    place: "Môtiers NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9111",
    longitude: "6.6111",
  },
  {
    country_code: "CH",
    zipcode: "2113",
    place: "Boveresse",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9182",
    longitude: "6.6006",
  },
  {
    country_code: "CH",
    zipcode: "2114",
    place: "Fleurier",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9022",
    longitude: "6.5825",
  },
  {
    country_code: "CH",
    zipcode: "2115",
    place: "Buttes",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.8881",
    longitude: "6.5514",
  },
  {
    country_code: "CH",
    zipcode: "2116",
    place: "Mont-de-Buttes",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.8779",
    longitude: "6.5086",
  },
  {
    country_code: "CH",
    zipcode: "2117",
    place: "La Côte-aux-Fées",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "La Côte-aux-Fées",
    community_code: "6504",
    latitude: "46.8674",
    longitude: "6.4904",
  },
  {
    country_code: "CH",
    zipcode: "2123",
    place: "St-Sulpice NE",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9111",
    longitude: "6.5622",
  },
  {
    country_code: "CH",
    zipcode: "2124",
    place: "Les Sagnettes",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9404",
    longitude: "6.5941",
  },
  {
    country_code: "CH",
    zipcode: "2126",
    place: "Les Verrières",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Les Verrières",
    community_code: "6511",
    latitude: "46.9041",
    longitude: "6.4804",
  },
  {
    country_code: "CH",
    zipcode: "2127",
    place: "Les Bayards",
    state: "Neuchâtel",
    state_code: "NE",
    province: "Val-de-Travers District",
    province_code: "2406",
    community: "Val-de-Travers",
    community_code: "6512",
    latitude: "46.9159",
    longitude: "6.5124",
  },
  {
    country_code: "CH",
    zipcode: "6052",
    place: "Hergiswil NW",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Hergiswil (NW)",
    community_code: "1507",
    latitude: "46.9843",
    longitude: "8.3094",
  },
  {
    country_code: "CH",
    zipcode: "6362",
    place: "Stansstad",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stansstad",
    community_code: "1510",
    latitude: "46.9768",
    longitude: "8.3355",
  },
  {
    country_code: "CH",
    zipcode: "6363",
    place: "Obbürgen",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stansstad",
    community_code: "1510",
    latitude: "46.9831",
    longitude: "8.3631",
  },
  {
    country_code: "CH",
    zipcode: "6363",
    place: "Fürigen",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stansstad",
    community_code: "1510",
    latitude: "46.9831",
    longitude: "8.3631",
  },
  {
    country_code: "CH",
    zipcode: "6363",
    place: "Bürgenstock",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Ennetbürgen",
    community_code: "1505",
    latitude: "46.9946",
    longitude: "8.4283",
  },
  {
    country_code: "CH",
    zipcode: "6365",
    place: "Kehrsiten",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stansstad",
    community_code: "1510",
    latitude: "47.0003",
    longitude: "8.3673",
  },
  {
    country_code: "CH",
    zipcode: "6370",
    place: "Stans",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stans",
    community_code: "1509",
    latitude: "46.9581",
    longitude: "8.3661",
  },
  {
    country_code: "CH",
    zipcode: "6370",
    place: "Oberdorf NW",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Oberdorf (NW)",
    community_code: "1508",
    latitude: "46.9424",
    longitude: "8.4059",
  },
  {
    country_code: "CH",
    zipcode: "6371",
    place: "Stans",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Stans",
    community_code: "1509",
    latitude: "46.9589",
    longitude: "8.3633",
  },
  {
    country_code: "CH",
    zipcode: "6372",
    place: "Ennetmoos",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Ennetmoos",
    community_code: "1506",
    latitude: "46.9559",
    longitude: "8.3388",
  },
  {
    country_code: "CH",
    zipcode: "6373",
    place: "Ennetbürgen",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Ennetbürgen",
    community_code: "1505",
    latitude: "46.9842",
    longitude: "8.41",
  },
  {
    country_code: "CH",
    zipcode: "6374",
    place: "Buochs",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Buochs",
    community_code: "1502",
    latitude: "46.974",
    longitude: "8.4228",
  },
  {
    country_code: "CH",
    zipcode: "6375",
    place: "Beckenried",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Beckenried",
    community_code: "1501",
    latitude: "46.9665",
    longitude: "8.4757",
  },
  {
    country_code: "CH",
    zipcode: "6376",
    place: "Emmetten",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Emmetten",
    community_code: "1504",
    latitude: "46.9566",
    longitude: "8.5147",
  },
  {
    country_code: "CH",
    zipcode: "6382",
    place: "Büren NW",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Oberdorf (NW)",
    community_code: "1508",
    latitude: "46.9408",
    longitude: "8.3976",
  },
  {
    country_code: "CH",
    zipcode: "6383",
    place: "Niederrickenbach",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Oberdorf (NW)",
    community_code: "1508",
    latitude: "46.9273",
    longitude: "8.4271",
  },
  {
    country_code: "CH",
    zipcode: "6383",
    place: "Wiesenberg",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Dallenwil",
    community_code: "1503",
    latitude: "46.9271",
    longitude: "8.3652",
  },
  {
    country_code: "CH",
    zipcode: "6383",
    place: "Wirzweli",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Dallenwil",
    community_code: "1503",
    latitude: "46.9149",
    longitude: "8.3645",
  },
  {
    country_code: "CH",
    zipcode: "6383",
    place: "Dallenwil",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Dallenwil",
    community_code: "1503",
    latitude: "46.9242",
    longitude: "8.3879",
  },
  {
    country_code: "CH",
    zipcode: "6386",
    place: "Wolfenschiessen",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Wolfenschiessen",
    community_code: "1511",
    latitude: "46.9032",
    longitude: "8.3942",
  },
  {
    country_code: "CH",
    zipcode: "6387",
    place: "Oberrickenbach",
    state: "Kanton Nidwalden",
    state_code: "NW",
    province: "Nidwalden",
    province_code: "700",
    community: "Wolfenschiessen",
    community_code: "1511",
    latitude: "46.8877",
    longitude: "8.4173",
  },
  {
    country_code: "CH",
    zipcode: "6010",
    place: "Kriens",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Alpnach",
    community_code: "1401",
    latitude: "46.8523",
    longitude: "8.2432",
  },
  {
    country_code: "CH",
    zipcode: "6053",
    place: "Alpnachstad",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Alpnach",
    community_code: "1401",
    latitude: "46.9544",
    longitude: "8.2764",
  },
  {
    country_code: "CH",
    zipcode: "6055",
    place: "Alpnach Dorf",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Alpnach",
    community_code: "1401",
    latitude: "46.9404",
    longitude: "8.2772",
  },
  {
    country_code: "CH",
    zipcode: "6056",
    place: "Kägiswil",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.9208",
    longitude: "8.2621",
  },
  {
    country_code: "CH",
    zipcode: "6060",
    place: "Sarnen",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.8961",
    longitude: "8.2453",
  },
  {
    country_code: "CH",
    zipcode: "6060",
    place: "Ramersberg",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.8993",
    longitude: "8.2335",
  },
  {
    country_code: "CH",
    zipcode: "6061",
    place: "Sarnen 1",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.8985",
    longitude: "8.1765",
  },
  {
    country_code: "CH",
    zipcode: "6062",
    place: "Wilen (Sarnen)",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.8799",
    longitude: "8.2225",
  },
  {
    country_code: "CH",
    zipcode: "6063",
    place: "Stalden (Sarnen)",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sarnen",
    community_code: "1407",
    latitude: "46.8871",
    longitude: "8.2125",
  },
  {
    country_code: "CH",
    zipcode: "6064",
    place: "Kerns",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Kerns",
    community_code: "1404",
    latitude: "46.9012",
    longitude: "8.2751",
  },
  {
    country_code: "CH",
    zipcode: "6066",
    place: "St. Niklausen OW",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Kerns",
    community_code: "1404",
    latitude: "46.8756",
    longitude: "8.2818",
  },
  {
    country_code: "CH",
    zipcode: "6067",
    place: "Melchtal",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Kerns",
    community_code: "1404",
    latitude: "46.8344",
    longitude: "8.2899",
  },
  {
    country_code: "CH",
    zipcode: "6068",
    place: "Melchsee-Frutt",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Kerns",
    community_code: "1404",
    latitude: "46.7746",
    longitude: "8.2685",
  },
  {
    country_code: "CH",
    zipcode: "6072",
    place: "Sachseln",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sachseln",
    community_code: "1406",
    latitude: "46.8672",
    longitude: "8.2334",
  },
  {
    country_code: "CH",
    zipcode: "6073",
    place: "Flüeli-Ranft",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Sachseln",
    community_code: "1406",
    latitude: "46.871",
    longitude: "8.2673",
  },
  {
    country_code: "CH",
    zipcode: "6074",
    place: "Giswil",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Giswil",
    community_code: "1403",
    latitude: "46.8333",
    longitude: "8.1806",
  },
  {
    country_code: "CH",
    zipcode: "6078",
    place: "Bürglen OW",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Lungern",
    community_code: "1405",
    latitude: "46.8103",
    longitude: "8.1626",
  },
  {
    country_code: "CH",
    zipcode: "6078",
    place: "Lungern",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Lungern",
    community_code: "1405",
    latitude: "46.7858",
    longitude: "8.1598",
  },
  {
    country_code: "CH",
    zipcode: "6388",
    place: "Grafenort",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Engelberg",
    community_code: "1402",
    latitude: "46.8702",
    longitude: "8.3744",
  },
  {
    country_code: "CH",
    zipcode: "6390",
    place: "Engelberg",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Engelberg",
    community_code: "1402",
    latitude: "46.8211",
    longitude: "8.4013",
  },
  {
    country_code: "CH",
    zipcode: "6391",
    place: "Engelberg",
    state: "Kanton Obwalden",
    state_code: "OW",
    province: "Obwalden",
    province_code: "600",
    community: "Engelberg",
    community_code: "1402",
    latitude: "46.8197",
    longitude: "8.4341",
  },
  {
    country_code: "CH",
    zipcode: "9000",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9001",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9004",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9006",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9007",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9008",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9010",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9011",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9012",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9013",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9014",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9015",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9016",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4239",
    longitude: "9.3748",
  },
  {
    country_code: "CH",
    zipcode: "9020",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9022",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9023",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9024",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9026",
    place: "St. Gallen K AG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9027",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9028",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9029",
    place: "St. Gallen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "St. Gallen",
    community_code: "3203",
    latitude: "47.4221",
    longitude: "9.3755",
  },
  {
    country_code: "CH",
    zipcode: "9030",
    place: "St. Josefen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gaiserwald",
    community_code: "3442",
    latitude: "47.426",
    longitude: "9.337",
  },
  {
    country_code: "CH",
    zipcode: "9030",
    place: "Abtwil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gaiserwald",
    community_code: "3442",
    latitude: "47.4237",
    longitude: "9.3211",
  },
  {
    country_code: "CH",
    zipcode: "9032",
    place: "Engelburg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gaiserwald",
    community_code: "3442",
    latitude: "47.4455",
    longitude: "9.342",
  },
  {
    country_code: "CH",
    zipcode: "9034",
    place: "Eggersriet",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Eggersriet",
    community_code: "3212",
    latitude: "47.442",
    longitude: "9.469",
  },
  {
    country_code: "CH",
    zipcode: "9036",
    place: "Grub SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Eggersriet",
    community_code: "3212",
    latitude: "47.4538",
    longitude: "9.5136",
  },
  {
    country_code: "CH",
    zipcode: "9200",
    place: "Gossau SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gossau (SG)",
    community_code: "3443",
    latitude: "47.4155",
    longitude: "9.2548",
  },
  {
    country_code: "CH",
    zipcode: "9201",
    place: "Gossau SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gossau (SG)",
    community_code: "3443",
    latitude: "47.421",
    longitude: "9.2536",
  },
  {
    country_code: "CH",
    zipcode: "9204",
    place: "Andwil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Andwil (SG)",
    community_code: "3441",
    latitude: "47.4386",
    longitude: "9.2744",
  },
  {
    country_code: "CH",
    zipcode: "9205",
    place: "Waldkirch",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Waldkirch",
    community_code: "3444",
    latitude: "47.4686",
    longitude: "9.2867",
  },
  {
    country_code: "CH",
    zipcode: "9212",
    place: "Arnegg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Gossau (SG)",
    community_code: "3443",
    latitude: "47.443",
    longitude: "9.2552",
  },
  {
    country_code: "CH",
    zipcode: "9300",
    place: "Wittenbach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Wittenbach",
    community_code: "3204",
    latitude: "47.4611",
    longitude: "9.386",
  },
  {
    country_code: "CH",
    zipcode: "9301",
    place: "Wittenbach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Wittenbach",
    community_code: "3204",
    latitude: "47.4667",
    longitude: "9.3795",
  },
  {
    country_code: "CH",
    zipcode: "9304",
    place: "Bernhardzell",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Waldkirch",
    community_code: "3444",
    latitude: "47.4747",
    longitude: "9.3363",
  },
  {
    country_code: "CH",
    zipcode: "9308",
    place: "Lömmenschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Häggenschwil",
    community_code: "3201",
    latitude: "47.4979",
    longitude: "9.3551",
  },
  {
    country_code: "CH",
    zipcode: "9312",
    place: "Häggenschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Häggenschwil",
    community_code: "3201",
    latitude: "47.4946",
    longitude: "9.3449",
  },
  {
    country_code: "CH",
    zipcode: "9313",
    place: "Muolen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis St. Gallen",
    province_code: "1721",
    community: "Muolen",
    community_code: "3202",
    latitude: "47.521",
    longitude: "9.3248",
  },
  {
    country_code: "CH",
    zipcode: "9033",
    place: "Untereggen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Untereggen",
    community_code: "3219",
    latitude: "47.454",
    longitude: "9.451",
  },
  {
    country_code: "CH",
    zipcode: "9305",
    place: "Berg SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Berg (SG)",
    community_code: "3211",
    latitude: "47.4867",
    longitude: "9.4099",
  },
  {
    country_code: "CH",
    zipcode: "9323",
    place: "Steinach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Steinach",
    community_code: "3217",
    latitude: "47.5018",
    longitude: "9.4385",
  },
  {
    country_code: "CH",
    zipcode: "9327",
    place: "Tübach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Tübach",
    community_code: "3218",
    latitude: "47.4868",
    longitude: "9.4546",
  },
  {
    country_code: "CH",
    zipcode: "9400",
    place: "Rorschach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Rorschach",
    community_code: "3215",
    latitude: "47.478",
    longitude: "9.4903",
  },
  {
    country_code: "CH",
    zipcode: "9401",
    place: "Rorschach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Rorschach",
    community_code: "3215",
    latitude: "47.4757",
    longitude: "9.4949",
  },
  {
    country_code: "CH",
    zipcode: "9402",
    place: "Mörschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Mörschwil",
    community_code: "3214",
    latitude: "47.471",
    longitude: "9.4228",
  },
  {
    country_code: "CH",
    zipcode: "9403",
    place: "Goldach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Goldach",
    community_code: "3213",
    latitude: "47.474",
    longitude: "9.4671",
  },
  {
    country_code: "CH",
    zipcode: "9404",
    place: "Rorschacherberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Rorschacherberg",
    community_code: "3216",
    latitude: "47.4731",
    longitude: "9.5112",
  },
  {
    country_code: "CH",
    zipcode: "9422",
    place: "Staad SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Thal",
    community_code: "3237",
    latitude: "47.4806",
    longitude: "9.5325",
  },
  {
    country_code: "CH",
    zipcode: "9423",
    place: "Altenrhein",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Thal",
    community_code: "3237",
    latitude: "47.4898",
    longitude: "9.5519",
  },
  {
    country_code: "CH",
    zipcode: "9425",
    place: "Thal",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rorschach",
    province_code: "1722",
    community: "Thal",
    community_code: "3237",
    latitude: "47.4668",
    longitude: "9.5664",
  },
  {
    country_code: "CH",
    zipcode: "9411",
    place: "Reute AR",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Berneck",
    community_code: "3233",
    latitude: "47.4246",
    longitude: "9.6125",
  },
  {
    country_code: "CH",
    zipcode: "9424",
    place: "Rheineck",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Rheineck",
    community_code: "3235",
    latitude: "47.4663",
    longitude: "9.5903",
  },
  {
    country_code: "CH",
    zipcode: "9430",
    place: "St. Margrethen SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "St. Margrethen",
    community_code: "3236",
    latitude: "47.4525",
    longitude: "9.6374",
  },
  {
    country_code: "CH",
    zipcode: "9434",
    place: "Au SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Au (SG)",
    community_code: "3231",
    latitude: "47.4309",
    longitude: "9.6345",
  },
  {
    country_code: "CH",
    zipcode: "9435",
    place: "Heerbrugg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Au (SG)",
    community_code: "3231",
    latitude: "47.4149",
    longitude: "9.6268",
  },
  {
    country_code: "CH",
    zipcode: "9436",
    place: "Balgach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Balgach",
    community_code: "3232",
    latitude: "47.4056",
    longitude: "9.607",
  },
  {
    country_code: "CH",
    zipcode: "9437",
    place: "Marbach SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Marbach (SG)",
    community_code: "3253",
    latitude: "47.3917",
    longitude: "9.5672",
  },
  {
    country_code: "CH",
    zipcode: "9442",
    place: "Berneck",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Berneck",
    community_code: "3233",
    latitude: "47.4255",
    longitude: "9.6162",
  },
  {
    country_code: "CH",
    zipcode: "9443",
    place: "Widnau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Widnau",
    community_code: "3238",
    latitude: "47.4083",
    longitude: "9.6332",
  },
  {
    country_code: "CH",
    zipcode: "9444",
    place: "Diepoldsau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Diepoldsau",
    community_code: "3234",
    latitude: "47.386",
    longitude: "9.6556",
  },
  {
    country_code: "CH",
    zipcode: "9445",
    place: "Rebstein",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Rebstein",
    community_code: "3255",
    latitude: "47.3981",
    longitude: "9.585",
  },
  {
    country_code: "CH",
    zipcode: "9450",
    place: "Altstätten SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Altstätten",
    community_code: "3251",
    latitude: "47.3777",
    longitude: "9.5475",
  },
  {
    country_code: "CH",
    zipcode: "9450",
    place: "Lüchingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Altstätten",
    community_code: "3251",
    latitude: "47.3885",
    longitude: "9.556",
  },
  {
    country_code: "CH",
    zipcode: "9451",
    place: "Kriessern",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Oberriet (SG)",
    community_code: "3254",
    latitude: "47.3688",
    longitude: "9.6113",
  },
  {
    country_code: "CH",
    zipcode: "9452",
    place: "Hinterforst",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Altstätten",
    community_code: "3251",
    latitude: "47.36",
    longitude: "9.5307",
  },
  {
    country_code: "CH",
    zipcode: "9453",
    place: "Eichberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Eichberg",
    community_code: "3252",
    latitude: "47.3437",
    longitude: "9.5314",
  },
  {
    country_code: "CH",
    zipcode: "9462",
    place: "Montlingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Oberriet (SG)",
    community_code: "3254",
    latitude: "47.3359",
    longitude: "9.5906",
  },
  {
    country_code: "CH",
    zipcode: "9462",
    place: "Montlingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Oberriet (SG)",
    community_code: "3254",
    latitude: "47.3359",
    longitude: "9.5906",
  },
  {
    country_code: "CH",
    zipcode: "9463",
    place: "Oberriet SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Oberriet (SG)",
    community_code: "3254",
    latitude: "47.3209",
    longitude: "9.5681",
  },
  {
    country_code: "CH",
    zipcode: "9464",
    place: "Lienz",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Altstätten",
    community_code: "3251",
    latitude: "47.2773",
    longitude: "9.5163",
  },
  {
    country_code: "CH",
    zipcode: "9464",
    place: "Rüthi (Rheintal)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Rheintal",
    province_code: "1723",
    community: "Rüthi (SG)",
    community_code: "3256",
    latitude: "47.2948",
    longitude: "9.5386",
  },
  {
    country_code: "CH",
    zipcode: "9465",
    place: "Salez",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sennwald",
    community_code: "3274",
    latitude: "47.2365",
    longitude: "9.5",
  },
  {
    country_code: "CH",
    zipcode: "9466",
    place: "Sennwald",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sennwald",
    community_code: "3274",
    latitude: "47.2606",
    longitude: "9.5027",
  },
  {
    country_code: "CH",
    zipcode: "9467",
    place: "Frümsen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sennwald",
    community_code: "3274",
    latitude: "47.2438",
    longitude: "9.4679",
  },
  {
    country_code: "CH",
    zipcode: "9468",
    place: "Sax",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sennwald",
    community_code: "3274",
    latitude: "47.2329",
    longitude: "9.4577",
  },
  {
    country_code: "CH",
    zipcode: "9469",
    place: "Haag (Rheintal)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sennwald",
    community_code: "3274",
    latitude: "47.2099",
    longitude: "9.4893",
  },
  {
    country_code: "CH",
    zipcode: "9470",
    place: "Werdenberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Grabs",
    community_code: "3273",
    latitude: "47.1698",
    longitude: "9.4549",
  },
  {
    country_code: "CH",
    zipcode: "9470",
    place: "Buchs SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Buchs (SG)",
    community_code: "3271",
    latitude: "47.1674",
    longitude: "9.4779",
  },
  {
    country_code: "CH",
    zipcode: "9471",
    place: "Buchs SG 1",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Buchs (SG)",
    community_code: "3271",
    latitude: "47.166",
    longitude: "9.4627",
  },
  {
    country_code: "CH",
    zipcode: "9471",
    place: "Buchs SG 3",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Buchs (SG)",
    community_code: "3271",
    latitude: "47.166",
    longitude: "9.4627",
  },
  {
    country_code: "CH",
    zipcode: "9472",
    place: "Grabserberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Grabs",
    community_code: "3273",
    latitude: "47.1816",
    longitude: "9.4252",
  },
  {
    country_code: "CH",
    zipcode: "9472",
    place: "Grabs",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Grabs",
    community_code: "3273",
    latitude: "47.1825",
    longitude: "9.444",
  },
  {
    country_code: "CH",
    zipcode: "9473",
    place: "Gams",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Gams",
    community_code: "3272",
    latitude: "47.2043",
    longitude: "9.4417",
  },
  {
    country_code: "CH",
    zipcode: "9475",
    place: "Sevelen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Sevelen",
    community_code: "3275",
    latitude: "47.1221",
    longitude: "9.486",
  },
  {
    country_code: "CH",
    zipcode: "9476",
    place: "Weite",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0938",
    longitude: "9.4983",
  },
  {
    country_code: "CH",
    zipcode: "9476",
    place: "Fontnas",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0906",
    longitude: "9.4886",
  },
  {
    country_code: "CH",
    zipcode: "9477",
    place: "Trübbach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0734",
    longitude: "9.4783",
  },
  {
    country_code: "CH",
    zipcode: "9478",
    place: "Azmoos",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0823",
    longitude: "9.4768",
  },
  {
    country_code: "CH",
    zipcode: "9479",
    place: "Gretschins",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.099",
    longitude: "9.4868",
  },
  {
    country_code: "CH",
    zipcode: "9479",
    place: "Malans SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0919",
    longitude: "9.4778",
  },
  {
    country_code: "CH",
    zipcode: "9479",
    place: "Oberschan",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Werdenberg",
    province_code: "1724",
    community: "Wartau",
    community_code: "3276",
    latitude: "47.0989",
    longitude: "9.4746",
  },
  {
    country_code: "CH",
    zipcode: "7310",
    place: "Bad Ragaz",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.933",
    longitude: "9.4068",
  },
  {
    country_code: "CH",
    zipcode: "7310",
    place: "Bad Ragaz",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Bad Ragaz",
    community_code: "3291",
    latitude: "47.006",
    longitude: "9.5027",
  },
  {
    country_code: "CH",
    zipcode: "7312",
    place: "Pfäfers",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9899",
    longitude: "9.5008",
  },
  {
    country_code: "CH",
    zipcode: "7313",
    place: "St. Margrethenberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9769",
    longitude: "9.5127",
  },
  {
    country_code: "CH",
    zipcode: "7314",
    place: "Vadura",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9562",
    longitude: "9.4851",
  },
  {
    country_code: "CH",
    zipcode: "7315",
    place: "Vättis",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9097",
    longitude: "9.4427",
  },
  {
    country_code: "CH",
    zipcode: "7317",
    place: "Vasön",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9528",
    longitude: "9.4773",
  },
  {
    country_code: "CH",
    zipcode: "7317",
    place: "Valens",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Pfäfers",
    community_code: "3294",
    latitude: "46.9666",
    longitude: "9.477",
  },
  {
    country_code: "CH",
    zipcode: "7320",
    place: "Sargans",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Sargans",
    community_code: "3296",
    latitude: "47.049",
    longitude: "9.441",
  },
  {
    country_code: "CH",
    zipcode: "7323",
    place: "Wangs",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Vilters-Wangs",
    community_code: "3297",
    latitude: "47.0326",
    longitude: "9.4329",
  },
  {
    country_code: "CH",
    zipcode: "7324",
    place: "Vilters",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Vilters-Wangs",
    community_code: "3297",
    latitude: "47.0261",
    longitude: "9.4532",
  },
  {
    country_code: "CH",
    zipcode: "7325",
    place: "Schwendi im Weisstannental",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.0078",
    longitude: "9.3652",
  },
  {
    country_code: "CH",
    zipcode: "7326",
    place: "Weisstannen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "46.9911",
    longitude: "9.3426",
  },
  {
    country_code: "CH",
    zipcode: "8877",
    place: "Murg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.113",
    longitude: "9.2155",
  },
  {
    country_code: "CH",
    zipcode: "8878",
    place: "Quinten",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.129",
    longitude: "9.2144",
  },
  {
    country_code: "CH",
    zipcode: "8879",
    place: "Pizolpark (Mels)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.0008",
    longitude: "9.3374",
  },
  {
    country_code: "CH",
    zipcode: "8880",
    place: "Walenstadt",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Walenstadt",
    community_code: "3298",
    latitude: "47.1241",
    longitude: "9.3119",
  },
  {
    country_code: "CH",
    zipcode: "8881",
    place: "Walenstadtberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Walenstadt",
    community_code: "3298",
    latitude: "47.1374",
    longitude: "9.2903",
  },
  {
    country_code: "CH",
    zipcode: "8881",
    place: "Tscherlach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Walenstadt",
    community_code: "3298",
    latitude: "47.1202",
    longitude: "9.3345",
  },
  {
    country_code: "CH",
    zipcode: "8882",
    place: "Unterterzen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.1128",
    longitude: "9.2531",
  },
  {
    country_code: "CH",
    zipcode: "8883",
    place: "Quarten",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.107",
    longitude: "9.242",
  },
  {
    country_code: "CH",
    zipcode: "8884",
    place: "Oberterzen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.1023",
    longitude: "9.2594",
  },
  {
    country_code: "CH",
    zipcode: "8885",
    place: "Mols",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Quarten",
    community_code: "3295",
    latitude: "47.112",
    longitude: "9.2782",
  },
  {
    country_code: "CH",
    zipcode: "8886",
    place: "Mädris-Vermol",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.05",
    longitude: "9.3944",
  },
  {
    country_code: "CH",
    zipcode: "8887",
    place: "Mels",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.0456",
    longitude: "9.4232",
  },
  {
    country_code: "CH",
    zipcode: "8888",
    place: "Heiligkreuz (Mels)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.0591",
    longitude: "9.4127",
  },
  {
    country_code: "CH",
    zipcode: "8889",
    place: "Plons",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Mels",
    community_code: "3293",
    latitude: "47.0575",
    longitude: "9.4008",
  },
  {
    country_code: "CH",
    zipcode: "8890",
    place: "Flums",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0906",
    longitude: "9.343",
  },
  {
    country_code: "CH",
    zipcode: "8892",
    place: "Berschis",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Walenstadt",
    community_code: "3298",
    latitude: "47.1068",
    longitude: "9.3464",
  },
  {
    country_code: "CH",
    zipcode: "8893",
    place: "Flums Hochwiese",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0839",
    longitude: "9.3722",
  },
  {
    country_code: "CH",
    zipcode: "8894",
    place: "Flumserberg Saxli",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0776",
    longitude: "9.3464",
  },
  {
    country_code: "CH",
    zipcode: "8895",
    place: "Flumserberg Portels",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.084",
    longitude: "9.3372",
  },
  {
    country_code: "CH",
    zipcode: "8896",
    place: "Flumserberg Bergheim",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0981",
    longitude: "9.3078",
  },
  {
    country_code: "CH",
    zipcode: "8897",
    place: "Flumserberg Tannenheim",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0891",
    longitude: "9.3034",
  },
  {
    country_code: "CH",
    zipcode: "8898",
    place: "Flumserberg Tannenbodenalp",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Sarganserland",
    province_code: "1725",
    community: "Flums",
    community_code: "3292",
    latitude: "47.0932",
    longitude: "9.2816",
  },
  {
    country_code: "CH",
    zipcode: "8638",
    place: "Goldingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2648",
    longitude: "8.9617",
  },
  {
    country_code: "CH",
    zipcode: "8640",
    place: "Rapperswil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Rapperswil-Jona",
    community_code: "3340",
    latitude: "47.2256",
    longitude: "8.8223",
  },
  {
    country_code: "CH",
    zipcode: "8645",
    place: "Jona",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Rapperswil-Jona",
    community_code: "3340",
    latitude: "47.2298",
    longitude: "8.8388",
  },
  {
    country_code: "CH",
    zipcode: "8646",
    place: "Wagen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Rapperswil-Jona",
    community_code: "3340",
    latitude: "47.2324",
    longitude: "8.8869",
  },
  {
    country_code: "CH",
    zipcode: "8715",
    place: "Bollingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Rapperswil-Jona",
    community_code: "3340",
    latitude: "47.2205",
    longitude: "8.8955",
  },
  {
    country_code: "CH",
    zipcode: "8716",
    place: "Schmerikon",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Schmerikon",
    community_code: "3338",
    latitude: "47.2254",
    longitude: "8.9484",
  },
  {
    country_code: "CH",
    zipcode: "8717",
    place: "Benken SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Benken (SG)",
    community_code: "3312",
    latitude: "47.1994",
    longitude: "9.0073",
  },
  {
    country_code: "CH",
    zipcode: "8718",
    place: "Schänis",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Schänis",
    community_code: "3315",
    latitude: "47.16",
    longitude: "9.0455",
  },
  {
    country_code: "CH",
    zipcode: "8722",
    place: "Kaltbrunn",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Kaltbrunn",
    community_code: "3313",
    latitude: "47.2137",
    longitude: "9.0259",
  },
  {
    country_code: "CH",
    zipcode: "8723",
    place: "Maseltrangen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Schänis",
    community_code: "3315",
    latitude: "47.1915",
    longitude: "9.0524",
  },
  {
    country_code: "CH",
    zipcode: "8723",
    place: "Rufi",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Schänis",
    community_code: "3315",
    latitude: "47.1801",
    longitude: "9.0497",
  },
  {
    country_code: "CH",
    zipcode: "8725",
    place: "Ernetschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2375",
    longitude: "9.0005",
  },
  {
    country_code: "CH",
    zipcode: "8725",
    place: "Gebertingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2537",
    longitude: "9.0055",
  },
  {
    country_code: "CH",
    zipcode: "8726",
    place: "Ricken SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2631",
    longitude: "9.044",
  },
  {
    country_code: "CH",
    zipcode: "8727",
    place: "Walde SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2718",
    longitude: "9.0122",
  },
  {
    country_code: "CH",
    zipcode: "8730",
    place: "Uznach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Uznach",
    community_code: "3339",
    latitude: "47.2242",
    longitude: "8.9826",
  },
  {
    country_code: "CH",
    zipcode: "8732",
    place: "Neuhaus SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2443",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "8733",
    place: "Eschenbach SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2398",
    longitude: "8.9216",
  },
  {
    country_code: "CH",
    zipcode: "8734",
    place: "Ermenswil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2461",
    longitude: "8.8862",
  },
  {
    country_code: "CH",
    zipcode: "8735",
    place: "St. Gallenkappel",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2437",
    longitude: "8.9644",
  },
  {
    country_code: "CH",
    zipcode: "8735",
    place: "Rüeterswil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Eschenbach (SG)",
    community_code: "3342",
    latitude: "47.2601",
    longitude: "8.9901",
  },
  {
    country_code: "CH",
    zipcode: "8737",
    place: "Gommiswald",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2313",
    longitude: "9.0236",
  },
  {
    country_code: "CH",
    zipcode: "8738",
    place: "Uetliburg SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2378",
    longitude: "9.0356",
  },
  {
    country_code: "CH",
    zipcode: "8739",
    place: "Rieden SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Gommiswald",
    community_code: "3341",
    latitude: "47.2228",
    longitude: "9.0516",
  },
  {
    country_code: "CH",
    zipcode: "8740",
    place: "Uznach Vögele AG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Uznach",
    community_code: "3339",
    latitude: "47.2265",
    longitude: "8.9813",
  },
  {
    country_code: "CH",
    zipcode: "8866",
    place: "Ziegelbrücke",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Schänis",
    community_code: "3315",
    latitude: "47.1324",
    longitude: "9.0603",
  },
  {
    country_code: "CH",
    zipcode: "8872",
    place: "Weesen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Weesen",
    community_code: "3316",
    latitude: "47.1345",
    longitude: "9.0964",
  },
  {
    country_code: "CH",
    zipcode: "8873",
    place: "Amden",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis See-Gaster",
    province_code: "1726",
    community: "Amden",
    community_code: "3311",
    latitude: "47.1489",
    longitude: "9.1423",
  },
  {
    country_code: "CH",
    zipcode: "9114",
    place: "Hoffeld",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3643",
    longitude: "9.1747",
  },
  {
    country_code: "CH",
    zipcode: "9115",
    place: "Dicken",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3379",
    longitude: "9.1917",
  },
  {
    country_code: "CH",
    zipcode: "9122",
    place: "Ebersol",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3489",
    longitude: "9.1517",
  },
  {
    country_code: "CH",
    zipcode: "9122",
    place: "Mogelsberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3622",
    longitude: "9.1354",
  },
  {
    country_code: "CH",
    zipcode: "9123",
    place: "Nassen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3744",
    longitude: "9.1373",
  },
  {
    country_code: "CH",
    zipcode: "9125",
    place: "Brunnadern",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3359",
    longitude: "9.1302",
  },
  {
    country_code: "CH",
    zipcode: "9126",
    place: "Necker",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Oberhelfenschwil",
    community_code: "3375",
    latitude: "47.3476",
    longitude: "9.1302",
  },
  {
    country_code: "CH",
    zipcode: "9127",
    place: "St. Peterzell",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Neckertal",
    community_code: "3378",
    latitude: "47.3178",
    longitude: "9.176",
  },
  {
    country_code: "CH",
    zipcode: "9500",
    place: "Wil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.2884",
    longitude: "9.1539",
  },
  {
    country_code: "CH",
    zipcode: "9533",
    place: "Kirchberg SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.4116",
    longitude: "9.0402",
  },
  {
    country_code: "CH",
    zipcode: "9533",
    place: "Dietschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.4229",
    longitude: "9.017",
  },
  {
    country_code: "CH",
    zipcode: "9534",
    place: "Gähwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.3987",
    longitude: "9.0026",
  },
  {
    country_code: "CH",
    zipcode: "9601",
    place: "Lütisburg Station",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Lütisburg",
    community_code: "3393",
    latitude: "47.3851",
    longitude: "9.0723",
  },
  {
    country_code: "CH",
    zipcode: "9602",
    place: "Müselbach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.3936",
    longitude: "9.0424",
  },
  {
    country_code: "CH",
    zipcode: "9602",
    place: "Bazenheid",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Kirchberg (SG)",
    community_code: "3392",
    latitude: "47.4097",
    longitude: "9.0706",
  },
  {
    country_code: "CH",
    zipcode: "9604",
    place: "Lütisburg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Lütisburg",
    community_code: "3393",
    latitude: "47.3945",
    longitude: "9.0831",
  },
  {
    country_code: "CH",
    zipcode: "9604",
    place: "Unterrindal",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Lütisburg",
    community_code: "3393",
    latitude: "47.4106",
    longitude: "9.0911",
  },
  {
    country_code: "CH",
    zipcode: "9606",
    place: "Bütschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Bütschwil-Ganterschwil",
    community_code: "3395",
    latitude: "47.3602",
    longitude: "9.0721",
  },
  {
    country_code: "CH",
    zipcode: "9607",
    place: "Mosnang",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Mosnang",
    community_code: "3394",
    latitude: "47.3625",
    longitude: "9.043",
  },
  {
    country_code: "CH",
    zipcode: "9608",
    place: "Ganterschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Bütschwil-Ganterschwil",
    community_code: "3395",
    latitude: "47.381",
    longitude: "9.0924",
  },
  {
    country_code: "CH",
    zipcode: "9612",
    place: "Dreien",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Mosnang",
    community_code: "3394",
    latitude: "47.3734",
    longitude: "9.0151",
  },
  {
    country_code: "CH",
    zipcode: "9613",
    place: "Mühlrüti",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Mosnang",
    community_code: "3394",
    latitude: "47.3716",
    longitude: "8.9866",
  },
  {
    country_code: "CH",
    zipcode: "9614",
    place: "Libingen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Mosnang",
    community_code: "3394",
    latitude: "47.33",
    longitude: "9.0243",
  },
  {
    country_code: "CH",
    zipcode: "9615",
    place: "Dietfurt",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Bütschwil-Ganterschwil",
    community_code: "3395",
    latitude: "47.3488",
    longitude: "9.0809",
  },
  {
    country_code: "CH",
    zipcode: "9620",
    place: "Lichtensteig",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Lichtensteig",
    community_code: "3374",
    latitude: "47.3238",
    longitude: "9.0876",
  },
  {
    country_code: "CH",
    zipcode: "9621",
    place: "Oberhelfenschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Oberhelfenschwil",
    community_code: "3375",
    latitude: "47.3567",
    longitude: "9.1108",
  },
  {
    country_code: "CH",
    zipcode: "9622",
    place: "Krinau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wattwil",
    community_code: "3379",
    latitude: "47.3173",
    longitude: "9.0505",
  },
  {
    country_code: "CH",
    zipcode: "9630",
    place: "Wattwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wattwil",
    community_code: "3379",
    latitude: "47.2996",
    longitude: "9.0866",
  },
  {
    country_code: "CH",
    zipcode: "9631",
    place: "Ulisbach",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wattwil",
    community_code: "3379",
    latitude: "47.2876",
    longitude: "9.1014",
  },
  {
    country_code: "CH",
    zipcode: "9633",
    place: "Bächli (Hemberg)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Hemberg",
    community_code: "3372",
    latitude: "47.3069",
    longitude: "9.1963",
  },
  {
    country_code: "CH",
    zipcode: "9633",
    place: "Hemberg",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Hemberg",
    community_code: "3372",
    latitude: "47.3006",
    longitude: "9.1752",
  },
  {
    country_code: "CH",
    zipcode: "9642",
    place: "Ebnat-Kappel",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Ebnat-Kappel",
    community_code: "3352",
    latitude: "47.262",
    longitude: "9.1247",
  },
  {
    country_code: "CH",
    zipcode: "9643",
    place: "Krummenau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Nesslau",
    community_code: "3360",
    latitude: "47.2476",
    longitude: "9.1706",
  },
  {
    country_code: "CH",
    zipcode: "9650",
    place: "Nesslau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Nesslau",
    community_code: "3360",
    latitude: "47.2228",
    longitude: "9.2012",
  },
  {
    country_code: "CH",
    zipcode: "9651",
    place: "Ennetbühl",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Nesslau",
    community_code: "3360",
    latitude: "47.242",
    longitude: "9.2126",
  },
  {
    country_code: "CH",
    zipcode: "9652",
    place: "Neu St. Johann",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Nesslau",
    community_code: "3360",
    latitude: "47.2306",
    longitude: "9.195",
  },
  {
    country_code: "CH",
    zipcode: "9655",
    place: "Stein SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Nesslau",
    community_code: "3360",
    latitude: "47.2",
    longitude: "9.2261",
  },
  {
    country_code: "CH",
    zipcode: "9656",
    place: "Alt St. Johann",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wildhaus-Alt St. Johann",
    community_code: "3359",
    latitude: "47.1931",
    longitude: "9.2813",
  },
  {
    country_code: "CH",
    zipcode: "9657",
    place: "Unterwasser",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wildhaus-Alt St. Johann",
    community_code: "3359",
    latitude: "47.197",
    longitude: "9.3086",
  },
  {
    country_code: "CH",
    zipcode: "9658",
    place: "Wildhaus",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Toggenburg",
    province_code: "1727",
    community: "Wildhaus-Alt St. Johann",
    community_code: "3359",
    latitude: "47.2058",
    longitude: "9.354",
  },
  {
    country_code: "CH",
    zipcode: "9113",
    place: "Degersheim",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Degersheim",
    community_code: "3401",
    latitude: "47.3743",
    longitude: "9.2002",
  },
  {
    country_code: "CH",
    zipcode: "9116",
    place: "Wolfertswil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Degersheim",
    community_code: "3401",
    latitude: "47.3957",
    longitude: "9.1839",
  },
  {
    country_code: "CH",
    zipcode: "9203",
    place: "Niederwil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberbüren",
    community_code: "3424",
    latitude: "47.4396",
    longitude: "9.1992",
  },
  {
    country_code: "CH",
    zipcode: "9230",
    place: "Flawil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Flawil",
    community_code: "3402",
    latitude: "47.413",
    longitude: "9.1832",
  },
  {
    country_code: "CH",
    zipcode: "9231",
    place: "Egg (Flawil)",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Flawil",
    community_code: "3402",
    latitude: "47.3931",
    longitude: "9.2276",
  },
  {
    country_code: "CH",
    zipcode: "9240",
    place: "Niederglatt SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberuzwil",
    community_code: "3407",
    latitude: "47.43",
    longitude: "9.1685",
  },
  {
    country_code: "CH",
    zipcode: "9240",
    place: "Uzwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4365",
    longitude: "9.1342",
  },
  {
    country_code: "CH",
    zipcode: "9242",
    place: "Oberuzwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberuzwil",
    community_code: "3407",
    latitude: "47.4308",
    longitude: "9.1272",
  },
  {
    country_code: "CH",
    zipcode: "9243",
    place: "Jonschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Jonschwil",
    community_code: "3405",
    latitude: "47.424",
    longitude: "9.0869",
  },
  {
    country_code: "CH",
    zipcode: "9244",
    place: "Niederuzwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4465",
    longitude: "9.1417",
  },
  {
    country_code: "CH",
    zipcode: "9245",
    place: "Sonnental",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberbüren",
    community_code: "3424",
    latitude: "47.4583",
    longitude: "9.1394",
  },
  {
    country_code: "CH",
    zipcode: "9245",
    place: "Oberbüren",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberbüren",
    community_code: "3424",
    latitude: "47.4519",
    longitude: "9.1586",
  },
  {
    country_code: "CH",
    zipcode: "9246",
    place: "Niederbüren",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Niederbüren",
    community_code: "3422",
    latitude: "47.4655",
    longitude: "9.2057",
  },
  {
    country_code: "CH",
    zipcode: "9247",
    place: "Henau",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4548",
    longitude: "9.1164",
  },
  {
    country_code: "CH",
    zipcode: "9248",
    place: "Bichwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Oberuzwil",
    community_code: "3407",
    latitude: "47.4218",
    longitude: "9.138",
  },
  {
    country_code: "CH",
    zipcode: "9249",
    place: "Niederstetten",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4531",
    longitude: "9.0817",
  },
  {
    country_code: "CH",
    zipcode: "9249",
    place: "Algetshausen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4491",
    longitude: "9.1084",
  },
  {
    country_code: "CH",
    zipcode: "9249",
    place: "Oberstetten",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Uzwil",
    community_code: "3408",
    latitude: "47.4511",
    longitude: "9.0951",
  },
  {
    country_code: "CH",
    zipcode: "9500",
    place: "Wil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Wil (SG)",
    community_code: "3427",
    latitude: "47.4615",
    longitude: "9.0455",
  },
  {
    country_code: "CH",
    zipcode: "9501",
    place: "Wil SG 1",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Wil (SG)",
    community_code: "3427",
    latitude: "47.4757",
    longitude: "9.0518",
  },
  {
    country_code: "CH",
    zipcode: "9512",
    place: "Rossrüti",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Wil (SG)",
    community_code: "3427",
    latitude: "47.4757",
    longitude: "9.0625",
  },
  {
    country_code: "CH",
    zipcode: "9523",
    place: "Züberwangen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Zuzwil (SG)",
    community_code: "3426",
    latitude: "47.4671",
    longitude: "9.0854",
  },
  {
    country_code: "CH",
    zipcode: "9524",
    place: "Zuzwil SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Zuzwil (SG)",
    community_code: "3426",
    latitude: "47.4745",
    longitude: "9.112",
  },
  {
    country_code: "CH",
    zipcode: "9525",
    place: "Lenggenwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Niederhelfenschwil",
    community_code: "3423",
    latitude: "47.4763",
    longitude: "9.1504",
  },
  {
    country_code: "CH",
    zipcode: "9526",
    place: "Zuckenriet",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Niederhelfenschwil",
    community_code: "3423",
    latitude: "47.4851",
    longitude: "9.1616",
  },
  {
    country_code: "CH",
    zipcode: "9527",
    place: "Niederhelfenschwil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Niederhelfenschwil",
    community_code: "3423",
    latitude: "47.4749",
    longitude: "9.1854",
  },
  {
    country_code: "CH",
    zipcode: "9532",
    place: "Rickenbach b. Wil",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Jonschwil",
    community_code: "3405",
    latitude: "47.4494",
    longitude: "9.0484",
  },
  {
    country_code: "CH",
    zipcode: "9536",
    place: "Schwarzenbach SG",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Jonschwil",
    community_code: "3405",
    latitude: "47.4436",
    longitude: "9.078",
  },
  {
    country_code: "CH",
    zipcode: "9552",
    place: "Bronschhofen",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Wil (SG)",
    community_code: "3427",
    latitude: "47.4783",
    longitude: "9.0345",
  },
  {
    country_code: "CH",
    zipcode: "9604",
    place: "Oberrindal",
    state: "Kanton St. Gallen",
    state_code: "SG",
    province: "Wahlkreis Wil",
    province_code: "1728",
    community: "Jonschwil",
    community_code: "3405",
    latitude: "47.4091",
    longitude: "9.1201",
  },
  {
    country_code: "CH",
    zipcode: "8213",
    place: "Neunkirch",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Oberklettgau",
    province_code: "1401",
    community: "Neunkirch",
    community_code: "2904",
    latitude: "47.6901",
    longitude: "8.4998",
  },
  {
    country_code: "CH",
    zipcode: "8214",
    place: "Gächlingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Oberklettgau",
    province_code: "1401",
    community: "Gächlingen",
    community_code: "2901",
    latitude: "47.7033",
    longitude: "8.4988",
  },
  {
    country_code: "CH",
    zipcode: "8224",
    place: "Löhningen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Oberklettgau",
    province_code: "1401",
    community: "Löhningen",
    community_code: "2903",
    latitude: "47.7012",
    longitude: "8.5524",
  },
  {
    country_code: "CH",
    zipcode: "8234",
    place: "Stetten SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Stetten (SH)",
    community_code: "2919",
    latitude: "47.7402",
    longitude: "8.663",
  },
  {
    country_code: "CH",
    zipcode: "8235",
    place: "Lohn SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Lohn (SH)",
    community_code: "2917",
    latitude: "47.7551",
    longitude: "8.6685",
  },
  {
    country_code: "CH",
    zipcode: "8236",
    place: "Opfertshofen SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7768",
    longitude: "8.662",
  },
  {
    country_code: "CH",
    zipcode: "8236",
    place: "Büttenhardt",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Büttenhardt",
    community_code: "2914",
    latitude: "47.7571",
    longitude: "8.6533",
  },
  {
    country_code: "CH",
    zipcode: "8239",
    place: "Dörflingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Dörflingen",
    community_code: "2915",
    latitude: "47.706",
    longitude: "8.7224",
  },
  {
    country_code: "CH",
    zipcode: "8240",
    place: "Thayngen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7472",
    longitude: "8.7072",
  },
  {
    country_code: "CH",
    zipcode: "8241",
    place: "Barzheim",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7605",
    longitude: "8.7213",
  },
  {
    country_code: "CH",
    zipcode: "8242",
    place: "Bibern SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7729",
    longitude: "8.6762",
  },
  {
    country_code: "CH",
    zipcode: "8242",
    place: "Hofen SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7818",
    longitude: "8.678",
  },
  {
    country_code: "CH",
    zipcode: "8243",
    place: "Altdorf SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Reiat",
    province_code: "1402",
    community: "Thayngen",
    community_code: "2920",
    latitude: "47.7842",
    longitude: "8.6595",
  },
  {
    country_code: "CH",
    zipcode: "8200",
    place: "Schaffhausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.6973",
    longitude: "8.6349",
  },
  {
    country_code: "CH",
    zipcode: "8201",
    place: "Schaffhausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.7214",
    longitude: "8.6247",
  },
  {
    country_code: "CH",
    zipcode: "8203",
    place: "Schaffhausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.6973",
    longitude: "8.6349",
  },
  {
    country_code: "CH",
    zipcode: "8207",
    place: "Schaffhausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.6973",
    longitude: "8.6349",
  },
  {
    country_code: "CH",
    zipcode: "8208",
    place: "Schaffhausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.6973",
    longitude: "8.6349",
  },
  {
    country_code: "CH",
    zipcode: "8212",
    place: "Neuhausen am Rheinfall",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Neuhausen am Rheinfall",
    community_code: "2937",
    latitude: "47.6858",
    longitude: "8.6147",
  },
  {
    country_code: "CH",
    zipcode: "8222",
    place: "Beringen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Beringen",
    community_code: "2932",
    latitude: "47.6976",
    longitude: "8.5743",
  },
  {
    country_code: "CH",
    zipcode: "8223",
    place: "Guntmadingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Beringen",
    community_code: "2932",
    latitude: "47.713",
    longitude: "8.6019",
  },
  {
    country_code: "CH",
    zipcode: "8231",
    place: "Hemmental",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Schaffhausen",
    community_code: "2939",
    latitude: "47.7338",
    longitude: "8.5853",
  },
  {
    country_code: "CH",
    zipcode: "8232",
    place: "Merishausen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Merishausen",
    community_code: "2936",
    latitude: "47.7601",
    longitude: "8.6105",
  },
  {
    country_code: "CH",
    zipcode: "8233",
    place: "Bargen SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Bargen (SH)",
    community_code: "2931",
    latitude: "47.7919",
    longitude: "8.6102",
  },
  {
    country_code: "CH",
    zipcode: "8454",
    place: "Buchberg",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Buchberg",
    community_code: "2933",
    latitude: "47.5728",
    longitude: "8.5628",
  },
  {
    country_code: "CH",
    zipcode: "8455",
    place: "Rüdlingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schaffhausen",
    province_code: "1403",
    community: "Rüdlingen",
    community_code: "2938",
    latitude: "47.5783",
    longitude: "8.5719",
  },
  {
    country_code: "CH",
    zipcode: "8225",
    place: "Siblingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schleitheim",
    province_code: "1404",
    community: "Siblingen",
    community_code: "2953",
    latitude: "47.7144",
    longitude: "8.5201",
  },
  {
    country_code: "CH",
    zipcode: "8226",
    place: "Schleitheim",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schleitheim",
    province_code: "1404",
    community: "Schleitheim",
    community_code: "2952",
    latitude: "47.7482",
    longitude: "8.4821",
  },
  {
    country_code: "CH",
    zipcode: "8228",
    place: "Beggingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Schleitheim",
    province_code: "1404",
    community: "Beggingen",
    community_code: "2951",
    latitude: "47.7674",
    longitude: "8.5353",
  },
  {
    country_code: "CH",
    zipcode: "8260",
    place: "Stein am Rhein",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Stein",
    province_code: "1405",
    community: "Stein am Rhein",
    community_code: "2964",
    latitude: "47.6593",
    longitude: "8.8596",
  },
  {
    country_code: "CH",
    zipcode: "8261",
    place: "Hemishofen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Stein",
    province_code: "1405",
    community: "Hemishofen",
    community_code: "2962",
    latitude: "47.6766",
    longitude: "8.8298",
  },
  {
    country_code: "CH",
    zipcode: "8262",
    place: "Ramsen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Stein",
    province_code: "1405",
    community: "Ramsen",
    community_code: "2963",
    latitude: "47.708",
    longitude: "8.8095",
  },
  {
    country_code: "CH",
    zipcode: "8263",
    place: "Buch SH",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Stein",
    province_code: "1405",
    community: "Buch (SH)",
    community_code: "2961",
    latitude: "47.7174",
    longitude: "8.7815",
  },
  {
    country_code: "CH",
    zipcode: "8215",
    place: "Hallau",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Unterklettgau",
    province_code: "1406",
    community: "Hallau",
    community_code: "2971",
    latitude: "47.6965",
    longitude: "8.4583",
  },
  {
    country_code: "CH",
    zipcode: "8216",
    place: "Oberhallau",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Unterklettgau",
    province_code: "1406",
    community: "Oberhallau",
    community_code: "2972",
    latitude: "47.7071",
    longitude: "8.4785",
  },
  {
    country_code: "CH",
    zipcode: "8217",
    place: "Wilchingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Unterklettgau",
    province_code: "1406",
    community: "Wilchingen",
    community_code: "2974",
    latitude: "47.6675",
    longitude: "8.4677",
  },
  {
    country_code: "CH",
    zipcode: "8218",
    place: "Osterfingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Unterklettgau",
    province_code: "1406",
    community: "Wilchingen",
    community_code: "2974",
    latitude: "47.6631",
    longitude: "8.4856",
  },
  {
    country_code: "CH",
    zipcode: "8219",
    place: "Trasadingen",
    state: "Kanton Schaffhausen",
    state_code: "SH",
    province: "Bezirk Unterklettgau",
    province_code: "1406",
    community: "Trasadingen",
    community_code: "2973",
    latitude: "47.6686",
    longitude: "8.4299",
  },
  {
    country_code: "CH",
    zipcode: "4622",
    place: "Egerkingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Egerkingen",
    community_code: "2401",
    latitude: "47.3196",
    longitude: "7.7842",
  },
  {
    country_code: "CH",
    zipcode: "4623",
    place: "Neuendorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Neuendorf",
    community_code: "2404",
    latitude: "47.3008",
    longitude: "7.7954",
  },
  {
    country_code: "CH",
    zipcode: "4624",
    place: "Härkingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Härkingen",
    community_code: "2402",
    latitude: "47.3089",
    longitude: "7.8181",
  },
  {
    country_code: "CH",
    zipcode: "4625",
    place: "Oberbuchsiten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Oberbuchsiten",
    community_code: "2406",
    latitude: "47.3133",
    longitude: "7.7684",
  },
  {
    country_code: "CH",
    zipcode: "4626",
    place: "Niederbuchsiten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Niederbuchsiten",
    community_code: "2405",
    latitude: "47.2965",
    longitude: "7.7718",
  },
  {
    country_code: "CH",
    zipcode: "4628",
    place: "Wolfwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Wolfwil",
    community_code: "2408",
    latitude: "47.2682",
    longitude: "7.7897",
  },
  {
    country_code: "CH",
    zipcode: "4702",
    place: "Oensingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Oensingen",
    community_code: "2407",
    latitude: "47.2864",
    longitude: "7.7231",
  },
  {
    country_code: "CH",
    zipcode: "4703",
    place: "Kestenholz",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gäu",
    province_code: "1101",
    community: "Kestenholz",
    community_code: "2403",
    latitude: "47.2804",
    longitude: "7.7529",
  },
  {
    country_code: "CH",
    zipcode: "4710",
    place: "Balsthal",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Balsthal",
    community_code: "2422",
    latitude: "47.3161",
    longitude: "7.6932",
  },
  {
    country_code: "CH",
    zipcode: "4712",
    place: "Laupersdorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Laupersdorf",
    community_code: "2426",
    latitude: "47.3143",
    longitude: "7.6505",
  },
  {
    country_code: "CH",
    zipcode: "4713",
    place: "Matzendorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Matzendorf",
    community_code: "2427",
    latitude: "47.3037",
    longitude: "7.6282",
  },
  {
    country_code: "CH",
    zipcode: "4714",
    place: "Aedermannsdorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Aedermannsdorf",
    community_code: "2421",
    latitude: "47.3037",
    longitude: "7.6105",
  },
  {
    country_code: "CH",
    zipcode: "4715",
    place: "Herbetswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Herbetswil",
    community_code: "2424",
    latitude: "47.296",
    longitude: "7.592",
  },
  {
    country_code: "CH",
    zipcode: "4716",
    place: "Gänsbrunnen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Gänsbrunnen",
    community_code: "2423",
    latitude: "47.2612",
    longitude: "7.4686",
  },
  {
    country_code: "CH",
    zipcode: "4716",
    place: "Welschenrohr",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Welschenrohr",
    community_code: "2429",
    latitude: "47.2803",
    longitude: "7.5263",
  },
  {
    country_code: "CH",
    zipcode: "4717",
    place: "Mümliswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Mümliswil-Ramiswil",
    community_code: "2428",
    latitude: "47.3441",
    longitude: "7.7035",
  },
  {
    country_code: "CH",
    zipcode: "4718",
    place: "Holderbank SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Holderbank (SO)",
    community_code: "2425",
    latitude: "47.3325",
    longitude: "7.7497",
  },
  {
    country_code: "CH",
    zipcode: "4719",
    place: "Ramiswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thal",
    province_code: "1102",
    community: "Mümliswil-Ramiswil",
    community_code: "2428",
    latitude: "47.3451",
    longitude: "7.6607",
  },
  {
    country_code: "CH",
    zipcode: "3253",
    place: "Schnottwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Schnottwil",
    community_code: "2461",
    latitude: "47.1117",
    longitude: "7.3937",
  },
  {
    country_code: "CH",
    zipcode: "3254",
    place: "Messen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Messen",
    community_code: "2457",
    latitude: "47.0913",
    longitude: "7.4496",
  },
  {
    country_code: "CH",
    zipcode: "3254",
    place: "Balm b. Messen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Messen",
    community_code: "2457",
    latitude: "47.0913",
    longitude: "7.4496",
  },
  {
    country_code: "CH",
    zipcode: "3307",
    place: "Brunnenthal",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Messen",
    community_code: "2457",
    latitude: "47.0867",
    longitude: "7.4686",
  },
  {
    country_code: "CH",
    zipcode: "4571",
    place: "Ichertswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüterkofen-Ichertswil",
    community_code: "2455",
    latitude: "47.1614",
    longitude: "7.5001",
  },
  {
    country_code: "CH",
    zipcode: "4571",
    place: "Lüterkofen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüterkofen-Ichertswil",
    community_code: "2455",
    latitude: "47.1653",
    longitude: "7.5106",
  },
  {
    country_code: "CH",
    zipcode: "4574",
    place: "Lüsslingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüsslingen-Nennigkofen",
    community_code: "2464",
    latitude: "47.19",
    longitude: "7.5017",
  },
  {
    country_code: "CH",
    zipcode: "4574",
    place: "Nennigkofen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüsslingen-Nennigkofen",
    community_code: "2464",
    latitude: "47.1863",
    longitude: "7.4952",
  },
  {
    country_code: "CH",
    zipcode: "4576",
    place: "Tscheppach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1487",
    longitude: "7.4827",
  },
  {
    country_code: "CH",
    zipcode: "4577",
    place: "Hessigkofen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1413",
    longitude: "7.4667",
  },
  {
    country_code: "CH",
    zipcode: "4578",
    place: "Bibern SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1469",
    longitude: "7.4576",
  },
  {
    country_code: "CH",
    zipcode: "4579",
    place: "Gossliwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1367",
    longitude: "7.4344",
  },
  {
    country_code: "CH",
    zipcode: "4581",
    place: "Küttigkofen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1514",
    longitude: "7.5155",
  },
  {
    country_code: "CH",
    zipcode: "4582",
    place: "Brügglen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1424",
    longitude: "7.4962",
  },
  {
    country_code: "CH",
    zipcode: "4583",
    place: "Aetigkofen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1257",
    longitude: "7.4645",
  },
  {
    country_code: "CH",
    zipcode: "4583",
    place: "Mühledorf SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1346",
    longitude: "7.4766",
  },
  {
    country_code: "CH",
    zipcode: "4584",
    place: "Lüterswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüterswil-Gächliwil",
    community_code: "2456",
    latitude: "47.1222",
    longitude: "7.4391",
  },
  {
    country_code: "CH",
    zipcode: "4584",
    place: "Gächliwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Lüterswil-Gächliwil",
    community_code: "2456",
    latitude: "47.1222",
    longitude: "7.4391",
  },
  {
    country_code: "CH",
    zipcode: "4585",
    place: "Biezwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Biezwil",
    community_code: "2445",
    latitude: "47.1165",
    longitude: "7.4155",
  },
  {
    country_code: "CH",
    zipcode: "4586",
    place: "Kyburg-Buchegg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1425",
    longitude: "7.5098",
  },
  {
    country_code: "CH",
    zipcode: "4587",
    place: "Aetingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.1322",
    longitude: "7.5059",
  },
  {
    country_code: "CH",
    zipcode: "4588",
    place: "Brittern",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Buchegg",
    community_code: "2465",
    latitude: "47.122",
    longitude: "7.4926",
  },
  {
    country_code: "CH",
    zipcode: "4588",
    place: "Oberramsern",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Messen",
    community_code: "2457",
    latitude: "47.0961",
    longitude: "7.4504",
  },
  {
    country_code: "CH",
    zipcode: "4588",
    place: "Unterramsern",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Bucheggberg",
    province_code: "1103",
    community: "Unterramsern",
    community_code: "2463",
    latitude: "47.1177",
    longitude: "7.4827",
  },
  {
    country_code: "CH",
    zipcode: "4108",
    place: "Witterswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Witterswil",
    community_code: "2481",
    latitude: "47.4866",
    longitude: "7.5212",
  },
  {
    country_code: "CH",
    zipcode: "4112",
    place: "Bättwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Bättwil",
    community_code: "2471",
    latitude: "47.4895",
    longitude: "7.5083",
  },
  {
    country_code: "CH",
    zipcode: "4112",
    place: "Flüh",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Hofstetten-Flüh",
    community_code: "2476",
    latitude: "47.4722",
    longitude: "7.5083",
  },
  {
    country_code: "CH",
    zipcode: "4114",
    place: "Hofstetten SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Hofstetten-Flüh",
    community_code: "2476",
    latitude: "47.4767",
    longitude: "7.5159",
  },
  {
    country_code: "CH",
    zipcode: "4115",
    place: "Mariastein",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Metzerlen-Mariastein",
    community_code: "2477",
    latitude: "47.4757",
    longitude: "7.4892",
  },
  {
    country_code: "CH",
    zipcode: "4116",
    place: "Metzerlen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Metzerlen-Mariastein",
    community_code: "2477",
    latitude: "47.4663",
    longitude: "7.4657",
  },
  {
    country_code: "CH",
    zipcode: "4118",
    place: "Rodersdorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Rodersdorf",
    community_code: "2479",
    latitude: "47.4822",
    longitude: "7.4576",
  },
  {
    country_code: "CH",
    zipcode: "4143",
    place: "Dornach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Dornach",
    community_code: "2473",
    latitude: "47.4804",
    longitude: "7.6164",
  },
  {
    country_code: "CH",
    zipcode: "4145",
    place: "Gempen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Gempen",
    community_code: "2474",
    latitude: "47.4759",
    longitude: "7.6603",
  },
  {
    country_code: "CH",
    zipcode: "4146",
    place: "Hochwald",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Hochwald",
    community_code: "2475",
    latitude: "47.4583",
    longitude: "7.6418",
  },
  {
    country_code: "CH",
    zipcode: "4206",
    place: "Seewen SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Seewen",
    community_code: "2480",
    latitude: "47.4374",
    longitude: "7.6615",
  },
  {
    country_code: "CH",
    zipcode: "4412",
    place: "Nuglar",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Nuglar-St. Pantaleon",
    community_code: "2478",
    latitude: "47.4727",
    longitude: "7.6938",
  },
  {
    country_code: "CH",
    zipcode: "4413",
    place: "Büren SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Büren (SO)",
    community_code: "2472",
    latitude: "47.447",
    longitude: "7.6706",
  },
  {
    country_code: "CH",
    zipcode: "4421",
    place: "St. Pantaleon",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Dorneck",
    province_code: "1104",
    community: "Nuglar-St. Pantaleon",
    community_code: "2478",
    latitude: "47.461",
    longitude: "7.6918",
  },
  {
    country_code: "CH",
    zipcode: "4468",
    place: "Kienberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Kienberg",
    community_code: "2492",
    latitude: "47.4383",
    longitude: "7.9638",
  },
  {
    country_code: "CH",
    zipcode: "4632",
    place: "Trimbach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Trimbach",
    community_code: "2500",
    latitude: "47.3656",
    longitude: "7.8868",
  },
  {
    country_code: "CH",
    zipcode: "4633",
    place: "Hauenstein",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Hauenstein-Ifenthal",
    community_code: "2491",
    latitude: "47.3779",
    longitude: "7.8721",
  },
  {
    country_code: "CH",
    zipcode: "4634",
    place: "Wisen SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Wisen (SO)",
    community_code: "2502",
    latitude: "47.3928",
    longitude: "7.8851",
  },
  {
    country_code: "CH",
    zipcode: "4652",
    place: "Winznau",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Winznau",
    community_code: "2501",
    latitude: "47.3636",
    longitude: "7.9301",
  },
  {
    country_code: "CH",
    zipcode: "4653",
    place: "Obergösgen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Obergösgen",
    community_code: "2497",
    latitude: "47.3566",
    longitude: "7.956",
  },
  {
    country_code: "CH",
    zipcode: "4654",
    place: "Lostorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Lostorf",
    community_code: "2493",
    latitude: "47.3837",
    longitude: "7.9466",
  },
  {
    country_code: "CH",
    zipcode: "4655",
    place: "Rohr b. Olten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Rohr (SO)",
    community_code: "2498",
    latitude: "47.4116",
    longitude: "7.9529",
  },
  {
    country_code: "CH",
    zipcode: "4655",
    place: "Stüsslingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Stüsslingen",
    community_code: "2499",
    latitude: "47.3949",
    longitude: "7.9695",
  },
  {
    country_code: "CH",
    zipcode: "5013",
    place: "Niedergösgen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Niedergösgen",
    community_code: "2495",
    latitude: "47.3722",
    longitude: "7.9912",
  },
  {
    country_code: "CH",
    zipcode: "5015",
    place: "Erlinsbach SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Gösgen",
    province_code: "1105",
    community: "Erlinsbach (SO)",
    community_code: "2503",
    latitude: "47.4",
    longitude: "8",
  },
  {
    country_code: "CH",
    zipcode: "4528",
    place: "Zuchwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Zuchwil",
    community_code: "2534",
    latitude: "47.2017",
    longitude: "7.5665",
  },
  {
    country_code: "CH",
    zipcode: "4542",
    place: "Luterbach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Luterbach",
    community_code: "2527",
    latitude: "47.2143",
    longitude: "7.5846",
  },
  {
    country_code: "CH",
    zipcode: "4543",
    place: "Deitingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Deitingen",
    community_code: "2516",
    latitude: "47.2158",
    longitude: "7.6199",
  },
  {
    country_code: "CH",
    zipcode: "4551",
    place: "Derendingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Derendingen",
    community_code: "2517",
    latitude: "47.1919",
    longitude: "7.5899",
  },
  {
    country_code: "CH",
    zipcode: "4552",
    place: "Derendingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Derendingen",
    community_code: "2517",
    latitude: "47.1985",
    longitude: "7.5884",
  },
  {
    country_code: "CH",
    zipcode: "4553",
    place: "Subingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Subingen",
    community_code: "2532",
    latitude: "47.1985",
    longitude: "7.6195",
  },
  {
    country_code: "CH",
    zipcode: "4554",
    place: "Etziken",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Etziken",
    community_code: "2518",
    latitude: "47.1876",
    longitude: "7.6478",
  },
  {
    country_code: "CH",
    zipcode: "4554",
    place: "Hüniken",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Hüniken",
    community_code: "2524",
    latitude: "47.1847",
    longitude: "7.6363",
  },
  {
    country_code: "CH",
    zipcode: "4556",
    place: "Steinhof SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Aeschi (SO)",
    community_code: "2511",
    latitude: "47.1612",
    longitude: "7.6867",
  },
  {
    country_code: "CH",
    zipcode: "4556",
    place: "Bolken",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Bolken",
    community_code: "2514",
    latitude: "47.1912",
    longitude: "7.663",
  },
  {
    country_code: "CH",
    zipcode: "4556",
    place: "Aeschi SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Aeschi (SO)",
    community_code: "2511",
    latitude: "47.1798",
    longitude: "7.6614",
  },
  {
    country_code: "CH",
    zipcode: "4556",
    place: "Burgäschi",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Aeschi (SO)",
    community_code: "2511",
    latitude: "47.1774",
    longitude: "7.6704",
  },
  {
    country_code: "CH",
    zipcode: "4557",
    place: "Horriwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Horriwil",
    community_code: "2523",
    latitude: "47.1823",
    longitude: "7.6219",
  },
  {
    country_code: "CH",
    zipcode: "4558",
    place: "Winistorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Drei Höfe",
    community_code: "2535",
    latitude: "47.1579",
    longitude: "7.6351",
  },
  {
    country_code: "CH",
    zipcode: "4558",
    place: "Hersiwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Drei Höfe",
    community_code: "2535",
    latitude: "47.1661",
    longitude: "7.6352",
  },
  {
    country_code: "CH",
    zipcode: "4558",
    place: "Heinrichswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Drei Höfe",
    community_code: "2535",
    latitude: "47.1579",
    longitude: "7.6351",
  },
  {
    country_code: "CH",
    zipcode: "4562",
    place: "Biberist",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Biberist",
    community_code: "2513",
    latitude: "47.1801",
    longitude: "7.5625",
  },
  {
    country_code: "CH",
    zipcode: "4563",
    place: "Gerlafingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Gerlafingen",
    community_code: "2519",
    latitude: "47.1698",
    longitude: "7.5751",
  },
  {
    country_code: "CH",
    zipcode: "4564",
    place: "Obergerlafingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Obergerlafingen",
    community_code: "2528",
    latitude: "47.1612",
    longitude: "7.5818",
  },
  {
    country_code: "CH",
    zipcode: "4565",
    place: "Recherswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Recherswil",
    community_code: "2530",
    latitude: "47.1626",
    longitude: "7.5946",
  },
  {
    country_code: "CH",
    zipcode: "4566",
    place: "Halten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Halten",
    community_code: "2520",
    latitude: "47.1688",
    longitude: "7.6035",
  },
  {
    country_code: "CH",
    zipcode: "4566",
    place: "Oekingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Oekingen",
    community_code: "2529",
    latitude: "47.1798",
    longitude: "7.6024",
  },
  {
    country_code: "CH",
    zipcode: "4566",
    place: "Kriegstetten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Kriegstetten",
    community_code: "2525",
    latitude: "47.1745",
    longitude: "7.598",
  },
  {
    country_code: "CH",
    zipcode: "4573",
    place: "Lohn-Ammannsegg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Wasseramt",
    province_code: "1106",
    community: "Lohn-Ammannsegg",
    community_code: "2526",
    latitude: "47.1714",
    longitude: "7.5294",
  },
  {
    country_code: "CH",
    zipcode: "2540",
    place: "Grenchen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Grenchen",
    community_code: "2546",
    latitude: "47.1921",
    longitude: "7.3959",
  },
  {
    country_code: "CH",
    zipcode: "2544",
    place: "Bettlach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Bettlach",
    community_code: "2543",
    latitude: "47.2006",
    longitude: "7.4241",
  },
  {
    country_code: "CH",
    zipcode: "2545",
    place: "Selzach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Selzach",
    community_code: "2556",
    latitude: "47.2053",
    longitude: "7.4552",
  },
  {
    country_code: "CH",
    zipcode: "4512",
    place: "Bellach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Bellach",
    community_code: "2542",
    latitude: "47.2136",
    longitude: "7.4992",
  },
  {
    country_code: "CH",
    zipcode: "4513",
    place: "Langendorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Langendorf",
    community_code: "2550",
    latitude: "47.2197",
    longitude: "7.5147",
  },
  {
    country_code: "CH",
    zipcode: "4514",
    place: "Lommiswil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Lommiswil",
    community_code: "2551",
    latitude: "47.2236",
    longitude: "7.4714",
  },
  {
    country_code: "CH",
    zipcode: "4515",
    place: "Weissenstein b. Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Oberdorf (SO)",
    community_code: "2553",
    latitude: "47.2305",
    longitude: "7.5064",
  },
  {
    country_code: "CH",
    zipcode: "4515",
    place: "Oberdorf SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Oberdorf (SO)",
    community_code: "2553",
    latitude: "47.2305",
    longitude: "7.5064",
  },
  {
    country_code: "CH",
    zipcode: "4522",
    place: "Rüttenen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Rüttenen",
    community_code: "2555",
    latitude: "47.2305",
    longitude: "7.5295",
  },
  {
    country_code: "CH",
    zipcode: "4523",
    place: "Niederwil SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Riedholz",
    community_code: "2554",
    latitude: "47.252",
    longitude: "7.5713",
  },
  {
    country_code: "CH",
    zipcode: "4524",
    place: "Balmberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Balm bei Günsberg",
    community_code: "2541",
    latitude: "47.2644",
    longitude: "7.5415",
  },
  {
    country_code: "CH",
    zipcode: "4524",
    place: "Günsberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Günsberg",
    community_code: "2547",
    latitude: "47.2567",
    longitude: "7.5769",
  },
  {
    country_code: "CH",
    zipcode: "4524",
    place: "Oberbalmberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Balm bei Günsberg",
    community_code: "2541",
    latitude: "47.2606",
    longitude: "7.5592",
  },
  {
    country_code: "CH",
    zipcode: "4525",
    place: "Balm b. Günsberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Balm bei Günsberg",
    community_code: "2541",
    latitude: "47.2531",
    longitude: "7.5583",
  },
  {
    country_code: "CH",
    zipcode: "4532",
    place: "Feldbrunnen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Feldbrunnen-St. Niklaus",
    community_code: "2544",
    latitude: "47.2202",
    longitude: "7.5549",
  },
  {
    country_code: "CH",
    zipcode: "4533",
    place: "Riedholz",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Riedholz",
    community_code: "2554",
    latitude: "47.2316",
    longitude: "7.5683",
  },
  {
    country_code: "CH",
    zipcode: "4534",
    place: "Flumenthal",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Flumenthal",
    community_code: "2545",
    latitude: "47.2355",
    longitude: "7.5985",
  },
  {
    country_code: "CH",
    zipcode: "4535",
    place: "Kammersrohr",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Kammersrohr",
    community_code: "2549",
    latitude: "47.253",
    longitude: "7.5932",
  },
  {
    country_code: "CH",
    zipcode: "4535",
    place: "Hubersdorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Lebern",
    province_code: "1107",
    community: "Hubersdorf",
    community_code: "2548",
    latitude: "47.2463",
    longitude: "7.5894",
  },
  {
    country_code: "CH",
    zipcode: "4600",
    place: "Olten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Olten",
    community_code: "2581",
    latitude: "47.35",
    longitude: "7.9033",
  },
  {
    country_code: "CH",
    zipcode: "4601",
    place: "Olten 1 Fächer",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Olten",
    community_code: "2581",
    latitude: "47.3448",
    longitude: "7.9001",
  },
  {
    country_code: "CH",
    zipcode: "4609",
    place: "Olten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Olten",
    community_code: "2581",
    latitude: "47.3448",
    longitude: "7.9001",
  },
  {
    country_code: "CH",
    zipcode: "4612",
    place: "Wangen b. Olten",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Wangen bei Olten",
    community_code: "2586",
    latitude: "47.3437",
    longitude: "7.8698",
  },
  {
    country_code: "CH",
    zipcode: "4613",
    place: "Rickenbach SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Rickenbach (SO)",
    community_code: "2582",
    latitude: "47.3424",
    longitude: "7.8547",
  },
  {
    country_code: "CH",
    zipcode: "4614",
    place: "Hägendorf",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Hägendorf",
    community_code: "2579",
    latitude: "47.3344",
    longitude: "7.8419",
  },
  {
    country_code: "CH",
    zipcode: "4615",
    place: "Allerheiligenberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Hägendorf",
    community_code: "2579",
    latitude: "47.3524",
    longitude: "7.8153",
  },
  {
    country_code: "CH",
    zipcode: "4616",
    place: "Kappel SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Kappel (SO)",
    community_code: "2580",
    latitude: "47.3247",
    longitude: "7.8465",
  },
  {
    country_code: "CH",
    zipcode: "4617",
    place: "Gunzgen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Gunzgen",
    community_code: "2578",
    latitude: "47.3137",
    longitude: "7.831",
  },
  {
    country_code: "CH",
    zipcode: "4618",
    place: "Boningen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Boningen",
    community_code: "2571",
    latitude: "47.3086",
    longitude: "7.8553",
  },
  {
    country_code: "CH",
    zipcode: "4629",
    place: "Fulenbach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Fulenbach",
    community_code: "2575",
    latitude: "47.275",
    longitude: "7.8334",
  },
  {
    country_code: "CH",
    zipcode: "4656",
    place: "Starrkirch-Wil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Starrkirch-Wil",
    community_code: "2584",
    latitude: "47.3478",
    longitude: "7.9274",
  },
  {
    country_code: "CH",
    zipcode: "4657",
    place: "Dulliken",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Dulliken",
    community_code: "2573",
    latitude: "47.3481",
    longitude: "7.9464",
  },
  {
    country_code: "CH",
    zipcode: "4658",
    place: "Däniken SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Däniken",
    community_code: "2572",
    latitude: "47.3532",
    longitude: "7.9874",
  },
  {
    country_code: "CH",
    zipcode: "5012",
    place: "Schönenwerd",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Schönenwerd",
    community_code: "2583",
    latitude: "47.3691",
    longitude: "8.0017",
  },
  {
    country_code: "CH",
    zipcode: "5012",
    place: "Wöschnau",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Eppenberg-Wöschnau",
    community_code: "2574",
    latitude: "47.3849",
    longitude: "8.0255",
  },
  {
    country_code: "CH",
    zipcode: "5012",
    place: "Eppenberg",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Eppenberg-Wöschnau",
    community_code: "2574",
    latitude: "47.3774",
    longitude: "8.0249",
  },
  {
    country_code: "CH",
    zipcode: "5014",
    place: "Gretzenbach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Gretzenbach",
    community_code: "2576",
    latitude: "47.3582",
    longitude: "8.0024",
  },
  {
    country_code: "CH",
    zipcode: "5746",
    place: "Walterswil SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Olten",
    province_code: "1108",
    community: "Walterswil (SO)",
    community_code: "2585",
    latitude: "47.3245",
    longitude: "7.9559",
  },
  {
    country_code: "CH",
    zipcode: "4500",
    place: "Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Solothurn",
    province_code: "1109",
    community: "Solothurn",
    community_code: "2601",
    latitude: "47.2079",
    longitude: "7.5371",
  },
  {
    country_code: "CH",
    zipcode: "4501",
    place: "Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Solothurn",
    province_code: "1109",
    community: "Solothurn",
    community_code: "2601",
    latitude: "47.2084",
    longitude: "7.5301",
  },
  {
    country_code: "CH",
    zipcode: "4502",
    place: "Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Solothurn",
    province_code: "1109",
    community: "Solothurn",
    community_code: "2601",
    latitude: "47.2084",
    longitude: "7.5301",
  },
  {
    country_code: "CH",
    zipcode: "4503",
    place: "Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Solothurn",
    province_code: "1109",
    community: "Solothurn",
    community_code: "2601",
    latitude: "47.2084",
    longitude: "7.5301",
  },
  {
    country_code: "CH",
    zipcode: "4509",
    place: "Solothurn",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Solothurn",
    province_code: "1109",
    community: "Solothurn",
    community_code: "2601",
    latitude: "47.2084",
    longitude: "7.5301",
  },
  {
    country_code: "CH",
    zipcode: "4204",
    place: "Himmelried",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Himmelried",
    community_code: "2618",
    latitude: "47.4211",
    longitude: "7.5966",
  },
  {
    country_code: "CH",
    zipcode: "4208",
    place: "Nunningen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Nunningen",
    community_code: "2621",
    latitude: "47.3945",
    longitude: "7.6212",
  },
  {
    country_code: "CH",
    zipcode: "4226",
    place: "Breitenbach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Breitenbach",
    community_code: "2613",
    latitude: "47.4056",
    longitude: "7.5438",
  },
  {
    country_code: "CH",
    zipcode: "4227",
    place: "Büsserach",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Büsserach",
    community_code: "2614",
    latitude: "47.3946",
    longitude: "7.5412",
  },
  {
    country_code: "CH",
    zipcode: "4228",
    place: "Erschwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Erschwil",
    community_code: "2615",
    latitude: "47.3739",
    longitude: "7.5414",
  },
  {
    country_code: "CH",
    zipcode: "4229",
    place: "Beinwil SO",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Beinwil (SO)",
    community_code: "2612",
    latitude: "47.3626",
    longitude: "7.5871",
  },
  {
    country_code: "CH",
    zipcode: "4232",
    place: "Fehren",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Fehren",
    community_code: "2616",
    latitude: "47.3965",
    longitude: "7.577",
  },
  {
    country_code: "CH",
    zipcode: "4233",
    place: "Meltingen",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Meltingen",
    community_code: "2620",
    latitude: "47.3841",
    longitude: "7.5867",
  },
  {
    country_code: "CH",
    zipcode: "4234",
    place: "Zullwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Zullwil",
    community_code: "2622",
    latitude: "47.3911",
    longitude: "7.6005",
  },
  {
    country_code: "CH",
    zipcode: "4245",
    place: "Kleinlützel",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Kleinlützel",
    community_code: "2619",
    latitude: "47.4254",
    longitude: "7.4229",
  },
  {
    country_code: "CH",
    zipcode: "4247",
    place: "Grindel",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Grindel",
    community_code: "2617",
    latitude: "47.3807",
    longitude: "7.5059",
  },
  {
    country_code: "CH",
    zipcode: "4252",
    place: "Bärschwil",
    state: "Kanton Solothurn",
    state_code: "SO",
    province: "Bezirk Thierstein",
    province_code: "1110",
    community: "Bärschwil",
    community_code: "2611",
    latitude: "47.3822",
    longitude: "7.4723",
  },
  {
    country_code: "CH",
    zipcode: "8836",
    place: "Bennau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1502",
    longitude: "8.7305",
  },
  {
    country_code: "CH",
    zipcode: "8840",
    place: "Trachslau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1028",
    longitude: "8.7294",
  },
  {
    country_code: "CH",
    zipcode: "8840",
    place: "Einsiedeln",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1285",
    longitude: "8.7473",
  },
  {
    country_code: "CH",
    zipcode: "8841",
    place: "Gross",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1178",
    longitude: "8.7744",
  },
  {
    country_code: "CH",
    zipcode: "8844",
    place: "Euthal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.0969",
    longitude: "8.8114",
  },
  {
    country_code: "CH",
    zipcode: "8846",
    place: "Willerzell",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1332",
    longitude: "8.7935",
  },
  {
    country_code: "CH",
    zipcode: "8847",
    place: "Egg SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Einsiedeln",
    province_code: "501",
    community: "Einsiedeln",
    community_code: "1301",
    latitude: "47.1631",
    longitude: "8.7848",
  },
  {
    country_code: "CH",
    zipcode: "6410",
    place: "Rigi Scheidegg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Gersau",
    province_code: "502",
    community: "Gersau",
    community_code: "1311",
    latitude: "47.0268",
    longitude: "8.5228",
  },
  {
    country_code: "CH",
    zipcode: "6442",
    place: "Gersau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Gersau",
    province_code: "502",
    community: "Gersau",
    community_code: "1311",
    latitude: "46.9942",
    longitude: "8.525",
  },
  {
    country_code: "CH",
    zipcode: "8640",
    place: "Hurden",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.2135",
    longitude: "8.8014",
  },
  {
    country_code: "CH",
    zipcode: "8806",
    place: "Bäch SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.2039",
    longitude: "8.7322",
  },
  {
    country_code: "CH",
    zipcode: "8807",
    place: "Freienbach",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.2053",
    longitude: "8.7584",
  },
  {
    country_code: "CH",
    zipcode: "8808",
    place: "Pfäffikon SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.2011",
    longitude: "8.7782",
  },
  {
    country_code: "CH",
    zipcode: "8832",
    place: "Wollerau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.1948",
    longitude: "8.719",
  },
  {
    country_code: "CH",
    zipcode: "8832",
    place: "Wilen b. Wollerau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Freienbach",
    community_code: "1322",
    latitude: "47.1948",
    longitude: "8.719",
  },
  {
    country_code: "CH",
    zipcode: "8832",
    place: "Wollerau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Wollerau",
    community_code: "1323",
    latitude: "47.1948",
    longitude: "8.719",
  },
  {
    country_code: "CH",
    zipcode: "8834",
    place: "Schindellegi",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Feusisberg",
    community_code: "1321",
    latitude: "47.1746",
    longitude: "8.7134",
  },
  {
    country_code: "CH",
    zipcode: "8835",
    place: "Feusisberg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Höfe",
    province_code: "503",
    community: "Feusisberg",
    community_code: "1321",
    latitude: "47.1871",
    longitude: "8.7472",
  },
  {
    country_code: "CH",
    zipcode: "6402",
    place: "Merlischachen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Küssnacht",
    province_code: "504",
    community: "Küssnacht (SZ)",
    community_code: "1331",
    latitude: "47.0662",
    longitude: "8.4054",
  },
  {
    country_code: "CH",
    zipcode: "6403",
    place: "Küssnacht am Rigi",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Küssnacht",
    province_code: "504",
    community: "Küssnacht (SZ)",
    community_code: "1331",
    latitude: "47.0856",
    longitude: "8.4421",
  },
  {
    country_code: "CH",
    zipcode: "6405",
    place: "Immensee",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Küssnacht",
    province_code: "504",
    community: "Küssnacht (SZ)",
    community_code: "1331",
    latitude: "47.0965",
    longitude: "8.4636",
  },
  {
    country_code: "CH",
    zipcode: "8852",
    place: "Altendorf",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Altendorf",
    community_code: "1341",
    latitude: "47.1899",
    longitude: "8.8382",
  },
  {
    country_code: "CH",
    zipcode: "8853",
    place: "Lachen SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Lachen",
    community_code: "1344",
    latitude: "47.1993",
    longitude: "8.8543",
  },
  {
    country_code: "CH",
    zipcode: "8854",
    place: "Siebnen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Schübelbach",
    community_code: "1346",
    latitude: "47.1745",
    longitude: "8.8978",
  },
  {
    country_code: "CH",
    zipcode: "8854",
    place: "Galgenen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Galgenen",
    community_code: "1342",
    latitude: "47.1823",
    longitude: "8.8705",
  },
  {
    country_code: "CH",
    zipcode: "8855",
    place: "Wangen SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Wangen (SZ)",
    community_code: "1349",
    latitude: "47.1908",
    longitude: "8.895",
  },
  {
    country_code: "CH",
    zipcode: "8856",
    place: "Tuggen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Tuggen",
    community_code: "1347",
    latitude: "47.2029",
    longitude: "8.949",
  },
  {
    country_code: "CH",
    zipcode: "8857",
    place: "Vorderthal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Vorderthal",
    community_code: "1348",
    latitude: "47.1217",
    longitude: "8.9022",
  },
  {
    country_code: "CH",
    zipcode: "8858",
    place: "Innerthal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Innerthal",
    community_code: "1343",
    latitude: "47.1057",
    longitude: "8.9201",
  },
  {
    country_code: "CH",
    zipcode: "8862",
    place: "Schübelbach",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Schübelbach",
    community_code: "1346",
    latitude: "47.1733",
    longitude: "8.9281",
  },
  {
    country_code: "CH",
    zipcode: "8863",
    place: "Buttikon SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Schübelbach",
    community_code: "1346",
    latitude: "47.1753",
    longitude: "8.9558",
  },
  {
    country_code: "CH",
    zipcode: "8864",
    place: "Reichenburg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk March",
    province_code: "505",
    community: "Reichenburg",
    community_code: "1345",
    latitude: "47.171",
    longitude: "8.977",
  },
  {
    country_code: "CH",
    zipcode: "6410",
    place: "Rigi Klösterli",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.0445",
    longitude: "8.4869",
  },
  {
    country_code: "CH",
    zipcode: "6410",
    place: "Rigi Staffel",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.0523",
    longitude: "8.4752",
  },
  {
    country_code: "CH",
    zipcode: "6410",
    place: "Goldau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.0476",
    longitude: "8.5462",
  },
  {
    country_code: "CH",
    zipcode: "6410",
    place: "Rigi Kulm",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.0555",
    longitude: "8.4845",
  },
  {
    country_code: "CH",
    zipcode: "6414",
    place: "Oberarth",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.055",
    longitude: "8.5359",
  },
  {
    country_code: "CH",
    zipcode: "6415",
    place: "Arth",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Arth",
    community_code: "1362",
    latitude: "47.0634",
    longitude: "8.5235",
  },
  {
    country_code: "CH",
    zipcode: "6416",
    place: "Steinerberg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Steinerberg",
    community_code: "1374",
    latitude: "47.0521",
    longitude: "8.584",
  },
  {
    country_code: "CH",
    zipcode: "6417",
    place: "Sattel",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Sattel",
    community_code: "1371",
    latitude: "47.0825",
    longitude: "8.6356",
  },
  {
    country_code: "CH",
    zipcode: "6418",
    place: "Rothenthurm",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Rothenthurm",
    community_code: "1370",
    latitude: "47.1042",
    longitude: "8.6759",
  },
  {
    country_code: "CH",
    zipcode: "6422",
    place: "Steinen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Steinen",
    community_code: "1373",
    latitude: "47.0498",
    longitude: "8.6121",
  },
  {
    country_code: "CH",
    zipcode: "6423",
    place: "Seewen SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Schwyz",
    community_code: "1372",
    latitude: "47.0288",
    longitude: "8.6304",
  },
  {
    country_code: "CH",
    zipcode: "6424",
    place: "Lauerz",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Lauerz",
    community_code: "1365",
    latitude: "47.0333",
    longitude: "8.5834",
  },
  {
    country_code: "CH",
    zipcode: "6430",
    place: "Schwyz",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Schwyz",
    community_code: "1372",
    latitude: "47.0208",
    longitude: "8.6541",
  },
  {
    country_code: "CH",
    zipcode: "6431",
    place: "Schwyz",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Schwyz",
    community_code: "1372",
    latitude: "47.0235",
    longitude: "8.6746",
  },
  {
    country_code: "CH",
    zipcode: "6432",
    place: "Rickenbach b. Schwyz",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Schwyz",
    community_code: "1372",
    latitude: "47.0235",
    longitude: "8.6746",
  },
  {
    country_code: "CH",
    zipcode: "6433",
    place: "Stoos SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Morschach",
    community_code: "1366",
    latitude: "46.9767",
    longitude: "8.6632",
  },
  {
    country_code: "CH",
    zipcode: "6434",
    place: "Illgau",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Illgau",
    community_code: "1363",
    latitude: "46.9876",
    longitude: "8.7251",
  },
  {
    country_code: "CH",
    zipcode: "6436",
    place: "Bisisthal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Muotathal",
    community_code: "1367",
    latitude: "46.9434",
    longitude: "8.8318",
  },
  {
    country_code: "CH",
    zipcode: "6436",
    place: "Muotathal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Muotathal",
    community_code: "1367",
    latitude: "46.9768",
    longitude: "8.765",
  },
  {
    country_code: "CH",
    zipcode: "6436",
    place: "Ried (Muotathal)",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Muotathal",
    community_code: "1367",
    latitude: "46.9883",
    longitude: "8.7128",
  },
  {
    country_code: "CH",
    zipcode: "6438",
    place: "Ibach",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Schwyz",
    community_code: "1372",
    latitude: "47.011",
    longitude: "8.6454",
  },
  {
    country_code: "CH",
    zipcode: "6440",
    place: "Brunnen",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Ingenbohl",
    community_code: "1364",
    latitude: "46.9936",
    longitude: "8.6054",
  },
  {
    country_code: "CH",
    zipcode: "6443",
    place: "Morschach",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Morschach",
    community_code: "1366",
    latitude: "46.9827",
    longitude: "8.6183",
  },
  {
    country_code: "CH",
    zipcode: "6452",
    place: "Riemenstalden",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Riemenstalden",
    community_code: "1369",
    latitude: "46.9471",
    longitude: "8.6653",
  },
  {
    country_code: "CH",
    zipcode: "8842",
    place: "Unteriberg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Unteriberg",
    community_code: "1375",
    latitude: "47.0626",
    longitude: "8.8052",
  },
  {
    country_code: "CH",
    zipcode: "8843",
    place: "Oberiberg",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Oberiberg",
    community_code: "1368",
    latitude: "47.0384",
    longitude: "8.7792",
  },
  {
    country_code: "CH",
    zipcode: "8845",
    place: "Studen SZ",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Unteriberg",
    community_code: "1375",
    latitude: "47.0735",
    longitude: "8.8418",
  },
  {
    country_code: "CH",
    zipcode: "8849",
    place: "Alpthal",
    state: "Kanton Schwyz",
    state_code: "SZ",
    province: "Bezirk Schwyz",
    province_code: "506",
    community: "Alpthal",
    community_code: "1361",
    latitude: "47.0695",
    longitude: "8.716",
  },
  {
    country_code: "CH",
    zipcode: "8580",
    place: "Biessenhofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Amriswil",
    community_code: "4461",
    latitude: "47.5469",
    longitude: "9.2611",
  },
  {
    country_code: "CH",
    zipcode: "8580",
    place: "Sommeri",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Sommeri",
    community_code: "4446",
    latitude: "47.568",
    longitude: "9.2894",
  },
  {
    country_code: "CH",
    zipcode: "8580",
    place: "Amriswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Amriswil",
    community_code: "4461",
    latitude: "47.547",
    longitude: "9.2959",
  },
  {
    country_code: "CH",
    zipcode: "8580",
    place: "Hagenwil b. Amriswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Amriswil",
    community_code: "4461",
    latitude: "47.5548",
    longitude: "9.2931",
  },
  {
    country_code: "CH",
    zipcode: "8580",
    place: "Hefenhofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Hefenhofen",
    community_code: "4416",
    latitude: "47.5572",
    longitude: "9.3259",
  },
  {
    country_code: "CH",
    zipcode: "8581",
    place: "Schocherswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Amriswil",
    community_code: "4461",
    latitude: "47.5381",
    longitude: "9.2674",
  },
  {
    country_code: "CH",
    zipcode: "8582",
    place: "Dozwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Dozwil",
    community_code: "4406",
    latitude: "47.576",
    longitude: "9.319",
  },
  {
    country_code: "CH",
    zipcode: "8587",
    place: "Oberaach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Amriswil",
    community_code: "4461",
    latitude: "47.5595",
    longitude: "9.2651",
  },
  {
    country_code: "CH",
    zipcode: "8590",
    place: "Romanshorn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Romanshorn",
    community_code: "4436",
    latitude: "47.5659",
    longitude: "9.3787",
  },
  {
    country_code: "CH",
    zipcode: "8592",
    place: "Uttwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Uttwil",
    community_code: "4451",
    latitude: "47.5844",
    longitude: "9.341",
  },
  {
    country_code: "CH",
    zipcode: "8593",
    place: "Kesswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Kesswil",
    community_code: "4426",
    latitude: "47.5935",
    longitude: "9.3172",
  },
  {
    country_code: "CH",
    zipcode: "8599",
    place: "Salmsach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Salmsach",
    community_code: "4441",
    latitude: "47.5543",
    longitude: "9.3723",
  },
  {
    country_code: "CH",
    zipcode: "9306",
    place: "Freidorf TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Roggwil (TG)",
    community_code: "4431",
    latitude: "47.4861",
    longitude: "9.3975",
  },
  {
    country_code: "CH",
    zipcode: "9314",
    place: "Steinebrunn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Egnach",
    community_code: "4411",
    latitude: "47.5355",
    longitude: "9.3441",
  },
  {
    country_code: "CH",
    zipcode: "9315",
    place: "Winden",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Egnach",
    community_code: "4411",
    latitude: "47.5065",
    longitude: "9.3592",
  },
  {
    country_code: "CH",
    zipcode: "9315",
    place: "Neukirch (Egnach)",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Egnach",
    community_code: "4411",
    latitude: "47.5287",
    longitude: "9.37",
  },
  {
    country_code: "CH",
    zipcode: "9320",
    place: "Frasnacht",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Arbon",
    community_code: "4401",
    latitude: "47.5298",
    longitude: "9.4058",
  },
  {
    country_code: "CH",
    zipcode: "9320",
    place: "Stachen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Arbon",
    community_code: "4401",
    latitude: "47.5087",
    longitude: "9.4132",
  },
  {
    country_code: "CH",
    zipcode: "9320",
    place: "Arbon",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Arbon",
    community_code: "4401",
    latitude: "47.5167",
    longitude: "9.4333",
  },
  {
    country_code: "CH",
    zipcode: "9322",
    place: "Egnach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Egnach",
    community_code: "4411",
    latitude: "47.5427",
    longitude: "9.3805",
  },
  {
    country_code: "CH",
    zipcode: "9325",
    place: "Roggwil TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Roggwil (TG)",
    community_code: "4431",
    latitude: "47.4998",
    longitude: "9.3958",
  },
  {
    country_code: "CH",
    zipcode: "9326",
    place: "Horn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Arbon District",
    province_code: "2011",
    community: "Horn",
    community_code: "4421",
    latitude: "47.4943",
    longitude: "9.4625",
  },
  {
    country_code: "CH",
    zipcode: "8252",
    place: "Schlatt TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Schlatt (TG)",
    community_code: "4546",
    latitude: "47.6613",
    longitude: "8.7033",
  },
  {
    country_code: "CH",
    zipcode: "8253",
    place: "Willisdorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Diessenhofen",
    community_code: "4545",
    latitude: "47.6813",
    longitude: "8.7381",
  },
  {
    country_code: "CH",
    zipcode: "8253",
    place: "Diessenhofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Diessenhofen",
    community_code: "4545",
    latitude: "47.6891",
    longitude: "8.7496",
  },
  {
    country_code: "CH",
    zipcode: "8254",
    place: "Basadingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Basadingen-Schlattingen",
    community_code: "4536",
    latitude: "47.6687",
    longitude: "8.7482",
  },
  {
    country_code: "CH",
    zipcode: "8255",
    place: "Schlattingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Basadingen-Schlattingen",
    community_code: "4536",
    latitude: "47.6656",
    longitude: "8.7698",
  },
  {
    country_code: "CH",
    zipcode: "8259",
    place: "Kaltenbach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Wagenhausen",
    community_code: "4871",
    latitude: "47.6518",
    longitude: "8.8394",
  },
  {
    country_code: "CH",
    zipcode: "8259",
    place: "Wagenhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Wagenhausen",
    community_code: "4871",
    latitude: "47.66",
    longitude: "8.8478",
  },
  {
    country_code: "CH",
    zipcode: "8259",
    place: "Etzwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Wagenhausen",
    community_code: "4871",
    latitude: "47.6603",
    longitude: "8.8179",
  },
  {
    country_code: "CH",
    zipcode: "8259",
    place: "Rheinklingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Wagenhausen",
    community_code: "4871",
    latitude: "47.6764",
    longitude: "8.8086",
  },
  {
    country_code: "CH",
    zipcode: "8264",
    place: "Eschenz",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Eschenz",
    community_code: "4806",
    latitude: "47.6479",
    longitude: "8.8747",
  },
  {
    country_code: "CH",
    zipcode: "8265",
    place: "Mammern",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Mammern",
    community_code: "4826",
    latitude: "47.6463",
    longitude: "8.9152",
  },
  {
    country_code: "CH",
    zipcode: "8266",
    place: "Steckborn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Steckborn",
    community_code: "4864",
    latitude: "47.6667",
    longitude: "8.9833",
  },
  {
    country_code: "CH",
    zipcode: "8267",
    place: "Berlingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Berlingen",
    community_code: "4801",
    latitude: "47.6739",
    longitude: "9.0197",
  },
  {
    country_code: "CH",
    zipcode: "8500",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.5578",
    longitude: "8.8989",
  },
  {
    country_code: "CH",
    zipcode: "8500",
    place: "Gerlikon",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.5395",
    longitude: "8.8787",
  },
  {
    country_code: "CH",
    zipcode: "8501",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.558",
    longitude: "8.8964",
  },
  {
    country_code: "CH",
    zipcode: "8502",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.558",
    longitude: "8.8964",
  },
  {
    country_code: "CH",
    zipcode: "8503",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.558",
    longitude: "8.8964",
  },
  {
    country_code: "CH",
    zipcode: "8505",
    place: "Pfyn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Pfyn",
    community_code: "4841",
    latitude: "47.5969",
    longitude: "8.9542",
  },
  {
    country_code: "CH",
    zipcode: "8505",
    place: "Dettighofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Pfyn",
    community_code: "4841",
    latitude: "47.6181",
    longitude: "8.9503",
  },
  {
    country_code: "CH",
    zipcode: "8506",
    place: "Lanzenneunforn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Herdern",
    community_code: "4811",
    latitude: "47.624",
    longitude: "8.9401",
  },
  {
    country_code: "CH",
    zipcode: "8507",
    place: "Hörhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Homburg",
    community_code: "4816",
    latitude: "47.632",
    longitude: "8.9695",
  },
  {
    country_code: "CH",
    zipcode: "8508",
    place: "Homburg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Homburg",
    community_code: "4816",
    latitude: "47.6347",
    longitude: "9.0076",
  },
  {
    country_code: "CH",
    zipcode: "8509",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.558",
    longitude: "8.8964",
  },
  {
    country_code: "CH",
    zipcode: "8510",
    place: "Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Frauenfeld",
    community_code: "4566",
    latitude: "47.558",
    longitude: "8.8964",
  },
  {
    country_code: "CH",
    zipcode: "8512",
    place: "Thundorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Thundorf",
    community_code: "4611",
    latitude: "47.5459",
    longitude: "8.9636",
  },
  {
    country_code: "CH",
    zipcode: "8512",
    place: "Wetzikon TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Thundorf",
    community_code: "4611",
    latitude: "47.538",
    longitude: "9.0002",
  },
  {
    country_code: "CH",
    zipcode: "8512",
    place: "Lustdorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Thundorf",
    community_code: "4611",
    latitude: "47.5514",
    longitude: "8.9881",
  },
  {
    country_code: "CH",
    zipcode: "8524",
    place: "Buch b. Frauenfeld",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Uesslingen-Buch",
    community_code: "4616",
    latitude: "47.5984",
    longitude: "8.8409",
  },
  {
    country_code: "CH",
    zipcode: "8524",
    place: "Uesslingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Uesslingen-Buch",
    community_code: "4616",
    latitude: "47.5815",
    longitude: "8.831",
  },
  {
    country_code: "CH",
    zipcode: "8525",
    place: "Niederneunforn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Neunforn",
    community_code: "4601",
    latitude: "47.5973",
    longitude: "8.7848",
  },
  {
    country_code: "CH",
    zipcode: "8525",
    place: "Wilen b. Neunforn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Neunforn",
    community_code: "4601",
    latitude: "47.5973",
    longitude: "8.7848",
  },
  {
    country_code: "CH",
    zipcode: "8526",
    place: "Oberneunforn",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Neunforn",
    community_code: "4601",
    latitude: "47.6077",
    longitude: "8.7713",
  },
  {
    country_code: "CH",
    zipcode: "8532",
    place: "Warth",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Warth-Weiningen",
    community_code: "4621",
    latitude: "47.585",
    longitude: "8.8741",
  },
  {
    country_code: "CH",
    zipcode: "8532",
    place: "Weiningen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Warth-Weiningen",
    community_code: "4621",
    latitude: "47.585",
    longitude: "8.8741",
  },
  {
    country_code: "CH",
    zipcode: "8535",
    place: "Herdern",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Herdern",
    community_code: "4811",
    latitude: "47.603",
    longitude: "8.9108",
  },
  {
    country_code: "CH",
    zipcode: "8536",
    place: "Hüttwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttwilen",
    community_code: "4821",
    latitude: "47.6067",
    longitude: "8.8734",
  },
  {
    country_code: "CH",
    zipcode: "8537",
    place: "Nussbaumen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttwilen",
    community_code: "4821",
    latitude: "47.6251",
    longitude: "8.828",
  },
  {
    country_code: "CH",
    zipcode: "8537",
    place: "Uerschhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttwilen",
    community_code: "4821",
    latitude: "47.6085",
    longitude: "8.8181",
  },
  {
    country_code: "CH",
    zipcode: "8546",
    place: "Islikon",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Gachnang",
    community_code: "4571",
    latitude: "47.546",
    longitude: "8.8417",
  },
  {
    country_code: "CH",
    zipcode: "8546",
    place: "Kefikon TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Gachnang",
    community_code: "4571",
    latitude: "47.5477",
    longitude: "8.8316",
  },
  {
    country_code: "CH",
    zipcode: "8547",
    place: "Gachnang",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Gachnang",
    community_code: "4571",
    latitude: "47.5393",
    longitude: "8.8531",
  },
  {
    country_code: "CH",
    zipcode: "8552",
    place: "Felben-Wellhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Felben-Wellhausen",
    community_code: "4561",
    latitude: "47.5791",
    longitude: "8.9422",
  },
  {
    country_code: "CH",
    zipcode: "8553",
    place: "Eschikofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttlingen",
    community_code: "4590",
    latitude: "47.5805",
    longitude: "9.0055",
  },
  {
    country_code: "CH",
    zipcode: "8553",
    place: "Harenwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttlingen",
    community_code: "4590",
    latitude: "47.5704",
    longitude: "9.0004",
  },
  {
    country_code: "CH",
    zipcode: "8553",
    place: "Mettendorf TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttlingen",
    community_code: "4590",
    latitude: "47.578",
    longitude: "8.9808",
  },
  {
    country_code: "CH",
    zipcode: "8553",
    place: "Hüttlingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Hüttlingen",
    community_code: "4590",
    latitude: "47.578",
    longitude: "8.9808",
  },
  {
    country_code: "CH",
    zipcode: "8555",
    place: "Müllheim Dorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Müllheim",
    community_code: "4831",
    latitude: "47.6019",
    longitude: "9.0036",
  },
  {
    country_code: "CH",
    zipcode: "9507",
    place: "Stettfurt",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Stettfurt",
    community_code: "4606",
    latitude: "47.5259",
    longitude: "8.9532",
  },
  {
    country_code: "CH",
    zipcode: "9548",
    place: "Matzingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Frauenfeld District",
    province_code: "2012",
    community: "Matzingen",
    community_code: "4591",
    latitude: "47.5196",
    longitude: "8.9337",
  },
  {
    country_code: "CH",
    zipcode: "8268",
    place: "Mannenbach-Salenstein",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Salenstein",
    community_code: "4851",
    latitude: "47.6727",
    longitude: "9.0535",
  },
  {
    country_code: "CH",
    zipcode: "8268",
    place: "Salenstein",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Salenstein",
    community_code: "4851",
    latitude: "47.6687",
    longitude: "9.0588",
  },
  {
    country_code: "CH",
    zipcode: "8269",
    place: "Fruthwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Salenstein",
    community_code: "4851",
    latitude: "47.6617",
    longitude: "9.0674",
  },
  {
    country_code: "CH",
    zipcode: "8272",
    place: "Ermatingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Ermatingen",
    community_code: "4646",
    latitude: "47.6706",
    longitude: "9.0857",
  },
  {
    country_code: "CH",
    zipcode: "8273",
    place: "Triboltingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Ermatingen",
    community_code: "4646",
    latitude: "47.6621",
    longitude: "9.1118",
  },
  {
    country_code: "CH",
    zipcode: "8274",
    place: "Gottlieben",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Gottlieben",
    community_code: "4651",
    latitude: "47.6645",
    longitude: "9.1274",
  },
  {
    country_code: "CH",
    zipcode: "8274",
    place: "Tägerwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Tägerwilen",
    community_code: "4696",
    latitude: "47.657",
    longitude: "9.14",
  },
  {
    country_code: "CH",
    zipcode: "8280",
    place: "Kreuzlingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kreuzlingen",
    community_code: "4671",
    latitude: "47.6505",
    longitude: "9.175",
  },
  {
    country_code: "CH",
    zipcode: "8285",
    place: "Kreuzlingen Ifolor",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kreuzlingen",
    community_code: "4671",
    latitude: "47.6398",
    longitude: "9.1727",
  },
  {
    country_code: "CH",
    zipcode: "8558",
    place: "Raperswilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Raperswilen",
    community_code: "4846",
    latitude: "47.6324",
    longitude: "9.0426",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Sonterswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6222",
    longitude: "9.0795",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Wäldi",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6342",
    longitude: "9.095",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Engwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6185",
    longitude: "9.0969",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Hefenhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6171",
    longitude: "9.0649",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Lipperswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.617",
    longitude: "9.057",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Hattenhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6258",
    longitude: "9.068",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Gunterswilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Wäldi",
    community_code: "4701",
    latitude: "47.6356",
    longitude: "9.0769",
  },
  {
    country_code: "CH",
    zipcode: "8565",
    place: "Hugelshofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.5999",
    longitude: "9.1165",
  },
  {
    country_code: "CH",
    zipcode: "8566",
    place: "Neuwilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6214",
    longitude: "9.1354",
  },
  {
    country_code: "CH",
    zipcode: "8566",
    place: "Dotnacht",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6016",
    longitude: "9.1424",
  },
  {
    country_code: "CH",
    zipcode: "8566",
    place: "Lippoldswilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6123",
    longitude: "9.1127",
  },
  {
    country_code: "CH",
    zipcode: "8566",
    place: "Ellighausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6149",
    longitude: "9.1369",
  },
  {
    country_code: "CH",
    zipcode: "8573",
    place: "Altishausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6026",
    longitude: "9.1719",
  },
  {
    country_code: "CH",
    zipcode: "8573",
    place: "Siegershausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6112",
    longitude: "9.1684",
  },
  {
    country_code: "CH",
    zipcode: "8573",
    place: "Alterswilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Kemmental",
    community_code: "4666",
    latitude: "47.6096",
    longitude: "9.1541",
  },
  {
    country_code: "CH",
    zipcode: "8574",
    place: "Oberhofen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Lengwil",
    community_code: "4683",
    latitude: "47.6252",
    longitude: "9.1924",
  },
  {
    country_code: "CH",
    zipcode: "8574",
    place: "Dettighofen (Lengwil)",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Lengwil",
    community_code: "4683",
    latitude: "47.6219",
    longitude: "9.2016",
  },
  {
    country_code: "CH",
    zipcode: "8574",
    place: "Illighausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Lengwil",
    community_code: "4683",
    latitude: "47.6031",
    longitude: "9.2098",
  },
  {
    country_code: "CH",
    zipcode: "8574",
    place: "Lengwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Lengwil",
    community_code: "4683",
    latitude: "47.6252",
    longitude: "9.1924",
  },
  {
    country_code: "CH",
    zipcode: "8574",
    place: "Lengwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Lengwil",
    community_code: "4683",
    latitude: "47.6252",
    longitude: "9.1924",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Langrickenbach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Langrickenbach",
    community_code: "4681",
    latitude: "47.5935",
    longitude: "9.2473",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Zuben",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Langrickenbach",
    community_code: "4681",
    latitude: "47.6102",
    longitude: "9.2379",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Herrenhof",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Langrickenbach",
    community_code: "4681",
    latitude: "47.5971",
    longitude: "9.2447",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Schönenbaumgarten",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Langrickenbach",
    community_code: "4681",
    latitude: "47.6139",
    longitude: "9.2341",
  },
  {
    country_code: "CH",
    zipcode: "8594",
    place: "Güttingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Güttingen",
    community_code: "4656",
    latitude: "47.6035",
    longitude: "9.2874",
  },
  {
    country_code: "CH",
    zipcode: "8595",
    place: "Altnau",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Altnau",
    community_code: "4641",
    latitude: "47.6105",
    longitude: "9.2616",
  },
  {
    country_code: "CH",
    zipcode: "8596",
    place: "Scherzingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Münsterlingen",
    community_code: "4691",
    latitude: "47.6312",
    longitude: "9.2248",
  },
  {
    country_code: "CH",
    zipcode: "8596",
    place: "Münsterlingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Münsterlingen",
    community_code: "4691",
    latitude: "47.632",
    longitude: "9.2327",
  },
  {
    country_code: "CH",
    zipcode: "8597",
    place: "Landschlacht",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Münsterlingen",
    community_code: "4691",
    latitude: "47.6276",
    longitude: "9.2455",
  },
  {
    country_code: "CH",
    zipcode: "8598",
    place: "Bottighofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Kreuzlingen District",
    province_code: "2013",
    community: "Bottighofen",
    community_code: "4643",
    latitude: "47.6364",
    longitude: "9.2088",
  },
  {
    country_code: "CH",
    zipcode: "8355",
    place: "Aadorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.492",
    longitude: "8.901",
  },
  {
    country_code: "CH",
    zipcode: "8356",
    place: "Ettenhausen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.479",
    longitude: "8.9008",
  },
  {
    country_code: "CH",
    zipcode: "8357",
    place: "Guntershausen b. Aadorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.4755",
    longitude: "8.9182",
  },
  {
    country_code: "CH",
    zipcode: "8360",
    place: "Eschlikon TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Eschlikon",
    community_code: "4724",
    latitude: "47.4636",
    longitude: "8.9638",
  },
  {
    country_code: "CH",
    zipcode: "8360",
    place: "Wallenwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Eschlikon",
    community_code: "4724",
    latitude: "47.4582",
    longitude: "8.9577",
  },
  {
    country_code: "CH",
    zipcode: "8362",
    place: "Balterswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Bichelsee-Balterswil",
    community_code: "4721",
    latitude: "47.4541",
    longitude: "8.9367",
  },
  {
    country_code: "CH",
    zipcode: "8363",
    place: "Bichelsee",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Bichelsee-Balterswil",
    community_code: "4721",
    latitude: "47.449",
    longitude: "8.9243",
  },
  {
    country_code: "CH",
    zipcode: "8370",
    place: "Sirnach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Sirnach",
    community_code: "4761",
    latitude: "47.4622",
    longitude: "8.9976",
  },
  {
    country_code: "CH",
    zipcode: "8371",
    place: "Busswil TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Sirnach",
    community_code: "4761",
    latitude: "47.4503",
    longitude: "9.0156",
  },
  {
    country_code: "CH",
    zipcode: "8372",
    place: "Wiezikon b. Sirnach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Sirnach",
    community_code: "4761",
    latitude: "47.4513",
    longitude: "8.9875",
  },
  {
    country_code: "CH",
    zipcode: "8374",
    place: "Oberwangen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Fischingen",
    community_code: "4726",
    latitude: "47.4286",
    longitude: "8.9679",
  },
  {
    country_code: "CH",
    zipcode: "8374",
    place: "Dussnang",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Fischingen",
    community_code: "4726",
    latitude: "47.4314",
    longitude: "8.9631",
  },
  {
    country_code: "CH",
    zipcode: "8376",
    place: "Fischingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Fischingen",
    community_code: "4726",
    latitude: "47.4142",
    longitude: "8.9686",
  },
  {
    country_code: "CH",
    zipcode: "8376",
    place: "Au TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Fischingen",
    community_code: "4726",
    latitude: "47.3984",
    longitude: "8.9542",
  },
  {
    country_code: "CH",
    zipcode: "8522",
    place: "Häuslenen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.5277",
    longitude: "8.904",
  },
  {
    country_code: "CH",
    zipcode: "8522",
    place: "Aawangen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.5133",
    longitude: "8.9008",
  },
  {
    country_code: "CH",
    zipcode: "9502",
    place: "Braunau",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Braunau",
    community_code: "4723",
    latitude: "47.5011",
    longitude: "9.0696",
  },
  {
    country_code: "CH",
    zipcode: "9506",
    place: "Lommis",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Lommis",
    community_code: "4741",
    latitude: "47.5173",
    longitude: "8.9967",
  },
  {
    country_code: "CH",
    zipcode: "9508",
    place: "Weingarten-Kalthäusern",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Lommis",
    community_code: "4741",
    latitude: "47.526",
    longitude: "8.9867",
  },
  {
    country_code: "CH",
    zipcode: "9532",
    place: "Rickenbach b. Wil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Rickenbach (TG)",
    community_code: "4751",
    latitude: "47.4494",
    longitude: "9.0484",
  },
  {
    country_code: "CH",
    zipcode: "9535",
    place: "Wilen b. Wil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Wilen (TG)",
    community_code: "4786",
    latitude: "47.4514",
    longitude: "9.0342",
  },
  {
    country_code: "CH",
    zipcode: "9542",
    place: "Münchwilen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Münchwilen (TG)",
    community_code: "4746",
    latitude: "47.4772",
    longitude: "8.9968",
  },
  {
    country_code: "CH",
    zipcode: "9543",
    place: "St. Margarethen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Münchwilen (TG)",
    community_code: "4746",
    latitude: "47.4893",
    longitude: "9.0018",
  },
  {
    country_code: "CH",
    zipcode: "9545",
    place: "Wängi",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Wängi",
    community_code: "4781",
    latitude: "47.4965",
    longitude: "8.9533",
  },
  {
    country_code: "CH",
    zipcode: "9546",
    place: "Tuttwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Wängi",
    community_code: "4781",
    latitude: "47.4835",
    longitude: "8.9422",
  },
  {
    country_code: "CH",
    zipcode: "9547",
    place: "Wittenwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Aadorf",
    community_code: "4551",
    latitude: "47.5048",
    longitude: "8.9231",
  },
  {
    country_code: "CH",
    zipcode: "9553",
    place: "Bettwiesen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Bettwiesen",
    community_code: "4716",
    latitude: "47.4972",
    longitude: "9.0285",
  },
  {
    country_code: "CH",
    zipcode: "9554",
    place: "Tägerschen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Tobel-Tägerschen",
    community_code: "4776",
    latitude: "47.5091",
    longitude: "9.0321",
  },
  {
    country_code: "CH",
    zipcode: "9555",
    place: "Tobel",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Tobel-Tägerschen",
    community_code: "4776",
    latitude: "47.515",
    longitude: "9.0336",
  },
  {
    country_code: "CH",
    zipcode: "9573",
    place: "Littenheid",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Münchwilen District",
    province_code: "2014",
    community: "Sirnach",
    community_code: "4761",
    latitude: "47.442",
    longitude: "9.0104",
  },
  {
    country_code: "CH",
    zipcode: "8514",
    place: "Amlikon-Bissegg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Amlikon-Bissegg",
    community_code: "4881",
    latitude: "47.5619",
    longitude: "9.0353",
  },
  {
    country_code: "CH",
    zipcode: "8554",
    place: "Bonau",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.5839",
    longitude: "9.0456",
  },
  {
    country_code: "CH",
    zipcode: "8554",
    place: "Müllheim-Wigoltingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.5839",
    longitude: "9.0456",
  },
  {
    country_code: "CH",
    zipcode: "8556",
    place: "Lamperswil TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.6117",
    longitude: "9.038",
  },
  {
    country_code: "CH",
    zipcode: "8556",
    place: "Engwang",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.6015",
    longitude: "9.0555",
  },
  {
    country_code: "CH",
    zipcode: "8556",
    place: "Illhart",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.6213",
    longitude: "9.0395",
  },
  {
    country_code: "CH",
    zipcode: "8556",
    place: "Wigoltingen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.5977",
    longitude: "9.0314",
  },
  {
    country_code: "CH",
    zipcode: "8560",
    place: "Märstetten",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Märstetten",
    community_code: "4941",
    latitude: "47.5925",
    longitude: "9.0685",
  },
  {
    country_code: "CH",
    zipcode: "8561",
    place: "Ottoberg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Märstetten",
    community_code: "4941",
    latitude: "47.5876",
    longitude: "9.0847",
  },
  {
    country_code: "CH",
    zipcode: "8564",
    place: "Wagerswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wigoltingen",
    community_code: "4951",
    latitude: "47.609",
    longitude: "9.0651",
  },
  {
    country_code: "CH",
    zipcode: "8570",
    place: "Weinfelden",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Weinfelden",
    community_code: "4946",
    latitude: "47.5667",
    longitude: "9.1",
  },
  {
    country_code: "CH",
    zipcode: "8572",
    place: "Berg TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5788",
    longitude: "9.1664",
  },
  {
    country_code: "CH",
    zipcode: "8572",
    place: "Guntershausen b. Berg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5835",
    longitude: "9.1743",
  },
  {
    country_code: "CH",
    zipcode: "8572",
    place: "Graltshausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5972",
    longitude: "9.1836",
  },
  {
    country_code: "CH",
    zipcode: "8572",
    place: "Andhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5794",
    longitude: "9.1807",
  },
  {
    country_code: "CH",
    zipcode: "8572",
    place: "Berg TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5788",
    longitude: "9.1664",
  },
  {
    country_code: "CH",
    zipcode: "8575",
    place: "Istighofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bürglen (TG)",
    community_code: "4911",
    latitude: "47.5423",
    longitude: "9.1499",
  },
  {
    country_code: "CH",
    zipcode: "8575",
    place: "Bürglen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bürglen (TG)",
    community_code: "4911",
    latitude: "47.5492",
    longitude: "9.1495",
  },
  {
    country_code: "CH",
    zipcode: "8576",
    place: "Mauren TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Berg (TG)",
    community_code: "4891",
    latitude: "47.5675",
    longitude: "9.1566",
  },
  {
    country_code: "CH",
    zipcode: "8577",
    place: "Schönholzerswilen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Schönholzerswilen",
    community_code: "4756",
    latitude: "47.517",
    longitude: "9.1409",
  },
  {
    country_code: "CH",
    zipcode: "8583",
    place: "Götighofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Sulgen",
    community_code: "4506",
    latitude: "47.536",
    longitude: "9.2173",
  },
  {
    country_code: "CH",
    zipcode: "8583",
    place: "Sulgen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Sulgen",
    community_code: "4506",
    latitude: "47.5397",
    longitude: "9.1859",
  },
  {
    country_code: "CH",
    zipcode: "8583",
    place: "Donzhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Sulgen",
    community_code: "4506",
    latitude: "47.5555",
    longitude: "9.1974",
  },
  {
    country_code: "CH",
    zipcode: "8584",
    place: "Leimbach TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bürglen (TG)",
    community_code: "4911",
    latitude: "47.5611",
    longitude: "9.1881",
  },
  {
    country_code: "CH",
    zipcode: "8584",
    place: "Opfershofen TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bürglen (TG)",
    community_code: "4911",
    latitude: "47.5609",
    longitude: "9.1753",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Happerswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5791",
    longitude: "9.2241",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Mattwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5791",
    longitude: "9.2077",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Klarsreuti",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5887",
    longitude: "9.214",
  },
  {
    country_code: "CH",
    zipcode: "8585",
    place: "Birwinken",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5432",
    longitude: "9.148",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Kümmertshausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.566",
    longitude: "9.2416",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Erlen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.5481",
    longitude: "9.2341",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Andwil TG",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5683",
    longitude: "9.2171",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Engishofen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.5586",
    longitude: "9.2528",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Buchackern",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.5399",
    longitude: "9.2339",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Ennetaach",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.5504",
    longitude: "9.2172",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Riedt b. Erlen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Erlen",
    community_code: "4476",
    latitude: "47.5552",
    longitude: "9.2328",
  },
  {
    country_code: "CH",
    zipcode: "8586",
    place: "Buch b. Kümmertshausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Birwinken",
    community_code: "4901",
    latitude: "47.5765",
    longitude: "9.2148",
  },
  {
    country_code: "CH",
    zipcode: "8588",
    place: "Zihlschlacht",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Zihlschlacht-Sitterdorf",
    community_code: "4511",
    latitude: "47.5207",
    longitude: "9.2574",
  },
  {
    country_code: "CH",
    zipcode: "8589",
    place: "Sitterdorf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Zihlschlacht-Sitterdorf",
    community_code: "4511",
    latitude: "47.5026",
    longitude: "9.2478",
  },
  {
    country_code: "CH",
    zipcode: "9213",
    place: "Hauptwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Hauptwil-Gottshaus",
    community_code: "4486",
    latitude: "47.4801",
    longitude: "9.2507",
  },
  {
    country_code: "CH",
    zipcode: "9214",
    place: "Kradolf",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Kradolf-Schönenberg",
    community_code: "4501",
    latitude: "47.525",
    longitude: "9.2014",
  },
  {
    country_code: "CH",
    zipcode: "9215",
    place: "Buhwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Kradolf-Schönenberg",
    community_code: "4501",
    latitude: "47.5285",
    longitude: "9.1676",
  },
  {
    country_code: "CH",
    zipcode: "9215",
    place: "Schönenberg an der Thur",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Kradolf-Schönenberg",
    community_code: "4501",
    latitude: "47.5214",
    longitude: "9.1986",
  },
  {
    country_code: "CH",
    zipcode: "9216",
    place: "Hohentannen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Hohentannen",
    community_code: "4495",
    latitude: "47.5304",
    longitude: "9.2222",
  },
  {
    country_code: "CH",
    zipcode: "9216",
    place: "Heldswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Hohentannen",
    community_code: "4495",
    latitude: "47.5304",
    longitude: "9.2222",
  },
  {
    country_code: "CH",
    zipcode: "9217",
    place: "Neukirch an der Thur",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Kradolf-Schönenberg",
    community_code: "4501",
    latitude: "47.5122",
    longitude: "9.174",
  },
  {
    country_code: "CH",
    zipcode: "9220",
    place: "Bischofszell",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bischofszell",
    community_code: "4471",
    latitude: "47.4957",
    longitude: "9.2388",
  },
  {
    country_code: "CH",
    zipcode: "9223",
    place: "Schweizersholz",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bischofszell",
    community_code: "4471",
    latitude: "47.5028",
    longitude: "9.1963",
  },
  {
    country_code: "CH",
    zipcode: "9223",
    place: "Halden",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bischofszell",
    community_code: "4471",
    latitude: "47.5055",
    longitude: "9.2106",
  },
  {
    country_code: "CH",
    zipcode: "9225",
    place: "St. Pelagiberg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Hauptwil-Gottshaus",
    community_code: "4486",
    latitude: "47.4892",
    longitude: "9.3025",
  },
  {
    country_code: "CH",
    zipcode: "9225",
    place: "Wilen (Gottshaus)",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Hauptwil-Gottshaus",
    community_code: "4486",
    latitude: "47.495",
    longitude: "9.2846",
  },
  {
    country_code: "CH",
    zipcode: "9503",
    place: "Stehrenberg",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5234",
    longitude: "9.0897",
  },
  {
    country_code: "CH",
    zipcode: "9503",
    place: "Lanterswil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.522",
    longitude: "9.0971",
  },
  {
    country_code: "CH",
    zipcode: "9504",
    place: "Friltschen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.54",
    longitude: "9.0863",
  },
  {
    country_code: "CH",
    zipcode: "9514",
    place: "Wuppenau",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wuppenau",
    community_code: "4791",
    latitude: "47.4963",
    longitude: "9.109",
  },
  {
    country_code: "CH",
    zipcode: "9515",
    place: "Hosenruck",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Wuppenau",
    community_code: "4791",
    latitude: "47.4917",
    longitude: "9.1239",
  },
  {
    country_code: "CH",
    zipcode: "9517",
    place: "Mettlen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5308",
    longitude: "9.1233",
  },
  {
    country_code: "CH",
    zipcode: "9556",
    place: "Affeltrangen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Affeltrangen",
    community_code: "4711",
    latitude: "47.5258",
    longitude: "9.0331",
  },
  {
    country_code: "CH",
    zipcode: "9556",
    place: "Zezikon",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Affeltrangen",
    community_code: "4711",
    latitude: "47.5365",
    longitude: "9.0237",
  },
  {
    country_code: "CH",
    zipcode: "9562",
    place: "Märwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Affeltrangen",
    community_code: "4711",
    latitude: "47.533",
    longitude: "9.0747",
  },
  {
    country_code: "CH",
    zipcode: "9562",
    place: "Buch b. Märwil",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Affeltrangen",
    community_code: "4711",
    latitude: "47.533",
    longitude: "9.0747",
  },
  {
    country_code: "CH",
    zipcode: "9565",
    place: "Rothenhausen",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5512",
    longitude: "9.1026",
  },
  {
    country_code: "CH",
    zipcode: "9565",
    place: "Oberbussnang",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5511",
    longitude: "9.0754",
  },
  {
    country_code: "CH",
    zipcode: "9565",
    place: "Schmidshof",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5445",
    longitude: "9.0553",
  },
  {
    country_code: "CH",
    zipcode: "9565",
    place: "Bussnang",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5568",
    longitude: "9.0816",
  },
  {
    country_code: "CH",
    zipcode: "9565",
    place: "Oppikon",
    state: "Kanton Thurgau",
    state_code: "TG",
    province: "Weinfelden District",
    province_code: "2015",
    community: "Bussnang",
    community_code: "4921",
    latitude: "47.5521",
    longitude: "9.062",
  },
  {
    country_code: "CH",
    zipcode: "6500",
    place: "Bellinzona",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1928",
    longitude: "9.017",
  },
  {
    country_code: "CH",
    zipcode: "6501",
    place: "Bellinzona",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6503",
    place: "Bellinzona",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1928",
    longitude: "9.017",
  },
  {
    country_code: "CH",
    zipcode: "6512",
    place: "Giubiasco",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6513",
    place: "Monte Carasso",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6514",
    place: "Sementina",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6515",
    place: "Gudo",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6517",
    place: "Arbedo",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Arbedo-Castione",
    community_code: "5001",
    latitude: "46.2146",
    longitude: "9.0455",
  },
  {
    country_code: "CH",
    zipcode: "6518",
    place: "Gorduno",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.2163",
    longitude: "9.0308",
  },
  {
    country_code: "CH",
    zipcode: "6523",
    place: "Preonzo",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6524",
    place: "Moleno",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6525",
    place: "Gnosca",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6528",
    place: "Camorino",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6532",
    place: "Castione",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Arbedo-Castione",
    community_code: "5001",
    latitude: "46.2281",
    longitude: "9.0433",
  },
  {
    country_code: "CH",
    zipcode: "6533",
    place: "Lumino",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Lumino",
    community_code: "5010",
    latitude: "46.2302",
    longitude: "9.0642",
  },
  {
    country_code: "CH",
    zipcode: "6582",
    place: "Pianezzo",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6583",
    place: "S. Antonio (Val Morobbia)",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6584",
    place: "Carena",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1999",
    longitude: "9.0225",
  },
  {
    country_code: "CH",
    zipcode: "6592",
    place: "S. Antonino",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Sant'Antonino",
    community_code: "5017",
    latitude: "46.1524",
    longitude: "8.9799",
  },
  {
    country_code: "CH",
    zipcode: "6593",
    place: "Cadenazzo",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Cadenazzo",
    community_code: "5003",
    latitude: "46.1517",
    longitude: "8.9472",
  },
  {
    country_code: "CH",
    zipcode: "6599",
    place: "Robasacco",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Cadenazzo",
    community_code: "5003",
    latitude: "46.1427",
    longitude: "8.9428",
  },
  {
    country_code: "CH",
    zipcode: "6702",
    place: "Claro",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Bellinzona",
    community_code: "5002",
    latitude: "46.1904",
    longitude: "9.0223",
  },
  {
    country_code: "CH",
    zipcode: "6809",
    place: "Medeglia",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Comunanza Cadenazzo/Montecener",
    community_code: "5391",
    latitude: "46.1904",
    longitude: "9.0223",
  },
  {
    country_code: "CH",
    zipcode: "6810",
    place: "Isone",
    state: "Ticino",
    state_code: "TI",
    province: "Bellinzona District",
    province_code: "2101",
    community: "Isone",
    community_code: "5009",
    latitude: "46.1286",
    longitude: "8.9854",
  },
  {
    country_code: "CH",
    zipcode: "6713",
    place: "Malvaglia",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Serravalle",
    community_code: "5050",
    latitude: "46.4059",
    longitude: "8.9819",
  },
  {
    country_code: "CH",
    zipcode: "6714",
    place: "Semione",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Serravalle",
    community_code: "5050",
    latitude: "46.4001",
    longitude: "8.9674",
  },
  {
    country_code: "CH",
    zipcode: "6715",
    place: "Dongio",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4378",
    longitude: "8.9553",
  },
  {
    country_code: "CH",
    zipcode: "6716",
    place: "Leontica",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4609",
    longitude: "8.9233",
  },
  {
    country_code: "CH",
    zipcode: "6716",
    place: "Lottigna",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4687",
    longitude: "8.9431",
  },
  {
    country_code: "CH",
    zipcode: "6716",
    place: "Acquarossa",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4547",
    longitude: "8.9426",
  },
  {
    country_code: "CH",
    zipcode: "6717",
    place: "Torre",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.489",
    longitude: "8.9533",
  },
  {
    country_code: "CH",
    zipcode: "6717",
    place: "Dangio",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.4955",
    longitude: "8.9542",
  },
  {
    country_code: "CH",
    zipcode: "6718",
    place: "Camperio",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.5239",
    longitude: "8.9077",
  },
  {
    country_code: "CH",
    zipcode: "6718",
    place: "Olivone",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.5296",
    longitude: "8.9436",
  },
  {
    country_code: "CH",
    zipcode: "6719",
    place: "Aquila",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.503",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "6719",
    place: "Aquila",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.503",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "6719",
    place: "Aquila",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.503",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "6720",
    place: "Ghirone",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.5613",
    longitude: "8.9441",
  },
  {
    country_code: "CH",
    zipcode: "6720",
    place: "Campo (Blenio)",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Blenio",
    community_code: "5049",
    latitude: "46.5567",
    longitude: "8.9364",
  },
  {
    country_code: "CH",
    zipcode: "6721",
    place: "Motto (Blenio)",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4286",
    longitude: "8.9716",
  },
  {
    country_code: "CH",
    zipcode: "6721",
    place: "Ludiano",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Serravalle",
    community_code: "5050",
    latitude: "46.42",
    longitude: "8.971",
  },
  {
    country_code: "CH",
    zipcode: "6722",
    place: "Corzoneso",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4476",
    longitude: "8.9352",
  },
  {
    country_code: "CH",
    zipcode: "6723",
    place: "Castro",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4718",
    longitude: "8.9278",
  },
  {
    country_code: "CH",
    zipcode: "6723",
    place: "Marolta",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4801",
    longitude: "8.9225",
  },
  {
    country_code: "CH",
    zipcode: "6723",
    place: "Prugiasco",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4616",
    longitude: "8.9326",
  },
  {
    country_code: "CH",
    zipcode: "6724",
    place: "Ponto Valentino",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4821",
    longitude: "8.9346",
  },
  {
    country_code: "CH",
    zipcode: "6724",
    place: "Largario",
    state: "Ticino",
    state_code: "TI",
    province: "Blenio District",
    province_code: "2102",
    community: "Acquarossa",
    community_code: "5048",
    latitude: "46.4945",
    longitude: "8.941",
  },
  {
    country_code: "CH",
    zipcode: "6742",
    place: "Pollegio",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Pollegio",
    community_code: "5077",
    latitude: "46.3649",
    longitude: "8.9484",
  },
  {
    country_code: "CH",
    zipcode: "6743",
    place: "Bodio TI",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Bodio",
    community_code: "5064",
    latitude: "46.3781",
    longitude: "8.9099",
  },
  {
    country_code: "CH",
    zipcode: "6744",
    place: "Personico",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Personico",
    community_code: "5076",
    latitude: "46.372",
    longitude: "8.9161",
  },
  {
    country_code: "CH",
    zipcode: "6745",
    place: "Giornico",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Giornico",
    community_code: "5073",
    latitude: "46.4014",
    longitude: "8.8737",
  },
  {
    country_code: "CH",
    zipcode: "6746",
    place: "Calonico",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4516",
    longitude: "8.8424",
  },
  {
    country_code: "CH",
    zipcode: "6746",
    place: "Lavorgo",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4426",
    longitude: "8.8395",
  },
  {
    country_code: "CH",
    zipcode: "6746",
    place: "Nivo",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4372",
    longitude: "8.843",
  },
  {
    country_code: "CH",
    zipcode: "6747",
    place: "Chironico",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4226",
    longitude: "8.8431",
  },
  {
    country_code: "CH",
    zipcode: "6748",
    place: "Anzonico",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4324",
    longitude: "8.8618",
  },
  {
    country_code: "CH",
    zipcode: "6749",
    place: "Sobrio",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4511",
    longitude: "8.8123",
  },
  {
    country_code: "CH",
    zipcode: "6749",
    place: "Cavagnago",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4152",
    longitude: "8.8815",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Rossura",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4753",
    longitude: "8.8229",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Molare",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4874",
    longitude: "8.8315",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Calpiogna",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4863",
    longitude: "8.8058",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Carì",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4948",
    longitude: "8.8209",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Faido",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.477",
    longitude: "8.8013",
  },
  {
    country_code: "CH",
    zipcode: "6760",
    place: "Campello",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4842",
    longitude: "8.8165",
  },
  {
    country_code: "CH",
    zipcode: "6763",
    place: "Osco",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.4937",
    longitude: "8.7819",
  },
  {
    country_code: "CH",
    zipcode: "6763",
    place: "Mairengo",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.49",
    longitude: "8.7886",
  },
  {
    country_code: "CH",
    zipcode: "6764",
    place: "Chiggiogna",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.467",
    longitude: "8.8217",
  },
  {
    country_code: "CH",
    zipcode: "6764",
    place: "Chiggiogna",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Faido",
    community_code: "5072",
    latitude: "46.467",
    longitude: "8.8217",
  },
  {
    country_code: "CH",
    zipcode: "6772",
    place: "Rodi-Fiesso",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Prato (Leventina)",
    community_code: "5078",
    latitude: "46.4891",
    longitude: "8.7412",
  },
  {
    country_code: "CH",
    zipcode: "6773",
    place: "Prato (Leventina)",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Prato (Leventina)",
    community_code: "5078",
    latitude: "46.4824",
    longitude: "8.757",
  },
  {
    country_code: "CH",
    zipcode: "6774",
    place: "Dalpe",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Dalpe",
    community_code: "5071",
    latitude: "46.4748",
    longitude: "8.7746",
  },
  {
    country_code: "CH",
    zipcode: "6775",
    place: "Ambrì",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Quinto",
    community_code: "5079",
    latitude: "46.5105",
    longitude: "8.6966",
  },
  {
    country_code: "CH",
    zipcode: "6776",
    place: "Piotta",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Quinto",
    community_code: "5079",
    latitude: "46.5146",
    longitude: "8.6738",
  },
  {
    country_code: "CH",
    zipcode: "6777",
    place: "Varenzo",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Quinto",
    community_code: "5079",
    latitude: "46.5033",
    longitude: "8.726",
  },
  {
    country_code: "CH",
    zipcode: "6777",
    place: "Quinto",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Quinto",
    community_code: "5079",
    latitude: "46.5108",
    longitude: "8.7111",
  },
  {
    country_code: "CH",
    zipcode: "6780",
    place: "Madrano",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Airolo",
    community_code: "5061",
    latitude: "46.5275",
    longitude: "8.6293",
  },
  {
    country_code: "CH",
    zipcode: "6780",
    place: "Airolo",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Airolo",
    community_code: "5061",
    latitude: "46.5286",
    longitude: "8.6119",
  },
  {
    country_code: "CH",
    zipcode: "6781",
    place: "Bedretto",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Bedretto",
    community_code: "5063",
    latitude: "46.506",
    longitude: "8.5114",
  },
  {
    country_code: "CH",
    zipcode: "6781",
    place: "Villa Bedretto",
    state: "Ticino",
    state_code: "TI",
    province: "Leventina District",
    province_code: "2103",
    community: "Bedretto",
    community_code: "5063",
    latitude: "46.5102",
    longitude: "8.5246",
  },
  {
    country_code: "CH",
    zipcode: "6516",
    place: "Cugnasco",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Cugnasco-Gerra",
    community_code: "5138",
    latitude: "46.1747",
    longitude: "8.9168",
  },
  {
    country_code: "CH",
    zipcode: "6571",
    place: "Indemini",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.0944",
    longitude: "8.8257",
  },
  {
    country_code: "CH",
    zipcode: "6572",
    place: "Quartino",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1515",
    longitude: "8.8958",
  },
  {
    country_code: "CH",
    zipcode: "6573",
    place: "Magadino",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1489",
    longitude: "8.8561",
  },
  {
    country_code: "CH",
    zipcode: "6574",
    place: "Vira (Gambarogno)",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1433",
    longitude: "8.842",
  },
  {
    country_code: "CH",
    zipcode: "6575",
    place: "S. Nazzaro",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1316",
    longitude: "8.8026",
  },
  {
    country_code: "CH",
    zipcode: "6575",
    place: "Vairano",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1322",
    longitude: "8.8109",
  },
  {
    country_code: "CH",
    zipcode: "6576",
    place: "Gerra (Gambarogno)",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1231",
    longitude: "8.7903",
  },
  {
    country_code: "CH",
    zipcode: "6577",
    place: "Ranzo",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1146",
    longitude: "8.7737",
  },
  {
    country_code: "CH",
    zipcode: "6578",
    place: "Caviano",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1071",
    longitude: "8.766",
  },
  {
    country_code: "CH",
    zipcode: "6579",
    place: "Piazzogna",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1356",
    longitude: "8.8237",
  },
  {
    country_code: "CH",
    zipcode: "6594",
    place: "Contone",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gambarogno",
    community_code: "5398",
    latitude: "46.1509",
    longitude: "8.9262",
  },
  {
    country_code: "CH",
    zipcode: "6595",
    place: "Riazzino",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Lavertezzo",
    community_code: "5112",
    latitude: "46.1747",
    longitude: "8.9",
  },
  {
    country_code: "CH",
    zipcode: "6596",
    place: "Gordola",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Gordola",
    community_code: "5108",
    latitude: "46.1826",
    longitude: "8.8666",
  },
  {
    country_code: "CH",
    zipcode: "6597",
    place: "Agarone",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Cugnasco-Gerra",
    community_code: "5138",
    latitude: "46.1784",
    longitude: "8.9063",
  },
  {
    country_code: "CH",
    zipcode: "6598",
    place: "Tenero",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Tenero-Contra",
    community_code: "5131",
    latitude: "46.1814",
    longitude: "8.851",
  },
  {
    country_code: "CH",
    zipcode: "6600",
    place: "Locarno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1709",
    longitude: "8.7995",
  },
  {
    country_code: "CH",
    zipcode: "6600",
    place: "Muralto",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Muralto",
    community_code: "5120",
    latitude: "46.1732",
    longitude: "8.8022",
  },
  {
    country_code: "CH",
    zipcode: "6600",
    place: "Locarno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1709",
    longitude: "8.7995",
  },
  {
    country_code: "CH",
    zipcode: "6600",
    place: "Solduno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1705",
    longitude: "8.7758",
  },
  {
    country_code: "CH",
    zipcode: "6601",
    place: "Locarno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1647",
    longitude: "8.8861",
  },
  {
    country_code: "CH",
    zipcode: "6602",
    place: "Muralto",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Muralto",
    community_code: "5120",
    latitude: "46.174",
    longitude: "8.8036",
  },
  {
    country_code: "CH",
    zipcode: "6604",
    place: "Locarno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1647",
    longitude: "8.8861",
  },
  {
    country_code: "CH",
    zipcode: "6605",
    place: "Locarno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Locarno",
    community_code: "5113",
    latitude: "46.1709",
    longitude: "8.7995",
  },
  {
    country_code: "CH",
    zipcode: "6611",
    place: "Mosogno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5866",
  },
  {
    country_code: "CH",
    zipcode: "6611",
    place: "Crana",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.204",
    longitude: "8.6098",
  },
  {
    country_code: "CH",
    zipcode: "6611",
    place: "Gresso",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2249",
    longitude: "8.6163",
  },
  {
    country_code: "CH",
    zipcode: "6612",
    place: "Ascona",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Ascona",
    community_code: "5091",
    latitude: "46.1545",
    longitude: "8.7733",
  },
  {
    country_code: "CH",
    zipcode: "6613",
    place: "Porto Ronco",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Ronco sopra Ascona",
    community_code: "5125",
    latitude: "46.1404",
    longitude: "8.7255",
  },
  {
    country_code: "CH",
    zipcode: "6614",
    place: "Brissago",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Brissago",
    community_code: "5097",
    latitude: "46.1201",
    longitude: "8.7118",
  },
  {
    country_code: "CH",
    zipcode: "6614",
    place: "Isole di Brissago",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Brissago",
    community_code: "5097",
    latitude: "46.1201",
    longitude: "8.7118",
  },
  {
    country_code: "CH",
    zipcode: "6616",
    place: "Losone",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Losone",
    community_code: "5115",
    latitude: "46.1687",
    longitude: "8.7593",
  },
  {
    country_code: "CH",
    zipcode: "6618",
    place: "Arcegno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Losone",
    community_code: "5115",
    latitude: "46.1615",
    longitude: "8.7421",
  },
  {
    country_code: "CH",
    zipcode: "6622",
    place: "Ronco sopra Ascona",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Ronco sopra Ascona",
    community_code: "5125",
    latitude: "46.1462",
    longitude: "8.7279",
  },
  {
    country_code: "CH",
    zipcode: "6631",
    place: "Corippo",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Corippo",
    community_code: "5102",
    latitude: "46.2359",
    longitude: "8.8407",
  },
  {
    country_code: "CH",
    zipcode: "6632",
    place: "Vogorno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Vogorno",
    community_code: "5135",
    latitude: "46.2228",
    longitude: "8.8583",
  },
  {
    country_code: "CH",
    zipcode: "6633",
    place: "Lavertezzo",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Lavertezzo",
    community_code: "5112",
    latitude: "46.2589",
    longitude: "8.8376",
  },
  {
    country_code: "CH",
    zipcode: "6634",
    place: "Brione (Verzasca)",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Brione (Verzasca)",
    community_code: "5095",
    latitude: "46.2953",
    longitude: "8.7917",
  },
  {
    country_code: "CH",
    zipcode: "6635",
    place: "Gerra (Verzasca)",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Cugnasco-Gerra",
    community_code: "5138",
    latitude: "46.3171",
    longitude: "8.8013",
  },
  {
    country_code: "CH",
    zipcode: "6636",
    place: "Frasco",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Frasco",
    community_code: "5105",
    latitude: "46.344",
    longitude: "8.7984",
  },
  {
    country_code: "CH",
    zipcode: "6637",
    place: "Sonogno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Sonogno",
    community_code: "5129",
    latitude: "46.3504",
    longitude: "8.7851",
  },
  {
    country_code: "CH",
    zipcode: "6644",
    place: "Orselina",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Orselina",
    community_code: "5121",
    latitude: "46.1801",
    longitude: "8.7998",
  },
  {
    country_code: "CH",
    zipcode: "6645",
    place: "Brione sopra Minusio",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Brione sopra Minusio",
    community_code: "5096",
    latitude: "46.1842",
    longitude: "8.8142",
  },
  {
    country_code: "CH",
    zipcode: "6646",
    place: "Contra",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Tenero-Contra",
    community_code: "5131",
    latitude: "46.188",
    longitude: "8.8407",
  },
  {
    country_code: "CH",
    zipcode: "6647",
    place: "Mergoscia",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Mergoscia",
    community_code: "5117",
    latitude: "46.2106",
    longitude: "8.8478",
  },
  {
    country_code: "CH",
    zipcode: "6648",
    place: "Minusio",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Minusio",
    community_code: "5118",
    latitude: "46.1777",
    longitude: "8.8147",
  },
  {
    country_code: "CH",
    zipcode: "6652",
    place: "Tegna",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Terre di Pedemonte",
    community_code: "5396",
    latitude: "46.1869",
    longitude: "8.7452",
  },
  {
    country_code: "CH",
    zipcode: "6653",
    place: "Verscio",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Terre di Pedemonte",
    community_code: "5396",
    latitude: "46.1848",
    longitude: "8.7322",
  },
  {
    country_code: "CH",
    zipcode: "6654",
    place: "Cavigliano",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Terre di Pedemonte",
    community_code: "5396",
    latitude: "46.1855",
    longitude: "8.7149",
  },
  {
    country_code: "CH",
    zipcode: "6655",
    place: "Rasa",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1572",
    longitude: "8.6559",
  },
  {
    country_code: "CH",
    zipcode: "6655",
    place: "Intragna",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1775",
    longitude: "8.7002",
  },
  {
    country_code: "CH",
    zipcode: "6655",
    place: "Verdasio",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1651",
    longitude: "8.6365",
  },
  {
    country_code: "CH",
    zipcode: "6656",
    place: "Golino",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.18",
    longitude: "8.7127",
  },
  {
    country_code: "CH",
    zipcode: "6657",
    place: "Palagnedra",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1546",
    longitude: "8.6314",
  },
  {
    country_code: "CH",
    zipcode: "6658",
    place: "Borgnone",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1597",
    longitude: "8.6162",
  },
  {
    country_code: "CH",
    zipcode: "6659",
    place: "Camedo",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1545",
    longitude: "8.6104",
  },
  {
    country_code: "CH",
    zipcode: "6659",
    place: "Moneto",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Centovalli",
    community_code: "5397",
    latitude: "46.1504",
    longitude: "8.6183",
  },
  {
    country_code: "CH",
    zipcode: "6661",
    place: "Loco",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5866",
  },
  {
    country_code: "CH",
    zipcode: "6661",
    place: "Berzona",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5866",
  },
  {
    country_code: "CH",
    zipcode: "6661",
    place: "Auressio",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5866",
  },
  {
    country_code: "CH",
    zipcode: "6662",
    place: "Russo",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.203",
    longitude: "8.6233",
  },
  {
    country_code: "CH",
    zipcode: "6663",
    place: "Comologno",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5745",
  },
  {
    country_code: "CH",
    zipcode: "6663",
    place: "Spruga",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2009",
    longitude: "8.5678",
  },
  {
    country_code: "CH",
    zipcode: "6664",
    place: "Vergeletto",
    state: "Ticino",
    state_code: "TI",
    province: "Locarno District",
    province_code: "2104",
    community: "Onsernone",
    community_code: "5136",
    latitude: "46.2035",
    longitude: "8.5866",
  },
  {
    country_code: "CH",
    zipcode: "6802",
    place: "Rivera",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteceneri",
    community_code: "5238",
    latitude: "46.1268",
    longitude: "8.9232",
  },
  {
    country_code: "CH",
    zipcode: "6803",
    place: "Camignolo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteceneri",
    community_code: "5238",
    latitude: "46.1087",
    longitude: "8.9365",
  },
  {
    country_code: "CH",
    zipcode: "6804",
    place: "Bironico",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteceneri",
    community_code: "5238",
    latitude: "46.1137",
    longitude: "8.9332",
  },
  {
    country_code: "CH",
    zipcode: "6805",
    place: "Mezzovico",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Mezzovico-Vira",
    community_code: "5199",
    latitude: "46.0898",
    longitude: "8.9176",
  },
  {
    country_code: "CH",
    zipcode: "6805",
    place: "Mezzovico",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Mezzovico-Vira",
    community_code: "5199",
    latitude: "46.0898",
    longitude: "8.9176",
  },
  {
    country_code: "CH",
    zipcode: "6806",
    place: "Sigirino",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteceneri",
    community_code: "5238",
    latitude: "46.0816",
    longitude: "8.9165",
  },
  {
    country_code: "CH",
    zipcode: "6807",
    place: "Taverne",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0911",
    longitude: "8.9864",
  },
  {
    country_code: "CH",
    zipcode: "6807",
    place: "Taverne",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Torricella-Taverne",
    community_code: "5227",
    latitude: "46.0616",
    longitude: "8.9287",
  },
  {
    country_code: "CH",
    zipcode: "6808",
    place: "Torricella",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Torricella-Taverne",
    community_code: "5227",
    latitude: "46.0638",
    longitude: "8.9233",
  },
  {
    country_code: "CH",
    zipcode: "6809",
    place: "Medeglia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteceneri",
    community_code: "5238",
    latitude: "46.1153",
    longitude: "8.9672",
  },
  {
    country_code: "CH",
    zipcode: "6814",
    place: "Lamone",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lamone",
    community_code: "5189",
    latitude: "46.0453",
    longitude: "8.9316",
  },
  {
    country_code: "CH",
    zipcode: "6814",
    place: "Cadempino",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Cadempino",
    community_code: "5162",
    latitude: "46.0367",
    longitude: "8.934",
  },
  {
    country_code: "CH",
    zipcode: "6815",
    place: "Melide",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Melide",
    community_code: "5198",
    latitude: "45.9546",
    longitude: "8.9472",
  },
  {
    country_code: "CH",
    zipcode: "6816",
    place: "Bissone",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bissone",
    community_code: "5154",
    latitude: "45.9541",
    longitude: "8.9666",
  },
  {
    country_code: "CH",
    zipcode: "6817",
    place: "Maroggia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Maroggia",
    community_code: "5195",
    latitude: "45.9352",
    longitude: "8.9706",
  },
  {
    country_code: "CH",
    zipcode: "6818",
    place: "Melano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Melano",
    community_code: "5197",
    latitude: "45.922",
    longitude: "8.9844",
  },
  {
    country_code: "CH",
    zipcode: "6821",
    place: "Rovio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Rovio",
    community_code: "5219",
    latitude: "45.9331",
    longitude: "8.987",
  },
  {
    country_code: "CH",
    zipcode: "6822",
    place: "Arogno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Arogno",
    community_code: "5144",
    latitude: "45.9591",
    longitude: "8.9844",
  },
  {
    country_code: "CH",
    zipcode: "6823",
    place: "Pugerna",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Arogno",
    community_code: "5144",
    latitude: "45.9798",
    longitude: "8.98",
  },
  {
    country_code: "CH",
    zipcode: "6825",
    place: "Capolago",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Rovio",
    community_code: "5219",
    latitude: "46.0409",
    longitude: "8.9425",
  },
  {
    country_code: "CH",
    zipcode: "6827",
    place: "Brusino Arsizio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Brusino Arsizio",
    community_code: "5160",
    latitude: "45.9303",
    longitude: "8.9388",
  },
  {
    country_code: "CH",
    zipcode: "6867",
    place: "Serpiano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Brusino Arsizio",
    community_code: "5160",
    latitude: "45.9104",
    longitude: "8.928",
  },
  {
    country_code: "CH",
    zipcode: "6900",
    place: "Lugano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0101",
    longitude: "8.96",
  },
  {
    country_code: "CH",
    zipcode: "6900",
    place: "Paradiso",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Paradiso",
    community_code: "5210",
    latitude: "45.99",
    longitude: "8.9457",
  },
  {
    country_code: "CH",
    zipcode: "6900",
    place: "Massagno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Massagno",
    community_code: "5196",
    latitude: "46.0126",
    longitude: "8.9435",
  },
  {
    country_code: "CH",
    zipcode: "6901",
    place: "Lugano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "8.9714",
  },
  {
    country_code: "CH",
    zipcode: "6902",
    place: "Lugano 2 Caselle",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Paradiso",
    community_code: "5210",
    latitude: "45.988",
    longitude: "8.9483",
  },
  {
    country_code: "CH",
    zipcode: "6903",
    place: "Lugano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "8.9714",
  },
  {
    country_code: "CH",
    zipcode: "6904",
    place: "Lugano 4 Caselle",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "8.9714",
  },
  {
    country_code: "CH",
    zipcode: "6906",
    place: "Lugano 6 Caselle",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "8.9714",
  },
  {
    country_code: "CH",
    zipcode: "6907",
    place: "Lugano 7 Caselle",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "8.9714",
  },
  {
    country_code: "CH",
    zipcode: "6908",
    place: "Massagno Caselle",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Massagno",
    community_code: "5196",
    latitude: "46.0126",
    longitude: "8.9437",
  },
  {
    country_code: "CH",
    zipcode: "6912",
    place: "Pazzallo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9859",
    longitude: "8.9444",
  },
  {
    country_code: "CH",
    zipcode: "6913",
    place: "Carabbia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9719",
    longitude: "8.937",
  },
  {
    country_code: "CH",
    zipcode: "6914",
    place: "Carona",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9574",
    longitude: "8.9358",
  },
  {
    country_code: "CH",
    zipcode: "6915",
    place: "Pambio-Noranco",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9841",
    longitude: "8.9293",
  },
  {
    country_code: "CH",
    zipcode: "6916",
    place: "Grancia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Grancia",
    community_code: "5186",
    latitude: "45.9681",
    longitude: "8.9269",
  },
  {
    country_code: "CH",
    zipcode: "6917",
    place: "Barbengo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9588",
    longitude: "8.9168",
  },
  {
    country_code: "CH",
    zipcode: "6918",
    place: "Figino",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "45.9518",
    longitude: "8.907",
  },
  {
    country_code: "CH",
    zipcode: "6919",
    place: "Carabietta",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Collina d'Oro",
    community_code: "5236",
    latitude: "45.9688",
    longitude: "8.9025",
  },
  {
    country_code: "CH",
    zipcode: "6921",
    place: "Vico Morcote",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Vico Morcote",
    community_code: "5233",
    latitude: "45.93",
    longitude: "8.922",
  },
  {
    country_code: "CH",
    zipcode: "6922",
    place: "Morcote",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Morcote",
    community_code: "5203",
    latitude: "45.925",
    longitude: "8.916",
  },
  {
    country_code: "CH",
    zipcode: "6924",
    place: "Sorengo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Sorengo",
    community_code: "5225",
    latitude: "45.9977",
    longitude: "8.9378",
  },
  {
    country_code: "CH",
    zipcode: "6925",
    place: "Gentilino",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Collina d'Oro",
    community_code: "5236",
    latitude: "45.9916",
    longitude: "8.9311",
  },
  {
    country_code: "CH",
    zipcode: "6926",
    place: "Montagnola",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Collina d'Oro",
    community_code: "5236",
    latitude: "45.9832",
    longitude: "8.9179",
  },
  {
    country_code: "CH",
    zipcode: "6927",
    place: "Agra",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Collina d'Oro",
    community_code: "5236",
    latitude: "45.9679",
    longitude: "8.9141",
  },
  {
    country_code: "CH",
    zipcode: "6928",
    place: "Manno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Manno",
    community_code: "5194",
    latitude: "46.0289",
    longitude: "8.9182",
  },
  {
    country_code: "CH",
    zipcode: "6929",
    place: "Gravesano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Gravesano",
    community_code: "5187",
    latitude: "46.0421",
    longitude: "8.9183",
  },
  {
    country_code: "CH",
    zipcode: "6930",
    place: "Bedano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bedano",
    community_code: "5148",
    latitude: "46.0512",
    longitude: "8.9194",
  },
  {
    country_code: "CH",
    zipcode: "6932",
    place: "Breganzona",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0069",
    longitude: "8.929",
  },
  {
    country_code: "CH",
    zipcode: "6933",
    place: "Muzzano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Muzzano",
    community_code: "5205",
    latitude: "45.9999",
    longitude: "8.9165",
  },
  {
    country_code: "CH",
    zipcode: "6934",
    place: "Bioggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bioggio",
    community_code: "5151",
    latitude: "46.0196",
    longitude: "8.9144",
  },
  {
    country_code: "CH",
    zipcode: "6935",
    place: "Bosco Luganese",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bioggio",
    community_code: "5151",
    latitude: "46.025",
    longitude: "8.9082",
  },
  {
    country_code: "CH",
    zipcode: "6936",
    place: "Cademario",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Cademario",
    community_code: "5161",
    latitude: "46.0226",
    longitude: "8.8924",
  },
  {
    country_code: "CH",
    zipcode: "6937",
    place: "Breno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Alto Malcantone",
    community_code: "5237",
    latitude: "46.0322",
    longitude: "8.87",
  },
  {
    country_code: "CH",
    zipcode: "6938",
    place: "Vezio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Alto Malcantone",
    community_code: "5237",
    latitude: "46.046",
    longitude: "8.8832",
  },
  {
    country_code: "CH",
    zipcode: "6938",
    place: "Fescoggia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Alto Malcantone",
    community_code: "5237",
    latitude: "46.0417",
    longitude: "8.8778",
  },
  {
    country_code: "CH",
    zipcode: "6939",
    place: "Mugena",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Alto Malcantone",
    community_code: "5237",
    latitude: "46.0486",
    longitude: "8.8893",
  },
  {
    country_code: "CH",
    zipcode: "6939",
    place: "Arosio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Alto Malcantone",
    community_code: "5237",
    latitude: "46.0477",
    longitude: "8.8997",
  },
  {
    country_code: "CH",
    zipcode: "6942",
    place: "Savosa",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Savosa",
    community_code: "5221",
    latitude: "46.0192",
    longitude: "8.9447",
  },
  {
    country_code: "CH",
    zipcode: "6943",
    place: "Vezia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Vezia",
    community_code: "5231",
    latitude: "46.0245",
    longitude: "8.9358",
  },
  {
    country_code: "CH",
    zipcode: "6944",
    place: "Cureglia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Cureglia",
    community_code: "5180",
    latitude: "46.0374",
    longitude: "8.9444",
  },
  {
    country_code: "CH",
    zipcode: "6945",
    place: "Origlio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Origlio",
    community_code: "5208",
    latitude: "46.0534",
    longitude: "8.9466",
  },
  {
    country_code: "CH",
    zipcode: "6946",
    place: "Ponte Capriasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Ponte Capriasca",
    community_code: "5212",
    latitude: "46.0605",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "6946",
    place: "Ponte Capriasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Ponte Capriasca",
    community_code: "5212",
    latitude: "46.0605",
    longitude: "8.9482",
  },
  {
    country_code: "CH",
    zipcode: "6947",
    place: "Vaglio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0622",
    longitude: "8.9585",
  },
  {
    country_code: "CH",
    zipcode: "6948",
    place: "Porza",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Porza",
    community_code: "5214",
    latitude: "46.0268",
    longitude: "8.9499",
  },
  {
    country_code: "CH",
    zipcode: "6949",
    place: "Comano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Comano",
    community_code: "5176",
    latitude: "46.0364",
    longitude: "8.9553",
  },
  {
    country_code: "CH",
    zipcode: "6950",
    place: "Tesserete",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0681",
    longitude: "8.965",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Scareglia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0912",
    longitude: "9.0382",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Insone",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0864",
    longitude: "9.0308",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Cozzo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0972",
    longitude: "9.062",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Signôra",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0926",
    longitude: "9.0472",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Bogno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0904",
    longitude: "9.0625",
  },
  {
    country_code: "CH",
    zipcode: "6951",
    place: "Colla",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0925",
    longitude: "9.0542",
  },
  {
    country_code: "CH",
    zipcode: "6952",
    place: "Canobbio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Canobbio",
    community_code: "5167",
    latitude: "46.0359",
    longitude: "8.966",
  },
  {
    country_code: "CH",
    zipcode: "6953",
    place: "Lugaggia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0626",
    longitude: "8.9727",
  },
  {
    country_code: "CH",
    zipcode: "6954",
    place: "Sala Capriasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0651",
    longitude: "8.9561",
  },
  {
    country_code: "CH",
    zipcode: "6954",
    place: "Bigorio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0701",
    longitude: "8.957",
  },
  {
    country_code: "CH",
    zipcode: "6955",
    place: "Oggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.073",
    longitude: "8.9881",
  },
  {
    country_code: "CH",
    zipcode: "6955",
    place: "Oggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.073",
    longitude: "8.9881",
  },
  {
    country_code: "CH",
    zipcode: "6955",
    place: "Cagiallo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0673",
    longitude: "8.9733",
  },
  {
    country_code: "CH",
    zipcode: "6956",
    place: "Lopagno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0701",
    longitude: "8.9781",
  },
  {
    country_code: "CH",
    zipcode: "6957",
    place: "Roveredo TI",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0749",
    longitude: "8.9848",
  },
  {
    country_code: "CH",
    zipcode: "6958",
    place: "Bidogno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0786",
    longitude: "8.9993",
  },
  {
    country_code: "CH",
    zipcode: "6958",
    place: "Corticiasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0888",
    longitude: "9.0227",
  },
  {
    country_code: "CH",
    zipcode: "6958",
    place: "Corticiasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0888",
    longitude: "9.0227",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Certara",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0846",
    longitude: "9.0598",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Cimadera",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0785",
    longitude: "9.0464",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Curtina",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0816",
    longitude: "9.0241",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Piandera Paese",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0816",
    longitude: "9.0434",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Piandera Paese",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0816",
    longitude: "9.0434",
  },
  {
    country_code: "CH",
    zipcode: "6959",
    place: "Maglio di Colla",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0816",
    longitude: "9.0434",
  },
  {
    country_code: "CH",
    zipcode: "6960",
    place: "Odogno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Capriasca",
    community_code: "5226",
    latitude: "46.0829",
    longitude: "8.9719",
  },
  {
    country_code: "CH",
    zipcode: "6962",
    place: "Viganello",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0134",
    longitude: "8.9688",
  },
  {
    country_code: "CH",
    zipcode: "6963",
    place: "Pregassona",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0202",
    longitude: "8.9743",
  },
  {
    country_code: "CH",
    zipcode: "6963",
    place: "Cureggia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0208",
    longitude: "8.9839",
  },
  {
    country_code: "CH",
    zipcode: "6964",
    place: "Davesco-Soragno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0376",
    longitude: "8.9829",
  },
  {
    country_code: "CH",
    zipcode: "6965",
    place: "Cadro",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.046",
    longitude: "8.9872",
  },
  {
    country_code: "CH",
    zipcode: "6966",
    place: "Villa Luganese",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0529",
    longitude: "8.9933",
  },
  {
    country_code: "CH",
    zipcode: "6967",
    place: "Dino",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.053",
    longitude: "8.9843",
  },
  {
    country_code: "CH",
    zipcode: "6968",
    place: "Sonvico",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.058",
    longitude: "8.9913",
  },
  {
    country_code: "CH",
    zipcode: "6974",
    place: "Aldesago",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.008",
    longitude: "8.9792",
  },
  {
    country_code: "CH",
    zipcode: "6976",
    place: "Castagnola",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.005",
    longitude: "8.9739",
  },
  {
    country_code: "CH",
    zipcode: "6977",
    place: "Ruvigliana",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0037",
    longitude: "8.9786",
  },
  {
    country_code: "CH",
    zipcode: "6978",
    place: "Gandria",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0055",
    longitude: "9.0026",
  },
  {
    country_code: "CH",
    zipcode: "6979",
    place: "Brè sopra Lugano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Lugano",
    community_code: "5192",
    latitude: "46.0115",
    longitude: "8.9961",
  },
  {
    country_code: "CH",
    zipcode: "6980",
    place: "Castelrotto",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Croglio",
    community_code: "5178",
    latitude: "45.9943",
    longitude: "8.8393",
  },
  {
    country_code: "CH",
    zipcode: "6981",
    place: "Banco",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bedigliora",
    community_code: "5149",
    latitude: "46.0094",
    longitude: "8.8452",
  },
  {
    country_code: "CH",
    zipcode: "6981",
    place: "Bedigliora",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bedigliora",
    community_code: "5149",
    latitude: "46.0024",
    longitude: "8.8393",
  },
  {
    country_code: "CH",
    zipcode: "6981",
    place: "Bombinasco",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Curio",
    community_code: "5181",
    latitude: "46.0141",
    longitude: "8.8334",
  },
  {
    country_code: "CH",
    zipcode: "6981",
    place: "Bedigliora",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bedigliora",
    community_code: "5149",
    latitude: "46.0024",
    longitude: "8.8393",
  },
  {
    country_code: "CH",
    zipcode: "6981",
    place: "Biogno-Beride",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Croglio",
    community_code: "5178",
    latitude: "45.9884",
    longitude: "8.8423",
  },
  {
    country_code: "CH",
    zipcode: "6982",
    place: "Agno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Agno",
    community_code: "5141",
    latitude: "45.9986",
    longitude: "8.9003",
  },
  {
    country_code: "CH",
    zipcode: "6983",
    place: "Magliaso",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Magliaso",
    community_code: "5193",
    latitude: "45.9812",
    longitude: "8.888",
  },
  {
    country_code: "CH",
    zipcode: "6984",
    place: "Pura",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Pura",
    community_code: "5216",
    latitude: "45.9865",
    longitude: "8.8688",
  },
  {
    country_code: "CH",
    zipcode: "6986",
    place: "Novaggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Novaggio",
    community_code: "5207",
    latitude: "46.0102",
    longitude: "8.8561",
  },
  {
    country_code: "CH",
    zipcode: "6986",
    place: "Miglieglia",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Miglieglia",
    community_code: "5200",
    latitude: "46.0238",
    longitude: "8.8574",
  },
  {
    country_code: "CH",
    zipcode: "6986",
    place: "Curio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Curio",
    community_code: "5181",
    latitude: "46.0014",
    longitude: "8.8622",
  },
  {
    country_code: "CH",
    zipcode: "6987",
    place: "Caslano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Caslano",
    community_code: "5171",
    latitude: "45.9736",
    longitude: "8.8774",
  },
  {
    country_code: "CH",
    zipcode: "6988",
    place: "Ponte Tresa",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Ponte Tresa",
    community_code: "5213",
    latitude: "45.9717",
    longitude: "8.8593",
  },
  {
    country_code: "CH",
    zipcode: "6989",
    place: "Purasca",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Croglio",
    community_code: "5178",
    latitude: "45.9775",
    longitude: "8.851",
  },
  {
    country_code: "CH",
    zipcode: "6990",
    place: "Cassina d'Agno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Agno",
    community_code: "5141",
    latitude: "45.9923",
    longitude: "8.8904",
  },
  {
    country_code: "CH",
    zipcode: "6991",
    place: "Neggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Neggio",
    community_code: "5206",
    latitude: "45.9871",
    longitude: "8.8797",
  },
  {
    country_code: "CH",
    zipcode: "6992",
    place: "Vernate",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Vernate",
    community_code: "5230",
    latitude: "45.9957",
    longitude: "8.8841",
  },
  {
    country_code: "CH",
    zipcode: "6992",
    place: "Cimo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bioggio",
    community_code: "5151",
    latitude: "46.0028",
    longitude: "8.8915",
  },
  {
    country_code: "CH",
    zipcode: "6993",
    place: "Iseo",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Bioggio",
    community_code: "5151",
    latitude: "46.0055",
    longitude: "8.8809",
  },
  {
    country_code: "CH",
    zipcode: "6994",
    place: "Aranno",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Aranno",
    community_code: "5143",
    latitude: "46.018",
    longitude: "8.8707",
  },
  {
    country_code: "CH",
    zipcode: "6995",
    place: "Molinazzo di Monteggio",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteggio",
    community_code: "5202",
    latitude: "45.9924",
    longitude: "8.8194",
  },
  {
    country_code: "CH",
    zipcode: "6995",
    place: "Madonna del Piano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Croglio",
    community_code: "5178",
    latitude: "45.9892",
    longitude: "8.8332",
  },
  {
    country_code: "CH",
    zipcode: "6996",
    place: "Ponte Cremenaga",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteggio",
    community_code: "5202",
    latitude: "45.9921",
    longitude: "8.8071",
  },
  {
    country_code: "CH",
    zipcode: "6997",
    place: "Sessa",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Sessa",
    community_code: "5222",
    latitude: "45.9986",
    longitude: "8.8197",
  },
  {
    country_code: "CH",
    zipcode: "6998",
    place: "Termine",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Monteggio",
    community_code: "5202",
    latitude: "45.9998",
    longitude: "8.7921",
  },
  {
    country_code: "CH",
    zipcode: "6999",
    place: "Astano",
    state: "Ticino",
    state_code: "TI",
    province: "Lugano District",
    province_code: "2105",
    community: "Astano",
    community_code: "5146",
    latitude: "46.0126",
    longitude: "8.8152",
  },
  {
    country_code: "CH",
    zipcode: "6825",
    place: "Capolago",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.9037",
    longitude: "8.9792",
  },
  {
    country_code: "CH",
    zipcode: "6826",
    place: "Riva San Vitale",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Riva San Vitale",
    community_code: "5263",
    latitude: "45.9012",
    longitude: "8.9717",
  },
  {
    country_code: "CH",
    zipcode: "6828",
    place: "Balerna",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Balerna",
    community_code: "5242",
    latitude: "45.8464",
    longitude: "9.0072",
  },
  {
    country_code: "CH",
    zipcode: "6830",
    place: "Chiasso",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Chiasso",
    community_code: "5250",
    latitude: "45.832",
    longitude: "9.0312",
  },
  {
    country_code: "CH",
    zipcode: "6832",
    place: "Seseglio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Chiasso",
    community_code: "5250",
    latitude: "45.828",
    longitude: "9.0005",
  },
  {
    country_code: "CH",
    zipcode: "6832",
    place: "Pedrinate",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Chiasso",
    community_code: "5250",
    latitude: "45.8261",
    longitude: "9.0132",
  },
  {
    country_code: "CH",
    zipcode: "6833",
    place: "Vacallo",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Vacallo",
    community_code: "5268",
    latitude: "45.8447",
    longitude: "9.0333",
  },
  {
    country_code: "CH",
    zipcode: "6834",
    place: "Morbio Inferiore",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Morbio Inferiore",
    community_code: "5257",
    latitude: "45.8491",
    longitude: "9.0191",
  },
  {
    country_code: "CH",
    zipcode: "6835",
    place: "Morbio Superiore",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.86",
    longitude: "9.0232",
  },
  {
    country_code: "CH",
    zipcode: "6836",
    place: "Serfontana",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Morbio Inferiore",
    community_code: "5257",
    latitude: "45.8497",
    longitude: "9.0216",
  },
  {
    country_code: "CH",
    zipcode: "6837",
    place: "Bruzella",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.8824",
    longitude: "9.0393",
  },
  {
    country_code: "CH",
    zipcode: "6837",
    place: "Caneggio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.8725",
    longitude: "9.0296",
  },
  {
    country_code: "CH",
    zipcode: "6838",
    place: "Cabbio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.8967",
    longitude: "9.0474",
  },
  {
    country_code: "CH",
    zipcode: "6838",
    place: "Muggio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.9021",
    longitude: "9.043",
  },
  {
    country_code: "CH",
    zipcode: "6838",
    place: "Scudellate",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.9229",
    longitude: "9.0415",
  },
  {
    country_code: "CH",
    zipcode: "6839",
    place: "Sagno",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Breggia",
    community_code: "5269",
    latitude: "45.8568",
    longitude: "9.0399",
  },
  {
    country_code: "CH",
    zipcode: "6850",
    place: "Mendrisio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8702",
    longitude: "8.9816",
  },
  {
    country_code: "CH",
    zipcode: "6852",
    place: "Genestrerio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8533",
    longitude: "8.9612",
  },
  {
    country_code: "CH",
    zipcode: "6853",
    place: "Ligornetto",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8616",
    longitude: "8.9517",
  },
  {
    country_code: "CH",
    zipcode: "6854",
    place: "S. Pietro",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Stabio",
    community_code: "5266",
    latitude: "45.8589",
    longitude: "8.9379",
  },
  {
    country_code: "CH",
    zipcode: "6855",
    place: "Stabio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Stabio",
    community_code: "5266",
    latitude: "45.8485",
    longitude: "8.9364",
  },
  {
    country_code: "CH",
    zipcode: "6862",
    place: "Rancate",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8715",
    longitude: "8.9671",
  },
  {
    country_code: "CH",
    zipcode: "6863",
    place: "Besazio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8721",
    longitude: "8.953",
  },
  {
    country_code: "CH",
    zipcode: "6864",
    place: "Arzo",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8761",
    longitude: "8.941",
  },
  {
    country_code: "CH",
    zipcode: "6865",
    place: "Tremona",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8815",
    longitude: "8.9588",
  },
  {
    country_code: "CH",
    zipcode: "6866",
    place: "Meride",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8905",
    longitude: "8.9529",
  },
  {
    country_code: "CH",
    zipcode: "6872",
    place: "Somazzo",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8786",
    longitude: "8.9937",
  },
  {
    country_code: "CH",
    zipcode: "6872",
    place: "Salorino",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Mendrisio",
    community_code: "5254",
    latitude: "45.8736",
    longitude: "8.9953",
  },
  {
    country_code: "CH",
    zipcode: "6873",
    place: "Corteglia",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Castel San Pietro",
    community_code: "5249",
    latitude: "45.8623",
    longitude: "8.9954",
  },
  {
    country_code: "CH",
    zipcode: "6874",
    place: "Castel San Pietro",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Castel San Pietro",
    community_code: "5249",
    latitude: "45.8621",
    longitude: "9.0084",
  },
  {
    country_code: "CH",
    zipcode: "6875",
    place: "Casima",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Castel San Pietro",
    community_code: "5249",
    latitude: "45.8906",
    longitude: "9.0369",
  },
  {
    country_code: "CH",
    zipcode: "6875",
    place: "Campora",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Castel San Pietro",
    community_code: "5249",
    latitude: "45.8743",
    longitude: "9.0238",
  },
  {
    country_code: "CH",
    zipcode: "6875",
    place: "Monte",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Castel San Pietro",
    community_code: "5249",
    latitude: "45.883",
    longitude: "9.0289",
  },
  {
    country_code: "CH",
    zipcode: "6877",
    place: "Coldrerio",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Coldrerio",
    community_code: "5251",
    latitude: "45.854",
    longitude: "8.9877",
  },
  {
    country_code: "CH",
    zipcode: "6883",
    place: "Novazzano",
    state: "Ticino",
    state_code: "TI",
    province: "Mendrisio District",
    province_code: "2106",
    community: "Novazzano",
    community_code: "5260",
    latitude: "45.8407",
    longitude: "8.9824",
  },
  {
    country_code: "CH",
    zipcode: "6526",
    place: "Prosito",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Riviera",
    community_code: "5287",
    latitude: "46.2806",
    longitude: "8.9841",
  },
  {
    country_code: "CH",
    zipcode: "6527",
    place: "Lodrino",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Riviera",
    community_code: "5287",
    latitude: "46.3002",
    longitude: "8.9799",
  },
  {
    country_code: "CH",
    zipcode: "6703",
    place: "Osogna",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Riviera",
    community_code: "5287",
    latitude: "46.3121",
    longitude: "8.9858",
  },
  {
    country_code: "CH",
    zipcode: "6705",
    place: "Cresciano",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Riviera",
    community_code: "5287",
    latitude: "46.2787",
    longitude: "9.0026",
  },
  {
    country_code: "CH",
    zipcode: "6707",
    place: "Iragna",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Riviera",
    community_code: "5287",
    latitude: "46.3257",
    longitude: "8.9666",
  },
  {
    country_code: "CH",
    zipcode: "6710",
    place: "Biasca",
    state: "Ticino",
    state_code: "TI",
    province: "Riviera District",
    province_code: "2107",
    community: "Biasca",
    community_code: "5281",
    latitude: "46.3597",
    longitude: "8.9697",
  },
  {
    country_code: "CH",
    zipcode: "6670",
    place: "Avegno",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Avegno Gordevio",
    community_code: "5324",
    latitude: "46.2052",
    longitude: "8.7455",
  },
  {
    country_code: "CH",
    zipcode: "6672",
    place: "Gordevio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Avegno Gordevio",
    community_code: "5324",
    latitude: "46.226",
    longitude: "8.7431",
  },
  {
    country_code: "CH",
    zipcode: "6673",
    place: "Maggia",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2469",
    longitude: "8.7062",
  },
  {
    country_code: "CH",
    zipcode: "6674",
    place: "Riveo",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2977",
    longitude: "8.6267",
  },
  {
    country_code: "CH",
    zipcode: "6674",
    place: "Someo",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2872",
    longitude: "8.6623",
  },
  {
    country_code: "CH",
    zipcode: "6675",
    place: "Cevio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Cevio",
    community_code: "5310",
    latitude: "46.3148",
    longitude: "8.6033",
  },
  {
    country_code: "CH",
    zipcode: "6676",
    place: "Bignasco",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Cevio",
    community_code: "5310",
    latitude: "46.3384",
    longitude: "8.6082",
  },
  {
    country_code: "CH",
    zipcode: "6677",
    place: "Moghegno",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2389",
    longitude: "8.7072",
  },
  {
    country_code: "CH",
    zipcode: "6677",
    place: "Aurigeno",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2301",
    longitude: "8.7152",
  },
  {
    country_code: "CH",
    zipcode: "6678",
    place: "Giumaglio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2741",
    longitude: "8.6812",
  },
  {
    country_code: "CH",
    zipcode: "6678",
    place: "Lodano",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2601",
    longitude: "8.6843",
  },
  {
    country_code: "CH",
    zipcode: "6678",
    place: "Coglio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Maggia",
    community_code: "5317",
    latitude: "46.2705",
    longitude: "8.685",
  },
  {
    country_code: "CH",
    zipcode: "6682",
    place: "Linescio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Linescio",
    community_code: "5315",
    latitude: "46.3092",
    longitude: "8.5822",
  },
  {
    country_code: "CH",
    zipcode: "6683",
    place: "Cerentino",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Cerentino",
    community_code: "5309",
    latitude: "46.3082",
    longitude: "8.5453",
  },
  {
    country_code: "CH",
    zipcode: "6683",
    place: "Niva (Vallemaggia)",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Campo (Vallemaggia)",
    community_code: "5307",
    latitude: "46.2922",
    longitude: "8.5321",
  },
  {
    country_code: "CH",
    zipcode: "6684",
    place: "Campo (Vallemaggia)",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Campo (Vallemaggia)",
    community_code: "5307",
    latitude: "46.2885",
    longitude: "8.4958",
  },
  {
    country_code: "CH",
    zipcode: "6684",
    place: "Cimalmotto",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Campo (Vallemaggia)",
    community_code: "5307",
    latitude: "46.2837",
    longitude: "8.4886",
  },
  {
    country_code: "CH",
    zipcode: "6685",
    place: "Bosco/Gurin",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Bosco/Gurin",
    community_code: "5304",
    latitude: "46.317",
    longitude: "8.4912",
  },
  {
    country_code: "CH",
    zipcode: "6690",
    place: "Cavergno",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Cevio",
    community_code: "5310",
    latitude: "46.3453",
    longitude: "8.6071",
  },
  {
    country_code: "CH",
    zipcode: "6690",
    place: "S. Carlo (Val Bavona)",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Cevio",
    community_code: "5310",
    latitude: "46.3453",
    longitude: "8.6071",
  },
  {
    country_code: "CH",
    zipcode: "6692",
    place: "Menzonio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.3624",
    longitude: "8.642",
  },
  {
    country_code: "CH",
    zipcode: "6692",
    place: "Brontallo",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.3545",
    longitude: "8.6272",
  },
  {
    country_code: "CH",
    zipcode: "6693",
    place: "Broglio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.3776",
    longitude: "8.6609",
  },
  {
    country_code: "CH",
    zipcode: "6694",
    place: "Prato-Sornico",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.3963",
    longitude: "8.6579",
  },
  {
    country_code: "CH",
    zipcode: "6695",
    place: "Piano di Peccia",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.4143",
    longitude: "8.6081",
  },
  {
    country_code: "CH",
    zipcode: "6695",
    place: "Peccia",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.4084",
    longitude: "8.6457",
  },
  {
    country_code: "CH",
    zipcode: "6696",
    place: "Fusio",
    state: "Ticino",
    state_code: "TI",
    province: "Vallemaggia District",
    province_code: "2108",
    community: "Lavizzara",
    community_code: "5323",
    latitude: "46.4445",
    longitude: "8.663",
  },
  {
    country_code: "CH",
    zipcode: "6377",
    place: "Seelisberg",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Seelisberg",
    community_code: "1215",
    latitude: "46.973",
    longitude: "8.5869",
  },
  {
    country_code: "CH",
    zipcode: "6441",
    place: "Rütli",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Seelisberg",
    community_code: "1215",
    latitude: "46.9685",
    longitude: "8.5927",
  },
  {
    country_code: "CH",
    zipcode: "6452",
    place: "Sisikon",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Sisikon",
    community_code: "1217",
    latitude: "46.9491",
    longitude: "8.62",
  },
  {
    country_code: "CH",
    zipcode: "6454",
    place: "Flüelen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Flüelen",
    community_code: "1207",
    latitude: "46.9048",
    longitude: "8.624",
  },
  {
    country_code: "CH",
    zipcode: "6460",
    place: "Altdorf UR",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Altdorf (UR)",
    community_code: "1201",
    latitude: "46.8804",
    longitude: "8.6444",
  },
  {
    country_code: "CH",
    zipcode: "6461",
    place: "Isenthal",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Isenthal",
    community_code: "1211",
    latitude: "46.9108",
    longitude: "8.5612",
  },
  {
    country_code: "CH",
    zipcode: "6462",
    place: "Seedorf UR",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Seedorf (UR)",
    community_code: "1214",
    latitude: "46.882",
    longitude: "8.6161",
  },
  {
    country_code: "CH",
    zipcode: "6463",
    place: "Bürglen UR",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Bürglen (UR)",
    community_code: "1205",
    latitude: "46.8757",
    longitude: "8.6654",
  },
  {
    country_code: "CH",
    zipcode: "6464",
    place: "Spiringen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Spiringen",
    community_code: "1218",
    latitude: "46.8726",
    longitude: "8.7302",
  },
  {
    country_code: "CH",
    zipcode: "6465",
    place: "Unterschächen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Unterschächen",
    community_code: "1219",
    latitude: "46.8628",
    longitude: "8.7692",
  },
  {
    country_code: "CH",
    zipcode: "6466",
    place: "Bauen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Bauen",
    community_code: "1204",
    latitude: "46.9356",
    longitude: "8.5784",
  },
  {
    country_code: "CH",
    zipcode: "6467",
    place: "Schattdorf",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Schattdorf",
    community_code: "1213",
    latitude: "46.8655",
    longitude: "8.6547",
  },
  {
    country_code: "CH",
    zipcode: "6468",
    place: "Attinghausen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Attinghausen",
    community_code: "1203",
    latitude: "46.8626",
    longitude: "8.6304",
  },
  {
    country_code: "CH",
    zipcode: "6469",
    place: "Haldi b. Schattdorf",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Schattdorf",
    community_code: "1213",
    latitude: "46.8636",
    longitude: "8.6749",
  },
  {
    country_code: "CH",
    zipcode: "6472",
    place: "Erstfeld",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Erstfeld",
    community_code: "1206",
    latitude: "46.8188",
    longitude: "8.6505",
  },
  {
    country_code: "CH",
    zipcode: "6473",
    place: "Silenen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Silenen",
    community_code: "1216",
    latitude: "46.7891",
    longitude: "8.6732",
  },
  {
    country_code: "CH",
    zipcode: "6474",
    place: "Amsteg",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Silenen",
    community_code: "1216",
    latitude: "46.7713",
    longitude: "8.6696",
  },
  {
    country_code: "CH",
    zipcode: "6475",
    place: "Bristen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Silenen",
    community_code: "1216",
    latitude: "46.7691",
    longitude: "8.6903",
  },
  {
    country_code: "CH",
    zipcode: "6476",
    place: "Intschi",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Gurtnellen",
    community_code: "1209",
    latitude: "46.7604",
    longitude: "8.649",
  },
  {
    country_code: "CH",
    zipcode: "6482",
    place: "Gurtnellen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Gurtnellen",
    community_code: "1209",
    latitude: "46.7381",
    longitude: "8.6284",
  },
  {
    country_code: "CH",
    zipcode: "6484",
    place: "Wassen UR",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Wassen",
    community_code: "1220",
    latitude: "46.7065",
    longitude: "8.5988",
  },
  {
    country_code: "CH",
    zipcode: "6485",
    place: "Meien",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Wassen",
    community_code: "1220",
    latitude: "46.7238",
    longitude: "8.5571",
  },
  {
    country_code: "CH",
    zipcode: "6487",
    place: "Göschenen",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Göschenen",
    community_code: "1208",
    latitude: "46.6682",
    longitude: "8.5871",
  },
  {
    country_code: "CH",
    zipcode: "6490",
    place: "Andermatt",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Andermatt",
    community_code: "1202",
    latitude: "46.6356",
    longitude: "8.5939",
  },
  {
    country_code: "CH",
    zipcode: "6491",
    place: "Realp",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Realp",
    community_code: "1212",
    latitude: "46.5982",
    longitude: "8.5028",
  },
  {
    country_code: "CH",
    zipcode: "6493",
    place: "Hospental",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Hospental",
    community_code: "1210",
    latitude: "46.6196",
    longitude: "8.5698",
  },
  {
    country_code: "CH",
    zipcode: "8751",
    place: "Urnerboden",
    state: "Kanton Uri",
    state_code: "UR",
    province: "Uri",
    province_code: "400",
    community: "Spiringen",
    community_code: "1218",
    latitude: "46.8891",
    longitude: "8.9015",
  },
  {
    country_code: "CH",
    zipcode: "1844",
    place: "Villeneuve VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Villeneuve (VD)",
    community_code: "5414",
    latitude: "46.3987",
    longitude: "6.9265",
  },
  {
    country_code: "CH",
    zipcode: "1845",
    place: "Noville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Noville",
    community_code: "5408",
    latitude: "46.3815",
    longitude: "6.9001",
  },
  {
    country_code: "CH",
    zipcode: "1846",
    place: "Chessel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Chessel",
    community_code: "5403",
    latitude: "46.3496",
    longitude: "6.897",
  },
  {
    country_code: "CH",
    zipcode: "1847",
    place: "Rennaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Rennaz",
    community_code: "5412",
    latitude: "46.3748",
    longitude: "6.916",
  },
  {
    country_code: "CH",
    zipcode: "1852",
    place: "Roche VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Roche (VD)",
    community_code: "5413",
    latitude: "46.3609",
    longitude: "6.9327",
  },
  {
    country_code: "CH",
    zipcode: "1853",
    place: "Yvorne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Yvorne",
    community_code: "5415",
    latitude: "46.3312",
    longitude: "6.9587",
  },
  {
    country_code: "CH",
    zipcode: "1854",
    place: "Leysin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Leysin",
    community_code: "5407",
    latitude: "46.3418",
    longitude: "7.0115",
  },
  {
    country_code: "CH",
    zipcode: "1856",
    place: "Corbeyrier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Corbeyrier",
    community_code: "5404",
    latitude: "46.3501",
    longitude: "6.9602",
  },
  {
    country_code: "CH",
    zipcode: "1860",
    place: "Aigle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Aigle",
    community_code: "5401",
    latitude: "46.3181",
    longitude: "6.9646",
  },
  {
    country_code: "CH",
    zipcode: "1862",
    place: "Les Mosses",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessous",
    community_code: "5410",
    latitude: "46.3953",
    longitude: "7.1006",
  },
  {
    country_code: "CH",
    zipcode: "1862",
    place: "La Comballaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessous",
    community_code: "5410",
    latitude: "46.3797",
    longitude: "7.0833",
  },
  {
    country_code: "CH",
    zipcode: "1863",
    place: "Le Sépey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessous",
    community_code: "5410",
    latitude: "46.3614",
    longitude: "7.05",
  },
  {
    country_code: "CH",
    zipcode: "1864",
    place: "Vers-l'Eglise",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessus",
    community_code: "5411",
    latitude: "46.3537",
    longitude: "7.1316",
  },
  {
    country_code: "CH",
    zipcode: "1865",
    place: "Les Diablerets",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessus",
    community_code: "5411",
    latitude: "46.35",
    longitude: "7.159",
  },
  {
    country_code: "CH",
    zipcode: "1866",
    place: "La Forclaz VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ormont-Dessous",
    community_code: "5410",
    latitude: "46.3507",
    longitude: "7.0689",
  },
  {
    country_code: "CH",
    zipcode: "1867",
    place: "Panex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.3118",
    longitude: "7.0137",
  },
  {
    country_code: "CH",
    zipcode: "1867",
    place: "St-Triphon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.2927",
    longitude: "6.9781",
  },
  {
    country_code: "CH",
    zipcode: "1867",
    place: "Ollon VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.2952",
    longitude: "6.9931",
  },
  {
    country_code: "CH",
    zipcode: "1880",
    place: "Les Plans-sur-Bex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Bex",
    community_code: "5402",
    latitude: "46.2574",
    longitude: "7.0934",
  },
  {
    country_code: "CH",
    zipcode: "1880",
    place: "Les Posses-sur-Bex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Bex",
    community_code: "5402",
    latitude: "46.2596",
    longitude: "7.0436",
  },
  {
    country_code: "CH",
    zipcode: "1880",
    place: "Bex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Bex",
    community_code: "5402",
    latitude: "46.2497",
    longitude: "7.0098",
  },
  {
    country_code: "CH",
    zipcode: "1880",
    place: "Fenalet-sur-Bex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Bex",
    community_code: "5402",
    latitude: "46.2718",
    longitude: "7.0276",
  },
  {
    country_code: "CH",
    zipcode: "1880",
    place: "Frenières-sur-Bex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Bex",
    community_code: "5402",
    latitude: "46.2596",
    longitude: "7.0436",
  },
  {
    country_code: "CH",
    zipcode: "1882",
    place: "Gryon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Gryon",
    community_code: "5405",
    latitude: "46.2738",
    longitude: "7.0597",
  },
  {
    country_code: "CH",
    zipcode: "1884",
    place: "Arveyes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.2899",
    longitude: "7.0617",
  },
  {
    country_code: "CH",
    zipcode: "1884",
    place: "Villars-sur-Ollon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.2983",
    longitude: "7.0563",
  },
  {
    country_code: "CH",
    zipcode: "1884",
    place: "Huémoz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.291",
    longitude: "7.0246",
  },
  {
    country_code: "CH",
    zipcode: "1885",
    place: "Chesières",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Ollon",
    community_code: "5409",
    latitude: "46.3024",
    longitude: "7.0441",
  },
  {
    country_code: "CH",
    zipcode: "1892",
    place: "Lavey-les-Bains",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Lavey-Morcles",
    community_code: "5406",
    latitude: "46.2037",
    longitude: "7.0187",
  },
  {
    country_code: "CH",
    zipcode: "1892",
    place: "Lavey-Village",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Lavey-Morcles",
    community_code: "5406",
    latitude: "46.2213",
    longitude: "7.0148",
  },
  {
    country_code: "CH",
    zipcode: "1892",
    place: "Morcles",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Aigle District",
    province_code: "2221",
    community: "Lavey-Morcles",
    community_code: "5406",
    latitude: "46.2087",
    longitude: "7.0363",
  },
  {
    country_code: "CH",
    zipcode: "1082",
    place: "Corcelles-le-Jorat",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Corcelles-le-Jorat",
    community_code: "5785",
    latitude: "46.6013",
    longitude: "6.7309",
  },
  {
    country_code: "CH",
    zipcode: "1085",
    place: "Vulliens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vulliens",
    community_code: "5803",
    latitude: "46.6225",
    longitude: "6.7935",
  },
  {
    country_code: "CH",
    zipcode: "1088",
    place: "Ropraz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Ropraz",
    community_code: "5798",
    latitude: "46.6146",
    longitude: "6.7535",
  },
  {
    country_code: "CH",
    zipcode: "1509",
    place: "Vucherens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vucherens",
    community_code: "5692",
    latitude: "46.627",
    longitude: "6.7758",
  },
  {
    country_code: "CH",
    zipcode: "1510",
    place: "Syens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Syens",
    community_code: "5688",
    latitude: "46.6441",
    longitude: "6.7769",
  },
  {
    country_code: "CH",
    zipcode: "1510",
    place: "Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Moudon",
    community_code: "5678",
    latitude: "46.6676",
    longitude: "6.7978",
  },
  {
    country_code: "CH",
    zipcode: "1512",
    place: "Chavannes-sur-Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Chavannes-sur-Moudon",
    community_code: "5665",
    latitude: "46.6587",
    longitude: "6.8096",
  },
  {
    country_code: "CH",
    zipcode: "1513",
    place: "Hermenches",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Hermenches",
    community_code: "5673",
    latitude: "46.6403",
    longitude: "6.7627",
  },
  {
    country_code: "CH",
    zipcode: "1513",
    place: "Rossenges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Rossenges",
    community_code: "5684",
    latitude: "46.6535",
    longitude: "6.776",
  },
  {
    country_code: "CH",
    zipcode: "1514",
    place: "Bussy-sur-Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Bussy-sur-Moudon",
    community_code: "5663",
    latitude: "46.6905",
    longitude: "6.8096",
  },
  {
    country_code: "CH",
    zipcode: "1515",
    place: "Villars-le-Comte",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Villars-le-Comte",
    community_code: "5690",
    latitude: "46.7131",
    longitude: "6.8014",
  },
  {
    country_code: "CH",
    zipcode: "1521",
    place: "Curtilles",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Curtilles",
    community_code: "5669",
    latitude: "46.7004",
    longitude: "6.8506",
  },
  {
    country_code: "CH",
    zipcode: "1522",
    place: "Oulens-sur-Lucens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7085",
    longitude: "6.8393",
  },
  {
    country_code: "CH",
    zipcode: "1522",
    place: "Lucens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7085",
    longitude: "6.8393",
  },
  {
    country_code: "CH",
    zipcode: "1523",
    place: "Granges-près-Marnand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7765",
    longitude: "6.8959",
  },
  {
    country_code: "CH",
    zipcode: "1524",
    place: "Marnand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7573",
    longitude: "6.8991",
  },
  {
    country_code: "CH",
    zipcode: "1525",
    place: "Henniez",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Henniez",
    community_code: "5819",
    latitude: "46.7422",
    longitude: "6.887",
  },
  {
    country_code: "CH",
    zipcode: "1525",
    place: "Seigneux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7276",
    longitude: "6.8822",
  },
  {
    country_code: "CH",
    zipcode: "1526",
    place: "Cremin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7089",
    longitude: "6.8326",
  },
  {
    country_code: "CH",
    zipcode: "1526",
    place: "Forel-sur-Lucens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7089",
    longitude: "6.8326",
  },
  {
    country_code: "CH",
    zipcode: "1530",
    place: "Payerne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Payerne",
    community_code: "5822",
    latitude: "46.8219",
    longitude: "6.9382",
  },
  {
    country_code: "CH",
    zipcode: "1534",
    place: "Sassel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.777",
    longitude: "6.8571",
  },
  {
    country_code: "CH",
    zipcode: "1535",
    place: "Combremont-le-Grand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7617",
    longitude: "6.8182",
  },
  {
    country_code: "CH",
    zipcode: "1536",
    place: "Combremont-le-Petit",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7494",
    longitude: "6.8093",
  },
  {
    country_code: "CH",
    zipcode: "1537",
    place: "Champtauroz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Champtauroz",
    community_code: "5812",
    latitude: "46.759",
    longitude: "6.7863",
  },
  {
    country_code: "CH",
    zipcode: "1538",
    place: "Treytorrens (Payerne)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Treytorrens (Payerne)",
    community_code: "5828",
    latitude: "46.7722",
    longitude: "6.8048",
  },
  {
    country_code: "CH",
    zipcode: "1543",
    place: "Grandcour",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Grandcour",
    community_code: "5817",
    latitude: "46.8719",
    longitude: "6.9284",
  },
  {
    country_code: "CH",
    zipcode: "1545",
    place: "Chevroux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Chevroux",
    community_code: "5813",
    latitude: "46.8884",
    longitude: "6.9069",
  },
  {
    country_code: "CH",
    zipcode: "1551",
    place: "Vers-chez-Perrin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Payerne",
    community_code: "5822",
    latitude: "46.7977",
    longitude: "6.9436",
  },
  {
    country_code: "CH",
    zipcode: "1552",
    place: "Trey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Trey",
    community_code: "5827",
    latitude: "46.7702",
    longitude: "6.9265",
  },
  {
    country_code: "CH",
    zipcode: "1554",
    place: "Sédeilles",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Villarzel",
    community_code: "5830",
    latitude: "46.7486",
    longitude: "6.9331",
  },
  {
    country_code: "CH",
    zipcode: "1554",
    place: "Rossens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Villarzel",
    community_code: "5830",
    latitude: "46.7343",
    longitude: "6.9247",
  },
  {
    country_code: "CH",
    zipcode: "1555",
    place: "Villarzel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Villarzel",
    community_code: "5830",
    latitude: "46.7476",
    longitude: "6.9122",
  },
  {
    country_code: "CH",
    zipcode: "1562",
    place: "Corcelles-près-Payerne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Corcelles-près-Payerne",
    community_code: "5816",
    latitude: "46.8332",
    longitude: "6.9623",
  },
  {
    country_code: "CH",
    zipcode: "1565",
    place: "Missy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Missy",
    community_code: "5821",
    latitude: "46.8801",
    longitude: "6.973",
  },
  {
    country_code: "CH",
    zipcode: "1580",
    place: "Donatyre",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Avenches",
    community_code: "5451",
    latitude: "46.8673",
    longitude: "7.0393",
  },
  {
    country_code: "CH",
    zipcode: "1580",
    place: "Oleyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Avenches",
    community_code: "5451",
    latitude: "46.8545",
    longitude: "7.0379",
  },
  {
    country_code: "CH",
    zipcode: "1580",
    place: "Avenches",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Avenches",
    community_code: "5451",
    latitude: "46.88",
    longitude: "7.0407",
  },
  {
    country_code: "CH",
    zipcode: "1584",
    place: "Villars-le-Grand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9049",
    longitude: "6.99",
  },
  {
    country_code: "CH",
    zipcode: "1585",
    place: "Bellerive VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9234",
    longitude: "7.0221",
  },
  {
    country_code: "CH",
    zipcode: "1585",
    place: "Salavaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9197",
    longitude: "7.0232",
  },
  {
    country_code: "CH",
    zipcode: "1585",
    place: "Cotterd",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9209",
    longitude: "7.0224",
  },
  {
    country_code: "CH",
    zipcode: "1586",
    place: "Vallamand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9293",
    longitude: "7.0403",
  },
  {
    country_code: "CH",
    zipcode: "1587",
    place: "Montmagny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9272",
    longitude: "7.0075",
  },
  {
    country_code: "CH",
    zipcode: "1587",
    place: "Constantine",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9183",
    longitude: "7.0086",
  },
  {
    country_code: "CH",
    zipcode: "1588",
    place: "Cudrefin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Cudrefin",
    community_code: "5456",
    latitude: "46.9558",
    longitude: "7.0185",
  },
  {
    country_code: "CH",
    zipcode: "1589",
    place: "Chabrey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.9269",
    longitude: "6.9816",
  },
  {
    country_code: "CH",
    zipcode: "1595",
    place: "Faoug",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Faoug",
    community_code: "5458",
    latitude: "46.9083",
    longitude: "7.078",
  },
  {
    country_code: "CH",
    zipcode: "1682",
    place: "Lovatens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lovatens",
    community_code: "5674",
    latitude: "46.692",
    longitude: "6.8607",
  },
  {
    country_code: "CH",
    zipcode: "1682",
    place: "Dompierre VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Dompierre (VD)",
    community_code: "5671",
    latitude: "46.7083",
    longitude: "6.8831",
  },
  {
    country_code: "CH",
    zipcode: "1682",
    place: "Prévonloup",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Prévonloup",
    community_code: "5683",
    latitude: "46.6979",
    longitude: "6.8828",
  },
  {
    country_code: "CH",
    zipcode: "1682",
    place: "Cerniaz VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7279",
    longitude: "6.8946",
  },
  {
    country_code: "CH",
    zipcode: "1682",
    place: "Villars-Bramard",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Valbroye",
    community_code: "5831",
    latitude: "46.7182",
    longitude: "6.9031",
  },
  {
    country_code: "CH",
    zipcode: "1683",
    place: "Sarzens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7089",
    longitude: "6.8326",
  },
  {
    country_code: "CH",
    zipcode: "1683",
    place: "Brenles",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.7089",
    longitude: "6.8326",
  },
  {
    country_code: "CH",
    zipcode: "1683",
    place: "Chesalles-sur-Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Lucens",
    community_code: "5675",
    latitude: "46.6762",
    longitude: "6.8506",
  },
  {
    country_code: "CH",
    zipcode: "1787",
    place: "Mur (Vully) VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Broye-Vully District",
    province_code: "2222",
    community: "Vully-les-Lacs",
    community_code: "5464",
    latitude: "46.7765",
    longitude: "6.8959",
  },
  {
    country_code: "CH",
    zipcode: "1031",
    place: "Mex VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Mex (VD)",
    community_code: "5489",
    latitude: "46.5759",
    longitude: "6.5545",
  },
  {
    country_code: "CH",
    zipcode: "1034",
    place: "Boussens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Boussens",
    community_code: "5473",
    latitude: "46.6033",
    longitude: "6.586",
  },
  {
    country_code: "CH",
    zipcode: "1035",
    place: "Bournens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bournens",
    community_code: "5472",
    latitude: "46.6049",
    longitude: "6.5648",
  },
  {
    country_code: "CH",
    zipcode: "1036",
    place: "Sullens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Sullens",
    community_code: "5501",
    latitude: "46.5956",
    longitude: "6.5669",
  },
  {
    country_code: "CH",
    zipcode: "1037",
    place: "Etagnières",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Etagnières",
    community_code: "5521",
    latitude: "46.6015",
    longitude: "6.6065",
  },
  {
    country_code: "CH",
    zipcode: "1038",
    place: "Bercher",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bercher",
    community_code: "5512",
    latitude: "46.6914",
    longitude: "6.7076",
  },
  {
    country_code: "CH",
    zipcode: "1040",
    place: "Villars-le-Terroir",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Villars-le-Terroir",
    community_code: "5537",
    latitude: "46.6582",
    longitude: "6.6439",
  },
  {
    country_code: "CH",
    zipcode: "1040",
    place: "Echallens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Echallens",
    community_code: "5518",
    latitude: "46.6413",
    longitude: "6.6332",
  },
  {
    country_code: "CH",
    zipcode: "1040",
    place: "St-Barthélemy VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Saint-Barthélemy (VD)",
    community_code: "5535",
    latitude: "46.6349",
    longitude: "6.6021",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Poliez-Pittet",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Poliez-Pittet",
    community_code: "5533",
    latitude: "46.6273",
    longitude: "6.6881",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Dommartin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montilliez",
    community_code: "5540",
    latitude: "46.6485",
    longitude: "6.7023",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Montaubion-Chardonney",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Jorat-Menthue",
    community_code: "5804",
    latitude: "46.6456",
    longitude: "6.7139",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Bottens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bottens",
    community_code: "5514",
    latitude: "46.616",
    longitude: "6.6615",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Poliez-le-Grand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montilliez",
    community_code: "5540",
    latitude: "46.6354",
    longitude: "6.6662",
  },
  {
    country_code: "CH",
    zipcode: "1041",
    place: "Naz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montilliez",
    community_code: "5540",
    latitude: "46.6588",
    longitude: "6.694",
  },
  {
    country_code: "CH",
    zipcode: "1042",
    place: "Bioley-Orjulaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bioley-Orjulaz",
    community_code: "5513",
    latitude: "46.6214",
    longitude: "6.5985",
  },
  {
    country_code: "CH",
    zipcode: "1042",
    place: "Bettens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bettens",
    community_code: "5471",
    latitude: "46.6283",
    longitude: "6.5734",
  },
  {
    country_code: "CH",
    zipcode: "1042",
    place: "Assens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Assens",
    community_code: "5511",
    latitude: "46.613",
    longitude: "6.6218",
  },
  {
    country_code: "CH",
    zipcode: "1043",
    place: "Sugnens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montilliez",
    community_code: "5540",
    latitude: "46.6551",
    longitude: "6.6714",
  },
  {
    country_code: "CH",
    zipcode: "1044",
    place: "Fey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Fey",
    community_code: "5522",
    latitude: "46.6729",
    longitude: "6.6801",
  },
  {
    country_code: "CH",
    zipcode: "1045",
    place: "Ogens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Ogens",
    community_code: "5680",
    latitude: "46.7084",
    longitude: "6.7245",
  },
  {
    country_code: "CH",
    zipcode: "1046",
    place: "Rueyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Rueyres",
    community_code: "5534",
    latitude: "46.6934",
    longitude: "6.6921",
  },
  {
    country_code: "CH",
    zipcode: "1047",
    place: "Oppens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Oppens",
    community_code: "5923",
    latitude: "46.714",
    longitude: "6.6917",
  },
  {
    country_code: "CH",
    zipcode: "1053",
    place: "Bretigny-sur-Morrens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Bretigny-sur-Morrens",
    community_code: "5515",
    latitude: "46.5998",
    longitude: "6.6431",
  },
  {
    country_code: "CH",
    zipcode: "1053",
    place: "Cugy VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Cugy (VD)",
    community_code: "5516",
    latitude: "46.5862",
    longitude: "6.6409",
  },
  {
    country_code: "CH",
    zipcode: "1054",
    place: "Morrens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Morrens (VD)",
    community_code: "5527",
    latitude: "46.5909",
    longitude: "6.6223",
  },
  {
    country_code: "CH",
    zipcode: "1055",
    place: "Froideville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Froideville",
    community_code: "5523",
    latitude: "46.6012",
    longitude: "6.6809",
  },
  {
    country_code: "CH",
    zipcode: "1058",
    place: "Villars-Tiercelin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Jorat-Menthue",
    community_code: "5804",
    latitude: "46.6251",
    longitude: "6.7038",
  },
  {
    country_code: "CH",
    zipcode: "1059",
    place: "Peney-le-Jorat",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Jorat-Menthue",
    community_code: "5804",
    latitude: "46.6323",
    longitude: "6.7288",
  },
  {
    country_code: "CH",
    zipcode: "1061",
    place: "Villars-Mendraz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Jorat-Menthue",
    community_code: "5804",
    latitude: "46.6459",
    longitude: "6.7296",
  },
  {
    country_code: "CH",
    zipcode: "1062",
    place: "Sottens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Jorat-Menthue",
    community_code: "5804",
    latitude: "46.6552",
    longitude: "6.742",
  },
  {
    country_code: "CH",
    zipcode: "1063",
    place: "Boulens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Boulens",
    community_code: "5661",
    latitude: "46.6789",
    longitude: "6.7183",
  },
  {
    country_code: "CH",
    zipcode: "1063",
    place: "Chapelle-sur-Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.6696",
    longitude: "6.7357",
  },
  {
    country_code: "CH",
    zipcode: "1063",
    place: "Peyres-Possens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.6703",
    longitude: "6.7366",
  },
  {
    country_code: "CH",
    zipcode: "1063",
    place: "Martherenges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.6623",
    longitude: "6.7557",
  },
  {
    country_code: "CH",
    zipcode: "1302",
    place: "Vufflens-la-Ville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Vufflens-la-Ville",
    community_code: "5503",
    latitude: "46.5752",
    longitude: "6.5372",
  },
  {
    country_code: "CH",
    zipcode: "1303",
    place: "Penthaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Penthaz",
    community_code: "5496",
    latitude: "46.5987",
    longitude: "6.5413",
  },
  {
    country_code: "CH",
    zipcode: "1305",
    place: "Penthalaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Penthalaz",
    community_code: "5495",
    latitude: "46.6108",
    longitude: "6.5252",
  },
  {
    country_code: "CH",
    zipcode: "1306",
    place: "Daillens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Daillens",
    community_code: "5480",
    latitude: "46.6211",
    longitude: "6.5487",
  },
  {
    country_code: "CH",
    zipcode: "1307",
    place: "Lussery-Villars",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Lussery-Villars",
    community_code: "5487",
    latitude: "46.6328",
    longitude: "6.5256",
  },
  {
    country_code: "CH",
    zipcode: "1375",
    place: "Penthéréaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Penthéréaz",
    community_code: "5531",
    latitude: "46.6817",
    longitude: "6.6039",
  },
  {
    country_code: "CH",
    zipcode: "1376",
    place: "Goumoens-la-Ville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Goumoëns",
    community_code: "5541",
    latitude: "46.6592",
    longitude: "6.604",
  },
  {
    country_code: "CH",
    zipcode: "1376",
    place: "Eclagnens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Goumoëns",
    community_code: "5541",
    latitude: "46.6513",
    longitude: "6.5919",
  },
  {
    country_code: "CH",
    zipcode: "1376",
    place: "Goumoens-le-Jux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Goumoëns",
    community_code: "5541",
    latitude: "46.6552",
    longitude: "6.5979",
  },
  {
    country_code: "CH",
    zipcode: "1377",
    place: "Oulens-sous-Echallens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Oulens-sous-Echallens",
    community_code: "5529",
    latitude: "46.6438",
    longitude: "6.577",
  },
  {
    country_code: "CH",
    zipcode: "1409",
    place: "Chanéaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.7288",
    longitude: "6.7448",
  },
  {
    country_code: "CH",
    zipcode: "1410",
    place: "Thierrens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.7038",
    longitude: "6.755",
  },
  {
    country_code: "CH",
    zipcode: "1410",
    place: "St-Cierges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.6894",
    longitude: "6.7354",
  },
  {
    country_code: "CH",
    zipcode: "1410",
    place: "Correvon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.7171",
    longitude: "6.7396",
  },
  {
    country_code: "CH",
    zipcode: "1410",
    place: "Denezy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.7215",
    longitude: "6.7818",
  },
  {
    country_code: "CH",
    zipcode: "1416",
    place: "Pailly",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Pailly",
    community_code: "5530",
    latitude: "46.7012",
    longitude: "6.6754",
  },
  {
    country_code: "CH",
    zipcode: "1417",
    place: "Essertines-sur-Yverdon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Essertines-sur-Yverdon",
    community_code: "5520",
    latitude: "46.7183",
    longitude: "6.6384",
  },
  {
    country_code: "CH",
    zipcode: "1417",
    place: "Epautheyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Essertines-sur-Yverdon",
    community_code: "5520",
    latitude: "46.7394",
    longitude: "6.6426",
  },
  {
    country_code: "CH",
    zipcode: "1418",
    place: "Vuarrens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Vuarrens",
    community_code: "5539",
    latitude: "46.6858",
    longitude: "6.6479",
  },
  {
    country_code: "CH",
    zipcode: "1515",
    place: "Neyruz-sur-Moudon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Gros-de-Vaud District",
    province_code: "2223",
    community: "Montanaire",
    community_code: "5693",
    latitude: "46.6503",
    longitude: "6.6536",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "La Praz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "La Praz",
    community_code: "5758",
    latitude: "46.6675",
    longitude: "6.4271",
  },
  {
    country_code: "CH",
    zipcode: "1321",
    place: "Arnex-sur-Orbe",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Arnex-sur-Orbe",
    community_code: "5743",
    latitude: "46.69",
    longitude: "6.5169",
  },
  {
    country_code: "CH",
    zipcode: "1322",
    place: "Croy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Croy",
    community_code: "5752",
    latitude: "46.6938",
    longitude: "6.4757",
  },
  {
    country_code: "CH",
    zipcode: "1323",
    place: "Romainmôtier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Romainmôtier-Envy",
    community_code: "5761",
    latitude: "46.6939",
    longitude: "6.4609",
  },
  {
    country_code: "CH",
    zipcode: "1324",
    place: "Premier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Premier",
    community_code: "5759",
    latitude: "46.7057",
    longitude: "6.4499",
  },
  {
    country_code: "CH",
    zipcode: "1325",
    place: "Vaulion",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Vaulion",
    community_code: "5765",
    latitude: "46.6891",
    longitude: "6.3894",
  },
  {
    country_code: "CH",
    zipcode: "1326",
    place: "Juriens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Juriens",
    community_code: "5754",
    latitude: "46.6885",
    longitude: "6.4457",
  },
  {
    country_code: "CH",
    zipcode: "1329",
    place: "Bretonnières",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bretonnières",
    community_code: "5748",
    latitude: "46.7124",
    longitude: "6.4696",
  },
  {
    country_code: "CH",
    zipcode: "1337",
    place: "Vallorbe",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Vallorbe",
    community_code: "5764",
    latitude: "46.7126",
    longitude: "6.3789",
  },
  {
    country_code: "CH",
    zipcode: "1338",
    place: "Ballaigues",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Ballaigues",
    community_code: "5744",
    latitude: "46.7298",
    longitude: "6.4136",
  },
  {
    country_code: "CH",
    zipcode: "1341",
    place: "Orient",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Chenit",
    community_code: "5872",
    latitude: "46.6015",
    longitude: "6.2394",
  },
  {
    country_code: "CH",
    zipcode: "1342",
    place: "Le Pont",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "L'Abbaye",
    community_code: "5871",
    latitude: "46.6658",
    longitude: "6.3309",
  },
  {
    country_code: "CH",
    zipcode: "1343",
    place: "Les Charbonnières",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Lieu",
    community_code: "5873",
    latitude: "46.6667",
    longitude: "6.3156",
  },
  {
    country_code: "CH",
    zipcode: "1344",
    place: "L'Abbaye",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "L'Abbaye",
    community_code: "5871",
    latitude: "46.6497",
    longitude: "6.3191",
  },
  {
    country_code: "CH",
    zipcode: "1345",
    place: "Le Séchey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Lieu",
    community_code: "5873",
    latitude: "46.6614",
    longitude: "6.3009",
  },
  {
    country_code: "CH",
    zipcode: "1345",
    place: "Le Lieu",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Lieu",
    community_code: "5873",
    latitude: "46.6478",
    longitude: "6.2828",
  },
  {
    country_code: "CH",
    zipcode: "1346",
    place: "Les Bioux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "L'Abbaye",
    community_code: "5871",
    latitude: "46.6207",
    longitude: "6.2699",
  },
  {
    country_code: "CH",
    zipcode: "1347",
    place: "Le Solliat",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Chenit",
    community_code: "5872",
    latitude: "46.6211",
    longitude: "6.2355",
  },
  {
    country_code: "CH",
    zipcode: "1347",
    place: "Le Sentier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Chenit",
    community_code: "5872",
    latitude: "46.608",
    longitude: "6.2324",
  },
  {
    country_code: "CH",
    zipcode: "1348",
    place: "Le Brassus",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Le Chenit",
    community_code: "5872",
    latitude: "46.5841",
    longitude: "6.2123",
  },
  {
    country_code: "CH",
    zipcode: "1350",
    place: "Orbe",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Orbe",
    community_code: "5757",
    latitude: "46.725",
    longitude: "6.5307",
  },
  {
    country_code: "CH",
    zipcode: "1352",
    place: "Agiez",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Agiez",
    community_code: "5742",
    latitude: "46.721",
    longitude: "6.5075",
  },
  {
    country_code: "CH",
    zipcode: "1353",
    place: "Bofflens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bofflens",
    community_code: "5747",
    latitude: "46.7031",
    longitude: "6.4948",
  },
  {
    country_code: "CH",
    zipcode: "1354",
    place: "Montcherand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Montcherand",
    community_code: "5756",
    latitude: "46.7336",
    longitude: "6.5082",
  },
  {
    country_code: "CH",
    zipcode: "1355",
    place: "Sergey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sergey",
    community_code: "5762",
    latitude: "46.7493",
    longitude: "6.5013",
  },
  {
    country_code: "CH",
    zipcode: "1355",
    place: "L'Abergement",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "L'Abergement",
    community_code: "5741",
    latitude: "46.7542",
    longitude: "6.485",
  },
  {
    country_code: "CH",
    zipcode: "1356",
    place: "La Russille",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Les Clées",
    community_code: "5750",
    latitude: "46.7379",
    longitude: "6.4808",
  },
  {
    country_code: "CH",
    zipcode: "1356",
    place: "Les Clées",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Les Clées",
    community_code: "5750",
    latitude: "46.7379",
    longitude: "6.4808",
  },
  {
    country_code: "CH",
    zipcode: "1357",
    place: "Lignerolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Lignerolle",
    community_code: "5755",
    latitude: "46.7408",
    longitude: "6.4587",
  },
  {
    country_code: "CH",
    zipcode: "1358",
    place: "Valeyres-sous-Rances",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Valeyres-sous-Rances",
    community_code: "5763",
    latitude: "46.753",
    longitude: "6.5262",
  },
  {
    country_code: "CH",
    zipcode: "1372",
    place: "Bavois",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bavois",
    community_code: "5746",
    latitude: "46.684",
    longitude: "6.5671",
  },
  {
    country_code: "CH",
    zipcode: "1373",
    place: "Chavornay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chavornay",
    community_code: "5749",
    latitude: "46.7024",
    longitude: "6.5694",
  },
  {
    country_code: "CH",
    zipcode: "1374",
    place: "Corcelles-sur-Chavornay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chavornay",
    community_code: "5749",
    latitude: "46.7065",
    longitude: "6.569",
  },
  {
    country_code: "CH",
    zipcode: "1400",
    place: "Yverdon-les-Bains",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Yverdon-les-Bains",
    community_code: "5938",
    latitude: "46.7785",
    longitude: "6.6411",
  },
  {
    country_code: "CH",
    zipcode: "1400",
    place: "Cheseaux-Noréaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Cheseaux-Noréaz",
    community_code: "5909",
    latitude: "46.7894",
    longitude: "6.6892",
  },
  {
    country_code: "CH",
    zipcode: "1401",
    place: "Yverdon-les-Bains",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Yverdon-les-Bains",
    community_code: "5938",
    latitude: "46.7743",
    longitude: "6.6371",
  },
  {
    country_code: "CH",
    zipcode: "1404",
    place: "Cuarny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Cuarny",
    community_code: "5911",
    latitude: "46.7699",
    longitude: "6.6921",
  },
  {
    country_code: "CH",
    zipcode: "1404",
    place: "Villars-Epeney",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Villars-Epeney",
    community_code: "5935",
    latitude: "46.7826",
    longitude: "6.6976",
  },
  {
    country_code: "CH",
    zipcode: "1405",
    place: "Pomy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Pomy",
    community_code: "5926",
    latitude: "46.7586",
    longitude: "6.6691",
  },
  {
    country_code: "CH",
    zipcode: "1406",
    place: "Cronay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Cronay",
    community_code: "5910",
    latitude: "46.7566",
    longitude: "6.6977",
  },
  {
    country_code: "CH",
    zipcode: "1407",
    place: "Gossens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Donneloye",
    community_code: "5913",
    latitude: "46.7393",
    longitude: "6.7003",
  },
  {
    country_code: "CH",
    zipcode: "1407",
    place: "Bioley-Magnoux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bioley-Magnoux",
    community_code: "5903",
    latitude: "46.7259",
    longitude: "6.7108",
  },
  {
    country_code: "CH",
    zipcode: "1407",
    place: "Mézery-près-Donneloye",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Donneloye",
    community_code: "5913",
    latitude: "46.7369",
    longitude: "6.7092",
  },
  {
    country_code: "CH",
    zipcode: "1407",
    place: "Donneloye",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Donneloye",
    community_code: "5913",
    latitude: "46.7455",
    longitude: "6.7164",
  },
  {
    country_code: "CH",
    zipcode: "1408",
    place: "Prahins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Donneloye",
    community_code: "5913",
    latitude: "46.7348",
    longitude: "6.7386",
  },
  {
    country_code: "CH",
    zipcode: "1412",
    place: "Ursins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Ursins",
    community_code: "5932",
    latitude: "46.7373",
    longitude: "6.6682",
  },
  {
    country_code: "CH",
    zipcode: "1412",
    place: "Valeyres-sous-Ursins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Valeyres-sous-Ursins",
    community_code: "5934",
    latitude: "46.7471",
    longitude: "6.6523",
  },
  {
    country_code: "CH",
    zipcode: "1413",
    place: "Orzens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Orzens",
    community_code: "5925",
    latitude: "46.7263",
    longitude: "6.683",
  },
  {
    country_code: "CH",
    zipcode: "1415",
    place: "Démoret",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Démoret",
    community_code: "5912",
    latitude: "46.7469",
    longitude: "6.7609",
  },
  {
    country_code: "CH",
    zipcode: "1415",
    place: "Molondin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Molondin",
    community_code: "5921",
    latitude: "46.7591",
    longitude: "6.7446",
  },
  {
    country_code: "CH",
    zipcode: "1420",
    place: "Fiez",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Fiez",
    community_code: "5556",
    latitude: "46.8282",
    longitude: "6.6239",
  },
  {
    country_code: "CH",
    zipcode: "1421",
    place: "Grandevent",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Grandevent",
    community_code: "5560",
    latitude: "46.8389",
    longitude: "6.6066",
  },
  {
    country_code: "CH",
    zipcode: "1421",
    place: "Fontaines-sur-Grandson",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Fontaines-sur-Grandson",
    community_code: "5557",
    latitude: "46.8282",
    longitude: "6.6239",
  },
  {
    country_code: "CH",
    zipcode: "1422",
    place: "Grandson",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Grandson",
    community_code: "5561",
    latitude: "46.8095",
    longitude: "6.646",
  },
  {
    country_code: "CH",
    zipcode: "1423",
    place: "Villars-Burquin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Tévenon",
    community_code: "5571",
    latitude: "46.8488",
    longitude: "6.6276",
  },
  {
    country_code: "CH",
    zipcode: "1423",
    place: "Romairon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Tévenon",
    community_code: "5571",
    latitude: "46.8502",
    longitude: "6.6425",
  },
  {
    country_code: "CH",
    zipcode: "1423",
    place: "Fontanezier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Tévenon",
    community_code: "5571",
    latitude: "46.8547",
    longitude: "6.6559",
  },
  {
    country_code: "CH",
    zipcode: "1423",
    place: "Vaugondry",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Tévenon",
    community_code: "5571",
    latitude: "46.8461",
    longitude: "6.6364",
  },
  {
    country_code: "CH",
    zipcode: "1424",
    place: "Champagne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Champagne",
    community_code: "5553",
    latitude: "46.8321",
    longitude: "6.6598",
  },
  {
    country_code: "CH",
    zipcode: "1425",
    place: "Onnens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Onnens (VD)",
    community_code: "5565",
    latitude: "46.8389",
    longitude: "6.689",
  },
  {
    country_code: "CH",
    zipcode: "1426",
    place: "Concise",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Concise",
    community_code: "5554",
    latitude: "46.8503",
    longitude: "6.7197",
  },
  {
    country_code: "CH",
    zipcode: "1426",
    place: "Corcelles-près-Concise",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Corcelles-près-Concise",
    community_code: "5555",
    latitude: "46.8518",
    longitude: "6.7027",
  },
  {
    country_code: "CH",
    zipcode: "1427",
    place: "Bonvillars",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bonvillars",
    community_code: "5551",
    latitude: "46.8398",
    longitude: "6.6713",
  },
  {
    country_code: "CH",
    zipcode: "1428",
    place: "Provence",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Provence",
    community_code: "5566",
    latitude: "46.8905",
    longitude: "6.7261",
  },
  {
    country_code: "CH",
    zipcode: "1428",
    place: "Mutrux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Mutrux",
    community_code: "5563",
    latitude: "46.8808",
    longitude: "6.7266",
  },
  {
    country_code: "CH",
    zipcode: "1429",
    place: "Giez",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Giez",
    community_code: "5559",
    latitude: "46.816",
    longitude: "6.6171",
  },
  {
    country_code: "CH",
    zipcode: "1430",
    place: "Orges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Orges",
    community_code: "5924",
    latitude: "46.8093",
    longitude: "6.5835",
  },
  {
    country_code: "CH",
    zipcode: "1431",
    place: "Novalles",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Novalles",
    community_code: "5564",
    latitude: "46.8284",
    longitude: "6.5951",
  },
  {
    country_code: "CH",
    zipcode: "1431",
    place: "Vugelles-La Mothe",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Vugelles-La Mothe",
    community_code: "5937",
    latitude: "46.825",
    longitude: "6.5738",
  },
  {
    country_code: "CH",
    zipcode: "1432",
    place: "Belmont-sur-Yverdon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Belmont-sur-Yverdon",
    community_code: "5902",
    latitude: "46.7418",
    longitude: "6.6247",
  },
  {
    country_code: "CH",
    zipcode: "1432",
    place: "Gressy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Yverdon-les-Bains",
    community_code: "5938",
    latitude: "46.7502",
    longitude: "6.6364",
  },
  {
    country_code: "CH",
    zipcode: "1433",
    place: "Suchy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Suchy",
    community_code: "5929",
    latitude: "46.7235",
    longitude: "6.5993",
  },
  {
    country_code: "CH",
    zipcode: "1434",
    place: "Ependes VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Ependes (VD)",
    community_code: "5914",
    latitude: "46.7428",
    longitude: "6.6081",
  },
  {
    country_code: "CH",
    zipcode: "1435",
    place: "Essert-Pittet",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chavornay",
    community_code: "5749",
    latitude: "46.7065",
    longitude: "6.569",
  },
  {
    country_code: "CH",
    zipcode: "1436",
    place: "Chamblon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chamblon",
    community_code: "5904",
    latitude: "46.7791",
    longitude: "6.6026",
  },
  {
    country_code: "CH",
    zipcode: "1436",
    place: "Treycovagnes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Treycovagnes",
    community_code: "5931",
    latitude: "46.7739",
    longitude: "6.6124",
  },
  {
    country_code: "CH",
    zipcode: "1437",
    place: "Suscévaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Suscévaz",
    community_code: "5930",
    latitude: "46.7622",
    longitude: "6.5868",
  },
  {
    country_code: "CH",
    zipcode: "1438",
    place: "Mathod",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Mathod",
    community_code: "5919",
    latitude: "46.7664",
    longitude: "6.5647",
  },
  {
    country_code: "CH",
    zipcode: "1439",
    place: "Rances",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Rances",
    community_code: "5760",
    latitude: "46.7607",
    longitude: "6.5297",
  },
  {
    country_code: "CH",
    zipcode: "1440",
    place: "Montagny-Chamard",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Montagny-près-Yverdon",
    community_code: "5922",
    latitude: "46.7904",
    longitude: "6.6098",
  },
  {
    country_code: "CH",
    zipcode: "1441",
    place: "Valeyres-sous-Montagny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Valeyres-sous-Montagny",
    community_code: "5933",
    latitude: "46.8005",
    longitude: "6.6037",
  },
  {
    country_code: "CH",
    zipcode: "1442",
    place: "Montagny-près-Yverdon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Montagny-près-Yverdon",
    community_code: "5922",
    latitude: "46.7929",
    longitude: "6.6122",
  },
  {
    country_code: "CH",
    zipcode: "1443",
    place: "Villars-sous-Champvent",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Champvent",
    community_code: "5905",
    latitude: "46.7829",
    longitude: "6.5719",
  },
  {
    country_code: "CH",
    zipcode: "1443",
    place: "Champvent",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Champvent",
    community_code: "5905",
    latitude: "46.7829",
    longitude: "6.5719",
  },
  {
    country_code: "CH",
    zipcode: "1443",
    place: "Essert-sous-Champvent",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Champvent",
    community_code: "5905",
    latitude: "46.7829",
    longitude: "6.5719",
  },
  {
    country_code: "CH",
    zipcode: "1445",
    place: "Vuiteboeuf",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Vuiteboeuf",
    community_code: "5766",
    latitude: "46.8092",
    longitude: "6.5504",
  },
  {
    country_code: "CH",
    zipcode: "1446",
    place: "Baulmes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Baulmes",
    community_code: "5745",
    latitude: "46.7903",
    longitude: "6.5228",
  },
  {
    country_code: "CH",
    zipcode: "1450",
    place: "La Sagne (Ste-Croix)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sainte-Croix",
    community_code: "5568",
    latitude: "46.8165",
    longitude: "6.4982",
  },
  {
    country_code: "CH",
    zipcode: "1450",
    place: "Le Château-de-Ste-Croix",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sainte-Croix",
    community_code: "5568",
    latitude: "46.8182",
    longitude: "6.5341",
  },
  {
    country_code: "CH",
    zipcode: "1450",
    place: "Ste-Croix",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sainte-Croix",
    community_code: "5568",
    latitude: "46.8174",
    longitude: "6.5161",
  },
  {
    country_code: "CH",
    zipcode: "1452",
    place: "Les Rasses",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bullet",
    community_code: "5552",
    latitude: "46.829",
    longitude: "6.5391",
  },
  {
    country_code: "CH",
    zipcode: "1453",
    place: "Mauborget",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Mauborget",
    community_code: "5562",
    latitude: "46.8561",
    longitude: "6.6156",
  },
  {
    country_code: "CH",
    zipcode: "1453",
    place: "Bullet",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Bullet",
    community_code: "5552",
    latitude: "46.8312",
    longitude: "6.554",
  },
  {
    country_code: "CH",
    zipcode: "1454",
    place: "L'Auberson",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sainte-Croix",
    community_code: "5568",
    latitude: "46.8192",
    longitude: "6.4597",
  },
  {
    country_code: "CH",
    zipcode: "1454",
    place: "La Vraconnaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Sainte-Croix",
    community_code: "5568",
    latitude: "46.8426",
    longitude: "6.4837",
  },
  {
    country_code: "CH",
    zipcode: "1462",
    place: "Yvonand",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Yvonand",
    community_code: "5939",
    latitude: "46.8003",
    longitude: "6.7425",
  },
  {
    country_code: "CH",
    zipcode: "1463",
    place: "Rovray",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Rovray",
    community_code: "5928",
    latitude: "46.7849",
    longitude: "6.7644",
  },
  {
    country_code: "CH",
    zipcode: "1464",
    place: "Chêne-Pâquier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chêne-Pâquier",
    community_code: "5908",
    latitude: "46.7715",
    longitude: "6.7694",
  },
  {
    country_code: "CH",
    zipcode: "1464",
    place: "Chavannes-le-Chêne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Jura-Nord vaudois District",
    province_code: "2224",
    community: "Chavannes-le-Chêne",
    community_code: "5907",
    latitude: "46.777",
    longitude: "6.7779",
  },
  {
    country_code: "CH",
    zipcode: "1000",
    place: "Lausanne 26",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5182",
    longitude: "6.6329",
  },
  {
    country_code: "CH",
    zipcode: "1000",
    place: "Lausanne 25",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5182",
    longitude: "6.6329",
  },
  {
    country_code: "CH",
    zipcode: "1000",
    place: "Lausanne 27",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5182",
    longitude: "6.6329",
  },
  {
    country_code: "CH",
    zipcode: "1001",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5218",
    longitude: "6.633",
  },
  {
    country_code: "CH",
    zipcode: "1002",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5218",
    longitude: "6.633",
  },
  {
    country_code: "CH",
    zipcode: "1003",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1004",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1005",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1006",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1007",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1008",
    place: "Jouxtens-Mézery",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Jouxtens-Mézery",
    community_code: "5585",
    latitude: "46.5556",
    longitude: "6.6437",
  },
  {
    country_code: "CH",
    zipcode: "1010",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1011",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1012",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1014",
    place: "Lausanne Adm cant",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5218",
    longitude: "6.633",
  },
  {
    country_code: "CH",
    zipcode: "1015",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1017",
    place: "Lausanne Veillon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5218",
    longitude: "6.633",
  },
  {
    country_code: "CH",
    zipcode: "1018",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.516",
    longitude: "6.6328",
  },
  {
    country_code: "CH",
    zipcode: "1019",
    place: "Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Lausanne",
    community_code: "5586",
    latitude: "46.5218",
    longitude: "6.633",
  },
  {
    country_code: "CH",
    zipcode: "1032",
    place: "Romanel-sur-Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Romanel-sur-Lausanne",
    community_code: "5592",
    latitude: "46.564",
    longitude: "6.6054",
  },
  {
    country_code: "CH",
    zipcode: "1033",
    place: "Cheseaux-sur-Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Cheseaux-sur-Lausanne",
    community_code: "5582",
    latitude: "46.5862",
    longitude: "6.6059",
  },
  {
    country_code: "CH",
    zipcode: "1039",
    place: "Cheseaux Polyval",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Cheseaux-sur-Lausanne",
    community_code: "5582",
    latitude: "46.5845",
    longitude: "6.5955",
  },
  {
    country_code: "CH",
    zipcode: "1052",
    place: "Le Mont-sur-Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Le Mont-sur-Lausanne",
    community_code: "5587",
    latitude: "46.5582",
    longitude: "6.6315",
  },
  {
    country_code: "CH",
    zipcode: "1066",
    place: "Epalinges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lausanne District",
    province_code: "2225",
    community: "Epalinges",
    community_code: "5584",
    latitude: "46.549",
    longitude: "6.6683",
  },
  {
    country_code: "CH",
    zipcode: "1009",
    place: "Pully",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Pully",
    community_code: "5590",
    latitude: "46.5103",
    longitude: "6.6618",
  },
  {
    country_code: "CH",
    zipcode: "1068",
    place: "Les Monts-de-Pully",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Pully",
    community_code: "5590",
    latitude: "46.5373",
    longitude: "6.6876",
  },
  {
    country_code: "CH",
    zipcode: "1070",
    place: "Puidoux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Puidoux",
    community_code: "5607",
    latitude: "46.5009",
    longitude: "6.7825",
  },
  {
    country_code: "CH",
    zipcode: "1071",
    place: "St-Saphorin (Lavaux)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Saint-Saphorin (Lavaux)",
    community_code: "5610",
    latitude: "46.4733",
    longitude: "6.796",
  },
  {
    country_code: "CH",
    zipcode: "1071",
    place: "Chexbres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Chexbres",
    community_code: "5601",
    latitude: "46.4821",
    longitude: "6.778",
  },
  {
    country_code: "CH",
    zipcode: "1071",
    place: "Rivaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Rivaz",
    community_code: "5609",
    latitude: "46.4763",
    longitude: "6.7788",
  },
  {
    country_code: "CH",
    zipcode: "1072",
    place: "Forel (Lavaux)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Forel (Lavaux)",
    community_code: "5604",
    latitude: "46.5379",
    longitude: "6.7629",
  },
  {
    country_code: "CH",
    zipcode: "1073",
    place: "Mollie-Margot",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Savigny",
    community_code: "5611",
    latitude: "46.558",
    longitude: "6.7513",
  },
  {
    country_code: "CH",
    zipcode: "1073",
    place: "Savigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Savigny",
    community_code: "5611",
    latitude: "46.5384",
    longitude: "6.7322",
  },
  {
    country_code: "CH",
    zipcode: "1076",
    place: "Ferlens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Jorat-Mézières",
    community_code: "5806",
    latitude: "46.5881",
    longitude: "6.7808",
  },
  {
    country_code: "CH",
    zipcode: "1077",
    place: "Servion",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Servion",
    community_code: "5799",
    latitude: "46.5731",
    longitude: "6.7494",
  },
  {
    country_code: "CH",
    zipcode: "1078",
    place: "Essertes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Essertes",
    community_code: "5788",
    latitude: "46.5619",
    longitude: "6.7864",
  },
  {
    country_code: "CH",
    zipcode: "1080",
    place: "Les Cullayes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Servion",
    community_code: "5799",
    latitude: "46.5731",
    longitude: "6.7494",
  },
  {
    country_code: "CH",
    zipcode: "1081",
    place: "Montpreveyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Montpreveyres",
    community_code: "5792",
    latitude: "46.5826",
    longitude: "6.7411",
  },
  {
    country_code: "CH",
    zipcode: "1083",
    place: "Mézières VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Jorat-Mézières",
    community_code: "5806",
    latitude: "46.5915",
    longitude: "6.7657",
  },
  {
    country_code: "CH",
    zipcode: "1084",
    place: "Carrouge VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Jorat-Mézières",
    community_code: "5806",
    latitude: "46.5377",
    longitude: "6.7677",
  },
  {
    country_code: "CH",
    zipcode: "1090",
    place: "La Croix (Lutry)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Lutry",
    community_code: "5606",
    latitude: "46.5179",
    longitude: "6.7036",
  },
  {
    country_code: "CH",
    zipcode: "1091",
    place: "Aran",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4993",
    longitude: "6.7104",
  },
  {
    country_code: "CH",
    zipcode: "1091",
    place: "Chenaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4957",
    longitude: "6.7302",
  },
  {
    country_code: "CH",
    zipcode: "1091",
    place: "Grandvaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4938",
    longitude: "6.7161",
  },
  {
    country_code: "CH",
    zipcode: "1092",
    place: "Belmont-sur-Lausanne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Belmont-sur-Lausanne",
    community_code: "5581",
    latitude: "46.5189",
    longitude: "6.6764",
  },
  {
    country_code: "CH",
    zipcode: "1093",
    place: "La Conversion",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Lutry",
    community_code: "5606",
    latitude: "46.5128",
    longitude: "6.6774",
  },
  {
    country_code: "CH",
    zipcode: "1094",
    place: "Paudex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Paudex",
    community_code: "5588",
    latitude: "46.5055",
    longitude: "6.6682",
  },
  {
    country_code: "CH",
    zipcode: "1095",
    place: "Lutry",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Lutry",
    community_code: "5606",
    latitude: "46.5024",
    longitude: "6.6865",
  },
  {
    country_code: "CH",
    zipcode: "1096",
    place: "Cully",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4889",
    longitude: "6.7294",
  },
  {
    country_code: "CH",
    zipcode: "1096",
    place: "Villette (Lavaux)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4957",
    longitude: "6.709",
  },
  {
    country_code: "CH",
    zipcode: "1097",
    place: "Riex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4935",
    longitude: "6.7356",
  },
  {
    country_code: "CH",
    zipcode: "1098",
    place: "Epesses",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Bourg-en-Lavaux",
    community_code: "5613",
    latitude: "46.4913",
    longitude: "6.7478",
  },
  {
    country_code: "CH",
    zipcode: "1607",
    place: "Palézieux-Village",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5553",
    longitude: "6.8311",
  },
  {
    country_code: "CH",
    zipcode: "1607",
    place: "Les Thioleyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5389",
    longitude: "6.8114",
  },
  {
    country_code: "CH",
    zipcode: "1607",
    place: "Les Tavernes",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5548",
    longitude: "6.8106",
  },
  {
    country_code: "CH",
    zipcode: "1607",
    place: "Palézieux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5419",
    longitude: "6.8399",
  },
  {
    country_code: "CH",
    zipcode: "1608",
    place: "Chesalles-sur-Oron",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5775",
    longitude: "6.8558",
  },
  {
    country_code: "CH",
    zipcode: "1608",
    place: "Oron-le-Châtel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.573",
    longitude: "6.8404",
  },
  {
    country_code: "CH",
    zipcode: "1608",
    place: "Bussigny-sur-Oron",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5698",
    longitude: "6.8648",
  },
  {
    country_code: "CH",
    zipcode: "1610",
    place: "Vuibroye",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5714",
    longitude: "6.8047",
  },
  {
    country_code: "CH",
    zipcode: "1610",
    place: "Oron-la-Ville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5709",
    longitude: "6.8256",
  },
  {
    country_code: "CH",
    zipcode: "1610",
    place: "Châtillens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5647",
    longitude: "6.8128",
  },
  {
    country_code: "CH",
    zipcode: "1612",
    place: "Ecoteaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Oron",
    community_code: "5805",
    latitude: "46.5469",
    longitude: "6.8641",
  },
  {
    country_code: "CH",
    zipcode: "1613",
    place: "Maracon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Lavaux-Oron District",
    province_code: "2226",
    community: "Maracon",
    community_code: "5790",
    latitude: "46.5501",
    longitude: "6.8722",
  },
  {
    country_code: "CH",
    zipcode: "1026",
    place: "Echandens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Echandens",
    community_code: "5633",
    latitude: "46.5397",
    longitude: "6.5379",
  },
  {
    country_code: "CH",
    zipcode: "1026",
    place: "Denges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Denges",
    community_code: "5632",
    latitude: "46.522",
    longitude: "6.5393",
  },
  {
    country_code: "CH",
    zipcode: "1027",
    place: "Lonay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Lonay",
    community_code: "5638",
    latitude: "46.5288",
    longitude: "6.5194",
  },
  {
    country_code: "CH",
    zipcode: "1028",
    place: "Préverenges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Préverenges",
    community_code: "5643",
    latitude: "46.5185",
    longitude: "6.5268",
  },
  {
    country_code: "CH",
    zipcode: "1110",
    place: "Morges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Morges",
    community_code: "5642",
    latitude: "46.5113",
    longitude: "6.4985",
  },
  {
    country_code: "CH",
    zipcode: "1112",
    place: "Echichens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Echichens",
    community_code: "5634",
    latitude: "46.5303",
    longitude: "6.4968",
  },
  {
    country_code: "CH",
    zipcode: "1113",
    place: "St-Saphorin-sur-Morges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Echichens",
    community_code: "5634",
    latitude: "46.5469",
    longitude: "6.4885",
  },
  {
    country_code: "CH",
    zipcode: "1114",
    place: "Colombier VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Echichens",
    community_code: "5634",
    latitude: "46.5571",
    longitude: "6.4728",
  },
  {
    country_code: "CH",
    zipcode: "1115",
    place: "Vullierens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Vullierens",
    community_code: "5654",
    latitude: "46.574",
    longitude: "6.4819",
  },
  {
    country_code: "CH",
    zipcode: "1116",
    place: "Cottens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Cottens (VD)",
    community_code: "5478",
    latitude: "46.5725",
    longitude: "6.4564",
  },
  {
    country_code: "CH",
    zipcode: "1117",
    place: "Grancy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Grancy",
    community_code: "5485",
    latitude: "46.5921",
    longitude: "6.4639",
  },
  {
    country_code: "CH",
    zipcode: "1121",
    place: "Bremblens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Bremblens",
    community_code: "5622",
    latitude: "46.5483",
    longitude: "6.5194",
  },
  {
    country_code: "CH",
    zipcode: "1122",
    place: "Romanel-sur-Morges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Romanel-sur-Morges",
    community_code: "5645",
    latitude: "46.5561",
    longitude: "6.5104",
  },
  {
    country_code: "CH",
    zipcode: "1123",
    place: "Aclens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Aclens",
    community_code: "5621",
    latitude: "46.5663",
    longitude: "6.5115",
  },
  {
    country_code: "CH",
    zipcode: "1124",
    place: "Gollion",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Gollion",
    community_code: "5484",
    latitude: "46.5852",
    longitude: "6.5097",
  },
  {
    country_code: "CH",
    zipcode: "1125",
    place: "Monnaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Echichens",
    community_code: "5634",
    latitude: "46.5325",
    longitude: "6.4806",
  },
  {
    country_code: "CH",
    zipcode: "1126",
    place: "Vaux-sur-Morges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Vaux-sur-Morges",
    community_code: "5650",
    latitude: "46.5385",
    longitude: "6.4639",
  },
  {
    country_code: "CH",
    zipcode: "1127",
    place: "Clarmont",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Clarmont",
    community_code: "5629",
    latitude: "46.5464",
    longitude: "6.4495",
  },
  {
    country_code: "CH",
    zipcode: "1128",
    place: "Reverolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Reverolle",
    community_code: "5644",
    latitude: "46.5428",
    longitude: "6.4405",
  },
  {
    country_code: "CH",
    zipcode: "1131",
    place: "Tolochenaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Tolochenaz",
    community_code: "5649",
    latitude: "46.505",
    longitude: "6.4728",
  },
  {
    country_code: "CH",
    zipcode: "1132",
    place: "Lully VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Lully (VD)",
    community_code: "5639",
    latitude: "46.5051",
    longitude: "6.4648",
  },
  {
    country_code: "CH",
    zipcode: "1134",
    place: "Chigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Chigny",
    community_code: "5628",
    latitude: "46.5197",
    longitude: "6.4766",
  },
  {
    country_code: "CH",
    zipcode: "1134",
    place: "Vufflens-le-Château",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Vufflens-le-Château",
    community_code: "5653",
    latitude: "46.5263",
    longitude: "6.4721",
  },
  {
    country_code: "CH",
    zipcode: "1135",
    place: "Denens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Denens",
    community_code: "5631",
    latitude: "46.5177",
    longitude: "6.4529",
  },
  {
    country_code: "CH",
    zipcode: "1136",
    place: "Bussy-Chardonney",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Bussy-Chardonney",
    community_code: "5625",
    latitude: "46.5305",
    longitude: "6.4425",
  },
  {
    country_code: "CH",
    zipcode: "1141",
    place: "Sévery",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Sévery",
    community_code: "5500",
    latitude: "46.5735",
    longitude: "6.4417",
  },
  {
    country_code: "CH",
    zipcode: "1142",
    place: "Pampigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Pampigny",
    community_code: "5494",
    latitude: "46.5809",
    longitude: "6.4294",
  },
  {
    country_code: "CH",
    zipcode: "1143",
    place: "Apples",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Apples",
    community_code: "5421",
    latitude: "46.5524",
    longitude: "6.4289",
  },
  {
    country_code: "CH",
    zipcode: "1144",
    place: "Ballens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Ballens",
    community_code: "5423",
    latitude: "46.5548",
    longitude: "6.3731",
  },
  {
    country_code: "CH",
    zipcode: "1145",
    place: "Bière",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Bière",
    community_code: "5425",
    latitude: "46.5376",
    longitude: "6.3336",
  },
  {
    country_code: "CH",
    zipcode: "1146",
    place: "Mollens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Mollens (VD)",
    community_code: "5431",
    latitude: "46.5776",
    longitude: "6.3632",
  },
  {
    country_code: "CH",
    zipcode: "1147",
    place: "Montricher",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Montricher",
    community_code: "5492",
    latitude: "46.5996",
    longitude: "6.3767",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Villars-Bozon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "L'Isle",
    community_code: "5486",
    latitude: "46.6065",
    longitude: "6.4067",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "La Coudre",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "L'Isle",
    community_code: "5486",
    latitude: "46.6404",
    longitude: "6.4036",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Chavannes-le-Veyron",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Chavannes-le-Veyron",
    community_code: "5475",
    latitude: "46.607",
    longitude: "6.4509",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "L'Isle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "L'Isle",
    community_code: "5486",
    latitude: "46.6184",
    longitude: "6.4133",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Moiry VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Moiry",
    community_code: "5490",
    latitude: "46.6491",
    longitude: "6.4534",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Mauraz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Mauraz",
    community_code: "5488",
    latitude: "46.6056",
    longitude: "6.4207",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Mont-la-Ville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Mont-la-Ville",
    community_code: "5491",
    latitude: "46.6466",
    longitude: "6.409",
  },
  {
    country_code: "CH",
    zipcode: "1148",
    place: "Cuarnens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Cuarnens",
    community_code: "5479",
    latitude: "46.6255",
    longitude: "6.4371",
  },
  {
    country_code: "CH",
    zipcode: "1149",
    place: "Berolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Berolle",
    community_code: "5424",
    latitude: "46.558",
    longitude: "6.3355",
  },
  {
    country_code: "CH",
    zipcode: "1162",
    place: "St-Prex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Saint-Prex",
    community_code: "5646",
    latitude: "46.4796",
    longitude: "6.4599",
  },
  {
    country_code: "CH",
    zipcode: "1163",
    place: "Etoy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Etoy",
    community_code: "5636",
    latitude: "46.4859",
    longitude: "6.4182",
  },
  {
    country_code: "CH",
    zipcode: "1164",
    place: "Buchillon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Buchillon",
    community_code: "5623",
    latitude: "46.4698",
    longitude: "6.4178",
  },
  {
    country_code: "CH",
    zipcode: "1165",
    place: "Allaman",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Allaman",
    community_code: "5851",
    latitude: "46.4701",
    longitude: "6.3964",
  },
  {
    country_code: "CH",
    zipcode: "1167",
    place: "Lussy-sur-Morges",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Lussy-sur-Morges",
    community_code: "5640",
    latitude: "46.5041",
    longitude: "6.45",
  },
  {
    country_code: "CH",
    zipcode: "1168",
    place: "Villars-sous-Yens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Villars-sous-Yens",
    community_code: "5652",
    latitude: "46.5092",
    longitude: "6.4293",
  },
  {
    country_code: "CH",
    zipcode: "1169",
    place: "Yens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Yens",
    community_code: "5655",
    latitude: "46.519",
    longitude: "6.4185",
  },
  {
    country_code: "CH",
    zipcode: "1170",
    place: "Aubonne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Aubonne",
    community_code: "5422",
    latitude: "46.4951",
    longitude: "6.3916",
  },
  {
    country_code: "CH",
    zipcode: "1172",
    place: "Bougy-Villars",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Bougy-Villars",
    community_code: "5426",
    latitude: "46.4819",
    longitude: "6.3536",
  },
  {
    country_code: "CH",
    zipcode: "1173",
    place: "Féchy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Féchy",
    community_code: "5427",
    latitude: "46.4788",
    longitude: "6.3728",
  },
  {
    country_code: "CH",
    zipcode: "1174",
    place: "Montherod",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Montherod",
    community_code: "5432",
    latitude: "46.5017",
    longitude: "6.3615",
  },
  {
    country_code: "CH",
    zipcode: "1174",
    place: "Pizy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Aubonne",
    community_code: "5422",
    latitude: "46.4939",
    longitude: "6.3478",
  },
  {
    country_code: "CH",
    zipcode: "1175",
    place: "Lavigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Lavigny",
    community_code: "5637",
    latitude: "46.5012",
    longitude: "6.4109",
  },
  {
    country_code: "CH",
    zipcode: "1176",
    place: "St-Livres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Saint-Livres",
    community_code: "5435",
    latitude: "46.5079",
    longitude: "6.3875",
  },
  {
    country_code: "CH",
    zipcode: "1187",
    place: "St-Oyens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Saint-Oyens",
    community_code: "5436",
    latitude: "46.4994",
    longitude: "6.3033",
  },
  {
    country_code: "CH",
    zipcode: "1188",
    place: "Gimel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Gimel",
    community_code: "5428",
    latitude: "46.5095",
    longitude: "6.3074",
  },
  {
    country_code: "CH",
    zipcode: "1189",
    place: "Saubraz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Saubraz",
    community_code: "5437",
    latitude: "46.5161",
    longitude: "6.3302",
  },
  {
    country_code: "CH",
    zipcode: "1304",
    place: "Cossonay-Ville",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Cossonay",
    community_code: "5477",
    latitude: "46.6144",
    longitude: "6.5063",
  },
  {
    country_code: "CH",
    zipcode: "1304",
    place: "Allens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Cossonay",
    community_code: "5477",
    latitude: "46.6009",
    longitude: "6.5109",
  },
  {
    country_code: "CH",
    zipcode: "1304",
    place: "Dizy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Dizy",
    community_code: "5481",
    latitude: "46.6354",
    longitude: "6.4965",
  },
  {
    country_code: "CH",
    zipcode: "1304",
    place: "Senarclens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Senarclens",
    community_code: "5499",
    latitude: "46.5979",
    longitude: "6.4854",
  },
  {
    country_code: "CH",
    zipcode: "1308",
    place: "La Chaux (Cossonay)",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "La Chaux (Cossonay)",
    community_code: "5474",
    latitude: "46.6171",
    longitude: "6.4722",
  },
  {
    country_code: "CH",
    zipcode: "1312",
    place: "Eclépens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Eclépens",
    community_code: "5482",
    latitude: "46.6482",
    longitude: "6.5358",
  },
  {
    country_code: "CH",
    zipcode: "1313",
    place: "Ferreyres",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Ferreyres",
    community_code: "5483",
    latitude: "46.658",
    longitude: "6.4852",
  },
  {
    country_code: "CH",
    zipcode: "1315",
    place: "La Sarraz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "La Sarraz",
    community_code: "5498",
    latitude: "46.6586",
    longitude: "6.5108",
  },
  {
    country_code: "CH",
    zipcode: "1316",
    place: "Chevilly",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Chevilly",
    community_code: "5476",
    latitude: "46.6427",
    longitude: "6.4766",
  },
  {
    country_code: "CH",
    zipcode: "1317",
    place: "Orny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Orny",
    community_code: "5493",
    latitude: "46.6676",
    longitude: "6.5264",
  },
  {
    country_code: "CH",
    zipcode: "1318",
    place: "Pompaples",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Morges District",
    province_code: "2227",
    community: "Pompaples",
    community_code: "5497",
    latitude: "46.667",
    longitude: "6.5097",
  },
  {
    country_code: "CH",
    zipcode: "1166",
    place: "Perroy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Perroy",
    community_code: "5860",
    latitude: "46.4669",
    longitude: "6.3535",
  },
  {
    country_code: "CH",
    zipcode: "1180",
    place: "Tartegnin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Tartegnin",
    community_code: "5862",
    latitude: "46.4662",
    longitude: "6.3125",
  },
  {
    country_code: "CH",
    zipcode: "1180",
    place: "Rolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Rolle",
    community_code: "5861",
    latitude: "46.4582",
    longitude: "6.335",
  },
  {
    country_code: "CH",
    zipcode: "1182",
    place: "Gilly",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Gilly",
    community_code: "5857",
    latitude: "46.4578",
    longitude: "6.2965",
  },
  {
    country_code: "CH",
    zipcode: "1183",
    place: "Bursins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Bursins",
    community_code: "5853",
    latitude: "46.4528",
    longitude: "6.2914",
  },
  {
    country_code: "CH",
    zipcode: "1184",
    place: "Luins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Luins",
    community_code: "5858",
    latitude: "46.442",
    longitude: "6.2727",
  },
  {
    country_code: "CH",
    zipcode: "1184",
    place: "Vinzel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Vinzel",
    community_code: "5863",
    latitude: "46.4483",
    longitude: "6.2782",
  },
  {
    country_code: "CH",
    zipcode: "1185",
    place: "Mont-sur-Rolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Mont-sur-Rolle",
    community_code: "5859",
    latitude: "46.4702",
    longitude: "6.3352",
  },
  {
    country_code: "CH",
    zipcode: "1186",
    place: "Essertines-sur-Rolle",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Essertines-sur-Rolle",
    community_code: "5856",
    latitude: "46.4931",
    longitude: "6.3177",
  },
  {
    country_code: "CH",
    zipcode: "1188",
    place: "St-George",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Saint-George",
    community_code: "5434",
    latitude: "46.5143",
    longitude: "6.2598",
  },
  {
    country_code: "CH",
    zipcode: "1195",
    place: "Bursinel",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Bursinel",
    community_code: "5852",
    latitude: "46.4396",
    longitude: "6.3058",
  },
  {
    country_code: "CH",
    zipcode: "1195",
    place: "Dully",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Dully",
    community_code: "5855",
    latitude: "46.4314",
    longitude: "6.2946",
  },
  {
    country_code: "CH",
    zipcode: "1196",
    place: "Gland",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Gland",
    community_code: "5721",
    latitude: "46.4208",
    longitude: "6.2701",
  },
  {
    country_code: "CH",
    zipcode: "1197",
    place: "Prangins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Prangins",
    community_code: "5725",
    latitude: "46.3952",
    longitude: "6.2496",
  },
  {
    country_code: "CH",
    zipcode: "1260",
    place: "Nyon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Nyon",
    community_code: "5724",
    latitude: "46.3832",
    longitude: "6.2395",
  },
  {
    country_code: "CH",
    zipcode: "1261",
    place: "Le Vaud",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Le Vaud",
    community_code: "5731",
    latitude: "46.4775",
    longitude: "6.236",
  },
  {
    country_code: "CH",
    zipcode: "1261",
    place: "Marchissy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Marchissy",
    community_code: "5430",
    latitude: "46.4881",
    longitude: "6.2466",
  },
  {
    country_code: "CH",
    zipcode: "1261",
    place: "Longirod",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Longirod",
    community_code: "5429",
    latitude: "46.495",
    longitude: "6.2584",
  },
  {
    country_code: "CH",
    zipcode: "1262",
    place: "Eysins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Eysins",
    community_code: "5716",
    latitude: "46.3817",
    longitude: "6.207",
  },
  {
    country_code: "CH",
    zipcode: "1263",
    place: "Crassier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Crassier",
    community_code: "5714",
    latitude: "46.3746",
    longitude: "6.1637",
  },
  {
    country_code: "CH",
    zipcode: "1264",
    place: "St-Cergue",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Saint-Cergue",
    community_code: "5727",
    latitude: "46.4459",
    longitude: "6.1574",
  },
  {
    country_code: "CH",
    zipcode: "1265",
    place: "La Cure",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Saint-Cergue",
    community_code: "5727",
    latitude: "46.4647",
    longitude: "6.0742",
  },
  {
    country_code: "CH",
    zipcode: "1266",
    place: "Duillier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Duillier",
    community_code: "5715",
    latitude: "46.4092",
    longitude: "6.2298",
  },
  {
    country_code: "CH",
    zipcode: "1267",
    place: "Vich",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Vich",
    community_code: "5732",
    latitude: "46.4292",
    longitude: "6.2501",
  },
  {
    country_code: "CH",
    zipcode: "1267",
    place: "Coinsins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Coinsins",
    community_code: "5710",
    latitude: "46.4239",
    longitude: "6.2356",
  },
  {
    country_code: "CH",
    zipcode: "1268",
    place: "Begnins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Begnins",
    community_code: "5704",
    latitude: "46.4415",
    longitude: "6.2476",
  },
  {
    country_code: "CH",
    zipcode: "1268",
    place: "Burtigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Burtigny",
    community_code: "5854",
    latitude: "46.4675",
    longitude: "6.2571",
  },
  {
    country_code: "CH",
    zipcode: "1269",
    place: "Bassins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Bassins",
    community_code: "5703",
    latitude: "46.4627",
    longitude: "6.2362",
  },
  {
    country_code: "CH",
    zipcode: "1270",
    place: "Trélex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Trélex",
    community_code: "5730",
    latitude: "46.4154",
    longitude: "6.2081",
  },
  {
    country_code: "CH",
    zipcode: "1271",
    place: "Givrins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Givrins",
    community_code: "5720",
    latitude: "46.4297",
    longitude: "6.2021",
  },
  {
    country_code: "CH",
    zipcode: "1272",
    place: "Genolier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Genolier",
    community_code: "5718",
    latitude: "46.4354",
    longitude: "6.2181",
  },
  {
    country_code: "CH",
    zipcode: "1273",
    place: "Arzier-Le Muids",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Arzier-Le Muids",
    community_code: "5702",
    latitude: "46.4596",
    longitude: "6.2081",
  },
  {
    country_code: "CH",
    zipcode: "1274",
    place: "Grens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Grens",
    community_code: "5722",
    latitude: "46.394",
    longitude: "6.1911",
  },
  {
    country_code: "CH",
    zipcode: "1274",
    place: "Signy",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Signy-Avenex",
    community_code: "5728",
    latitude: "46.3917",
    longitude: "6.2029",
  },
  {
    country_code: "CH",
    zipcode: "1275",
    place: "Chéserex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Chéserex",
    community_code: "5709",
    latitude: "46.3993",
    longitude: "6.174",
  },
  {
    country_code: "CH",
    zipcode: "1276",
    place: "Gingins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Gingins",
    community_code: "5719",
    latitude: "46.4091",
    longitude: "6.1781",
  },
  {
    country_code: "CH",
    zipcode: "1277",
    place: "Arnex-sur-Nyon",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Arnex-sur-Nyon",
    community_code: "5701",
    latitude: "46.3729",
    longitude: "6.1898",
  },
  {
    country_code: "CH",
    zipcode: "1277",
    place: "Borex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Borex",
    community_code: "5706",
    latitude: "46.3789",
    longitude: "6.1762",
  },
  {
    country_code: "CH",
    zipcode: "1278",
    place: "La Rippe",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "La Rippe",
    community_code: "5726",
    latitude: "46.381",
    longitude: "6.1505",
  },
  {
    country_code: "CH",
    zipcode: "1279",
    place: "Bogis-Bossey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Bogis-Bossey",
    community_code: "5705",
    latitude: "46.3537",
    longitude: "6.166",
  },
  {
    country_code: "CH",
    zipcode: "1279",
    place: "Chavannes-de-Bogis",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Chavannes-de-Bogis",
    community_code: "5707",
    latitude: "46.3454",
    longitude: "6.162",
  },
  {
    country_code: "CH",
    zipcode: "1290",
    place: "Chavannes-des-Bois",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Chavannes-des-Bois",
    community_code: "5708",
    latitude: "46.314",
    longitude: "6.135",
  },
  {
    country_code: "CH",
    zipcode: "1291",
    place: "Commugny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Commugny",
    community_code: "5711",
    latitude: "46.321",
    longitude: "6.1712",
  },
  {
    country_code: "CH",
    zipcode: "1295",
    place: "Tannay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Tannay",
    community_code: "5729",
    latitude: "46.3088",
    longitude: "6.1773",
  },
  {
    country_code: "CH",
    zipcode: "1295",
    place: "Mies",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Mies",
    community_code: "5723",
    latitude: "46.3035",
    longitude: "6.1633",
  },
  {
    country_code: "CH",
    zipcode: "1296",
    place: "Coppet",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Coppet",
    community_code: "5712",
    latitude: "46.3168",
    longitude: "6.1911",
  },
  {
    country_code: "CH",
    zipcode: "1297",
    place: "Founex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Founex",
    community_code: "5717",
    latitude: "46.3328",
    longitude: "6.1924",
  },
  {
    country_code: "CH",
    zipcode: "1299",
    place: "Crans-près-Céligny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Nyon District",
    province_code: "2228",
    community: "Crans-près-Céligny",
    community_code: "5713",
    latitude: "46.3591",
    longitude: "6.2058",
  },
  {
    country_code: "CH",
    zipcode: "1008",
    place: "Prilly",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Prilly",
    community_code: "5589",
    latitude: "46.537",
    longitude: "6.6046",
  },
  {
    country_code: "CH",
    zipcode: "1020",
    place: "Renens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Renens (VD)",
    community_code: "5591",
    latitude: "46.5399",
    longitude: "6.5881",
  },
  {
    country_code: "CH",
    zipcode: "1022",
    place: "Chavannes-près-Renens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Chavannes-près-Renens",
    community_code: "5627",
    latitude: "46.5301",
    longitude: "6.5707",
  },
  {
    country_code: "CH",
    zipcode: "1023",
    place: "Crissier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Crissier",
    community_code: "5583",
    latitude: "46.5459",
    longitude: "6.5757",
  },
  {
    country_code: "CH",
    zipcode: "1024",
    place: "Ecublens VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Ecublens (VD)",
    community_code: "5635",
    latitude: "46.529",
    longitude: "6.5626",
  },
  {
    country_code: "CH",
    zipcode: "1025",
    place: "St-Sulpice VD",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Saint-Sulpice (VD)",
    community_code: "5648",
    latitude: "46.5105",
    longitude: "6.559",
  },
  {
    country_code: "CH",
    zipcode: "1029",
    place: "Villars-Ste-Croix",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Villars-Sainte-Croix",
    community_code: "5651",
    latitude: "46.5656",
    longitude: "6.5633",
  },
  {
    country_code: "CH",
    zipcode: "1030",
    place: "Bussigny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Ouest Lausannois District",
    province_code: "2229",
    community: "Bussigny",
    community_code: "5624",
    latitude: "46.5511",
    longitude: "6.556",
  },
  {
    country_code: "CH",
    zipcode: "1658",
    place: "Rossinière",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Rossinière",
    community_code: "5842",
    latitude: "46.4676",
    longitude: "7.0837",
  },
  {
    country_code: "CH",
    zipcode: "1658",
    place: "La Tine",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Rossinière",
    community_code: "5842",
    latitude: "46.4696",
    longitude: "7.0522",
  },
  {
    country_code: "CH",
    zipcode: "1659",
    place: "Flendruz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Rougemont",
    community_code: "5843",
    latitude: "46.4851",
    longitude: "7.1827",
  },
  {
    country_code: "CH",
    zipcode: "1659",
    place: "Rougemont",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Rougemont",
    community_code: "5843",
    latitude: "46.4881",
    longitude: "7.2066",
  },
  {
    country_code: "CH",
    zipcode: "1660",
    place: "L'Etivaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Château-d'Oex",
    community_code: "5841",
    latitude: "46.4248",
    longitude: "7.1462",
  },
  {
    country_code: "CH",
    zipcode: "1660",
    place: "La Lécherette",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Château-d'Oex",
    community_code: "5841",
    latitude: "46.4199",
    longitude: "7.1073",
  },
  {
    country_code: "CH",
    zipcode: "1660",
    place: "Château-d'Oex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Château-d'Oex",
    community_code: "5841",
    latitude: "46.4745",
    longitude: "7.1316",
  },
  {
    country_code: "CH",
    zipcode: "1660",
    place: "Les Moulins",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Château-d'Oex",
    community_code: "5841",
    latitude: "46.4619",
    longitude: "7.1051",
  },
  {
    country_code: "CH",
    zipcode: "1800",
    place: "Vevey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Vevey",
    community_code: "5890",
    latitude: "46.463",
    longitude: "6.8435",
  },
  {
    country_code: "CH",
    zipcode: "1801",
    place: "Le Mont-Pèlerin",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Chardonne",
    community_code: "5882",
    latitude: "46.4886",
    longitude: "6.8216",
  },
  {
    country_code: "CH",
    zipcode: "1802",
    place: "Corseaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Corseaux",
    community_code: "5883",
    latitude: "46.4722",
    longitude: "6.8294",
  },
  {
    country_code: "CH",
    zipcode: "1803",
    place: "Chardonne",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Chardonne",
    community_code: "5882",
    latitude: "46.4768",
    longitude: "6.8268",
  },
  {
    country_code: "CH",
    zipcode: "1804",
    place: "Corsier-sur-Vevey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Corsier-sur-Vevey",
    community_code: "5884",
    latitude: "46.4944",
    longitude: "6.871",
  },
  {
    country_code: "CH",
    zipcode: "1805",
    place: "Jongny",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Jongny",
    community_code: "5885",
    latitude: "46.4788",
    longitude: "6.8411",
  },
  {
    country_code: "CH",
    zipcode: "1806",
    place: "St-Légier-La Chiésaz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Saint-Légier-La Chiésaz",
    community_code: "5888",
    latitude: "46.4723",
    longitude: "6.8737",
  },
  {
    country_code: "CH",
    zipcode: "1807",
    place: "Blonay",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Blonay",
    community_code: "5881",
    latitude: "46.4678",
    longitude: "6.8962",
  },
  {
    country_code: "CH",
    zipcode: "1808",
    place: "Les Monts-de-Corsier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Corsier-sur-Vevey",
    community_code: "5884",
    latitude: "46.5044",
    longitude: "6.8807",
  },
  {
    country_code: "CH",
    zipcode: "1809",
    place: "Fenil-sur-Corsier",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Corsier-sur-Vevey",
    community_code: "5884",
    latitude: "46.4836",
    longitude: "6.8635",
  },
  {
    country_code: "CH",
    zipcode: "1811",
    place: "Vevey",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Vevey",
    community_code: "5890",
    latitude: "46.4642",
    longitude: "6.8481",
  },
  {
    country_code: "CH",
    zipcode: "1814",
    place: "La Tour-de-Peilz",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "La Tour-de-Peilz",
    community_code: "5889",
    latitude: "46.4531",
    longitude: "6.8586",
  },
  {
    country_code: "CH",
    zipcode: "1815",
    place: "Clarens",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4405",
    longitude: "6.8923",
  },
  {
    country_code: "CH",
    zipcode: "1816",
    place: "Chailly-Montreux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4543",
    longitude: "6.8928",
  },
  {
    country_code: "CH",
    zipcode: "1817",
    place: "Brent",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4593",
    longitude: "6.9032",
  },
  {
    country_code: "CH",
    zipcode: "1818",
    place: "Montreux Redoute",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4518",
    longitude: "6.9408",
  },
  {
    country_code: "CH",
    zipcode: "1820",
    place: "Territet",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4286",
    longitude: "6.9212",
  },
  {
    country_code: "CH",
    zipcode: "1820",
    place: "Veytaux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Veytaux",
    community_code: "5891",
    latitude: "46.4213",
    longitude: "6.9305",
  },
  {
    country_code: "CH",
    zipcode: "1820",
    place: "Montreux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.433",
    longitude: "6.9114",
  },
  {
    country_code: "CH",
    zipcode: "1822",
    place: "Chernex",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4439",
    longitude: "6.9115",
  },
  {
    country_code: "CH",
    zipcode: "1823",
    place: "Glion",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4336",
    longitude: "6.9262",
  },
  {
    country_code: "CH",
    zipcode: "1824",
    place: "Caux",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4324",
    longitude: "6.9386",
  },
  {
    country_code: "CH",
    zipcode: "1832",
    place: "Chamby",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4497",
    longitude: "6.9123",
  },
  {
    country_code: "CH",
    zipcode: "1832",
    place: "Villard-sur-Chamby",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.4722",
    longitude: "6.929",
  },
  {
    country_code: "CH",
    zipcode: "1833",
    place: "Les Avants",
    state: "Canton de Vaud",
    state_code: "VD",
    province: "Riviera-Pays-d'Enhaut District",
    province_code: "2230",
    community: "Montreux",
    community_code: "5886",
    latitude: "46.453",
    longitude: "6.9431",
  },
  {
    country_code: "CH",
    zipcode: "3900",
    place: "Brig",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Brig-Glis",
    community_code: "6002",
    latitude: "46.3167",
    longitude: "7.9833",
  },
  {
    country_code: "CH",
    zipcode: "3900",
    place: "Gamsen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Brig-Glis",
    community_code: "6002",
    latitude: "46.3048",
    longitude: "7.9536",
  },
  {
    country_code: "CH",
    zipcode: "3900",
    place: "Brigerbad",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Brig-Glis",
    community_code: "6002",
    latitude: "46.2999",
    longitude: "7.9179",
  },
  {
    country_code: "CH",
    zipcode: "3901",
    place: "Rothwald",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Ried-Brig",
    community_code: "6008",
    latitude: "46.2818",
    longitude: "8.0402",
  },
  {
    country_code: "CH",
    zipcode: "3902",
    place: "Glis",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Brig-Glis",
    community_code: "6002",
    latitude: "46.3093",
    longitude: "7.972",
  },
  {
    country_code: "CH",
    zipcode: "3903",
    place: "Birgisch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Naters",
    community_code: "6007",
    latitude: "46.3187",
    longitude: "7.9577",
  },
  {
    country_code: "CH",
    zipcode: "3903",
    place: "Mund",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Naters",
    community_code: "6007",
    latitude: "46.315",
    longitude: "7.9412",
  },
  {
    country_code: "CH",
    zipcode: "3904",
    place: "Naters",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Naters",
    community_code: "6007",
    latitude: "46.3254",
    longitude: "7.9891",
  },
  {
    country_code: "CH",
    zipcode: "3907",
    place: "Gondo",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Zwischbergen",
    community_code: "6011",
    latitude: "46.1964",
    longitude: "8.1388",
  },
  {
    country_code: "CH",
    zipcode: "3907",
    place: "Simplon Dorf",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Simplon",
    community_code: "6009",
    latitude: "46.1945",
    longitude: "8.0575",
  },
  {
    country_code: "CH",
    zipcode: "3907",
    place: "Gabi (Simplon)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Simplon",
    community_code: "6009",
    latitude: "46.1837",
    longitude: "8.0731",
  },
  {
    country_code: "CH",
    zipcode: "3911",
    place: "Ried-Brig",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Ried-Brig",
    community_code: "6008",
    latitude: "46.3138",
    longitude: "8.0175",
  },
  {
    country_code: "CH",
    zipcode: "3912",
    place: "Termen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Termen",
    community_code: "6010",
    latitude: "46.3269",
    longitude: "8.021",
  },
  {
    country_code: "CH",
    zipcode: "3913",
    place: "Rosswald",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Termen",
    community_code: "6010",
    latitude: "46.3056",
    longitude: "8.0417",
  },
  {
    country_code: "CH",
    zipcode: "3914",
    place: "Belalp",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Naters",
    community_code: "6007",
    latitude: "46.3708",
    longitude: "7.9712",
  },
  {
    country_code: "CH",
    zipcode: "3914",
    place: "Blatten b. Naters",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Naters",
    community_code: "6007",
    latitude: "46.3708",
    longitude: "7.9712",
  },
  {
    country_code: "CH",
    zipcode: "3939",
    place: "Eggerberg",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Brig District",
    province_code: "2301",
    community: "Eggerberg",
    community_code: "6004",
    latitude: "46.3068",
    longitude: "7.8763",
  },
  {
    country_code: "CH",
    zipcode: "1911",
    place: "Mayens-de-Chamoson",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.2034",
    longitude: "7.1843",
  },
  {
    country_code: "CH",
    zipcode: "1955",
    place: "Les Vérines (Chamoson)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.2105",
    longitude: "7.2044",
  },
  {
    country_code: "CH",
    zipcode: "1955",
    place: "St-Pierre-de-Clages",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.1921",
    longitude: "7.2369",
  },
  {
    country_code: "CH",
    zipcode: "1955",
    place: "Grugnay (Chamoson)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.208",
    longitude: "7.219",
  },
  {
    country_code: "CH",
    zipcode: "1955",
    place: "Némiaz (Chamoson)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.2123",
    longitude: "7.2235",
  },
  {
    country_code: "CH",
    zipcode: "1955",
    place: "Chamoson",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Chamoson",
    community_code: "6022",
    latitude: "46.2027",
    longitude: "7.2232",
  },
  {
    country_code: "CH",
    zipcode: "1957",
    place: "Ardon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Ardon",
    community_code: "6021",
    latitude: "46.2095",
    longitude: "7.2601",
  },
  {
    country_code: "CH",
    zipcode: "1963",
    place: "Vétroz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Vétroz",
    community_code: "6025",
    latitude: "46.2217",
    longitude: "7.2786",
  },
  {
    country_code: "CH",
    zipcode: "1964",
    place: "Conthey",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Conthey",
    community_code: "6023",
    latitude: "46.2237",
    longitude: "7.3028",
  },
  {
    country_code: "CH",
    zipcode: "1975",
    place: "St-Séverin",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Conthey",
    community_code: "6023",
    latitude: "46.2349",
    longitude: "7.3007",
  },
  {
    country_code: "CH",
    zipcode: "1976",
    place: "Aven",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Conthey",
    community_code: "6023",
    latitude: "46.2376",
    longitude: "7.2731",
  },
  {
    country_code: "CH",
    zipcode: "1976",
    place: "Daillon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Conthey",
    community_code: "6023",
    latitude: "46.2574",
    longitude: "7.3067",
  },
  {
    country_code: "CH",
    zipcode: "1976",
    place: "Erde",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Conthey",
    community_code: "6023",
    latitude: "46.2388",
    longitude: "7.2876",
  },
  {
    country_code: "CH",
    zipcode: "1993",
    place: "Clèbes (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1875",
    longitude: "7.3375",
  },
  {
    country_code: "CH",
    zipcode: "1994",
    place: "Aproz (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.2055",
    longitude: "7.3108",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Beuson (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1667",
    longitude: "7.3",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Bieudron (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.194",
    longitude: "7.2797",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Saclentse",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1761",
    longitude: "7.3178",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Condémines (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1755",
    longitude: "7.2554",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Baar (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.2057",
    longitude: "7.3321",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Basse-Nendaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1899",
    longitude: "7.3121",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Brignon (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1896",
    longitude: "7.3261",
  },
  {
    country_code: "CH",
    zipcode: "1996",
    place: "Fey (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1868",
    longitude: "7.2699",
  },
  {
    country_code: "CH",
    zipcode: "1997",
    place: "Sornard (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1887",
    longitude: "7.3032",
  },
  {
    country_code: "CH",
    zipcode: "1997",
    place: "Siviez (Nendaz)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1366",
    longitude: "7.316",
  },
  {
    country_code: "CH",
    zipcode: "1997",
    place: "Haute-Nendaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Conthey District",
    province_code: "2302",
    community: "Nendaz",
    community_code: "6024",
    latitude: "46.1872",
    longitude: "7.2975",
  },
  {
    country_code: "CH",
    zipcode: "1927",
    place: "Chemin",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Vollèges",
    community_code: "6036",
    latitude: "46.0895",
    longitude: "7.0963",
  },
  {
    country_code: "CH",
    zipcode: "1933",
    place: "Chamoille (Sembrancher)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Sembrancher",
    community_code: "6035",
    latitude: "46.0592",
    longitude: "7.1555",
  },
  {
    country_code: "CH",
    zipcode: "1933",
    place: "La Garde (Sembrancher)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Sembrancher",
    community_code: "6035",
    latitude: "46.0664",
    longitude: "7.1473",
  },
  {
    country_code: "CH",
    zipcode: "1933",
    place: "Sembrancher",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Sembrancher",
    community_code: "6035",
    latitude: "46.0775",
    longitude: "7.1528",
  },
  {
    country_code: "CH",
    zipcode: "1933",
    place: "Vens (Sembrancher)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Vollèges",
    community_code: "6036",
    latitude: "46.0959",
    longitude: "7.1535",
  },
  {
    country_code: "CH",
    zipcode: "1934",
    place: "Bruson",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.066",
    longitude: "7.2183",
  },
  {
    country_code: "CH",
    zipcode: "1934",
    place: "Le Châble VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.066",
    longitude: "7.2183",
  },
  {
    country_code: "CH",
    zipcode: "1936",
    place: "Verbier",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.1002",
    longitude: "7.2265",
  },
  {
    country_code: "CH",
    zipcode: "1937",
    place: "Orsières",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Orsières",
    community_code: "6034",
    latitude: "46.029",
    longitude: "7.1444",
  },
  {
    country_code: "CH",
    zipcode: "1938",
    place: "Champex-Lac",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Orsières",
    community_code: "6034",
    latitude: "45.9723",
    longitude: "7.1015",
  },
  {
    country_code: "CH",
    zipcode: "1941",
    place: "Cries (Vollèges)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Vollèges",
    community_code: "6036",
    latitude: "46.092",
    longitude: "7.1755",
  },
  {
    country_code: "CH",
    zipcode: "1941",
    place: "Vollèges",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Vollèges",
    community_code: "6036",
    latitude: "46.0862",
    longitude: "7.17",
  },
  {
    country_code: "CH",
    zipcode: "1942",
    place: "Levron",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Vollèges",
    community_code: "6036",
    latitude: "46.1002",
    longitude: "7.1638",
  },
  {
    country_code: "CH",
    zipcode: "1943",
    place: "Praz-de-Fort",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Orsières",
    community_code: "6034",
    latitude: "45.9878",
    longitude: "7.1226",
  },
  {
    country_code: "CH",
    zipcode: "1944",
    place: "La Fouly VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Orsières",
    community_code: "6034",
    latitude: "45.9336",
    longitude: "7.0977",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Dranse (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.989",
    longitude: "7.1804",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Fontaine Dessus (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "46.0054",
    longitude: "7.1749",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Chez Petit (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9875",
    longitude: "7.1772",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Vichères (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9973",
    longitude: "7.1675",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Palasuit (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9822",
    longitude: "7.1933",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Liddes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9925",
    longitude: "7.1873",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Fornex (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "46.009",
    longitude: "7.1622",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Rive Haute (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "46.0088",
    longitude: "7.1684",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Les Moulins VS (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "46.0046",
    longitude: "7.168",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Chandonne (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "46.0066",
    longitude: "7.1806",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Fontaine Dessous (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9983",
    longitude: "7.176",
  },
  {
    country_code: "CH",
    zipcode: "1945",
    place: "Petit Vichères (Liddes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Liddes",
    community_code: "6033",
    latitude: "45.9983",
    longitude: "7.176",
  },
  {
    country_code: "CH",
    zipcode: "1946",
    place: "Bourg-St-Pierre",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bourg-Saint-Pierre",
    community_code: "6032",
    latitude: "45.9488",
    longitude: "7.2077",
  },
  {
    country_code: "CH",
    zipcode: "1947",
    place: "Champsec",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.0581",
    longitude: "7.243",
  },
  {
    country_code: "CH",
    zipcode: "1947",
    place: "Versegères",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.0671",
    longitude: "7.2348",
  },
  {
    country_code: "CH",
    zipcode: "1948",
    place: "Sarreyer",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.064",
    longitude: "7.2519",
  },
  {
    country_code: "CH",
    zipcode: "1948",
    place: "Lourtier",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.0488",
    longitude: "7.2668",
  },
  {
    country_code: "CH",
    zipcode: "1948",
    place: "Fionnay",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Entremont District",
    province_code: "2303",
    community: "Bagnes",
    community_code: "6031",
    latitude: "46.032",
    longitude: "7.3089",
  },
  {
    country_code: "CH",
    zipcode: "3801",
    place: "Jungfraujoch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Fieschertal",
    community_code: "6058",
    latitude: "46.5468",
    longitude: "7.9822",
  },
  {
    country_code: "CH",
    zipcode: "3984",
    place: "Fieschertal",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Fieschertal",
    community_code: "6058",
    latitude: "46.422",
    longitude: "8.1454",
  },
  {
    country_code: "CH",
    zipcode: "3984",
    place: "Fiesch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Bellwald",
    community_code: "6052",
    latitude: "46.4443",
    longitude: "8.157",
  },
  {
    country_code: "CH",
    zipcode: "3984",
    place: "Fiesch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Fiesch",
    community_code: "6057",
    latitude: "46.3998",
    longitude: "8.1353",
  },
  {
    country_code: "CH",
    zipcode: "3985",
    place: "Münster VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4865",
    longitude: "8.2643",
  },
  {
    country_code: "CH",
    zipcode: "3985",
    place: "Geschinen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4865",
    longitude: "8.2643",
  },
  {
    country_code: "CH",
    zipcode: "3988",
    place: "Obergesteln",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Obergoms",
    community_code: "6076",
    latitude: "46.5143",
    longitude: "8.3253",
  },
  {
    country_code: "CH",
    zipcode: "3988",
    place: "Ulrichen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Obergoms",
    community_code: "6076",
    latitude: "46.5054",
    longitude: "8.3096",
  },
  {
    country_code: "CH",
    zipcode: "3989",
    place: "Ritzingen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4566",
    longitude: "8.2216",
  },
  {
    country_code: "CH",
    zipcode: "3989",
    place: "Niederwald",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4354",
    longitude: "8.1899",
  },
  {
    country_code: "CH",
    zipcode: "3989",
    place: "Blitzingen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4429",
    longitude: "8.2019",
  },
  {
    country_code: "CH",
    zipcode: "3989",
    place: "Selkingen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4552",
    longitude: "8.2154",
  },
  {
    country_code: "CH",
    zipcode: "3989",
    place: "Biel VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4559",
    longitude: "8.2177",
  },
  {
    country_code: "CH",
    zipcode: "3994",
    place: "Lax",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Lax",
    community_code: "6061",
    latitude: "46.3884",
    longitude: "8.117",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Mühlebach (Goms)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.4085",
    longitude: "8.1562",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Ernen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.3968",
    longitude: "8.1417",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Ausserbinn",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.3828",
    longitude: "8.1473",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Steinhaus",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.4218",
    longitude: "8.1774",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Mühlebach (Goms)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.4085",
    longitude: "8.1562",
  },
  {
    country_code: "CH",
    zipcode: "3995",
    place: "Steinhaus",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Ernen",
    community_code: "6056",
    latitude: "46.4218",
    longitude: "8.1774",
  },
  {
    country_code: "CH",
    zipcode: "3996",
    place: "Binn",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Binn",
    community_code: "6054",
    latitude: "46.3639",
    longitude: "8.1842",
  },
  {
    country_code: "CH",
    zipcode: "3997",
    place: "Bellwald",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Bellwald",
    community_code: "6052",
    latitude: "46.4276",
    longitude: "8.1614",
  },
  {
    country_code: "CH",
    zipcode: "3998",
    place: "Reckingen VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4699",
    longitude: "8.2416",
  },
  {
    country_code: "CH",
    zipcode: "3998",
    place: "Gluringen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Goms",
    community_code: "6077",
    latitude: "46.4699",
    longitude: "8.2416",
  },
  {
    country_code: "CH",
    zipcode: "3999",
    place: "Oberwald",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Goms District",
    province_code: "2304",
    community: "Obergoms",
    community_code: "6076",
    latitude: "46.5319",
    longitude: "8.355",
  },
  {
    country_code: "CH",
    zipcode: "1961",
    place: "Vernamiège",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Mont-Noble",
    community_code: "6090",
    latitude: "46.2117",
    longitude: "7.4313",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Blignou (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2734",
    longitude: "7.4012",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Fortunau (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2869",
    longitude: "7.4153",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Saxonne (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2811",
    longitude: "7.4054",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Argnou (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2636",
    longitude: "7.3997",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Villa (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2764",
    longitude: "7.4165",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "St-Romain (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2831",
    longitude: "7.4117",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Botyre (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2753",
    longitude: "7.406",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Luc (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2845",
    longitude: "7.4195",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "Signèse (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.278",
    longitude: "7.4094",
  },
  {
    country_code: "CH",
    zipcode: "1966",
    place: "La Place (Ayent)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.278",
    longitude: "7.4094",
  },
  {
    country_code: "CH",
    zipcode: "1968",
    place: "Mase",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Mont-Noble",
    community_code: "6090",
    latitude: "46.1952",
    longitude: "7.4347",
  },
  {
    country_code: "CH",
    zipcode: "1969",
    place: "St-Martin VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Saint-Martin (VS)",
    community_code: "6087",
    latitude: "46.1615",
    longitude: "7.451",
  },
  {
    country_code: "CH",
    zipcode: "1969",
    place: "Eison (St-Martin)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Saint-Martin (VS)",
    community_code: "6087",
    latitude: "46.1615",
    longitude: "7.451",
  },
  {
    country_code: "CH",
    zipcode: "1969",
    place: "Liez (St-Martin)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Saint-Martin (VS)",
    community_code: "6087",
    latitude: "46.1615",
    longitude: "7.451",
  },
  {
    country_code: "CH",
    zipcode: "1969",
    place: "Suen (St-Martin)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Saint-Martin (VS)",
    community_code: "6087",
    latitude: "46.1615",
    longitude: "7.451",
  },
  {
    country_code: "CH",
    zipcode: "1969",
    place: "Trogne (St-Martin)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Saint-Martin (VS)",
    community_code: "6087",
    latitude: "46.1615",
    longitude: "7.451",
  },
  {
    country_code: "CH",
    zipcode: "1972",
    place: "Anzère",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Ayent",
    community_code: "6082",
    latitude: "46.2956",
    longitude: "7.3962",
  },
  {
    country_code: "CH",
    zipcode: "1973",
    place: "Nax",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Mont-Noble",
    community_code: "6090",
    latitude: "46.2282",
    longitude: "7.431",
  },
  {
    country_code: "CH",
    zipcode: "1981",
    place: "Vex",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Vex",
    community_code: "6089",
    latitude: "46.2124",
    longitude: "7.3983",
  },
  {
    country_code: "CH",
    zipcode: "1982",
    place: "Euseigne",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Hérémence",
    community_code: "6084",
    latitude: "46.1721",
    longitude: "7.4231",
  },
  {
    country_code: "CH",
    zipcode: "1983",
    place: "Evolène",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.1142",
    longitude: "7.4941",
  },
  {
    country_code: "CH",
    zipcode: "1983",
    place: "Lanna",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.1333",
    longitude: "7.4833",
  },
  {
    country_code: "CH",
    zipcode: "1984",
    place: "Les Haudères",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.0832",
    longitude: "7.509",
  },
  {
    country_code: "CH",
    zipcode: "1984",
    place: "La Tour VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.0982",
    longitude: "7.5057",
  },
  {
    country_code: "CH",
    zipcode: "1985",
    place: "Villa (Evolène)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.1076",
    longitude: "7.5134",
  },
  {
    country_code: "CH",
    zipcode: "1985",
    place: "La Sage",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.0997",
    longitude: "7.516",
  },
  {
    country_code: "CH",
    zipcode: "1985",
    place: "La Forclaz VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.0872",
    longitude: "7.5212",
  },
  {
    country_code: "CH",
    zipcode: "1986",
    place: "Arolla",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Evolène",
    community_code: "6083",
    latitude: "46.0254",
    longitude: "7.4836",
  },
  {
    country_code: "CH",
    zipcode: "1987",
    place: "Hérémence",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Hérémence",
    community_code: "6084",
    latitude: "46.1803",
    longitude: "7.4048",
  },
  {
    country_code: "CH",
    zipcode: "1988",
    place: "Les Collons",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Vex",
    community_code: "6089",
    latitude: "46.1818",
    longitude: "7.3864",
  },
  {
    country_code: "CH",
    zipcode: "1988",
    place: "Thyon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Hérens District",
    province_code: "2305",
    community: "Vex",
    community_code: "6089",
    latitude: "46.1823",
    longitude: "7.3725",
  },
  {
    country_code: "CH",
    zipcode: "3945",
    place: "Niedergampel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Gampel-Bratsch",
    community_code: "6118",
    latitude: "46.3127",
    longitude: "7.7129",
  },
  {
    country_code: "CH",
    zipcode: "3945",
    place: "Gampel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Gampel-Bratsch",
    community_code: "6118",
    latitude: "46.316",
    longitude: "7.7421",
  },
  {
    country_code: "CH",
    zipcode: "3946",
    place: "Gruben",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Turtmann-Unterems",
    community_code: "6119",
    latitude: "46.2114",
    longitude: "7.7062",
  },
  {
    country_code: "CH",
    zipcode: "3946",
    place: "Turtmann",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Turtmann-Unterems",
    community_code: "6119",
    latitude: "46.3003",
    longitude: "7.702",
  },
  {
    country_code: "CH",
    zipcode: "3947",
    place: "Ergisch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Ergisch",
    community_code: "6104",
    latitude: "46.2922",
    longitude: "7.7147",
  },
  {
    country_code: "CH",
    zipcode: "3948",
    place: "Unterems",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Turtmann-Unterems",
    community_code: "6119",
    latitude: "46.2916",
    longitude: "7.6959",
  },
  {
    country_code: "CH",
    zipcode: "3948",
    place: "Oberems",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Oberems",
    community_code: "6112",
    latitude: "46.2819",
    longitude: "7.6935",
  },
  {
    country_code: "CH",
    zipcode: "3951",
    place: "Agarn",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Agarn",
    community_code: "6101",
    latitude: "46.2975",
    longitude: "7.6632",
  },
  {
    country_code: "CH",
    zipcode: "3952",
    place: "Susten",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Leuk",
    community_code: "6110",
    latitude: "46.3111",
    longitude: "7.6433",
  },
  {
    country_code: "CH",
    zipcode: "3953",
    place: "Varen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Varen",
    community_code: "6116",
    latitude: "46.3186",
    longitude: "7.6074",
  },
  {
    country_code: "CH",
    zipcode: "3953",
    place: "Inden",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Inden",
    community_code: "6109",
    latitude: "46.3444",
    longitude: "7.6174",
  },
  {
    country_code: "CH",
    zipcode: "3953",
    place: "Leuk Stadt",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Leuk",
    community_code: "6110",
    latitude: "46.3174",
    longitude: "7.6341",
  },
  {
    country_code: "CH",
    zipcode: "3954",
    place: "Leukerbad",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Leukerbad",
    community_code: "6111",
    latitude: "46.3794",
    longitude: "7.6269",
  },
  {
    country_code: "CH",
    zipcode: "3955",
    place: "Albinen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Albinen",
    community_code: "6102",
    latitude: "46.3407",
    longitude: "7.6332",
  },
  {
    country_code: "CH",
    zipcode: "3956",
    place: "Guttet-Feschel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Guttet-Feschel",
    community_code: "6117",
    latitude: "46.3253",
    longitude: "7.667",
  },
  {
    country_code: "CH",
    zipcode: "3957",
    place: "Bratsch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Gampel-Bratsch",
    community_code: "6118",
    latitude: "46.3207",
    longitude: "7.7083",
  },
  {
    country_code: "CH",
    zipcode: "3957",
    place: "Erschmatt",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Leuk",
    community_code: "6110",
    latitude: "46.3218",
    longitude: "7.6915",
  },
  {
    country_code: "CH",
    zipcode: "3970",
    place: "Salgesch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Leuk District",
    province_code: "2306",
    community: "Salgesch",
    community_code: "6113",
    latitude: "46.3116",
    longitude: "7.5712",
  },
  {
    country_code: "CH",
    zipcode: "1906",
    place: "Charrat",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Charrat",
    community_code: "6132",
    latitude: "46.1249",
    longitude: "7.1314",
  },
  {
    country_code: "CH",
    zipcode: "1907",
    place: "Saxon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Saxon",
    community_code: "6141",
    latitude: "46.1494",
    longitude: "7.1751",
  },
  {
    country_code: "CH",
    zipcode: "1908",
    place: "Riddes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Riddes",
    community_code: "6139",
    latitude: "46.1728",
    longitude: "7.2236",
  },
  {
    country_code: "CH",
    zipcode: "1911",
    place: "Ovronnaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Leytron",
    community_code: "6135",
    latitude: "46.199",
    longitude: "7.1736",
  },
  {
    country_code: "CH",
    zipcode: "1912",
    place: "Leytron",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Leytron",
    community_code: "6135",
    latitude: "46.1866",
    longitude: "7.2078",
  },
  {
    country_code: "CH",
    zipcode: "1912",
    place: "Produit (Leytron)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Leytron",
    community_code: "6135",
    latitude: "46.1858",
    longitude: "7.1942",
  },
  {
    country_code: "CH",
    zipcode: "1912",
    place: "Montagnon (Leytron)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Leytron",
    community_code: "6135",
    latitude: "46.1901",
    longitude: "7.1927",
  },
  {
    country_code: "CH",
    zipcode: "1912",
    place: "Dugny (Leytron)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Leytron",
    community_code: "6135",
    latitude: "46.1875",
    longitude: "7.1982",
  },
  {
    country_code: "CH",
    zipcode: "1913",
    place: "Saillon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Saillon",
    community_code: "6140",
    latitude: "46.1703",
    longitude: "7.1877",
  },
  {
    country_code: "CH",
    zipcode: "1913",
    place: "Saillon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Saillon",
    community_code: "6140",
    latitude: "46.1703",
    longitude: "7.1877",
  },
  {
    country_code: "CH",
    zipcode: "1914",
    place: "Isérables",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Isérables",
    community_code: "6134",
    latitude: "46.1623",
    longitude: "7.2447",
  },
  {
    country_code: "CH",
    zipcode: "1914",
    place: "Auddes-sur-Riddes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Riddes",
    community_code: "6139",
    latitude: "46.1623",
    longitude: "7.2447",
  },
  {
    country_code: "CH",
    zipcode: "1918",
    place: "La Tzoumaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Riddes",
    community_code: "6139",
    latitude: "46.1448",
    longitude: "7.233",
  },
  {
    country_code: "CH",
    zipcode: "1919",
    place: "Martigny Mutuel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Martigny",
    community_code: "6136",
    latitude: "46.1072",
    longitude: "7.081",
  },
  {
    country_code: "CH",
    zipcode: "1920",
    place: "Martigny",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Martigny",
    community_code: "6136",
    latitude: "46.1028",
    longitude: "7.0724",
  },
  {
    country_code: "CH",
    zipcode: "1921",
    place: "Martigny-Croix",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Martigny-Combe",
    community_code: "6137",
    latitude: "46.086",
    longitude: "7.0513",
  },
  {
    country_code: "CH",
    zipcode: "1926",
    place: "Fully",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Fully",
    community_code: "6133",
    latitude: "46.1385",
    longitude: "7.1147",
  },
  {
    country_code: "CH",
    zipcode: "1928",
    place: "Ravoire",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Martigny-Combe",
    community_code: "6137",
    latitude: "46.1007",
    longitude: "7.0458",
  },
  {
    country_code: "CH",
    zipcode: "1929",
    place: "Trient",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Trient",
    community_code: "6142",
    latitude: "46.058",
    longitude: "6.994",
  },
  {
    country_code: "CH",
    zipcode: "1932",
    place: "Bovernier",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Bovernier",
    community_code: "6131",
    latitude: "46.079",
    longitude: "7.0858",
  },
  {
    country_code: "CH",
    zipcode: "1932",
    place: "Les Valettes (Bovernier)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Martigny District",
    province_code: "2307",
    community: "Bovernier",
    community_code: "6131",
    latitude: "46.0753",
    longitude: "7.0735",
  },
  {
    country_code: "CH",
    zipcode: "1868",
    place: "Collombey",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Collombey-Muraz",
    community_code: "6152",
    latitude: "46.2739",
    longitude: "6.9479",
  },
  {
    country_code: "CH",
    zipcode: "1870",
    place: "Monthey",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Monthey",
    community_code: "6153",
    latitude: "46.2545",
    longitude: "6.9541",
  },
  {
    country_code: "CH",
    zipcode: "1870",
    place: "Monthey",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Monthey",
    community_code: "6153",
    latitude: "46.2545",
    longitude: "6.9541",
  },
  {
    country_code: "CH",
    zipcode: "1871",
    place: "Les Giettes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Monthey",
    community_code: "6153",
    latitude: "46.2275",
    longitude: "6.9583",
  },
  {
    country_code: "CH",
    zipcode: "1871",
    place: "Choëx",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Monthey",
    community_code: "6153",
    latitude: "46.2413",
    longitude: "6.9584",
  },
  {
    country_code: "CH",
    zipcode: "1872",
    place: "Troistorrents",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Troistorrents",
    community_code: "6156",
    latitude: "46.2289",
    longitude: "6.9159",
  },
  {
    country_code: "CH",
    zipcode: "1873",
    place: "Champoussin",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Val-d'Illiez",
    community_code: "6157",
    latitude: "46.2077",
    longitude: "6.8639",
  },
  {
    country_code: "CH",
    zipcode: "1873",
    place: "Val-d'Illiez",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Val-d'Illiez",
    community_code: "6157",
    latitude: "46.2036",
    longitude: "6.891",
  },
  {
    country_code: "CH",
    zipcode: "1873",
    place: "Les Crosets",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Val-d'Illiez",
    community_code: "6157",
    latitude: "46.1851",
    longitude: "6.8347",
  },
  {
    country_code: "CH",
    zipcode: "1874",
    place: "Champéry",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Champéry",
    community_code: "6151",
    latitude: "46.1754",
    longitude: "6.869",
  },
  {
    country_code: "CH",
    zipcode: "1875",
    place: "Morgins",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Troistorrents",
    community_code: "6156",
    latitude: "46.239",
    longitude: "6.852",
  },
  {
    country_code: "CH",
    zipcode: "1893",
    place: "Muraz (Collombey)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Collombey-Muraz",
    community_code: "6152",
    latitude: "46.2842",
    longitude: "6.9334",
  },
  {
    country_code: "CH",
    zipcode: "1895",
    place: "Vionnaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Vionnaz",
    community_code: "6158",
    latitude: "46.311",
    longitude: "6.9006",
  },
  {
    country_code: "CH",
    zipcode: "1896",
    place: "Miex",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Vouvry",
    community_code: "6159",
    latitude: "46.3395",
    longitude: "6.867",
  },
  {
    country_code: "CH",
    zipcode: "1896",
    place: "Vouvry",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Vouvry",
    community_code: "6159",
    latitude: "46.3375",
    longitude: "6.8895",
  },
  {
    country_code: "CH",
    zipcode: "1897",
    place: "Bouveret",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Port-Valais",
    community_code: "6154",
    latitude: "46.3832",
    longitude: "6.8596",
  },
  {
    country_code: "CH",
    zipcode: "1897",
    place: "Les Evouettes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Port-Valais",
    community_code: "6154",
    latitude: "46.362",
    longitude: "6.8767",
  },
  {
    country_code: "CH",
    zipcode: "1898",
    place: "St-Gingolph",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Saint-Gingolph",
    community_code: "6155",
    latitude: "46.3922",
    longitude: "6.8059",
  },
  {
    country_code: "CH",
    zipcode: "1899",
    place: "Torgon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Monthey District",
    province_code: "2308",
    community: "Vionnaz",
    community_code: "6158",
    latitude: "46.3195",
    longitude: "6.8753",
  },
  {
    country_code: "CH",
    zipcode: "3916",
    place: "Ferden",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Ferden",
    community_code: "6195",
    latitude: "46.3936",
    longitude: "7.7592",
  },
  {
    country_code: "CH",
    zipcode: "3917",
    place: "Kippel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Kippel",
    community_code: "6197",
    latitude: "46.3988",
    longitude: "7.7719",
  },
  {
    country_code: "CH",
    zipcode: "3917",
    place: "Goppenstein",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Ferden",
    community_code: "6195",
    latitude: "46.369",
    longitude: "7.7541",
  },
  {
    country_code: "CH",
    zipcode: "3918",
    place: "Wiler (Lötschen)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Wiler (Lötschen)",
    community_code: "6202",
    latitude: "46.4032",
    longitude: "7.7816",
  },
  {
    country_code: "CH",
    zipcode: "3919",
    place: "Blatten (Lötschen)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Blatten",
    community_code: "6192",
    latitude: "46.4207",
    longitude: "7.8175",
  },
  {
    country_code: "CH",
    zipcode: "3935",
    place: "Bürchen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bürchen",
    community_code: "6193",
    latitude: "46.2805",
    longitude: "7.8151",
  },
  {
    country_code: "CH",
    zipcode: "3938",
    place: "Ausserberg",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Ausserberg",
    community_code: "6191",
    latitude: "46.3139",
    longitude: "7.853",
  },
  {
    country_code: "CH",
    zipcode: "3940",
    place: "Steg VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Steg-Hohtenn",
    community_code: "6204",
    latitude: "46.3149",
    longitude: "7.7492",
  },
  {
    country_code: "CH",
    zipcode: "3942",
    place: "St. German",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Raron",
    community_code: "6199",
    latitude: "46.3128",
    longitude: "7.8228",
  },
  {
    country_code: "CH",
    zipcode: "3942",
    place: "Niedergesteln",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Niedergesteln",
    community_code: "6198",
    latitude: "46.3124",
    longitude: "7.7836",
  },
  {
    country_code: "CH",
    zipcode: "3942",
    place: "Raron",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Raron",
    community_code: "6199",
    latitude: "46.312",
    longitude: "7.8003",
  },
  {
    country_code: "CH",
    zipcode: "3943",
    place: "Eischoll",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Eischoll",
    community_code: "6194",
    latitude: "46.2935",
    longitude: "7.78",
  },
  {
    country_code: "CH",
    zipcode: "3944",
    place: "Unterbäch VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Unterbäch",
    community_code: "6201",
    latitude: "46.2843",
    longitude: "7.7992",
  },
  {
    country_code: "CH",
    zipcode: "3949",
    place: "Hohtenn",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Steg-Hohtenn",
    community_code: "6204",
    latitude: "46.3189",
    longitude: "7.7557",
  },
  {
    country_code: "CH",
    zipcode: "3982",
    place: "Bitsch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bitsch",
    community_code: "6173",
    latitude: "46.3378",
    longitude: "8.0109",
  },
  {
    country_code: "CH",
    zipcode: "3983",
    place: "Greich",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Riederalp",
    community_code: "6181",
    latitude: "46.3679",
    longitude: "8.0402",
  },
  {
    country_code: "CH",
    zipcode: "3983",
    place: "Filet",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Mörel-Filet",
    community_code: "6203",
    latitude: "46.3607",
    longitude: "8.0532",
  },
  {
    country_code: "CH",
    zipcode: "3983",
    place: "Goppisberg",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Riederalp",
    community_code: "6181",
    latitude: "46.3714",
    longitude: "8.0504",
  },
  {
    country_code: "CH",
    zipcode: "3983",
    place: "Mörel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Mörel-Filet",
    community_code: "6203",
    latitude: "46.3566",
    longitude: "8.0446",
  },
  {
    country_code: "CH",
    zipcode: "3983",
    place: "Bister",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bister",
    community_code: "6172",
    latitude: "46.3603",
    longitude: "8.0645",
  },
  {
    country_code: "CH",
    zipcode: "3986",
    place: "Ried-Mörel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Riederalp",
    community_code: "6181",
    latitude: "46.3571",
    longitude: "8.0314",
  },
  {
    country_code: "CH",
    zipcode: "3987",
    place: "Riederalp",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Riederalp",
    community_code: "6181",
    latitude: "46.3764",
    longitude: "8.0261",
  },
  {
    country_code: "CH",
    zipcode: "3991",
    place: "Betten",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bettmeralp",
    community_code: "6205",
    latitude: "46.3771",
    longitude: "8.0696",
  },
  {
    country_code: "CH",
    zipcode: "3992",
    place: "Bettmeralp",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bettmeralp",
    community_code: "6205",
    latitude: "46.3902",
    longitude: "8.0625",
  },
  {
    country_code: "CH",
    zipcode: "3993",
    place: "Grengiols",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Grengiols",
    community_code: "6177",
    latitude: "46.374",
    longitude: "8.093",
  },
  {
    country_code: "CH",
    zipcode: "3994",
    place: "Martisberg",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Raron District",
    province_code: "2309",
    community: "Bettmeralp",
    community_code: "6205",
    latitude: "46.3871",
    longitude: "8.0985",
  },
  {
    country_code: "CH",
    zipcode: "1869",
    place: "Massongex",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Massongex",
    community_code: "6215",
    latitude: "46.2427",
    longitude: "6.9888",
  },
  {
    country_code: "CH",
    zipcode: "1890",
    place: "Mex VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Saint-Maurice",
    community_code: "6217",
    latitude: "46.1855",
    longitude: "7",
  },
  {
    country_code: "CH",
    zipcode: "1890",
    place: "St-Maurice",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Saint-Maurice",
    community_code: "6217",
    latitude: "46.2183",
    longitude: "7.0032",
  },
  {
    country_code: "CH",
    zipcode: "1891",
    place: "Vérossaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Vérossaz",
    community_code: "6220",
    latitude: "46.2122",
    longitude: "6.9863",
  },
  {
    country_code: "CH",
    zipcode: "1902",
    place: "Evionnaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Evionnaz",
    community_code: "6213",
    latitude: "46.181",
    longitude: "7.0223",
  },
  {
    country_code: "CH",
    zipcode: "1903",
    place: "Collonges",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Collonges",
    community_code: "6211",
    latitude: "46.172",
    longitude: "7.0343",
  },
  {
    country_code: "CH",
    zipcode: "1904",
    place: "Vernayaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Vernayaz",
    community_code: "6219",
    latitude: "46.1367",
    longitude: "7.0391",
  },
  {
    country_code: "CH",
    zipcode: "1905",
    place: "Dorénaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Dorénaz",
    community_code: "6212",
    latitude: "46.1489",
    longitude: "7.0429",
  },
  {
    country_code: "CH",
    zipcode: "1922",
    place: "Salvan",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Salvan",
    community_code: "6218",
    latitude: "46.1189",
    longitude: "7.0208",
  },
  {
    country_code: "CH",
    zipcode: "1922",
    place: "Les Granges (Salvan)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Salvan",
    community_code: "6218",
    latitude: "46.1292",
    longitude: "7.0231",
  },
  {
    country_code: "CH",
    zipcode: "1923",
    place: "Le Trétien",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Salvan",
    community_code: "6218",
    latitude: "46.1022",
    longitude: "6.9993",
  },
  {
    country_code: "CH",
    zipcode: "1923",
    place: "Les Marécottes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Salvan",
    community_code: "6218",
    latitude: "46.1114",
    longitude: "7.0073",
  },
  {
    country_code: "CH",
    zipcode: "1925",
    place: "Le Châtelard VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Finhaut",
    community_code: "6214",
    latitude: "46.0617",
    longitude: "6.9589",
  },
  {
    country_code: "CH",
    zipcode: "1925",
    place: "Finhaut",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Saint-Maurice District",
    province_code: "2310",
    community: "Finhaut",
    community_code: "6214",
    latitude: "46.0838",
    longitude: "6.9778",
  },
  {
    country_code: "CH",
    zipcode: "1958",
    place: "St-Léonard",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Saint-Léonard",
    community_code: "6246",
    latitude: "46.2515",
    longitude: "7.4171",
  },
  {
    country_code: "CH",
    zipcode: "1977",
    place: "Icogne",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Icogne",
    community_code: "6239",
    latitude: "46.2919",
    longitude: "7.4405",
  },
  {
    country_code: "CH",
    zipcode: "1978",
    place: "Lens",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Lens",
    community_code: "6240",
    latitude: "46.283",
    longitude: "7.4498",
  },
  {
    country_code: "CH",
    zipcode: "3960",
    place: "Sierre",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Sierre",
    community_code: "6248",
    latitude: "46.2919",
    longitude: "7.5356",
  },
  {
    country_code: "CH",
    zipcode: "3960",
    place: "Corin-de-la-Crête",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.2886",
    longitude: "7.5328",
  },
  {
    country_code: "CH",
    zipcode: "3960",
    place: "Loc",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.2934",
    longitude: "7.5131",
  },
  {
    country_code: "CH",
    zipcode: "3960",
    place: "Muraz (Sierre)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Sierre",
    community_code: "6248",
    latitude: "46.2976",
    longitude: "7.5288",
  },
  {
    country_code: "CH",
    zipcode: "3960",
    place: "Niouc",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.2716",
    longitude: "7.5538",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Zinal",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.1343",
    longitude: "7.6287",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "St-Luc",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.2215",
    longitude: "7.5962",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Chandolin",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.2519",
    longitude: "7.5937",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Grimentz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.1809",
    longitude: "7.5758",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "St-Jean VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.1969",
    longitude: "7.5866",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Vissoie",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.2162",
    longitude: "7.5864",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Mission",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.1911",
    longitude: "7.5936",
  },
  {
    country_code: "CH",
    zipcode: "3961",
    place: "Ayer",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Anniviers",
    community_code: "6252",
    latitude: "46.1809",
    longitude: "7.6012",
  },
  {
    country_code: "CH",
    zipcode: "3963",
    place: "Crans-Montana",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.3132",
    longitude: "7.4791",
  },
  {
    country_code: "CH",
    zipcode: "3963",
    place: "Montana",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.3134",
    longitude: "7.4884",
  },
  {
    country_code: "CH",
    zipcode: "3963",
    place: "Aminona",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.3313",
    longitude: "7.5328",
  },
  {
    country_code: "CH",
    zipcode: "3965",
    place: "Chippis",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Chippis",
    community_code: "6235",
    latitude: "46.2802",
    longitude: "7.5396",
  },
  {
    country_code: "CH",
    zipcode: "3966",
    place: "Réchy",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Chalais",
    community_code: "6232",
    latitude: "46.2626",
    longitude: "7.4962",
  },
  {
    country_code: "CH",
    zipcode: "3966",
    place: "Chalais",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Chalais",
    community_code: "6232",
    latitude: "46.2676",
    longitude: "7.5114",
  },
  {
    country_code: "CH",
    zipcode: "3967",
    place: "Vercorin",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Chalais",
    community_code: "6232",
    latitude: "46.2565",
    longitude: "7.531",
  },
  {
    country_code: "CH",
    zipcode: "3968",
    place: "Veyras",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Veyras",
    community_code: "6250",
    latitude: "46.3021",
    longitude: "7.5362",
  },
  {
    country_code: "CH",
    zipcode: "3971",
    place: "Chermignon",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.2884",
    longitude: "7.4749",
  },
  {
    country_code: "CH",
    zipcode: "3971",
    place: "Ollon VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.275",
    longitude: "7.479",
  },
  {
    country_code: "CH",
    zipcode: "3971",
    place: "Chermignon-d'en-Bas",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.2882",
    longitude: "7.4744",
  },
  {
    country_code: "CH",
    zipcode: "3972",
    place: "Miège",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Miège",
    community_code: "6241",
    latitude: "46.3112",
    longitude: "7.547",
  },
  {
    country_code: "CH",
    zipcode: "3973",
    place: "Venthône",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Venthône",
    community_code: "6249",
    latitude: "46.3067",
    longitude: "7.5292",
  },
  {
    country_code: "CH",
    zipcode: "3974",
    place: "Mollens VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.3165",
    longitude: "7.5212",
  },
  {
    country_code: "CH",
    zipcode: "3975",
    place: "Randogne",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.3095",
    longitude: "7.5006",
  },
  {
    country_code: "CH",
    zipcode: "3976",
    place: "Noës",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Sierre",
    community_code: "6248",
    latitude: "46.2798",
    longitude: "7.5025",
  },
  {
    country_code: "CH",
    zipcode: "3976",
    place: "Champzabé",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Crans-Montana",
    community_code: "6253",
    latitude: "46.2792",
    longitude: "7.4994",
  },
  {
    country_code: "CH",
    zipcode: "3977",
    place: "Granges VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Sierre",
    community_code: "6248",
    latitude: "46.2618",
    longitude: "7.4675",
  },
  {
    country_code: "CH",
    zipcode: "3978",
    place: "Flanthey",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Lens",
    community_code: "6240",
    latitude: "46.2691",
    longitude: "7.4471",
  },
  {
    country_code: "CH",
    zipcode: "3979",
    place: "Grône",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sierre District",
    province_code: "2311",
    community: "Grône",
    community_code: "6238",
    latitude: "46.2529",
    longitude: "7.4595",
  },
  {
    country_code: "CH",
    zipcode: "1950",
    place: "Sion",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2274",
    longitude: "7.3556",
  },
  {
    country_code: "CH",
    zipcode: "1951",
    place: "Sion",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2304",
    longitude: "7.3661",
  },
  {
    country_code: "CH",
    zipcode: "1958",
    place: "Uvrier",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2486",
    longitude: "7.4104",
  },
  {
    country_code: "CH",
    zipcode: "1962",
    place: "Pont-de-la-Morge (Sion)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.226",
    longitude: "7.3139",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Granois (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2489",
    longitude: "7.3369",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Roumaz (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2453",
    longitude: "7.3456",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Ormône (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2431",
    longitude: "7.3469",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "St-Germain (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2496",
    longitude: "7.3526",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Monteiller (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2571",
    longitude: "7.3565",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Diolly (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2531",
    longitude: "7.3478",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Chandolin (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2494",
    longitude: "7.3193",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Drône (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2612",
    longitude: "7.3681",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "Mayens-de-la-Zour (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2698",
    longitude: "7.3569",
  },
  {
    country_code: "CH",
    zipcode: "1965",
    place: "La Muraz (Savièse)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Savièse",
    community_code: "6265",
    latitude: "46.2531",
    longitude: "7.3478",
  },
  {
    country_code: "CH",
    zipcode: "1967",
    place: "Bramois",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2324",
    longitude: "7.4007",
  },
  {
    country_code: "CH",
    zipcode: "1971",
    place: "Grimisuat",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Grimisuat",
    community_code: "6263",
    latitude: "46.2594",
    longitude: "7.3841",
  },
  {
    country_code: "CH",
    zipcode: "1971",
    place: "Champlan (Grimisuat)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Grimisuat",
    community_code: "6263",
    latitude: "46.2471",
    longitude: "7.3729",
  },
  {
    country_code: "CH",
    zipcode: "1974",
    place: "Arbaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Arbaz",
    community_code: "6261",
    latitude: "46.2781",
    longitude: "7.3854",
  },
  {
    country_code: "CH",
    zipcode: "1991",
    place: "Arvillard (Salins)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.211",
    longitude: "7.3459",
  },
  {
    country_code: "CH",
    zipcode: "1991",
    place: "Salins",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2094",
    longitude: "7.3579",
  },
  {
    country_code: "CH",
    zipcode: "1991",
    place: "Pravidondaz (Salins)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2124",
    longitude: "7.3549",
  },
  {
    country_code: "CH",
    zipcode: "1991",
    place: "Turin (Salins)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2155",
    longitude: "7.3541",
  },
  {
    country_code: "CH",
    zipcode: "1991",
    place: "Misériez (Salins)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2121",
    longitude: "7.3532",
  },
  {
    country_code: "CH",
    zipcode: "1992",
    place: "Les Agettes",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.1978",
    longitude: "7.3683",
  },
  {
    country_code: "CH",
    zipcode: "1992",
    place: "Les Mayens-de-Sion",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2857",
    longitude: "7.3361",
  },
  {
    country_code: "CH",
    zipcode: "1992",
    place: "Crête-à-l'Oeil (Les Agettes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.212",
    longitude: "7.3715",
  },
  {
    country_code: "CH",
    zipcode: "1992",
    place: "La Vernaz (Les Agettes)",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Sion",
    community_code: "6266",
    latitude: "46.2102",
    longitude: "7.3734",
  },
  {
    country_code: "CH",
    zipcode: "1993",
    place: "Veysonnaz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Sion District",
    province_code: "2312",
    community: "Veysonnaz",
    community_code: "6267",
    latitude: "46.1952",
    longitude: "7.3356",
  },
  {
    country_code: "CH",
    zipcode: "3905",
    place: "Saas-Almagell",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Saas-Almagell",
    community_code: "6288",
    latitude: "46.0935",
    longitude: "7.9574",
  },
  {
    country_code: "CH",
    zipcode: "3906",
    place: "Saas-Fee",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Saas-Fee",
    community_code: "6290",
    latitude: "46.1081",
    longitude: "7.9274",
  },
  {
    country_code: "CH",
    zipcode: "3908",
    place: "Saas-Balen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Saas-Balen",
    community_code: "6289",
    latitude: "46.1544",
    longitude: "7.927",
  },
  {
    country_code: "CH",
    zipcode: "3910",
    place: "Saas-Grund",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Saas-Grund",
    community_code: "6291",
    latitude: "46.1228",
    longitude: "7.9365",
  },
  {
    country_code: "CH",
    zipcode: "3920",
    place: "Zermatt",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Zermatt",
    community_code: "6300",
    latitude: "46.02",
    longitude: "7.7486",
  },
  {
    country_code: "CH",
    zipcode: "3922",
    place: "Kalpetran",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Embd",
    community_code: "6283",
    latitude: "46.2148",
    longitude: "7.8386",
  },
  {
    country_code: "CH",
    zipcode: "3922",
    place: "Eisten",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Eisten",
    community_code: "6282",
    latitude: "46.2007",
    longitude: "7.8928",
  },
  {
    country_code: "CH",
    zipcode: "3922",
    place: "Stalden VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Stalden (VS)",
    community_code: "6293",
    latitude: "46.2334",
    longitude: "7.8727",
  },
  {
    country_code: "CH",
    zipcode: "3923",
    place: "Törbel",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Törbel",
    community_code: "6296",
    latitude: "46.2384",
    longitude: "7.8524",
  },
  {
    country_code: "CH",
    zipcode: "3924",
    place: "St. Niklaus VS",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "St. Niklaus",
    community_code: "6292",
    latitude: "46.1772",
    longitude: "7.8035",
  },
  {
    country_code: "CH",
    zipcode: "3925",
    place: "Grächen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Grächen",
    community_code: "6285",
    latitude: "46.1953",
    longitude: "7.8375",
  },
  {
    country_code: "CH",
    zipcode: "3926",
    place: "Embd",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Embd",
    community_code: "6283",
    latitude: "46.215",
    longitude: "7.8267",
  },
  {
    country_code: "CH",
    zipcode: "3927",
    place: "Herbriggen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "St. Niklaus",
    community_code: "6292",
    latitude: "46.1359",
    longitude: "7.7923",
  },
  {
    country_code: "CH",
    zipcode: "3928",
    place: "Randa",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Randa",
    community_code: "6287",
    latitude: "46.0988",
    longitude: "7.7823",
  },
  {
    country_code: "CH",
    zipcode: "3929",
    place: "Täsch",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Täsch",
    community_code: "6295",
    latitude: "46.0684",
    longitude: "7.7777",
  },
  {
    country_code: "CH",
    zipcode: "3930",
    place: "Visp",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Visp",
    community_code: "6297",
    latitude: "46.2937",
    longitude: "7.8815",
  },
  {
    country_code: "CH",
    zipcode: "3930",
    place: "Eyholz",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Visp",
    community_code: "6297",
    latitude: "46.2942",
    longitude: "7.9084",
  },
  {
    country_code: "CH",
    zipcode: "3931",
    place: "Lalden",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Lalden",
    community_code: "6286",
    latitude: "46.2995",
    longitude: "7.9023",
  },
  {
    country_code: "CH",
    zipcode: "3932",
    place: "Visperterminen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Visperterminen",
    community_code: "6298",
    latitude: "46.259",
    longitude: "7.9019",
  },
  {
    country_code: "CH",
    zipcode: "3933",
    place: "Staldenried",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Staldenried",
    community_code: "6294",
    latitude: "46.2294",
    longitude: "7.8827",
  },
  {
    country_code: "CH",
    zipcode: "3934",
    place: "Zeneggen",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Zeneggen",
    community_code: "6299",
    latitude: "46.2734",
    longitude: "7.8654",
  },
  {
    country_code: "CH",
    zipcode: "3937",
    place: "Baltschieder",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Baltschieder",
    community_code: "6281",
    latitude: "46.3089",
    longitude: "7.8657",
  },
  {
    country_code: "CH",
    zipcode: "3937",
    place: "Baltschieder",
    state: "Canton du Valais",
    state_code: "VS",
    province: "Visp District",
    province_code: "2313",
    community: "Baltschieder",
    community_code: "6281",
    latitude: "46.3089",
    longitude: "7.8657",
  },
  {
    country_code: "CH",
    zipcode: "6300",
    place: "Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.1724",
    longitude: "8.5174",
  },
  {
    country_code: "CH",
    zipcode: "6300",
    place: "Zugerberg",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.1434",
    longitude: "8.5334",
  },
  {
    country_code: "CH",
    zipcode: "6301",
    place: "Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.15",
    longitude: "8.5232",
  },
  {
    country_code: "CH",
    zipcode: "6302",
    place: "Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.15",
    longitude: "8.5232",
  },
  {
    country_code: "CH",
    zipcode: "6303",
    place: "Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.15",
    longitude: "8.5232",
  },
  {
    country_code: "CH",
    zipcode: "6310",
    place: "Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.15",
    longitude: "8.5232",
  },
  {
    country_code: "CH",
    zipcode: "6312",
    place: "Steinhausen",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Steinhausen",
    community_code: "1708",
    latitude: "47.1951",
    longitude: "8.4858",
  },
  {
    country_code: "CH",
    zipcode: "6313",
    place: "Edlibach",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Menzingen",
    community_code: "1704",
    latitude: "47.1826",
    longitude: "8.575",
  },
  {
    country_code: "CH",
    zipcode: "6313",
    place: "Menzingen",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Menzingen",
    community_code: "1704",
    latitude: "47.1776",
    longitude: "8.5922",
  },
  {
    country_code: "CH",
    zipcode: "6313",
    place: "Finstersee",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Menzingen",
    community_code: "1704",
    latitude: "47.1685",
    longitude: "8.6308",
  },
  {
    country_code: "CH",
    zipcode: "6314",
    place: "Neuägeri",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Unterägeri",
    community_code: "1709",
    latitude: "47.1538",
    longitude: "8.5642",
  },
  {
    country_code: "CH",
    zipcode: "6314",
    place: "Unterägeri",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Unterägeri",
    community_code: "1709",
    latitude: "47.1364",
    longitude: "8.5853",
  },
  {
    country_code: "CH",
    zipcode: "6315",
    place: "Alosen",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Oberägeri",
    community_code: "1706",
    latitude: "47.1423",
    longitude: "8.6386",
  },
  {
    country_code: "CH",
    zipcode: "6315",
    place: "Morgarten",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Oberägeri",
    community_code: "1706",
    latitude: "47.1026",
    longitude: "8.6398",
  },
  {
    country_code: "CH",
    zipcode: "6315",
    place: "Oberägeri",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Oberägeri",
    community_code: "1706",
    latitude: "47.1361",
    longitude: "8.6144",
  },
  {
    country_code: "CH",
    zipcode: "6317",
    place: "Oberwil b. Zug",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Zug",
    community_code: "1711",
    latitude: "47.1483",
    longitude: "8.5073",
  },
  {
    country_code: "CH",
    zipcode: "6318",
    place: "Walchwil",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Walchwil",
    community_code: "1710",
    latitude: "47.1017",
    longitude: "8.5169",
  },
  {
    country_code: "CH",
    zipcode: "6319",
    place: "Allenwinden",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Baar",
    community_code: "1701",
    latitude: "47.1642",
    longitude: "8.5554",
  },
  {
    country_code: "CH",
    zipcode: "6330",
    place: "Cham",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Cham",
    community_code: "1702",
    latitude: "47.1821",
    longitude: "8.4636",
  },
  {
    country_code: "CH",
    zipcode: "6331",
    place: "Hünenberg",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Hünenberg",
    community_code: "1703",
    latitude: "47.1754",
    longitude: "8.425",
  },
  {
    country_code: "CH",
    zipcode: "6332",
    place: "Hagendorn",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Cham",
    community_code: "1702",
    latitude: "47.2045",
    longitude: "8.4333",
  },
  {
    country_code: "CH",
    zipcode: "6333",
    place: "Hünenberg See",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Hünenberg",
    community_code: "1703",
    latitude: "47.1743",
    longitude: "8.4512",
  },
  {
    country_code: "CH",
    zipcode: "6340",
    place: "Baar",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Baar",
    community_code: "1701",
    latitude: "47.1963",
    longitude: "8.5295",
  },
  {
    country_code: "CH",
    zipcode: "6340",
    place: "Sihlbrugg",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Neuheim",
    community_code: "1705",
    latitude: "47.2157",
    longitude: "8.5735",
  },
  {
    country_code: "CH",
    zipcode: "6341",
    place: "Baar",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Baar",
    community_code: "1701",
    latitude: "47.1954",
    longitude: "8.537",
  },
  {
    country_code: "CH",
    zipcode: "6343",
    place: "Buonas",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Risch",
    community_code: "1707",
    latitude: "47.1415",
    longitude: "8.4535",
  },
  {
    country_code: "CH",
    zipcode: "6343",
    place: "Risch",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Risch",
    community_code: "1707",
    latitude: "47.1335",
    longitude: "8.4651",
  },
  {
    country_code: "CH",
    zipcode: "6343",
    place: "Rotkreuz",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Risch",
    community_code: "1707",
    latitude: "47.1406",
    longitude: "8.4293",
  },
  {
    country_code: "CH",
    zipcode: "6343",
    place: "Holzhäusern ZG",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Risch",
    community_code: "1707",
    latitude: "47.1565",
    longitude: "8.4409",
  },
  {
    country_code: "CH",
    zipcode: "6345",
    place: "Neuheim",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Neuheim",
    community_code: "1705",
    latitude: "47.2019",
    longitude: "8.5805",
  },
  {
    country_code: "CH",
    zipcode: "6349",
    place: "Baar",
    state: "Kanton Zug",
    state_code: "ZG",
    province: "Zug",
    province_code: "900",
    community: "Baar",
    community_code: "1701",
    latitude: "47.1954",
    longitude: "8.537",
  },
  {
    country_code: "CH",
    zipcode: "8143",
    place: "Uetliberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Stallikon",
    community_code: "13",
    latitude: "47.3521",
    longitude: "8.4875",
  },
  {
    country_code: "CH",
    zipcode: "8143",
    place: "Stallikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Stallikon",
    community_code: "13",
    latitude: "47.3258",
    longitude: "8.4897",
  },
  {
    country_code: "CH",
    zipcode: "8906",
    place: "Bonstetten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Bonstetten",
    community_code: "3",
    latitude: "47.315",
    longitude: "8.4684",
  },
  {
    country_code: "CH",
    zipcode: "8907",
    place: "Wettswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Wettswil am Albis",
    community_code: "14",
    latitude: "47.2695",
    longitude: "8.4744",
  },
  {
    country_code: "CH",
    zipcode: "8908",
    place: "Hedingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Hedingen",
    community_code: "5",
    latitude: "47.2979",
    longitude: "8.4483",
  },
  {
    country_code: "CH",
    zipcode: "8909",
    place: "Zwillikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Affoltern am Albis",
    community_code: "2",
    latitude: "47.2883",
    longitude: "8.4312",
  },
  {
    country_code: "CH",
    zipcode: "8910",
    place: "Affoltern am Albis",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Affoltern am Albis",
    community_code: "2",
    latitude: "47.2774",
    longitude: "8.4513",
  },
  {
    country_code: "CH",
    zipcode: "8911",
    place: "Rifferswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Rifferswil",
    community_code: "12",
    latitude: "47.2437",
    longitude: "8.4969",
  },
  {
    country_code: "CH",
    zipcode: "8912",
    place: "Obfelden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Obfelden",
    community_code: "10",
    latitude: "47.2641",
    longitude: "8.4215",
  },
  {
    country_code: "CH",
    zipcode: "8913",
    place: "Ottenbach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Ottenbach",
    community_code: "11",
    latitude: "47.2823",
    longitude: "8.4043",
  },
  {
    country_code: "CH",
    zipcode: "8914",
    place: "Aeugst am Albis",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Aeugst am Albis",
    community_code: "1",
    latitude: "47.2754",
    longitude: "8.4897",
  },
  {
    country_code: "CH",
    zipcode: "8914",
    place: "Aeugstertal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Aeugst am Albis",
    community_code: "1",
    latitude: "47.2754",
    longitude: "8.4897",
  },
  {
    country_code: "CH",
    zipcode: "8915",
    place: "Hausen am Albis",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Hausen am Albis",
    community_code: "4",
    latitude: "47.245",
    longitude: "8.533",
  },
  {
    country_code: "CH",
    zipcode: "8925",
    place: "Ebertswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Hausen am Albis",
    community_code: "4",
    latitude: "47.2264",
    longitude: "8.5496",
  },
  {
    country_code: "CH",
    zipcode: "8926",
    place: "Hauptikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Kappel am Albis",
    community_code: "6",
    latitude: "47.2304",
    longitude: "8.4954",
  },
  {
    country_code: "CH",
    zipcode: "8926",
    place: "Kappel am Albis",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Kappel am Albis",
    community_code: "6",
    latitude: "47.2281",
    longitude: "8.5273",
  },
  {
    country_code: "CH",
    zipcode: "8926",
    place: "Uerzlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Kappel am Albis",
    community_code: "6",
    latitude: "47.2215",
    longitude: "8.4981",
  },
  {
    country_code: "CH",
    zipcode: "8932",
    place: "Mettmenstetten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Mettmenstetten",
    community_code: "9",
    latitude: "47.2453",
    longitude: "8.4635",
  },
  {
    country_code: "CH",
    zipcode: "8933",
    place: "Maschwanden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Maschwanden",
    community_code: "8",
    latitude: "47.2343",
    longitude: "8.4271",
  },
  {
    country_code: "CH",
    zipcode: "8934",
    place: "Knonau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Affoltern",
    province_code: "101",
    community: "Knonau",
    community_code: "7",
    latitude: "47.2235",
    longitude: "8.462",
  },
  {
    country_code: "CH",
    zipcode: "8212",
    place: "Nohl",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Laufen-Uhwiesen",
    community_code: "34",
    latitude: "47.6695",
    longitude: "8.6079",
  },
  {
    country_code: "CH",
    zipcode: "8245",
    place: "Feuerthalen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Feuerthalen",
    community_code: "27",
    latitude: "47.6905",
    longitude: "8.6436",
  },
  {
    country_code: "CH",
    zipcode: "8246",
    place: "Langwiesen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Feuerthalen",
    community_code: "27",
    latitude: "47.684",
    longitude: "8.6609",
  },
  {
    country_code: "CH",
    zipcode: "8247",
    place: "Flurlingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Flurlingen",
    community_code: "29",
    latitude: "47.6839",
    longitude: "8.6299",
  },
  {
    country_code: "CH",
    zipcode: "8248",
    place: "Uhwiesen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Laufen-Uhwiesen",
    community_code: "34",
    latitude: "47.6707",
    longitude: "8.6354",
  },
  {
    country_code: "CH",
    zipcode: "8414",
    place: "Buch am Irchel",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Buch am Irchel",
    community_code: "24",
    latitude: "47.55",
    longitude: "8.6333",
  },
  {
    country_code: "CH",
    zipcode: "8415",
    place: "Gräslikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Berg am Irchel",
    community_code: "23",
    latitude: "47.5566",
    longitude: "8.6054",
  },
  {
    country_code: "CH",
    zipcode: "8415",
    place: "Berg am Irchel",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Berg am Irchel",
    community_code: "23",
    latitude: "47.5695",
    longitude: "8.5968",
  },
  {
    country_code: "CH",
    zipcode: "8416",
    place: "Flaach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Flaach",
    community_code: "28",
    latitude: "47.5761",
    longitude: "8.6063",
  },
  {
    country_code: "CH",
    zipcode: "8444",
    place: "Henggart",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Henggart",
    community_code: "31",
    latitude: "47.5627",
    longitude: "8.6821",
  },
  {
    country_code: "CH",
    zipcode: "8447",
    place: "Dachsen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Dachsen",
    community_code: "25",
    latitude: "47.6652",
    longitude: "8.6179",
  },
  {
    country_code: "CH",
    zipcode: "8450",
    place: "Andelfingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Andelfingen",
    community_code: "30",
    latitude: "47.5945",
    longitude: "8.6783",
  },
  {
    country_code: "CH",
    zipcode: "8451",
    place: "Kleinandelfingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Kleinandelfingen",
    community_code: "33",
    latitude: "47.6006",
    longitude: "8.6836",
  },
  {
    country_code: "CH",
    zipcode: "8452",
    place: "Adlikon b. Andelfingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Adlikon",
    community_code: "21",
    latitude: "47.5823",
    longitude: "8.6915",
  },
  {
    country_code: "CH",
    zipcode: "8453",
    place: "Alten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Kleinandelfingen",
    community_code: "33",
    latitude: "47.5989",
    longitude: "8.6437",
  },
  {
    country_code: "CH",
    zipcode: "8457",
    place: "Humlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Humlikon",
    community_code: "32",
    latitude: "47.5764",
    longitude: "8.6701",
  },
  {
    country_code: "CH",
    zipcode: "8458",
    place: "Dorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Dorf",
    community_code: "26",
    latitude: "47.5729",
    longitude: "8.6478",
  },
  {
    country_code: "CH",
    zipcode: "8459",
    place: "Volken",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Volken",
    community_code: "43",
    latitude: "47.5748",
    longitude: "8.6258",
  },
  {
    country_code: "CH",
    zipcode: "8460",
    place: "Marthalen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Marthalen",
    community_code: "35",
    latitude: "47.6291",
    longitude: "8.6533",
  },
  {
    country_code: "CH",
    zipcode: "8461",
    place: "Oerlingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Kleinandelfingen",
    community_code: "33",
    latitude: "47.6249",
    longitude: "8.6759",
  },
  {
    country_code: "CH",
    zipcode: "8462",
    place: "Rheinau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Rheinau",
    community_code: "38",
    latitude: "47.6328",
    longitude: "8.6025",
  },
  {
    country_code: "CH",
    zipcode: "8463",
    place: "Benken ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Benken (ZH)",
    community_code: "22",
    latitude: "47.6528",
    longitude: "8.6539",
  },
  {
    country_code: "CH",
    zipcode: "8464",
    place: "Ellikon am Rhein",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Marthalen",
    community_code: "35",
    latitude: "47.605",
    longitude: "8.5992",
  },
  {
    country_code: "CH",
    zipcode: "8465",
    place: "Wildensbuch",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Trüllikon",
    community_code: "40",
    latitude: "47.6521",
    longitude: "8.6766",
  },
  {
    country_code: "CH",
    zipcode: "8465",
    place: "Rudolfingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Trüllikon",
    community_code: "40",
    latitude: "47.6406",
    longitude: "8.6743",
  },
  {
    country_code: "CH",
    zipcode: "8466",
    place: "Trüllikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Trüllikon",
    community_code: "40",
    latitude: "47.6354",
    longitude: "8.6892",
  },
  {
    country_code: "CH",
    zipcode: "8467",
    place: "Truttikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Truttikon",
    community_code: "41",
    latitude: "47.6291",
    longitude: "8.7272",
  },
  {
    country_code: "CH",
    zipcode: "8468",
    place: "Guntalingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Waltalingen",
    community_code: "44",
    latitude: "47.6324",
    longitude: "8.7685",
  },
  {
    country_code: "CH",
    zipcode: "8468",
    place: "Waltalingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Waltalingen",
    community_code: "44",
    latitude: "47.622",
    longitude: "8.7782",
  },
  {
    country_code: "CH",
    zipcode: "8475",
    place: "Ossingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Ossingen",
    community_code: "37",
    latitude: "47.6118",
    longitude: "8.7278",
  },
  {
    country_code: "CH",
    zipcode: "8476",
    place: "Unterstammheim",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Unterstammheim",
    community_code: "42",
    latitude: "47.6392",
    longitude: "8.7906",
  },
  {
    country_code: "CH",
    zipcode: "8477",
    place: "Oberstammheim",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Oberstammheim",
    community_code: "36",
    latitude: "47.6324",
    longitude: "8.7996",
  },
  {
    country_code: "CH",
    zipcode: "8478",
    place: "Thalheim an der Thur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Andelfingen",
    province_code: "102",
    community: "Thalheim an der Thur",
    community_code: "39",
    latitude: "47.5782",
    longitude: "8.7512",
  },
  {
    country_code: "CH",
    zipcode: "8058",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Kloten",
    community_code: "62",
    latitude: "47.458",
    longitude: "8.5853",
  },
  {
    country_code: "CH",
    zipcode: "8060",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Kloten",
    community_code: "62",
    latitude: "47.458",
    longitude: "8.5853",
  },
  {
    country_code: "CH",
    zipcode: "8152",
    place: "Glattpark (Opfikon)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Opfikon",
    community_code: "66",
    latitude: "47.4315",
    longitude: "8.5693",
  },
  {
    country_code: "CH",
    zipcode: "8152",
    place: "Glattbrugg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Opfikon",
    community_code: "66",
    latitude: "47.4313",
    longitude: "8.5627",
  },
  {
    country_code: "CH",
    zipcode: "8152",
    place: "Opfikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Opfikon",
    community_code: "66",
    latitude: "47.4317",
    longitude: "8.5759",
  },
  {
    country_code: "CH",
    zipcode: "8180",
    place: "Bülach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Bülach",
    community_code: "53",
    latitude: "47.522",
    longitude: "8.5405",
  },
  {
    country_code: "CH",
    zipcode: "8181",
    place: "Höri",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Höri",
    community_code: "60",
    latitude: "47.5075",
    longitude: "8.5024",
  },
  {
    country_code: "CH",
    zipcode: "8182",
    place: "Hochfelden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Hochfelden",
    community_code: "59",
    latitude: "47.5226",
    longitude: "8.5156",
  },
  {
    country_code: "CH",
    zipcode: "8184",
    place: "Bachenbülach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Bachenbülach",
    community_code: "51",
    latitude: "47.5032",
    longitude: "8.5456",
  },
  {
    country_code: "CH",
    zipcode: "8185",
    place: "Winkel",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Winkel",
    community_code: "72",
    latitude: "47.4919",
    longitude: "8.5537",
  },
  {
    country_code: "CH",
    zipcode: "8192",
    place: "Glattfelden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Glattfelden",
    community_code: "58",
    latitude: "47.5587",
    longitude: "8.5017",
  },
  {
    country_code: "CH",
    zipcode: "8192",
    place: "Zweidlen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Glattfelden",
    community_code: "58",
    latitude: "47.5587",
    longitude: "8.5017",
  },
  {
    country_code: "CH",
    zipcode: "8193",
    place: "Eglisau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Eglisau",
    community_code: "55",
    latitude: "47.5774",
    longitude: "8.5212",
  },
  {
    country_code: "CH",
    zipcode: "8194",
    place: "Hüntwangen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Hüntwangen",
    community_code: "61",
    latitude: "47.5958",
    longitude: "8.4912",
  },
  {
    country_code: "CH",
    zipcode: "8195",
    place: "Wasterkingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Wasterkingen",
    community_code: "70",
    latitude: "47.5896",
    longitude: "8.4712",
  },
  {
    country_code: "CH",
    zipcode: "8196",
    place: "Wil ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Wil (ZH)",
    community_code: "71",
    latitude: "47.6045",
    longitude: "8.5081",
  },
  {
    country_code: "CH",
    zipcode: "8197",
    place: "Rafz",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Rafz",
    community_code: "67",
    latitude: "47.6044",
    longitude: "8.543",
  },
  {
    country_code: "CH",
    zipcode: "8302",
    place: "Kloten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Kloten",
    community_code: "62",
    latitude: "47.4515",
    longitude: "8.5849",
  },
  {
    country_code: "CH",
    zipcode: "8303",
    place: "Bassersdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Bassersdorf",
    community_code: "52",
    latitude: "47.4434",
    longitude: "8.6285",
  },
  {
    country_code: "CH",
    zipcode: "8304",
    place: "Wallisellen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Wallisellen",
    community_code: "69",
    latitude: "47.415",
    longitude: "8.5967",
  },
  {
    country_code: "CH",
    zipcode: "8305",
    place: "Dietlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Dietlikon",
    community_code: "54",
    latitude: "47.4183",
    longitude: "8.6188",
  },
  {
    country_code: "CH",
    zipcode: "8309",
    place: "Nürensdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Nürensdorf",
    community_code: "64",
    latitude: "47.4481",
    longitude: "8.6491",
  },
  {
    country_code: "CH",
    zipcode: "8424",
    place: "Embrach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Embrach",
    community_code: "56",
    latitude: "47.5056",
    longitude: "8.5941",
  },
  {
    country_code: "CH",
    zipcode: "8425",
    place: "Oberembrach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Oberembrach",
    community_code: "65",
    latitude: "47.4878",
    longitude: "8.6183",
  },
  {
    country_code: "CH",
    zipcode: "8426",
    place: "Lufingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Lufingen",
    community_code: "63",
    latitude: "47.4911",
    longitude: "8.5943",
  },
  {
    country_code: "CH",
    zipcode: "8427",
    place: "Freienstein",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Freienstein-Teufen",
    community_code: "57",
    latitude: "47.5331",
    longitude: "8.5845",
  },
  {
    country_code: "CH",
    zipcode: "8427",
    place: "Rorbas",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Rorbas",
    community_code: "68",
    latitude: "47.5309",
    longitude: "8.5756",
  },
  {
    country_code: "CH",
    zipcode: "8428",
    place: "Teufen ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Bülach",
    province_code: "103",
    community: "Freienstein-Teufen",
    community_code: "57",
    latitude: "47.5512",
    longitude: "8.5657",
  },
  {
    country_code: "CH",
    zipcode: "8105",
    place: "Watt",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Regensdorf",
    community_code: "96",
    latitude: "47.4486",
    longitude: "8.4853",
  },
  {
    country_code: "CH",
    zipcode: "8105",
    place: "Regensdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Regensdorf",
    community_code: "96",
    latitude: "47.4341",
    longitude: "8.4687",
  },
  {
    country_code: "CH",
    zipcode: "8106",
    place: "Adlikon b. Regensdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Regensdorf",
    community_code: "96",
    latitude: "47.4468",
    longitude: "8.4664",
  },
  {
    country_code: "CH",
    zipcode: "8107",
    place: "Buchs ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Buchs (ZH)",
    community_code: "83",
    latitude: "47.4588",
    longitude: "8.4366",
  },
  {
    country_code: "CH",
    zipcode: "8108",
    place: "Dällikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Dällikon",
    community_code: "84",
    latitude: "47.4397",
    longitude: "8.44",
  },
  {
    country_code: "CH",
    zipcode: "8112",
    place: "Otelfingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Otelfingen",
    community_code: "94",
    latitude: "47.4605",
    longitude: "8.3914",
  },
  {
    country_code: "CH",
    zipcode: "8113",
    place: "Boppelsen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Boppelsen",
    community_code: "82",
    latitude: "47.4695",
    longitude: "8.4061",
  },
  {
    country_code: "CH",
    zipcode: "8114",
    place: "Dänikon ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Dänikon",
    community_code: "85",
    latitude: "47.4467",
    longitude: "8.4065",
  },
  {
    country_code: "CH",
    zipcode: "8115",
    place: "Hüttikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Hüttikon",
    community_code: "87",
    latitude: "47.4426",
    longitude: "8.3881",
  },
  {
    country_code: "CH",
    zipcode: "8153",
    place: "Rümlang",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Rümlang",
    community_code: "97",
    latitude: "47.4504",
    longitude: "8.5299",
  },
  {
    country_code: "CH",
    zipcode: "8154",
    place: "Oberglatt ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Oberglatt",
    community_code: "92",
    latitude: "47.4758",
    longitude: "8.519",
  },
  {
    country_code: "CH",
    zipcode: "8155",
    place: "Niederhasli",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Niederhasli",
    community_code: "90",
    latitude: "47.4801",
    longitude: "8.4858",
  },
  {
    country_code: "CH",
    zipcode: "8155",
    place: "Nassenwil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Niederhasli",
    community_code: "90",
    latitude: "47.4668",
    longitude: "8.469",
  },
  {
    country_code: "CH",
    zipcode: "8156",
    place: "Oberhasli",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Niederhasli",
    community_code: "90",
    latitude: "47.4657",
    longitude: "8.4988",
  },
  {
    country_code: "CH",
    zipcode: "8157",
    place: "Dielsdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Dielsdorf",
    community_code: "86",
    latitude: "47.4815",
    longitude: "8.4585",
  },
  {
    country_code: "CH",
    zipcode: "8158",
    place: "Regensberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Regensberg",
    community_code: "95",
    latitude: "47.483",
    longitude: "8.4379",
  },
  {
    country_code: "CH",
    zipcode: "8162",
    place: "Steinmaur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Steinmaur",
    community_code: "101",
    latitude: "47.4971",
    longitude: "8.4522",
  },
  {
    country_code: "CH",
    zipcode: "8162",
    place: "Sünikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Steinmaur",
    community_code: "101",
    latitude: "47.4904",
    longitude: "8.4369",
  },
  {
    country_code: "CH",
    zipcode: "8164",
    place: "Bachs",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Bachs",
    community_code: "81",
    latitude: "47.5239",
    longitude: "8.4394",
  },
  {
    country_code: "CH",
    zipcode: "8165",
    place: "Schleinikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Schleinikon",
    community_code: "98",
    latitude: "47.4975",
    longitude: "8.3975",
  },
  {
    country_code: "CH",
    zipcode: "8165",
    place: "Oberweningen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Oberweningen",
    community_code: "93",
    latitude: "47.5023",
    longitude: "8.407",
  },
  {
    country_code: "CH",
    zipcode: "8165",
    place: "Schöfflisdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Schöfflisdorf",
    community_code: "99",
    latitude: "47.5003",
    longitude: "8.4175",
  },
  {
    country_code: "CH",
    zipcode: "8166",
    place: "Niederweningen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Niederweningen",
    community_code: "91",
    latitude: "47.5061",
    longitude: "8.3771",
  },
  {
    country_code: "CH",
    zipcode: "8172",
    place: "Niederglatt ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Niederglatt",
    community_code: "89",
    latitude: "47.4907",
    longitude: "8.4999",
  },
  {
    country_code: "CH",
    zipcode: "8173",
    place: "Neerach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Neerach",
    community_code: "88",
    latitude: "47.511",
    longitude: "8.471",
  },
  {
    country_code: "CH",
    zipcode: "8174",
    place: "Stadel b. Niederglatt",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Stadel",
    community_code: "100",
    latitude: "47.5294",
    longitude: "8.4635",
  },
  {
    country_code: "CH",
    zipcode: "8175",
    place: "Windlach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Stadel",
    community_code: "100",
    latitude: "47.5409",
    longitude: "8.4738",
  },
  {
    country_code: "CH",
    zipcode: "8187",
    place: "Weiach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dielsdorf",
    province_code: "104",
    community: "Weiach",
    community_code: "102",
    latitude: "47.5591",
    longitude: "8.4334",
  },
  {
    country_code: "CH",
    zipcode: "8340",
    place: "Hinwil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Hinwil",
    community_code: "117",
    latitude: "47.2943",
    longitude: "8.8439",
  },
  {
    country_code: "CH",
    zipcode: "8342",
    place: "Wernetshausen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Hinwil",
    community_code: "117",
    latitude: "47.2991",
    longitude: "8.8646",
  },
  {
    country_code: "CH",
    zipcode: "8344",
    place: "Bäretswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Bäretswil",
    community_code: "111",
    latitude: "47.3371",
    longitude: "8.8564",
  },
  {
    country_code: "CH",
    zipcode: "8345",
    place: "Adetswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Bäretswil",
    community_code: "111",
    latitude: "47.3397",
    longitude: "8.84",
  },
  {
    country_code: "CH",
    zipcode: "8496",
    place: "Steg im Tösstal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Fischenthal",
    community_code: "114",
    latitude: "47.3506",
    longitude: "8.9342",
  },
  {
    country_code: "CH",
    zipcode: "8497",
    place: "Fischenthal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Fischenthal",
    community_code: "114",
    latitude: "47.3312",
    longitude: "8.9205",
  },
  {
    country_code: "CH",
    zipcode: "8498",
    place: "Gibswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Fischenthal",
    community_code: "114",
    latitude: "47.3153",
    longitude: "8.9141",
  },
  {
    country_code: "CH",
    zipcode: "8607",
    place: "Aathal-Seegräben",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Seegräben",
    community_code: "119",
    latitude: "47.3351",
    longitude: "8.77",
  },
  {
    country_code: "CH",
    zipcode: "8608",
    place: "Bubikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Bubikon",
    community_code: "112",
    latitude: "47.267",
    longitude: "8.8179",
  },
  {
    country_code: "CH",
    zipcode: "8614",
    place: "Bertschikon (Gossau ZH)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Gossau (ZH)",
    community_code: "115",
    latitude: "47.3281",
    longitude: "8.7461",
  },
  {
    country_code: "CH",
    zipcode: "8620",
    place: "Wetzikon ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Wetzikon (ZH)",
    community_code: "121",
    latitude: "47.3264",
    longitude: "8.7978",
  },
  {
    country_code: "CH",
    zipcode: "8623",
    place: "Wetzikon ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Wetzikon (ZH)",
    community_code: "121",
    latitude: "47.3264",
    longitude: "8.7978",
  },
  {
    country_code: "CH",
    zipcode: "8624",
    place: "Grüt (Gossau ZH)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Gossau (ZH)",
    community_code: "115",
    latitude: "47.3115",
    longitude: "8.7834",
  },
  {
    country_code: "CH",
    zipcode: "8625",
    place: "Gossau ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Gossau (ZH)",
    community_code: "115",
    latitude: "47.3051",
    longitude: "8.7583",
  },
  {
    country_code: "CH",
    zipcode: "8626",
    place: "Ottikon (Gossau ZH)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Gossau (ZH)",
    community_code: "115",
    latitude: "47.2946",
    longitude: "8.7819",
  },
  {
    country_code: "CH",
    zipcode: "8627",
    place: "Grüningen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Grüningen",
    community_code: "116",
    latitude: "47.2833",
    longitude: "8.75",
  },
  {
    country_code: "CH",
    zipcode: "8630",
    place: "Rüti ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Rüti (ZH)",
    community_code: "118",
    latitude: "47.256",
    longitude: "8.8555",
  },
  {
    country_code: "CH",
    zipcode: "8632",
    place: "Tann",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Dürnten",
    community_code: "113",
    latitude: "47.269",
    longitude: "8.8502",
  },
  {
    country_code: "CH",
    zipcode: "8633",
    place: "Wolfhausen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Bubikon",
    community_code: "112",
    latitude: "47.2562",
    longitude: "8.7991",
  },
  {
    country_code: "CH",
    zipcode: "8635",
    place: "Dürnten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Dürnten",
    community_code: "113",
    latitude: "47.2786",
    longitude: "8.8416",
  },
  {
    country_code: "CH",
    zipcode: "8636",
    place: "Wald ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Wald (ZH)",
    community_code: "120",
    latitude: "47.276",
    longitude: "8.914",
  },
  {
    country_code: "CH",
    zipcode: "8637",
    place: "Laupen ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Hinwil",
    province_code: "105",
    community: "Wald (ZH)",
    community_code: "120",
    latitude: "47.265",
    longitude: "8.9285",
  },
  {
    country_code: "CH",
    zipcode: "8134",
    place: "Adliswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Adliswil",
    community_code: "131",
    latitude: "47.31",
    longitude: "8.5246",
  },
  {
    country_code: "CH",
    zipcode: "8135",
    place: "Langnau am Albis",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Langnau am Albis",
    community_code: "136",
    latitude: "47.2889",
    longitude: "8.5411",
  },
  {
    country_code: "CH",
    zipcode: "8135",
    place: "Sihlwald",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Horgen",
    community_code: "133",
    latitude: "47.269",
    longitude: "8.5572",
  },
  {
    country_code: "CH",
    zipcode: "8135",
    place: "Sihlbrugg Station",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Horgen",
    community_code: "133",
    latitude: "47.2389",
    longitude: "8.5772",
  },
  {
    country_code: "CH",
    zipcode: "8136",
    place: "Gattikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Thalwil",
    community_code: "141",
    latitude: "47.2844",
    longitude: "8.5483",
  },
  {
    country_code: "CH",
    zipcode: "8800",
    place: "Thalwil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Thalwil",
    community_code: "141",
    latitude: "47.2918",
    longitude: "8.5635",
  },
  {
    country_code: "CH",
    zipcode: "8802",
    place: "Kilchberg ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Kilchberg (ZH)",
    community_code: "135",
    latitude: "47.3244",
    longitude: "8.5455",
  },
  {
    country_code: "CH",
    zipcode: "8803",
    place: "Rüschlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Rüschlikon",
    community_code: "139",
    latitude: "47.3069",
    longitude: "8.5514",
  },
  {
    country_code: "CH",
    zipcode: "8804",
    place: "Au ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Wädenswil",
    community_code: "142",
    latitude: "47.2418",
    longitude: "8.6441",
  },
  {
    country_code: "CH",
    zipcode: "8805",
    place: "Richterswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Richterswil",
    community_code: "138",
    latitude: "47.2062",
    longitude: "8.6969",
  },
  {
    country_code: "CH",
    zipcode: "8810",
    place: "Horgen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Horgen",
    community_code: "133",
    latitude: "47.2598",
    longitude: "8.5978",
  },
  {
    country_code: "CH",
    zipcode: "8815",
    place: "Horgenberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Horgen",
    community_code: "133",
    latitude: "47.2485",
    longitude: "8.5877",
  },
  {
    country_code: "CH",
    zipcode: "8816",
    place: "Hirzel",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Hirzel",
    community_code: "132",
    latitude: "47.2167",
    longitude: "8.6",
  },
  {
    country_code: "CH",
    zipcode: "8820",
    place: "Wädenswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Wädenswil",
    community_code: "142",
    latitude: "47.2268",
    longitude: "8.6687",
  },
  {
    country_code: "CH",
    zipcode: "8824",
    place: "Schönenberg ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Schönenberg (ZH)",
    community_code: "140",
    latitude: "47.1918",
    longitude: "8.6449",
  },
  {
    country_code: "CH",
    zipcode: "8825",
    place: "Hütten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Hütten",
    community_code: "134",
    latitude: "47.1757",
    longitude: "8.6665",
  },
  {
    country_code: "CH",
    zipcode: "8833",
    place: "Samstagern",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Richterswil",
    community_code: "138",
    latitude: "47.1917",
    longitude: "8.682",
  },
  {
    country_code: "CH",
    zipcode: "8942",
    place: "Oberrieden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Horgen",
    province_code: "106",
    community: "Oberrieden",
    community_code: "137",
    latitude: "47.2744",
    longitude: "8.5784",
  },
  {
    country_code: "CH",
    zipcode: "8125",
    place: "Zollikerberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Zollikon",
    community_code: "161",
    latitude: "47.3451",
    longitude: "8.6009",
  },
  {
    country_code: "CH",
    zipcode: "8126",
    place: "Zumikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Zumikon",
    community_code: "160",
    latitude: "47.3316",
    longitude: "8.6227",
  },
  {
    country_code: "CH",
    zipcode: "8127",
    place: "Forch",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Küsnacht (ZH)",
    community_code: "154",
    latitude: "47.3252",
    longitude: "8.6434",
  },
  {
    country_code: "CH",
    zipcode: "8618",
    place: "Oetwil am See",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Oetwil am See",
    community_code: "157",
    latitude: "47.2705",
    longitude: "8.7202",
  },
  {
    country_code: "CH",
    zipcode: "8634",
    place: "Hombrechtikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Hombrechtikon",
    community_code: "153",
    latitude: "47.253",
    longitude: "8.7721",
  },
  {
    country_code: "CH",
    zipcode: "8700",
    place: "Küsnacht ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Küsnacht (ZH)",
    community_code: "154",
    latitude: "47.3181",
    longitude: "8.584",
  },
  {
    country_code: "CH",
    zipcode: "8702",
    place: "Zollikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Zollikon",
    community_code: "161",
    latitude: "47.3402",
    longitude: "8.5741",
  },
  {
    country_code: "CH",
    zipcode: "8703",
    place: "Erlenbach ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Erlenbach (ZH)",
    community_code: "151",
    latitude: "47.303",
    longitude: "8.5974",
  },
  {
    country_code: "CH",
    zipcode: "8704",
    place: "Herrliberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Herrliberg",
    community_code: "152",
    latitude: "47.2906",
    longitude: "8.6146",
  },
  {
    country_code: "CH",
    zipcode: "8706",
    place: "Meilen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Meilen",
    community_code: "156",
    latitude: "47.2723",
    longitude: "8.6462",
  },
  {
    country_code: "CH",
    zipcode: "8707",
    place: "Uetikon am See",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Uetikon am See",
    community_code: "159",
    latitude: "47.2644",
    longitude: "8.6792",
  },
  {
    country_code: "CH",
    zipcode: "8708",
    place: "Männedorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Männedorf",
    community_code: "155",
    latitude: "47.2569",
    longitude: "8.6989",
  },
  {
    country_code: "CH",
    zipcode: "8712",
    place: "Stäfa",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Stäfa",
    community_code: "158",
    latitude: "47.2425",
    longitude: "8.7234",
  },
  {
    country_code: "CH",
    zipcode: "8713",
    place: "Uerikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Stäfa",
    community_code: "158",
    latitude: "47.2367",
    longitude: "8.7573",
  },
  {
    country_code: "CH",
    zipcode: "8714",
    place: "Feldbach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Meilen",
    province_code: "107",
    community: "Hombrechtikon",
    community_code: "153",
    latitude: "47.2396",
    longitude: "8.7847",
  },
  {
    country_code: "CH",
    zipcode: "8307",
    place: "Effretikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Illnau-Effretikon",
    community_code: "296",
    latitude: "47.4258",
    longitude: "8.6909",
  },
  {
    country_code: "CH",
    zipcode: "8307",
    place: "Ottikon b. Kemptthal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Illnau-Effretikon",
    community_code: "296",
    latitude: "47.4258",
    longitude: "8.6909",
  },
  {
    country_code: "CH",
    zipcode: "8308",
    place: "Agasul",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Illnau-Effretikon",
    community_code: "296",
    latitude: "47.4258",
    longitude: "8.7408",
  },
  {
    country_code: "CH",
    zipcode: "8308",
    place: "Illnau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Illnau-Effretikon",
    community_code: "296",
    latitude: "47.4113",
    longitude: "8.7213",
  },
  {
    country_code: "CH",
    zipcode: "8310",
    place: "Kemptthal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Lindau",
    community_code: "176",
    latitude: "47.4472",
    longitude: "8.7044",
  },
  {
    country_code: "CH",
    zipcode: "8310",
    place: "Grafstal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Lindau",
    community_code: "176",
    latitude: "47.4434",
    longitude: "8.6996",
  },
  {
    country_code: "CH",
    zipcode: "8312",
    place: "Winterberg ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Lindau",
    community_code: "176",
    latitude: "47.4565",
    longitude: "8.6943",
  },
  {
    country_code: "CH",
    zipcode: "8314",
    place: "Kyburg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Illnau-Effretikon",
    community_code: "296",
    latitude: "47.4557",
    longitude: "8.7448",
  },
  {
    country_code: "CH",
    zipcode: "8315",
    place: "Lindau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Lindau",
    community_code: "176",
    latitude: "47.443",
    longitude: "8.6736",
  },
  {
    country_code: "CH",
    zipcode: "8317",
    place: "Tagelswangen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Lindau",
    community_code: "176",
    latitude: "47.4307",
    longitude: "8.6728",
  },
  {
    country_code: "CH",
    zipcode: "8320",
    place: "Fehraltorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Fehraltorf",
    community_code: "172",
    latitude: "47.3878",
    longitude: "8.7515",
  },
  {
    country_code: "CH",
    zipcode: "8322",
    place: "Gündisau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Russikon",
    community_code: "178",
    latitude: "47.3984",
    longitude: "8.8084",
  },
  {
    country_code: "CH",
    zipcode: "8322",
    place: "Madetswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Russikon",
    community_code: "178",
    latitude: "47.4107",
    longitude: "8.792",
  },
  {
    country_code: "CH",
    zipcode: "8330",
    place: "Pfäffikon ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Pfäffikon",
    community_code: "177",
    latitude: "47.3645",
    longitude: "8.792",
  },
  {
    country_code: "CH",
    zipcode: "8331",
    place: "Auslikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Pfäffikon",
    community_code: "177",
    latitude: "47.3443",
    longitude: "8.8065",
  },
  {
    country_code: "CH",
    zipcode: "8332",
    place: "Russikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Russikon",
    community_code: "178",
    latitude: "47.3967",
    longitude: "8.7751",
  },
  {
    country_code: "CH",
    zipcode: "8332",
    place: "Rumlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Russikon",
    community_code: "178",
    latitude: "47.4078",
    longitude: "8.7616",
  },
  {
    country_code: "CH",
    zipcode: "8335",
    place: "Hittnau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Hittnau",
    community_code: "173",
    latitude: "47.3633",
    longitude: "8.8242",
  },
  {
    country_code: "CH",
    zipcode: "8484",
    place: "Theilingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Weisslingen",
    community_code: "180",
    latitude: "47.4208",
    longitude: "8.7676",
  },
  {
    country_code: "CH",
    zipcode: "8484",
    place: "Weisslingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Weisslingen",
    community_code: "180",
    latitude: "47.4306",
    longitude: "8.7679",
  },
  {
    country_code: "CH",
    zipcode: "8484",
    place: "Neschwil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Weisslingen",
    community_code: "180",
    latitude: "47.4294",
    longitude: "8.7888",
  },
  {
    country_code: "CH",
    zipcode: "8489",
    place: "Schalchen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Wildberg",
    community_code: "182",
    latitude: "47.4073",
    longitude: "8.8255",
  },
  {
    country_code: "CH",
    zipcode: "8489",
    place: "Ehrikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Wildberg",
    community_code: "182",
    latitude: "47.4196",
    longitude: "8.8102",
  },
  {
    country_code: "CH",
    zipcode: "8489",
    place: "Wildberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Wildberg",
    community_code: "182",
    latitude: "47.4259",
    longitude: "8.8167",
  },
  {
    country_code: "CH",
    zipcode: "8492",
    place: "Wila",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Wila",
    community_code: "181",
    latitude: "47.4193",
    longitude: "8.8452",
  },
  {
    country_code: "CH",
    zipcode: "8493",
    place: "Saland",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Bauma",
    community_code: "297",
    latitude: "47.3907",
    longitude: "8.8529",
  },
  {
    country_code: "CH",
    zipcode: "8494",
    place: "Bauma",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Bauma",
    community_code: "297",
    latitude: "47.3674",
    longitude: "8.879",
  },
  {
    country_code: "CH",
    zipcode: "8499",
    place: "Sternenberg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Pfäffikon",
    province_code: "108",
    community: "Bauma",
    community_code: "297",
    latitude: "47.3833",
    longitude: "8.9167",
  },
  {
    country_code: "CH",
    zipcode: "8044",
    place: "Gockhausen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Dübendorf",
    community_code: "191",
    latitude: "47.381",
    longitude: "8.5998",
  },
  {
    country_code: "CH",
    zipcode: "8117",
    place: "Fällanden",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Fällanden",
    community_code: "193",
    latitude: "47.3717",
    longitude: "8.6387",
  },
  {
    country_code: "CH",
    zipcode: "8118",
    place: "Pfaffhausen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Fällanden",
    community_code: "193",
    latitude: "47.3648",
    longitude: "8.6238",
  },
  {
    country_code: "CH",
    zipcode: "8121",
    place: "Benglen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Fällanden",
    community_code: "193",
    latitude: "47.3608",
    longitude: "8.6369",
  },
  {
    country_code: "CH",
    zipcode: "8122",
    place: "Binz",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Maur",
    community_code: "195",
    latitude: "47.3563",
    longitude: "8.6266",
  },
  {
    country_code: "CH",
    zipcode: "8123",
    place: "Ebmatingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Maur",
    community_code: "195",
    latitude: "47.3499",
    longitude: "8.6401",
  },
  {
    country_code: "CH",
    zipcode: "8124",
    place: "Maur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Maur",
    community_code: "195",
    latitude: "47.34",
    longitude: "8.6678",
  },
  {
    country_code: "CH",
    zipcode: "8132",
    place: "Hinteregg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Egg",
    community_code: "192",
    latitude: "47.3074",
    longitude: "8.6834",
  },
  {
    country_code: "CH",
    zipcode: "8132",
    place: "Egg b. Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Egg",
    community_code: "192",
    latitude: "47.3074",
    longitude: "8.6834",
  },
  {
    country_code: "CH",
    zipcode: "8133",
    place: "Esslingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Egg",
    community_code: "192",
    latitude: "47.2833",
    longitude: "8.7104",
  },
  {
    country_code: "CH",
    zipcode: "8306",
    place: "Brüttisellen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Wangen-Brüttisellen",
    community_code: "200",
    latitude: "47.4217",
    longitude: "8.6326",
  },
  {
    country_code: "CH",
    zipcode: "8600",
    place: "Dübendorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Dübendorf",
    community_code: "191",
    latitude: "47.3972",
    longitude: "8.6187",
  },
  {
    country_code: "CH",
    zipcode: "8602",
    place: "Wangen b. Dübendorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Wangen-Brüttisellen",
    community_code: "200",
    latitude: "47.4118",
    longitude: "8.6452",
  },
  {
    country_code: "CH",
    zipcode: "8603",
    place: "Schwerzenbach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Schwerzenbach",
    community_code: "197",
    latitude: "47.3821",
    longitude: "8.6573",
  },
  {
    country_code: "CH",
    zipcode: "8604",
    place: "Volketswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Volketswil",
    community_code: "199",
    latitude: "47.3902",
    longitude: "8.6909",
  },
  {
    country_code: "CH",
    zipcode: "8605",
    place: "Gutenswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Volketswil",
    community_code: "199",
    latitude: "47.3839",
    longitude: "8.7176",
  },
  {
    country_code: "CH",
    zipcode: "8606",
    place: "Nänikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3697",
    longitude: "8.6889",
  },
  {
    country_code: "CH",
    zipcode: "8606",
    place: "Greifensee",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Greifensee",
    community_code: "194",
    latitude: "47.3672",
    longitude: "8.6811",
  },
  {
    country_code: "CH",
    zipcode: "8610",
    place: "Uster",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3471",
    longitude: "8.7209",
  },
  {
    country_code: "CH",
    zipcode: "8613",
    place: "Uster 3",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3532",
    longitude: "8.7216",
  },
  {
    country_code: "CH",
    zipcode: "8614",
    place: "Sulzbach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3281",
    longitude: "8.7461",
  },
  {
    country_code: "CH",
    zipcode: "8615",
    place: "Freudwil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3757",
    longitude: "8.7334",
  },
  {
    country_code: "CH",
    zipcode: "8615",
    place: "Wermatswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3641",
    longitude: "8.7415",
  },
  {
    country_code: "CH",
    zipcode: "8616",
    place: "Riedikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Uster",
    community_code: "198",
    latitude: "47.3317",
    longitude: "8.7127",
  },
  {
    country_code: "CH",
    zipcode: "8617",
    place: "Mönchaltorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Uster",
    province_code: "109",
    community: "Mönchaltorf",
    community_code: "196",
    latitude: "47.3096",
    longitude: "8.7203",
  },
  {
    country_code: "CH",
    zipcode: "8311",
    place: "Brütten",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Brütten",
    community_code: "213",
    latitude: "47.4732",
    longitude: "8.6757",
  },
  {
    country_code: "CH",
    zipcode: "8352",
    place: "Elsau",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Elsau",
    community_code: "219",
    latitude: "47.5026",
    longitude: "8.8079",
  },
  {
    country_code: "CH",
    zipcode: "8352",
    place: "Ricketwil (Winterthur)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.4869",
    longitude: "8.8006",
  },
  {
    country_code: "CH",
    zipcode: "8353",
    place: "Elgg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Elgg",
    community_code: "217",
    latitude: "47.4972",
    longitude: "8.8652",
  },
  {
    country_code: "CH",
    zipcode: "8354",
    place: "Hofstetten ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Hofstetten (ZH)",
    community_code: "222",
    latitude: "47.4724",
    longitude: "8.854",
  },
  {
    country_code: "CH",
    zipcode: "8400",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8401",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.4967",
    longitude: "8.7342",
  },
  {
    country_code: "CH",
    zipcode: "8403",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.4967",
    longitude: "8.7342",
  },
  {
    country_code: "CH",
    zipcode: "8404",
    place: "Stadel (Winterthur)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5344",
    longitude: "8.7628",
  },
  {
    country_code: "CH",
    zipcode: "8404",
    place: "Reutlingen (Winterthur)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5281",
    longitude: "8.7484",
  },
  {
    country_code: "CH",
    zipcode: "8404",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8405",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8406",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8408",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8409",
    place: "Winterthur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.5056",
    longitude: "8.7241",
  },
  {
    country_code: "CH",
    zipcode: "8412",
    place: "Aesch (Neftenbach)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Neftenbach",
    community_code: "223",
    latitude: "47.5411",
    longitude: "8.6796",
  },
  {
    country_code: "CH",
    zipcode: "8412",
    place: "Riet (Neftenbach)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Neftenbach",
    community_code: "223",
    latitude: "47.5369",
    longitude: "8.6885",
  },
  {
    country_code: "CH",
    zipcode: "8412",
    place: "Hünikon (Neftenbach)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Neftenbach",
    community_code: "223",
    latitude: "47.552",
    longitude: "8.6678",
  },
  {
    country_code: "CH",
    zipcode: "8413",
    place: "Neftenbach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Neftenbach",
    community_code: "223",
    latitude: "47.5276",
    longitude: "8.6649",
  },
  {
    country_code: "CH",
    zipcode: "8418",
    place: "Schlatt ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Schlatt (ZH)",
    community_code: "226",
    latitude: "47.4667",
    longitude: "8.8282",
  },
  {
    country_code: "CH",
    zipcode: "8421",
    place: "Dättlikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dättlikon",
    community_code: "215",
    latitude: "47.5249",
    longitude: "8.6245",
  },
  {
    country_code: "CH",
    zipcode: "8422",
    place: "Pfungen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Pfungen",
    community_code: "224",
    latitude: "47.5139",
    longitude: "8.6423",
  },
  {
    country_code: "CH",
    zipcode: "8442",
    place: "Hettlingen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Hettlingen",
    community_code: "221",
    latitude: "47.5461",
    longitude: "8.7053",
  },
  {
    country_code: "CH",
    zipcode: "8471",
    place: "Oberwil (Dägerlen)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dägerlen",
    community_code: "214",
    latitude: "47.5736",
    longitude: "8.7171",
  },
  {
    country_code: "CH",
    zipcode: "8471",
    place: "Rutschwil (Dägerlen)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dägerlen",
    community_code: "214",
    latitude: "47.5569",
    longitude: "8.7313",
  },
  {
    country_code: "CH",
    zipcode: "8471",
    place: "Berg (Dägerlen)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dägerlen",
    community_code: "214",
    latitude: "47.5636",
    longitude: "8.7322",
  },
  {
    country_code: "CH",
    zipcode: "8471",
    place: "Dägerlen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dägerlen",
    community_code: "214",
    latitude: "47.5607",
    longitude: "8.7215",
  },
  {
    country_code: "CH",
    zipcode: "8471",
    place: "Bänk (Dägerlen)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dägerlen",
    community_code: "214",
    latitude: "47.5482",
    longitude: "8.739",
  },
  {
    country_code: "CH",
    zipcode: "8472",
    place: "Seuzach",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Seuzach",
    community_code: "227",
    latitude: "47.5356",
    longitude: "8.7321",
  },
  {
    country_code: "CH",
    zipcode: "8474",
    place: "Dinhard",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Dinhard",
    community_code: "216",
    latitude: "47.55",
    longitude: "8.7667",
  },
  {
    country_code: "CH",
    zipcode: "8479",
    place: "Altikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Altikon",
    community_code: "211",
    latitude: "47.574",
    longitude: "8.7828",
  },
  {
    country_code: "CH",
    zipcode: "8482",
    place: "Sennhof (Winterthur)",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Winterthur",
    community_code: "230",
    latitude: "47.468",
    longitude: "8.7578",
  },
  {
    country_code: "CH",
    zipcode: "8483",
    place: "Kollbrunn",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Zell (ZH)",
    community_code: "231",
    latitude: "47.4579",
    longitude: "8.7829",
  },
  {
    country_code: "CH",
    zipcode: "8486",
    place: "Rikon im Tösstal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Zell (ZH)",
    community_code: "231",
    latitude: "47.4459",
    longitude: "8.798",
  },
  {
    country_code: "CH",
    zipcode: "8487",
    place: "Rämismühle",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Zell (ZH)",
    community_code: "231",
    latitude: "47.4393",
    longitude: "8.8217",
  },
  {
    country_code: "CH",
    zipcode: "8487",
    place: "Zell ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Zell (ZH)",
    community_code: "231",
    latitude: "47.4461",
    longitude: "8.8218",
  },
  {
    country_code: "CH",
    zipcode: "8488",
    place: "Turbenthal",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Turbenthal",
    community_code: "228",
    latitude: "47.4363",
    longitude: "8.8463",
  },
  {
    country_code: "CH",
    zipcode: "8495",
    place: "Schmidrüti",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Turbenthal",
    community_code: "228",
    latitude: "47.4155",
    longitude: "8.9029",
  },
  {
    country_code: "CH",
    zipcode: "8523",
    place: "Hagenbuch ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Hagenbuch",
    community_code: "220",
    latitude: "47.5204",
    longitude: "8.8892",
  },
  {
    country_code: "CH",
    zipcode: "8542",
    place: "Wiesendangen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5217",
    longitude: "8.7897",
  },
  {
    country_code: "CH",
    zipcode: "8543",
    place: "Gundetswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5407",
    longitude: "8.823",
  },
  {
    country_code: "CH",
    zipcode: "8543",
    place: "Kefikon ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5489",
    longitude: "8.8307",
  },
  {
    country_code: "CH",
    zipcode: "8543",
    place: "Bertschikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5273",
    longitude: "8.8142",
  },
  {
    country_code: "CH",
    zipcode: "8544",
    place: "Attikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5362",
    longitude: "8.7964",
  },
  {
    country_code: "CH",
    zipcode: "8545",
    place: "Rickenbach Sulz",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Rickenbach (ZH)",
    community_code: "225",
    latitude: "47.5395",
    longitude: "8.7889",
  },
  {
    country_code: "CH",
    zipcode: "8545",
    place: "Rickenbach ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Rickenbach (ZH)",
    community_code: "225",
    latitude: "47.5519",
    longitude: "8.7965",
  },
  {
    country_code: "CH",
    zipcode: "8546",
    place: "Menzengrüt",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Wiesendangen",
    community_code: "298",
    latitude: "47.5494",
    longitude: "8.8215",
  },
  {
    country_code: "CH",
    zipcode: "8548",
    place: "Ellikon an der Thur",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Winterthur",
    province_code: "110",
    community: "Ellikon an der Thur",
    community_code: "218",
    latitude: "47.563",
    longitude: "8.8233",
  },
  {
    country_code: "CH",
    zipcode: "8010",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Schlieren",
    community_code: "247",
    latitude: "47.395",
    longitude: "8.4488",
  },
  {
    country_code: "CH",
    zipcode: "8012",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Schlieren",
    community_code: "247",
    latitude: "47.395",
    longitude: "8.4488",
  },
  {
    country_code: "CH",
    zipcode: "8102",
    place: "Oberengstringen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Oberengstringen",
    community_code: "245",
    latitude: "47.4084",
    longitude: "8.4651",
  },
  {
    country_code: "CH",
    zipcode: "8103",
    place: "Unterengstringen",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Unterengstringen",
    community_code: "249",
    latitude: "47.414",
    longitude: "8.4476",
  },
  {
    country_code: "CH",
    zipcode: "8104",
    place: "Weiningen ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Weiningen (ZH)",
    community_code: "251",
    latitude: "47.4202",
    longitude: "8.4364",
  },
  {
    country_code: "CH",
    zipcode: "8142",
    place: "Uitikon Waldegg",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Uitikon",
    community_code: "248",
    latitude: "47.3691",
    longitude: "8.457",
  },
  {
    country_code: "CH",
    zipcode: "8901",
    place: "Urdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Urdorf",
    community_code: "250",
    latitude: "47.3798",
    longitude: "8.4224",
  },
  {
    country_code: "CH",
    zipcode: "8902",
    place: "Urdorf",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Urdorf",
    community_code: "250",
    latitude: "47.3851",
    longitude: "8.4258",
  },
  {
    country_code: "CH",
    zipcode: "8903",
    place: "Birmensdorf ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Birmensdorf (ZH)",
    community_code: "242",
    latitude: "47.3552",
    longitude: "8.4426",
  },
  {
    country_code: "CH",
    zipcode: "8904",
    place: "Aesch ZH",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Aesch (ZH)",
    community_code: "241",
    latitude: "47.3367",
    longitude: "8.441",
  },
  {
    country_code: "CH",
    zipcode: "8951",
    place: "Fahrweid",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Geroldswil",
    community_code: "244",
    latitude: "47.4117",
    longitude: "8.4156",
  },
  {
    country_code: "CH",
    zipcode: "8952",
    place: "Schlieren",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Schlieren",
    community_code: "247",
    latitude: "47.3967",
    longitude: "8.4476",
  },
  {
    country_code: "CH",
    zipcode: "8953",
    place: "Dietikon",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Dietikon",
    community_code: "243",
    latitude: "47.4017",
    longitude: "8.4001",
  },
  {
    country_code: "CH",
    zipcode: "8954",
    place: "Geroldswil",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Geroldswil",
    community_code: "244",
    latitude: "47.4221",
    longitude: "8.4108",
  },
  {
    country_code: "CH",
    zipcode: "8955",
    place: "Oetwil an der Limmat",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Dietikon",
    province_code: "111",
    community: "Oetwil an der Limmat",
    community_code: "246",
    latitude: "47.4283",
    longitude: "8.3949",
  },
  {
    country_code: "CH",
    zipcode: "8000",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8001",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8002",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8003",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8004",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8005",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8006",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8008",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8021",
    place: "Zürich 1",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8022",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8024",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8027",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8031",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8032",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8034",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8036",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8037",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8038",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8040",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8041",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8042",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8044",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8045",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8046",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8047",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8048",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8049",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8050",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8051",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8052",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8053",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8055",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8057",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8063",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8064",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3667",
    longitude: "8.55",
  },
  {
    country_code: "CH",
    zipcode: "8070",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8071",
    place: "Zürich CS PZ",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8074",
    place: "Zürich Voice Pub",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8075",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8080",
    place: "Zürich 80",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8081",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8085",
    place: "Zürich Versich.",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8086",
    place: "Zürich R Digest",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8087",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8088",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8090",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8091",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8092",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8093",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8096",
    place: "Zürich IBRS local",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8098",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
  {
    country_code: "CH",
    zipcode: "8099",
    place: "Zürich",
    state: "Kanton Zürich",
    state_code: "ZH",
    province: "Bezirk Zürich",
    province_code: "112",
    community: "Zürich",
    community_code: "261",
    latitude: "47.3828",
    longitude: "8.5307",
  },
];
