export const SwissPlz = [
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 16",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 22",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 24 Vennes",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 27",
  },
  {
    npa: "1003",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1008",
    canton: "VD",
    location: "Prilly",
  },
  {
    npa: "1012",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1014",
    canton: "VD",
    location: "Lausanne Adm cant VD",
  },
  {
    npa: "1024",
    canton: "VD",
    location: "Ecublens VD",
  },
  {
    npa: "1026",
    canton: "VD",
    location: "Echandens-Denges",
  },
  {
    npa: "1027",
    canton: "VD",
    location: "Lonay",
  },
  {
    npa: "1030",
    canton: "VD",
    location: "Bussigny",
  },
  {
    npa: "1040",
    canton: "VD",
    location: "St-Barthélemy VD",
  },
  {
    npa: "1042",
    canton: "VD",
    location: "Bettens",
  },
  {
    npa: "1042",
    canton: "VD",
    location: "Bioley-Orjulaz",
  },
  {
    npa: "1052",
    canton: "VD",
    location: "Le Mont-sur-Lausanne",
  },
  {
    npa: "1053",
    canton: "VD",
    location: "Cugy VD",
  },
  {
    npa: "1055",
    canton: "VD",
    location: "Froideville",
  },
  {
    npa: "1410",
    canton: "VD",
    location: "Thierrens",
  },
  {
    npa: "1092",
    canton: "VD",
    location: "Belmont-sur-Lausanne",
  },
  {
    npa: "1058",
    canton: "VD",
    location: "Villars-Tiercelin",
  },
  {
    npa: "1059",
    canton: "VD",
    location: "Peney-le-Jorat",
  },
  {
    npa: "1062",
    canton: "VD",
    location: "Sottens",
  },
  {
    npa: "1063",
    canton: "VD",
    location: "Chapelle-sur-Moudon",
  },
  {
    npa: "1080",
    canton: "VD",
    location: "Les Cullayes",
  },
  {
    npa: "1673",
    canton: "FR",
    location: "Auboranges",
  },
  {
    npa: "1110",
    canton: "VD",
    location: "Morges 1",
  },
  {
    npa: "1132",
    canton: "VD",
    location: "Lully VD",
  },
  {
    npa: "1169",
    canton: "VD",
    location: "Yens",
  },
  {
    npa: "1142",
    canton: "VD",
    location: "Pampigny",
  },
  {
    npa: "1141",
    canton: "VD",
    location: "Sévery",
  },
  {
    npa: "1184",
    canton: "VD",
    location: "Luins",
  },
  {
    npa: "1185",
    canton: "VD",
    location: "Mont-sur-Rolle",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 4",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 7",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 13",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 19",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 21",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève Dist Ba",
  },
  {
    npa: "1201",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1202",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1205",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1206",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1218",
    canton: "GE",
    location: "Le Grand-Saconnex",
  },
  {
    npa: "1225",
    canton: "GE",
    location: "Chêne-Bourg",
  },
  {
    npa: "1227",
    canton: "GE",
    location: "Les Acacias",
  },
  {
    npa: "1228",
    canton: "GE",
    location: "Plan-les-Ouates",
  },
  {
    npa: "1239",
    canton: "GE",
    location: "Collex",
  },
  {
    npa: "1241",
    canton: "GE",
    location: "Puplinge",
  },
  {
    npa: "1246",
    canton: "GE",
    location: "Corsier GE",
  },
  {
    npa: "1248",
    canton: "GE",
    location: "Hermance",
  },
  {
    npa: "1253",
    canton: "GE",
    location: "Vandoeuvres",
  },
  {
    npa: "1269",
    canton: "VD",
    location: "Bassins",
  },
  {
    npa: "1263",
    canton: "VD",
    location: "Crassier",
  },
  {
    npa: "1265",
    canton: "VD",
    location: "La Cure",
  },
  {
    npa: "1266",
    canton: "VD",
    location: "Duillier",
  },
  {
    npa: "1284",
    canton: "GE",
    location: "Chancy",
  },
  {
    npa: "1286",
    canton: "GE",
    location: "Soral",
  },
  {
    npa: "1288",
    canton: "GE",
    location: "Aire-la-Ville",
  },
  {
    npa: "1291",
    canton: "VD",
    location: "Commugny",
  },
  {
    npa: "1338",
    canton: "VD",
    location: "Ballaigues",
  },
  {
    npa: "1341",
    canton: "VD",
    location: "L'Orient",
  },
  {
    npa: "1346",
    canton: "VD",
    location: "Les Bioux",
  },
  {
    npa: "1342",
    canton: "VD",
    location: "Le Pont",
  },
  {
    npa: "1329",
    canton: "VD",
    location: "Bretonnières",
  },
  {
    npa: "1354",
    canton: "VD",
    location: "Montcherand",
  },
  {
    npa: "1355",
    canton: "VD",
    location: "L'Abergement",
  },
  {
    npa: "1406",
    canton: "VD",
    location: "Cronay",
  },
  {
    npa: "1424",
    canton: "VD",
    location: "Champagne",
  },
  {
    npa: "1515",
    canton: "VD",
    location: "Neyruz-sur-Moudon",
  },
  {
    npa: "1410",
    canton: "VD",
    location: "Denezy",
  },
  {
    npa: "1522",
    canton: "VD",
    location: "Lucens",
  },
  {
    npa: "1551",
    canton: "VD",
    location: "Vers-chez-Perrin",
  },
  {
    npa: "1682",
    canton: "VD",
    location: "Villars-Bramard",
  },
  {
    npa: "1682",
    canton: "VD",
    location: "Dompierre VD",
  },
  {
    npa: "1568",
    canton: "FR",
    location: "Portalban",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 2",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 12",
  },
  {
    npa: "1018",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1020",
    canton: "VD",
    location: "Renens VD Village",
  },
  {
    npa: "1022",
    canton: "VD",
    location: "Chavannes-près-Renens",
  },
  {
    npa: "1063",
    canton: "VD",
    location: "Peyres-Possens",
  },
  {
    npa: "1036",
    canton: "VD",
    location: "Sullens",
  },
  {
    npa: "1043",
    canton: "VD",
    location: "Sugnens",
  },
  {
    npa: "1044",
    canton: "VD",
    location: "Fey",
  },
  {
    npa: "1045",
    canton: "VD",
    location: "Ogens",
  },
  {
    npa: "1082",
    canton: "VD",
    location: "Corcelles-le-Jorat",
  },
  {
    npa: "1088",
    canton: "VD",
    location: "Ropraz",
  },
  {
    npa: "1084",
    canton: "VD",
    location: "Carrouge VD",
  },
  {
    npa: "1076",
    canton: "VD",
    location: "Ferlens VD",
  },
  {
    npa: "1077",
    canton: "VD",
    location: "Servion",
  },
  {
    npa: "1078",
    canton: "VD",
    location: "Essertes",
  },
  {
    npa: "1167",
    canton: "VD",
    location: "Lussy-sur-Morges",
  },
  {
    npa: "1113",
    canton: "VD",
    location: "St-Saphorin-sur-Morges",
  },
  {
    npa: "1122",
    canton: "VD",
    location: "Romanel-sur-Morges",
  },
  {
    npa: "1123",
    canton: "VD",
    location: "Aclens",
  },
  {
    npa: "1134",
    canton: "VD",
    location: "Vufflens-le-Château",
  },
  {
    npa: "1135",
    canton: "VD",
    location: "Denens",
  },
  {
    npa: "1146",
    canton: "VD",
    location: "Mollens VD",
  },
  {
    npa: "1162",
    canton: "VD",
    location: "St-Prex",
  },
  {
    npa: "1166",
    canton: "VD",
    location: "Perroy",
  },
  {
    npa: "1170",
    canton: "VD",
    location: "Aubonne",
  },
  {
    npa: "1173",
    canton: "VD",
    location: "Féchy",
  },
  {
    npa: "1184",
    canton: "VD",
    location: "Vinzel",
  },
  {
    npa: "1203",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1207",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 1",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 3",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 6",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 8",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 11",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 19",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 27",
  },
  {
    npa: "1213",
    canton: "GE",
    location: "Petit-Lancy 1",
  },
  {
    npa: "1215",
    canton: "GE",
    location: "Genève 15 Aéroport",
  },
  {
    npa: "1217",
    canton: "GE",
    location: "Meyrin",
  },
  {
    npa: "1219",
    canton: "GE",
    location: "Le Lignon",
  },
  {
    npa: "1260",
    canton: "VD",
    location: "Nyon",
  },
  {
    npa: "1260",
    canton: "VD",
    location: "Nyon 1 Distribution",
  },
  {
    npa: "1277",
    canton: "VD",
    location: "Borex",
  },
  {
    npa: "1275",
    canton: "VD",
    location: "Chéserex",
  },
  {
    npa: "1276",
    canton: "VD",
    location: "Gingins",
  },
  {
    npa: "1273",
    canton: "VD",
    location: "Arzier-Le Muids",
  },
  {
    npa: "1261",
    canton: "VD",
    location: "Marchissy",
  },
  {
    npa: "1294",
    canton: "GE",
    location: "Genthod",
  },
  {
    npa: "1297",
    canton: "VD",
    location: "Founex",
  },
  {
    npa: "1299",
    canton: "VD",
    location: "Crans VD",
  },
  {
    npa: "1304",
    canton: "VD",
    location: "Cossonay-Ville",
  },
  {
    npa: "1304",
    canton: "VD",
    location: "Dizy",
  },
  {
    npa: "1307",
    canton: "VD",
    location: "Lussery-Villars",
  },
  {
    npa: "1343",
    canton: "VD",
    location: "Les Charbonnières",
  },
  {
    npa: "1347",
    canton: "VD",
    location: "Le Sentier",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Cuarnens",
  },
  {
    npa: "1313",
    canton: "VD",
    location: "Ferreyres",
  },
  {
    npa: "1321",
    canton: "VD",
    location: "Arnex-sur-Orbe",
  },
  {
    npa: "1318",
    canton: "VD",
    location: "Pompaples",
  },
  {
    npa: "1312",
    canton: "VD",
    location: "Eclépens",
  },
  {
    npa: "1324",
    canton: "VD",
    location: "Premier",
  },
  {
    npa: "1352",
    canton: "VD",
    location: "Agiez",
  },
  {
    npa: "1356",
    canton: "VD",
    location: "Les Clées",
  },
  {
    npa: "1375",
    canton: "VD",
    location: "Penthéréaz",
  },
  {
    npa: "1376",
    canton: "VD",
    location: "Goumoens-la-Ville",
  },
  {
    npa: "1417",
    canton: "VD",
    location: "Essertines-sur-Yverdon",
  },
  {
    npa: "1423",
    canton: "VD",
    location: "Villars-Burquin",
  },
  {
    npa: "1453",
    canton: "VD",
    location: "Mauborget",
  },
  {
    npa: "1430",
    canton: "VD",
    location: "Orges",
  },
  {
    npa: "1427",
    canton: "VD",
    location: "Bonvillars",
  },
  {
    npa: "1438",
    canton: "VD",
    location: "Mathod",
  },
  {
    npa: "1443",
    canton: "VD",
    location: "Champvent",
  },
  {
    npa: "1446",
    canton: "VD",
    location: "Baulmes",
  },
  {
    npa: "1454",
    canton: "VD",
    location: "L'Auberson",
  },
  {
    npa: "1450",
    canton: "VD",
    location: "Le Château-de-Ste-Croix",
  },
  {
    npa: "1464",
    canton: "VD",
    location: "Chêne-Pâquier",
  },
  {
    npa: "1470",
    canton: "FR",
    location: "Estavayer-le-Lac",
  },
  {
    npa: "1473",
    canton: "FR",
    location: "Font",
  },
  {
    npa: "1489",
    canton: "FR",
    location: "Murist",
  },
  {
    npa: "1512",
    canton: "VD",
    location: "Chavannes-sur-Moudon",
  },
  {
    npa: "1514",
    canton: "VD",
    location: "Bussy-sur-Moudon",
  },
  {
    npa: "1521",
    canton: "VD",
    location: "Curtilles",
  },
  {
    npa: "1532",
    canton: "FR",
    location: "Fétigny",
  },
  {
    npa: "1533",
    canton: "FR",
    location: "Ménières",
  },
  {
    npa: "1552",
    canton: "VD",
    location: "Trey",
  },
  {
    npa: "1535",
    canton: "VD",
    location: "Combremont-le-Grand",
  },
  {
    npa: "1563",
    canton: "FR",
    location: "Dompierre FR",
  },
  {
    npa: "1564",
    canton: "FR",
    location: "Domdidier",
  },
  {
    npa: "1567",
    canton: "FR",
    location: "Delley",
  },
  {
    npa: "1566",
    canton: "FR",
    location: "St-Aubin FR",
  },
  {
    npa: "1583",
    canton: "FR",
    location: "Villarepos",
  },
  {
    npa: "1585",
    canton: "VD",
    location: "Salavaux",
  },
  {
    npa: "1607",
    canton: "VD",
    location: "Palézieux-Village",
  },
  {
    npa: "1673",
    canton: "FR",
    location: "Ecublens FR",
  },
  {
    npa: "1524",
    canton: "VD",
    location: "Marnand",
  },
  {
    npa: "1071",
    canton: "VD",
    location: "Chexbres",
  },
  {
    npa: "1607",
    canton: "VD",
    location: "Palézieux",
  },
  {
    npa: "1615",
    canton: "FR",
    location: "Bossonnens",
  },
  {
    npa: "1616",
    canton: "FR",
    location: "Attalens",
  },
  {
    npa: "1617",
    canton: "FR",
    location: "Remaufens",
  },
  {
    npa: "1633",
    canton: "FR",
    location: "Vuippens",
  },
  {
    npa: "1645",
    canton: "FR",
    location: "Le Bry",
  },
  {
    npa: "1646",
    canton: "FR",
    location: "Echarlens",
  },
  {
    npa: "1648",
    canton: "FR",
    location: "Hauteville",
  },
  {
    npa: "1654",
    canton: "FR",
    location: "Cerniat FR",
  },
  {
    npa: "1652",
    canton: "FR",
    location: "Botterens",
  },
  {
    npa: "1632",
    canton: "FR",
    location: "Riaz",
  },
  {
    npa: "1634",
    canton: "FR",
    location: "La Roche FR",
  },
  {
    npa: "1649",
    canton: "FR",
    location: "Pont-la-Ville",
  },
  {
    npa: "1637",
    canton: "FR",
    location: "Charmey (Gruyère)",
  },
  {
    npa: "1661",
    canton: "FR",
    location: "Le Pâquier-Montbarry",
  },
  {
    npa: "1663",
    canton: "FR",
    location: "Pringy",
  },
  {
    npa: "1667",
    canton: "FR",
    location: "Enney",
  },
  {
    npa: "1669",
    canton: "FR",
    location: "Neirivue",
  },
  {
    npa: "1663",
    canton: "FR",
    location: "Gruyères",
  },
  {
    npa: "1663",
    canton: "FR",
    location: "Epagny",
  },
  {
    npa: "1666",
    canton: "FR",
    location: "Grandvillard",
  },
  {
    npa: "1675",
    canton: "FR",
    location: "Vauderens",
  },
  {
    npa: "1674",
    canton: "FR",
    location: "Vuarmarens",
  },
  {
    npa: "1673",
    canton: "FR",
    location: "Promasens",
  },
  {
    npa: "1682",
    canton: "VD",
    location: "Prévonloup",
  },
  {
    npa: "1683",
    canton: "VD",
    location: "Chesalles-sur-Moudon",
  },
  {
    npa: "1694",
    canton: "FR",
    location: "Villarsiviriaux",
  },
  {
    npa: "1684",
    canton: "FR",
    location: "Mézières FR",
  },
  {
    npa: "1686",
    canton: "FR",
    location: "Grangettes-près-Romont",
  },
  {
    npa: "1688",
    canton: "FR",
    location: "Sommentier",
  },
  {
    npa: "1625",
    canton: "FR",
    location: "Sâles (Gruyère)",
  },
  {
    npa: "1609",
    canton: "FR",
    location: "St-Martin FR",
  },
  {
    npa: "1697",
    canton: "FR",
    location: "La Joux FR",
  },
  {
    npa: "1699",
    canton: "FR",
    location: "Pont (Veveyse)",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg 1",
  },
  {
    npa: "1735",
    canton: "FR",
    location: "Giffers",
  },
  {
    npa: "1737",
    canton: "FR",
    location: "Plasselb",
  },
  {
    npa: "1716",
    canton: "FR",
    location: "Schwarzsee",
  },
  {
    npa: "1728",
    canton: "FR",
    location: "Rossens FR",
  },
  {
    npa: "1719",
    canton: "FR",
    location: "Brünisried",
  },
  {
    npa: "1731",
    canton: "FR",
    location: "Ependes FR",
  },
  {
    npa: "1733",
    canton: "FR",
    location: "Treyvaux",
  },
  {
    npa: "1713",
    canton: "FR",
    location: "St. Antoni",
  },
  {
    npa: "1722",
    canton: "FR",
    location: "Bourguillon",
  },
  {
    npa: "1726",
    canton: "FR",
    location: "Farvagny-le-Grand",
  },
  {
    npa: "1746",
    canton: "FR",
    location: "Prez-vers-Noréaz",
  },
  {
    npa: "1752",
    canton: "FR",
    location: "Villars-sur-Glâne 1",
  },
  {
    npa: "1773",
    canton: "FR",
    location: "Léchelles",
  },
  {
    npa: "1774",
    canton: "FR",
    location: "Cousset",
  },
  {
    npa: "1795",
    canton: "FR",
    location: "Courlevon",
  },
  {
    npa: "1794",
    canton: "FR",
    location: "Salvenach",
  },
  {
    npa: "1792",
    canton: "FR",
    location: "Cordast",
  },
  {
    npa: "1789",
    canton: "FR",
    location: "Lugnorre",
  },
  {
    npa: "1783",
    canton: "FR",
    location: "Pensier",
  },
  {
    npa: "1785",
    canton: "FR",
    location: "Cressier FR",
  },
  {
    npa: "1786",
    canton: "FR",
    location: "Sugiez",
  },
  {
    npa: "1787",
    canton: "FR",
    location: "Môtier (Vully)",
  },
  {
    npa: "1800",
    canton: "VD",
    location: "Vevey 1",
  },
  {
    npa: "1808",
    canton: "VD",
    location: "Les Monts-de-Corsier",
  },
  {
    npa: "1809",
    canton: "VD",
    location: "Fenil-sur-Corsier",
  },
  {
    npa: "1805",
    canton: "VD",
    location: "Jongny",
  },
  {
    npa: "1806",
    canton: "VD",
    location: "St-Légier-La Chiésaz",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Montreux 2",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Montreux 1 Distribution",
  },
  {
    npa: "1824",
    canton: "VD",
    location: "Caux",
  },
  {
    npa: "1832",
    canton: "VD",
    location: "Villard-sur-Chamby",
  },
  {
    npa: "1669",
    canton: "FR",
    location: "Les Sciernes-d'Albeuve",
  },
  {
    npa: "1660",
    canton: "VD",
    location: "Les Moulins",
  },
  {
    npa: "1659",
    canton: "VD",
    location: "Flendruz",
  },
  {
    npa: "1833",
    canton: "VD",
    location: "Les Avants",
  },
  {
    npa: "1658",
    canton: "VD",
    location: "Rossinière",
  },
  {
    npa: "1660",
    canton: "VD",
    location: "Château-d'Oex",
  },
  {
    npa: "1844",
    canton: "VD",
    location: "Villeneuve VD",
  },
  {
    npa: "1852",
    canton: "VD",
    location: "Roche VD",
  },
  {
    npa: "1853",
    canton: "VD",
    location: "Yvorne",
  },
  {
    npa: "1854",
    canton: "VD",
    location: "Leysin",
  },
  {
    npa: "1867",
    canton: "VD",
    location: "St-Triphon",
  },
  {
    npa: "1860",
    canton: "VD",
    location: "Aigle",
  },
  {
    npa: "1856",
    canton: "VD",
    location: "Corbeyrier",
  },
  {
    npa: "1862",
    canton: "VD",
    location: "Les Mosses",
  },
  {
    npa: "1865",
    canton: "VD",
    location: "Les Diablerets",
  },
  {
    npa: "1867",
    canton: "VD",
    location: "Ollon VD",
  },
  {
    npa: "1868",
    canton: "VS",
    location: "Collombey",
  },
  {
    npa: "1871",
    canton: "VS",
    location: "Choëx",
  },
  {
    npa: "1871",
    canton: "VS",
    location: "Les Giettes",
  },
  {
    npa: "1874",
    canton: "VS",
    location: "Champéry",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Les Plans-sur-Bex",
  },
  {
    npa: "1885",
    canton: "VD",
    location: "Chesières",
  },
  {
    npa: "1890",
    canton: "VS",
    location: "Mex VS",
  },
  {
    npa: "1902",
    canton: "VS",
    location: "Evionnaz",
  },
  {
    npa: "1904",
    canton: "VS",
    location: "Vernayaz",
  },
  {
    npa: "1906",
    canton: "VS",
    location: "Charrat",
  },
  {
    npa: "1914",
    canton: "VS",
    location: "Isérables",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "Chamoson",
  },
  {
    npa: "1920",
    canton: "VS",
    location: "Martigny 1",
  },
  {
    npa: "1921",
    canton: "VS",
    location: "Martigny-Croix",
  },
  {
    npa: "1923",
    canton: "VS",
    location: "Le Trétien",
  },
  {
    npa: "1927",
    canton: "VS",
    location: "Chemin",
  },
  {
    npa: "1929",
    canton: "VS",
    location: "Trient",
  },
  {
    npa: "1932",
    canton: "VS",
    location: "Bovernier",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Liddes",
  },
  {
    npa: "1946",
    canton: "VS",
    location: "Bourg-St-Pierre",
  },
  {
    npa: "1943",
    canton: "VS",
    location: "Praz-de-Fort",
  },
  {
    npa: "1936",
    canton: "VS",
    location: "Verbier",
  },
  {
    npa: "1938",
    canton: "VS",
    location: "Champex-Lac",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion",
  },
  {
    npa: "1976",
    canton: "VS",
    location: "Erde",
  },
  {
    npa: "1976",
    canton: "VS",
    location: "Daillon",
  },
  {
    npa: "1971",
    canton: "VS",
    location: "Grimisuat",
  },
  {
    npa: "1961",
    canton: "VS",
    location: "Vernamiège",
  },
  {
    npa: "1973",
    canton: "VS",
    location: "Nax",
  },
  {
    npa: "1984",
    canton: "VS",
    location: "Les Haudères",
  },
  {
    npa: "1987",
    canton: "VS",
    location: "Hérémence",
  },
  {
    npa: "1988",
    canton: "VS",
    location: "Les Collons",
  },
  {
    npa: "1991",
    canton: "VS",
    location: "Salins",
  },
  {
    npa: "1992",
    canton: "VS",
    location: "Les Mayens-de-Sion",
  },
  {
    npa: "1993",
    canton: "VS",
    location: "Veysonnaz",
  },
  {
    npa: "1997",
    canton: "VS",
    location: "Haute-Nendaz",
  },
  {
    npa: "1994",
    canton: "VS",
    location: "Aproz (Nendaz)",
  },
  {
    npa: "1962",
    canton: "VS",
    location: "Pont-de-la-Morge (Sion)",
  },
  {
    npa: "1964",
    canton: "VS",
    location: "Conthey",
  },
  {
    npa: "1965",
    canton: "VS",
    location: "Savièse",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Ayent",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Signèse (Ayent)",
  },
  {
    npa: "1967",
    canton: "VS",
    location: "Bramois",
  },
  {
    npa: "1988",
    canton: "VS",
    location: "Thyon",
  },
  {
    npa: "2000",
    canton: "NE",
    location: "Neuchâtel",
  },
  {
    npa: "2001",
    canton: "NE",
    location: "Neuchâtel 1 Dépôt",
  },
  {
    npa: "2007",
    canton: "NE",
    location: "Neuchâtel 7",
  },
  {
    npa: "2001",
    canton: "NE",
    location: "Neuchâtel 1",
  },
  {
    npa: "2000",
    canton: "NE",
    location: "Neuchâtel 2 Distribution",
  },
  {
    npa: "2006",
    canton: "NE",
    location: "Neuchâtel Vauseyon",
  },
  {
    npa: "2012",
    canton: "NE",
    location: "Auvernier",
  },
  {
    npa: "2014",
    canton: "NE",
    location: "Bôle",
  },
  {
    npa: "2022",
    canton: "NE",
    location: "Bevaix",
  },
  {
    npa: "2042",
    canton: "NE",
    location: "Valangin",
  },
  {
    npa: "2046",
    canton: "NE",
    location: "Fontaines NE",
  },
  {
    npa: "2053",
    canton: "NE",
    location: "Cernier",
  },
  {
    npa: "2054",
    canton: "NE",
    location: "Les Vieux-Prés",
  },
  {
    npa: "2063",
    canton: "NE",
    location: "Vilars NE",
  },
  {
    npa: "2065",
    canton: "NE",
    location: "Savagnier",
  },
  {
    npa: "2072",
    canton: "NE",
    location: "St-Blaise",
  },
  {
    npa: "2073",
    canton: "NE",
    location: "Enges",
  },
  {
    npa: "3238",
    canton: "BE",
    location: "Gals",
  },
  {
    npa: "2088",
    canton: "NE",
    location: "Cressier NE",
  },
  {
    npa: "2112",
    canton: "NE",
    location: "Môtiers NE",
  },
  {
    npa: "2115",
    canton: "NE",
    location: "Buttes",
  },
  {
    npa: "2123",
    canton: "NE",
    location: "St-Sulpice NE",
  },
  {
    npa: "2406",
    canton: "NE",
    location: "La Brévine",
  },
  {
    npa: "2126",
    canton: "NE",
    location: "Les Verrières",
  },
  {
    npa: "2406",
    canton: "NE",
    location: "Le Brouillet",
  },
  {
    npa: "2406",
    canton: "NE",
    location: "Les Taillères",
  },
  {
    npa: "2127",
    canton: "NE",
    location: "Les Bayards",
  },
  {
    npa: "1454",
    canton: "VD",
    location: "La Vraconnaz",
  },
  {
    npa: "2019",
    canton: "NE",
    location: "Rochefort",
  },
  {
    npa: "2037",
    canton: "NE",
    location: "Montmollin",
  },
  {
    npa: "2300",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "2300",
    canton: "NE",
    location: "La Chaux-de-Fonds 1",
  },
  {
    npa: "2302",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "2301",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "2304",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "2316",
    canton: "NE",
    location: "Petit-Martel",
  },
  {
    npa: "2338",
    canton: "JU",
    location: "Les Emibois",
  },
  {
    npa: "2314",
    canton: "NE",
    location: "La Sagne NE",
  },
  {
    npa: "2400",
    canton: "NE",
    location: "Le Locle",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne Jardin Paradis",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 7",
  },
  {
    npa: "2501",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2503",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2505",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2515",
    canton: "BE",
    location: "Prêles",
  },
  {
    npa: "2517",
    canton: "BE",
    location: "Diesse",
  },
  {
    npa: "2518",
    canton: "BE",
    location: "Nods",
  },
  {
    npa: "2533",
    canton: "BE",
    location: "Evilard",
  },
  {
    npa: "2540",
    canton: "SO",
    location: "Grenchen",
  },
  {
    npa: "2540",
    canton: "SO",
    location: "Grenchen 1",
  },
  {
    npa: "2540",
    canton: "SO",
    location: "Grenchen Schmelzi",
  },
  {
    npa: "2552",
    canton: "BE",
    location: "Orpund",
  },
  {
    npa: "2553",
    canton: "BE",
    location: "Safnern",
  },
  {
    npa: "2554",
    canton: "BE",
    location: "Meinisberg",
  },
  {
    npa: "2558",
    canton: "BE",
    location: "Aegerten",
  },
  {
    npa: "2563",
    canton: "BE",
    location: "Ipsach",
  },
  {
    npa: "2564",
    canton: "BE",
    location: "Bellmund",
  },
  {
    npa: "2565",
    canton: "BE",
    location: "Jens",
  },
  {
    npa: "2605",
    canton: "BE",
    location: "Sonceboz-Sombeval",
  },
  {
    npa: "2606",
    canton: "BE",
    location: "Corgémont",
  },
  {
    npa: "2608",
    canton: "BE",
    location: "Courtelary",
  },
  {
    npa: "2615",
    canton: "BE",
    location: "Montagne-de-Sonvilier",
  },
  {
    npa: "2717",
    canton: "BE",
    location: "Fornet-Dessous",
  },
  {
    npa: "2715",
    canton: "BE",
    location: "Châtelat-Monible",
  },
  {
    npa: "2716",
    canton: "BE",
    location: "Sornetan",
  },
  {
    npa: "2345",
    canton: "JU",
    location: "Les Breuleux",
  },
  {
    npa: "2345",
    canton: "JU",
    location: "Le Cerneux-Veusil",
  },
  {
    npa: "2732",
    canton: "BE",
    location: "Reconvilier",
  },
  {
    npa: "2736",
    canton: "BE",
    location: "Sorvilier",
  },
  {
    npa: "2740",
    canton: "BE",
    location: "Moutier",
  },
  {
    npa: "2748",
    canton: "BE",
    location: "Souboz",
  },
  {
    npa: "2744",
    canton: "BE",
    location: "Belprahon",
  },
  {
    npa: "2803",
    canton: "JU",
    location: "Bourrignon",
  },
  {
    npa: "2805",
    canton: "JU",
    location: "Soyhières",
  },
  {
    npa: "2807",
    canton: "JU",
    location: "Lucelle",
  },
  {
    npa: "2814",
    canton: "BL",
    location: "Roggenburg",
  },
  {
    npa: "2822",
    canton: "JU",
    location: "Courroux",
  },
  {
    npa: "2824",
    canton: "JU",
    location: "Vicques",
  },
  {
    npa: "2828",
    canton: "JU",
    location: "Montsevelier",
  },
  {
    npa: "2829",
    canton: "JU",
    location: "Vermes",
  },
  {
    npa: "2843",
    canton: "JU",
    location: "Châtillon JU",
  },
  {
    npa: "2855",
    canton: "JU",
    location: "Glovelier",
  },
  {
    npa: "2857",
    canton: "JU",
    location: "Montavon",
  },
  {
    npa: "2864",
    canton: "JU",
    location: "Soulce",
  },
  {
    npa: "2873",
    canton: "JU",
    location: "Saulcy",
  },
  {
    npa: "2882",
    canton: "JU",
    location: "St-Ursanne",
  },
  {
    npa: "2884",
    canton: "JU",
    location: "Montenol",
  },
  {
    npa: "2886",
    canton: "JU",
    location: "Epiquerez",
  },
  {
    npa: "2900",
    canton: "JU",
    location: "Porrentruy 1 Distribution",
  },
  {
    npa: "2905",
    canton: "JU",
    location: "Courtedoux",
  },
  {
    npa: "2906",
    canton: "JU",
    location: "Chevenez",
  },
  {
    npa: "2916",
    canton: "JU",
    location: "Fahy",
  },
  {
    npa: "2924",
    canton: "JU",
    location: "Montignez",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 6",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 13",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 14",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 15",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 16",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Sulgenbach",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Postauto BE-FB-SO",
  },
  {
    npa: "3001",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3005",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3032",
    canton: "BE",
    location: "Hinterkappelen",
  },
  {
    npa: "3036",
    canton: "BE",
    location: "Detligen",
  },
  {
    npa: "3042",
    canton: "BE",
    location: "Ortschwaben",
  },
  {
    npa: "3043",
    canton: "BE",
    location: "Uettligen",
  },
  {
    npa: "3048",
    canton: "BE",
    location: "Worblaufen",
  },
  {
    npa: "3054",
    canton: "BE",
    location: "Schüpfen",
  },
  {
    npa: "3067",
    canton: "BE",
    location: "Boll",
  },
  {
    npa: "3072",
    canton: "BE",
    location: "Ostermundigen 1",
  },
  {
    npa: "3075",
    canton: "BE",
    location: "Rüfenacht BE",
  },
  {
    npa: "3078",
    canton: "BE",
    location: "Richigen",
  },
  {
    npa: "3082",
    canton: "BE",
    location: "Schlosswil",
  },
  {
    npa: "3089",
    canton: "BE",
    location: "Hinterfultigen",
  },
  {
    npa: "3112",
    canton: "BE",
    location: "Allmendingen b. Bern",
  },
  {
    npa: "3114",
    canton: "BE",
    location: "Wichtrach",
  },
  {
    npa: "3115",
    canton: "BE",
    location: "Gerzensee",
  },
  {
    npa: "3629",
    canton: "BE",
    location: "Kiesen",
  },
  {
    npa: "3628",
    canton: "BE",
    location: "Uttigen",
  },
  {
    npa: "3124",
    canton: "BE",
    location: "Belpberg",
  },
  {
    npa: "3665",
    canton: "BE",
    location: "Wattenwil",
  },
  {
    npa: "3145",
    canton: "BE",
    location: "Niederscherli",
  },
  {
    npa: "3150",
    canton: "BE",
    location: "Schwarzenburg",
  },
  {
    npa: "3153",
    canton: "BE",
    location: "Rüschegg Gambach",
  },
  {
    npa: "3154",
    canton: "BE",
    location: "Rüschegg Heubach",
  },
  {
    npa: "3172",
    canton: "BE",
    location: "Niederwangen b. Bern",
  },
  {
    npa: "3173",
    canton: "BE",
    location: "Oberwangen b. Bern",
  },
  {
    npa: "3174",
    canton: "BE",
    location: "Thörishaus",
  },
  {
    npa: "3178",
    canton: "FR",
    location: "Bösingen",
  },
  {
    npa: "3126",
    canton: "BE",
    location: "Gelterfingen",
  },
  {
    npa: "3204",
    canton: "BE",
    location: "Rosshäusern",
  },
  {
    npa: "3205",
    canton: "BE",
    location: "Gümmenen",
  },
  {
    npa: "3210",
    canton: "FR",
    location: "Kerzers",
  },
  {
    npa: "3225",
    canton: "BE",
    location: "Müntschemier",
  },
  {
    npa: "3256",
    canton: "BE",
    location: "Dieterswil",
  },
  {
    npa: "3257",
    canton: "BE",
    location: "Ammerzwil BE",
  },
  {
    npa: "3266",
    canton: "BE",
    location: "Wiler b. Seedorf",
  },
  {
    npa: "3268",
    canton: "BE",
    location: "Lobsigen",
  },
  {
    npa: "3270",
    canton: "BE",
    location: "Aarberg",
  },
  {
    npa: "3272",
    canton: "BE",
    location: "Walperswil",
  },
  {
    npa: "3285",
    canton: "FR",
    location: "Galmiz",
  },
  {
    npa: "3286",
    canton: "FR",
    location: "Muntelier",
  },
  {
    npa: "3293",
    canton: "BE",
    location: "Dotzigen",
  },
  {
    npa: "3313",
    canton: "BE",
    location: "Büren zum Hof",
  },
  {
    npa: "3314",
    canton: "BE",
    location: "Schalunen",
  },
  {
    npa: "3315",
    canton: "BE",
    location: "Bätterkinden",
  },
  {
    npa: "3315",
    canton: "BE",
    location: "Kräiligen",
  },
  {
    npa: "3322",
    canton: "BE",
    location: "Urtenen-Schönbühl",
  },
  {
    npa: "3322",
    canton: "BE",
    location: "Mattstetten",
  },
  {
    npa: "3324",
    canton: "BE",
    location: "Hindelbank",
  },
  {
    npa: "3309",
    canton: "BE",
    location: "Kernenried",
  },
  {
    npa: "3372",
    canton: "BE",
    location: "Wanzwil",
  },
  {
    npa: "3373",
    canton: "BE",
    location: "Heimenhausen",
  },
  {
    npa: "3373",
    canton: "BE",
    location: "Röthenbach Herzogenbuchsee",
  },
  {
    npa: "3365",
    canton: "BE",
    location: "Seeberg",
  },
  {
    npa: "3365",
    canton: "BE",
    location: "Grasswil",
  },
  {
    npa: "3366",
    canton: "BE",
    location: "Bettenhausen",
  },
  {
    npa: "3367",
    canton: "BE",
    location: "Thörigen",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf 1",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf Zustellung",
  },
  {
    npa: "3402",
    canton: "BE",
    location: "Burgdorf",
  },
  {
    npa: "3413",
    canton: "BE",
    location: "Kaltacker",
  },
  {
    npa: "3432",
    canton: "BE",
    location: "Lützelflüh-Goldbach",
  },
  {
    npa: "3434",
    canton: "BE",
    location: "Obergoldbach",
  },
  {
    npa: "3435",
    canton: "BE",
    location: "Ramsei",
  },
  {
    npa: "3436",
    canton: "BE",
    location: "Zollbrück",
  },
  {
    npa: "3437",
    canton: "BE",
    location: "Rüderswil",
  },
  {
    npa: "3457",
    canton: "BE",
    location: "Wasen im Emmental",
  },
  {
    npa: "3462",
    canton: "BE",
    location: "Weier im Emmental",
  },
  {
    npa: "3463",
    canton: "BE",
    location: "Häusernmoos im Emmental",
  },
  {
    npa: "3464",
    canton: "BE",
    location: "Schmidigen-Mühleweg",
  },
  {
    npa: "3465",
    canton: "BE",
    location: "Dürrenroth",
  },
  {
    npa: "3472",
    canton: "BE",
    location: "Wynigen",
  },
  {
    npa: "3475",
    canton: "BE",
    location: "Riedtwil",
  },
  {
    npa: "3507",
    canton: "BE",
    location: "Biglen",
  },
  {
    npa: "3510",
    canton: "BE",
    location: "Konolfingen",
  },
  {
    npa: "3672",
    canton: "BE",
    location: "Oberdiessbach",
  },
  {
    npa: "3673",
    canton: "BE",
    location: "Linden",
  },
  {
    npa: "3674",
    canton: "BE",
    location: "Bleiken b. Oberdiessbach",
  },
  {
    npa: "3532",
    canton: "BE",
    location: "Zäziwil",
  },
  {
    npa: "3535",
    canton: "BE",
    location: "Schüpbach",
  },
  {
    npa: "3550",
    canton: "BE",
    location: "Langnau im Emmental",
  },
  {
    npa: "3552",
    canton: "BE",
    location: "Bärau",
  },
  {
    npa: "3555",
    canton: "BE",
    location: "Trubschachen",
  },
  {
    npa: "3600",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3603",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3617",
    canton: "BE",
    location: "Fahrni b. Thun",
  },
  {
    npa: "3636",
    canton: "BE",
    location: "Längenbühl",
  },
  {
    npa: "3614",
    canton: "BE",
    location: "Unterlangenegg",
  },
  {
    npa: "3615",
    canton: "BE",
    location: "Heimenschwand",
  },
  {
    npa: "3638",
    canton: "BE",
    location: "Blumenstein",
  },
  {
    npa: "3647",
    canton: "BE",
    location: "Reutigen",
  },
  {
    npa: "3656",
    canton: "BE",
    location: "Aeschlen ob Gunten",
  },
  {
    npa: "3702",
    canton: "BE",
    location: "Hondrich",
  },
  {
    npa: "3703",
    canton: "BE",
    location: "Aeschi b. Spiez",
  },
  {
    npa: "3706",
    canton: "BE",
    location: "Leissigen",
  },
  {
    npa: "3707",
    canton: "BE",
    location: "Därligen",
  },
  {
    npa: "3711",
    canton: "BE",
    location: "Mülenen",
  },
  {
    npa: "3713",
    canton: "BE",
    location: "Reichenbach im Kandertal",
  },
  {
    npa: "3715",
    canton: "BE",
    location: "Adelboden",
  },
  {
    npa: "3716",
    canton: "BE",
    location: "Kandergrund",
  },
  {
    npa: "3718",
    canton: "BE",
    location: "Kandersteg",
  },
  {
    npa: "3722",
    canton: "BE",
    location: "Scharnachtal",
  },
  {
    npa: "3724",
    canton: "BE",
    location: "Ried (Frutigen)",
  },
  {
    npa: "3754",
    canton: "BE",
    location: "Diemtigen",
  },
  {
    npa: "3762",
    canton: "BE",
    location: "Erlenbach im Simmental",
  },
  {
    npa: "3764",
    canton: "BE",
    location: "Weissenburg",
  },
  {
    npa: "3775",
    canton: "BE",
    location: "Lenk im Simmental",
  },
  {
    npa: "3776",
    canton: "BE",
    location: "Oeschseite",
  },
  {
    npa: "3778",
    canton: "BE",
    location: "Schönried",
  },
  {
    npa: "3780",
    canton: "BE",
    location: "Gstaad",
  },
  {
    npa: "3803",
    canton: "BE",
    location: "Beatenberg",
  },
  {
    npa: "3805",
    canton: "BE",
    location: "Goldswil b. Interlaken",
  },
  {
    npa: "3806",
    canton: "BE",
    location: "Bönigen b. Interlaken",
  },
  {
    npa: "3812",
    canton: "BE",
    location: "Wilderswil",
  },
  {
    npa: "3814",
    canton: "BE",
    location: "Gsteigwiler",
  },
  {
    npa: "3815",
    canton: "BE",
    location: "Zweilütschinen",
  },
  {
    npa: "3816",
    canton: "BE",
    location: "Lütschental",
  },
  {
    npa: "3822",
    canton: "BE",
    location: "Isenfluh",
  },
  {
    npa: "3826",
    canton: "BE",
    location: "Gimmelwald",
  },
  {
    npa: "3853",
    canton: "BE",
    location: "Niederried b. Interlaken",
  },
  {
    npa: "3857",
    canton: "BE",
    location: "Unterbach BE",
  },
  {
    npa: "3860",
    canton: "BE",
    location: "Meiringen",
  },
  {
    npa: "3900",
    canton: "VS",
    location: "Gamsen",
  },
  {
    npa: "3901",
    canton: "VS",
    location: "Rothwald",
  },
  {
    npa: "3907",
    canton: "VS",
    location: "Gabi (Simplon)",
  },
  {
    npa: "3907",
    canton: "VS",
    location: "Gondo",
  },
  {
    npa: "3918",
    canton: "VS",
    location: "Wiler (Lötschen)",
  },
  {
    npa: "3904",
    canton: "VS",
    location: "Naters",
  },
  {
    npa: "3905",
    canton: "VS",
    location: "Saas-Almagell",
  },
  {
    npa: "3920",
    canton: "VS",
    location: "Zermatt",
  },
  {
    npa: "3923",
    canton: "VS",
    location: "Törbel",
  },
  {
    npa: "3928",
    canton: "VS",
    location: "Randa",
  },
  {
    npa: "3925",
    canton: "VS",
    location: "Grächen",
  },
  {
    npa: "3942",
    canton: "VS",
    location: "St. German",
  },
  {
    npa: "3933",
    canton: "VS",
    location: "Staldenried",
  },
  {
    npa: "3938",
    canton: "VS",
    location: "Ausserberg",
  },
  {
    npa: "3934",
    canton: "VS",
    location: "Zeneggen",
  },
  {
    npa: "3935",
    canton: "VS",
    location: "Bürchen",
  },
  {
    npa: "3947",
    canton: "VS",
    location: "Ergisch",
  },
  {
    npa: "3948",
    canton: "VS",
    location: "Oberems",
  },
  {
    npa: "3956",
    canton: "VS",
    location: "Guttet-Feschel",
  },
  {
    npa: "3953",
    canton: "VS",
    location: "Varen",
  },
  {
    npa: "1978",
    canton: "VS",
    location: "Lens",
  },
  {
    npa: "3979",
    canton: "VS",
    location: "Grône",
  },
  {
    npa: "3946",
    canton: "VS",
    location: "Turtmann",
  },
  {
    npa: "3970",
    canton: "VS",
    location: "Salgesch",
  },
  {
    npa: "3977",
    canton: "VS",
    location: "Granges VS",
  },
  {
    npa: "1958",
    canton: "VS",
    location: "St-Léonard",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Vissoie",
  },
  {
    npa: "3967",
    canton: "VS",
    location: "Vercorin",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Muraz (Sierre)",
  },
  {
    npa: "3982",
    canton: "VS",
    location: "Bitsch",
  },
  {
    npa: "3997",
    canton: "VS",
    location: "Bellwald",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Blitzingen",
  },
  {
    npa: "3992",
    canton: "VS",
    location: "Bettmeralp",
  },
  {
    npa: "3996",
    canton: "VS",
    location: "Binn",
  },
  {
    npa: "3984",
    canton: "VS",
    location: "Fiesch",
  },
  {
    npa: "4000",
    canton: "BS",
    location: "Basel 2 Zustellung",
  },
  {
    npa: "4000",
    canton: "BS",
    location: "Basel Dist Ba",
  },
  {
    npa: "4010",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4015",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4018",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4024",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4025",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4030",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4031",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4052",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4053",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4057",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4059",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4102",
    canton: "BL",
    location: "Binningen",
  },
  {
    npa: "4102",
    canton: "BL",
    location: "Binningen 1",
  },
  {
    npa: "4103",
    canton: "BL",
    location: "Bottmingen",
  },
  {
    npa: "4112",
    canton: "SO",
    location: "Flüh",
  },
  {
    npa: "4114",
    canton: "SO",
    location: "Hofstetten SO",
  },
  {
    npa: "4117",
    canton: "BL",
    location: "Burg im Leimental",
  },
  {
    npa: "4118",
    canton: "SO",
    location: "Rodersdorf",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil Dorf",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil Lindenplatz",
  },
  {
    npa: "4125",
    canton: "BS",
    location: "Riehen Rauracher",
  },
  {
    npa: "4126",
    canton: "BS",
    location: "Bettingen",
  },
  {
    npa: "4132",
    canton: "BL",
    location: "Muttenz",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln",
  },
  {
    npa: "4140",
    canton: "BL",
    location: "Pratteln CIRCLE",
  },
  {
    npa: "4142",
    canton: "BL",
    location: "Münchenstein 1",
  },
  {
    npa: "4142",
    canton: "BL",
    location: "Münchenstein Zollweiden",
  },
  {
    npa: "4143",
    canton: "SO",
    location: "Dornach",
  },
  {
    npa: "4147",
    canton: "BL",
    location: "Aesch BL",
  },
  {
    npa: "4153",
    canton: "BL",
    location: "Reinach BL",
  },
  {
    npa: "4207",
    canton: "BL",
    location: "Bretzwil",
  },
  {
    npa: "4222",
    canton: "BL",
    location: "Zwingen",
  },
  {
    npa: "4245",
    canton: "SO",
    location: "Kleinlützel",
  },
  {
    npa: "4204",
    canton: "SO",
    location: "Himmelried",
  },
  {
    npa: "4232",
    canton: "SO",
    location: "Fehren",
  },
  {
    npa: "4233",
    canton: "SO",
    location: "Meltingen",
  },
  {
    npa: "4246",
    canton: "BL",
    location: "Wahlen b. Laufen",
  },
  {
    npa: "4302",
    canton: "BL",
    location: "Augst BL",
  },
  {
    npa: "4303",
    canton: "AG",
    location: "Kaiseraugst",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden",
  },
  {
    npa: "4315",
    canton: "AG",
    location: "Zuzgen",
  },
  {
    npa: "4316",
    canton: "AG",
    location: "Hellikon",
  },
  {
    npa: "4324",
    canton: "AG",
    location: "Obermumpf",
  },
  {
    npa: "4333",
    canton: "AG",
    location: "Münchwilen AG",
  },
  {
    npa: "5080",
    canton: "AG",
    location: "Laufenburg",
  },
  {
    npa: "5082",
    canton: "AG",
    location: "Kaisten",
  },
  {
    npa: "5085",
    canton: "AG",
    location: "Sulz AG",
  },
  {
    npa: "5274",
    canton: "AG",
    location: "Mettau",
  },
  {
    npa: "5273",
    canton: "AG",
    location: "Oberhofen AG",
  },
  {
    npa: "5276",
    canton: "AG",
    location: "Wil AG",
  },
  {
    npa: "4402",
    canton: "BL",
    location: "Frenkendorf",
  },
  {
    npa: "4416",
    canton: "BL",
    location: "Bubendorf",
  },
  {
    npa: "4422",
    canton: "BL",
    location: "Arisdorf",
  },
  {
    npa: "4423",
    canton: "BL",
    location: "Hersberg",
  },
  {
    npa: "4435",
    canton: "BL",
    location: "Niederdorf",
  },
  {
    npa: "4442",
    canton: "BL",
    location: "Diepflingen",
  },
  {
    npa: "4455",
    canton: "BL",
    location: "Zunzgen",
  },
  {
    npa: "4461",
    canton: "BL",
    location: "Böckten",
  },
  {
    npa: "4469",
    canton: "BL",
    location: "Anwil",
  },
  {
    npa: "4466",
    canton: "BL",
    location: "Ormalingen",
  },
  {
    npa: "4467",
    canton: "BL",
    location: "Rothenfluh",
  },
  {
    npa: "4492",
    canton: "BL",
    location: "Tecknau",
  },
  {
    npa: "4494",
    canton: "BL",
    location: "Oltingen",
  },
  {
    npa: "4495",
    canton: "BL",
    location: "Zeglingen",
  },
  {
    npa: "4497",
    canton: "BL",
    location: "Rünenberg",
  },
  {
    npa: "4500",
    canton: "SO",
    location: "Solothurn",
  },
  {
    npa: "4500",
    canton: "SO",
    location: "Solothurn 1",
  },
  {
    npa: "4503",
    canton: "SO",
    location: "Solothurn",
  },
  {
    npa: "4515",
    canton: "SO",
    location: "Weissenstein b. Solothurn",
  },
  {
    npa: "4525",
    canton: "SO",
    location: "Balm b. Günsberg",
  },
  {
    npa: "4523",
    canton: "SO",
    location: "Niederwil SO",
  },
  {
    npa: "4539",
    canton: "BE",
    location: "Rumisberg",
  },
  {
    npa: "4539",
    canton: "BE",
    location: "Farnern",
  },
  {
    npa: "4558",
    canton: "SO",
    location: "Hersiwil",
  },
  {
    npa: "4532",
    canton: "SO",
    location: "Feldbrunnen",
  },
  {
    npa: "4552",
    canton: "SO",
    location: "Derendingen",
  },
  {
    npa: "4554",
    canton: "SO",
    location: "Etziken",
  },
  {
    npa: "3375",
    canton: "BE",
    location: "Inkwil",
  },
  {
    npa: "4564",
    canton: "SO",
    location: "Obergerlafingen",
  },
  {
    npa: "4566",
    canton: "SO",
    location: "Kriegstetten",
  },
  {
    npa: "4582",
    canton: "SO",
    location: "Brügglen",
  },
  {
    npa: "4576",
    canton: "SO",
    location: "Tscheppach",
  },
  {
    npa: "4587",
    canton: "SO",
    location: "Aetingen",
  },
  {
    npa: "4574",
    canton: "SO",
    location: "Nennigkofen",
  },
  {
    npa: "4600",
    canton: "SO",
    location: "Olten",
  },
  {
    npa: "4600",
    canton: "SO",
    location: "Olten 2 Zustellung",
  },
  {
    npa: "4601",
    canton: "SO",
    location: "Olten 1 Fächer",
  },
  {
    npa: "4616",
    canton: "SO",
    location: "Kappel SO",
  },
  {
    npa: "4618",
    canton: "SO",
    location: "Boningen",
  },
  {
    npa: "4632",
    canton: "SO",
    location: "Trimbach",
  },
  {
    npa: "4657",
    canton: "SO",
    location: "Dulliken",
  },
  {
    npa: "4663",
    canton: "AG",
    location: "Aarburg",
  },
  {
    npa: "4665",
    canton: "AG",
    location: "Oftringen 2",
  },
  {
    npa: "4633",
    canton: "SO",
    location: "Hauenstein",
  },
  {
    npa: "4634",
    canton: "SO",
    location: "Wisen SO",
  },
  {
    npa: "4703",
    canton: "SO",
    location: "Kestenholz",
  },
  {
    npa: "3380",
    canton: "BE",
    location: "Wangen an der Aare",
  },
  {
    npa: "4542",
    canton: "SO",
    location: "Luterbach",
  },
  {
    npa: "4719",
    canton: "SO",
    location: "Ramiswil",
  },
  {
    npa: "4712",
    canton: "SO",
    location: "Laupersdorf",
  },
  {
    npa: "4716",
    canton: "SO",
    location: "Gänsbrunnen",
  },
  {
    npa: "4852",
    canton: "AG",
    location: "Rothrist",
  },
  {
    npa: "4853",
    canton: "AG",
    location: "Murgenthal",
  },
  {
    npa: "4911",
    canton: "BE",
    location: "Schwarzhäusern",
  },
  {
    npa: "4912",
    canton: "BE",
    location: "Aarwangen",
  },
  {
    npa: "4913",
    canton: "BE",
    location: "Bannwil",
  },
  {
    npa: "4915",
    canton: "LU",
    location: "St. Urban",
  },
  {
    npa: "4916",
    canton: "BE",
    location: "Untersteckholz",
  },
  {
    npa: "4917",
    canton: "BE",
    location: "Busswil b. Melchnau",
  },
  {
    npa: "4919",
    canton: "BE",
    location: "Reisiswil",
  },
  {
    npa: "4922",
    canton: "BE",
    location: "Bützberg",
  },
  {
    npa: "4923",
    canton: "BE",
    location: "Wynau",
  },
  {
    npa: "4924",
    canton: "BE",
    location: "Obersteckholz",
  },
  {
    npa: "4933",
    canton: "BE",
    location: "Rütschelen",
  },
  {
    npa: "4937",
    canton: "BE",
    location: "Ursenbach",
  },
  {
    npa: "4938",
    canton: "BE",
    location: "Rohrbach",
  },
  {
    npa: "4942",
    canton: "BE",
    location: "Walterswil BE",
  },
  {
    npa: "4952",
    canton: "BE",
    location: "Eriswil",
  },
  {
    npa: "4954",
    canton: "BE",
    location: "Wyssachen",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau 1 Zustellung",
  },
  {
    npa: "5004",
    canton: "AG",
    location: "Aarau",
  },
  {
    npa: "5015",
    canton: "SO",
    location: "Erlinsbach SO",
  },
  {
    npa: "5017",
    canton: "AG",
    location: "Barmelweid",
  },
  {
    npa: "5022",
    canton: "AG",
    location: "Rombach",
  },
  {
    npa: "5024",
    canton: "AG",
    location: "Küttigen",
  },
  {
    npa: "5025",
    canton: "AG",
    location: "Asp",
  },
  {
    npa: "5027",
    canton: "AG",
    location: "Herznach",
  },
  {
    npa: "5035",
    canton: "AG",
    location: "Unterentfelden",
  },
  {
    npa: "5046",
    canton: "AG",
    location: "Walde AG",
  },
  {
    npa: "5113",
    canton: "AG",
    location: "Holderbank AG",
  },
  {
    npa: "5103",
    canton: "AG",
    location: "Möriken AG",
  },
  {
    npa: "5246",
    canton: "AG",
    location: "Scherz",
  },
  {
    npa: "5200",
    canton: "AG",
    location: "Brugg AG",
  },
  {
    npa: "5201",
    canton: "AG",
    location: "Brugg AG",
  },
  {
    npa: "5222",
    canton: "AG",
    location: "Umiken",
  },
  {
    npa: "5233",
    canton: "AG",
    location: "Stilli",
  },
  {
    npa: "5076",
    canton: "AG",
    location: "Bözen",
  },
  {
    npa: "5073",
    canton: "AG",
    location: "Gipf-Oberfrick",
  },
  {
    npa: "5064",
    canton: "AG",
    location: "Wittnau",
  },
  {
    npa: "5063",
    canton: "AG",
    location: "Wölflinswil",
  },
  {
    npa: "5074",
    canton: "AG",
    location: "Eiken",
  },
  {
    npa: "5300",
    canton: "AG",
    location: "Turgi",
  },
  {
    npa: "5304",
    canton: "AG",
    location: "Endingen",
  },
  {
    npa: "5306",
    canton: "AG",
    location: "Tegerfelden",
  },
  {
    npa: "5313",
    canton: "AG",
    location: "Klingnau",
  },
  {
    npa: "5317",
    canton: "AG",
    location: "Hettenschwil",
  },
  {
    npa: "5318",
    canton: "AG",
    location: "Mandach",
  },
  {
    npa: "5408",
    canton: "AG",
    location: "Ennetbaden",
  },
  {
    npa: "5413",
    canton: "AG",
    location: "Birmenstorf AG",
  },
  {
    npa: "5416",
    canton: "AG",
    location: "Kirchdorf AG",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen",
  },
  {
    npa: "5432",
    canton: "AG",
    location: "Neuenhof",
  },
  {
    npa: "5504",
    canton: "AG",
    location: "Othmarsingen",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil",
  },
  {
    npa: "5522",
    canton: "AG",
    location: "Tägerig",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Lenzburg Stadt",
  },
  {
    npa: "5604",
    canton: "AG",
    location: "Hendschiken",
  },
  {
    npa: "5610",
    canton: "AG",
    location: "Wohlen AG 1",
  },
  {
    npa: "5613",
    canton: "AG",
    location: "Hilfikon",
  },
  {
    npa: "5618",
    canton: "AG",
    location: "Bettwil",
  },
  {
    npa: "5623",
    canton: "AG",
    location: "Boswil",
  },
  {
    npa: "5625",
    canton: "AG",
    location: "Kallern",
  },
  {
    npa: "5627",
    canton: "AG",
    location: "Besenbüren",
  },
  {
    npa: "5632",
    canton: "AG",
    location: "Buttwil",
  },
  {
    npa: "5634",
    canton: "AG",
    location: "Merenschwand",
  },
  {
    npa: "5636",
    canton: "AG",
    location: "Benzenschwil",
  },
  {
    npa: "5645",
    canton: "AG",
    location: "Aettenschwil",
  },
  {
    npa: "5733",
    canton: "AG",
    location: "Leimbach AG",
  },
  {
    npa: "5734",
    canton: "AG",
    location: "Reinach AG",
  },
  {
    npa: "5735",
    canton: "LU",
    location: "Pfeffikon LU",
  },
  {
    npa: "5736",
    canton: "AG",
    location: "Burg AG",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 4",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 6",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 7",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 15",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Zustellung",
  },
  {
    npa: "6002",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6003",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6006",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens",
  },
  {
    npa: "6015",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6019",
    canton: "LU",
    location: "Sigigen",
  },
  {
    npa: "6020",
    canton: "LU",
    location: "Emmenbrücke",
  },
  {
    npa: "6020",
    canton: "LU",
    location: "Emmenbrücke 3",
  },
  {
    npa: "6024",
    canton: "LU",
    location: "Hildisrieden",
  },
  {
    npa: "6026",
    canton: "LU",
    location: "Rain",
  },
  {
    npa: "6032",
    canton: "LU",
    location: "Emmen",
  },
  {
    npa: "6033",
    canton: "LU",
    location: "Buchrain",
  },
  {
    npa: "6038",
    canton: "LU",
    location: "Gisikon",
  },
  {
    npa: "6042",
    canton: "AG",
    location: "Dietwil",
  },
  {
    npa: "6044",
    canton: "LU",
    location: "Udligenswil",
  },
  {
    npa: "6055",
    canton: "OW",
    location: "Alpnach Dorf",
  },
  {
    npa: "6068",
    canton: "OW",
    location: "Melchsee-Frutt",
  },
  {
    npa: "6066",
    canton: "OW",
    location: "St. Niklausen OW",
  },
  {
    npa: "6078",
    canton: "OW",
    location: "Bürglen OW",
  },
  {
    npa: "6078",
    canton: "OW",
    location: "Lungern",
  },
  {
    npa: "3860",
    canton: "BE",
    location: "Brünig",
  },
  {
    npa: "6083",
    canton: "BE",
    location: "Hasliberg Hohfluh",
  },
  {
    npa: "6084",
    canton: "BE",
    location: "Hasliberg Wasserwendi",
  },
  {
    npa: "6086",
    canton: "BE",
    location: "Hasliberg Reuti",
  },
  {
    npa: "6010",
    canton: "OW",
    location: "Pilatus Kulm",
  },
  {
    npa: "6114",
    canton: "LU",
    location: "Steinhuserberg",
  },
  {
    npa: "6113",
    canton: "LU",
    location: "Romoos",
  },
  {
    npa: "6125",
    canton: "LU",
    location: "Menzberg",
  },
  {
    npa: "6130",
    canton: "LU",
    location: "Willisau",
  },
  {
    npa: "6142",
    canton: "LU",
    location: "Gettnau",
  },
  {
    npa: "6143",
    canton: "LU",
    location: "Ohmstal",
  },
  {
    npa: "6145",
    canton: "LU",
    location: "Fischbach LU",
  },
  {
    npa: "6146",
    canton: "LU",
    location: "Grossdietwil",
  },
  {
    npa: "6147",
    canton: "LU",
    location: "Altbüron",
  },
  {
    npa: "6153",
    canton: "LU",
    location: "Ufhusen",
  },
  {
    npa: "6156",
    canton: "LU",
    location: "Luthern",
  },
  {
    npa: "6162",
    canton: "LU",
    location: "Entlebuch",
  },
  {
    npa: "6163",
    canton: "LU",
    location: "Ebnet",
  },
  {
    npa: "6162",
    canton: "LU",
    location: "Rengg",
  },
  {
    npa: "6173",
    canton: "LU",
    location: "Flühli LU",
  },
  {
    npa: "6205",
    canton: "LU",
    location: "Eich",
  },
  {
    npa: "6206",
    canton: "LU",
    location: "Neuenkirch",
  },
  {
    npa: "6212",
    canton: "LU",
    location: "St. Erhard",
  },
  {
    npa: "6215",
    canton: "LU",
    location: "Beromünster",
  },
  {
    npa: "6216",
    canton: "LU",
    location: "Mauensee",
  },
  {
    npa: "6218",
    canton: "LU",
    location: "Ettiswil",
  },
  {
    npa: "6233",
    canton: "LU",
    location: "Büron",
  },
  {
    npa: "6235",
    canton: "LU",
    location: "Winikon",
  },
  {
    npa: "6242",
    canton: "LU",
    location: "Wauwil",
  },
  {
    npa: "6253",
    canton: "LU",
    location: "Uffikon",
  },
  {
    npa: "6274",
    canton: "LU",
    location: "Eschenbach LU",
  },
  {
    npa: "6276",
    canton: "LU",
    location: "Hohenrain",
  },
  {
    npa: "6280",
    canton: "LU",
    location: "Hochdorf",
  },
  {
    npa: "6285",
    canton: "LU",
    location: "Hitzkirch",
  },
  {
    npa: "6285",
    canton: "LU",
    location: "Retschwil",
  },
  {
    npa: "6289",
    canton: "LU",
    location: "Müswangen",
  },
  {
    npa: "6301",
    canton: "ZG",
    location: "Zug",
  },
  {
    npa: "6315",
    canton: "ZG",
    location: "Morgarten",
  },
  {
    npa: "6314",
    canton: "ZG",
    location: "Unterägeri",
  },
  {
    npa: "6300",
    canton: "ZG",
    location: "Zugerberg",
  },
  {
    npa: "6331",
    canton: "ZG",
    location: "Hünenberg",
  },
  {
    npa: "6344",
    canton: "LU",
    location: "Meierskappel",
  },
  {
    npa: "6345",
    canton: "ZG",
    location: "Neuheim",
  },
  {
    npa: "6356",
    canton: "LU",
    location: "Rigi Kaltbad",
  },
  {
    npa: "6365",
    canton: "NW",
    location: "Kehrsiten",
  },
  {
    npa: "6372",
    canton: "NW",
    location: "Ennetmoos",
  },
  {
    npa: "6373",
    canton: "NW",
    location: "Ennetbürgen",
  },
  {
    npa: "6376",
    canton: "NW",
    location: "Emmetten",
  },
  {
    npa: "6388",
    canton: "OW",
    location: "Grafenort",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Rigi Klösterli",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Rigi Staffel",
  },
  {
    npa: "6432",
    canton: "SZ",
    location: "Rickenbach b. Schwyz",
  },
  {
    npa: "6440",
    canton: "SZ",
    location: "Brunnen",
  },
  {
    npa: "6452",
    canton: "UR",
    location: "Sisikon",
  },
  {
    npa: "6452",
    canton: "SZ",
    location: "Riemenstalden",
  },
  {
    npa: "6461",
    canton: "UR",
    location: "Isenthal",
  },
  {
    npa: "6464",
    canton: "UR",
    location: "Spiringen",
  },
  {
    npa: "6465",
    canton: "UR",
    location: "Unterschächen",
  },
  {
    npa: "6466",
    canton: "UR",
    location: "Bauen",
  },
  {
    npa: "6469",
    canton: "UR",
    location: "Haldi b. Schattdorf",
  },
  {
    npa: "6468",
    canton: "UR",
    location: "Attinghausen",
  },
  {
    npa: "6472",
    canton: "UR",
    location: "Erstfeld",
  },
  {
    npa: "6474",
    canton: "UR",
    location: "Amsteg",
  },
  {
    npa: "6475",
    canton: "UR",
    location: "Bristen",
  },
  {
    npa: "6484",
    canton: "UR",
    location: "Wassen UR",
  },
  {
    npa: "6485",
    canton: "UR",
    location: "Meien",
  },
  {
    npa: "6487",
    canton: "UR",
    location: "Göschenen",
  },
  {
    npa: "6493",
    canton: "UR",
    location: "Hospental",
  },
  {
    npa: "6513",
    canton: "TI",
    location: "Monte Carasso",
  },
  {
    npa: "6517",
    canton: "TI",
    location: "Arbedo",
  },
  {
    npa: "6535",
    canton: "GR",
    location: "Roveredo GR",
  },
  {
    npa: "6584",
    canton: "TI",
    location: "Carena",
  },
  {
    npa: "6538",
    canton: "GR",
    location: "Verdabbio",
  },
  {
    npa: "6542",
    canton: "GR",
    location: "Buseno",
  },
  {
    npa: "6545",
    canton: "GR",
    location: "Selma",
  },
  {
    npa: "6548",
    canton: "GR",
    location: "Rossa",
  },
  {
    npa: "6556",
    canton: "GR",
    location: "Leggia",
  },
  {
    npa: "6565",
    canton: "GR",
    location: "S. Bernardino",
  },
  {
    npa: "6557",
    canton: "GR",
    location: "Cama",
  },
  {
    npa: "6563",
    canton: "GR",
    location: "Mesocco",
  },
  {
    npa: "6574",
    canton: "TI",
    location: "Vira (Gambarogno)",
  },
  {
    npa: "6575",
    canton: "TI",
    location: "S. Nazzaro",
  },
  {
    npa: "6576",
    canton: "TI",
    location: "Gerra (Gambarogno)",
  },
  {
    npa: "6595",
    canton: "TI",
    location: "Riazzino",
  },
  {
    npa: "6597",
    canton: "TI",
    location: "Agarone",
  },
  {
    npa: "6598",
    canton: "TI",
    location: "Tenero",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Locarno",
  },
  {
    npa: "6601",
    canton: "TI",
    location: "Locarno",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Locarno Distribuzione",
  },
  {
    npa: "6656",
    canton: "TI",
    location: "Golino",
  },
  {
    npa: "6646",
    canton: "TI",
    location: "Contra",
  },
  {
    npa: "6661",
    canton: "TI",
    location: "Loco",
  },
  {
    npa: "6661",
    canton: "TI",
    location: "Berzona",
  },
  {
    npa: "6662",
    canton: "TI",
    location: "Russo",
  },
  {
    npa: "6663",
    canton: "TI",
    location: "Comologno",
  },
  {
    npa: "6635",
    canton: "TI",
    location: "Gerra (Verzasca)",
  },
  {
    npa: "6612",
    canton: "TI",
    location: "Ascona",
  },
  {
    npa: "6644",
    canton: "TI",
    location: "Orselina",
  },
  {
    npa: "6648",
    canton: "TI",
    location: "Minusio",
  },
  {
    npa: "6655",
    canton: "TI",
    location: "Verdasio",
  },
  {
    npa: "6659",
    canton: "TI",
    location: "Moneto",
  },
  {
    npa: "6670",
    canton: "TI",
    location: "Avegno",
  },
  {
    npa: "6682",
    canton: "TI",
    location: "Linescio",
  },
  {
    npa: "6683",
    canton: "TI",
    location: "Cerentino",
  },
  {
    npa: "6684",
    canton: "TI",
    location: "Campo (Vallemaggia)",
  },
  {
    npa: "6684",
    canton: "TI",
    location: "Cimalmotto",
  },
  {
    npa: "6692",
    canton: "TI",
    location: "Brontallo",
  },
  {
    npa: "6695",
    canton: "TI",
    location: "Peccia",
  },
  {
    npa: "6695",
    canton: "TI",
    location: "Piano di Peccia",
  },
  {
    npa: "6696",
    canton: "TI",
    location: "Fusio",
  },
  {
    npa: "6672",
    canton: "TI",
    location: "Gordevio",
  },
  {
    npa: "6676",
    canton: "TI",
    location: "Bignasco",
  },
  {
    npa: "6705",
    canton: "TI",
    location: "Cresciano",
  },
  {
    npa: "6721",
    canton: "TI",
    location: "Ludiano",
  },
  {
    npa: "6723",
    canton: "TI",
    location: "Castro",
  },
  {
    npa: "6713",
    canton: "TI",
    location: "Malvaglia",
  },
  {
    npa: "6717",
    canton: "TI",
    location: "Dangio",
  },
  {
    npa: "6717",
    canton: "TI",
    location: "Torre",
  },
  {
    npa: "6745",
    canton: "TI",
    location: "Giornico",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Molare",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Calpiogna",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Campello",
  },
  {
    npa: "6772",
    canton: "TI",
    location: "Rodi-Fiesso",
  },
  {
    npa: "6777",
    canton: "TI",
    location: "Quinto",
  },
  {
    npa: "6780",
    canton: "TI",
    location: "Airolo",
  },
  {
    npa: "6781",
    canton: "TI",
    location: "Villa Bedretto",
  },
  {
    npa: "6780",
    canton: "TI",
    location: "Madrano",
  },
  {
    npa: "6781",
    canton: "TI",
    location: "Bedretto",
  },
  {
    npa: "6744",
    canton: "TI",
    location: "Personico",
  },
  {
    npa: "6748",
    canton: "TI",
    location: "Anzonico",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Rossura",
  },
  {
    npa: "6773",
    canton: "TI",
    location: "Prato (Leventina)",
  },
  {
    npa: "6802",
    canton: "TI",
    location: "Rivera",
  },
  {
    npa: "6804",
    canton: "TI",
    location: "Bironico",
  },
  {
    npa: "6807",
    canton: "TI",
    location: "Taverne",
  },
  {
    npa: "6808",
    canton: "TI",
    location: "Torricella",
  },
  {
    npa: "6814",
    canton: "TI",
    location: "Lamone-Cadempino",
  },
  {
    npa: "6818",
    canton: "TI",
    location: "Melano",
  },
  {
    npa: "6830",
    canton: "TI",
    location: "Chiasso 1",
  },
  {
    npa: "6830",
    canton: "TI",
    location: "Chiasso 3",
  },
  {
    npa: "6838",
    canton: "TI",
    location: "Muggio",
  },
  {
    npa: "6836",
    canton: "TI",
    location: "Serfontana",
  },
  {
    npa: "6806",
    canton: "TI",
    location: "Sigirino",
  },
  {
    npa: "6821",
    canton: "TI",
    location: "Rovio",
  },
  {
    npa: "6823",
    canton: "TI",
    location: "Pugerna",
  },
  {
    npa: "6854",
    canton: "TI",
    location: "S. Pietro",
  },
  {
    npa: "6864",
    canton: "TI",
    location: "Arzo",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano 1",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Massagno",
  },
  {
    npa: "6906",
    canton: "TI",
    location: "Lugano 6 Cassarate Caselle",
  },
  {
    npa: "6915",
    canton: "TI",
    location: "Pambio-Noranco",
  },
  {
    npa: "6919",
    canton: "TI",
    location: "Carabietta",
  },
  {
    npa: "6929",
    canton: "TI",
    location: "Gravesano",
  },
  {
    npa: "6930",
    canton: "TI",
    location: "Bedano",
  },
  {
    npa: "6939",
    canton: "TI",
    location: "Arosio",
  },
  {
    npa: "6939",
    canton: "TI",
    location: "Mugena",
  },
  {
    npa: "6938",
    canton: "TI",
    location: "Vezio",
  },
  {
    npa: "6937",
    canton: "TI",
    location: "Breno",
  },
  {
    npa: "6938",
    canton: "TI",
    location: "Fescoggia",
  },
  {
    npa: "6914",
    canton: "TI",
    location: "Carona",
  },
  {
    npa: "6924",
    canton: "TI",
    location: "Sorengo",
  },
  {
    npa: "6925",
    canton: "TI",
    location: "Gentilino",
  },
  {
    npa: "6934",
    canton: "TI",
    location: "Bioggio",
  },
  {
    npa: "6945",
    canton: "TI",
    location: "Origlio",
  },
  {
    npa: "6946",
    canton: "TI",
    location: "Ponte Capriasca",
  },
  {
    npa: "6953",
    canton: "TI",
    location: "Lugaggia",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Insone",
  },
  {
    npa: "6956",
    canton: "TI",
    location: "Lopagno",
  },
  {
    npa: "6957",
    canton: "TI",
    location: "Roveredo TI",
  },
  {
    npa: "6958",
    canton: "TI",
    location: "Bidogno",
  },
  {
    npa: "6955",
    canton: "TI",
    location: "Cagiallo",
  },
  {
    npa: "6959",
    canton: "TI",
    location: "Piandera Paese",
  },
  {
    npa: "6977",
    canton: "TI",
    location: "Ruvigliana",
  },
  {
    npa: "6978",
    canton: "TI",
    location: "Gandria",
  },
  {
    npa: "6990",
    canton: "TI",
    location: "Cassina d'Agno",
  },
  {
    npa: "6992",
    canton: "TI",
    location: "Vernate",
  },
  {
    npa: "6981",
    canton: "TI",
    location: "Banco",
  },
  {
    npa: "6986",
    canton: "TI",
    location: "Miglieglia",
  },
  {
    npa: "6989",
    canton: "TI",
    location: "Purasca",
  },
  {
    npa: "6997",
    canton: "TI",
    location: "Sessa",
  },
  {
    npa: "6986",
    canton: "TI",
    location: "Curio",
  },
  {
    npa: "7004",
    canton: "GR",
    location: "Chur",
  },
  {
    npa: "7006",
    canton: "GR",
    location: "Chur",
  },
  {
    npa: "7007",
    canton: "GR",
    location: "Chur",
  },
  {
    npa: "7012",
    canton: "GR",
    location: "Felsberg",
  },
  {
    npa: "7013",
    canton: "GR",
    location: "Domat/Ems",
  },
  {
    npa: "7023",
    canton: "GR",
    location: "Haldenstein",
  },
  {
    npa: "7050",
    canton: "GR",
    location: "Arosa",
  },
  {
    npa: "7062",
    canton: "GR",
    location: "Passugg",
  },
  {
    npa: "7074",
    canton: "GR",
    location: "Malix",
  },
  {
    npa: "7076",
    canton: "GR",
    location: "Parpan",
  },
  {
    npa: "7077",
    canton: "GR",
    location: "Valbella",
  },
  {
    npa: "7016",
    canton: "GR",
    location: "Trin Mulin",
  },
  {
    npa: "7027",
    canton: "GR",
    location: "Lüen",
  },
  {
    npa: "7028",
    canton: "GR",
    location: "St. Peter",
  },
  {
    npa: "7057",
    canton: "GR",
    location: "Langwies",
  },
  {
    npa: "7107",
    canton: "GR",
    location: "Safien Platz",
  },
  {
    npa: "7151",
    canton: "GR",
    location: "Schluein",
  },
  {
    npa: "7115",
    canton: "GR",
    location: "Surcasti",
  },
  {
    npa: "7116",
    canton: "GR",
    location: "Tersnaus",
  },
  {
    npa: "7144",
    canton: "GR",
    location: "Vella",
  },
  {
    npa: "7145",
    canton: "GR",
    location: "Degen",
  },
  {
    npa: "7137",
    canton: "GR",
    location: "Flond",
  },
  {
    npa: "7132",
    canton: "GR",
    location: "Vals",
  },
  {
    npa: "7163",
    canton: "GR",
    location: "Danis",
  },
  {
    npa: "7164",
    canton: "GR",
    location: "Dardin",
  },
  {
    npa: "7165",
    canton: "GR",
    location: "Breil/Brigels",
  },
  {
    npa: "7174",
    canton: "GR",
    location: "S. Benedetg",
  },
  {
    npa: "7183",
    canton: "GR",
    location: "Mumpé Medel",
  },
  {
    npa: "7184",
    canton: "GR",
    location: "Curaglia",
  },
  {
    npa: "7185",
    canton: "GR",
    location: "Platta",
  },
  {
    npa: "7187",
    canton: "GR",
    location: "Camischolas",
  },
  {
    npa: "7173",
    canton: "GR",
    location: "Surrein",
  },
  {
    npa: "7202",
    canton: "GR",
    location: "Says",
  },
  {
    npa: "7213",
    canton: "GR",
    location: "Valzeina",
  },
  {
    npa: "7214",
    canton: "GR",
    location: "Grüsch",
  },
  {
    npa: "7222",
    canton: "GR",
    location: "Lunden",
  },
  {
    npa: "7228",
    canton: "GR",
    location: "Pusserein",
  },
  {
    npa: "7235",
    canton: "GR",
    location: "Fideris",
  },
  {
    npa: "7241",
    canton: "GR",
    location: "Conters im Prättigau",
  },
  {
    npa: "7265",
    canton: "GR",
    location: "Davos Wolfgang",
  },
  {
    npa: "7270",
    canton: "GR",
    location: "Davos Platz",
  },
  {
    npa: "7302",
    canton: "GR",
    location: "Landquart",
  },
  {
    npa: "7303",
    canton: "GR",
    location: "Mastrils",
  },
  {
    npa: "7310",
    canton: "SG",
    location: "Bad Ragaz",
  },
  {
    npa: "7323",
    canton: "SG",
    location: "Wangs",
  },
  {
    npa: "7402",
    canton: "GR",
    location: "Bonaduz",
  },
  {
    npa: "7403",
    canton: "GR",
    location: "Rhäzüns",
  },
  {
    npa: "7413",
    canton: "GR",
    location: "Fürstenaubruck",
  },
  {
    npa: "7415",
    canton: "GR",
    location: "Rodels",
  },
  {
    npa: "7407",
    canton: "GR",
    location: "Trans",
  },
  {
    npa: "7418",
    canton: "GR",
    location: "Tumegl/Tomils",
  },
  {
    npa: "7419",
    canton: "GR",
    location: "Scheid",
  },
  {
    npa: "7427",
    canton: "GR",
    location: "Urmein",
  },
  {
    npa: "7428",
    canton: "GR",
    location: "Tschappina",
  },
  {
    npa: "7421",
    canton: "GR",
    location: "Summaprada",
  },
  {
    npa: "7433",
    canton: "GR",
    location: "Mathon",
  },
  {
    npa: "7445",
    canton: "GR",
    location: "Innerferrera",
  },
  {
    npa: "7447",
    canton: "GR",
    location: "Cresta (Avers)",
  },
  {
    npa: "7447",
    canton: "GR",
    location: "Am Bach (Avers)",
  },
  {
    npa: "7453",
    canton: "GR",
    location: "Tinizong",
  },
  {
    npa: "7454",
    canton: "GR",
    location: "Rona",
  },
  {
    npa: "7456",
    canton: "GR",
    location: "Sur",
  },
  {
    npa: "7463",
    canton: "GR",
    location: "Riom",
  },
  {
    npa: "7477",
    canton: "GR",
    location: "Filisur",
  },
  {
    npa: "7494",
    canton: "GR",
    location: "Davos Wiesen",
  },
  {
    npa: "7500",
    canton: "GR",
    location: "St. Moritz 1",
  },
  {
    npa: "7503",
    canton: "GR",
    location: "Samedan",
  },
  {
    npa: "7505",
    canton: "GR",
    location: "Celerina/Schlarigna",
  },
  {
    npa: "7514",
    canton: "GR",
    location: "Sils/Segl Maria",
  },
  {
    npa: "7516",
    canton: "GR",
    location: "Maloja",
  },
  {
    npa: "7517",
    canton: "GR",
    location: "Plaun da Lej",
  },
  {
    npa: "7523",
    canton: "GR",
    location: "Madulain",
  },
  {
    npa: "7532",
    canton: "GR",
    location: "Tschierv",
  },
  {
    npa: "7533",
    canton: "GR",
    location: "Fuldera",
  },
  {
    npa: "7534",
    canton: "GR",
    location: "Lü",
  },
  {
    npa: "7546",
    canton: "GR",
    location: "Ardez",
  },
  {
    npa: "7552",
    canton: "GR",
    location: "Vulpera",
  },
  {
    npa: "7558",
    canton: "GR",
    location: "Strada",
  },
  {
    npa: "7559",
    canton: "GR",
    location: "Tschlin",
  },
  {
    npa: "7610",
    canton: "GR",
    location: "Soglio",
  },
  {
    npa: "7608",
    canton: "GR",
    location: "Castasegna",
  },
  {
    npa: "7742",
    canton: "GR",
    location: "Sfazù",
  },
  {
    npa: "7743",
    canton: "GR",
    location: "Brusio",
  },
  {
    npa: "7747",
    canton: "GR",
    location: "Viano",
  },
  {
    npa: "7744",
    canton: "GR",
    location: "Campocologno",
  },
  {
    npa: "7741",
    canton: "GR",
    location: "S. Carlo (Poschiavo)",
  },
  {
    npa: "7710",
    canton: "GR",
    location: "Alp Grüm",
  },
  {
    npa: "7745",
    canton: "GR",
    location: "Li Curt",
  },
  {
    npa: "7746",
    canton: "GR",
    location: "Le Prese",
  },
  {
    npa: "7748",
    canton: "GR",
    location: "Campascio",
  },
  {
    npa: "8000",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8005",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8008",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich BZ FP",
  },
  {
    npa: "8022",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8024",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8026",
    canton: "ZH",
    location: "Zürich Helvetiaplatz",
  },
  {
    npa: "8032",
    canton: "ZH",
    location: "Zürich Neumünster",
  },
  {
    npa: "8038",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8045",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8047",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8048",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8050",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8051",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8090",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8104",
    canton: "ZH",
    location: "Weiningen ZH",
  },
  {
    npa: "8114",
    canton: "ZH",
    location: "Dänikon ZH",
  },
  {
    npa: "8117",
    canton: "ZH",
    location: "Fällanden",
  },
  {
    npa: "8121",
    canton: "ZH",
    location: "Benglen",
  },
  {
    npa: "8122",
    canton: "ZH",
    location: "Binz",
  },
  {
    npa: "8134",
    canton: "ZH",
    location: "Adliswil",
  },
  {
    npa: "8135",
    canton: "ZH",
    location: "Langnau am Albis",
  },
  {
    npa: "8143",
    canton: "ZH",
    location: "Stallikon",
  },
  {
    npa: "8154",
    canton: "ZH",
    location: "Oberglatt ZH",
  },
  {
    npa: "8157",
    canton: "ZH",
    location: "Dielsdorf",
  },
  {
    npa: "8182",
    canton: "ZH",
    location: "Hochfelden",
  },
  {
    npa: "8185",
    canton: "ZH",
    location: "Winkel",
  },
  {
    npa: "8200",
    canton: "SH",
    location: "Schaffhausen 1 Zust",
  },
  {
    npa: "8201",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8203",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8243",
    canton: "SH",
    location: "Altdorf SH",
  },
  {
    npa: "8239",
    canton: "SH",
    location: "Dörflingen",
  },
  {
    npa: "8212",
    canton: "SH",
    location: "Neuhausen am Rheinfall 1",
  },
  {
    npa: "8213",
    canton: "SH",
    location: "Neunkirch",
  },
  {
    npa: "8214",
    canton: "SH",
    location: "Gächlingen",
  },
  {
    npa: "8215",
    canton: "SH",
    location: "Hallau",
  },
  {
    npa: "8217",
    canton: "SH",
    location: "Wilchingen",
  },
  {
    npa: "8218",
    canton: "SH",
    location: "Osterfingen",
  },
  {
    npa: "8240",
    canton: "SH",
    location: "Thayngen",
  },
  {
    npa: "8247",
    canton: "ZH",
    location: "Flurlingen",
  },
  {
    npa: "8259",
    canton: "TG",
    location: "Kaltenbach",
  },
  {
    npa: "8259",
    canton: "TG",
    location: "Etzwilen",
  },
  {
    npa: "8260",
    canton: "SH",
    location: "Stein am Rhein",
  },
  {
    npa: "8261",
    canton: "SH",
    location: "Hemishofen",
  },
  {
    npa: "8262",
    canton: "SH",
    location: "Ramsen",
  },
  {
    npa: "8264",
    canton: "TG",
    location: "Eschenz",
  },
  {
    npa: "8267",
    canton: "TG",
    location: "Berlingen",
  },
  {
    npa: "8280",
    canton: "TG",
    location: "Kreuzlingen ceha!",
  },
  {
    npa: "8303",
    canton: "ZH",
    location: "Bassersdorf",
  },
  {
    npa: "8306",
    canton: "ZH",
    location: "Brüttisellen",
  },
  {
    npa: "8307",
    canton: "ZH",
    location: "Effretikon",
  },
  {
    npa: "8315",
    canton: "ZH",
    location: "Lindau",
  },
  {
    npa: "8308",
    canton: "ZH",
    location: "Agasul",
  },
  {
    npa: "8310",
    canton: "ZH",
    location: "Kemptthal",
  },
  {
    npa: "8312",
    canton: "ZH",
    location: "Winterberg ZH",
  },
  {
    npa: "8311",
    canton: "ZH",
    location: "Brütten",
  },
  {
    npa: "8489",
    canton: "ZH",
    location: "Wildberg",
  },
  {
    npa: "8330",
    canton: "ZH",
    location: "Pfäffikon ZH",
  },
  {
    npa: "8335",
    canton: "ZH",
    location: "Hittnau",
  },
  {
    npa: "8342",
    canton: "ZH",
    location: "Wernetshausen",
  },
  {
    npa: "8344",
    canton: "ZH",
    location: "Bäretswil",
  },
  {
    npa: "8352",
    canton: "ZH",
    location: "Elsau",
  },
  {
    npa: "8354",
    canton: "ZH",
    location: "Hofstetten ZH",
  },
  {
    npa: "8356",
    canton: "TG",
    location: "Ettenhausen TG",
  },
  {
    npa: "8357",
    canton: "TG",
    location: "Guntershausen b. Aadorf",
  },
  {
    npa: "8363",
    canton: "TG",
    location: "Bichelsee",
  },
  {
    npa: "8400",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8401",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8402",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8408",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8412",
    canton: "ZH",
    location: "Aesch (Neftenbach)",
  },
  {
    npa: "8415",
    canton: "ZH",
    location: "Berg am Irchel",
  },
  {
    npa: "8418",
    canton: "ZH",
    location: "Schlatt ZH",
  },
  {
    npa: "8425",
    canton: "ZH",
    location: "Oberembrach",
  },
  {
    npa: "8192",
    canton: "ZH",
    location: "Zweidlen",
  },
  {
    npa: "5330",
    canton: "AG",
    location: "Bad Zurzach",
  },
  {
    npa: "5465",
    canton: "AG",
    location: "Mellikon",
  },
  {
    npa: "8444",
    canton: "ZH",
    location: "Henggart",
  },
  {
    npa: "8248",
    canton: "ZH",
    location: "Uhwiesen",
  },
  {
    npa: "8451",
    canton: "ZH",
    location: "Kleinandelfingen",
  },
  {
    npa: "8452",
    canton: "ZH",
    location: "Adlikon b. Andelfingen",
  },
  {
    npa: "8459",
    canton: "ZH",
    location: "Volken",
  },
  {
    npa: "8455",
    canton: "SH",
    location: "Rüdlingen",
  },
  {
    npa: "8461",
    canton: "ZH",
    location: "Oerlingen",
  },
  {
    npa: "8465",
    canton: "ZH",
    location: "Rudolfingen",
  },
  {
    npa: "8462",
    canton: "ZH",
    location: "Rheinau",
  },
  {
    npa: "8463",
    canton: "ZH",
    location: "Benken ZH",
  },
  {
    npa: "8472",
    canton: "ZH",
    location: "Seuzach",
  },
  {
    npa: "8474",
    canton: "ZH",
    location: "Dinhard",
  },
  {
    npa: "8479",
    canton: "ZH",
    location: "Altikon",
  },
  {
    npa: "8467",
    canton: "ZH",
    location: "Truttikon",
  },
  {
    npa: "8468",
    canton: "ZH",
    location: "Waltalingen",
  },
  {
    npa: "8482",
    canton: "ZH",
    location: "Sennhof (Winterthur)",
  },
  {
    npa: "8484",
    canton: "ZH",
    location: "Weisslingen",
  },
  {
    npa: "8484",
    canton: "ZH",
    location: "Neschwil",
  },
  {
    npa: "8486",
    canton: "ZH",
    location: "Rikon im Tösstal",
  },
  {
    npa: "8492",
    canton: "ZH",
    location: "Wila",
  },
  {
    npa: "8496",
    canton: "ZH",
    location: "Steg im Tösstal",
  },
  {
    npa: "8497",
    canton: "ZH",
    location: "Fischenthal",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Gerlikon",
  },
  {
    npa: "8502",
    canton: "TG",
    location: "Frauenfeld Talbach",
  },
  {
    npa: "8507",
    canton: "TG",
    location: "Hörhausen",
  },
  {
    npa: "8512",
    canton: "TG",
    location: "Thundorf",
  },
  {
    npa: "8526",
    canton: "TG",
    location: "Oberneunforn",
  },
  {
    npa: "8532",
    canton: "TG",
    location: "Warth",
  },
  {
    npa: "8545",
    canton: "ZH",
    location: "Rickenbach ZH",
  },
  {
    npa: "8547",
    canton: "TG",
    location: "Gachnang",
  },
  {
    npa: "8548",
    canton: "ZH",
    location: "Ellikon an der Thur",
  },
  {
    npa: "8552",
    canton: "TG",
    location: "Felben-Wellhausen",
  },
  {
    npa: "8554",
    canton: "TG",
    location: "Bonau",
  },
  {
    npa: "8560",
    canton: "TG",
    location: "Märstetten",
  },
  {
    npa: "8566",
    canton: "TG",
    location: "Dotnacht",
  },
  {
    npa: "8570",
    canton: "TG",
    location: "Weinfelden",
  },
  {
    npa: "8576",
    canton: "TG",
    location: "Mauren TG",
  },
  {
    npa: "8577",
    canton: "TG",
    location: "Schönholzerswilen",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Sommeri",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Hagenwil b. Amriswil",
  },
  {
    npa: "8581",
    canton: "TG",
    location: "Schocherswil",
  },
  {
    npa: "8588",
    canton: "TG",
    location: "Zihlschlacht",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Happerswil",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Erlen",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Riedt b. Erlen",
  },
  {
    npa: "8590",
    canton: "TG",
    location: "Romanshorn",
  },
  {
    npa: "8599",
    canton: "TG",
    location: "Salmsach",
  },
  {
    npa: "8592",
    canton: "TG",
    location: "Uttwil",
  },
  {
    npa: "8597",
    canton: "TG",
    location: "Landschlacht",
  },
  {
    npa: "8600",
    canton: "ZH",
    location: "Dübendorf 1",
  },
  {
    npa: "8606",
    canton: "ZH",
    location: "Nänikon",
  },
  {
    npa: "8610",
    canton: "ZH",
    location: "Uster 1",
  },
  {
    npa: "8617",
    canton: "ZH",
    location: "Mönchaltorf",
  },
  {
    npa: "8618",
    canton: "ZH",
    location: "Oetwil am See",
  },
  {
    npa: "8620",
    canton: "ZH",
    location: "Wetzikon ZH",
  },
  {
    npa: "8621",
    canton: "ZH",
    location: "Wetzikon ZH Robenhausen",
  },
  {
    npa: "8623",
    canton: "ZH",
    location: "Wetzikon ZH",
  },
  {
    npa: "8626",
    canton: "ZH",
    location: "Ottikon (Gossau ZH)",
  },
  {
    npa: "8633",
    canton: "ZH",
    location: "Wolfhausen",
  },
  {
    npa: "8636",
    canton: "ZH",
    location: "Wald ZH",
  },
  {
    npa: "8638",
    canton: "SG",
    location: "Goldingen",
  },
  {
    npa: "8640",
    canton: "SG",
    location: "Rapperswil SG",
  },
  {
    npa: "8640",
    canton: "SG",
    location: "Rapperswil SG Zustellung",
  },
  {
    npa: "8645",
    canton: "SG",
    location: "Jona",
  },
  {
    npa: "8700",
    canton: "ZH",
    location: "Küsnacht ZH",
  },
  {
    npa: "8702",
    canton: "ZH",
    location: "Zollikon",
  },
  {
    npa: "8702",
    canton: "ZH",
    location: "Zollikon Dorf",
  },
  {
    npa: "8707",
    canton: "ZH",
    location: "Uetikon am See",
  },
  {
    npa: "8713",
    canton: "ZH",
    location: "Uerikon",
  },
  {
    npa: "8714",
    canton: "ZH",
    location: "Feldbach",
  },
  {
    npa: "8715",
    canton: "SG",
    location: "Bollingen",
  },
  {
    npa: "8717",
    canton: "SG",
    location: "Benken SG",
  },
  {
    npa: "8718",
    canton: "SG",
    location: "Schänis",
  },
  {
    npa: "8723",
    canton: "SG",
    location: "Rufi",
  },
  {
    npa: "8725",
    canton: "SG",
    location: "Ernetschwil",
  },
  {
    npa: "8726",
    canton: "SG",
    location: "Ricken SG",
  },
  {
    npa: "8733",
    canton: "SG",
    location: "Eschenbach SG",
  },
  {
    npa: "8735",
    canton: "SG",
    location: "St. Gallenkappel",
  },
  {
    npa: "8737",
    canton: "SG",
    location: "Gommiswald",
  },
  {
    npa: "8739",
    canton: "SG",
    location: "Rieden SG",
  },
  {
    npa: "8740",
    canton: "SG",
    location: "Uznach Vögele Versandhaus",
  },
  {
    npa: "8750",
    canton: "GL",
    location: "Riedern",
  },
  {
    npa: "8754",
    canton: "GL",
    location: "Netstal",
  },
  {
    npa: "8762",
    canton: "GL",
    location: "Schwanden GL",
  },
  {
    npa: "8766",
    canton: "GL",
    location: "Matt",
  },
  {
    npa: "8775",
    canton: "GL",
    location: "Hätzingen",
  },
  {
    npa: "8784",
    canton: "GL",
    location: "Braunwald",
  },
  {
    npa: "8800",
    canton: "ZH",
    location: "Thalwil",
  },
  {
    npa: "8804",
    canton: "ZH",
    location: "Au ZH",
  },
  {
    npa: "8805",
    canton: "ZH",
    location: "Richterswil",
  },
  {
    npa: "8806",
    canton: "SZ",
    location: "Bäch SZ",
  },
  {
    npa: "8807",
    canton: "SZ",
    location: "Freienbach",
  },
  {
    npa: "8810",
    canton: "ZH",
    location: "Horgen 1",
  },
  {
    npa: "8813",
    canton: "ZH",
    location: "Horgen",
  },
  {
    npa: "8820",
    canton: "ZH",
    location: "Wädenswil",
  },
  {
    npa: "8824",
    canton: "ZH",
    location: "Schönenberg ZH",
  },
  {
    npa: "8834",
    canton: "SZ",
    location: "Schindellegi",
  },
  {
    npa: "8840",
    canton: "SZ",
    location: "Einsiedeln",
  },
  {
    npa: "8849",
    canton: "SZ",
    location: "Alpthal",
  },
  {
    npa: "8844",
    canton: "SZ",
    location: "Euthal",
  },
  {
    npa: "8845",
    canton: "SZ",
    location: "Studen SZ",
  },
  {
    npa: "8852",
    canton: "SZ",
    location: "Altendorf",
  },
  {
    npa: "8853",
    canton: "SZ",
    location: "Lachen SZ",
  },
  {
    npa: "8856",
    canton: "SZ",
    location: "Tuggen",
  },
  {
    npa: "8872",
    canton: "SG",
    location: "Weesen",
  },
  {
    npa: "8873",
    canton: "SG",
    location: "Amden",
  },
  {
    npa: "8874",
    canton: "GL",
    location: "Mühlehorn",
  },
  {
    npa: "8880",
    canton: "SG",
    location: "Walenstadt",
  },
  {
    npa: "8881",
    canton: "SG",
    location: "Tscherlach",
  },
  {
    npa: "8883",
    canton: "SG",
    location: "Quarten",
  },
  {
    npa: "8884",
    canton: "SG",
    location: "Oberterzen",
  },
  {
    npa: "8888",
    canton: "SG",
    location: "Heiligkreuz (Mels)",
  },
  {
    npa: "8895",
    canton: "SG",
    location: "Flumserberg Portels",
  },
  {
    npa: "8908",
    canton: "ZH",
    location: "Hedingen",
  },
  {
    npa: "8912",
    canton: "ZH",
    location: "Obfelden",
  },
  {
    npa: "8913",
    canton: "ZH",
    location: "Ottenbach",
  },
  {
    npa: "8915",
    canton: "ZH",
    location: "Hausen am Albis",
  },
  {
    npa: "8925",
    canton: "ZH",
    location: "Ebertswil",
  },
  {
    npa: "8926",
    canton: "ZH",
    location: "Kappel am Albis",
  },
  {
    npa: "8942",
    canton: "ZH",
    location: "Oberrieden",
  },
  {
    npa: "8135",
    canton: "ZH",
    location: "Sihlbrugg Station",
  },
  {
    npa: "6340",
    canton: "ZG",
    location: "Sihlbrugg",
  },
  {
    npa: "8951",
    canton: "ZH",
    location: "Fahrweid",
  },
  {
    npa: "8953",
    canton: "ZH",
    location: "Dietikon 1",
  },
  {
    npa: "8953",
    canton: "ZH",
    location: "Dietikon Glanzenberg",
  },
  {
    npa: "8954",
    canton: "ZH",
    location: "Geroldswil",
  },
  {
    npa: "8957",
    canton: "AG",
    location: "Spreitenbach",
  },
  {
    npa: "8965",
    canton: "AG",
    location: "Berikon",
  },
  {
    npa: "8966",
    canton: "AG",
    location: "Oberwil-Lieli",
  },
  {
    npa: "8967",
    canton: "AG",
    location: "Widen",
  },
  {
    npa: "9000",
    canton: "SG",
    location: "St. Gallen 1 Annahme",
  },
  {
    npa: "9004",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9008",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9011",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9014",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9015",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9016",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9032",
    canton: "SG",
    location: "Engelburg",
  },
  {
    npa: "9036",
    canton: "SG",
    location: "Grub SG",
  },
  {
    npa: "9038",
    canton: "AR",
    location: "Rehetobel",
  },
  {
    npa: "9042",
    canton: "AR",
    location: "Speicher",
  },
  {
    npa: "9052",
    canton: "AR",
    location: "Niederteufen",
  },
  {
    npa: "9053",
    canton: "AR",
    location: "Teufen AR",
  },
  {
    npa: "9057",
    canton: "AI",
    location: "Weissbad",
  },
  {
    npa: "9063",
    canton: "AR",
    location: "Stein AR",
  },
  {
    npa: "9100",
    canton: "AR",
    location: "Herisau 1",
  },
  {
    npa: "9102",
    canton: "AR",
    location: "Herisau",
  },
  {
    npa: "9115",
    canton: "SG",
    location: "Dicken",
  },
  {
    npa: "9123",
    canton: "SG",
    location: "Nassen",
  },
  {
    npa: "9127",
    canton: "SG",
    location: "St. Peterzell",
  },
  {
    npa: "9633",
    canton: "SG",
    location: "Bächli (Hemberg)",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG 2",
  },
  {
    npa: "9204",
    canton: "SG",
    location: "Andwil SG",
  },
  {
    npa: "9212",
    canton: "SG",
    location: "Arnegg",
  },
  {
    npa: "9215",
    canton: "TG",
    location: "Schönenberg an der Thur",
  },
  {
    npa: "9216",
    canton: "TG",
    location: "Hohentannen",
  },
  {
    npa: "9225",
    canton: "TG",
    location: "Wilen (Gottshaus)",
  },
  {
    npa: "9225",
    canton: "TG",
    location: "St. Pelagiberg",
  },
  {
    npa: "9248",
    canton: "SG",
    location: "Bichwil",
  },
  {
    npa: "9243",
    canton: "SG",
    location: "Jonschwil",
  },
  {
    npa: "9249",
    canton: "SG",
    location: "Algetshausen",
  },
  {
    npa: "9300",
    canton: "SG",
    location: "Wittenbach",
  },
  {
    npa: "9315",
    canton: "TG",
    location: "Winden",
  },
  {
    npa: "9308",
    canton: "SG",
    location: "Lömmenschwil",
  },
  {
    npa: "9315",
    canton: "TG",
    location: "Neukirch (Egnach)",
  },
  {
    npa: "9320",
    canton: "TG",
    location: "Arbon",
  },
  {
    npa: "9320",
    canton: "TG",
    location: "Frasnacht",
  },
  {
    npa: "9323",
    canton: "SG",
    location: "Steinach",
  },
  {
    npa: "9402",
    canton: "SG",
    location: "Mörschwil",
  },
  {
    npa: "9403",
    canton: "SG",
    location: "Goldach",
  },
  {
    npa: "9411",
    canton: "AR",
    location: "Schachen b. Reute",
  },
  {
    npa: "9422",
    canton: "SG",
    location: "Staad SG",
  },
  {
    npa: "9428",
    canton: "AR",
    location: "Walzenhausen",
  },
  {
    npa: "9430",
    canton: "SG",
    location: "St. Margrethen SG",
  },
  {
    npa: "9436",
    canton: "SG",
    location: "Balgach",
  },
  {
    npa: "9437",
    canton: "SG",
    location: "Marbach SG",
  },
  {
    npa: "9450",
    canton: "SG",
    location: "Lüchingen",
  },
  {
    npa: "9443",
    canton: "SG",
    location: "Widnau",
  },
  {
    npa: "9445",
    canton: "SG",
    location: "Rebstein",
  },
  {
    npa: "9452",
    canton: "SG",
    location: "Hinterforst",
  },
  {
    npa: "9453",
    canton: "SG",
    location: "Eichberg",
  },
  {
    npa: "9464",
    canton: "SG",
    location: "Rüthi (Rheintal)",
  },
  {
    npa: "9465",
    canton: "SG",
    location: "Salez",
  },
  {
    npa: "9466",
    canton: "SG",
    location: "Sennwald",
  },
  {
    npa: "9467",
    canton: "SG",
    location: "Frümsen",
  },
  {
    npa: "9469",
    canton: "SG",
    location: "Haag (Rheintal)",
  },
  {
    npa: "9470",
    canton: "SG",
    location: "Buchs SG",
  },
  {
    npa: "9470",
    canton: "SG",
    location: "Buchs SG 1",
  },
  {
    npa: "9472",
    canton: "SG",
    location: "Grabs",
  },
  {
    npa: "9477",
    canton: "SG",
    location: "Trübbach",
  },
  {
    npa: "9478",
    canton: "SG",
    location: "Azmoos",
  },
  {
    npa: "9485",
    canton: "FL",
    location: "Nendeln",
  },
  {
    npa: "9486",
    canton: "FL",
    location: "Schaanwald",
  },
  {
    npa: "9493",
    canton: "FL",
    location: "Mauren FL",
  },
  {
    npa: "9500",
    canton: "SG",
    location: "Wil SG 1",
  },
  {
    npa: "9503",
    canton: "TG",
    location: "Stehrenberg",
  },
  {
    npa: "9506",
    canton: "TG",
    location: "Lommis",
  },
  {
    npa: "9508",
    canton: "TG",
    location: "Weingarten-Kalthäusern",
  },
  {
    npa: "9517",
    canton: "TG",
    location: "Mettlen",
  },
  {
    npa: "9525",
    canton: "SG",
    location: "Lenggenwil",
  },
  {
    npa: "9532",
    canton: "TG",
    location: "Rickenbach b. Wil",
  },
  {
    npa: "9533",
    canton: "SG",
    location: "Kirchberg SG",
  },
  {
    npa: "9542",
    canton: "TG",
    location: "Münchwilen TG",
  },
  {
    npa: "9543",
    canton: "TG",
    location: "St. Margarethen TG",
  },
  {
    npa: "9546",
    canton: "TG",
    location: "Tuttwil",
  },
  {
    npa: "9547",
    canton: "TG",
    location: "Wittenwil",
  },
  {
    npa: "9552",
    canton: "SG",
    location: "Bronschhofen",
  },
  {
    npa: "9556",
    canton: "TG",
    location: "Affeltrangen",
  },
  {
    npa: "9565",
    canton: "TG",
    location: "Bussnang",
  },
  {
    npa: "9602",
    canton: "SG",
    location: "Bazenheid",
  },
  {
    npa: "9608",
    canton: "SG",
    location: "Ganterschwil",
  },
  {
    npa: "9612",
    canton: "SG",
    location: "Dreien",
  },
  {
    npa: "9613",
    canton: "SG",
    location: "Mühlrüti",
  },
  {
    npa: "9620",
    canton: "SG",
    location: "Lichtensteig",
  },
  {
    npa: "9642",
    canton: "SG",
    location: "Ebnat-Kappel",
  },
  {
    npa: "9650",
    canton: "SG",
    location: "Nesslau",
  },
  {
    npa: "9655",
    canton: "SG",
    location: "Stein SG",
  },
  {
    npa: "9652",
    canton: "SG",
    location: "Neu St. Johann",
  },
  {
    npa: "6911",
    canton: "IT",
    location: "Campione d'Italia",
  },
  {
    npa: "8238",
    canton: "DE",
    location: "Büsingen",
  },
  {
    npa: "1934",
    canton: "VS",
    location: "Bruson",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Cheseaux-Noréaz",
  },
  {
    npa: "1920",
    canton: "VS",
    location: "Martigny 1 Distribution",
  },
  {
    npa: "8317",
    canton: "ZH",
    location: "Tagelswangen",
  },
  {
    npa: "3855",
    canton: "BE",
    location: "Schwanden b. Brienz",
  },
  {
    npa: "6009",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "1267",
    canton: "VD",
    location: "Vich",
  },
  {
    npa: "5232",
    canton: "AG",
    location: "Villigen PSI",
  },
  {
    npa: "2830",
    canton: "JU",
    location: "Vellerat",
  },
  {
    npa: "2717",
    canton: "BE",
    location: "Rebévelier",
  },
  {
    npa: "2575",
    canton: "BE",
    location: "Hagneck",
  },
  {
    npa: "1473",
    canton: "FR",
    location: "Châtillon FR",
  },
  {
    npa: "1566",
    canton: "FR",
    location: "Les Friques",
  },
  {
    npa: "1528",
    canton: "FR",
    location: "Praratoud",
  },
  {
    npa: "1470",
    canton: "FR",
    location: "Seiry",
  },
  {
    npa: "1541",
    canton: "FR",
    location: "Sévaz",
  },
  {
    npa: "1680",
    canton: "FR",
    location: "Berlens",
  },
  {
    npa: "1670",
    canton: "FR",
    location: "Bionnens",
  },
  {
    npa: "1690",
    canton: "FR",
    location: "Lussy FR",
  },
  {
    npa: "1687",
    canton: "FR",
    location: "La Magne",
  },
  {
    npa: "1674",
    canton: "FR",
    location: "Morlens",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "Autafond",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Essert FR",
  },
  {
    npa: "1726",
    canton: "FR",
    location: "Farvagny-le-Petit",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Ferpicloz",
  },
  {
    npa: "1726",
    canton: "FR",
    location: "Grenilles",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "Lossy",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Montévraz",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Oberried FR",
  },
  {
    npa: "1695",
    canton: "FR",
    location: "Villarsel-le-Gibloux",
  },
  {
    npa: "1792",
    canton: "FR",
    location: "Guschelmuth",
  },
  {
    npa: "1784",
    canton: "FR",
    location: "Wallenried",
  },
  {
    npa: "1609",
    canton: "FR",
    location: "Besencens",
  },
  {
    npa: "1609",
    canton: "FR",
    location: "Fiaugères",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Mauraz",
  },
  {
    npa: "1376",
    canton: "VD",
    location: "Eclagnens",
  },
  {
    npa: "1376",
    canton: "VD",
    location: "Goumoens-le-Jux",
  },
  {
    npa: "1026",
    canton: "VD",
    location: "Denges",
  },
  {
    npa: "1682",
    canton: "VD",
    location: "Lovatens",
  },
  {
    npa: "1063",
    canton: "VD",
    location: "Martherenges",
  },
  {
    npa: "1290",
    canton: "VD",
    location: "Chavannes-des-Bois",
  },
  {
    npa: "1610",
    canton: "VD",
    location: "Vuibroye",
  },
  {
    npa: "1682",
    canton: "VD",
    location: "Cerniaz VD",
  },
  {
    npa: "1554",
    canton: "VD",
    location: "Rossens VD",
  },
  {
    npa: "1525",
    canton: "VD",
    location: "Seigneux",
  },
  {
    npa: "1195",
    canton: "VD",
    location: "Dully",
  },
  {
    npa: "1415",
    canton: "VD",
    location: "Démoret",
  },
  {
    npa: "1443",
    canton: "VD",
    location: "Essert-sous-Champvent",
  },
  {
    npa: "1443",
    canton: "VD",
    location: "Villars-sous-Champvent",
  },
  {
    npa: "1047",
    canton: "VD",
    location: "Oppens",
  },
  {
    npa: "2027",
    canton: "NE",
    location: "Montalchez",
  },
  {
    npa: "3283",
    canton: "BE",
    location: "Niederried b. Kallnach",
  },
  {
    npa: "3472",
    canton: "BE",
    location: "Rumendingen",
  },
  {
    npa: "3421",
    canton: "BE",
    location: "Rüti b. Lyssach",
  },
  {
    npa: "3425",
    canton: "BE",
    location: "Willadingen",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Deisswil b. Münchenbuchsee",
  },
  {
    npa: "3305",
    canton: "BE",
    location: "Scheunen",
  },
  {
    npa: "3309",
    canton: "BE",
    location: "Zauggenried",
  },
  {
    npa: "3510",
    canton: "BE",
    location: "Freimettigen",
  },
  {
    npa: "3510",
    canton: "BE",
    location: "Häutligen",
  },
  {
    npa: "3504",
    canton: "BE",
    location: "Oberhünigen",
  },
  {
    npa: "3207",
    canton: "BE",
    location: "Golaten",
  },
  {
    npa: "3272",
    canton: "BE",
    location: "Epsach",
  },
  {
    npa: "3127",
    canton: "BE",
    location: "Lohnstorf",
  },
  {
    npa: "3116",
    canton: "BE",
    location: "Mühledorf BE",
  },
  {
    npa: "3636",
    canton: "BE",
    location: "Forst b. Längenbühl",
  },
  {
    npa: "3366",
    canton: "BE",
    location: "Bollodingen",
  },
  {
    npa: "4704",
    canton: "BE",
    location: "Wolfisberg",
  },
  {
    npa: "8774",
    canton: "GL",
    location: "Leuggelbach",
  },
  {
    npa: "3216",
    canton: "FR",
    location: "Agriswil",
  },
  {
    npa: "3280",
    canton: "FR",
    location: "Greng",
  },
  {
    npa: "3215",
    canton: "FR",
    location: "Lurtigen",
  },
  {
    npa: "3206",
    canton: "FR",
    location: "Wallenbuch",
  },
  {
    npa: "3254",
    canton: "SO",
    location: "Balm b. Messen",
  },
  {
    npa: "4558",
    canton: "SO",
    location: "Heinrichswil",
  },
  {
    npa: "4554",
    canton: "SO",
    location: "Hüniken",
  },
  {
    npa: "4556",
    canton: "SO",
    location: "Steinhof SO",
  },
  {
    npa: "4535",
    canton: "SO",
    location: "Kammersrohr",
  },
  {
    npa: "5619",
    canton: "AG",
    location: "Uezwil",
  },
  {
    npa: "8905",
    canton: "AG",
    location: "Islisberg",
  },
  {
    npa: "5058",
    canton: "AG",
    location: "Wiliberg",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Hefenhofen",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Buchackern",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Ennetaach",
  },
  {
    npa: "8546",
    canton: "TG",
    location: "Kefikon TG",
  },
  {
    npa: "8553",
    canton: "TG",
    location: "Eschikofen",
  },
  {
    npa: "8573",
    canton: "TG",
    location: "Alterswilen",
  },
  {
    npa: "8566",
    canton: "TG",
    location: "Ellighausen",
  },
  {
    npa: "8566",
    canton: "TG",
    location: "Lippoldswilen",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Herrenhof",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Sonterswil",
  },
  {
    npa: "9562",
    canton: "TG",
    location: "Buch b. Märwil",
  },
  {
    npa: "9556",
    canton: "TG",
    location: "Zezikon",
  },
  {
    npa: "8512",
    canton: "TG",
    location: "Wetzikon TG",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Birwinken",
  },
  {
    npa: "6724",
    canton: "TI",
    location: "Largario",
  },
  {
    npa: "6723",
    canton: "TI",
    location: "Marolta",
  },
  {
    npa: "3995",
    canton: "VS",
    location: "Steinhaus",
  },
  {
    npa: "3983",
    canton: "VS",
    location: "Bister",
  },
  {
    npa: "3983",
    canton: "VS",
    location: "Filet",
  },
  {
    npa: "6212",
    canton: "LU",
    location: "Kaltbach",
  },
  {
    npa: "1653",
    canton: "FR",
    location: "Crésuz",
  },
  {
    npa: "2027",
    canton: "NE",
    location: "Fresens",
  },
  {
    npa: "2953",
    canton: "JU",
    location: "Pleujouse",
  },
  {
    npa: "3984",
    canton: "VS",
    location: "Fieschertal",
  },
  {
    npa: "6814",
    canton: "TI",
    location: "Cadempino",
  },
  {
    npa: "9470",
    canton: "SG",
    location: "Werdenberg",
  },
  {
    npa: "1626",
    canton: "FR",
    location: "Rueyres-Treyfayes",
  },
  {
    npa: "1884",
    canton: "VD",
    location: "Arveyes",
  },
  {
    npa: "6005",
    canton: "LU",
    location: "St. Niklausen LU",
  },
  {
    npa: "7226",
    canton: "GR",
    location: "Fajauna",
  },
  {
    npa: "8212",
    canton: "ZH",
    location: "Nohl",
  },
  {
    npa: "9122",
    canton: "SG",
    location: "Ebersol",
  },
  {
    npa: "6341",
    canton: "ZG",
    location: "Baar",
  },
  {
    npa: "3656",
    canton: "BE",
    location: "Ringoldswil",
  },
  {
    npa: "1195",
    canton: "VD",
    location: "Bursinel",
  },
  {
    npa: "6207",
    canton: "LU",
    location: "Nottwil Paraplegikerzentrum",
  },
  {
    npa: "2715",
    canton: "BE",
    location: "Châtelat",
  },
  {
    npa: "1091",
    canton: "VD",
    location: "Chenaux",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Marly Grand Pré",
  },
  {
    npa: "6363",
    canton: "NW",
    location: "Bürgenstock",
  },
  {
    npa: "1754",
    canton: "FR",
    location: "Avry-Centre FR",
  },
  {
    npa: "1260",
    canton: "VD",
    location: "Nyon car postal La Côte-Ju",
  },
  {
    npa: "3900",
    canton: "VS",
    location: "Brig Postauto Oberwallis",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld Postauto TG-SH",
  },
  {
    npa: "3415",
    canton: "BE",
    location: "Rüegsauschachen",
  },
  {
    npa: "8071",
    canton: "ZH",
    location: "Zürich CS PZ",
  },
  {
    npa: "3040",
    canton: "BE",
    location: "Bern Verarbeitungszentrum",
  },
  {
    npa: "8759",
    canton: "GL",
    location: "Netstal",
  },
  {
    npa: "9020",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "8268",
    canton: "TG",
    location: "Salenstein",
  },
  {
    npa: "1215",
    canton: "GE",
    location: "Genève ICC OLS",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion 4",
  },
  {
    npa: "9026",
    canton: "SG",
    location: "St. Gallen Künzler AG",
  },
  {
    npa: "1988",
    canton: "VS",
    location: "Thyon-Les Collons",
  },
  {
    npa: "8965",
    canton: "AG",
    location: "Berikon 2 Dorf",
  },
  {
    npa: "8965",
    canton: "AG",
    location: "Berikon-Widen",
  },
  {
    npa: "5046",
    canton: "AG",
    location: "Schmiedrued-Walde",
  },
  {
    npa: "6031",
    canton: "LU",
    location: "Ebikon",
  },
  {
    npa: "6061",
    canton: "OW",
    location: "Sarnen 1",
  },
  {
    npa: "8302",
    canton: "ZH",
    location: "Kloten Kaserne",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano Loreto",
  },
  {
    npa: "6907",
    canton: "TI",
    location: "Lugano 7 Loreto Caselle",
  },
  {
    npa: "1240",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "9401",
    canton: "SG",
    location: "Rorschach",
  },
  {
    npa: "5620",
    canton: "AG",
    location: "Bremgarten AG 1",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich Briefzentrum",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Sarnen 2 Büntenpark",
  },
  {
    npa: "9014",
    canton: "SG",
    location: "St. Gallen 14 Zustellung",
  },
  {
    npa: "8730",
    canton: "SG",
    location: "Uznach Postauto Linth-SZ-GL",
  },
  {
    npa: "9001",
    canton: "SG",
    location: "St. Gallen Postauto SG-Azel",
  },
  {
    npa: "4524",
    canton: "SO",
    location: "Balmberg",
  },
  {
    npa: "4524",
    canton: "SO",
    location: "Oberbalmberg",
  },
  {
    npa: "6602",
    canton: "TI",
    location: "Muralto",
  },
  {
    npa: "6677",
    canton: "TI",
    location: "Aurigeno",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano 2",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Locarno 4 Solduno",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano 6",
  },
  {
    npa: "2575",
    canton: "BE",
    location: "Gerolfingen",
  },
  {
    npa: "8260",
    canton: "SH",
    location: "Stein am Rhein 2 Stadt",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon Caserne",
  },
  {
    npa: "6810",
    canton: "TI",
    location: "Isone Caserma",
  },
  {
    npa: "1510",
    canton: "VD",
    location: "Moudon Caserne",
  },
  {
    npa: "1890",
    canton: "VS",
    location: "St-Maurice Caserne",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion Caserne",
  },
  {
    npa: "3380",
    canton: "BE",
    location: "Wangen a. A. Kaserne",
  },
  {
    npa: "1145",
    canton: "VD",
    location: "Bière Caserne",
  },
  {
    npa: "8086",
    canton: "ZH",
    location: "Zürich Reader's Digest",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle 1",
  },
  {
    npa: "6460",
    canton: "UR",
    location: "Altdorf UR 1",
  },
  {
    npa: "1660",
    canton: "VD",
    location: "La Lécherette",
  },
  {
    npa: "8085",
    canton: "ZH",
    location: "Zürich Versicherung",
  },
  {
    npa: "2075",
    canton: "NE",
    location: "Thielle",
  },
  {
    npa: "2075",
    canton: "NE",
    location: "Wavre",
  },
  {
    npa: "3632",
    canton: "BE",
    location: "Oberstocken",
  },
  {
    npa: "1762",
    canton: "FR",
    location: "Givisiez Dist Ba",
  },
  {
    npa: "1213",
    canton: "GE",
    location: "Petit-Lancy Distribution",
  },
  {
    npa: "1290",
    canton: "GE",
    location: "Versoix Distribution",
  },
  {
    npa: "2540",
    canton: "SO",
    location: "Grenchen 1 Zustellung",
  },
  {
    npa: "4702",
    canton: "SO",
    location: "Oensingen Zustellung",
  },
  {
    npa: "4313",
    canton: "AG",
    location: "Möhlin Zustellung",
  },
  {
    npa: "8957",
    canton: "AG",
    location: "Spreitenbach Zustellung",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen Zustellung",
  },
  {
    npa: "6020",
    canton: "LU",
    location: "Emmenbrücke 1 Zustellung",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens 2 Zustellung",
  },
  {
    npa: "6015",
    canton: "LU",
    location: "Luzern Reussbühl Zustellung",
  },
  {
    npa: "6130",
    canton: "LU",
    location: "Willisau Zustellung",
  },
  {
    npa: "1870",
    canton: "VS",
    location: "Monthey 1 Distribution",
  },
  {
    npa: "8910",
    canton: "ZH",
    location: "Affoltern am A. Zustellung",
  },
  {
    npa: "1350",
    canton: "VD",
    location: "Orbe Distribution",
  },
  {
    npa: "8700",
    canton: "ZH",
    location: "Küsnacht ZH Zustellung",
  },
  {
    npa: "1020",
    canton: "VD",
    location: "Renens VD 1 Distribution",
  },
  {
    npa: "8330",
    canton: "ZH",
    location: "Pfäffikon ZH Zustellung",
  },
  {
    npa: "3714",
    canton: "BE",
    location: "Frutigen Zustellung",
  },
  {
    npa: "3073",
    canton: "BE",
    location: "Gümligen Zustellung",
  },
  {
    npa: "8820",
    canton: "ZH",
    location: "Wädenswil Zustellung",
  },
  {
    npa: "8304",
    canton: "ZH",
    location: "Wallisellen Zustellung",
  },
  {
    npa: "8406",
    canton: "ZH",
    location: "Winterthur 6 Zustellung",
  },
  {
    npa: "3422",
    canton: "BE",
    location: "Kirchberg BE Zustellung",
  },
  {
    npa: "8408",
    canton: "ZH",
    location: "Winterthur 8 Zustellung",
  },
  {
    npa: "6850",
    canton: "TI",
    location: "Mendrisio Distribuzione",
  },
  {
    npa: "3110",
    canton: "BE",
    location: "Münsingen Zustellung",
  },
  {
    npa: "3076",
    canton: "BE",
    location: "Worb Zustellung",
  },
  {
    npa: "4710",
    canton: "SO",
    location: "Balsthal Zustellung",
  },
  {
    npa: "4563",
    canton: "SO",
    location: "Gerlafingen Zustellung",
  },
  {
    npa: "5200",
    canton: "AG",
    location: "Brugg AG 1 Zustellung",
  },
  {
    npa: "1274",
    canton: "VD",
    location: "Signy-Centre",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 31",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 5 Dépôt",
  },
  {
    npa: "1310",
    canton: "VD",
    location: "Daillens Dist Ba",
  },
  {
    npa: "1310",
    canton: "VD",
    location: "Daillens Centre Colis",
  },
  {
    npa: "1530",
    canton: "VD",
    location: "Payerne Distribution",
  },
  {
    npa: "8098",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "9442",
    canton: "SG",
    location: "Berneck Dist Ba",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Wagerswil",
  },
  {
    npa: "9565",
    canton: "TG",
    location: "Oberbussnang",
  },
  {
    npa: "8867",
    canton: "GL",
    location: "Niederurnen Dist Hub",
  },
  {
    npa: "5624",
    canton: "AG",
    location: "Waldhäusern AG",
  },
  {
    npa: "4410",
    canton: "BL",
    location: "Liestal Dist Ba",
  },
  {
    npa: "1912",
    canton: "VS",
    location: "Dugny (Leytron)",
  },
  {
    npa: "1933",
    canton: "VS",
    location: "Chamoille (Sembrancher)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Fornex (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Les Moulins VS (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Palasuit (Liddes)",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "Némiaz (Chamoson)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Luc (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "La Place (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Argnou (Ayent)",
  },
  {
    npa: "1969",
    canton: "VS",
    location: "Trogne (St-Martin)",
  },
  {
    npa: "1983",
    canton: "VS",
    location: "Lanna",
  },
  {
    npa: "1991",
    canton: "VS",
    location: "Misériez (Salins)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Condémines (Nendaz)",
  },
  {
    npa: "2063",
    canton: "NE",
    location: "Fenin",
  },
  {
    npa: "1873",
    canton: "VS",
    location: "Les Crosets",
  },
  {
    npa: "1095",
    canton: "VD",
    location: "Lutry Distribution",
  },
  {
    npa: "1052",
    canton: "VD",
    location: "Le Mont-sur-Lausanne Dist",
  },
  {
    npa: "2074",
    canton: "NE",
    location: "Marin-Epagnier Distribution",
  },
  {
    npa: "2520",
    canton: "BE",
    location: "La Neuveville Distribution",
  },
  {
    npa: "2720",
    canton: "BE",
    location: "Tramelan Distribution",
  },
  {
    npa: "1180",
    canton: "VD",
    location: "Rolle Distribution",
  },
  {
    npa: "5734",
    canton: "AG",
    location: "Reinach AG Zustellung",
  },
  {
    npa: "5630",
    canton: "AG",
    location: "Muri AG Zustellung",
  },
  {
    npa: "6280",
    canton: "LU",
    location: "Hochdorf Zustellung",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Reiden Zustellung",
  },
  {
    npa: "8260",
    canton: "SH",
    location: "Stein am Rhein 1 Zustellung",
  },
  {
    npa: "9450",
    canton: "SG",
    location: "Altstätten SG Zustellung",
  },
  {
    npa: "9424",
    canton: "SG",
    location: "Rheineck Zustellung",
  },
  {
    npa: "7310",
    canton: "SG",
    location: "Bad Ragaz Zustellung",
  },
  {
    npa: "4609",
    canton: "SO",
    location: "Olten Sonderdienste",
  },
  {
    npa: "1818",
    canton: "VD",
    location: "Montreux La Redoute",
  },
  {
    npa: "1787",
    canton: "FR",
    location: "Mur (Vully) FR",
  },
  {
    npa: "8530",
    canton: "TG",
    location: "Frauenfeld CALL",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens 2",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona VZ",
  },
  {
    npa: "8080",
    canton: "ZH",
    location: "Zürich PN",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 10",
  },
  {
    npa: "1440",
    canton: "VD",
    location: "Montagny-Chamard",
  },
  {
    npa: "2149",
    canton: "NE",
    location: "Brot-Dessous",
  },
  {
    npa: "9249",
    canton: "SG",
    location: "Oberstetten",
  },
  {
    npa: "1023",
    canton: "VD",
    location: "Crissier 1",
  },
  {
    npa: "2610",
    canton: "BE",
    location: "Les Pontins",
  },
  {
    npa: "1617",
    canton: "FR",
    location: "Tatroz",
  },
  {
    npa: "8075",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "2037",
    canton: "NE",
    location: "Montezillon",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Territet",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Crans-Montana 1",
  },
  {
    npa: "6939",
    canton: "TI",
    location: "Arosio-Mugena",
  },
  {
    npa: "2338",
    canton: "JU",
    location: "Muriaux",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano MailSource",
  },
  {
    npa: "1162",
    canton: "VD",
    location: "St-Prex Distribution",
  },
  {
    npa: "8615",
    canton: "ZH",
    location: "Freudwil",
  },
  {
    npa: "3661",
    canton: "BE",
    location: "Uetendorf Zustellung",
  },
  {
    npa: "6710",
    canton: "TI",
    location: "Biasca Distribuzione",
  },
  {
    npa: "1969",
    canton: "VS",
    location: "Eison (St-Martin)",
  },
  {
    npa: "4588",
    canton: "SO",
    location: "Brittern",
  },
  {
    npa: "5415",
    canton: "AG",
    location: "Rieden AG",
  },
  {
    npa: "3071",
    canton: "BE",
    location: "Ostermundigen Zustellung",
  },
  {
    npa: "4033",
    canton: "BS",
    location: "Basel 33 Novartis",
  },
  {
    npa: "8902",
    canton: "ZH",
    location: "Urdorf Dist Ba",
  },
  {
    npa: "8902",
    canton: "ZH",
    location: "Urdorf Sortierstelle",
  },
  {
    npa: "1816",
    canton: "VD",
    location: "Chailly-Montreux Dist",
  },
  {
    npa: "8240",
    canton: "SH",
    location: "Thayngen Zustellung",
  },
  {
    npa: "3715",
    canton: "BE",
    location: "Adelboden Zustellung",
  },
  {
    npa: "3780",
    canton: "BE",
    location: "Gstaad Zustellung",
  },
  {
    npa: "5242",
    canton: "AG",
    location: "Birr-Lupfig Zustellung",
  },
  {
    npa: "5726",
    canton: "AG",
    location: "Unterkulm Zustellung",
  },
  {
    npa: "1347",
    canton: "VD",
    location: "Le Sentier Distribution",
  },
  {
    npa: "8901",
    canton: "ZH",
    location: "Urdorf Tessi",
  },
  {
    npa: "8546",
    canton: "ZH",
    location: "Menzengrüt",
  },
  {
    npa: "2035",
    canton: "NE",
    location: "Corcelles NE Distribution",
  },
  {
    npa: "6162",
    canton: "LU",
    location: "Entlebuch Zustellung",
  },
  {
    npa: "6170",
    canton: "LU",
    location: "Schüpfheim Zustellung",
  },
  {
    npa: "7078",
    canton: "GR",
    location: "Lenzerheide Zustellung",
  },
  {
    npa: "4041",
    canton: "BS",
    location: "Basel UBS",
  },
  {
    npa: "8107",
    canton: "ZH",
    location: "Buchs ZH Zustellung",
  },
  {
    npa: "9602",
    canton: "SG",
    location: "Bazenheid Zustellung",
  },
  {
    npa: "1926",
    canton: "VS",
    location: "Fully Distribution",
  },
  {
    npa: "8543",
    canton: "ZH",
    location: "Gundetswil",
  },
  {
    npa: "1039",
    canton: "VD",
    location: "Cheseaux Polyval",
  },
  {
    npa: "6346",
    canton: "ZG",
    location: "Baar 3",
  },
  {
    npa: "6331",
    canton: "ZG",
    location: "Hünenberg Zustellung",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 73",
  },
  {
    npa: "4621",
    canton: "SO",
    location: "Härkingen Briefzentrum",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan Betriebszentrum",
  },
  {
    npa: "8096",
    canton: "ZH",
    location: "Zürich IBRS local",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg PostFinance Fil.",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf Filiale",
  },
  {
    npa: "4611",
    canton: "SO",
    location: "Härkingen Scanning-Center",
  },
  {
    npa: "8011",
    canton: "ZH",
    location: "Zürich Mülligen SC",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich BZ Annahme",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden SPI Logistics A",
  },
  {
    npa: "8543",
    canton: "ZH",
    location: "Kefikon ZH",
  },
  {
    npa: "8465",
    canton: "ZH",
    location: "Wildensbuch",
  },
  {
    npa: "8471",
    canton: "ZH",
    location: "Berg (Dägerlen)",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan BZ GKS",
  },
  {
    npa: "8412",
    canton: "ZH",
    location: "Riet (Neftenbach)",
  },
  {
    npa: "8074",
    canton: "ZH",
    location: "Zürich Voice Publishing",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Steinegg",
  },
  {
    npa: "9057",
    canton: "AI",
    location: "Wasserauen",
  },
  {
    npa: "6593",
    canton: "TI",
    location: "Cadenazzo Distribuzione",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil PL Hub SK Blitz",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil HUB",
  },
  {
    npa: "1356",
    canton: "VD",
    location: "La Russille",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden PostAuto AG",
  },
  {
    npa: "2362",
    canton: "JU",
    location: "Montfavergier",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens KC PG/GK 2",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen KC PK/GK 3",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich Helsana Annahme",
  },
  {
    npa: "1984",
    canton: "VS",
    location: "La Tour VS",
  },
  {
    npa: "3071",
    canton: "BE",
    location: "Ostermundigen KATA",
  },
  {
    npa: "8645",
    canton: "SG",
    location: "Jona Buech Gewerbe",
  },
  {
    npa: "4621",
    canton: "SO",
    location: "Härkingen BZ FP",
  },
  {
    npa: "8322",
    canton: "ZH",
    location: "Gündisau",
  },
  {
    npa: "3930",
    canton: "VS",
    location: "Visp Verkaufssupport",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens Clinique",
  },
  {
    npa: "7212",
    canton: "GR",
    location: "Seewis-Pardisla",
  },
  {
    npa: "8212",
    canton: "SH",
    location: "Neuhausen Rundbuck",
  },
  {
    npa: "3075",
    canton: "BE",
    location: "Vielbringen b. Worb",
  },
  {
    npa: "8574",
    canton: "TG",
    location: "Dettighofen (Lengwil)",
  },
  {
    npa: "1663",
    canton: "FR",
    location: "Gruyères Vieille Ville",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG LZB FP",
  },
  {
    npa: "8155",
    canton: "ZH",
    location: "Nassenwil",
  },
  {
    npa: "5225",
    canton: "AG",
    location: "Bözberg",
  },
  {
    npa: "1310",
    canton: "VD",
    location: "Daillens PL3",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln PL3",
  },
  {
    npa: "5612",
    canton: "AG",
    location: "Villmergen PL3",
  },
  {
    npa: "6593",
    canton: "TI",
    location: "Cadenazzo PL3",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Biessenhofen",
  },
  {
    npa: "6203",
    canton: "LU",
    location: "Sempach Bahnhof GK",
  },
  {
    npa: "8970",
    canton: "ZH",
    location: "Urdorf Exchange PL",
  },
  {
    npa: "6130",
    canton: "LU",
    location: "Willisau Rotmatt",
  },
  {
    npa: "5004",
    canton: "AG",
    location: "Aarau Tellistrasse",
  },
  {
    npa: "9423",
    canton: "SG",
    location: "Altenrhein Dorfstrasse",
  },
  {
    npa: "6002",
    canton: "LU",
    location: "Luzern SPS",
  },
  {
    npa: "6301",
    canton: "ZG",
    location: "Zug SPS",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 1 PostParc",
  },
  {
    npa: "9524",
    canton: "SG",
    location: "Zuzwil SG Gewerbestrasse",
  },
  {
    npa: "9450",
    canton: "SG",
    location: "Altstätten SG 2",
  },
  {
    npa: "3802",
    canton: "BE",
    location: "Interlaken Ost",
  },
  {
    npa: "8489",
    canton: "ZH",
    location: "Schalchen",
  },
  {
    npa: "3030",
    canton: "BE",
    location: "Bern Finform",
  },
  {
    npa: "3613",
    canton: "BE",
    location: "Steffisburg alte Bernstr",
  },
  {
    npa: "6032",
    canton: "LU",
    location: "Emmen Seetalstrasse",
  },
  {
    npa: "8025",
    canton: "ZH",
    location: "Zürich 25 Urania",
  },
  {
    npa: "7304",
    canton: "GR",
    location: "Maienfeld Stutz",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Weissenbühl",
  },
  {
    npa: "6331",
    canton: "ZG",
    location: "Hünenberg Bösch",
  },
  {
    npa: "8253",
    canton: "TG",
    location: "Diessenhofen Bahnhofstrasse",
  },
  {
    npa: "9443",
    canton: "SG",
    location: "Widnau Industriestrasse",
  },
  {
    npa: "8370",
    canton: "TG",
    location: "Sirnach Zustellung",
  },
  {
    npa: "8730",
    canton: "SG",
    location: "Uznach Zürcherstrasse",
  },
  {
    npa: "8600",
    canton: "ZH",
    location: "Dübendorf Wilstrasse",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens Luzernerstrasse",
  },
  {
    npa: "6998",
    canton: "TI",
    location: "Monteggio",
  },
  {
    npa: "6998",
    canton: "TI",
    location: "Monteggio 2",
  },
  {
    npa: "6048",
    canton: "LU",
    location: "Horw Gemeindehausplatz",
  },
  {
    npa: "6370",
    canton: "NW",
    location: "Stans Bahnhofplatz",
  },
  {
    npa: "8354",
    canton: "ZH",
    location: "Dickbuch",
  },
  {
    npa: "6233",
    canton: "LU",
    location: "Büron Industriestrasse",
  },
  {
    npa: "4450",
    canton: "BL",
    location: "Sissach Postgasse",
  },
  {
    npa: "8424",
    canton: "ZH",
    location: "Embrach Dorfstrasse",
  },
  {
    npa: "7000",
    canton: "GR",
    location: "Chur Postplatz",
  },
  {
    npa: "4605",
    canton: "SO",
    location: "Olten",
  },
  {
    npa: "5330",
    canton: "AG",
    location: "Bad Zurzach Hauptstrasse",
  },
  {
    npa: "6591",
    canton: "TI",
    location: "Cadenazzo CPR",
  },
  {
    npa: "6530",
    canton: "TI",
    location: "Cadenazzo CALL",
  },
  {
    npa: "8203",
    canton: "SH",
    location: "Schaffhausen Buchthalen",
  },
  {
    npa: "6033",
    canton: "LU",
    location: "Buchrain Schiltwald",
  },
  {
    npa: "6130",
    canton: "LU",
    location: "Willisau Dist Hub",
  },
  {
    npa: "6280",
    canton: "LU",
    location: "Hochdorf Hauptstrasse",
  },
  {
    npa: "2560",
    canton: "BE",
    location: "Nidau Weyermattstrasse",
  },
  {
    npa: "7200",
    canton: "GR",
    location: "Untervaz Sortierung",
  },
  {
    npa: "7200",
    canton: "GR",
    location: "Untervaz RPZ",
  },
  {
    npa: "7230",
    canton: "GR",
    location: "Untervaz CALL",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne Bahnhof/Gare GK",
  },
  {
    npa: "3090",
    canton: "BE",
    location: "Ostermundigen CALL",
  },
  {
    npa: "6043",
    canton: "LU",
    location: "Adligenswil Ebikonerstrasse",
  },
  {
    npa: "5070",
    canton: "AG",
    location: "Frick Widenplatz",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen Bahnhof",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil Baslerstrasse",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln Bahnhofstrasse",
  },
  {
    npa: "8036",
    canton: "ZH",
    location: "Zürich 36 Zustellung",
  },
  {
    npa: "8134",
    canton: "ZH",
    location: "Adliswil Poststrasse",
  },
  {
    npa: "3360",
    canton: "BE",
    location: "Herzogenbuchsee Lagerstr",
  },
  {
    npa: "4810",
    canton: "AG",
    location: "Zofingen PF",
  },
  {
    npa: "8018",
    canton: "ZH",
    location: "Zürich PF",
  },
  {
    npa: "8940",
    canton: "ZH",
    location: "Urdorf CIRCLE",
  },
  {
    npa: "4528",
    canton: "SO",
    location: "Zuchwil Hauptstrasse",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Mehlsecken Dörflimatte",
  },
  {
    npa: "4600",
    canton: "SO",
    location: "Olten Bahnhofstrasse",
  },
  {
    npa: "4132",
    canton: "BL",
    location: "Muttenz Hauptstrasse",
  },
  {
    npa: "1023",
    canton: "VD",
    location: "Crissier Chemin de Chisaz",
  },
  {
    npa: "8810",
    canton: "ZH",
    location: "Horgen Bahnhofstrasse",
  },
  {
    npa: "4057",
    canton: "BS",
    location: "Basel Hochbergerstrasse",
  },
  {
    npa: "7526",
    canton: "GR",
    location: "Chapella",
  },
  {
    npa: "1815",
    canton: "VD",
    location: "Clarens rue du Collège",
  },
  {
    npa: "1440",
    canton: "VD",
    location: "Montagny-Chamard En Chamard",
  },
  {
    npa: "8072",
    canton: "ZH",
    location: "Zürich CIRCLE",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 80",
  },
  {
    npa: "4802",
    canton: "AG",
    location: "Strengelbach Dist Hub",
  },
  {
    npa: "4014",
    canton: "BS",
    location: "Basel CIRCLE",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Marly Route du Châtelet",
  },
  {
    npa: "8130",
    canton: "ZH",
    location: "Rümlang CALL",
  },
  {
    npa: "8150",
    canton: "ZH",
    location: "Rümlang RPZ",
  },
  {
    npa: "8150",
    canton: "ZH",
    location: "Rümlang Sort",
  },
  {
    npa: "1180",
    canton: "VD",
    location: "Rolle Chemin du Famolens",
  },
  {
    npa: "5612",
    canton: "AG",
    location: "Villmergen Grenzweg",
  },
  {
    npa: "5030",
    canton: "AG",
    location: "Buchs AG Sortierung",
  },
  {
    npa: "5060",
    canton: "AG",
    location: "Buchs AG CIRCLE",
  },
  {
    npa: "4134",
    canton: "BL",
    location: "Pratteln Sortierung",
  },
  {
    npa: "3550",
    canton: "BE",
    location: "Langnau i. E. Bahnhofstr",
  },
  {
    npa: "1026",
    canton: "VD",
    location: "Echandens-Denges Rte Lonay",
  },
  {
    npa: "6850",
    canton: "TI",
    location: "Mendrisio Staz St.Franscini",
  },
  {
    npa: "1025",
    canton: "VD",
    location: "St-Sulpice VD",
  },
  {
    npa: "1040",
    canton: "VD",
    location: "Echallens",
  },
  {
    npa: "1035",
    canton: "VD",
    location: "Bournens",
  },
  {
    npa: "1042",
    canton: "VD",
    location: "Assens",
  },
  {
    npa: "1083",
    canton: "VD",
    location: "Mézières VD",
  },
  {
    npa: "1097",
    canton: "VD",
    location: "Riex",
  },
  {
    npa: "1098",
    canton: "VD",
    location: "Epesses",
  },
  {
    npa: "1061",
    canton: "VD",
    location: "Villars-Mendraz",
  },
  {
    npa: "1509",
    canton: "VD",
    location: "Vucherens",
  },
  {
    npa: "1110",
    canton: "VD",
    location: "Morges",
  },
  {
    npa: "1131",
    canton: "VD",
    location: "Tolochenaz",
  },
  {
    npa: "1125",
    canton: "VD",
    location: "Monnaz",
  },
  {
    npa: "1127",
    canton: "VD",
    location: "Clarmont",
  },
  {
    npa: "1114",
    canton: "VD",
    location: "Colombier VD",
  },
  {
    npa: "1136",
    canton: "VD",
    location: "Bussy-Chardonney",
  },
  {
    npa: "1168",
    canton: "VD",
    location: "Villars-sous-Yens",
  },
  {
    npa: "1147",
    canton: "VD",
    location: "Montricher",
  },
  {
    npa: "1143",
    canton: "VD",
    location: "Apples",
  },
  {
    npa: "1163",
    canton: "VD",
    location: "Etoy",
  },
  {
    npa: "1174",
    canton: "VD",
    location: "Montherod",
  },
  {
    npa: "1186",
    canton: "VD",
    location: "Essertines-sur-Rolle",
  },
  {
    npa: "1187",
    canton: "VD",
    location: "St-Oyens",
  },
  {
    npa: "1189",
    canton: "VD",
    location: "Saubraz",
  },
  {
    npa: "1196",
    canton: "VD",
    location: "Gland",
  },
  {
    npa: "1197",
    canton: "VD",
    location: "Prangins",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 1",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 6",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 8",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 12",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 26",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève Centre logistique",
  },
  {
    npa: "1208",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 12",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 20",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 22",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 23",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 28",
  },
  {
    npa: "1217",
    canton: "GE",
    location: "Meyrin 1",
  },
  {
    npa: "1217",
    canton: "GE",
    location: "Meyrin 2",
  },
  {
    npa: "1219",
    canton: "GE",
    location: "Aïre",
  },
  {
    npa: "1220",
    canton: "GE",
    location: "Les Avanchets",
  },
  {
    npa: "1222",
    canton: "GE",
    location: "Vésenaz",
  },
  {
    npa: "1223",
    canton: "GE",
    location: "Cologny",
  },
  {
    npa: "1245",
    canton: "GE",
    location: "Collonge-Bellerive",
  },
  {
    npa: "1247",
    canton: "GE",
    location: "Anières",
  },
  {
    npa: "1256",
    canton: "GE",
    location: "Troinex",
  },
  {
    npa: "1274",
    canton: "VD",
    location: "Grens",
  },
  {
    npa: "1270",
    canton: "VD",
    location: "Trélex",
  },
  {
    npa: "1262",
    canton: "VD",
    location: "Eysins",
  },
  {
    npa: "1264",
    canton: "VD",
    location: "St-Cergue",
  },
  {
    npa: "1268",
    canton: "VD",
    location: "Begnins",
  },
  {
    npa: "1287",
    canton: "GE",
    location: "Laconnex",
  },
  {
    npa: "1290",
    canton: "GE",
    location: "Versoix",
  },
  {
    npa: "1296",
    canton: "VD",
    location: "Coppet",
  },
  {
    npa: "1302",
    canton: "VD",
    location: "Vufflens-la-Ville",
  },
  {
    npa: "1305",
    canton: "VD",
    location: "Penthalaz",
  },
  {
    npa: "1317",
    canton: "VD",
    location: "Orny",
  },
  {
    npa: "1316",
    canton: "VD",
    location: "Chevilly",
  },
  {
    npa: "1323",
    canton: "VD",
    location: "Romainmôtier",
  },
  {
    npa: "1325",
    canton: "VD",
    location: "Vaulion",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "La Praz",
  },
  {
    npa: "1357",
    canton: "VD",
    location: "Lignerolle",
  },
  {
    npa: "1416",
    canton: "VD",
    location: "Pailly",
  },
  {
    npa: "1421",
    canton: "VD",
    location: "Fontaines-sur-Grandson",
  },
  {
    npa: "1405",
    canton: "VD",
    location: "Pomy",
  },
  {
    npa: "1409",
    canton: "VD",
    location: "Chanéaz",
  },
  {
    npa: "1412",
    canton: "VD",
    location: "Valeyres-sous-Ursins",
  },
  {
    npa: "1046",
    canton: "VD",
    location: "Rueyres",
  },
  {
    npa: "1404",
    canton: "VD",
    location: "Cuarny",
  },
  {
    npa: "1425",
    canton: "VD",
    location: "Onnens VD",
  },
  {
    npa: "1426",
    canton: "VD",
    location: "Corcelles-près-Concise",
  },
  {
    npa: "1436",
    canton: "VD",
    location: "Treycovagnes",
  },
  {
    npa: "1450",
    canton: "VD",
    location: "La Sagne (Ste-Croix)",
  },
  {
    npa: "1453",
    canton: "VD",
    location: "Bullet",
  },
  {
    npa: "1463",
    canton: "VD",
    location: "Rovray",
  },
  {
    npa: "1527",
    canton: "FR",
    location: "Villeneuve FR",
  },
  {
    npa: "1530",
    canton: "VD",
    location: "Payerne",
  },
  {
    npa: "1534",
    canton: "VD",
    location: "Sassel",
  },
  {
    npa: "1475",
    canton: "FR",
    location: "Autavaux",
  },
  {
    npa: "1584",
    canton: "VD",
    location: "Villars-le-Grand",
  },
  {
    npa: "1587",
    canton: "VD",
    location: "Constantine",
  },
  {
    npa: "1618",
    canton: "FR",
    location: "Châtel-St-Denis",
  },
  {
    npa: "1643",
    canton: "FR",
    location: "Gumefens",
  },
  {
    npa: "1647",
    canton: "FR",
    location: "Corbières",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 1 Dépôt",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 3",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Ouchy",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Bellevaux",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 14",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Grangette",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Bourdonnette",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 26",
  },
  {
    npa: "1001",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1005",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1011",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1020",
    canton: "VD",
    location: "Renens VD",
  },
  {
    npa: "1023",
    canton: "VD",
    location: "Crissier",
  },
  {
    npa: "1028",
    canton: "VD",
    location: "Préverenges",
  },
  {
    npa: "1037",
    canton: "VD",
    location: "Etagnières",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Dommartin",
  },
  {
    npa: "1063",
    canton: "VD",
    location: "Boulens",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Poliez-Pittet",
  },
  {
    npa: "1054",
    canton: "VD",
    location: "Morrens VD",
  },
  {
    npa: "1073",
    canton: "VD",
    location: "Savigny",
  },
  {
    npa: "1093",
    canton: "VD",
    location: "La Conversion",
  },
  {
    npa: "1095",
    canton: "VD",
    location: "Lutry",
  },
  {
    npa: "1110",
    canton: "VD",
    location: "Morges 2",
  },
  {
    npa: "1176",
    canton: "VD",
    location: "St-Livres",
  },
  {
    npa: "1180",
    canton: "VD",
    location: "Rolle",
  },
  {
    npa: "1212",
    canton: "GE",
    location: "Grand-Lancy",
  },
  {
    npa: "1214",
    canton: "GE",
    location: "Vernier",
  },
  {
    npa: "1215",
    canton: "GE",
    location: "Genève 15 Aéroport Dépôt",
  },
  {
    npa: "1216",
    canton: "GE",
    location: "Cointrin",
  },
  {
    npa: "1244",
    canton: "GE",
    location: "Choulex",
  },
  {
    npa: "1252",
    canton: "GE",
    location: "Meinier",
  },
  {
    npa: "1255",
    canton: "GE",
    location: "Veyrier",
  },
  {
    npa: "1257",
    canton: "GE",
    location: "La Croix-de-Rozon",
  },
  {
    npa: "1258",
    canton: "GE",
    location: "Perly",
  },
  {
    npa: "1278",
    canton: "VD",
    location: "La Rippe",
  },
  {
    npa: "1272",
    canton: "VD",
    location: "Genolier",
  },
  {
    npa: "1188",
    canton: "VD",
    location: "St-George",
  },
  {
    npa: "1337",
    canton: "VD",
    location: "Vallorbe",
  },
  {
    npa: "1348",
    canton: "VD",
    location: "Le Brassus",
  },
  {
    npa: "1303",
    canton: "VD",
    location: "Penthaz",
  },
  {
    npa: "1308",
    canton: "VD",
    location: "La Chaux (Cossonay)",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Moiry VD",
  },
  {
    npa: "1322",
    canton: "VD",
    location: "Croy",
  },
  {
    npa: "1358",
    canton: "VD",
    location: "Valeyres-sous-Rances",
  },
  {
    npa: "1373",
    canton: "VD",
    location: "Chavornay",
  },
  {
    npa: "1372",
    canton: "VD",
    location: "Bavois",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon-les-Bains 1",
  },
  {
    npa: "1401",
    canton: "VD",
    location: "Yverdon-les-Bains",
  },
  {
    npa: "1431",
    canton: "VD",
    location: "Vugelles-La Mothe",
  },
  {
    npa: "1423",
    canton: "VD",
    location: "Fontanezier",
  },
  {
    npa: "1441",
    canton: "VD",
    location: "Valeyres-sous-Montagny",
  },
  {
    npa: "1442",
    canton: "VD",
    location: "Montagny-près-Yverdon",
  },
  {
    npa: "1464",
    canton: "VD",
    location: "Chavannes-le-Chêne",
  },
  {
    npa: "1537",
    canton: "VD",
    location: "Champtauroz",
  },
  {
    npa: "1522",
    canton: "VD",
    location: "Oulens-sur-Lucens",
  },
  {
    npa: "1515",
    canton: "VD",
    location: "Villars-le-Comte",
  },
  {
    npa: "1523",
    canton: "VD",
    location: "Granges-près-Marnand",
  },
  {
    npa: "1529",
    canton: "FR",
    location: "Cheiry",
  },
  {
    npa: "1542",
    canton: "FR",
    location: "Rueyres-les-Prés",
  },
  {
    npa: "1544",
    canton: "FR",
    location: "Gletterens",
  },
  {
    npa: "1554",
    canton: "VD",
    location: "Sédeilles",
  },
  {
    npa: "1555",
    canton: "VD",
    location: "Villarzel",
  },
  {
    npa: "1588",
    canton: "VD",
    location: "Cudrefin",
  },
  {
    npa: "1090",
    canton: "VD",
    location: "La Croix (Lutry)",
  },
  {
    npa: "1091",
    canton: "VD",
    location: "Grandvaux",
  },
  {
    npa: "1070",
    canton: "VD",
    location: "Puidoux",
  },
  {
    npa: "1072",
    canton: "VD",
    location: "Forel (Lavaux)",
  },
  {
    npa: "1614",
    canton: "FR",
    location: "Granges (Veveyse)",
  },
  {
    npa: "1656",
    canton: "FR",
    location: "Jaun",
  },
  {
    npa: "1657",
    canton: "BE",
    location: "Abländschen",
  },
  {
    npa: "1651",
    canton: "FR",
    location: "Villarvolard",
  },
  {
    npa: "1663",
    canton: "FR",
    location: "Moléson-sur-Gruyères",
  },
  {
    npa: "1633",
    canton: "FR",
    location: "Marsens",
  },
  {
    npa: "1635",
    canton: "FR",
    location: "La Tour-de-Trême",
  },
  {
    npa: "1665",
    canton: "FR",
    location: "Estavannens",
  },
  {
    npa: "1610",
    canton: "VD",
    location: "Oron-la-Ville",
  },
  {
    npa: "1670",
    canton: "FR",
    location: "Ursy",
  },
  {
    npa: "1681",
    canton: "FR",
    location: "Billens",
  },
  {
    npa: "1694",
    canton: "FR",
    location: "Orsonnens",
  },
  {
    npa: "1695",
    canton: "FR",
    location: "Villarlod",
  },
  {
    npa: "1685",
    canton: "FR",
    location: "Villariaz",
  },
  {
    npa: "1689",
    canton: "FR",
    location: "Le Châtelard-près-Romont",
  },
  {
    npa: "1626",
    canton: "FR",
    location: "Romanens",
  },
  {
    npa: "1612",
    canton: "VD",
    location: "Ecoteaux",
  },
  {
    npa: "1613",
    canton: "VD",
    location: "Maracon",
  },
  {
    npa: "1611",
    canton: "FR",
    location: "Le Crêt-près-Semsales",
  },
  {
    npa: "1699",
    canton: "FR",
    location: "Bouloz",
  },
  {
    npa: "1702",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg 1 Distribution",
  },
  {
    npa: "1701",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1738",
    canton: "BE",
    location: "Sangernboden",
  },
  {
    npa: "1727",
    canton: "FR",
    location: "Corpataux",
  },
  {
    npa: "1712",
    canton: "FR",
    location: "Tafers",
  },
  {
    npa: "1714",
    canton: "FR",
    location: "Heitenried",
  },
  {
    npa: "1715",
    canton: "FR",
    location: "Alterswil FR",
  },
  {
    npa: "1716",
    canton: "FR",
    location: "Plaffeien",
  },
  {
    npa: "1718",
    canton: "FR",
    location: "Rechthalten",
  },
  {
    npa: "1747",
    canton: "FR",
    location: "Corserey",
  },
  {
    npa: "1748",
    canton: "FR",
    location: "Torny-le-Grand",
  },
  {
    npa: "1741",
    canton: "FR",
    location: "Cottens FR",
  },
  {
    npa: "1744",
    canton: "FR",
    location: "Chénens",
  },
  {
    npa: "1745",
    canton: "FR",
    location: "Lentigny",
  },
  {
    npa: "1742",
    canton: "FR",
    location: "Autigny",
  },
  {
    npa: "1695",
    canton: "FR",
    location: "Estavayer-le-Gibloux",
  },
  {
    npa: "1691",
    canton: "FR",
    location: "Villarimboud",
  },
  {
    npa: "1752",
    canton: "FR",
    location: "Villars-sur-Glâne Dailles",
  },
  {
    npa: "1757",
    canton: "FR",
    location: "Noréaz",
  },
  {
    npa: "1690",
    canton: "FR",
    location: "Villaz-St-Pierre",
  },
  {
    npa: "1762",
    canton: "FR",
    location: "Givisiez",
  },
  {
    npa: "1796",
    canton: "FR",
    location: "Courgevaux",
  },
  {
    npa: "1791",
    canton: "FR",
    location: "Courtaman",
  },
  {
    npa: "1788",
    canton: "FR",
    location: "Praz (Vully)",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "Belfaux",
  },
  {
    npa: "1784",
    canton: "FR",
    location: "Courtepin",
  },
  {
    npa: "1802",
    canton: "VD",
    location: "Corseaux",
  },
  {
    npa: "1804",
    canton: "VD",
    location: "Corsier-sur-Vevey",
  },
  {
    npa: "1071",
    canton: "VD",
    location: "Rivaz",
  },
  {
    npa: "1815",
    canton: "VD",
    location: "Clarens",
  },
  {
    npa: "1816",
    canton: "VD",
    location: "Chailly-Montreux",
  },
  {
    npa: "1822",
    canton: "VD",
    location: "Chernex",
  },
  {
    npa: "1823",
    canton: "VD",
    location: "Glion",
  },
  {
    npa: "1658",
    canton: "VD",
    location: "La Tine",
  },
  {
    npa: "1832",
    canton: "VD",
    location: "Chamby",
  },
  {
    npa: "1669",
    canton: "FR",
    location: "Montbovon",
  },
  {
    npa: "1845",
    canton: "VD",
    location: "Noville",
  },
  {
    npa: "1862",
    canton: "VD",
    location: "La Comballaz",
  },
  {
    npa: "1884",
    canton: "VD",
    location: "Huémoz",
  },
  {
    npa: "1867",
    canton: "VD",
    location: "Panex",
  },
  {
    npa: "1872",
    canton: "VS",
    location: "Troistorrents",
  },
  {
    npa: "1873",
    canton: "VS",
    location: "Val-d'Illiez",
  },
  {
    npa: "1875",
    canton: "VS",
    location: "Morgins",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Bex",
  },
  {
    npa: "1896",
    canton: "VS",
    location: "Vouvry",
  },
  {
    npa: "1898",
    canton: "VS",
    location: "St-Gingolph",
  },
  {
    npa: "1905",
    canton: "VS",
    location: "Dorénaz",
  },
  {
    npa: "1907",
    canton: "VS",
    location: "Saxon",
  },
  {
    npa: "1908",
    canton: "VS",
    location: "Riddes",
  },
  {
    npa: "1911",
    canton: "VS",
    location: "Ovronnaz",
  },
  {
    npa: "1913",
    canton: "VS",
    location: "Saillon",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "St-Pierre-de-Clages",
  },
  {
    npa: "1957",
    canton: "VS",
    location: "Ardon",
  },
  {
    npa: "1925",
    canton: "VS",
    location: "Le Châtelard VS",
  },
  {
    npa: "1928",
    canton: "VS",
    location: "Ravoire",
  },
  {
    npa: "1922",
    canton: "VS",
    location: "Salvan",
  },
  {
    npa: "1922",
    canton: "VS",
    location: "Les Granges (Salvan)",
  },
  {
    npa: "1941",
    canton: "VS",
    location: "Vollèges",
  },
  {
    npa: "1948",
    canton: "VS",
    location: "Lourtier",
  },
  {
    npa: "1948",
    canton: "VS",
    location: "Sarreyer",
  },
  {
    npa: "1944",
    canton: "VS",
    location: "La Fouly VS",
  },
  {
    npa: "1933",
    canton: "VS",
    location: "Sembrancher",
  },
  {
    npa: "1934",
    canton: "VS",
    location: "Le Châble VS",
  },
  {
    npa: "1937",
    canton: "VS",
    location: "Orsières",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion 1",
  },
  {
    npa: "1951",
    canton: "VS",
    location: "Sion",
  },
  {
    npa: "1975",
    canton: "VS",
    location: "St-Séverin",
  },
  {
    npa: "1976",
    canton: "VS",
    location: "Aven",
  },
  {
    npa: "1968",
    canton: "VS",
    location: "Mase",
  },
  {
    npa: "1969",
    canton: "VS",
    location: "St-Martin VS",
  },
  {
    npa: "1981",
    canton: "VS",
    location: "Vex",
  },
  {
    npa: "1986",
    canton: "VS",
    location: "Arolla",
  },
  {
    npa: "1997",
    canton: "VS",
    location: "Siviez (Nendaz)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Fey (Nendaz)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Beuson (Nendaz)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Basse-Nendaz",
  },
  {
    npa: "1983",
    canton: "VS",
    location: "Evolène",
  },
  {
    npa: "1972",
    canton: "VS",
    location: "Anzère",
  },
  {
    npa: "2000",
    canton: "NE",
    location: "Neuchâtel 2",
  },
  {
    npa: "2002",
    canton: "NE",
    location: "Neuchâtel 2",
  },
  {
    npa: "2003",
    canton: "NE",
    location: "Neuchâtel 3",
  },
  {
    npa: "2013",
    canton: "NE",
    location: "Colombier NE",
  },
  {
    npa: "2016",
    canton: "NE",
    location: "Cortaillod",
  },
  {
    npa: "2017",
    canton: "NE",
    location: "Boudry",
  },
  {
    npa: "2025",
    canton: "NE",
    location: "Chez-le-Bart",
  },
  {
    npa: "2036",
    canton: "NE",
    location: "Cormondrèche",
  },
  {
    npa: "2052",
    canton: "NE",
    location: "Fontainemelon",
  },
  {
    npa: "2056",
    canton: "NE",
    location: "Dombresson",
  },
  {
    npa: "2057",
    canton: "NE",
    location: "Villiers",
  },
  {
    npa: "2058",
    canton: "NE",
    location: "Le Pâquier NE",
  },
  {
    npa: "2074",
    canton: "NE",
    location: "Marin-Epagnier",
  },
  {
    npa: "2087",
    canton: "NE",
    location: "Cornaux NE",
  },
  {
    npa: "2105",
    canton: "NE",
    location: "Travers",
  },
  {
    npa: "2117",
    canton: "NE",
    location: "La Côte-aux-Fées",
  },
  {
    npa: "2116",
    canton: "NE",
    location: "Mont-de-Buttes",
  },
  {
    npa: "2019",
    canton: "NE",
    location: "Chambrelien",
  },
  {
    npa: "2207",
    canton: "NE",
    location: "Coffrane",
  },
  {
    npa: "2208",
    canton: "NE",
    location: "Les Hauts-Geneveys",
  },
  {
    npa: "2303",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "2300",
    canton: "NE",
    location: "La Chaux-de-Fonds 1 Dist",
  },
  {
    npa: "2316",
    canton: "NE",
    location: "Les Ponts-de-Martel",
  },
  {
    npa: "2322",
    canton: "NE",
    location: "Le Crêt-du-Locle",
  },
  {
    npa: "2333",
    canton: "BE",
    location: "La Ferrière",
  },
  {
    npa: "2416",
    canton: "NE",
    location: "Les Brenets",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 3",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 8",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne Zustellung",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 1 Annahme",
  },
  {
    npa: "2502",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2504",
    canton: "BE",
    location: "Biel/Bienne",
  },
  {
    npa: "2512",
    canton: "BE",
    location: "Tüscherz-Alfermée",
  },
  {
    npa: "2513",
    canton: "BE",
    location: "Twann",
  },
  {
    npa: "2516",
    canton: "BE",
    location: "Lamboing",
  },
  {
    npa: "2520",
    canton: "BE",
    location: "La Neuveville",
  },
  {
    npa: "2523",
    canton: "NE",
    location: "Lignières",
  },
  {
    npa: "2532",
    canton: "BE",
    location: "Magglingen/Macolin",
  },
  {
    npa: "2534",
    canton: "BE",
    location: "Orvin",
  },
  {
    npa: "2534",
    canton: "BE",
    location: "Les Prés-d'Orvin",
  },
  {
    npa: "2535",
    canton: "BE",
    location: "Frinvillier",
  },
  {
    npa: "2536",
    canton: "BE",
    location: "Plagne",
  },
  {
    npa: "2538",
    canton: "BE",
    location: "Romont BE",
  },
  {
    npa: "2545",
    canton: "SO",
    location: "Selzach",
  },
  {
    npa: "2555",
    canton: "BE",
    location: "Brügg BE",
  },
  {
    npa: "2556",
    canton: "BE",
    location: "Scheuren",
  },
  {
    npa: "2557",
    canton: "BE",
    location: "Studen BE",
  },
  {
    npa: "2562",
    canton: "BE",
    location: "Port",
  },
  {
    npa: "2572",
    canton: "BE",
    location: "Sutz",
  },
  {
    npa: "2575",
    canton: "BE",
    location: "Täuffelen",
  },
  {
    npa: "2577",
    canton: "BE",
    location: "Siselen BE",
  },
  {
    npa: "2610",
    canton: "BE",
    location: "St-Imier",
  },
  {
    npa: "2610",
    canton: "BE",
    location: "Mont-Soleil",
  },
  {
    npa: "2612",
    canton: "BE",
    location: "Cormoret",
  },
  {
    npa: "2613",
    canton: "BE",
    location: "Villeret",
  },
  {
    npa: "2616",
    canton: "BE",
    location: "Renan BE",
  },
  {
    npa: "2710",
    canton: "BE",
    location: "Tavannes",
  },
  {
    npa: "2712",
    canton: "BE",
    location: "Le Fuet",
  },
  {
    npa: "2714",
    canton: "JU",
    location: "Le Prédame",
  },
  {
    npa: "2713",
    canton: "BE",
    location: "Bellelay",
  },
  {
    npa: "2714",
    canton: "JU",
    location: "Les Genevez JU",
  },
  {
    npa: "2720",
    canton: "BE",
    location: "Tramelan",
  },
  {
    npa: "2723",
    canton: "BE",
    location: "Mont-Tramelan",
  },
  {
    npa: "2340",
    canton: "JU",
    location: "Le Noirmont",
  },
  {
    npa: "2353",
    canton: "JU",
    location: "Les Pommerats",
  },
  {
    npa: "2354",
    canton: "JU",
    location: "Goumois",
  },
  {
    npa: "2732",
    canton: "BE",
    location: "Loveresse",
  },
  {
    npa: "2742",
    canton: "BE",
    location: "Perrefitte",
  },
  {
    npa: "2743",
    canton: "BE",
    location: "Eschert",
  },
  {
    npa: "2746",
    canton: "BE",
    location: "Crémines",
  },
  {
    npa: "2747",
    canton: "BE",
    location: "Corcelles BE",
  },
  {
    npa: "2762",
    canton: "BE",
    location: "Roches BE",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont 1 Distribution",
  },
  {
    npa: "2802",
    canton: "JU",
    location: "Develier",
  },
  {
    npa: "2826",
    canton: "JU",
    location: "Corban",
  },
  {
    npa: "2827",
    canton: "JU",
    location: "Mervelier",
  },
  {
    npa: "2852",
    canton: "JU",
    location: "Courtételle",
  },
  {
    npa: "2854",
    canton: "JU",
    location: "Bassecourt",
  },
  {
    npa: "2863",
    canton: "JU",
    location: "Undervelier",
  },
  {
    npa: "2888",
    canton: "JU",
    location: "Seleute",
  },
  {
    npa: "2889",
    canton: "JU",
    location: "Ocourt",
  },
  {
    npa: "2900",
    canton: "JU",
    location: "Porrentruy",
  },
  {
    npa: "2900",
    canton: "JU",
    location: "Porrentruy 2",
  },
  {
    npa: "2902",
    canton: "JU",
    location: "Fontenais",
  },
  {
    npa: "2908",
    canton: "JU",
    location: "Grandfontaine",
  },
  {
    npa: "2912",
    canton: "JU",
    location: "Roche-d'Or",
  },
  {
    npa: "2915",
    canton: "JU",
    location: "Bure",
  },
  {
    npa: "2922",
    canton: "JU",
    location: "Courchavon",
  },
  {
    npa: "2923",
    canton: "JU",
    location: "Courtemaîche",
  },
  {
    npa: "2943",
    canton: "JU",
    location: "Vendlincourt",
  },
  {
    npa: "2946",
    canton: "JU",
    location: "Miécourt",
  },
  {
    npa: "2947",
    canton: "JU",
    location: "Charmoille",
  },
  {
    npa: "2953",
    canton: "JU",
    location: "Fregiécourt-Pleujouse",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 8",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Fischermätteli",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Spitalacker",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Freudenbergerplatz",
  },
  {
    npa: "3002",
    canton: "BE",
    location: "Bern PostFinance",
  },
  {
    npa: "3004",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3006",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3008",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3012",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3013",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3015",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3019",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3020",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3027",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3095",
    canton: "BE",
    location: "Spiegel b. Bern",
  },
  {
    npa: "3034",
    canton: "BE",
    location: "Murzelen",
  },
  {
    npa: "3037",
    canton: "BE",
    location: "Herrenschwanden",
  },
  {
    npa: "3049",
    canton: "BE",
    location: "Säriswil",
  },
  {
    npa: "3045",
    canton: "BE",
    location: "Meikirch",
  },
  {
    npa: "3047",
    canton: "BE",
    location: "Bremgarten b. Bern",
  },
  {
    npa: "3052",
    canton: "BE",
    location: "Zollikofen",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Münchenbuchsee",
  },
  {
    npa: "3065",
    canton: "BE",
    location: "Bolligen",
  },
  {
    npa: "3065",
    canton: "BE",
    location: "Bolligen Dorf",
  },
  {
    npa: "3072",
    canton: "BE",
    location: "Ostermundigen",
  },
  {
    npa: "3074",
    canton: "BE",
    location: "Muri b. Bern",
  },
  {
    npa: "3076",
    canton: "BE",
    location: "Worb",
  },
  {
    npa: "3083",
    canton: "BE",
    location: "Trimstein",
  },
  {
    npa: "3084",
    canton: "BE",
    location: "Wabern",
  },
  {
    npa: "3087",
    canton: "BE",
    location: "Niedermuhlern",
  },
  {
    npa: "3088",
    canton: "BE",
    location: "Rüeggisberg",
  },
  {
    npa: "3097",
    canton: "BE",
    location: "Liebefeld",
  },
  {
    npa: "3098",
    canton: "BE",
    location: "Köniz",
  },
  {
    npa: "3099",
    canton: "BE",
    location: "Rüti b. Riggisberg",
  },
  {
    npa: "3123",
    canton: "BE",
    location: "Belp",
  },
  {
    npa: "3127",
    canton: "BE",
    location: "Mühlethurnen",
  },
  {
    npa: "3661",
    canton: "BE",
    location: "Uetendorf",
  },
  {
    npa: "3155",
    canton: "BE",
    location: "Helgisried-Rohrbach",
  },
  {
    npa: "3157",
    canton: "BE",
    location: "Milken",
  },
  {
    npa: "3158",
    canton: "BE",
    location: "Guggisberg",
  },
  {
    npa: "3159",
    canton: "BE",
    location: "Riedstätt",
  },
  {
    npa: "3179",
    canton: "BE",
    location: "Kriechenwil",
  },
  {
    npa: "3183",
    canton: "BE",
    location: "Albligen",
  },
  {
    npa: "3184",
    canton: "FR",
    location: "Wünnewil",
  },
  {
    npa: "3186",
    canton: "FR",
    location: "Düdingen",
  },
  {
    npa: "3202",
    canton: "BE",
    location: "Frauenkappelen",
  },
  {
    npa: "3207",
    canton: "BE",
    location: "Wileroltigen",
  },
  {
    npa: "3208",
    canton: "BE",
    location: "Gurbrü",
  },
  {
    npa: "3216",
    canton: "FR",
    location: "Ried b. Kerzers",
  },
  {
    npa: "3215",
    canton: "FR",
    location: "Gempenach",
  },
  {
    npa: "3214",
    canton: "FR",
    location: "Ulmiz",
  },
  {
    npa: "3213",
    canton: "FR",
    location: "Liebistorf",
  },
  {
    npa: "3226",
    canton: "BE",
    location: "Treiten",
  },
  {
    npa: "3235",
    canton: "BE",
    location: "Erlach",
  },
  {
    npa: "3250",
    canton: "BE",
    location: "Lyss",
  },
  {
    npa: "3251",
    canton: "BE",
    location: "Wengi b. Büren",
  },
  {
    npa: "3252",
    canton: "BE",
    location: "Worben",
  },
  {
    npa: "3253",
    canton: "SO",
    location: "Schnottwil",
  },
  {
    npa: "3255",
    canton: "BE",
    location: "Rapperswil BE",
  },
  {
    npa: "3257",
    canton: "BE",
    location: "Grossaffoltern",
  },
  {
    npa: "3264",
    canton: "BE",
    location: "Diessbach b. Büren",
  },
  {
    npa: "3267",
    canton: "BE",
    location: "Seedorf BE",
  },
  {
    npa: "3271",
    canton: "BE",
    location: "Radelfingen b. Aarberg",
  },
  {
    npa: "3280",
    canton: "FR",
    location: "Murten",
  },
  {
    npa: "3283",
    canton: "BE",
    location: "Kallnach",
  },
  {
    npa: "3284",
    canton: "FR",
    location: "Fräschels",
  },
  {
    npa: "3296",
    canton: "BE",
    location: "Arch",
  },
  {
    npa: "3298",
    canton: "BE",
    location: "Oberwil b. Büren",
  },
  {
    npa: "3303",
    canton: "BE",
    location: "Jegenstorf",
  },
  {
    npa: "3308",
    canton: "BE",
    location: "Grafenried",
  },
  {
    npa: "3321",
    canton: "BE",
    location: "Schönbühl Einkaufszentrum",
  },
  {
    npa: "3323",
    canton: "BE",
    location: "Bäriswil BE",
  },
  {
    npa: "3326",
    canton: "BE",
    location: "Krauchthal",
  },
  {
    npa: "3307",
    canton: "SO",
    location: "Brunnenthal",
  },
  {
    npa: "3317",
    canton: "BE",
    location: "Mülchi",
  },
  {
    npa: "3429",
    canton: "BE",
    location: "Höchstetten-Hellsau",
  },
  {
    npa: "3376",
    canton: "BE",
    location: "Graben",
  },
  {
    npa: "3415",
    canton: "BE",
    location: "Hasle-Rüegsau",
  },
  {
    npa: "3422",
    canton: "BE",
    location: "Alchenflüh",
  },
  {
    npa: "3424",
    canton: "BE",
    location: "Niederösch",
  },
  {
    npa: "3425",
    canton: "BE",
    location: "Koppigen",
  },
  {
    npa: "3426",
    canton: "BE",
    location: "Aefligen",
  },
  {
    npa: "3428",
    canton: "BE",
    location: "Wiler b. Utzenstorf",
  },
  {
    npa: "3433",
    canton: "BE",
    location: "Schwanden im Emmental",
  },
  {
    npa: "3439",
    canton: "BE",
    location: "Ranflüh",
  },
  {
    npa: "3452",
    canton: "BE",
    location: "Grünenmatt",
  },
  {
    npa: "3453",
    canton: "BE",
    location: "Heimisbach",
  },
  {
    npa: "3455",
    canton: "BE",
    location: "Grünen",
  },
  {
    npa: "3474",
    canton: "BE",
    location: "Rüedisbach",
  },
  {
    npa: "3504",
    canton: "BE",
    location: "Niederhünigen",
  },
  {
    npa: "3506",
    canton: "BE",
    location: "Grosshöchstetten",
  },
  {
    npa: "3508",
    canton: "BE",
    location: "Arni BE",
  },
  {
    npa: "3513",
    canton: "BE",
    location: "Bigenthal",
  },
  {
    npa: "3672",
    canton: "BE",
    location: "Aeschlen b. Oberdiessbach",
  },
  {
    npa: "3531",
    canton: "BE",
    location: "Oberthal",
  },
  {
    npa: "3534",
    canton: "BE",
    location: "Signau",
  },
  {
    npa: "3536",
    canton: "BE",
    location: "Aeschau",
  },
  {
    npa: "3537",
    canton: "BE",
    location: "Eggiwil",
  },
  {
    npa: "3543",
    canton: "BE",
    location: "Emmenmatt",
  },
  {
    npa: "3551",
    canton: "BE",
    location: "Oberfrittenbach",
  },
  {
    npa: "3556",
    canton: "BE",
    location: "Trub",
  },
  {
    npa: "3557",
    canton: "BE",
    location: "Fankhaus (Trub)",
  },
  {
    npa: "3604",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3607",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3631",
    canton: "BE",
    location: "Höfen b. Thun",
  },
  {
    npa: "3612",
    canton: "BE",
    location: "Steffisburg",
  },
  {
    npa: "3613",
    canton: "BE",
    location: "Steffisburg",
  },
  {
    npa: "3624",
    canton: "BE",
    location: "Goldiwil (Thun)",
  },
  {
    npa: "3627",
    canton: "BE",
    location: "Heimberg",
  },
  {
    npa: "3633",
    canton: "BE",
    location: "Amsoldingen",
  },
  {
    npa: "3634",
    canton: "BE",
    location: "Thierachern",
  },
  {
    npa: "3638",
    canton: "BE",
    location: "Pohlern",
  },
  {
    npa: "3645",
    canton: "BE",
    location: "Gwatt (Thun)",
  },
  {
    npa: "3652",
    canton: "BE",
    location: "Hilterfingen",
  },
  {
    npa: "3653",
    canton: "BE",
    location: "Oberhofen am Thunersee",
  },
  {
    npa: "3656",
    canton: "BE",
    location: "Tschingel ob Gunten",
  },
  {
    npa: "3657",
    canton: "BE",
    location: "Schwanden (Sigriswil)",
  },
  {
    npa: "3658",
    canton: "BE",
    location: "Merligen",
  },
  {
    npa: "3700",
    canton: "BE",
    location: "Spiez",
  },
  {
    npa: "3703",
    canton: "BE",
    location: "Aeschiried",
  },
  {
    npa: "3704",
    canton: "BE",
    location: "Krattigen",
  },
  {
    npa: "3705",
    canton: "BE",
    location: "Faulensee",
  },
  {
    npa: "3714",
    canton: "BE",
    location: "Wengi b. Frutigen",
  },
  {
    npa: "3723",
    canton: "BE",
    location: "Kiental",
  },
  {
    npa: "3756",
    canton: "BE",
    location: "Zwischenflüh",
  },
  {
    npa: "3757",
    canton: "BE",
    location: "Schwenden im Diemtigtal",
  },
  {
    npa: "3763",
    canton: "BE",
    location: "Därstetten",
  },
  {
    npa: "3766",
    canton: "BE",
    location: "Boltigen",
  },
  {
    npa: "3772",
    canton: "BE",
    location: "St. Stephan",
  },
  {
    npa: "3777",
    canton: "BE",
    location: "Saanenmöser",
  },
  {
    npa: "3781",
    canton: "BE",
    location: "Turbach",
  },
  {
    npa: "3782",
    canton: "BE",
    location: "Lauenen b. Gstaad",
  },
  {
    npa: "3783",
    canton: "BE",
    location: "Grund b. Gstaad",
  },
  {
    npa: "3785",
    canton: "BE",
    location: "Gsteig b. Gstaad",
  },
  {
    npa: "3792",
    canton: "BE",
    location: "Saanen",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Interlaken",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Matten b. Interlaken",
  },
  {
    npa: "3801",
    canton: "VS",
    location: "Jungfraujoch",
  },
  {
    npa: "3807",
    canton: "BE",
    location: "Iseltwald",
  },
  {
    npa: "3816",
    canton: "BE",
    location: "Burglauenen",
  },
  {
    npa: "3824",
    canton: "BE",
    location: "Stechelberg",
  },
  {
    npa: "3852",
    canton: "BE",
    location: "Ringgenberg BE",
  },
  {
    npa: "3856",
    canton: "BE",
    location: "Brienzwiler",
  },
  {
    npa: "3860",
    canton: "BE",
    location: "Rosenlaui",
  },
  {
    npa: "3862",
    canton: "BE",
    location: "Innertkirchen",
  },
  {
    npa: "3863",
    canton: "BE",
    location: "Gadmen",
  },
  {
    npa: "3900",
    canton: "VS",
    location: "Brig",
  },
  {
    npa: "3949",
    canton: "VS",
    location: "Hohtenn",
  },
  {
    npa: "3903",
    canton: "VS",
    location: "Mund",
  },
  {
    npa: "3914",
    canton: "VS",
    location: "Blatten b. Naters",
  },
  {
    npa: "3913",
    canton: "VS",
    location: "Rosswald",
  },
  {
    npa: "3911",
    canton: "VS",
    location: "Ried-Brig",
  },
  {
    npa: "3912",
    canton: "VS",
    location: "Termen",
  },
  {
    npa: "3922",
    canton: "VS",
    location: "Eisten",
  },
  {
    npa: "3908",
    canton: "VS",
    location: "Saas-Balen",
  },
  {
    npa: "3902",
    canton: "VS",
    location: "Glis",
  },
  {
    npa: "3916",
    canton: "VS",
    location: "Ferden",
  },
  {
    npa: "3917",
    canton: "VS",
    location: "Kippel",
  },
  {
    npa: "3919",
    canton: "VS",
    location: "Blatten (Lötschen)",
  },
  {
    npa: "3906",
    canton: "VS",
    location: "Saas-Fee",
  },
  {
    npa: "3929",
    canton: "VS",
    location: "Täsch",
  },
  {
    npa: "3922",
    canton: "VS",
    location: "Kalpetran",
  },
  {
    npa: "3930",
    canton: "VS",
    location: "Visp",
  },
  {
    npa: "3939",
    canton: "VS",
    location: "Eggerberg",
  },
  {
    npa: "3931",
    canton: "VS",
    location: "Lalden",
  },
  {
    npa: "3944",
    canton: "VS",
    location: "Unterbäch VS",
  },
  {
    npa: "3948",
    canton: "VS",
    location: "Unterems",
  },
  {
    npa: "3946",
    canton: "VS",
    location: "Gruben",
  },
  {
    npa: "3951",
    canton: "VS",
    location: "Agarn",
  },
  {
    npa: "3978",
    canton: "VS",
    location: "Flanthey",
  },
  {
    npa: "1977",
    canton: "VS",
    location: "Icogne",
  },
  {
    npa: "3942",
    canton: "VS",
    location: "Raron",
  },
  {
    npa: "3942",
    canton: "VS",
    location: "Niedergesteln",
  },
  {
    npa: "3945",
    canton: "VS",
    location: "Gampel",
  },
  {
    npa: "3952",
    canton: "VS",
    location: "Susten",
  },
  {
    npa: "3953",
    canton: "VS",
    location: "Inden",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Sierre",
  },
  {
    npa: "3971",
    canton: "VS",
    location: "Chermignon-d'en-Bas",
  },
  {
    npa: "3971",
    canton: "VS",
    location: "Ollon VS",
  },
  {
    npa: "3972",
    canton: "VS",
    location: "Miège",
  },
  {
    npa: "3973",
    canton: "VS",
    location: "Venthône",
  },
  {
    npa: "3975",
    canton: "VS",
    location: "Randogne",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "St-Luc",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Zinal",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Grimentz",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Montana",
  },
  {
    npa: "3965",
    canton: "VS",
    location: "Chippis",
  },
  {
    npa: "3966",
    canton: "VS",
    location: "Chalais",
  },
  {
    npa: "3966",
    canton: "VS",
    location: "Réchy",
  },
  {
    npa: "3993",
    canton: "VS",
    location: "Grengiols",
  },
  {
    npa: "3994",
    canton: "VS",
    location: "Lax",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Niederwald",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Biel VS",
  },
  {
    npa: "3985",
    canton: "VS",
    location: "Geschinen",
  },
  {
    npa: "3988",
    canton: "VS",
    location: "Obergesteln",
  },
  {
    npa: "3991",
    canton: "VS",
    location: "Betten",
  },
  {
    npa: "3995",
    canton: "VS",
    location: "Ernen",
  },
  {
    npa: "3983",
    canton: "VS",
    location: "Mörel",
  },
  {
    npa: "3985",
    canton: "VS",
    location: "Münster VS",
  },
  {
    npa: "4002",
    canton: "BS",
    location: "Basel 2 Annahme",
  },
  {
    npa: "4002",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4003",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4011",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4013",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4016",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4019",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4020",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4051",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4054",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4056",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4102",
    canton: "BL",
    location: "Binningen Gorenmatt",
  },
  {
    npa: "4105",
    canton: "BL",
    location: "Biel-Benken BL",
  },
  {
    npa: "4106",
    canton: "BL",
    location: "Therwil",
  },
  {
    npa: "4107",
    canton: "BL",
    location: "Ettingen",
  },
  {
    npa: "4112",
    canton: "SO",
    location: "Bättwil-Flüh",
  },
  {
    npa: "4112",
    canton: "SO",
    location: "Bättwil",
  },
  {
    npa: "4115",
    canton: "SO",
    location: "Mariastein",
  },
  {
    npa: "4124",
    canton: "BL",
    location: "Schönenbuch",
  },
  {
    npa: "4125",
    canton: "BS",
    location: "Riehen",
  },
  {
    npa: "4125",
    canton: "BS",
    location: "Riehen 1",
  },
  {
    npa: "4127",
    canton: "BL",
    location: "Birsfelden",
  },
  {
    npa: "4132",
    canton: "BL",
    location: "Muttenz 1",
  },
  {
    npa: "4132",
    canton: "BL",
    location: "Muttenz Lutzert",
  },
  {
    npa: "4144",
    canton: "BL",
    location: "Arlesheim",
  },
  {
    npa: "4145",
    canton: "SO",
    location: "Gempen",
  },
  {
    npa: "4146",
    canton: "SO",
    location: "Hochwald",
  },
  {
    npa: "4153",
    canton: "BL",
    location: "Reinach BL Surbaum",
  },
  {
    npa: "4202",
    canton: "BL",
    location: "Duggingen",
  },
  {
    npa: "4226",
    canton: "SO",
    location: "Breitenbach",
  },
  {
    npa: "4227",
    canton: "SO",
    location: "Büsserach",
  },
  {
    npa: "4242",
    canton: "BL",
    location: "Laufen",
  },
  {
    npa: "4244",
    canton: "BL",
    location: "Röschenz",
  },
  {
    npa: "4224",
    canton: "BL",
    location: "Nenzlingen",
  },
  {
    npa: "4229",
    canton: "SO",
    location: "Beinwil SO",
  },
  {
    npa: "4234",
    canton: "SO",
    location: "Zullwil",
  },
  {
    npa: "4247",
    canton: "SO",
    location: "Grindel",
  },
  {
    npa: "4252",
    canton: "SO",
    location: "Bärschwil Dorf",
  },
  {
    npa: "4253",
    canton: "BL",
    location: "Liesberg",
  },
  {
    npa: "4304",
    canton: "BL",
    location: "Giebenach",
  },
  {
    npa: "4312",
    canton: "AG",
    location: "Magden",
  },
  {
    npa: "4322",
    canton: "AG",
    location: "Mumpf",
  },
  {
    npa: "4332",
    canton: "AG",
    location: "Stein AG",
  },
  {
    npa: "4334",
    canton: "AG",
    location: "Sisseln AG",
  },
  {
    npa: "5083",
    canton: "AG",
    location: "Ittenthal",
  },
  {
    npa: "5084",
    canton: "AG",
    location: "Rheinsulz",
  },
  {
    npa: "5272",
    canton: "AG",
    location: "Gansingen",
  },
  {
    npa: "5277",
    canton: "AG",
    location: "Hottwil",
  },
  {
    npa: "5325",
    canton: "AG",
    location: "Leibstadt",
  },
  {
    npa: "4410",
    canton: "BL",
    location: "Liestal Kaserne",
  },
  {
    npa: "4412",
    canton: "SO",
    location: "Nuglar",
  },
  {
    npa: "4413",
    canton: "SO",
    location: "Büren SO",
  },
  {
    npa: "4414",
    canton: "BL",
    location: "Füllinsdorf",
  },
  {
    npa: "4415",
    canton: "BL",
    location: "Lausen",
  },
  {
    npa: "4418",
    canton: "BL",
    location: "Reigoldswil",
  },
  {
    npa: "4419",
    canton: "BL",
    location: "Lupsingen",
  },
  {
    npa: "4421",
    canton: "SO",
    location: "St. Pantaleon",
  },
  {
    npa: "4425",
    canton: "BL",
    location: "Titterten",
  },
  {
    npa: "4431",
    canton: "BL",
    location: "Bennwil",
  },
  {
    npa: "4433",
    canton: "BL",
    location: "Ramlinsburg",
  },
  {
    npa: "4434",
    canton: "BL",
    location: "Hölstein",
  },
  {
    npa: "4437",
    canton: "BL",
    location: "Waldenburg",
  },
  {
    npa: "4443",
    canton: "BL",
    location: "Wittinsburg",
  },
  {
    npa: "4445",
    canton: "BL",
    location: "Häfelfingen",
  },
  {
    npa: "4450",
    canton: "BL",
    location: "Sissach",
  },
  {
    npa: "4451",
    canton: "BL",
    location: "Wintersingen",
  },
  {
    npa: "4452",
    canton: "BL",
    location: "Itingen",
  },
  {
    npa: "4460",
    canton: "BL",
    location: "Gelterkinden",
  },
  {
    npa: "4462",
    canton: "BL",
    location: "Rickenbach BL",
  },
  {
    npa: "4464",
    canton: "BL",
    location: "Maisprach",
  },
  {
    npa: "4501",
    canton: "SO",
    location: "Solothurn Bahnhof",
  },
  {
    npa: "4557",
    canton: "SO",
    location: "Horriwil",
  },
  {
    npa: "4513",
    canton: "SO",
    location: "Langendorf",
  },
  {
    npa: "4515",
    canton: "SO",
    location: "Oberdorf SO",
  },
  {
    npa: "4524",
    canton: "SO",
    location: "Günsberg",
  },
  {
    npa: "4534",
    canton: "SO",
    location: "Flumenthal",
  },
  {
    npa: "4538",
    canton: "BE",
    location: "Oberbipp",
  },
  {
    npa: "4562",
    canton: "SO",
    location: "Biberist",
  },
  {
    npa: "4565",
    canton: "SO",
    location: "Recherswil",
  },
  {
    npa: "4581",
    canton: "SO",
    location: "Küttigkofen",
  },
  {
    npa: "4583",
    canton: "SO",
    location: "Mühledorf SO",
  },
  {
    npa: "4578",
    canton: "SO",
    location: "Bibern SO",
  },
  {
    npa: "4571",
    canton: "SO",
    location: "Lüterkofen-Ichertswil",
  },
  {
    npa: "4571",
    canton: "SO",
    location: "Ichertswil",
  },
  {
    npa: "4577",
    canton: "SO",
    location: "Hessigkofen",
  },
  {
    npa: "4600",
    canton: "SO",
    location: "Olten 1",
  },
  {
    npa: "4612",
    canton: "SO",
    location: "Wangen b. Olten",
  },
  {
    npa: "4614",
    canton: "SO",
    location: "Hägendorf",
  },
  {
    npa: "4617",
    canton: "SO",
    location: "Gunzgen",
  },
  {
    npa: "4622",
    canton: "SO",
    location: "Egerkingen",
  },
  {
    npa: "4623",
    canton: "SO",
    location: "Neuendorf",
  },
  {
    npa: "4624",
    canton: "SO",
    location: "Härkingen",
  },
  {
    npa: "4653",
    canton: "SO",
    location: "Obergösgen",
  },
  {
    npa: "4654",
    canton: "SO",
    location: "Lostorf",
  },
  {
    npa: "4655",
    canton: "SO",
    location: "Rohr b. Olten",
  },
  {
    npa: "4665",
    canton: "AG",
    location: "Oftringen",
  },
  {
    npa: "3377",
    canton: "BE",
    location: "Walliswil b. Wangen",
  },
  {
    npa: "4710",
    canton: "SO",
    location: "Balsthal Postauto Thal-Gäu",
  },
  {
    npa: "4714",
    canton: "SO",
    location: "Aedermannsdorf",
  },
  {
    npa: "4715",
    canton: "SO",
    location: "Herbetswil",
  },
  {
    npa: "4713",
    canton: "SO",
    location: "Matzendorf",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen",
  },
  {
    npa: "4806",
    canton: "LU",
    location: "Wikon",
  },
  {
    npa: "4813",
    canton: "AG",
    location: "Uerkheim",
  },
  {
    npa: "4814",
    canton: "AG",
    location: "Bottenwil",
  },
  {
    npa: "4629",
    canton: "SO",
    location: "Fulenbach",
  },
  {
    npa: "4628",
    canton: "SO",
    location: "Wolfwil",
  },
  {
    npa: "4853",
    canton: "AG",
    location: "Riken AG",
  },
  {
    npa: "4900",
    canton: "BE",
    location: "Langenthal",
  },
  {
    npa: "4900",
    canton: "BE",
    location: "Langenthal 1",
  },
  {
    npa: "4914",
    canton: "BE",
    location: "Roggwil BE",
  },
  {
    npa: "4917",
    canton: "BE",
    location: "Melchnau",
  },
  {
    npa: "4934",
    canton: "BE",
    location: "Madiswil",
  },
  {
    npa: "4935",
    canton: "BE",
    location: "Leimiswil",
  },
  {
    npa: "4943",
    canton: "BE",
    location: "Oeschenbach",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau 1",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau Kaserne",
  },
  {
    npa: "5001",
    canton: "AG",
    location: "Aarau 1",
  },
  {
    npa: "5013",
    canton: "SO",
    location: "Niedergösgen",
  },
  {
    npa: "5014",
    canton: "SO",
    location: "Gretzenbach",
  },
  {
    npa: "5023",
    canton: "AG",
    location: "Biberstein",
  },
  {
    npa: "5028",
    canton: "AG",
    location: "Ueken",
  },
  {
    npa: "5032",
    canton: "AG",
    location: "Aarau Rohr",
  },
  {
    npa: "5033",
    canton: "AG",
    location: "Buchs AG",
  },
  {
    npa: "5037",
    canton: "AG",
    location: "Muhen",
  },
  {
    npa: "5046",
    canton: "AG",
    location: "Schmiedrued",
  },
  {
    npa: "5054",
    canton: "AG",
    location: "Kirchleerau-Moosleerau",
  },
  {
    npa: "5105",
    canton: "AG",
    location: "Auenstein",
  },
  {
    npa: "5107",
    canton: "AG",
    location: "Schinznach Dorf",
  },
  {
    npa: "5108",
    canton: "AG",
    location: "Oberflachs",
  },
  {
    npa: "5116",
    canton: "AG",
    location: "Schinznach Bad",
  },
  {
    npa: "5245",
    canton: "AG",
    location: "Habsburg",
  },
  {
    npa: "5212",
    canton: "AG",
    location: "Hausen AG",
  },
  {
    npa: "5213",
    canton: "AG",
    location: "Villnachern",
  },
  {
    npa: "5223",
    canton: "AG",
    location: "Riniken",
  },
  {
    npa: "5235",
    canton: "AG",
    location: "Rüfenach AG",
  },
  {
    npa: "5242",
    canton: "AG",
    location: "Birr-Lupfig",
  },
  {
    npa: "5244",
    canton: "AG",
    location: "Birrhard",
  },
  {
    npa: "5077",
    canton: "AG",
    location: "Elfingen",
  },
  {
    npa: "5079",
    canton: "AG",
    location: "Zeihen",
  },
  {
    npa: "5072",
    canton: "AG",
    location: "Oeschgen",
  },
  {
    npa: "5062",
    canton: "AG",
    location: "Oberhof",
  },
  {
    npa: "5301",
    canton: "AG",
    location: "Siggenthal Station",
  },
  {
    npa: "5303",
    canton: "AG",
    location: "Würenlingen",
  },
  {
    npa: "5305",
    canton: "AG",
    location: "Unterendingen",
  },
  {
    npa: "5314",
    canton: "AG",
    location: "Kleindöttingen",
  },
  {
    npa: "5315",
    canton: "AG",
    location: "Böttstein",
  },
  {
    npa: "5316",
    canton: "AG",
    location: "Leuggern",
  },
  {
    npa: "5400",
    canton: "AG",
    location: "Baden 1",
  },
  {
    npa: "5400",
    canton: "AG",
    location: "Baden 1 Postautodienst",
  },
  {
    npa: "5402",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5406",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5412",
    canton: "AG",
    location: "Gebenstorf",
  },
  {
    npa: "5417",
    canton: "AG",
    location: "Untersiggenthal",
  },
  {
    npa: "5420",
    canton: "AG",
    location: "Ehrendingen",
  },
  {
    npa: "5444",
    canton: "AG",
    location: "Künten",
  },
  {
    npa: "5453",
    canton: "AG",
    location: "Remetschwil",
  },
  {
    npa: "5454",
    canton: "AG",
    location: "Bellikon",
  },
  {
    npa: "5503",
    canton: "AG",
    location: "Schafisheim",
  },
  {
    npa: "5525",
    canton: "AG",
    location: "Fischbach-Göslikon",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Lenzburg 1",
  },
  {
    npa: "5606",
    canton: "AG",
    location: "Dintikon",
  },
  {
    npa: "5610",
    canton: "AG",
    location: "Wohlen AG",
  },
  {
    npa: "5611",
    canton: "AG",
    location: "Anglikon",
  },
  {
    npa: "5612",
    canton: "AG",
    location: "Villmergen",
  },
  {
    npa: "5614",
    canton: "AG",
    location: "Sarmenstorf",
  },
  {
    npa: "5615",
    canton: "AG",
    location: "Fahrwangen",
  },
  {
    npa: "5616",
    canton: "AG",
    location: "Meisterschwanden",
  },
  {
    npa: "5621",
    canton: "AG",
    location: "Zufikon",
  },
  {
    npa: "5624",
    canton: "AG",
    location: "Bünzen",
  },
  {
    npa: "5642",
    canton: "AG",
    location: "Mühlau",
  },
  {
    npa: "5644",
    canton: "AG",
    location: "Auw",
  },
  {
    npa: "5646",
    canton: "AG",
    location: "Abtwil AG",
  },
  {
    npa: "5647",
    canton: "AG",
    location: "Oberrüti",
  },
  {
    npa: "5702",
    canton: "AG",
    location: "Niederlenz",
  },
  {
    npa: "5703",
    canton: "AG",
    location: "Seon",
  },
  {
    npa: "5706",
    canton: "AG",
    location: "Boniswil",
  },
  {
    npa: "5707",
    canton: "AG",
    location: "Seengen",
  },
  {
    npa: "5708",
    canton: "AG",
    location: "Birrwil",
  },
  {
    npa: "5727",
    canton: "AG",
    location: "Oberkulm",
  },
  {
    npa: "5742",
    canton: "AG",
    location: "Kölliken",
  },
  {
    npa: "5746",
    canton: "SO",
    location: "Walterswil SO",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 1 Hauptpost",
  },
  {
    npa: "6005",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6013",
    canton: "LU",
    location: "Eigenthal",
  },
  {
    npa: "6016",
    canton: "LU",
    location: "Hellbühl",
  },
  {
    npa: "6018",
    canton: "LU",
    location: "Buttisholz",
  },
  {
    npa: "6020",
    canton: "LU",
    location: "Emmenbrücke 2",
  },
  {
    npa: "6022",
    canton: "LU",
    location: "Grosswangen",
  },
  {
    npa: "6023",
    canton: "LU",
    location: "Rothenburg",
  },
  {
    npa: "6027",
    canton: "LU",
    location: "Römerswil LU",
  },
  {
    npa: "6030",
    canton: "LU",
    location: "Ebikon",
  },
  {
    npa: "6034",
    canton: "LU",
    location: "Inwil",
  },
  {
    npa: "6036",
    canton: "LU",
    location: "Dierikon",
  },
  {
    npa: "6043",
    canton: "LU",
    location: "Adligenswil",
  },
  {
    npa: "6045",
    canton: "LU",
    location: "Meggen",
  },
  {
    npa: "6047",
    canton: "LU",
    location: "Kastanienbaum",
  },
  {
    npa: "6053",
    canton: "OW",
    location: "Alpnachstad",
  },
  {
    npa: "6056",
    canton: "OW",
    location: "Kägiswil",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Sarnen",
  },
  {
    npa: "6062",
    canton: "OW",
    location: "Wilen (Sarnen)",
  },
  {
    npa: "6063",
    canton: "OW",
    location: "Stalden (Sarnen)",
  },
  {
    npa: "6073",
    canton: "OW",
    location: "Flüeli-Ranft",
  },
  {
    npa: "6085",
    canton: "BE",
    location: "Hasliberg Goldern",
  },
  {
    npa: "6102",
    canton: "LU",
    location: "Malters",
  },
  {
    npa: "6103",
    canton: "LU",
    location: "Schwarzenberg LU",
  },
  {
    npa: "6112",
    canton: "LU",
    location: "Doppleschwand",
  },
  {
    npa: "6133",
    canton: "LU",
    location: "Hergiswil b. Willisau",
  },
  {
    npa: "6144",
    canton: "LU",
    location: "Zell LU",
  },
  {
    npa: "6152",
    canton: "LU",
    location: "Hüswil",
  },
  {
    npa: "6162",
    canton: "LU",
    location: "Finsterwald b. Entlebuch",
  },
  {
    npa: "6167",
    canton: "LU",
    location: "Bramboden",
  },
  {
    npa: "6170",
    canton: "LU",
    location: "Schüpfheim",
  },
  {
    npa: "6174",
    canton: "LU",
    location: "Sörenberg",
  },
  {
    npa: "6192",
    canton: "LU",
    location: "Wiggen",
  },
  {
    npa: "6197",
    canton: "BE",
    location: "Schangnau",
  },
  {
    npa: "6203",
    canton: "LU",
    location: "Sempach Station",
  },
  {
    npa: "6213",
    canton: "LU",
    location: "Knutwil",
  },
  {
    npa: "6215",
    canton: "LU",
    location: "Schwarzenbach LU",
  },
  {
    npa: "6217",
    canton: "LU",
    location: "Kottwil",
  },
  {
    npa: "6222",
    canton: "LU",
    location: "Gunzwil",
  },
  {
    npa: "6231",
    canton: "LU",
    location: "Schlierbach",
  },
  {
    npa: "6232",
    canton: "LU",
    location: "Geuensee",
  },
  {
    npa: "6234",
    canton: "LU",
    location: "Triengen",
  },
  {
    npa: "6234",
    canton: "LU",
    location: "Kulmerau",
  },
  {
    npa: "6236",
    canton: "LU",
    location: "Wilihof",
  },
  {
    npa: "6243",
    canton: "LU",
    location: "Egolzwil",
  },
  {
    npa: "6245",
    canton: "LU",
    location: "Ebersecken",
  },
  {
    npa: "6247",
    canton: "LU",
    location: "Schötz",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Reidermoos",
  },
  {
    npa: "6262",
    canton: "LU",
    location: "Langnau b. Reiden",
  },
  {
    npa: "6263",
    canton: "LU",
    location: "Richenthal",
  },
  {
    npa: "6265",
    canton: "LU",
    location: "Roggliswil",
  },
  {
    npa: "6275",
    canton: "LU",
    location: "Ballwil",
  },
  {
    npa: "6277",
    canton: "LU",
    location: "Kleinwangen",
  },
  {
    npa: "6280",
    canton: "LU",
    location: "Urswil",
  },
  {
    npa: "6283",
    canton: "LU",
    location: "Baldegg",
  },
  {
    npa: "6287",
    canton: "LU",
    location: "Aesch LU",
  },
  {
    npa: "6294",
    canton: "LU",
    location: "Ermensee",
  },
  {
    npa: "6295",
    canton: "LU",
    location: "Mosen",
  },
  {
    npa: "6300",
    canton: "ZG",
    location: "Zug",
  },
  {
    npa: "6313",
    canton: "ZG",
    location: "Edlibach",
  },
  {
    npa: "6319",
    canton: "ZG",
    location: "Allenwinden",
  },
  {
    npa: "6312",
    canton: "ZG",
    location: "Steinhausen",
  },
  {
    npa: "6315",
    canton: "ZG",
    location: "Alosen",
  },
  {
    npa: "6332",
    canton: "ZG",
    location: "Hagendorn",
  },
  {
    npa: "6340",
    canton: "ZG",
    location: "Baar",
  },
  {
    npa: "6353",
    canton: "LU",
    location: "Weggis",
  },
  {
    npa: "6354",
    canton: "LU",
    location: "Vitznau",
  },
  {
    npa: "6370",
    canton: "NW",
    location: "Stans",
  },
  {
    npa: "6374",
    canton: "NW",
    location: "Buochs",
  },
  {
    npa: "6382",
    canton: "NW",
    location: "Büren NW",
  },
  {
    npa: "6383",
    canton: "NW",
    location: "Niederrickenbach",
  },
  {
    npa: "6386",
    canton: "NW",
    location: "Wolfenschiessen",
  },
  {
    npa: "6402",
    canton: "SZ",
    location: "Merlischachen",
  },
  {
    npa: "6403",
    canton: "SZ",
    location: "Küssnacht am Rigi",
  },
  {
    npa: "6404",
    canton: "LU",
    location: "Greppen",
  },
  {
    npa: "6415",
    canton: "SZ",
    location: "Arth",
  },
  {
    npa: "6416",
    canton: "SZ",
    location: "Steinerberg",
  },
  {
    npa: "6417",
    canton: "SZ",
    location: "Sattel",
  },
  {
    npa: "6418",
    canton: "SZ",
    location: "Rothenthurm",
  },
  {
    npa: "6430",
    canton: "SZ",
    location: "Schwyz",
  },
  {
    npa: "6436",
    canton: "SZ",
    location: "Ried (Muotathal)",
  },
  {
    npa: "6433",
    canton: "SZ",
    location: "Stoos SZ",
  },
  {
    npa: "6434",
    canton: "SZ",
    location: "Illgau",
  },
  {
    npa: "6441",
    canton: "UR",
    location: "Rütli",
  },
  {
    npa: "6442",
    canton: "SZ",
    location: "Gersau",
  },
  {
    npa: "6443",
    canton: "SZ",
    location: "Morschach",
  },
  {
    npa: "6491",
    canton: "UR",
    location: "Realp",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona 4",
  },
  {
    npa: "6501",
    canton: "TI",
    location: "Bellinzona",
  },
  {
    npa: "6503",
    canton: "TI",
    location: "Bellinzona",
  },
  {
    npa: "6512",
    canton: "TI",
    location: "Giubiasco",
  },
  {
    npa: "6518",
    canton: "TI",
    location: "Gorduno",
  },
  {
    npa: "6526",
    canton: "TI",
    location: "Prosito",
  },
  {
    npa: "6534",
    canton: "GR",
    location: "S. Vittore",
  },
  {
    npa: "6524",
    canton: "TI",
    location: "Moleno",
  },
  {
    npa: "6583",
    canton: "TI",
    location: "S. Antonio (Val Morobbia)",
  },
  {
    npa: "6540",
    canton: "GR",
    location: "Castaneda",
  },
  {
    npa: "6543",
    canton: "GR",
    location: "Arvigo",
  },
  {
    npa: "6546",
    canton: "GR",
    location: "Cauco",
  },
  {
    npa: "6558",
    canton: "GR",
    location: "Lostallo",
  },
  {
    npa: "6558",
    canton: "GR",
    location: "Cabbiolo",
  },
  {
    npa: "6562",
    canton: "GR",
    location: "Soazza",
  },
  {
    npa: "6573",
    canton: "TI",
    location: "Magadino",
  },
  {
    npa: "6575",
    canton: "TI",
    location: "Vairano",
  },
  {
    npa: "6578",
    canton: "TI",
    location: "Caviano",
  },
  {
    npa: "6571",
    canton: "TI",
    location: "Indemini",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Locarno 1",
  },
  {
    npa: "6647",
    canton: "TI",
    location: "Mergoscia",
  },
  {
    npa: "6663",
    canton: "TI",
    location: "Spruga",
  },
  {
    npa: "6632",
    canton: "TI",
    location: "Vogorno",
  },
  {
    npa: "6631",
    canton: "TI",
    location: "Corippo",
  },
  {
    npa: "6633",
    canton: "TI",
    location: "Lavertezzo",
  },
  {
    npa: "6634",
    canton: "TI",
    location: "Brione (Verzasca)",
  },
  {
    npa: "6636",
    canton: "TI",
    location: "Frasco",
  },
  {
    npa: "6614",
    canton: "TI",
    location: "Isole di Brissago",
  },
  {
    npa: "6622",
    canton: "TI",
    location: "Ronco sopra Ascona",
  },
  {
    npa: "6645",
    canton: "TI",
    location: "Brione sopra Minusio",
  },
  {
    npa: "6655",
    canton: "TI",
    location: "Rasa",
  },
  {
    npa: "6657",
    canton: "TI",
    location: "Palagnedra",
  },
  {
    npa: "6659",
    canton: "TI",
    location: "Camedo",
  },
  {
    npa: "6655",
    canton: "TI",
    location: "Intragna",
  },
  {
    npa: "6678",
    canton: "TI",
    location: "Coglio",
  },
  {
    npa: "6685",
    canton: "TI",
    location: "Bosco/Gurin",
  },
  {
    npa: "6690",
    canton: "TI",
    location: "Cavergno",
  },
  {
    npa: "6690",
    canton: "TI",
    location: "S. Carlo (Val Bavona)",
  },
  {
    npa: "6693",
    canton: "TI",
    location: "Broglio",
  },
  {
    npa: "6702",
    canton: "TI",
    location: "Claro",
  },
  {
    npa: "6707",
    canton: "TI",
    location: "Iragna",
  },
  {
    npa: "6710",
    canton: "TI",
    location: "Biasca",
  },
  {
    npa: "6710",
    canton: "TI",
    location: "Biasca Stazione",
  },
  {
    npa: "6722",
    canton: "TI",
    location: "Corzoneso",
  },
  {
    npa: "6716",
    canton: "TI",
    location: "Lottigna",
  },
  {
    npa: "6721",
    canton: "TI",
    location: "Motto (Blenio)",
  },
  {
    npa: "6714",
    canton: "TI",
    location: "Semione",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Carì",
  },
  {
    npa: "6775",
    canton: "TI",
    location: "Ambrì",
  },
  {
    npa: "6776",
    canton: "TI",
    location: "Piotta",
  },
  {
    npa: "6763",
    canton: "TI",
    location: "Mairengo",
  },
  {
    npa: "6764",
    canton: "TI",
    location: "Chiggiogna",
  },
  {
    npa: "6774",
    canton: "TI",
    location: "Dalpe",
  },
  {
    npa: "6803",
    canton: "TI",
    location: "Camignolo",
  },
  {
    npa: "6822",
    canton: "TI",
    location: "Arogno",
  },
  {
    npa: "6839",
    canton: "TI",
    location: "Sagno",
  },
  {
    npa: "6837",
    canton: "TI",
    location: "Caneggio",
  },
  {
    npa: "6832",
    canton: "TI",
    location: "Pedrinate",
  },
  {
    npa: "6832",
    canton: "TI",
    location: "Seseglio",
  },
  {
    npa: "6805",
    canton: "TI",
    location: "Mezzovico",
  },
  {
    npa: "6850",
    canton: "TI",
    location: "Mendrisio",
  },
  {
    npa: "6850",
    canton: "TI",
    location: "Mendrisio Stazione",
  },
  {
    npa: "6850",
    canton: "TI",
    location: "Mendrisio Borgo",
  },
  {
    npa: "6875",
    canton: "TI",
    location: "Casima",
  },
  {
    npa: "6852",
    canton: "TI",
    location: "Genestrerio",
  },
  {
    npa: "6863",
    canton: "TI",
    location: "Besazio",
  },
  {
    npa: "6872",
    canton: "TI",
    location: "Somazzo",
  },
  {
    npa: "6874",
    canton: "TI",
    location: "Castel San Pietro",
  },
  {
    npa: "6877",
    canton: "TI",
    location: "Coldrerio",
  },
  {
    npa: "6883",
    canton: "TI",
    location: "Novazzano",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano Distribuzione",
  },
  {
    npa: "6903",
    canton: "TI",
    location: "Lugano",
  },
  {
    npa: "6913",
    canton: "TI",
    location: "Carabbia",
  },
  {
    npa: "6916",
    canton: "TI",
    location: "Grancia",
  },
  {
    npa: "6921",
    canton: "TI",
    location: "Vico Morcote",
  },
  {
    npa: "6928",
    canton: "TI",
    location: "Manno",
  },
  {
    npa: "6949",
    canton: "TI",
    location: "Comano",
  },
  {
    npa: "6918",
    canton: "TI",
    location: "Figino",
  },
  {
    npa: "6926",
    canton: "TI",
    location: "Montagnola",
  },
  {
    npa: "6927",
    canton: "TI",
    location: "Agra",
  },
  {
    npa: "6932",
    canton: "TI",
    location: "Breganzona",
  },
  {
    npa: "6933",
    canton: "TI",
    location: "Muzzano",
  },
  {
    npa: "6936",
    canton: "TI",
    location: "Cademario",
  },
  {
    npa: "6950",
    canton: "TI",
    location: "Tesserete",
  },
  {
    npa: "6954",
    canton: "TI",
    location: "Bigorio",
  },
  {
    npa: "6958",
    canton: "TI",
    location: "Corticiasca",
  },
  {
    npa: "6955",
    canton: "TI",
    location: "Oggio",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Signôra",
  },
  {
    npa: "6959",
    canton: "TI",
    location: "Cimadera",
  },
  {
    npa: "6959",
    canton: "TI",
    location: "Certara",
  },
  {
    npa: "6959",
    canton: "TI",
    location: "Curtina",
  },
  {
    npa: "6962",
    canton: "TI",
    location: "Viganello",
  },
  {
    npa: "6963",
    canton: "TI",
    location: "Pregassona",
  },
  {
    npa: "6965",
    canton: "TI",
    location: "Cadro",
  },
  {
    npa: "6967",
    canton: "TI",
    location: "Dino",
  },
  {
    npa: "6968",
    canton: "TI",
    location: "Sonvico",
  },
  {
    npa: "6974",
    canton: "TI",
    location: "Aldesago",
  },
  {
    npa: "6976",
    canton: "TI",
    location: "Castagnola",
  },
  {
    npa: "6993",
    canton: "TI",
    location: "Iseo",
  },
  {
    npa: "6981",
    canton: "TI",
    location: "Bedigliora",
  },
  {
    npa: "6999",
    canton: "TI",
    location: "Astano",
  },
  {
    npa: "6983",
    canton: "TI",
    location: "Magliaso",
  },
  {
    npa: "6986",
    canton: "TI",
    location: "Novaggio",
  },
  {
    npa: "6988",
    canton: "TI",
    location: "Ponte Tresa",
  },
  {
    npa: "7000",
    canton: "GR",
    location: "Chur 1 Annahme",
  },
  {
    npa: "7000",
    canton: "GR",
    location: "Chur 1 Zustellung",
  },
  {
    npa: "7026",
    canton: "GR",
    location: "Maladers",
  },
  {
    npa: "7032",
    canton: "GR",
    location: "Laax GR 2",
  },
  {
    npa: "7084",
    canton: "GR",
    location: "Brienz/Brinzauls GR",
  },
  {
    npa: "7019",
    canton: "GR",
    location: "Fidaz",
  },
  {
    npa: "7056",
    canton: "GR",
    location: "Molinis",
  },
  {
    npa: "7029",
    canton: "GR",
    location: "Peist",
  },
  {
    npa: "7106",
    canton: "GR",
    location: "Tenna",
  },
  {
    npa: "7122",
    canton: "GR",
    location: "Valendas",
  },
  {
    npa: "7126",
    canton: "GR",
    location: "Castrisch",
  },
  {
    npa: "7130",
    canton: "GR",
    location: "Ilanz",
  },
  {
    npa: "7154",
    canton: "GR",
    location: "Ruschein",
  },
  {
    npa: "7152",
    canton: "GR",
    location: "Sagogn",
  },
  {
    npa: "7127",
    canton: "GR",
    location: "Sevgein",
  },
  {
    npa: "7113",
    canton: "GR",
    location: "Camuns",
  },
  {
    npa: "7114",
    canton: "GR",
    location: "Uors (Lumnezia)",
  },
  {
    npa: "7142",
    canton: "GR",
    location: "Cumbel",
  },
  {
    npa: "7138",
    canton: "GR",
    location: "Surcuolm",
  },
  {
    npa: "7156",
    canton: "GR",
    location: "Pigniu",
  },
  {
    npa: "7157",
    canton: "GR",
    location: "Siat",
  },
  {
    npa: "7159",
    canton: "GR",
    location: "Andiast",
  },
  {
    npa: "7166",
    canton: "GR",
    location: "Trun",
  },
  {
    npa: "7168",
    canton: "GR",
    location: "Schlans",
  },
  {
    npa: "7172",
    canton: "GR",
    location: "Rabius",
  },
  {
    npa: "7189",
    canton: "GR",
    location: "Rueras",
  },
  {
    npa: "7188",
    canton: "GR",
    location: "Sedrun",
  },
  {
    npa: "7176",
    canton: "GR",
    location: "Cumpadials",
  },
  {
    npa: "7203",
    canton: "GR",
    location: "Trimmis",
  },
  {
    npa: "7204",
    canton: "GR",
    location: "Untervaz",
  },
  {
    npa: "7205",
    canton: "GR",
    location: "Zizers",
  },
  {
    npa: "7208",
    canton: "GR",
    location: "Malans GR",
  },
  {
    npa: "7215",
    canton: "GR",
    location: "Fanas",
  },
  {
    npa: "7228",
    canton: "GR",
    location: "Schuders",
  },
  {
    npa: "7223",
    canton: "GR",
    location: "Buchen im Prättigau",
  },
  {
    npa: "7224",
    canton: "GR",
    location: "Putz",
  },
  {
    npa: "7231",
    canton: "GR",
    location: "Pragg-Jenaz",
  },
  {
    npa: "7233",
    canton: "GR",
    location: "Jenaz",
  },
  {
    npa: "7240",
    canton: "GR",
    location: "Küblis",
  },
  {
    npa: "7242",
    canton: "GR",
    location: "Luzein",
  },
  {
    npa: "7243",
    canton: "GR",
    location: "Pany",
  },
  {
    npa: "7244",
    canton: "GR",
    location: "Gadenstätt",
  },
  {
    npa: "7249",
    canton: "GR",
    location: "Serneus",
  },
  {
    npa: "7270",
    canton: "GR",
    location: "Davos Platz 1",
  },
  {
    npa: "7272",
    canton: "GR",
    location: "Davos Clavadel",
  },
  {
    npa: "7276",
    canton: "GR",
    location: "Davos Frauenkirch",
  },
  {
    npa: "7277",
    canton: "GR",
    location: "Davos Glaris",
  },
  {
    npa: "7278",
    canton: "GR",
    location: "Davos Monstein",
  },
  {
    npa: "7306",
    canton: "GR",
    location: "Fläsch",
  },
  {
    npa: "7307",
    canton: "GR",
    location: "Jenins",
  },
  {
    npa: "7317",
    canton: "SG",
    location: "Vasön",
  },
  {
    npa: "7314",
    canton: "SG",
    location: "Vadura",
  },
  {
    npa: "7312",
    canton: "SG",
    location: "Pfäfers",
  },
  {
    npa: "7313",
    canton: "SG",
    location: "St. Margrethenberg",
  },
  {
    npa: "7325",
    canton: "SG",
    location: "Schwendi im Weisstannental",
  },
  {
    npa: "7324",
    canton: "SG",
    location: "Vilters",
  },
  {
    npa: "7404",
    canton: "GR",
    location: "Feldis/Veulden",
  },
  {
    npa: "7408",
    canton: "GR",
    location: "Cazis",
  },
  {
    npa: "7411",
    canton: "GR",
    location: "Sils im Domleschg",
  },
  {
    npa: "7416",
    canton: "GR",
    location: "Almens",
  },
  {
    npa: "7430",
    canton: "GR",
    location: "Thusis",
  },
  {
    npa: "7425",
    canton: "GR",
    location: "Masein",
  },
  {
    npa: "7422",
    canton: "GR",
    location: "Tartar",
  },
  {
    npa: "7424",
    canton: "GR",
    location: "Dalin",
  },
  {
    npa: "7430",
    canton: "GR",
    location: "Rongellen",
  },
  {
    npa: "7432",
    canton: "GR",
    location: "Zillis",
  },
  {
    npa: "7433",
    canton: "GR",
    location: "Donat",
  },
  {
    npa: "7435",
    canton: "GR",
    location: "Splügen",
  },
  {
    npa: "7442",
    canton: "GR",
    location: "Clugin",
  },
  {
    npa: "7446",
    canton: "GR",
    location: "Campsut-Cröt",
  },
  {
    npa: "7448",
    canton: "GR",
    location: "Juf",
  },
  {
    npa: "7450",
    canton: "GR",
    location: "Tiefencastel",
  },
  {
    npa: "7458",
    canton: "GR",
    location: "Mon",
  },
  {
    npa: "7459",
    canton: "GR",
    location: "Stierva",
  },
  {
    npa: "7452",
    canton: "GR",
    location: "Cunter",
  },
  {
    npa: "7455",
    canton: "GR",
    location: "Mulegns",
  },
  {
    npa: "7457",
    canton: "GR",
    location: "Bivio",
  },
  {
    npa: "7460",
    canton: "GR",
    location: "Savognin",
  },
  {
    npa: "7472",
    canton: "GR",
    location: "Surava",
  },
  {
    npa: "7482",
    canton: "GR",
    location: "Bergün/Bravuogn",
  },
  {
    npa: "7482",
    canton: "GR",
    location: "Preda",
  },
  {
    npa: "7492",
    canton: "GR",
    location: "Alvaneu Dorf",
  },
  {
    npa: "7493",
    canton: "GR",
    location: "Schmitten (Albula)",
  },
  {
    npa: "7512",
    canton: "GR",
    location: "Champfèr",
  },
  {
    npa: "7514",
    canton: "GR",
    location: "Fex",
  },
  {
    npa: "7524",
    canton: "GR",
    location: "Zuoz",
  },
  {
    npa: "7525",
    canton: "GR",
    location: "S-chanf",
  },
  {
    npa: "7535",
    canton: "GR",
    location: "Valchava",
  },
  {
    npa: "7536",
    canton: "GR",
    location: "Sta. Maria Val Müstair",
  },
  {
    npa: "7537",
    canton: "GR",
    location: "Müstair",
  },
  {
    npa: "7542",
    canton: "GR",
    location: "Susch",
  },
  {
    npa: "7543",
    canton: "GR",
    location: "Lavin",
  },
  {
    npa: "7545",
    canton: "GR",
    location: "Guarda",
  },
  {
    npa: "7550",
    canton: "GR",
    location: "Scuol",
  },
  {
    npa: "7553",
    canton: "GR",
    location: "Tarasp",
  },
  {
    npa: "7557",
    canton: "GR",
    location: "Vnà",
  },
  {
    npa: "7560",
    canton: "GR",
    location: "Martina",
  },
  {
    npa: "7562",
    canton: "GR",
    location: "Samnaun-Compatsch",
  },
  {
    npa: "7563",
    canton: "GR",
    location: "Samnaun Dorf",
  },
  {
    npa: "7603",
    canton: "GR",
    location: "Vicosoprano",
  },
  {
    npa: "7605",
    canton: "GR",
    location: "Stampa",
  },
  {
    npa: "7606",
    canton: "GR",
    location: "Promontogno",
  },
  {
    npa: "7710",
    canton: "GR",
    location: "Ospizio Bernina",
  },
  {
    npa: "8001",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8002",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8080",
    canton: "ZH",
    location: "Zürich 80",
  },
  {
    npa: "8027",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8031",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8036",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8037",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8039",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8040",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8041",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8044",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8049",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8063",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8064",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8093",
    canton: "ZH",
    location: "Zürich ETH-Hönggerberg",
  },
  {
    npa: "8099",
    canton: "ZH",
    location: "Zürich Sonderdienste",
  },
  {
    npa: "8105",
    canton: "ZH",
    location: "Regensdorf",
  },
  {
    npa: "8105",
    canton: "ZH",
    location: "Watt",
  },
  {
    npa: "8107",
    canton: "ZH",
    location: "Buchs ZH",
  },
  {
    npa: "8113",
    canton: "ZH",
    location: "Boppelsen",
  },
  {
    npa: "8115",
    canton: "ZH",
    location: "Hüttikon",
  },
  {
    npa: "8123",
    canton: "ZH",
    location: "Ebmatingen",
  },
  {
    npa: "8127",
    canton: "ZH",
    location: "Forch",
  },
  {
    npa: "8132",
    canton: "ZH",
    location: "Hinteregg",
  },
  {
    npa: "8132",
    canton: "ZH",
    location: "Egg b. Zürich",
  },
  {
    npa: "8133",
    canton: "ZH",
    location: "Esslingen",
  },
  {
    npa: "8134",
    canton: "ZH",
    location: "Adliswil Sood",
  },
  {
    npa: "8136",
    canton: "ZH",
    location: "Gattikon",
  },
  {
    npa: "8142",
    canton: "ZH",
    location: "Uitikon Waldegg",
  },
  {
    npa: "8152",
    canton: "ZH",
    location: "Opfikon",
  },
  {
    npa: "8158",
    canton: "ZH",
    location: "Regensberg",
  },
  {
    npa: "8162",
    canton: "ZH",
    location: "Steinmaur",
  },
  {
    npa: "8173",
    canton: "ZH",
    location: "Neerach",
  },
  {
    npa: "8174",
    canton: "ZH",
    location: "Stadel b. Niederglatt",
  },
  {
    npa: "8181",
    canton: "ZH",
    location: "Höri",
  },
  {
    npa: "8192",
    canton: "ZH",
    location: "Glattfelden",
  },
  {
    npa: "8193",
    canton: "ZH",
    location: "Eglisau",
  },
  {
    npa: "8194",
    canton: "ZH",
    location: "Hüntwangen",
  },
  {
    npa: "8195",
    canton: "ZH",
    location: "Wasterkingen",
  },
  {
    npa: "8196",
    canton: "ZH",
    location: "Wil ZH",
  },
  {
    npa: "8197",
    canton: "ZH",
    location: "Rafz",
  },
  {
    npa: "8200",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8207",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8219",
    canton: "SH",
    location: "Trasadingen",
  },
  {
    npa: "8228",
    canton: "SH",
    location: "Beggingen",
  },
  {
    npa: "8234",
    canton: "SH",
    location: "Stetten SH",
  },
  {
    npa: "8235",
    canton: "SH",
    location: "Lohn SH",
  },
  {
    npa: "8236",
    canton: "SH",
    location: "Büttenhardt",
  },
  {
    npa: "8242",
    canton: "SH",
    location: "Hofen SH",
  },
  {
    npa: "8216",
    canton: "SH",
    location: "Oberhallau",
  },
  {
    npa: "8224",
    canton: "SH",
    location: "Löhningen",
  },
  {
    npa: "8225",
    canton: "SH",
    location: "Siblingen",
  },
  {
    npa: "8226",
    canton: "SH",
    location: "Schleitheim",
  },
  {
    npa: "8232",
    canton: "SH",
    location: "Merishausen",
  },
  {
    npa: "8246",
    canton: "ZH",
    location: "Langwiesen",
  },
  {
    npa: "8252",
    canton: "TG",
    location: "Schlatt TG",
  },
  {
    npa: "8253",
    canton: "TG",
    location: "Diessenhofen",
  },
  {
    npa: "8255",
    canton: "TG",
    location: "Schlattingen",
  },
  {
    npa: "8259",
    canton: "TG",
    location: "Wagenhausen",
  },
  {
    npa: "8263",
    canton: "SH",
    location: "Buch SH",
  },
  {
    npa: "8268",
    canton: "TG",
    location: "Mannenbach-Salenstein",
  },
  {
    npa: "8272",
    canton: "TG",
    location: "Ermatingen",
  },
  {
    npa: "8273",
    canton: "TG",
    location: "Triboltingen",
  },
  {
    npa: "8280",
    canton: "TG",
    location: "Kreuzlingen 1",
  },
  {
    npa: "8302",
    canton: "ZH",
    location: "Kloten",
  },
  {
    npa: "8305",
    canton: "ZH",
    location: "Dietlikon",
  },
  {
    npa: "8308",
    canton: "ZH",
    location: "Illnau",
  },
  {
    npa: "8309",
    canton: "ZH",
    location: "Nürensdorf",
  },
  {
    npa: "8307",
    canton: "ZH",
    location: "Ottikon b. Kemptthal",
  },
  {
    npa: "8314",
    canton: "ZH",
    location: "Kyburg",
  },
  {
    npa: "8322",
    canton: "ZH",
    location: "Madetswil",
  },
  {
    npa: "8340",
    canton: "ZH",
    location: "Hinwil",
  },
  {
    npa: "8345",
    canton: "ZH",
    location: "Adetswil",
  },
  {
    npa: "8353",
    canton: "ZH",
    location: "Elgg",
  },
  {
    npa: "8374",
    canton: "TG",
    location: "Dussnang",
  },
  {
    npa: "8376",
    canton: "TG",
    location: "Fischingen",
  },
  {
    npa: "8400",
    canton: "ZH",
    location: "Winterthur Zustellung",
  },
  {
    npa: "8405",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8413",
    canton: "ZH",
    location: "Neftenbach",
  },
  {
    npa: "8414",
    canton: "ZH",
    location: "Buch am Irchel",
  },
  {
    npa: "8421",
    canton: "ZH",
    location: "Dättlikon",
  },
  {
    npa: "8424",
    canton: "ZH",
    location: "Embrach",
  },
  {
    npa: "8428",
    canton: "ZH",
    location: "Teufen ZH",
  },
  {
    npa: "8187",
    canton: "ZH",
    location: "Weiach",
  },
  {
    npa: "5466",
    canton: "AG",
    location: "Kaiserstuhl AG",
  },
  {
    npa: "5332",
    canton: "AG",
    location: "Rekingen AG",
  },
  {
    npa: "5323",
    canton: "AG",
    location: "Rietheim",
  },
  {
    npa: "5464",
    canton: "AG",
    location: "Rümikon AG",
  },
  {
    npa: "5462",
    canton: "AG",
    location: "Siglistorf",
  },
  {
    npa: "5333",
    canton: "AG",
    location: "Baldingen",
  },
  {
    npa: "8442",
    canton: "ZH",
    location: "Hettlingen",
  },
  {
    npa: "8457",
    canton: "ZH",
    location: "Humlikon",
  },
  {
    npa: "8464",
    canton: "ZH",
    location: "Ellikon am Rhein",
  },
  {
    npa: "8466",
    canton: "ZH",
    location: "Trüllikon",
  },
  {
    npa: "8477",
    canton: "ZH",
    location: "Oberstammheim",
  },
  {
    npa: "8484",
    canton: "ZH",
    location: "Theilingen",
  },
  {
    npa: "8488",
    canton: "ZH",
    location: "Turbenthal",
  },
  {
    npa: "8494",
    canton: "ZH",
    location: "Bauma",
  },
  {
    npa: "8498",
    canton: "ZH",
    location: "Gibswil",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld 1",
  },
  {
    npa: "8501",
    canton: "TG",
    location: "Frauenfeld",
  },
  {
    npa: "8503",
    canton: "TG",
    location: "Frauenfeld Kurzdorf",
  },
  {
    npa: "8505",
    canton: "TG",
    location: "Pfyn",
  },
  {
    npa: "8512",
    canton: "TG",
    location: "Lustdorf",
  },
  {
    npa: "8514",
    canton: "TG",
    location: "Amlikon-Bissegg",
  },
  {
    npa: "8523",
    canton: "ZH",
    location: "Hagenbuch ZH",
  },
  {
    npa: "8524",
    canton: "TG",
    location: "Uesslingen",
  },
  {
    npa: "8524",
    canton: "TG",
    location: "Buch b. Frauenfeld",
  },
  {
    npa: "8535",
    canton: "TG",
    location: "Herdern",
  },
  {
    npa: "8536",
    canton: "TG",
    location: "Hüttwilen",
  },
  {
    npa: "8404",
    canton: "ZH",
    location: "Stadel (Winterthur)",
  },
  {
    npa: "8555",
    canton: "TG",
    location: "Müllheim Dorf",
  },
  {
    npa: "8269",
    canton: "TG",
    location: "Fruthwilen",
  },
  {
    npa: "8561",
    canton: "TG",
    location: "Ottoberg",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Engwilen",
  },
  {
    npa: "8574",
    canton: "TG",
    location: "Illighausen",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Amriswil",
  },
  {
    npa: "8583",
    canton: "TG",
    location: "Sulgen",
  },
  {
    npa: "8583",
    canton: "TG",
    location: "Donzhausen",
  },
  {
    npa: "8584",
    canton: "TG",
    location: "Leimbach TG",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Mattwil",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Zuben",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Andwil TG",
  },
  {
    npa: "8587",
    canton: "TG",
    location: "Oberaach",
  },
  {
    npa: "8593",
    canton: "TG",
    location: "Kesswil",
  },
  {
    npa: "8600",
    canton: "ZH",
    location: "Dübendorf",
  },
  {
    npa: "8604",
    canton: "ZH",
    location: "Volketswil",
  },
  {
    npa: "8605",
    canton: "ZH",
    location: "Gutenswil",
  },
  {
    npa: "8607",
    canton: "ZH",
    location: "Aathal-Seegräben",
  },
  {
    npa: "8616",
    canton: "ZH",
    location: "Riedikon",
  },
  {
    npa: "8622",
    canton: "ZH",
    location: "Wetzikon ZH",
  },
  {
    npa: "8627",
    canton: "ZH",
    location: "Grüningen",
  },
  {
    npa: "8632",
    canton: "ZH",
    location: "Tann",
  },
  {
    npa: "8639",
    canton: "ZH",
    location: "Faltigberg",
  },
  {
    npa: "8704",
    canton: "ZH",
    location: "Herrliberg",
  },
  {
    npa: "8706",
    canton: "ZH",
    location: "Meilen",
  },
  {
    npa: "8708",
    canton: "ZH",
    location: "Männedorf",
  },
  {
    npa: "8712",
    canton: "ZH",
    location: "Stäfa",
  },
  {
    npa: "8716",
    canton: "SG",
    location: "Schmerikon",
  },
  {
    npa: "8750",
    canton: "GL",
    location: "Klöntal",
  },
  {
    npa: "8751",
    canton: "UR",
    location: "Urnerboden",
  },
  {
    npa: "8755",
    canton: "GL",
    location: "Ennenda",
  },
  {
    npa: "8756",
    canton: "GL",
    location: "Mitlödi",
  },
  {
    npa: "8765",
    canton: "GL",
    location: "Engi",
  },
  {
    npa: "8772",
    canton: "GL",
    location: "Nidfurn",
  },
  {
    npa: "8782",
    canton: "GL",
    location: "Rüti GL",
  },
  {
    npa: "8802",
    canton: "ZH",
    location: "Kilchberg ZH",
  },
  {
    npa: "8803",
    canton: "ZH",
    location: "Rüschlikon",
  },
  {
    npa: "8810",
    canton: "ZH",
    location: "Horgen",
  },
  {
    npa: "8815",
    canton: "ZH",
    location: "Horgenberg",
  },
  {
    npa: "8816",
    canton: "ZH",
    location: "Hirzel",
  },
  {
    npa: "8825",
    canton: "ZH",
    location: "Hütten",
  },
  {
    npa: "8840",
    canton: "SZ",
    location: "Trachslau",
  },
  {
    npa: "8854",
    canton: "SZ",
    location: "Galgenen",
  },
  {
    npa: "8855",
    canton: "SZ",
    location: "Wangen SZ",
  },
  {
    npa: "8858",
    canton: "SZ",
    location: "Innerthal",
  },
  {
    npa: "8866",
    canton: "GL",
    location: "Ziegelbrücke",
  },
  {
    npa: "8758",
    canton: "GL",
    location: "Obstalden",
  },
  {
    npa: "8757",
    canton: "GL",
    location: "Filzbach",
  },
  {
    npa: "8877",
    canton: "SG",
    location: "Murg",
  },
  {
    npa: "8881",
    canton: "SG",
    location: "Walenstadtberg",
  },
  {
    npa: "8882",
    canton: "SG",
    location: "Unterterzen",
  },
  {
    npa: "8889",
    canton: "SG",
    location: "Plons",
  },
  {
    npa: "8890",
    canton: "SG",
    location: "Flums",
  },
  {
    npa: "8897",
    canton: "SG",
    location: "Flumserberg Tannenheim",
  },
  {
    npa: "8902",
    canton: "ZH",
    location: "Urdorf",
  },
  {
    npa: "8905",
    canton: "AG",
    location: "Arni-Islisberg",
  },
  {
    npa: "8910",
    canton: "ZH",
    location: "Affoltern am Albis",
  },
  {
    npa: "8911",
    canton: "ZH",
    location: "Rifferswil",
  },
  {
    npa: "8914",
    canton: "ZH",
    location: "Aeugstertal",
  },
  {
    npa: "8916",
    canton: "AG",
    location: "Jonen",
  },
  {
    npa: "8917",
    canton: "AG",
    location: "Oberlunkhofen",
  },
  {
    npa: "8918",
    canton: "AG",
    location: "Unterlunkhofen",
  },
  {
    npa: "8919",
    canton: "AG",
    location: "Rottenschwil",
  },
  {
    npa: "8932",
    canton: "ZH",
    location: "Mettmenstetten",
  },
  {
    npa: "8955",
    canton: "ZH",
    location: "Oetwil an der Limmat",
  },
  {
    npa: "8956",
    canton: "AG",
    location: "Killwangen",
  },
  {
    npa: "8964",
    canton: "AG",
    location: "Rudolfstetten",
  },
  {
    npa: "9000",
    canton: "SG",
    location: "St. Gallen Dist Ba",
  },
  {
    npa: "9007",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9010",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9012",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9034",
    canton: "SG",
    location: "Eggersriet",
  },
  {
    npa: "9037",
    canton: "AR",
    location: "Speicherschwendi",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell",
  },
  {
    npa: "9055",
    canton: "AR",
    location: "Bühler",
  },
  {
    npa: "9056",
    canton: "AR",
    location: "Gais",
  },
  {
    npa: "9062",
    canton: "AR",
    location: "Lustmühle",
  },
  {
    npa: "9105",
    canton: "AR",
    location: "Schönengrund",
  },
  {
    npa: "9112",
    canton: "AR",
    location: "Schachen b. Herisau",
  },
  {
    npa: "9114",
    canton: "SG",
    location: "Hoffeld",
  },
  {
    npa: "9116",
    canton: "SG",
    location: "Wolfertswil",
  },
  {
    npa: "9122",
    canton: "SG",
    location: "Mogelsberg",
  },
  {
    npa: "9125",
    canton: "SG",
    location: "Brunnadern",
  },
  {
    npa: "9216",
    canton: "TG",
    location: "Heldswil",
  },
  {
    npa: "9215",
    canton: "TG",
    location: "Buhwil",
  },
  {
    npa: "9230",
    canton: "SG",
    location: "Flawil",
  },
  {
    npa: "9230",
    canton: "SG",
    location: "Flawil 1",
  },
  {
    npa: "9604",
    canton: "SG",
    location: "Oberrindal",
  },
  {
    npa: "9240",
    canton: "SG",
    location: "Uzwil",
  },
  {
    npa: "9240",
    canton: "SG",
    location: "Niederglatt SG",
  },
  {
    npa: "9244",
    canton: "SG",
    location: "Niederuzwil",
  },
  {
    npa: "9246",
    canton: "SG",
    location: "Niederbüren",
  },
  {
    npa: "9247",
    canton: "SG",
    location: "Henau",
  },
  {
    npa: "9306",
    canton: "TG",
    location: "Freidorf TG",
  },
  {
    npa: "9326",
    canton: "TG",
    location: "Horn",
  },
  {
    npa: "9327",
    canton: "SG",
    location: "Tübach",
  },
  {
    npa: "9400",
    canton: "SG",
    location: "Rorschach",
  },
  {
    npa: "9426",
    canton: "AR",
    location: "Lutzenberg",
  },
  {
    npa: "9427",
    canton: "AR",
    location: "Wolfhalden",
  },
  {
    npa: "9434",
    canton: "SG",
    location: "Au SG",
  },
  {
    npa: "9442",
    canton: "SG",
    location: "Berneck",
  },
  {
    npa: "9462",
    canton: "SG",
    location: "Montlingen",
  },
  {
    npa: "9463",
    canton: "SG",
    location: "Oberriet SG",
  },
  {
    npa: "9464",
    canton: "SG",
    location: "Lienz",
  },
  {
    npa: "9468",
    canton: "SG",
    location: "Sax",
  },
  {
    npa: "9472",
    canton: "SG",
    location: "Grabserberg",
  },
  {
    npa: "9473",
    canton: "SG",
    location: "Gams",
  },
  {
    npa: "9475",
    canton: "SG",
    location: "Sevelen",
  },
  {
    npa: "9492",
    canton: "FL",
    location: "Eschen",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan",
  },
  {
    npa: "9495",
    canton: "FL",
    location: "Triesen",
  },
  {
    npa: "9496",
    canton: "FL",
    location: "Balzers",
  },
  {
    npa: "9504",
    canton: "TG",
    location: "Friltschen",
  },
  {
    npa: "9512",
    canton: "SG",
    location: "Rossrüti",
  },
  {
    npa: "9514",
    canton: "TG",
    location: "Wuppenau",
  },
  {
    npa: "9515",
    canton: "TG",
    location: "Hosenruck",
  },
  {
    npa: "9565",
    canton: "TG",
    location: "Rothenhausen",
  },
  {
    npa: "9523",
    canton: "SG",
    location: "Züberwangen",
  },
  {
    npa: "9524",
    canton: "SG",
    location: "Zuzwil SG",
  },
  {
    npa: "9527",
    canton: "SG",
    location: "Niederhelfenschwil",
  },
  {
    npa: "9535",
    canton: "TG",
    location: "Wilen b. Wil",
  },
  {
    npa: "9548",
    canton: "TG",
    location: "Matzingen",
  },
  {
    npa: "9554",
    canton: "TG",
    location: "Tägerschen",
  },
  {
    npa: "9562",
    canton: "TG",
    location: "Märwil",
  },
  {
    npa: "9565",
    canton: "TG",
    location: "Oppikon",
  },
  {
    npa: "9601",
    canton: "SG",
    location: "Lütisburg Station",
  },
  {
    npa: "9606",
    canton: "SG",
    location: "Bütschwil",
  },
  {
    npa: "9607",
    canton: "SG",
    location: "Mosnang",
  },
  {
    npa: "9622",
    canton: "SG",
    location: "Krinau",
  },
  {
    npa: "9630",
    canton: "SG",
    location: "Wattwil",
  },
  {
    npa: "9633",
    canton: "SG",
    location: "Hemberg",
  },
  {
    npa: "9657",
    canton: "SG",
    location: "Unterwasser",
  },
  {
    npa: "3113",
    canton: "BE",
    location: "Rubigen",
  },
  {
    npa: "3900",
    canton: "VS",
    location: "Brig Zustellung",
  },
  {
    npa: "7003",
    canton: "GR",
    location: "Chur Postauto GR Reg. Chur",
  },
  {
    npa: "3024",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Sundlauenen",
  },
  {
    npa: "3030",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "1031",
    canton: "VD",
    location: "Mex VD",
  },
  {
    npa: "3044",
    canton: "BE",
    location: "Innerberg",
  },
  {
    npa: "3700",
    canton: "BE",
    location: "Spiezwiler",
  },
  {
    npa: "6908",
    canton: "TI",
    location: "Massagno Caselle",
  },
  {
    npa: "1267",
    canton: "VD",
    location: "Coinsins",
  },
  {
    npa: "8044",
    canton: "ZH",
    location: "Gockhausen",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg 1 Dépôt",
  },
  {
    npa: "8614",
    canton: "ZH",
    location: "Sulzbach",
  },
  {
    npa: "1595",
    canton: "BE",
    location: "Clavaleyres",
  },
  {
    npa: "2735",
    canton: "BE",
    location: "Champoz",
  },
  {
    npa: "2556",
    canton: "BE",
    location: "Schwadernau",
  },
  {
    npa: "1470",
    canton: "FR",
    location: "Bollion",
  },
  {
    npa: "1475",
    canton: "FR",
    location: "Forel FR",
  },
  {
    npa: "1484",
    canton: "FR",
    location: "Granges-de-Vesin",
  },
  {
    npa: "1475",
    canton: "FR",
    location: "Montbrelloz",
  },
  {
    npa: "1410",
    canton: "FR",
    location: "Prévondavaux",
  },
  {
    npa: "1773",
    canton: "FR",
    location: "Russy",
  },
  {
    npa: "1694",
    canton: "FR",
    location: "Chavannes-sous-Orsonnens",
  },
  {
    npa: "1697",
    canton: "FR",
    location: "Les Ecasseys",
  },
  {
    npa: "1674",
    canton: "FR",
    location: "Montet (Glâne)",
  },
  {
    npa: "1653",
    canton: "FR",
    location: "Châtel-sur-Montsalvens",
  },
  {
    npa: "1652",
    canton: "FR",
    location: "Villarbeney",
  },
  {
    npa: "1666",
    canton: "FR",
    location: "Villars-sous-Mont",
  },
  {
    npa: "1730",
    canton: "FR",
    location: "Ecuvillens",
  },
  {
    npa: "1756",
    canton: "FR",
    location: "Lovens",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Pierrafortscha",
  },
  {
    npa: "1772",
    canton: "FR",
    location: "Ponthaux",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Zénauva",
  },
  {
    npa: "1721",
    canton: "FR",
    location: "Cormérod",
  },
  {
    npa: "1721",
    canton: "FR",
    location: "Courtion",
  },
  {
    npa: "1716",
    canton: "FR",
    location: "Oberschrot",
  },
  {
    npa: "1719",
    canton: "FR",
    location: "Zumholz",
  },
  {
    npa: "1174",
    canton: "VD",
    location: "Pizy",
  },
  {
    npa: "1787",
    canton: "VD",
    location: "Mur (Vully) VD",
  },
  {
    npa: "1580",
    canton: "VD",
    location: "Oleyres",
  },
  {
    npa: "1428",
    canton: "VD",
    location: "Mutrux",
  },
  {
    npa: "1423",
    canton: "VD",
    location: "Vaugondry",
  },
  {
    npa: "1008",
    canton: "VD",
    location: "Jouxtens-Mézery",
  },
  {
    npa: "1410",
    canton: "VD",
    location: "Correvon",
  },
  {
    npa: "1683",
    canton: "VD",
    location: "Sarzens",
  },
  {
    npa: "1277",
    canton: "VD",
    location: "Arnex-sur-Nyon",
  },
  {
    npa: "1279",
    canton: "VD",
    location: "Bogis-Bossey",
  },
  {
    npa: "1355",
    canton: "VD",
    location: "Sergey",
  },
  {
    npa: "1608",
    canton: "VD",
    location: "Chesalles-sur-Oron",
  },
  {
    npa: "1607",
    canton: "VD",
    location: "Les Tavernes",
  },
  {
    npa: "1407",
    canton: "VD",
    location: "Gossens",
  },
  {
    npa: "1407",
    canton: "VD",
    location: "Mézery-près-Donneloye",
  },
  {
    npa: "1412",
    canton: "VD",
    location: "Ursins",
  },
  {
    npa: "2063",
    canton: "NE",
    location: "Engollon",
  },
  {
    npa: "8165",
    canton: "ZH",
    location: "Oberweningen",
  },
  {
    npa: "8165",
    canton: "ZH",
    location: "Schleinikon",
  },
  {
    npa: "8543",
    canton: "ZH",
    location: "Bertschikon",
  },
  {
    npa: "4932",
    canton: "BE",
    location: "Gutenburg",
  },
  {
    npa: "3294",
    canton: "BE",
    location: "Meienried",
  },
  {
    npa: "3429",
    canton: "BE",
    location: "Höchstetten",
  },
  {
    npa: "2577",
    canton: "BE",
    location: "Finsterhennen",
  },
  {
    npa: "3303",
    canton: "BE",
    location: "Ballmoos",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Diemerswil",
  },
  {
    npa: "3251",
    canton: "BE",
    location: "Ruppoldsried",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Wiggiswil",
  },
  {
    npa: "3532",
    canton: "BE",
    location: "Mirchel",
  },
  {
    npa: "3629",
    canton: "BE",
    location: "Oppligen",
  },
  {
    npa: "3086",
    canton: "BE",
    location: "Englisberg",
  },
  {
    npa: "3623",
    canton: "BE",
    location: "Horrenbach",
  },
  {
    npa: "3624",
    canton: "BE",
    location: "Schwendibach",
  },
  {
    npa: "3645",
    canton: "BE",
    location: "Zwieselberg",
  },
  {
    npa: "3367",
    canton: "BE",
    location: "Ochlenberg",
  },
  {
    npa: "6289",
    canton: "LU",
    location: "Hämikon",
  },
  {
    npa: "6284",
    canton: "LU",
    location: "Sulz LU",
  },
  {
    npa: "6038",
    canton: "LU",
    location: "Honau",
  },
  {
    npa: "3215",
    canton: "FR",
    location: "Büchslen",
  },
  {
    npa: "3213",
    canton: "FR",
    location: "Kleinbösingen",
  },
  {
    npa: "4583",
    canton: "SO",
    location: "Aetigkofen",
  },
  {
    npa: "4556",
    canton: "SO",
    location: "Bolken",
  },
  {
    npa: "4556",
    canton: "SO",
    location: "Burgäschi",
  },
  {
    npa: "4566",
    canton: "SO",
    location: "Halten",
  },
  {
    npa: "4558",
    canton: "SO",
    location: "Winistorf",
  },
  {
    npa: "7456",
    canton: "GR",
    location: "Marmorera",
  },
  {
    npa: "7116",
    canton: "GR",
    location: "St. Martin (Lugnez)",
  },
  {
    npa: "7415",
    canton: "GR",
    location: "Pratval",
  },
  {
    npa: "7027",
    canton: "GR",
    location: "Calfreisen",
  },
  {
    npa: "2912",
    canton: "JU",
    location: "Réclère",
  },
  {
    npa: "5012",
    canton: "SO",
    location: "Eppenberg",
  },
  {
    npa: "5637",
    canton: "AG",
    location: "Geltwil",
  },
  {
    npa: "8253",
    canton: "TG",
    location: "Willisdorf",
  },
  {
    npa: "8553",
    canton: "TG",
    location: "Harenwilen",
  },
  {
    npa: "8573",
    canton: "TG",
    location: "Altishausen",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Schönenbaumgarten",
  },
  {
    npa: "8360",
    canton: "TG",
    location: "Wallenwil",
  },
  {
    npa: "8537",
    canton: "TG",
    location: "Uerschhausen",
  },
  {
    npa: "8572",
    canton: "TG",
    location: "Andhausen",
  },
  {
    npa: "8572",
    canton: "TG",
    location: "Guntershausen b. Berg",
  },
  {
    npa: "8575",
    canton: "TG",
    location: "Istighofen",
  },
  {
    npa: "6720",
    canton: "TI",
    location: "Ghirone",
  },
  {
    npa: "3995",
    canton: "VS",
    location: "Ausserbinn",
  },
  {
    npa: "3995",
    canton: "VS",
    location: "Mühlebach (Goms)",
  },
  {
    npa: "3957",
    canton: "VS",
    location: "Bratsch",
  },
  {
    npa: "3983",
    canton: "VS",
    location: "Goppisberg",
  },
  {
    npa: "3994",
    canton: "VS",
    location: "Martisberg",
  },
  {
    npa: "8525",
    canton: "TG",
    location: "Wilen b. Neunforn",
  },
  {
    npa: "2735",
    canton: "BE",
    location: "Malleray",
  },
  {
    npa: "1626",
    canton: "FR",
    location: "Treyfayes",
  },
  {
    npa: "5012",
    canton: "SO",
    location: "Wöschnau",
  },
  {
    npa: "8553",
    canton: "TG",
    location: "Hüttlingen",
  },
  {
    npa: "8427",
    canton: "ZH",
    location: "Rorbas",
  },
  {
    npa: "6995",
    canton: "TI",
    location: "Madonna del Piano",
  },
  {
    npa: "7743",
    canton: "GR",
    location: "Miralago",
  },
  {
    npa: "8471",
    canton: "ZH",
    location: "Dägerlen",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Paradiso",
  },
  {
    npa: "1783",
    canton: "FR",
    location: "Barberêche",
  },
  {
    npa: "1774",
    canton: "FR",
    location: "Montagny-les-Monts",
  },
  {
    npa: "1110",
    canton: "VD",
    location: "Morges Grosse Pierre",
  },
  {
    npa: "9029",
    canton: "SG",
    location: "St. Gallen Sonderdienste",
  },
  {
    npa: "1585",
    canton: "VD",
    location: "Bellerive VD",
  },
  {
    npa: "4922",
    canton: "BE",
    location: "Thunstetten",
  },
  {
    npa: "8879",
    canton: "SG",
    location: "Pizolpark (Mels)",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Radio Schweiz",
  },
  {
    npa: "3900",
    canton: "VS",
    location: "Brigerbad",
  },
  {
    npa: "1811",
    canton: "VD",
    location: "Vevey Services spéciaux",
  },
  {
    npa: "8832",
    canton: "SZ",
    location: "Wilen b. Wollerau",
  },
  {
    npa: "1775",
    canton: "FR",
    location: "Mannens",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 14",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon car postal VD-Broye",
  },
  {
    npa: "1951",
    canton: "VS",
    location: "Sion car postal VS-Léman",
  },
  {
    npa: "5405",
    canton: "AG",
    location: "Dättwil AG",
  },
  {
    npa: "6343",
    canton: "ZG",
    location: "Buonas",
  },
  {
    npa: "6343",
    canton: "ZG",
    location: "Risch",
  },
  {
    npa: "3945",
    canton: "VS",
    location: "Niedergampel",
  },
  {
    npa: "4571",
    canton: "SO",
    location: "Lüterkofen",
  },
  {
    npa: "8070",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "1631",
    canton: "FR",
    location: "Bulle centre de traitement",
  },
  {
    npa: "6007",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "9503",
    canton: "TG",
    location: "Lanterswil",
  },
  {
    npa: "1279",
    canton: "VD",
    location: "Chavannes-de-Bogis OLS",
  },
  {
    npa: "4573",
    canton: "SO",
    location: "Lohn-Ammannsegg",
  },
  {
    npa: "8152",
    canton: "ZH",
    location: "Glattbrugg Zustellung",
  },
  {
    npa: "6391",
    canton: "OW",
    location: "Engelberg",
  },
  {
    npa: "6371",
    canton: "NW",
    location: "Stans",
  },
  {
    npa: "9471",
    canton: "SG",
    location: "Buchs SG 1",
  },
  {
    npa: "9471",
    canton: "SG",
    location: "Buchs SG 3",
  },
  {
    npa: "1260",
    canton: "VD",
    location: "Nyon 1",
  },
  {
    npa: "1530",
    canton: "VD",
    location: "Payerne Caserne",
  },
  {
    npa: "8903",
    canton: "ZH",
    location: "Birmensdorf ZH Kaserne",
  },
  {
    npa: "8180",
    canton: "ZH",
    location: "Bülach Kaserne",
  },
  {
    npa: "8600",
    canton: "ZH",
    location: "Dübendorf Kaserne",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld Kaserne",
  },
  {
    npa: "5620",
    canton: "AG",
    location: "Bremgarten AG West",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont car postal JU-NE",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 70",
  },
  {
    npa: "4509",
    canton: "SO",
    location: "Solothurn",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Sarnen 1",
  },
  {
    npa: "1958",
    canton: "VS",
    location: "Uvrier",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 5",
  },
  {
    npa: "4000",
    canton: "BS",
    location: "Basel Postauto Nordwestschw",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Mehlsecken",
  },
  {
    npa: "6161",
    canton: "LU",
    location: "Entlebuch Versandzentrum",
  },
  {
    npa: "9201",
    canton: "SG",
    location: "Gossau SG",
  },
  {
    npa: "3050",
    canton: "BE",
    location: "Bern Swisscom",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano 8",
  },
  {
    npa: "7431",
    canton: "GR",
    location: "Obermutten",
  },
  {
    npa: "1892",
    canton: "VD",
    location: "Lavey-les-Bains",
  },
  {
    npa: "8260",
    canton: "SH",
    location: "Stein am Rhein 1",
  },
  {
    npa: "9214",
    canton: "TG",
    location: "Kradolf",
  },
  {
    npa: "2013",
    canton: "NE",
    location: "Colombier NE Caserne",
  },
  {
    npa: "1680",
    canton: "FR",
    location: "Romont Caserne",
  },
  {
    npa: "6032",
    canton: "LU",
    location: "Emmen Kaserne",
  },
  {
    npa: "2900",
    canton: "JU",
    location: "Porrentruy Caserne",
  },
  {
    npa: "8640",
    canton: "SZ",
    location: "Hurden",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle 2",
  },
  {
    npa: "6460",
    canton: "UR",
    location: "Altdorf UR 2",
  },
  {
    npa: "1721",
    canton: "FR",
    location: "Misery",
  },
  {
    npa: "2010",
    canton: "NE",
    location: "Neuchâtel OFS",
  },
  {
    npa: "2510",
    canton: "BE",
    location: "Biel/Bienne Dist Ba",
  },
  {
    npa: "8210",
    canton: "SH",
    location: "Schaffhausen Dist Ba",
  },
  {
    npa: "1225",
    canton: "GE",
    location: "Chêne-Bourg Distribution",
  },
  {
    npa: "1196",
    canton: "VD",
    location: "Gland Distribution",
  },
  {
    npa: "1218",
    canton: "GE",
    location: "Grand-Saconnex Distribution",
  },
  {
    npa: "1219",
    canton: "GE",
    location: "Le Lignon Distribution",
  },
  {
    npa: "1217",
    canton: "GE",
    location: "Meyrin Distribution",
  },
  {
    npa: "4242",
    canton: "BL",
    location: "Laufen Zustellung",
  },
  {
    npa: "4132",
    canton: "BL",
    location: "Muttenz 1 Zustellung",
  },
  {
    npa: "4153",
    canton: "BL",
    location: "Reinach BL Zustellung",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Lenzburg Zustellung",
  },
  {
    npa: "5610",
    canton: "AG",
    location: "Wohlen AG 1 Zustellung",
  },
  {
    npa: "6430",
    canton: "SZ",
    location: "Schwyz Zustellung",
  },
  {
    npa: "6210",
    canton: "LU",
    location: "Sursee Zustellung",
  },
  {
    npa: "8580",
    canton: "TG",
    location: "Amriswil Zustellung",
  },
  {
    npa: "9220",
    canton: "TG",
    location: "Bischofszell Zustellung",
  },
  {
    npa: "8180",
    canton: "ZH",
    location: "Bülach Zustellung",
  },
  {
    npa: "8157",
    canton: "ZH",
    location: "Dielsdorf Zustellung",
  },
  {
    npa: "8424",
    canton: "ZH",
    location: "Embrach Zustellung",
  },
  {
    npa: "8706",
    canton: "ZH",
    location: "Meilen Zustellung",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Sierre Distribution",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Crans-Montana 1 Dist",
  },
  {
    npa: "3123",
    canton: "BE",
    location: "Belp Zustellung",
  },
  {
    npa: "8590",
    canton: "TG",
    location: "Romanshorn Zustellung",
  },
  {
    npa: "4950",
    canton: "BE",
    location: "Huttwil Zustellung",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Interlaken Zustellung",
  },
  {
    npa: "4900",
    canton: "BE",
    location: "Langenthal 1 Zustellung",
  },
  {
    npa: "8050",
    canton: "ZH",
    location: "Zürich 50 Zustellung",
  },
  {
    npa: "9100",
    canton: "AR",
    location: "Herisau 1 Zustellung",
  },
  {
    npa: "3322",
    canton: "BE",
    location: "Urtenen-Schönbühl Zust",
  },
  {
    npa: "8340",
    canton: "ZH",
    location: "Hinwil Zustellung",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG 1 Zustellung",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil Zustellung",
  },
  {
    npa: "9476",
    canton: "SG",
    location: "Fontnas",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 30 AAL",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont Dist Ba",
  },
  {
    npa: "3550",
    canton: "BE",
    location: "Langnau i. E. Zustellung",
  },
  {
    npa: "1274",
    canton: "VD",
    location: "Signy",
  },
  {
    npa: "4620",
    canton: "SO",
    location: "Härkingen Paketzentrum",
  },
  {
    npa: "4620",
    canton: "SO",
    location: "Härkingen Dist Ba",
  },
  {
    npa: "8183",
    canton: "ZH",
    location: "Bülach Dist Ba",
  },
  {
    npa: "8343",
    canton: "ZH",
    location: "Hinwil Dist Ba",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Hefenhausen",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Buch b. Kümmertshausen",
  },
  {
    npa: "1347",
    canton: "VD",
    location: "Le Solliat",
  },
  {
    npa: "1304",
    canton: "VD",
    location: "Allens",
  },
  {
    npa: "1196",
    canton: "VD",
    location: "Gland Dist Fil",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 65 SBB",
  },
  {
    npa: "1933",
    canton: "VS",
    location: "Vens (Sembrancher)",
  },
  {
    npa: "1933",
    canton: "VS",
    location: "La Garde (Sembrancher)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Fontaine Dessous (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Vichères (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Chez Petit (Liddes)",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "Les Vérines (Chamoson)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Fortunau (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "St-Romain (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Saxonne (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Villa (Ayent)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Botyre (Ayent)",
  },
  {
    npa: "1971",
    canton: "VS",
    location: "Champlan (Grimisuat)",
  },
  {
    npa: "1991",
    canton: "VS",
    location: "Arvillard (Salins)",
  },
  {
    npa: "1991",
    canton: "VS",
    location: "Turin (Salins)",
  },
  {
    npa: "1993",
    canton: "VS",
    location: "Clèbes (Nendaz)",
  },
  {
    npa: "1992",
    canton: "VS",
    location: "Crête-à-l'Oeil(Les Agettes)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Bieudron (Nendaz)",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "Mayens-de-Chamoson",
  },
  {
    npa: "1585",
    canton: "VD",
    location: "Cotterd",
  },
  {
    npa: "2013",
    canton: "NE",
    location: "Colombier NE Distribution",
  },
  {
    npa: "1580",
    canton: "VD",
    location: "Avenches Distribution",
  },
  {
    npa: "1510",
    canton: "VD",
    location: "Moudon Distribution",
  },
  {
    npa: "1233",
    canton: "GE",
    location: "Bernex Distribution",
  },
  {
    npa: "1040",
    canton: "VD",
    location: "Echallens Distribution",
  },
  {
    npa: "3150",
    canton: "BE",
    location: "Schwarzenburg Zustellung",
  },
  {
    npa: "3294",
    canton: "BE",
    location: "Büren an der Aare Zust",
  },
  {
    npa: "3860",
    canton: "BE",
    location: "Meiringen Zustellung",
  },
  {
    npa: "4663",
    canton: "AG",
    location: "Aarburg Zustellung",
  },
  {
    npa: "5036",
    canton: "AG",
    location: "Oberentfelden Zustellung",
  },
  {
    npa: "6110",
    canton: "LU",
    location: "Wolhusen Zustellung",
  },
  {
    npa: "6314",
    canton: "ZG",
    location: "Unterägeri Zustellung",
  },
  {
    npa: "8730",
    canton: "SG",
    location: "Uznach Zustellung",
  },
  {
    npa: "9240",
    canton: "SG",
    location: "Uzwil Zustellung",
  },
  {
    npa: "9630",
    canton: "SG",
    location: "Wattwil Zustellung",
  },
  {
    npa: "7013",
    canton: "GR",
    location: "Domat/Ems Zustellung",
  },
  {
    npa: "7302",
    canton: "GR",
    location: "Landquart Zustellung",
  },
  {
    npa: "7320",
    canton: "SG",
    location: "Sargans Zustellung",
  },
  {
    npa: "8887",
    canton: "SG",
    location: "Mels Zustellung",
  },
  {
    npa: "8890",
    canton: "SG",
    location: "Flums Zustellung",
  },
  {
    npa: "1222",
    canton: "GE",
    location: "Vésenaz Distribution",
  },
  {
    npa: "8540",
    canton: "TG",
    location: "Frauenfeld CIRCLE",
  },
  {
    npa: "1330",
    canton: "VD",
    location: "Daillens CALL",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens 2 Sternmatt",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Grafschaft",
  },
  {
    npa: "2149",
    canton: "NE",
    location: "Fretereules",
  },
  {
    npa: "9249",
    canton: "SG",
    location: "Niederstetten",
  },
  {
    npa: "3815",
    canton: "BE",
    location: "Gündlischwand",
  },
  {
    npa: "3860",
    canton: "BE",
    location: "Schattenhalb",
  },
  {
    npa: "6039",
    canton: "LU",
    location: "Root D4",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Crans-Montana 2",
  },
  {
    npa: "8253",
    canton: "TG",
    location: "Diessenhofen Zustellung",
  },
  {
    npa: "6422",
    canton: "SZ",
    location: "Steinen Zustellung",
  },
  {
    npa: "1964",
    canton: "VS",
    location: "Conthey Distribution",
  },
  {
    npa: "4035",
    canton: "BS",
    location: "Basel 35 Bäumlihof",
  },
  {
    npa: "1618",
    canton: "FR",
    location: "Châtel-St-Denis Dist",
  },
  {
    npa: "1965",
    canton: "VS",
    location: "Savièse Distribution",
  },
  {
    npa: "3623",
    canton: "BE",
    location: "Buchen BE",
  },
  {
    npa: "4089",
    canton: "BS",
    location: "Basel SPILOG",
  },
  {
    npa: "9545",
    canton: "TG",
    location: "Wängi Zustellung",
  },
  {
    npa: "9113",
    canton: "SG",
    location: "Degersheim Zustellung",
  },
  {
    npa: "8762",
    canton: "GL",
    location: "Schwanden GL Zustellung",
  },
  {
    npa: "5415",
    canton: "AG",
    location: "Hertenstein AG",
  },
  {
    npa: "3976",
    canton: "VS",
    location: "Champzabé",
  },
  {
    npa: "3537",
    canton: "BE",
    location: "Eggiwil Zustellung",
  },
  {
    npa: "3818",
    canton: "BE",
    location: "Grindelwald Zustellung",
  },
  {
    npa: "5605",
    canton: "AG",
    location: "Dottikon Zustellung",
  },
  {
    npa: "5703",
    canton: "AG",
    location: "Seon Zustellung",
  },
  {
    npa: "6037",
    canton: "LU",
    location: "Root Zustellung",
  },
  {
    npa: "6374",
    canton: "NW",
    location: "Buochs Zustellung",
  },
  {
    npa: "3071",
    canton: "BE",
    location: "Ostermundigen RPZ J",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan Zustellung",
  },
  {
    npa: "3175",
    canton: "FR",
    location: "Flamatt Zustellung",
  },
  {
    npa: "6017",
    canton: "LU",
    location: "Ruswil Zustellung",
  },
  {
    npa: "6182",
    canton: "LU",
    location: "Escholzmatt Zustellung",
  },
  {
    npa: "8494",
    canton: "ZH",
    location: "Bauma Zustellung",
  },
  {
    npa: "6204",
    canton: "LU",
    location: "Sempach Stadt",
  },
  {
    npa: "9489",
    canton: "FL",
    location: "Schaan Log",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Lätti",
  },
  {
    npa: "3256",
    canton: "BE",
    location: "Seewil",
  },
  {
    npa: "1607",
    canton: "VD",
    location: "Palézieux Distribution",
  },
  {
    npa: "7513",
    canton: "GR",
    location: "Silvaplana-Surlej",
  },
  {
    npa: "4089",
    canton: "BS",
    location: "Basel SPI GLS Retour",
  },
  {
    npa: "8109",
    canton: "AG",
    location: "Kloster Fahr",
  },
  {
    npa: "1019",
    canton: "VD",
    location: "Lausanne Services spéciaux",
  },
  {
    npa: "6346",
    canton: "ZG",
    location: "Baar 3 Dist Ba",
  },
  {
    npa: "3085",
    canton: "BE",
    location: "Wabern 2 x Weihnachten",
  },
  {
    npa: "8952",
    canton: "ZH",
    location: "Schlieren DocumentServices",
  },
  {
    npa: "3050",
    canton: "BE",
    location: "Bern DocumentServices",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens Logistikzentrum",
  },
  {
    npa: "6590",
    canton: "TI",
    location: "Cadenazzo Centro logistico",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG Logistikzentrum",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "La Coudre",
  },
  {
    npa: "8352",
    canton: "ZH",
    location: "Ricketwil (Winterthur)",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln SPI Logistics",
  },
  {
    npa: "4621",
    canton: "SO",
    location: "Härkingen SPS AG",
  },
  {
    npa: "4809",
    canton: "AG",
    location: "Zofingen PF UBS Verarb.",
  },
  {
    npa: "6039",
    canton: "LU",
    location: "Root Längenbold SH",
  },
  {
    npa: "8545",
    canton: "ZH",
    location: "Rickenbach Sulz",
  },
  {
    npa: "8471",
    canton: "ZH",
    location: "Bänk (Dägerlen)",
  },
  {
    npa: "9301",
    canton: "SG",
    location: "Wittenbach",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens CC Dépôt",
  },
  {
    npa: "8412",
    canton: "ZH",
    location: "Hünikon (Neftenbach)",
  },
  {
    npa: "6511",
    canton: "TI",
    location: "Cadenazzo Scanning-Center",
  },
  {
    npa: "5643",
    canton: "AG",
    location: "Meienberg",
  },
  {
    npa: "3823",
    canton: "BE",
    location: "Kleine Scheidegg",
  },
  {
    npa: "3823",
    canton: "BE",
    location: "Eigergletscher",
  },
  {
    npa: "1532",
    canton: "FR",
    location: "Fétigny Dist Fil",
  },
  {
    npa: "8200",
    canton: "SH",
    location: "Schaffhausen PF",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Brünnen",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Eggerstanden",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Enggenhütten",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Schlatt",
  },
  {
    npa: "9442",
    canton: "AI",
    location: "Büriswilen",
  },
  {
    npa: "6595",
    canton: "TI",
    location: "Riazzino Distribuzione",
  },
  {
    npa: "6987",
    canton: "TI",
    location: "Caslano Distribuzione",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil Dist Ba",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil GKA",
  },
  {
    npa: "8307",
    canton: "ZH",
    location: "Effretikon PostAuto AG",
  },
  {
    npa: "9245",
    canton: "SG",
    location: "Sonnental",
  },
  {
    npa: "1227",
    canton: "GE",
    location: "Genève PF Fil Carouge",
  },
  {
    npa: "2340",
    canton: "JU",
    location: "Le Noirmont Distribution",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 2 Universität",
  },
  {
    npa: "3206",
    canton: "BE",
    location: "Ferenbalm",
  },
  {
    npa: "3206",
    canton: "BE",
    location: "Biberen",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen B&V GK",
  },
  {
    npa: "8852",
    canton: "SZ",
    location: "Altendorf B&V GK 2",
  },
  {
    npa: "1905",
    canton: "VS",
    location: "Dorénaz PostAuto AG Schweiz",
  },
  {
    npa: "9030",
    canton: "SG",
    location: "St. Josefen",
  },
  {
    npa: "8723",
    canton: "SG",
    location: "Maseltrangen",
  },
  {
    npa: "1985",
    canton: "VS",
    location: "Villa (Evolène)",
  },
  {
    npa: "5412",
    canton: "AG",
    location: "Vogelsang AG",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens CC AP",
  },
  {
    npa: "1997",
    canton: "VS",
    location: "Sornard (Nendaz)",
  },
  {
    npa: "8371",
    canton: "TG",
    location: "Busswil TG",
  },
  {
    npa: "6023",
    canton: "LU",
    location: "Rothenburg DIST BA",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich BZ Briefklinik",
  },
  {
    npa: "4144",
    canton: "BL",
    location: "Arlesheim Postlogistics Hub",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève Les Acacias",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Les Posses-sur-Bex",
  },
  {
    npa: "6590",
    canton: "TI",
    location: "Cadenazzo CLL AP",
  },
  {
    npa: "1580",
    canton: "VD",
    location: "Donatyre",
  },
  {
    npa: "8006",
    canton: "ZH",
    location: "Zürich Asendia",
  },
  {
    npa: "1532",
    canton: "FR",
    location: "Fétigny PL3",
  },
  {
    npa: "4704",
    canton: "BE",
    location: "Niederbipp PL3",
  },
  {
    npa: "8422",
    canton: "ZH",
    location: "Pfungen PL3",
  },
  {
    npa: "8902",
    canton: "ZH",
    location: "Urdorf PL3",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens Contact Center",
  },
  {
    npa: "1232",
    canton: "GE",
    location: "Confignon Cressy",
  },
  {
    npa: "8509",
    canton: "TG",
    location: "Frauenfeld",
  },
  {
    npa: "3030",
    canton: "BE",
    location: "Bern PAS",
  },
  {
    npa: "8403",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "6601",
    canton: "TI",
    location: "Locarno SPS",
  },
  {
    npa: "6830",
    canton: "TI",
    location: "Chiasso SPS",
  },
  {
    npa: "6901",
    canton: "TI",
    location: "Lugano SPS",
  },
  {
    npa: "5463",
    canton: "AG",
    location: "Wislikofen Probsteiweg",
  },
  {
    npa: "8404",
    canton: "ZH",
    location: "Winterthur im LInk",
  },
  {
    npa: "9464",
    canton: "SG",
    location: "Rüthi (Rheintal) Staatstr",
  },
  {
    npa: "1305",
    canton: "VD",
    location: "Penthalaz Distribution",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld Murgstrasse",
  },
  {
    npa: "8047",
    canton: "ZH",
    location: "Zürich Triemli",
  },
  {
    npa: "6960",
    canton: "TI",
    location: "Odogno",
  },
  {
    npa: "8489",
    canton: "ZH",
    location: "Ehrikon",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens AVJ",
  },
  {
    npa: "8370",
    canton: "TG",
    location: "Sirnach Distributionsbasis",
  },
  {
    npa: "6330",
    canton: "ZG",
    location: "Cham Hinterbergstrasse",
  },
  {
    npa: "6616",
    canton: "TI",
    location: "Losone via Locarno",
  },
  {
    npa: "8301",
    canton: "ZH",
    location: "Glattzentrum N. Wint.str.",
  },
  {
    npa: "8037",
    canton: "ZH",
    location: "Zürich Hönggerstrasse",
  },
  {
    npa: "8045",
    canton: "ZH",
    location: "Zürich Brunaupark",
  },
  {
    npa: "3052",
    canton: "BE",
    location: "Zollikofen Waldstrasse",
  },
  {
    npa: "6403",
    canton: "SZ",
    location: "Küssnacht a.R. Zugstrasse",
  },
  {
    npa: "8045",
    canton: "ZH",
    location: "Zürich Friesenbergplatz",
  },
  {
    npa: "6014",
    canton: "LU",
    location: "Luzern Littau Fanghöfli",
  },
  {
    npa: "8603",
    canton: "ZH",
    location: "Schwerzenbach Bahnstrasse",
  },
  {
    npa: "8031",
    canton: "ZH",
    location: "Zürich Limmatstrasse",
  },
  {
    npa: "4702",
    canton: "SO",
    location: "Oensingen Solothurnstrasse",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Lorraine",
  },
  {
    npa: "4144",
    canton: "BL",
    location: "Arlesheim Postplatz",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf Bahnhofstrasse",
  },
  {
    npa: "8575",
    canton: "TG",
    location: "Bürglen TG Postweg",
  },
  {
    npa: "6814",
    canton: "TI",
    location: "Lamone-Cadempino via Cant.",
  },
  {
    npa: "4852",
    canton: "AG",
    location: "Rothrist Bernstrasse",
  },
  {
    npa: "8460",
    canton: "ZH",
    location: "Marthalen Güeterstrass",
  },
  {
    npa: "6340",
    canton: "ZG",
    location: "Baar Dorfstrasse",
  },
  {
    npa: "8024",
    canton: "ZH",
    location: "Zürich Oberdorf",
  },
  {
    npa: "2074",
    canton: "NE",
    location: "Marin-Epagnier Fleur de Lys",
  },
  {
    npa: "7210",
    canton: "GR",
    location: "Untervaz CIRCLE",
  },
  {
    npa: "1960",
    canton: "VS",
    location: "Vétroz CCR",
  },
  {
    npa: "1960",
    canton: "VS",
    location: "Vétroz Tri",
  },
  {
    npa: "1940",
    canton: "VS",
    location: "Vétroz CIRCLE",
  },
  {
    npa: "3080",
    canton: "BE",
    location: "Ostermundigen CIRCLE",
  },
  {
    npa: "8310",
    canton: "ZH",
    location: "Kemptthal Kemptpark",
  },
  {
    npa: "4153",
    canton: "BL",
    location: "Reinach BL Austrasse",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon-les-Bains Ch-Lovats",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne Rennweg",
  },
  {
    npa: "5630",
    canton: "AG",
    location: "Muri AG Seetalstrasse",
  },
  {
    npa: "6210",
    canton: "LU",
    location: "Sursee Schellenrain",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen Seminarstrasse",
  },
  {
    npa: "8636",
    canton: "ZH",
    location: "Wald ZH Poststrasse",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan Paketzustellung",
  },
  {
    npa: "8017",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8930",
    canton: "ZH",
    location: "Urdorf CALL",
  },
  {
    npa: "5615",
    canton: "AG",
    location: "Fahrwangen Hintergasse",
  },
  {
    npa: "3063",
    canton: "BE",
    location: "Ittigen Talgut-Zentrum",
  },
  {
    npa: "3073",
    canton: "BE",
    location: "Gümligen Füllerichstrasse",
  },
  {
    npa: "8302",
    canton: "ZH",
    location: "Kloten Bahnhofstrasse",
  },
  {
    npa: "8152",
    canton: "ZH",
    location: "Glattbrugg Schaffhauserstr",
  },
  {
    npa: "6030",
    canton: "LU",
    location: "Ebikon Riedmattstrasse",
  },
  {
    npa: "3380",
    canton: "BE",
    location: "Wangen a.d.A. Bahnhofallee",
  },
  {
    npa: "1228",
    canton: "GE",
    location: "Plan-les-Ouates Place des A",
  },
  {
    npa: "2001",
    canton: "NE",
    location: "Neuchâtel Place Numa-Droz",
  },
  {
    npa: "4914",
    canton: "BE",
    location: "Roggwil BE Dorfstrasse",
  },
  {
    npa: "9021",
    canton: "SG",
    location: "St. Gallen MS",
  },
  {
    npa: "8840",
    canton: "SZ",
    location: "Einsiedeln Bahnhofplatz",
  },
  {
    npa: "8073",
    canton: "ZH",
    location: "Zürich CALL",
  },
  {
    npa: "8048",
    canton: "ZH",
    location: "Zürich Altstetterstrasse",
  },
  {
    npa: "1844",
    canton: "VD",
    location: "Villeneuve VD Ave C.d.S.",
  },
  {
    npa: "4125",
    canton: "BS",
    location: "Riehen Bahnhofstrasse",
  },
  {
    npa: "8274",
    canton: "TG",
    location: "Tägerwilen Hauptstrasse",
  },
  {
    npa: "4922",
    canton: "BE",
    location: "Bützberg Sperrgut HUB",
  },
  {
    npa: "4104",
    canton: "BL",
    location: "Oberwil BL Konsumstrasse",
  },
  {
    npa: "6043",
    canton: "LU",
    location: "Adligenswil Dist Hub",
  },
  {
    npa: "8910",
    canton: "ZH",
    location: "Affoltern am Albis Obfelder",
  },
  {
    npa: "8215",
    canton: "SH",
    location: "Hallau Neunkircherstrasse",
  },
  {
    npa: "9245",
    canton: "SG",
    location: "Oberbüren Unterdorf",
  },
  {
    npa: "5033",
    canton: "AG",
    location: "Buchs AG RPZ J",
  },
  {
    npa: "8027",
    canton: "ZH",
    location: "Zürich Gutenbergstrasse",
  },
  {
    npa: "1024",
    canton: "VD",
    location: "Ecublens VD Swiss Tech EPFL",
  },
  {
    npa: "4001",
    canton: "BS",
    location: "Basel Freie Strasse",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne St-Paul",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 17",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 19",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Sévelin",
  },
  {
    npa: "1010",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1417",
    canton: "VD",
    location: "Epautheyres",
  },
  {
    npa: "1034",
    canton: "VD",
    location: "Boussens",
  },
  {
    npa: "1081",
    canton: "VD",
    location: "Montpreveyres",
  },
  {
    npa: "1128",
    canton: "VD",
    location: "Reverolle",
  },
  {
    npa: "1124",
    canton: "VD",
    location: "Gollion",
  },
  {
    npa: "1144",
    canton: "VD",
    location: "Ballens",
  },
  {
    npa: "1149",
    canton: "VD",
    location: "Berolle",
  },
  {
    npa: "1145",
    canton: "VD",
    location: "Bière",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "L'Isle",
  },
  {
    npa: "1182",
    canton: "VD",
    location: "Gilly",
  },
  {
    npa: "1227",
    canton: "GE",
    location: "Carouge GE",
  },
  {
    npa: "1231",
    canton: "GE",
    location: "Conches",
  },
  {
    npa: "1232",
    canton: "GE",
    location: "Confignon",
  },
  {
    npa: "1233",
    canton: "GE",
    location: "Bernex",
  },
  {
    npa: "1234",
    canton: "GE",
    location: "Vessy",
  },
  {
    npa: "1236",
    canton: "GE",
    location: "Cartigny",
  },
  {
    npa: "1237",
    canton: "GE",
    location: "Avully",
  },
  {
    npa: "1242",
    canton: "GE",
    location: "Satigny",
  },
  {
    npa: "1243",
    canton: "GE",
    location: "Presinge",
  },
  {
    npa: "1254",
    canton: "GE",
    location: "Jussy",
  },
  {
    npa: "1279",
    canton: "VD",
    location: "Chavannes-de-Bogis",
  },
  {
    npa: "1271",
    canton: "VD",
    location: "Givrins",
  },
  {
    npa: "1268",
    canton: "VD",
    location: "Burtigny",
  },
  {
    npa: "1285",
    canton: "GE",
    location: "Athenaz (Avusy)",
  },
  {
    npa: "1292",
    canton: "GE",
    location: "Chambésy",
  },
  {
    npa: "1293",
    canton: "GE",
    location: "Bellevue",
  },
  {
    npa: "1298",
    canton: "GE",
    location: "Céligny",
  },
  {
    npa: "1315",
    canton: "VD",
    location: "La Sarraz",
  },
  {
    npa: "1344",
    canton: "VD",
    location: "L'Abbaye",
  },
  {
    npa: "1345",
    canton: "VD",
    location: "Le Lieu",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Mont-la-Ville",
  },
  {
    npa: "1350",
    canton: "VD",
    location: "Orbe",
  },
  {
    npa: "1353",
    canton: "VD",
    location: "Bofflens",
  },
  {
    npa: "1439",
    canton: "VD",
    location: "Rances",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon-les-Bains",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon 2",
  },
  {
    npa: "1418",
    canton: "VD",
    location: "Vuarrens",
  },
  {
    npa: "1420",
    canton: "VD",
    location: "Fiez",
  },
  {
    npa: "1429",
    canton: "VD",
    location: "Giez",
  },
  {
    npa: "1426",
    canton: "VD",
    location: "Concise",
  },
  {
    npa: "1432",
    canton: "VD",
    location: "Belmont-sur-Yverdon",
  },
  {
    npa: "1433",
    canton: "VD",
    location: "Suchy",
  },
  {
    npa: "1434",
    canton: "VD",
    location: "Ependes VD",
  },
  {
    npa: "1435",
    canton: "VD",
    location: "Essert-Pittet",
  },
  {
    npa: "1437",
    canton: "VD",
    location: "Suscévaz",
  },
  {
    npa: "1445",
    canton: "VD",
    location: "Vuiteboeuf",
  },
  {
    npa: "1450",
    canton: "VD",
    location: "Ste-Croix",
  },
  {
    npa: "1462",
    canton: "VD",
    location: "Yvonand",
  },
  {
    npa: "1468",
    canton: "FR",
    location: "Cheyres",
  },
  {
    npa: "1474",
    canton: "FR",
    location: "Châbles FR",
  },
  {
    npa: "1482",
    canton: "FR",
    location: "Cugy FR",
  },
  {
    npa: "1541",
    canton: "FR",
    location: "Bussy FR",
  },
  {
    npa: "1484",
    canton: "FR",
    location: "Aumont",
  },
  {
    npa: "1485",
    canton: "FR",
    location: "Nuvilly",
  },
  {
    npa: "1526",
    canton: "VD",
    location: "Cremin",
  },
  {
    npa: "1553",
    canton: "FR",
    location: "Châtonnaye",
  },
  {
    npa: "1586",
    canton: "VD",
    location: "Vallamand",
  },
  {
    npa: "1587",
    canton: "VD",
    location: "Montmagny",
  },
  {
    npa: "1589",
    canton: "VD",
    location: "Chabrey",
  },
  {
    npa: "1595",
    canton: "VD",
    location: "Faoug",
  },
  {
    npa: "1610",
    canton: "VD",
    location: "Châtillens",
  },
  {
    npa: "1525",
    canton: "VD",
    location: "Henniez",
  },
  {
    npa: "1091",
    canton: "VD",
    location: "Aran",
  },
  {
    npa: "1624",
    canton: "FR",
    location: "La Verrerie",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 25",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne Distribution",
  },
  {
    npa: "1002",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1004",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1006",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1007",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1009",
    canton: "VD",
    location: "Pully",
  },
  {
    npa: "1015",
    canton: "VD",
    location: "Lausanne",
  },
  {
    npa: "1020",
    canton: "VD",
    location: "Renens VD 1",
  },
  {
    npa: "1032",
    canton: "VD",
    location: "Romanel-sur-Lausanne",
  },
  {
    npa: "1033",
    canton: "VD",
    location: "Cheseaux-sur-Lausanne",
  },
  {
    npa: "1038",
    canton: "VD",
    location: "Bercher",
  },
  {
    npa: "1377",
    canton: "VD",
    location: "Oulens-sous-Echallens",
  },
  {
    npa: "1040",
    canton: "VD",
    location: "Villars-le-Terroir",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Poliez-le-Grand",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Bottens",
  },
  {
    npa: "1053",
    canton: "VD",
    location: "Bretigny-sur-Morrens",
  },
  {
    npa: "1407",
    canton: "VD",
    location: "Bioley-Magnoux",
  },
  {
    npa: "1066",
    canton: "VD",
    location: "Epalinges",
  },
  {
    npa: "1094",
    canton: "VD",
    location: "Paudex",
  },
  {
    npa: "1096",
    canton: "VD",
    location: "Cully",
  },
  {
    npa: "1410",
    canton: "VD",
    location: "St-Cierges",
  },
  {
    npa: "1073",
    canton: "VD",
    location: "Mollie-Margot",
  },
  {
    npa: "1085",
    canton: "VD",
    location: "Vulliens",
  },
  {
    npa: "1510",
    canton: "VD",
    location: "Syens",
  },
  {
    npa: "1112",
    canton: "VD",
    location: "Echichens",
  },
  {
    npa: "1126",
    canton: "VD",
    location: "Vaux-sur-Morges",
  },
  {
    npa: "1115",
    canton: "VD",
    location: "Vullierens",
  },
  {
    npa: "1116",
    canton: "VD",
    location: "Cottens VD",
  },
  {
    npa: "1117",
    canton: "VD",
    location: "Grancy",
  },
  {
    npa: "1304",
    canton: "VD",
    location: "Senarclens",
  },
  {
    npa: "1121",
    canton: "VD",
    location: "Bremblens",
  },
  {
    npa: "1164",
    canton: "VD",
    location: "Buchillon",
  },
  {
    npa: "1165",
    canton: "VD",
    location: "Allaman",
  },
  {
    npa: "1172",
    canton: "VD",
    location: "Bougy-Villars",
  },
  {
    npa: "1175",
    canton: "VD",
    location: "Lavigny",
  },
  {
    npa: "1183",
    canton: "VD",
    location: "Bursins",
  },
  {
    npa: "1188",
    canton: "VD",
    location: "Gimel",
  },
  {
    npa: "1195",
    canton: "VD",
    location: "Dully-Bursinel",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 2 Cornavin Dépôt",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 3",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 2 Transit",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 17",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 20",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 28 Balexert",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 2 Distribution",
  },
  {
    npa: "1204",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1209",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 2",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 4",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 10",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 13",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 26",
  },
  {
    npa: "1212",
    canton: "GE",
    location: "Grand-Lancy 1",
  },
  {
    npa: "1213",
    canton: "GE",
    location: "Petit-Lancy",
  },
  {
    npa: "1213",
    canton: "GE",
    location: "Petit-Lancy 2",
  },
  {
    npa: "1213",
    canton: "GE",
    location: "Onex",
  },
  {
    npa: "1219",
    canton: "GE",
    location: "Châtelaine",
  },
  {
    npa: "1224",
    canton: "GE",
    location: "Chêne-Bougeries",
  },
  {
    npa: "1226",
    canton: "GE",
    location: "Thônex",
  },
  {
    npa: "1251",
    canton: "GE",
    location: "Gy",
  },
  {
    npa: "1261",
    canton: "VD",
    location: "Le Vaud",
  },
  {
    npa: "1261",
    canton: "VD",
    location: "Longirod",
  },
  {
    npa: "1281",
    canton: "GE",
    location: "Russin",
  },
  {
    npa: "1283",
    canton: "GE",
    location: "Dardagny",
  },
  {
    npa: "1283",
    canton: "GE",
    location: "La Plaine",
  },
  {
    npa: "1306",
    canton: "VD",
    location: "Daillens",
  },
  {
    npa: "1326",
    canton: "VD",
    location: "Juriens",
  },
  {
    npa: "1374",
    canton: "VD",
    location: "Corcelles-sur-Chavornay",
  },
  {
    npa: "1407",
    canton: "VD",
    location: "Donneloye",
  },
  {
    npa: "1408",
    canton: "VD",
    location: "Prahins",
  },
  {
    npa: "1413",
    canton: "VD",
    location: "Orzens",
  },
  {
    npa: "1431",
    canton: "VD",
    location: "Novalles",
  },
  {
    npa: "1415",
    canton: "VD",
    location: "Molondin",
  },
  {
    npa: "1422",
    canton: "VD",
    location: "Grandson",
  },
  {
    npa: "1452",
    canton: "VD",
    location: "Les Rasses",
  },
  {
    npa: "1483",
    canton: "FR",
    location: "Montet (Broye)",
  },
  {
    npa: "1486",
    canton: "FR",
    location: "Vuissens",
  },
  {
    npa: "1538",
    canton: "VD",
    location: "Treytorrens (Payerne)",
  },
  {
    npa: "1510",
    canton: "VD",
    location: "Moudon",
  },
  {
    npa: "1513",
    canton: "VD",
    location: "Hermenches",
  },
  {
    npa: "1528",
    canton: "FR",
    location: "Surpierre",
  },
  {
    npa: "1536",
    canton: "VD",
    location: "Combremont-le-Petit",
  },
  {
    npa: "1526",
    canton: "VD",
    location: "Forel-sur-Lucens",
  },
  {
    npa: "1543",
    canton: "VD",
    location: "Grandcour",
  },
  {
    npa: "1545",
    canton: "VD",
    location: "Chevroux",
  },
  {
    npa: "1562",
    canton: "VD",
    location: "Corcelles-près-Payerne",
  },
  {
    npa: "1565",
    canton: "VD",
    location: "Missy",
  },
  {
    npa: "1580",
    canton: "VD",
    location: "Avenches",
  },
  {
    npa: "1619",
    canton: "FR",
    location: "Les Paccots",
  },
  {
    npa: "1623",
    canton: "FR",
    location: "Semsales",
  },
  {
    npa: "1627",
    canton: "FR",
    location: "Vaulruz",
  },
  {
    npa: "1628",
    canton: "FR",
    location: "Vuadens",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle",
  },
  {
    npa: "1642",
    canton: "FR",
    location: "Sorens",
  },
  {
    npa: "1644",
    canton: "FR",
    location: "Avry-devant-Pont",
  },
  {
    npa: "1656",
    canton: "FR",
    location: "Im Fang",
  },
  {
    npa: "1638",
    canton: "FR",
    location: "Morlon",
  },
  {
    npa: "1636",
    canton: "FR",
    location: "Broc",
  },
  {
    npa: "1669",
    canton: "FR",
    location: "Albeuve",
  },
  {
    npa: "1673",
    canton: "FR",
    location: "Rue",
  },
  {
    npa: "1678",
    canton: "FR",
    location: "Siviriez",
  },
  {
    npa: "1679",
    canton: "FR",
    location: "Villaraboud",
  },
  {
    npa: "1676",
    canton: "FR",
    location: "Chavannes-les-Forts",
  },
  {
    npa: "1677",
    canton: "FR",
    location: "Prez-vers-Siviriez",
  },
  {
    npa: "1680",
    canton: "FR",
    location: "Romont FR",
  },
  {
    npa: "1683",
    canton: "VD",
    location: "Brenles",
  },
  {
    npa: "1696",
    canton: "FR",
    location: "Vuisternens-en-Ogoz",
  },
  {
    npa: "1687",
    canton: "FR",
    location: "Estévenens",
  },
  {
    npa: "1692",
    canton: "FR",
    location: "Massonnens",
  },
  {
    npa: "1687",
    canton: "FR",
    location: "Vuisternens-devant-Romont",
  },
  {
    npa: "1608",
    canton: "VD",
    location: "Oron-le-Châtel",
  },
  {
    npa: "1699",
    canton: "FR",
    location: "Porsel",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1704",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1707",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1708",
    canton: "FR",
    location: "Fribourg",
  },
  {
    npa: "1763",
    canton: "FR",
    location: "Granges-Paccot",
  },
  {
    npa: "1720",
    canton: "FR",
    location: "Corminboeuf",
  },
  {
    npa: "1721",
    canton: "FR",
    location: "Misery-Courtion",
  },
  {
    npa: "1736",
    canton: "FR",
    location: "St. Silvester",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Bonnefontaine",
  },
  {
    npa: "1732",
    canton: "FR",
    location: "Arconciel",
  },
  {
    npa: "1720",
    canton: "FR",
    location: "Chésopelloz",
  },
  {
    npa: "1717",
    canton: "FR",
    location: "St. Ursen",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Marly",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Le Mouret",
  },
  {
    npa: "1725",
    canton: "FR",
    location: "Posieux",
  },
  {
    npa: "1749",
    canton: "FR",
    location: "Middes",
  },
  {
    npa: "1740",
    canton: "FR",
    location: "Neyruz FR",
  },
  {
    npa: "1752",
    canton: "FR",
    location: "Villars-sur-Glâne",
  },
  {
    npa: "1753",
    canton: "FR",
    location: "Matran",
  },
  {
    npa: "1756",
    canton: "FR",
    location: "Onnens FR",
  },
  {
    npa: "1772",
    canton: "FR",
    location: "Grolley",
  },
  {
    npa: "1776",
    canton: "FR",
    location: "Montagny-la-Ville",
  },
  {
    npa: "1797",
    canton: "BE",
    location: "Münchenwiler",
  },
  {
    npa: "1793",
    canton: "FR",
    location: "Jeuss",
  },
  {
    npa: "1800",
    canton: "VD",
    location: "Vevey",
  },
  {
    npa: "1800",
    canton: "VD",
    location: "Vevey 1 Distribution",
  },
  {
    npa: "1801",
    canton: "VD",
    location: "Le Mont-Pèlerin",
  },
  {
    npa: "1803",
    canton: "VD",
    location: "Chardonne",
  },
  {
    npa: "1807",
    canton: "VD",
    location: "Blonay",
  },
  {
    npa: "1071",
    canton: "VD",
    location: "St-Saphorin (Lavaux)",
  },
  {
    npa: "1814",
    canton: "VD",
    location: "La Tour-de-Peilz",
  },
  {
    npa: "1817",
    canton: "VD",
    location: "Brent",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Montreux",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Montreux 1",
  },
  {
    npa: "1660",
    canton: "VD",
    location: "L'Etivaz",
  },
  {
    npa: "1659",
    canton: "VD",
    location: "Rougemont",
  },
  {
    npa: "1847",
    canton: "VD",
    location: "Rennaz",
  },
  {
    npa: "1846",
    canton: "VD",
    location: "Chessel",
  },
  {
    npa: "1866",
    canton: "VD",
    location: "La Forclaz VD",
  },
  {
    npa: "1863",
    canton: "VD",
    location: "Le Sépey",
  },
  {
    npa: "1864",
    canton: "VD",
    location: "Vers-l'Eglise",
  },
  {
    npa: "1870",
    canton: "VS",
    location: "Monthey",
  },
  {
    npa: "1870",
    canton: "VS",
    location: "Monthey 1",
  },
  {
    npa: "1870",
    canton: "VS",
    location: "Monthey 2",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Fenalet-sur-Bex",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Frenières-sur-Bex",
  },
  {
    npa: "1882",
    canton: "VD",
    location: "Gryon",
  },
  {
    npa: "1884",
    canton: "VD",
    location: "Villars-sur-Ollon",
  },
  {
    npa: "1890",
    canton: "VS",
    location: "St-Maurice",
  },
  {
    npa: "1891",
    canton: "VS",
    location: "Vérossaz",
  },
  {
    npa: "1869",
    canton: "VS",
    location: "Massongex",
  },
  {
    npa: "1895",
    canton: "VS",
    location: "Vionnaz",
  },
  {
    npa: "1897",
    canton: "VS",
    location: "Les Evouettes",
  },
  {
    npa: "1899",
    canton: "VS",
    location: "Torgon",
  },
  {
    npa: "1892",
    canton: "VD",
    location: "Lavey-Village",
  },
  {
    npa: "1893",
    canton: "VS",
    location: "Muraz (Collombey)",
  },
  {
    npa: "1896",
    canton: "VS",
    location: "Miex",
  },
  {
    npa: "1897",
    canton: "VS",
    location: "Bouveret",
  },
  {
    npa: "1903",
    canton: "VS",
    location: "Collonges",
  },
  {
    npa: "1912",
    canton: "VS",
    location: "Leytron",
  },
  {
    npa: "1918",
    canton: "VS",
    location: "La Tzoumaz",
  },
  {
    npa: "1920",
    canton: "VS",
    location: "Martigny",
  },
  {
    npa: "1920",
    canton: "VS",
    location: "Martigny Bourg",
  },
  {
    npa: "1923",
    canton: "VS",
    location: "Les Marécottes",
  },
  {
    npa: "1925",
    canton: "VS",
    location: "Finhaut",
  },
  {
    npa: "1926",
    canton: "VS",
    location: "Fully",
  },
  {
    npa: "1947",
    canton: "VS",
    location: "Versegères",
  },
  {
    npa: "1942",
    canton: "VS",
    location: "Levron",
  },
  {
    npa: "1948",
    canton: "VS",
    location: "Fionnay",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion 1 Distribution",
  },
  {
    npa: "1950",
    canton: "VS",
    location: "Sion 2",
  },
  {
    npa: "1974",
    canton: "VS",
    location: "Arbaz",
  },
  {
    npa: "1982",
    canton: "VS",
    location: "Euseigne",
  },
  {
    npa: "1985",
    canton: "VS",
    location: "La Sage",
  },
  {
    npa: "1992",
    canton: "VS",
    location: "Les Agettes",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Baar (Nendaz)",
  },
  {
    npa: "1963",
    canton: "VS",
    location: "Vétroz",
  },
  {
    npa: "2004",
    canton: "NE",
    location: "Neuchâtel Ecluse",
  },
  {
    npa: "2009",
    canton: "NE",
    location: "Neuchâtel La Coudre",
  },
  {
    npa: "2015",
    canton: "NE",
    location: "Areuse",
  },
  {
    npa: "2023",
    canton: "NE",
    location: "Gorgier",
  },
  {
    npa: "2024",
    canton: "NE",
    location: "St-Aubin-Sauges",
  },
  {
    npa: "2028",
    canton: "NE",
    location: "Vaumarcus",
  },
  {
    npa: "2034",
    canton: "NE",
    location: "Peseux",
  },
  {
    npa: "2035",
    canton: "NE",
    location: "Corcelles NE",
  },
  {
    npa: "2043",
    canton: "NE",
    location: "Boudevilliers",
  },
  {
    npa: "2054",
    canton: "NE",
    location: "Chézard-St-Martin",
  },
  {
    npa: "2067",
    canton: "NE",
    location: "Chaumont",
  },
  {
    npa: "2068",
    canton: "NE",
    location: "Hauterive NE",
  },
  {
    npa: "2318",
    canton: "NE",
    location: "Brot-Plamboz",
  },
  {
    npa: "2103",
    canton: "NE",
    location: "Noiraigue",
  },
  {
    npa: "2108",
    canton: "NE",
    location: "Couvet",
  },
  {
    npa: "2113",
    canton: "NE",
    location: "Boveresse",
  },
  {
    npa: "2114",
    canton: "NE",
    location: "Fleurier",
  },
  {
    npa: "2149",
    canton: "NE",
    location: "Champ-du-Moulin",
  },
  {
    npa: "2124",
    canton: "NE",
    location: "Les Sagnettes",
  },
  {
    npa: "2206",
    canton: "NE",
    location: "Les Geneveys-sur-Coffrane",
  },
  {
    npa: "2325",
    canton: "NE",
    location: "Les Planchettes",
  },
  {
    npa: "2300",
    canton: "NE",
    location: "La Cibourg",
  },
  {
    npa: "2336",
    canton: "JU",
    location: "Les Bois",
  },
  {
    npa: "2406",
    canton: "NE",
    location: "La Châtagne",
  },
  {
    npa: "2405",
    canton: "NE",
    location: "La Chaux-du-Milieu",
  },
  {
    npa: "2400",
    canton: "NE",
    location: "Le Prévoux",
  },
  {
    npa: "2414",
    canton: "NE",
    location: "Le Cerneux-Péquignot",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 1",
  },
  {
    npa: "2500",
    canton: "BE",
    location: "Biel/Bienne 6",
  },
  {
    npa: "2514",
    canton: "BE",
    location: "Ligerz",
  },
  {
    npa: "2525",
    canton: "NE",
    location: "Le Landeron",
  },
  {
    npa: "2537",
    canton: "BE",
    location: "Vauffelin",
  },
  {
    npa: "2542",
    canton: "BE",
    location: "Pieterlen",
  },
  {
    npa: "2543",
    canton: "BE",
    location: "Lengnau BE",
  },
  {
    npa: "2544",
    canton: "SO",
    location: "Bettlach",
  },
  {
    npa: "2560",
    canton: "BE",
    location: "Nidau",
  },
  {
    npa: "2576",
    canton: "BE",
    location: "Lüscherz",
  },
  {
    npa: "3237",
    canton: "BE",
    location: "Brüttelen",
  },
  {
    npa: "2603",
    canton: "BE",
    location: "Péry",
  },
  {
    npa: "2604",
    canton: "BE",
    location: "La Heutte",
  },
  {
    npa: "2607",
    canton: "BE",
    location: "Cortébert",
  },
  {
    npa: "2608",
    canton: "BE",
    location: "Montagne-de-Courtelary",
  },
  {
    npa: "2610",
    canton: "BE",
    location: "Mont-Crosin",
  },
  {
    npa: "2615",
    canton: "BE",
    location: "Sonvilier",
  },
  {
    npa: "2718",
    canton: "JU",
    location: "Fornet-Dessus",
  },
  {
    npa: "2718",
    canton: "JU",
    location: "Lajoux JU",
  },
  {
    npa: "2720",
    canton: "BE",
    location: "La Tanne",
  },
  {
    npa: "2722",
    canton: "BE",
    location: "Les Reussilles",
  },
  {
    npa: "2345",
    canton: "JU",
    location: "La Chaux-des-Breuleux",
  },
  {
    npa: "2350",
    canton: "JU",
    location: "Saignelégier",
  },
  {
    npa: "2732",
    canton: "BE",
    location: "Saicourt",
  },
  {
    npa: "2732",
    canton: "BE",
    location: "Saules BE",
  },
  {
    npa: "2733",
    canton: "BE",
    location: "Pontenet",
  },
  {
    npa: "2735",
    canton: "BE",
    location: "Malleray-Bévilard",
  },
  {
    npa: "2735",
    canton: "BE",
    location: "Bévilard",
  },
  {
    npa: "2738",
    canton: "BE",
    location: "Court",
  },
  {
    npa: "2747",
    canton: "BE",
    location: "Seehof",
  },
  {
    npa: "2745",
    canton: "BE",
    location: "Grandval",
  },
  {
    npa: "2832",
    canton: "JU",
    location: "Rebeuvelier",
  },
  {
    npa: "2830",
    canton: "JU",
    location: "Courrendlin",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont 1",
  },
  {
    npa: "2800",
    canton: "JU",
    location: "Delémont 2",
  },
  {
    npa: "2806",
    canton: "JU",
    location: "Mettembert",
  },
  {
    npa: "2807",
    canton: "JU",
    location: "Pleigne",
  },
  {
    npa: "2812",
    canton: "JU",
    location: "Movelier",
  },
  {
    npa: "2813",
    canton: "JU",
    location: "Ederswiler",
  },
  {
    npa: "2823",
    canton: "JU",
    location: "Courcelon",
  },
  {
    npa: "2825",
    canton: "JU",
    location: "Courchapoix",
  },
  {
    npa: "2842",
    canton: "JU",
    location: "Rossemaison",
  },
  {
    npa: "2853",
    canton: "JU",
    location: "Courfaivre",
  },
  {
    npa: "2856",
    canton: "JU",
    location: "Boécourt",
  },
  {
    npa: "2883",
    canton: "JU",
    location: "Montmelon",
  },
  {
    npa: "2885",
    canton: "JU",
    location: "Epauvillers",
  },
  {
    npa: "2887",
    canton: "JU",
    location: "Soubey",
  },
  {
    npa: "2950",
    canton: "JU",
    location: "Courgenay",
  },
  {
    npa: "2900",
    canton: "JU",
    location: "Porrentruy 1",
  },
  {
    npa: "2903",
    canton: "JU",
    location: "Villars-sur-Fontenais",
  },
  {
    npa: "2904",
    canton: "JU",
    location: "Bressaucourt",
  },
  {
    npa: "2907",
    canton: "JU",
    location: "Rocourt",
  },
  {
    npa: "2914",
    canton: "JU",
    location: "Damvant",
  },
  {
    npa: "2925",
    canton: "JU",
    location: "Buix",
  },
  {
    npa: "2926",
    canton: "JU",
    location: "Boncourt",
  },
  {
    npa: "2932",
    canton: "JU",
    location: "Coeuve",
  },
  {
    npa: "2935",
    canton: "JU",
    location: "Beurnevésin",
  },
  {
    npa: "2942",
    canton: "JU",
    location: "Alle",
  },
  {
    npa: "2944",
    canton: "JU",
    location: "Bonfol",
  },
  {
    npa: "2952",
    canton: "JU",
    location: "Cornol",
  },
  {
    npa: "2954",
    canton: "JU",
    location: "Asuel",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern Europaplatz",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 9",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 22",
  },
  {
    npa: "3003",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3007",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3010",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3011",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3014",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3018",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "3033",
    canton: "BE",
    location: "Wohlen b. Bern",
  },
  {
    npa: "3035",
    canton: "BE",
    location: "Frieswil",
  },
  {
    npa: "3038",
    canton: "BE",
    location: "Kirchlindach",
  },
  {
    npa: "3046",
    canton: "BE",
    location: "Wahlendorf",
  },
  {
    npa: "3063",
    canton: "BE",
    location: "Ittigen",
  },
  {
    npa: "3066",
    canton: "BE",
    location: "Stettlen",
  },
  {
    npa: "3068",
    canton: "BE",
    location: "Utzigen",
  },
  {
    npa: "3073",
    canton: "BE",
    location: "Gümligen",
  },
  {
    npa: "3077",
    canton: "BE",
    location: "Enggistein",
  },
  {
    npa: "3088",
    canton: "BE",
    location: "Oberbütschel",
  },
  {
    npa: "3086",
    canton: "BE",
    location: "Zimmerwald",
  },
  {
    npa: "3096",
    canton: "BE",
    location: "Oberbalm",
  },
  {
    npa: "3098",
    canton: "BE",
    location: "Schliern b. Köniz",
  },
  {
    npa: "3110",
    canton: "BE",
    location: "Münsingen",
  },
  {
    npa: "3116",
    canton: "BE",
    location: "Kirchdorf BE",
  },
  {
    npa: "3122",
    canton: "BE",
    location: "Kehrsatz",
  },
  {
    npa: "3125",
    canton: "BE",
    location: "Toffen",
  },
  {
    npa: "3126",
    canton: "BE",
    location: "Kaufdorf",
  },
  {
    npa: "3128",
    canton: "BE",
    location: "Kirchenthurnen",
  },
  {
    npa: "3132",
    canton: "BE",
    location: "Riggisberg",
  },
  {
    npa: "3664",
    canton: "BE",
    location: "Burgistein",
  },
  {
    npa: "3662",
    canton: "BE",
    location: "Seftigen",
  },
  {
    npa: "3663",
    canton: "BE",
    location: "Gurzelen",
  },
  {
    npa: "3144",
    canton: "BE",
    location: "Gasel",
  },
  {
    npa: "3147",
    canton: "BE",
    location: "Mittelhäusern",
  },
  {
    npa: "3148",
    canton: "BE",
    location: "Lanzenhäusern",
  },
  {
    npa: "3152",
    canton: "BE",
    location: "Mamishaus",
  },
  {
    npa: "3156",
    canton: "BE",
    location: "Riffenmatt",
  },
  {
    npa: "3175",
    canton: "FR",
    location: "Flamatt",
  },
  {
    npa: "3176",
    canton: "BE",
    location: "Neuenegg",
  },
  {
    npa: "3177",
    canton: "BE",
    location: "Laupen BE",
  },
  {
    npa: "3182",
    canton: "FR",
    location: "Ueberstorf",
  },
  {
    npa: "3203",
    canton: "BE",
    location: "Mühleberg",
  },
  {
    npa: "3206",
    canton: "BE",
    location: "Rizenbach",
  },
  {
    npa: "3212",
    canton: "FR",
    location: "Gurmels",
  },
  {
    npa: "3232",
    canton: "BE",
    location: "Ins",
  },
  {
    npa: "3233",
    canton: "BE",
    location: "Tschugg",
  },
  {
    npa: "3234",
    canton: "BE",
    location: "Vinelz",
  },
  {
    npa: "3236",
    canton: "BE",
    location: "Gampelen",
  },
  {
    npa: "3250",
    canton: "BE",
    location: "Lyss Zustellung",
  },
  {
    npa: "3254",
    canton: "SO",
    location: "Messen",
  },
  {
    npa: "3262",
    canton: "BE",
    location: "Suberg",
  },
  {
    npa: "3263",
    canton: "BE",
    location: "Büetigen",
  },
  {
    npa: "3273",
    canton: "BE",
    location: "Kappelen",
  },
  {
    npa: "3274",
    canton: "BE",
    location: "Hermrigen",
  },
  {
    npa: "3280",
    canton: "FR",
    location: "Meyriez",
  },
  {
    npa: "3282",
    canton: "BE",
    location: "Bargen BE",
  },
  {
    npa: "3292",
    canton: "BE",
    location: "Busswil BE",
  },
  {
    npa: "3294",
    canton: "BE",
    location: "Büren an der Aare",
  },
  {
    npa: "3295",
    canton: "BE",
    location: "Rüti b. Büren",
  },
  {
    npa: "3297",
    canton: "BE",
    location: "Leuzigen",
  },
  {
    npa: "3302",
    canton: "BE",
    location: "Moosseedorf",
  },
  {
    npa: "3312",
    canton: "BE",
    location: "Fraubrunnen",
  },
  {
    npa: "3325",
    canton: "BE",
    location: "Hettiswil b. Hindelbank",
  },
  {
    npa: "3303",
    canton: "BE",
    location: "Zuzwil BE",
  },
  {
    npa: "3305",
    canton: "BE",
    location: "Iffwil",
  },
  {
    npa: "3306",
    canton: "BE",
    location: "Etzelkofen",
  },
  {
    npa: "3317",
    canton: "BE",
    location: "Limpach",
  },
  {
    npa: "3360",
    canton: "BE",
    location: "Herzogenbuchsee",
  },
  {
    npa: "4556",
    canton: "SO",
    location: "Aeschi SO",
  },
  {
    npa: "3374",
    canton: "BE",
    location: "Wangenried",
  },
  {
    npa: "3362",
    canton: "BE",
    location: "Niederönz",
  },
  {
    npa: "3363",
    canton: "BE",
    location: "Oberönz",
  },
  {
    npa: "3368",
    canton: "BE",
    location: "Bleienbach",
  },
  {
    npa: "3401",
    canton: "BE",
    location: "Burgdorf",
  },
  {
    npa: "3412",
    canton: "BE",
    location: "Heimiswil",
  },
  {
    npa: "3414",
    canton: "BE",
    location: "Oberburg",
  },
  {
    npa: "3416",
    canton: "BE",
    location: "Affoltern im Emmental",
  },
  {
    npa: "3417",
    canton: "BE",
    location: "Rüegsau",
  },
  {
    npa: "3418",
    canton: "BE",
    location: "Rüegsbach",
  },
  {
    npa: "3419",
    canton: "BE",
    location: "Biembach im Emmental",
  },
  {
    npa: "3421",
    canton: "BE",
    location: "Lyssach",
  },
  {
    npa: "3422",
    canton: "BE",
    location: "Kirchberg BE",
  },
  {
    npa: "3422",
    canton: "BE",
    location: "Rüdtligen",
  },
  {
    npa: "3423",
    canton: "BE",
    location: "Ersigen",
  },
  {
    npa: "3427",
    canton: "BE",
    location: "Utzenstorf",
  },
  {
    npa: "3438",
    canton: "BE",
    location: "Lauperswil",
  },
  {
    npa: "3454",
    canton: "BE",
    location: "Sumiswald",
  },
  {
    npa: "3456",
    canton: "BE",
    location: "Trachselwald",
  },
  {
    npa: "3473",
    canton: "BE",
    location: "Alchenstorf",
  },
  {
    npa: "3475",
    canton: "BE",
    location: "Hermiswil",
  },
  {
    npa: "3476",
    canton: "BE",
    location: "Oschwand",
  },
  {
    npa: "3111",
    canton: "BE",
    location: "Tägertschi",
  },
  {
    npa: "3503",
    canton: "BE",
    location: "Gysenstein",
  },
  {
    npa: "3512",
    canton: "BE",
    location: "Walkringen",
  },
  {
    npa: "3415",
    canton: "BE",
    location: "Schafhausen im Emmental",
  },
  {
    npa: "3671",
    canton: "BE",
    location: "Brenzikofen",
  },
  {
    npa: "3533",
    canton: "BE",
    location: "Bowil",
  },
  {
    npa: "3538",
    canton: "BE",
    location: "Röthenbach im Emmental",
  },
  {
    npa: "3553",
    canton: "BE",
    location: "Gohl",
  },
  {
    npa: "3600",
    canton: "BE",
    location: "Thun 2 Zustellung",
  },
  {
    npa: "3601",
    canton: "BE",
    location: "Thun Aarezentrum",
  },
  {
    npa: "3602",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3605",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3608",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "3618",
    canton: "BE",
    location: "Süderen",
  },
  {
    npa: "3619",
    canton: "BE",
    location: "Eriz",
  },
  {
    npa: "3622",
    canton: "BE",
    location: "Homberg b. Thun",
  },
  {
    npa: "3635",
    canton: "BE",
    location: "Uebeschi",
  },
  {
    npa: "3616",
    canton: "BE",
    location: "Schwarzenegg",
  },
  {
    npa: "3623",
    canton: "BE",
    location: "Teuffenthal b. Thun",
  },
  {
    npa: "3625",
    canton: "BE",
    location: "Heiligenschwendi",
  },
  {
    npa: "3626",
    canton: "BE",
    location: "Hünibach",
  },
  {
    npa: "3646",
    canton: "BE",
    location: "Einigen",
  },
  {
    npa: "3654",
    canton: "BE",
    location: "Gunten",
  },
  {
    npa: "3655",
    canton: "BE",
    location: "Sigriswil",
  },
  {
    npa: "3711",
    canton: "BE",
    location: "Emdthal",
  },
  {
    npa: "3714",
    canton: "BE",
    location: "Frutigen",
  },
  {
    npa: "3717",
    canton: "BE",
    location: "Blausee-Mitholz",
  },
  {
    npa: "3725",
    canton: "BE",
    location: "Achseten",
  },
  {
    npa: "3752",
    canton: "BE",
    location: "Wimmis",
  },
  {
    npa: "3753",
    canton: "BE",
    location: "Oey",
  },
  {
    npa: "3755",
    canton: "BE",
    location: "Horboden",
  },
  {
    npa: "3758",
    canton: "BE",
    location: "Latterbach",
  },
  {
    npa: "3765",
    canton: "BE",
    location: "Oberwil im Simmental",
  },
  {
    npa: "3770",
    canton: "BE",
    location: "Zweisimmen",
  },
  {
    npa: "3771",
    canton: "BE",
    location: "Blankenburg",
  },
  {
    npa: "3773",
    canton: "BE",
    location: "Matten (St. Stephan)",
  },
  {
    npa: "3784",
    canton: "BE",
    location: "Feutersoey",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Unterseen",
  },
  {
    npa: "3804",
    canton: "BE",
    location: "Habkern",
  },
  {
    npa: "3813",
    canton: "BE",
    location: "Saxeten",
  },
  {
    npa: "3818",
    canton: "BE",
    location: "Grindelwald",
  },
  {
    npa: "3822",
    canton: "BE",
    location: "Lauterbrunnen",
  },
  {
    npa: "3823",
    canton: "BE",
    location: "Wengen",
  },
  {
    npa: "3825",
    canton: "BE",
    location: "Mürren",
  },
  {
    npa: "3854",
    canton: "BE",
    location: "Oberried am Brienzersee",
  },
  {
    npa: "3855",
    canton: "BE",
    location: "Brienz BE",
  },
  {
    npa: "3855",
    canton: "BE",
    location: "Axalp",
  },
  {
    npa: "3858",
    canton: "BE",
    location: "Hofstetten b. Brienz",
  },
  {
    npa: "3864",
    canton: "BE",
    location: "Guttannen",
  },
  {
    npa: "3903",
    canton: "VS",
    location: "Birgisch",
  },
  {
    npa: "3914",
    canton: "VS",
    location: "Belalp",
  },
  {
    npa: "3907",
    canton: "VS",
    location: "Simplon Hospiz",
  },
  {
    npa: "3907",
    canton: "VS",
    location: "Simplon Dorf",
  },
  {
    npa: "3910",
    canton: "VS",
    location: "Saas-Grund",
  },
  {
    npa: "3917",
    canton: "VS",
    location: "Goppenstein",
  },
  {
    npa: "3926",
    canton: "VS",
    location: "Embd",
  },
  {
    npa: "3927",
    canton: "VS",
    location: "Herbriggen",
  },
  {
    npa: "3922",
    canton: "VS",
    location: "Stalden VS",
  },
  {
    npa: "3924",
    canton: "VS",
    location: "St. Niklaus VS",
  },
  {
    npa: "3937",
    canton: "VS",
    location: "Baltschieder",
  },
  {
    npa: "3930",
    canton: "VS",
    location: "Eyholz",
  },
  {
    npa: "3932",
    canton: "VS",
    location: "Visperterminen",
  },
  {
    npa: "3943",
    canton: "VS",
    location: "Eischoll",
  },
  {
    npa: "3955",
    canton: "VS",
    location: "Albinen",
  },
  {
    npa: "3957",
    canton: "VS",
    location: "Erschmatt",
  },
  {
    npa: "3976",
    canton: "VS",
    location: "Noës",
  },
  {
    npa: "3953",
    canton: "VS",
    location: "Leuk Stadt",
  },
  {
    npa: "3954",
    canton: "VS",
    location: "Leukerbad",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Corin-de-la-Crête",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Loc",
  },
  {
    npa: "3971",
    canton: "VS",
    location: "Chermignon",
  },
  {
    npa: "3974",
    canton: "VS",
    location: "Mollens VS",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Chandolin",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Ayer",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Crans-Montana",
  },
  {
    npa: "3968",
    canton: "VS",
    location: "Veyras",
  },
  {
    npa: "3998",
    canton: "VS",
    location: "Gluringen",
  },
  {
    npa: "3998",
    canton: "VS",
    location: "Reckingen VS",
  },
  {
    npa: "3999",
    canton: "VS",
    location: "Oberwald",
  },
  {
    npa: "3986",
    canton: "VS",
    location: "Ried-Mörel",
  },
  {
    npa: "3987",
    canton: "VS",
    location: "Riederalp",
  },
  {
    npa: "3988",
    canton: "VS",
    location: "Ulrichen",
  },
  {
    npa: "4000",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4001",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4004",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4005",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4007",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4009",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4012",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4017",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4021",
    canton: "BS",
    location: "Basel Sortierung",
  },
  {
    npa: "4032",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4055",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4058",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4091",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "4101",
    canton: "BL",
    location: "Bruderholz",
  },
  {
    npa: "4104",
    canton: "BL",
    location: "Oberwil BL",
  },
  {
    npa: "4108",
    canton: "SO",
    location: "Witterswil",
  },
  {
    npa: "4116",
    canton: "SO",
    location: "Metzerlen",
  },
  {
    npa: "4123",
    canton: "BL",
    location: "Allschwil 1",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln 1",
  },
  {
    npa: "4142",
    canton: "BL",
    location: "Münchenstein",
  },
  {
    npa: "4148",
    canton: "BL",
    location: "Pfeffingen",
  },
  {
    npa: "4153",
    canton: "BL",
    location: "Reinach BL 1",
  },
  {
    npa: "4203",
    canton: "BL",
    location: "Grellingen",
  },
  {
    npa: "4206",
    canton: "SO",
    location: "Seewen SO",
  },
  {
    npa: "4208",
    canton: "SO",
    location: "Nunningen",
  },
  {
    npa: "4225",
    canton: "BL",
    location: "Brislach",
  },
  {
    npa: "4228",
    canton: "SO",
    location: "Erschwil",
  },
  {
    npa: "4243",
    canton: "BL",
    location: "Dittingen",
  },
  {
    npa: "4223",
    canton: "BL",
    location: "Blauen",
  },
  {
    npa: "4252",
    canton: "SO",
    location: "Bärschwil",
  },
  {
    npa: "4254",
    canton: "BL",
    location: "Liesberg Dorf",
  },
  {
    npa: "4303",
    canton: "AG",
    location: "Kaiseraugst Liebrüti",
  },
  {
    npa: "4305",
    canton: "AG",
    location: "Olsberg",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden 1",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden 2",
  },
  {
    npa: "4313",
    canton: "AG",
    location: "Möhlin",
  },
  {
    npa: "4314",
    canton: "AG",
    location: "Zeiningen",
  },
  {
    npa: "4317",
    canton: "AG",
    location: "Wegenstetten",
  },
  {
    npa: "4323",
    canton: "AG",
    location: "Wallbach",
  },
  {
    npa: "4325",
    canton: "AG",
    location: "Schupfart",
  },
  {
    npa: "5275",
    canton: "AG",
    location: "Etzgen",
  },
  {
    npa: "5326",
    canton: "AG",
    location: "Schwaderloch",
  },
  {
    npa: "5324",
    canton: "AG",
    location: "Full-Reuenthal",
  },
  {
    npa: "4410",
    canton: "BL",
    location: "Liestal",
  },
  {
    npa: "4411",
    canton: "BL",
    location: "Seltisberg",
  },
  {
    npa: "4417",
    canton: "BL",
    location: "Ziefen",
  },
  {
    npa: "4424",
    canton: "BL",
    location: "Arboldswil",
  },
  {
    npa: "4426",
    canton: "BL",
    location: "Lauwil",
  },
  {
    npa: "4432",
    canton: "BL",
    location: "Lampenberg",
  },
  {
    npa: "4436",
    canton: "BL",
    location: "Oberdorf BL",
  },
  {
    npa: "4438",
    canton: "BL",
    location: "Langenbruck",
  },
  {
    npa: "4441",
    canton: "BL",
    location: "Thürnen",
  },
  {
    npa: "4444",
    canton: "BL",
    location: "Rümlingen",
  },
  {
    npa: "4446",
    canton: "BL",
    location: "Buckten",
  },
  {
    npa: "4447",
    canton: "BL",
    location: "Känerkinden",
  },
  {
    npa: "4448",
    canton: "BL",
    location: "Läufelfingen",
  },
  {
    npa: "4453",
    canton: "BL",
    location: "Nusshof",
  },
  {
    npa: "4456",
    canton: "BL",
    location: "Tenniken",
  },
  {
    npa: "4457",
    canton: "BL",
    location: "Diegten",
  },
  {
    npa: "4458",
    canton: "BL",
    location: "Eptingen",
  },
  {
    npa: "4465",
    canton: "BL",
    location: "Hemmiken",
  },
  {
    npa: "4463",
    canton: "BL",
    location: "Buus",
  },
  {
    npa: "4468",
    canton: "SO",
    location: "Kienberg",
  },
  {
    npa: "4493",
    canton: "BL",
    location: "Wenslingen",
  },
  {
    npa: "4496",
    canton: "BL",
    location: "Kilchberg BL",
  },
  {
    npa: "4500",
    canton: "SO",
    location: "Solothurn 1 Zustellung",
  },
  {
    npa: "4502",
    canton: "SO",
    location: "Solothurn",
  },
  {
    npa: "4535",
    canton: "SO",
    location: "Hubersdorf",
  },
  {
    npa: "4512",
    canton: "SO",
    location: "Bellach",
  },
  {
    npa: "4514",
    canton: "SO",
    location: "Lommiswil",
  },
  {
    npa: "4522",
    canton: "SO",
    location: "Rüttenen",
  },
  {
    npa: "4528",
    canton: "SO",
    location: "Zuchwil",
  },
  {
    npa: "4533",
    canton: "SO",
    location: "Riedholz",
  },
  {
    npa: "4536",
    canton: "BE",
    location: "Attiswil",
  },
  {
    npa: "4537",
    canton: "BE",
    location: "Wiedlisbach",
  },
  {
    npa: "4553",
    canton: "SO",
    location: "Subingen",
  },
  {
    npa: "4563",
    canton: "SO",
    location: "Gerlafingen",
  },
  {
    npa: "4564",
    canton: "BE",
    location: "Zielebach",
  },
  {
    npa: "4586",
    canton: "SO",
    location: "Kyburg-Buchegg",
  },
  {
    npa: "4579",
    canton: "SO",
    location: "Gossliwil",
  },
  {
    npa: "4585",
    canton: "SO",
    location: "Biezwil",
  },
  {
    npa: "4588",
    canton: "SO",
    location: "Unterramsern",
  },
  {
    npa: "4588",
    canton: "SO",
    location: "Oberramsern",
  },
  {
    npa: "4574",
    canton: "SO",
    location: "Lüsslingen",
  },
  {
    npa: "4613",
    canton: "SO",
    location: "Rickenbach SO",
  },
  {
    npa: "4625",
    canton: "SO",
    location: "Oberbuchsiten",
  },
  {
    npa: "4626",
    canton: "SO",
    location: "Niederbuchsiten",
  },
  {
    npa: "4652",
    canton: "SO",
    location: "Winznau",
  },
  {
    npa: "4655",
    canton: "SO",
    location: "Stüsslingen",
  },
  {
    npa: "4656",
    canton: "SO",
    location: "Starrkirch-Wil",
  },
  {
    npa: "4658",
    canton: "SO",
    location: "Däniken SO",
  },
  {
    npa: "4665",
    canton: "AG",
    location: "Oftringen 1",
  },
  {
    npa: "4615",
    canton: "SO",
    location: "Allerheiligenberg",
  },
  {
    npa: "4702",
    canton: "SO",
    location: "Oensingen",
  },
  {
    npa: "4704",
    canton: "BE",
    location: "Niederbipp",
  },
  {
    npa: "4543",
    canton: "SO",
    location: "Deitingen",
  },
  {
    npa: "4710",
    canton: "SO",
    location: "Balsthal",
  },
  {
    npa: "4716",
    canton: "SO",
    location: "Welschenrohr",
  },
  {
    npa: "4717",
    canton: "SO",
    location: "Mümliswil",
  },
  {
    npa: "4718",
    canton: "SO",
    location: "Holderbank SO",
  },
  {
    npa: "4801",
    canton: "AG",
    location: "Zofingen Ringier AG",
  },
  {
    npa: "4802",
    canton: "AG",
    location: "Strengelbach",
  },
  {
    npa: "4803",
    canton: "AG",
    location: "Vordemwald",
  },
  {
    npa: "4805",
    canton: "AG",
    location: "Brittnau",
  },
  {
    npa: "4812",
    canton: "AG",
    location: "Mühlethal",
  },
  {
    npa: "4856",
    canton: "AG",
    location: "Glashütten",
  },
  {
    npa: "4901",
    canton: "BE",
    location: "Langenthal",
  },
  {
    npa: "4955",
    canton: "BE",
    location: "Gondiswil",
  },
  {
    npa: "4932",
    canton: "BE",
    location: "Lotzwil",
  },
  {
    npa: "4936",
    canton: "BE",
    location: "Kleindietwil",
  },
  {
    npa: "4944",
    canton: "BE",
    location: "Auswil",
  },
  {
    npa: "4938",
    canton: "BE",
    location: "Rohrbachgraben",
  },
  {
    npa: "4950",
    canton: "BE",
    location: "Huttwil",
  },
  {
    npa: "4953",
    canton: "BE",
    location: "Schwarzenbach (Huttwil)",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau Postauto Aargau",
  },
  {
    npa: "5012",
    canton: "SO",
    location: "Schönenwerd",
  },
  {
    npa: "5026",
    canton: "AG",
    location: "Densbüren",
  },
  {
    npa: "5034",
    canton: "AG",
    location: "Suhr",
  },
  {
    npa: "5036",
    canton: "AG",
    location: "Oberentfelden",
  },
  {
    npa: "5040",
    canton: "AG",
    location: "Schöftland",
  },
  {
    npa: "5042",
    canton: "AG",
    location: "Hirschthal",
  },
  {
    npa: "5043",
    canton: "AG",
    location: "Holziken",
  },
  {
    npa: "5044",
    canton: "AG",
    location: "Schlossrued",
  },
  {
    npa: "5053",
    canton: "AG",
    location: "Staffelbach",
  },
  {
    npa: "5054",
    canton: "AG",
    location: "Kirchleerau",
  },
  {
    npa: "5054",
    canton: "AG",
    location: "Moosleerau",
  },
  {
    npa: "5056",
    canton: "AG",
    location: "Attelwil",
  },
  {
    npa: "5057",
    canton: "AG",
    location: "Reitnau",
  },
  {
    npa: "5102",
    canton: "AG",
    location: "Rupperswil",
  },
  {
    npa: "5103",
    canton: "AG",
    location: "Wildegg",
  },
  {
    npa: "5106",
    canton: "AG",
    location: "Veltheim AG",
  },
  {
    npa: "5112",
    canton: "AG",
    location: "Thalheim AG",
  },
  {
    npa: "5200",
    canton: "AG",
    location: "Brugg AG 1",
  },
  {
    npa: "5210",
    canton: "AG",
    location: "Windisch",
  },
  {
    npa: "5200",
    canton: "AG",
    location: "Brugg AG Kaserne",
  },
  {
    npa: "5234",
    canton: "AG",
    location: "Villigen",
  },
  {
    npa: "5236",
    canton: "AG",
    location: "Remigen",
  },
  {
    npa: "5237",
    canton: "AG",
    location: "Mönthal",
  },
  {
    npa: "5242",
    canton: "AG",
    location: "Birr",
  },
  {
    npa: "5242",
    canton: "AG",
    location: "Lupfig",
  },
  {
    npa: "5243",
    canton: "AG",
    location: "Mülligen",
  },
  {
    npa: "5078",
    canton: "AG",
    location: "Effingen",
  },
  {
    npa: "5075",
    canton: "AG",
    location: "Hornussen",
  },
  {
    npa: "5070",
    canton: "AG",
    location: "Frick",
  },
  {
    npa: "5312",
    canton: "AG",
    location: "Döttingen",
  },
  {
    npa: "5322",
    canton: "AG",
    location: "Koblenz",
  },
  {
    npa: "5400",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5401",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5404",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5405",
    canton: "AG",
    location: "Baden",
  },
  {
    npa: "5415",
    canton: "AG",
    location: "Nussbaumen AG",
  },
  {
    npa: "5423",
    canton: "AG",
    location: "Freienwil",
  },
  {
    npa: "5425",
    canton: "AG",
    location: "Schneisingen",
  },
  {
    npa: "5426",
    canton: "AG",
    location: "Lengnau AG",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen 1",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen Dorf",
  },
  {
    npa: "5430",
    canton: "AG",
    location: "Wettingen 3",
  },
  {
    npa: "5442",
    canton: "AG",
    location: "Fislisbach",
  },
  {
    npa: "5443",
    canton: "AG",
    location: "Niederrohrdorf",
  },
  {
    npa: "5445",
    canton: "AG",
    location: "Eggenwil",
  },
  {
    npa: "5452",
    canton: "AG",
    location: "Oberrohrdorf",
  },
  {
    npa: "5502",
    canton: "AG",
    location: "Hunzenschwil",
  },
  {
    npa: "5505",
    canton: "AG",
    location: "Brunegg",
  },
  {
    npa: "5507",
    canton: "AG",
    location: "Mellingen",
  },
  {
    npa: "5512",
    canton: "AG",
    location: "Wohlenschwil",
  },
  {
    npa: "5524",
    canton: "AG",
    location: "Nesselnbach",
  },
  {
    npa: "5524",
    canton: "AG",
    location: "Niederwil AG",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Lenzburg",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Ammerswil AG",
  },
  {
    npa: "5603",
    canton: "AG",
    location: "Staufen",
  },
  {
    npa: "5605",
    canton: "AG",
    location: "Dottikon",
  },
  {
    npa: "5607",
    canton: "AG",
    location: "Hägglingen",
  },
  {
    npa: "5608",
    canton: "AG",
    location: "Stetten AG",
  },
  {
    npa: "5610",
    canton: "AG",
    location: "Wohlen AG Jurastrasse",
  },
  {
    npa: "5619",
    canton: "AG",
    location: "Büttikon AG",
  },
  {
    npa: "5617",
    canton: "AG",
    location: "Tennwil",
  },
  {
    npa: "5620",
    canton: "AG",
    location: "Bremgarten AG",
  },
  {
    npa: "5620",
    canton: "AG",
    location: "Bremgarten AG Kaserne",
  },
  {
    npa: "5622",
    canton: "AG",
    location: "Waltenschwil",
  },
  {
    npa: "5626",
    canton: "AG",
    location: "Hermetschwil-Staffeln",
  },
  {
    npa: "5628",
    canton: "AG",
    location: "Aristau",
  },
  {
    npa: "5630",
    canton: "AG",
    location: "Muri AG",
  },
  {
    npa: "5637",
    canton: "AG",
    location: "Beinwil (Freiamt)",
  },
  {
    npa: "5643",
    canton: "AG",
    location: "Sins",
  },
  {
    npa: "5643",
    canton: "AG",
    location: "Alikon",
  },
  {
    npa: "5704",
    canton: "AG",
    location: "Egliswil",
  },
  {
    npa: "5705",
    canton: "AG",
    location: "Hallwil",
  },
  {
    npa: "5712",
    canton: "AG",
    location: "Beinwil am See",
  },
  {
    npa: "5722",
    canton: "AG",
    location: "Gränichen",
  },
  {
    npa: "5723",
    canton: "AG",
    location: "Teufenthal AG",
  },
  {
    npa: "5724",
    canton: "AG",
    location: "Dürrenäsch",
  },
  {
    npa: "5725",
    canton: "AG",
    location: "Leutwil",
  },
  {
    npa: "5726",
    canton: "AG",
    location: "Unterkulm",
  },
  {
    npa: "5728",
    canton: "AG",
    location: "Gontenschwil",
  },
  {
    npa: "5732",
    canton: "AG",
    location: "Zetzwil",
  },
  {
    npa: "5737",
    canton: "AG",
    location: "Menziken",
  },
  {
    npa: "5745",
    canton: "AG",
    location: "Safenwil",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Wesemlin",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Kreuzstutz",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Schönbühl",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern 16",
  },
  {
    npa: "6004",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6012",
    canton: "LU",
    location: "Obernau",
  },
  {
    npa: "6014",
    canton: "LU",
    location: "Luzern",
  },
  {
    npa: "6017",
    canton: "LU",
    location: "Ruswil",
  },
  {
    npa: "6020",
    canton: "LU",
    location: "Emmenbrücke Bahnhof",
  },
  {
    npa: "6025",
    canton: "LU",
    location: "Neudorf",
  },
  {
    npa: "6028",
    canton: "LU",
    location: "Herlisberg",
  },
  {
    npa: "6035",
    canton: "LU",
    location: "Perlen",
  },
  {
    npa: "6037",
    canton: "LU",
    location: "Root",
  },
  {
    npa: "6048",
    canton: "LU",
    location: "Horw",
  },
  {
    npa: "6052",
    canton: "NW",
    location: "Hergiswil NW",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Ramersberg",
  },
  {
    npa: "6064",
    canton: "OW",
    location: "Kerns",
  },
  {
    npa: "6067",
    canton: "OW",
    location: "Melchtal",
  },
  {
    npa: "6072",
    canton: "OW",
    location: "Sachseln",
  },
  {
    npa: "6074",
    canton: "OW",
    location: "Giswil",
  },
  {
    npa: "6105",
    canton: "LU",
    location: "Schachen LU",
  },
  {
    npa: "6106",
    canton: "LU",
    location: "Werthenstein",
  },
  {
    npa: "6110",
    canton: "LU",
    location: "Wolhusen",
  },
  {
    npa: "6110",
    canton: "LU",
    location: "Fontannen b. Wolhusen",
  },
  {
    npa: "6122",
    canton: "LU",
    location: "Menznau",
  },
  {
    npa: "6123",
    canton: "LU",
    location: "Geiss",
  },
  {
    npa: "6126",
    canton: "LU",
    location: "Daiwil",
  },
  {
    npa: "6132",
    canton: "LU",
    location: "Rohrmatt",
  },
  {
    npa: "6154",
    canton: "LU",
    location: "Hofstatt",
  },
  {
    npa: "6160",
    canton: "LU",
    location: "Entlebuch Businesszentrum",
  },
  {
    npa: "6166",
    canton: "LU",
    location: "Hasle LU",
  },
  {
    npa: "6182",
    canton: "LU",
    location: "Escholzmatt",
  },
  {
    npa: "6196",
    canton: "LU",
    location: "Marbach LU",
  },
  {
    npa: "6204",
    canton: "LU",
    location: "Sempach",
  },
  {
    npa: "6207",
    canton: "LU",
    location: "Nottwil",
  },
  {
    npa: "6208",
    canton: "LU",
    location: "Oberkirch LU",
  },
  {
    npa: "6210",
    canton: "LU",
    location: "Sursee",
  },
  {
    npa: "6211",
    canton: "LU",
    location: "Buchs LU",
  },
  {
    npa: "6214",
    canton: "LU",
    location: "Schenkon",
  },
  {
    npa: "6221",
    canton: "LU",
    location: "Rickenbach LU",
  },
  {
    npa: "6244",
    canton: "LU",
    location: "Nebikon",
  },
  {
    npa: "6246",
    canton: "LU",
    location: "Altishofen",
  },
  {
    npa: "6248",
    canton: "LU",
    location: "Alberswil",
  },
  {
    npa: "6252",
    canton: "LU",
    location: "Dagmersellen",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Reiden",
  },
  {
    npa: "6264",
    canton: "LU",
    location: "Pfaffnau",
  },
  {
    npa: "6284",
    canton: "LU",
    location: "Gelfingen",
  },
  {
    npa: "6286",
    canton: "LU",
    location: "Altwis",
  },
  {
    npa: "6288",
    canton: "LU",
    location: "Schongau",
  },
  {
    npa: "6300",
    canton: "ZG",
    location: "Zug Altstadt",
  },
  {
    npa: "6313",
    canton: "ZG",
    location: "Finstersee",
  },
  {
    npa: "6313",
    canton: "ZG",
    location: "Menzingen",
  },
  {
    npa: "6314",
    canton: "ZG",
    location: "Neuägeri",
  },
  {
    npa: "6315",
    canton: "ZG",
    location: "Oberägeri",
  },
  {
    npa: "6317",
    canton: "ZG",
    location: "Oberwil b. Zug",
  },
  {
    npa: "6318",
    canton: "ZG",
    location: "Walchwil",
  },
  {
    npa: "6330",
    canton: "ZG",
    location: "Cham",
  },
  {
    npa: "6343",
    canton: "ZG",
    location: "Rotkreuz",
  },
  {
    npa: "6362",
    canton: "NW",
    location: "Stansstad",
  },
  {
    npa: "6363",
    canton: "NW",
    location: "Obbürgen",
  },
  {
    npa: "6375",
    canton: "NW",
    location: "Beckenried",
  },
  {
    npa: "6377",
    canton: "UR",
    location: "Seelisberg",
  },
  {
    npa: "6383",
    canton: "NW",
    location: "Dallenwil",
  },
  {
    npa: "6387",
    canton: "NW",
    location: "Oberrickenbach",
  },
  {
    npa: "6390",
    canton: "OW",
    location: "Engelberg",
  },
  {
    npa: "6405",
    canton: "SZ",
    location: "Immensee",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Goldau",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Rigi Kulm",
  },
  {
    npa: "6414",
    canton: "SZ",
    location: "Oberarth",
  },
  {
    npa: "6422",
    canton: "SZ",
    location: "Steinen",
  },
  {
    npa: "6423",
    canton: "SZ",
    location: "Seewen SZ",
  },
  {
    npa: "6424",
    canton: "SZ",
    location: "Lauerz",
  },
  {
    npa: "6436",
    canton: "SZ",
    location: "Muotathal",
  },
  {
    npa: "6436",
    canton: "SZ",
    location: "Bisisthal",
  },
  {
    npa: "6438",
    canton: "SZ",
    location: "Ibach",
  },
  {
    npa: "6454",
    canton: "UR",
    location: "Flüelen",
  },
  {
    npa: "6460",
    canton: "UR",
    location: "Altdorf UR",
  },
  {
    npa: "6462",
    canton: "UR",
    location: "Seedorf UR",
  },
  {
    npa: "6463",
    canton: "UR",
    location: "Bürglen UR",
  },
  {
    npa: "6467",
    canton: "UR",
    location: "Schattdorf",
  },
  {
    npa: "6473",
    canton: "UR",
    location: "Silenen",
  },
  {
    npa: "6476",
    canton: "UR",
    location: "Intschi",
  },
  {
    npa: "6482",
    canton: "UR",
    location: "Gurtnellen",
  },
  {
    npa: "6490",
    canton: "UR",
    location: "Andermatt",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona 1",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona 2",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona 5",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona Distribuzione",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona Caserma",
  },
  {
    npa: "6506",
    canton: "TI",
    location: "Bellinzona 6 Autopostale TI",
  },
  {
    npa: "6514",
    canton: "TI",
    location: "Sementina",
  },
  {
    npa: "6515",
    canton: "TI",
    location: "Gudo",
  },
  {
    npa: "6516",
    canton: "TI",
    location: "Cugnasco",
  },
  {
    npa: "6525",
    canton: "TI",
    location: "Gnosca",
  },
  {
    npa: "6527",
    canton: "TI",
    location: "Lodrino",
  },
  {
    npa: "6528",
    canton: "TI",
    location: "Camorino",
  },
  {
    npa: "6532",
    canton: "TI",
    location: "Castione",
  },
  {
    npa: "6533",
    canton: "TI",
    location: "Lumino",
  },
  {
    npa: "6537",
    canton: "GR",
    location: "Grono",
  },
  {
    npa: "6523",
    canton: "TI",
    location: "Preonzo",
  },
  {
    npa: "6582",
    canton: "TI",
    location: "Pianezzo",
  },
  {
    npa: "6549",
    canton: "GR",
    location: "Laura",
  },
  {
    npa: "6541",
    canton: "GR",
    location: "Sta. Maria in Calanca",
  },
  {
    npa: "6544",
    canton: "GR",
    location: "Braggio",
  },
  {
    npa: "6548",
    canton: "GR",
    location: "Augio",
  },
  {
    npa: "6572",
    canton: "TI",
    location: "Quartino",
  },
  {
    npa: "6577",
    canton: "TI",
    location: "Ranzo",
  },
  {
    npa: "6579",
    canton: "TI",
    location: "Piazzogna",
  },
  {
    npa: "6592",
    canton: "TI",
    location: "S. Antonino",
  },
  {
    npa: "6593",
    canton: "TI",
    location: "Cadenazzo",
  },
  {
    npa: "6594",
    canton: "TI",
    location: "Contone",
  },
  {
    npa: "6596",
    canton: "TI",
    location: "Gordola",
  },
  {
    npa: "6599",
    canton: "TI",
    location: "Robasacco",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Muralto",
  },
  {
    npa: "6604",
    canton: "TI",
    location: "Locarno",
  },
  {
    npa: "6605",
    canton: "TI",
    location: "Locarno",
  },
  {
    npa: "6618",
    canton: "TI",
    location: "Arcegno",
  },
  {
    npa: "6661",
    canton: "TI",
    location: "Auressio",
  },
  {
    npa: "6611",
    canton: "TI",
    location: "Mosogno",
  },
  {
    npa: "6664",
    canton: "TI",
    location: "Vergeletto",
  },
  {
    npa: "6611",
    canton: "TI",
    location: "Gresso",
  },
  {
    npa: "6611",
    canton: "TI",
    location: "Crana",
  },
  {
    npa: "6637",
    canton: "TI",
    location: "Sonogno",
  },
  {
    npa: "6613",
    canton: "TI",
    location: "Porto Ronco",
  },
  {
    npa: "6614",
    canton: "TI",
    location: "Brissago",
  },
  {
    npa: "6616",
    canton: "TI",
    location: "Losone",
  },
  {
    npa: "6658",
    canton: "TI",
    location: "Borgnone",
  },
  {
    npa: "6652",
    canton: "TI",
    location: "Tegna",
  },
  {
    npa: "6653",
    canton: "TI",
    location: "Verscio",
  },
  {
    npa: "6654",
    canton: "TI",
    location: "Cavigliano",
  },
  {
    npa: "6677",
    canton: "TI",
    location: "Moghegno",
  },
  {
    npa: "6678",
    canton: "TI",
    location: "Lodano",
  },
  {
    npa: "6678",
    canton: "TI",
    location: "Giumaglio",
  },
  {
    npa: "6674",
    canton: "TI",
    location: "Riveo",
  },
  {
    npa: "6683",
    canton: "TI",
    location: "Niva (Vallemaggia)",
  },
  {
    npa: "6692",
    canton: "TI",
    location: "Menzonio",
  },
  {
    npa: "6694",
    canton: "TI",
    location: "Prato-Sornico",
  },
  {
    npa: "6673",
    canton: "TI",
    location: "Maggia",
  },
  {
    npa: "6674",
    canton: "TI",
    location: "Someo",
  },
  {
    npa: "6675",
    canton: "TI",
    location: "Cevio",
  },
  {
    npa: "6703",
    canton: "TI",
    location: "Osogna",
  },
  {
    npa: "6716",
    canton: "TI",
    location: "Leontica",
  },
  {
    npa: "6723",
    canton: "TI",
    location: "Prugiasco",
  },
  {
    npa: "6724",
    canton: "TI",
    location: "Ponto Valentino",
  },
  {
    npa: "6719",
    canton: "TI",
    location: "Aquila",
  },
  {
    npa: "6720",
    canton: "TI",
    location: "Campo (Blenio)",
  },
  {
    npa: "6715",
    canton: "TI",
    location: "Dongio",
  },
  {
    npa: "6716",
    canton: "TI",
    location: "Acquarossa",
  },
  {
    npa: "6718",
    canton: "TI",
    location: "Olivone",
  },
  {
    npa: "6718",
    canton: "TI",
    location: "Camperio",
  },
  {
    npa: "6742",
    canton: "TI",
    location: "Pollegio",
  },
  {
    npa: "6743",
    canton: "TI",
    location: "Bodio TI",
  },
  {
    npa: "6746",
    canton: "TI",
    location: "Lavorgo",
  },
  {
    npa: "6746",
    canton: "TI",
    location: "Calonico",
  },
  {
    npa: "6746",
    canton: "TI",
    location: "Nivo",
  },
  {
    npa: "6747",
    canton: "TI",
    location: "Chironico",
  },
  {
    npa: "6760",
    canton: "TI",
    location: "Faido",
  },
  {
    npa: "6777",
    canton: "TI",
    location: "Varenzo",
  },
  {
    npa: "6749",
    canton: "TI",
    location: "Sobrio",
  },
  {
    npa: "6749",
    canton: "TI",
    location: "Cavagnago",
  },
  {
    npa: "6763",
    canton: "TI",
    location: "Osco",
  },
  {
    npa: "6815",
    canton: "TI",
    location: "Melide",
  },
  {
    npa: "6816",
    canton: "TI",
    location: "Bissone",
  },
  {
    npa: "6817",
    canton: "TI",
    location: "Maroggia",
  },
  {
    npa: "6825",
    canton: "TI",
    location: "Capolago",
  },
  {
    npa: "6826",
    canton: "TI",
    location: "Riva San Vitale",
  },
  {
    npa: "6828",
    canton: "TI",
    location: "Balerna",
  },
  {
    npa: "6830",
    canton: "TI",
    location: "Chiasso",
  },
  {
    npa: "6837",
    canton: "TI",
    location: "Bruzella",
  },
  {
    npa: "6838",
    canton: "TI",
    location: "Cabbio",
  },
  {
    npa: "6833",
    canton: "TI",
    location: "Vacallo",
  },
  {
    npa: "6834",
    canton: "TI",
    location: "Morbio Inferiore",
  },
  {
    npa: "6835",
    canton: "TI",
    location: "Morbio Superiore",
  },
  {
    npa: "6809",
    canton: "TI",
    location: "Medeglia",
  },
  {
    npa: "6810",
    canton: "TI",
    location: "Isone",
  },
  {
    npa: "6875",
    canton: "TI",
    location: "Monte",
  },
  {
    npa: "6873",
    canton: "TI",
    location: "Corteglia",
  },
  {
    npa: "6853",
    canton: "TI",
    location: "Ligornetto",
  },
  {
    npa: "6855",
    canton: "TI",
    location: "Stabio",
  },
  {
    npa: "6862",
    canton: "TI",
    location: "Rancate",
  },
  {
    npa: "6865",
    canton: "TI",
    location: "Tremona",
  },
  {
    npa: "6866",
    canton: "TI",
    location: "Meride",
  },
  {
    npa: "6867",
    canton: "TI",
    location: "Serpiano",
  },
  {
    npa: "6872",
    canton: "TI",
    location: "Salorino",
  },
  {
    npa: "6901",
    canton: "TI",
    location: "Lugano",
  },
  {
    npa: "6902",
    canton: "TI",
    location: "Lugano 2 Paradiso Caselle",
  },
  {
    npa: "6904",
    canton: "TI",
    location: "Lugano 4 Molino Nuovo Casel",
  },
  {
    npa: "6905",
    canton: "TI",
    location: "Lugano 5 Serv Autopostali",
  },
  {
    npa: "6917",
    canton: "TI",
    location: "Barbengo",
  },
  {
    npa: "6979",
    canton: "TI",
    location: "Brè sopra Lugano",
  },
  {
    npa: "6827",
    canton: "TI",
    location: "Brusino Arsizio",
  },
  {
    npa: "6912",
    canton: "TI",
    location: "Pazzallo",
  },
  {
    npa: "6922",
    canton: "TI",
    location: "Morcote",
  },
  {
    npa: "6935",
    canton: "TI",
    location: "Bosco Luganese",
  },
  {
    npa: "6942",
    canton: "TI",
    location: "Savosa",
  },
  {
    npa: "6943",
    canton: "TI",
    location: "Vezia",
  },
  {
    npa: "6948",
    canton: "TI",
    location: "Porza",
  },
  {
    npa: "6944",
    canton: "TI",
    location: "Cureglia",
  },
  {
    npa: "6947",
    canton: "TI",
    location: "Vaglio",
  },
  {
    npa: "6954",
    canton: "TI",
    location: "Sala Capriasca",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Scareglia",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Colla",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Bogno",
  },
  {
    npa: "6951",
    canton: "TI",
    location: "Cozzo",
  },
  {
    npa: "6959",
    canton: "TI",
    location: "Maglio di Colla",
  },
  {
    npa: "6952",
    canton: "TI",
    location: "Canobbio",
  },
  {
    npa: "6964",
    canton: "TI",
    location: "Davesco-Soragno",
  },
  {
    npa: "6966",
    canton: "TI",
    location: "Villa Luganese",
  },
  {
    npa: "6991",
    canton: "TI",
    location: "Neggio",
  },
  {
    npa: "6994",
    canton: "TI",
    location: "Aranno",
  },
  {
    npa: "6992",
    canton: "TI",
    location: "Cimo",
  },
  {
    npa: "6980",
    canton: "TI",
    location: "Castelrotto",
  },
  {
    npa: "6981",
    canton: "TI",
    location: "Bombinasco",
  },
  {
    npa: "6982",
    canton: "TI",
    location: "Agno",
  },
  {
    npa: "6984",
    canton: "TI",
    location: "Pura",
  },
  {
    npa: "6987",
    canton: "TI",
    location: "Caslano",
  },
  {
    npa: "7000",
    canton: "GR",
    location: "Chur",
  },
  {
    npa: "7000",
    canton: "GR",
    location: "Chur Kaserne",
  },
  {
    npa: "7001",
    canton: "GR",
    location: "Chur",
  },
  {
    npa: "7015",
    canton: "GR",
    location: "Tamins",
  },
  {
    npa: "7017",
    canton: "GR",
    location: "Flims Dorf",
  },
  {
    npa: "7018",
    canton: "GR",
    location: "Flims Waldhaus",
  },
  {
    npa: "7031",
    canton: "GR",
    location: "Laax GR",
  },
  {
    npa: "7063",
    canton: "GR",
    location: "Praden",
  },
  {
    npa: "7064",
    canton: "GR",
    location: "Tschiertschen",
  },
  {
    npa: "7075",
    canton: "GR",
    location: "Churwalden",
  },
  {
    npa: "7078",
    canton: "GR",
    location: "Lenzerheide/Lai",
  },
  {
    npa: "7082",
    canton: "GR",
    location: "Vaz/Obervaz",
  },
  {
    npa: "7083",
    canton: "GR",
    location: "Lantsch/Lenz",
  },
  {
    npa: "7014",
    canton: "GR",
    location: "Trin",
  },
  {
    npa: "7027",
    canton: "GR",
    location: "Castiel",
  },
  {
    npa: "7028",
    canton: "GR",
    location: "Pagig",
  },
  {
    npa: "7058",
    canton: "GR",
    location: "Litzirüti",
  },
  {
    npa: "7104",
    canton: "GR",
    location: "Versam",
  },
  {
    npa: "7109",
    canton: "GR",
    location: "Thalkirch",
  },
  {
    npa: "7155",
    canton: "GR",
    location: "Ladir",
  },
  {
    npa: "7153",
    canton: "GR",
    location: "Falera",
  },
  {
    npa: "7128",
    canton: "GR",
    location: "Riein",
  },
  {
    npa: "7111",
    canton: "GR",
    location: "Pitasch",
  },
  {
    npa: "7112",
    canton: "GR",
    location: "Duvin",
  },
  {
    npa: "7141",
    canton: "GR",
    location: "Luven",
  },
  {
    npa: "7143",
    canton: "GR",
    location: "Morissen",
  },
  {
    npa: "7146",
    canton: "GR",
    location: "Vattiz",
  },
  {
    npa: "7147",
    canton: "GR",
    location: "Vignogn",
  },
  {
    npa: "7148",
    canton: "GR",
    location: "Lumbrein",
  },
  {
    npa: "7149",
    canton: "GR",
    location: "Vrin",
  },
  {
    npa: "7134",
    canton: "GR",
    location: "Obersaxen",
  },
  {
    npa: "7156",
    canton: "GR",
    location: "Rueun",
  },
  {
    npa: "7158",
    canton: "GR",
    location: "Waltensburg/Vuorz",
  },
  {
    npa: "7162",
    canton: "GR",
    location: "Tavanasa",
  },
  {
    npa: "7167",
    canton: "GR",
    location: "Zignau",
  },
  {
    npa: "7175",
    canton: "GR",
    location: "Sumvitg",
  },
  {
    npa: "7180",
    canton: "GR",
    location: "Disentis/Mustér",
  },
  {
    npa: "7182",
    canton: "GR",
    location: "Cavardiras",
  },
  {
    npa: "7186",
    canton: "GR",
    location: "Segnas",
  },
  {
    npa: "7206",
    canton: "GR",
    location: "Igis",
  },
  {
    npa: "7212",
    canton: "GR",
    location: "Seewis Dorf",
  },
  {
    npa: "7220",
    canton: "GR",
    location: "Schiers",
  },
  {
    npa: "7226",
    canton: "GR",
    location: "Stels",
  },
  {
    npa: "7232",
    canton: "GR",
    location: "Furna",
  },
  {
    npa: "7245",
    canton: "GR",
    location: "Ascharina",
  },
  {
    npa: "7246",
    canton: "GR",
    location: "St. Antönien",
  },
  {
    npa: "7247",
    canton: "GR",
    location: "Saas im Prättigau",
  },
  {
    npa: "7250",
    canton: "GR",
    location: "Klosters",
  },
  {
    npa: "7252",
    canton: "GR",
    location: "Klosters Dorf",
  },
  {
    npa: "7260",
    canton: "GR",
    location: "Davos Dorf",
  },
  {
    npa: "7304",
    canton: "GR",
    location: "Maienfeld",
  },
  {
    npa: "7317",
    canton: "SG",
    location: "Valens",
  },
  {
    npa: "7315",
    canton: "SG",
    location: "Vättis",
  },
  {
    npa: "7320",
    canton: "SG",
    location: "Sargans",
  },
  {
    npa: "7326",
    canton: "SG",
    location: "Weisstannen",
  },
  {
    npa: "7405",
    canton: "GR",
    location: "Rothenbrunnen",
  },
  {
    npa: "7412",
    canton: "GR",
    location: "Scharans",
  },
  {
    npa: "7414",
    canton: "GR",
    location: "Fürstenau",
  },
  {
    npa: "7417",
    canton: "GR",
    location: "Paspels",
  },
  {
    npa: "7426",
    canton: "GR",
    location: "Flerden",
  },
  {
    npa: "7423",
    canton: "GR",
    location: "Sarn",
  },
  {
    npa: "7424",
    canton: "GR",
    location: "Präz",
  },
  {
    npa: "7431",
    canton: "GR",
    location: "Mutten",
  },
  {
    npa: "7433",
    canton: "GR",
    location: "Wergenstein",
  },
  {
    npa: "7433",
    canton: "GR",
    location: "Lohn GR",
  },
  {
    npa: "7434",
    canton: "GR",
    location: "Sufers",
  },
  {
    npa: "7436",
    canton: "GR",
    location: "Medels im Rheinwald",
  },
  {
    npa: "7437",
    canton: "GR",
    location: "Nufenen",
  },
  {
    npa: "7438",
    canton: "GR",
    location: "Hinterrhein",
  },
  {
    npa: "7440",
    canton: "GR",
    location: "Andeer",
  },
  {
    npa: "7443",
    canton: "GR",
    location: "Pignia",
  },
  {
    npa: "7444",
    canton: "GR",
    location: "Ausserferrera",
  },
  {
    npa: "7451",
    canton: "GR",
    location: "Alvaschein",
  },
  {
    npa: "7462",
    canton: "GR",
    location: "Salouf",
  },
  {
    npa: "7464",
    canton: "GR",
    location: "Parsonz",
  },
  {
    npa: "7473",
    canton: "GR",
    location: "Alvaneu Bad",
  },
  {
    npa: "7482",
    canton: "GR",
    location: "Stugl/Stuls",
  },
  {
    npa: "7484",
    canton: "GR",
    location: "Latsch",
  },
  {
    npa: "7500",
    canton: "GR",
    location: "St. Moritz",
  },
  {
    npa: "7500",
    canton: "GR",
    location: "St. Moritz 3",
  },
  {
    npa: "7502",
    canton: "GR",
    location: "Bever",
  },
  {
    npa: "7504",
    canton: "GR",
    location: "Pontresina",
  },
  {
    npa: "7513",
    canton: "GR",
    location: "Silvaplana",
  },
  {
    npa: "7515",
    canton: "GR",
    location: "Sils/Segl Baselgia",
  },
  {
    npa: "7522",
    canton: "GR",
    location: "La Punt Chamues-ch",
  },
  {
    npa: "7526",
    canton: "GR",
    location: "Cinuos-chel",
  },
  {
    npa: "7527",
    canton: "GR",
    location: "Brail",
  },
  {
    npa: "7530",
    canton: "GR",
    location: "Zernez",
  },
  {
    npa: "7551",
    canton: "GR",
    location: "Ftan",
  },
  {
    npa: "7554",
    canton: "GR",
    location: "Sent",
  },
  {
    npa: "7556",
    canton: "GR",
    location: "Ramosch",
  },
  {
    npa: "7602",
    canton: "GR",
    location: "Casaccia",
  },
  {
    npa: "7604",
    canton: "GR",
    location: "Borgonovo",
  },
  {
    npa: "7742",
    canton: "GR",
    location: "Poschiavo",
  },
  {
    npa: "7742",
    canton: "GR",
    location: "La Rösa",
  },
  {
    npa: "8003",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8004",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8006",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich Mülligen PL",
  },
  {
    npa: "8021",
    canton: "ZH",
    location: "Zürich 1 Sihlpost",
  },
  {
    npa: "8021",
    canton: "ZH",
    location: "Zürich 1",
  },
  {
    npa: "8023",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8032",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8033",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8034",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8042",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8046",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8050",
    canton: "ZH",
    location: "Zürich Dist Ba",
  },
  {
    npa: "8052",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8053",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8055",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8057",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8058",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8061",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8088",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8091",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "8092",
    canton: "ZH",
    location: "Zürich ETH-Zentrum",
  },
  {
    npa: "8102",
    canton: "ZH",
    location: "Oberengstringen",
  },
  {
    npa: "8103",
    canton: "ZH",
    location: "Unterengstringen",
  },
  {
    npa: "8108",
    canton: "ZH",
    location: "Dällikon",
  },
  {
    npa: "8112",
    canton: "ZH",
    location: "Otelfingen",
  },
  {
    npa: "5436",
    canton: "AG",
    location: "Würenlos",
  },
  {
    npa: "8124",
    canton: "ZH",
    location: "Maur",
  },
  {
    npa: "8125",
    canton: "ZH",
    location: "Zollikerberg",
  },
  {
    npa: "8126",
    canton: "ZH",
    location: "Zumikon",
  },
  {
    npa: "8134",
    canton: "ZH",
    location: "Adliswil 1",
  },
  {
    npa: "8143",
    canton: "ZH",
    location: "Uetliberg",
  },
  {
    npa: "8152",
    canton: "ZH",
    location: "Glattbrugg",
  },
  {
    npa: "8153",
    canton: "ZH",
    location: "Rümlang",
  },
  {
    npa: "8155",
    canton: "ZH",
    location: "Niederhasli",
  },
  {
    npa: "8156",
    canton: "ZH",
    location: "Oberhasli",
  },
  {
    npa: "8164",
    canton: "ZH",
    location: "Bachs",
  },
  {
    npa: "8165",
    canton: "ZH",
    location: "Schöfflisdorf",
  },
  {
    npa: "8166",
    canton: "ZH",
    location: "Niederweningen",
  },
  {
    npa: "8172",
    canton: "ZH",
    location: "Niederglatt ZH",
  },
  {
    npa: "8175",
    canton: "ZH",
    location: "Windlach",
  },
  {
    npa: "8180",
    canton: "ZH",
    location: "Bülach",
  },
  {
    npa: "8184",
    canton: "ZH",
    location: "Bachenbülach",
  },
  {
    npa: "8200",
    canton: "SH",
    location: "Schaffhausen 1",
  },
  {
    npa: "8202",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8204",
    canton: "SH",
    location: "Schaffhausen Breite",
  },
  {
    npa: "8208",
    canton: "SH",
    location: "Schaffhausen",
  },
  {
    npa: "8231",
    canton: "SH",
    location: "Hemmental",
  },
  {
    npa: "8236",
    canton: "SH",
    location: "Opfertshofen SH",
  },
  {
    npa: "8242",
    canton: "SH",
    location: "Bibern SH",
  },
  {
    npa: "8212",
    canton: "SH",
    location: "Neuhausen am Rheinfall",
  },
  {
    npa: "8222",
    canton: "SH",
    location: "Beringen",
  },
  {
    npa: "8223",
    canton: "SH",
    location: "Guntmadingen",
  },
  {
    npa: "8233",
    canton: "SH",
    location: "Bargen SH",
  },
  {
    npa: "8241",
    canton: "SH",
    location: "Barzheim",
  },
  {
    npa: "8245",
    canton: "ZH",
    location: "Feuerthalen",
  },
  {
    npa: "8254",
    canton: "TG",
    location: "Basadingen",
  },
  {
    npa: "8265",
    canton: "TG",
    location: "Mammern",
  },
  {
    npa: "8266",
    canton: "TG",
    location: "Steckborn",
  },
  {
    npa: "8274",
    canton: "TG",
    location: "Tägerwilen",
  },
  {
    npa: "8280",
    canton: "TG",
    location: "Kreuzlingen",
  },
  {
    npa: "8280",
    canton: "TG",
    location: "Kreuzlingen 3",
  },
  {
    npa: "8301",
    canton: "ZH",
    location: "Glattzentrum b. Wallisellen",
  },
  {
    npa: "8304",
    canton: "ZH",
    location: "Wallisellen",
  },
  {
    npa: "8320",
    canton: "ZH",
    location: "Fehraltorf",
  },
  {
    npa: "8331",
    canton: "ZH",
    location: "Auslikon",
  },
  {
    npa: "8332",
    canton: "ZH",
    location: "Russikon",
  },
  {
    npa: "8355",
    canton: "TG",
    location: "Aadorf",
  },
  {
    npa: "8360",
    canton: "TG",
    location: "Eschlikon TG",
  },
  {
    npa: "8362",
    canton: "TG",
    location: "Balterswil",
  },
  {
    npa: "8370",
    canton: "TG",
    location: "Sirnach",
  },
  {
    npa: "8372",
    canton: "TG",
    location: "Wiezikon b. Sirnach",
  },
  {
    npa: "8374",
    canton: "TG",
    location: "Oberwangen TG",
  },
  {
    npa: "8376",
    canton: "TG",
    location: "Au TG",
  },
  {
    npa: "8400",
    canton: "ZH",
    location: "Winterthur 1 Annahme",
  },
  {
    npa: "8400",
    canton: "ZH",
    location: "Winterthur Kaserne",
  },
  {
    npa: "8404",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8406",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8409",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8410",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8411",
    canton: "ZH",
    location: "Winterthur",
  },
  {
    npa: "8415",
    canton: "ZH",
    location: "Gräslikon",
  },
  {
    npa: "8416",
    canton: "ZH",
    location: "Flaach",
  },
  {
    npa: "8422",
    canton: "ZH",
    location: "Pfungen",
  },
  {
    npa: "8426",
    canton: "ZH",
    location: "Lufingen",
  },
  {
    npa: "5467",
    canton: "AG",
    location: "Fisibach",
  },
  {
    npa: "5463",
    canton: "AG",
    location: "Wislikofen",
  },
  {
    npa: "5334",
    canton: "AG",
    location: "Böbikon",
  },
  {
    npa: "8447",
    canton: "ZH",
    location: "Dachsen",
  },
  {
    npa: "8450",
    canton: "ZH",
    location: "Andelfingen",
  },
  {
    npa: "8453",
    canton: "ZH",
    location: "Alten",
  },
  {
    npa: "8458",
    canton: "ZH",
    location: "Dorf",
  },
  {
    npa: "8454",
    canton: "SH",
    location: "Buchberg",
  },
  {
    npa: "8460",
    canton: "ZH",
    location: "Marthalen",
  },
  {
    npa: "8404",
    canton: "ZH",
    location: "Reutlingen (Winterthur)",
  },
  {
    npa: "8475",
    canton: "ZH",
    location: "Ossingen",
  },
  {
    npa: "8476",
    canton: "ZH",
    location: "Unterstammheim",
  },
  {
    npa: "8471",
    canton: "ZH",
    location: "Rutschwil (Dägerlen)",
  },
  {
    npa: "8478",
    canton: "ZH",
    location: "Thalheim an der Thur",
  },
  {
    npa: "8468",
    canton: "ZH",
    location: "Guntalingen",
  },
  {
    npa: "8483",
    canton: "ZH",
    location: "Kollbrunn",
  },
  {
    npa: "8487",
    canton: "ZH",
    location: "Rämismühle",
  },
  {
    npa: "8487",
    canton: "ZH",
    location: "Zell ZH",
  },
  {
    npa: "8493",
    canton: "ZH",
    location: "Saland",
  },
  {
    npa: "8495",
    canton: "ZH",
    location: "Schmidrüti",
  },
  {
    npa: "8499",
    canton: "ZH",
    location: "Sternenberg",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld",
  },
  {
    npa: "8500",
    canton: "TG",
    location: "Frauenfeld 1 Zustellung",
  },
  {
    npa: "8506",
    canton: "TG",
    location: "Lanzenneunforn",
  },
  {
    npa: "8508",
    canton: "TG",
    location: "Homburg",
  },
  {
    npa: "8522",
    canton: "TG",
    location: "Häuslenen",
  },
  {
    npa: "8525",
    canton: "TG",
    location: "Niederneunforn",
  },
  {
    npa: "8532",
    canton: "TG",
    location: "Weiningen TG",
  },
  {
    npa: "8537",
    canton: "TG",
    location: "Nussbaumen TG",
  },
  {
    npa: "8542",
    canton: "ZH",
    location: "Wiesendangen",
  },
  {
    npa: "8544",
    canton: "ZH",
    location: "Attikon",
  },
  {
    npa: "8546",
    canton: "TG",
    location: "Islikon",
  },
  {
    npa: "8553",
    canton: "TG",
    location: "Hüttlingen-Mettendorf",
  },
  {
    npa: "8554",
    canton: "TG",
    location: "Müllheim-Wigoltingen",
  },
  {
    npa: "8556",
    canton: "TG",
    location: "Wigoltingen",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Lipperswil",
  },
  {
    npa: "8558",
    canton: "TG",
    location: "Raperswilen",
  },
  {
    npa: "8565",
    canton: "TG",
    location: "Hugelshofen",
  },
  {
    npa: "8566",
    canton: "TG",
    location: "Neuwilen",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Wäldi",
  },
  {
    npa: "8572",
    canton: "TG",
    location: "Berg TG",
  },
  {
    npa: "8573",
    canton: "TG",
    location: "Siegershausen",
  },
  {
    npa: "8575",
    canton: "TG",
    location: "Bürglen TG",
  },
  {
    npa: "9217",
    canton: "TG",
    location: "Neukirch an der Thur",
  },
  {
    npa: "8582",
    canton: "TG",
    location: "Dozwil",
  },
  {
    npa: "8589",
    canton: "TG",
    location: "Sitterdorf",
  },
  {
    npa: "8583",
    canton: "TG",
    location: "Götighofen",
  },
  {
    npa: "8584",
    canton: "TG",
    location: "Opfershofen TG",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Langrickenbach",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Kümmertshausen",
  },
  {
    npa: "8594",
    canton: "TG",
    location: "Güttingen",
  },
  {
    npa: "8595",
    canton: "TG",
    location: "Altnau",
  },
  {
    npa: "8596",
    canton: "TG",
    location: "Scherzingen",
  },
  {
    npa: "8598",
    canton: "TG",
    location: "Bottighofen",
  },
  {
    npa: "8602",
    canton: "ZH",
    location: "Wangen b. Dübendorf",
  },
  {
    npa: "8603",
    canton: "ZH",
    location: "Schwerzenbach",
  },
  {
    npa: "8606",
    canton: "ZH",
    location: "Greifensee",
  },
  {
    npa: "8608",
    canton: "ZH",
    location: "Bubikon",
  },
  {
    npa: "8610",
    canton: "ZH",
    location: "Uster",
  },
  {
    npa: "8612",
    canton: "ZH",
    location: "Uster Niederuster",
  },
  {
    npa: "8613",
    canton: "ZH",
    location: "Uster 3",
  },
  {
    npa: "8614",
    canton: "ZH",
    location: "Bertschikon (Gossau ZH)",
  },
  {
    npa: "8615",
    canton: "ZH",
    location: "Wermatswil",
  },
  {
    npa: "8620",
    canton: "ZH",
    location: "Wetzikon ZH 1",
  },
  {
    npa: "8624",
    canton: "ZH",
    location: "Grüt (Gossau ZH)",
  },
  {
    npa: "8625",
    canton: "ZH",
    location: "Gossau ZH",
  },
  {
    npa: "8630",
    canton: "ZH",
    location: "Rüti ZH",
  },
  {
    npa: "8634",
    canton: "ZH",
    location: "Hombrechtikon",
  },
  {
    npa: "8635",
    canton: "ZH",
    location: "Dürnten",
  },
  {
    npa: "8637",
    canton: "ZH",
    location: "Laupen ZH",
  },
  {
    npa: "8640",
    canton: "SG",
    location: "Kempraten",
  },
  {
    npa: "8646",
    canton: "SG",
    location: "Wagen",
  },
  {
    npa: "8703",
    canton: "ZH",
    location: "Erlenbach ZH",
  },
  {
    npa: "8722",
    canton: "SG",
    location: "Kaltbrunn",
  },
  {
    npa: "8727",
    canton: "SG",
    location: "Walde SG",
  },
  {
    npa: "8730",
    canton: "SG",
    location: "Uznach",
  },
  {
    npa: "8732",
    canton: "SG",
    location: "Neuhaus SG",
  },
  {
    npa: "8734",
    canton: "SG",
    location: "Ermenswil",
  },
  {
    npa: "8735",
    canton: "SG",
    location: "Rüeterswil",
  },
  {
    npa: "8738",
    canton: "SG",
    location: "Uetliburg SG",
  },
  {
    npa: "8750",
    canton: "GL",
    location: "Glarus",
  },
  {
    npa: "8752",
    canton: "GL",
    location: "Näfels",
  },
  {
    npa: "8753",
    canton: "GL",
    location: "Mollis",
  },
  {
    npa: "8762",
    canton: "GL",
    location: "Schwändi b. Schwanden",
  },
  {
    npa: "8762",
    canton: "GL",
    location: "Sool",
  },
  {
    npa: "8767",
    canton: "GL",
    location: "Elm",
  },
  {
    npa: "8773",
    canton: "GL",
    location: "Haslen GL",
  },
  {
    npa: "8775",
    canton: "GL",
    location: "Luchsingen",
  },
  {
    npa: "8777",
    canton: "GL",
    location: "Diesbach GL",
  },
  {
    npa: "8783",
    canton: "GL",
    location: "Linthal",
  },
  {
    npa: "8805",
    canton: "ZH",
    location: "Richterswil Burghalden SOB",
  },
  {
    npa: "8808",
    canton: "SZ",
    location: "Pfäffikon SZ",
  },
  {
    npa: "8832",
    canton: "SZ",
    location: "Wollerau",
  },
  {
    npa: "8833",
    canton: "ZH",
    location: "Samstagern",
  },
  {
    npa: "8835",
    canton: "SZ",
    location: "Feusisberg",
  },
  {
    npa: "8836",
    canton: "SZ",
    location: "Bennau",
  },
  {
    npa: "8846",
    canton: "SZ",
    location: "Willerzell",
  },
  {
    npa: "8847",
    canton: "SZ",
    location: "Egg SZ",
  },
  {
    npa: "8841",
    canton: "SZ",
    location: "Gross",
  },
  {
    npa: "8842",
    canton: "SZ",
    location: "Unteriberg",
  },
  {
    npa: "8843",
    canton: "SZ",
    location: "Oberiberg",
  },
  {
    npa: "8854",
    canton: "SZ",
    location: "Siebnen",
  },
  {
    npa: "8857",
    canton: "SZ",
    location: "Vorderthal",
  },
  {
    npa: "8862",
    canton: "SZ",
    location: "Schübelbach",
  },
  {
    npa: "8863",
    canton: "SZ",
    location: "Buttikon SZ",
  },
  {
    npa: "8864",
    canton: "SZ",
    location: "Reichenburg",
  },
  {
    npa: "8865",
    canton: "GL",
    location: "Bilten",
  },
  {
    npa: "8867",
    canton: "GL",
    location: "Niederurnen",
  },
  {
    npa: "8868",
    canton: "GL",
    location: "Oberurnen",
  },
  {
    npa: "8878",
    canton: "SG",
    location: "Quinten",
  },
  {
    npa: "8885",
    canton: "SG",
    location: "Mols",
  },
  {
    npa: "8887",
    canton: "SG",
    location: "Mels",
  },
  {
    npa: "8886",
    canton: "SG",
    location: "Mädris-Vermol",
  },
  {
    npa: "8892",
    canton: "SG",
    location: "Berschis",
  },
  {
    npa: "8893",
    canton: "SG",
    location: "Flums Hochwiese",
  },
  {
    npa: "8894",
    canton: "SG",
    location: "Flumserberg Saxli",
  },
  {
    npa: "8896",
    canton: "SG",
    location: "Flumserberg Bergheim",
  },
  {
    npa: "8898",
    canton: "SG",
    location: "Flumserberg Tannenbodenalp",
  },
  {
    npa: "8903",
    canton: "ZH",
    location: "Birmensdorf ZH",
  },
  {
    npa: "8904",
    canton: "ZH",
    location: "Aesch ZH",
  },
  {
    npa: "8906",
    canton: "ZH",
    location: "Bonstetten",
  },
  {
    npa: "8907",
    canton: "ZH",
    location: "Wettswil",
  },
  {
    npa: "8909",
    canton: "ZH",
    location: "Zwillikon",
  },
  {
    npa: "8914",
    canton: "ZH",
    location: "Aeugst am Albis",
  },
  {
    npa: "8933",
    canton: "ZH",
    location: "Maschwanden",
  },
  {
    npa: "8934",
    canton: "ZH",
    location: "Knonau",
  },
  {
    npa: "8952",
    canton: "ZH",
    location: "Schlieren",
  },
  {
    npa: "8953",
    canton: "ZH",
    location: "Dietikon",
  },
  {
    npa: "8962",
    canton: "AG",
    location: "Bergdietikon",
  },
  {
    npa: "9000",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9000",
    canton: "SG",
    location: "St. Gallen Kaserne",
  },
  {
    npa: "9001",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9006",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9013",
    canton: "SG",
    location: "St. Gallen Lachen-Vonwil",
  },
  {
    npa: "9030",
    canton: "SG",
    location: "Abtwil SG",
  },
  {
    npa: "9033",
    canton: "SG",
    location: "Untereggen",
  },
  {
    npa: "9035",
    canton: "AR",
    location: "Grub AR",
  },
  {
    npa: "9043",
    canton: "AR",
    location: "Trogen",
  },
  {
    npa: "9044",
    canton: "AR",
    location: "Wald AR",
  },
  {
    npa: "9054",
    canton: "AI",
    location: "Haslen AI",
  },
  {
    npa: "9058",
    canton: "AI",
    location: "Brülisau",
  },
  {
    npa: "9064",
    canton: "AR",
    location: "Hundwil",
  },
  {
    npa: "9100",
    canton: "AR",
    location: "Herisau",
  },
  {
    npa: "9103",
    canton: "AR",
    location: "Schwellbrunn",
  },
  {
    npa: "9104",
    canton: "AR",
    location: "Waldstatt",
  },
  {
    npa: "9107",
    canton: "AR",
    location: "Urnäsch",
  },
  {
    npa: "9108",
    canton: "AI",
    location: "Gonten",
  },
  {
    npa: "9113",
    canton: "SG",
    location: "Degersheim",
  },
  {
    npa: "9126",
    canton: "SG",
    location: "Necker",
  },
  {
    npa: "9200",
    canton: "SG",
    location: "Gossau SG 1",
  },
  {
    npa: "9203",
    canton: "SG",
    location: "Niederwil SG",
  },
  {
    npa: "9205",
    canton: "SG",
    location: "Waldkirch",
  },
  {
    npa: "9213",
    canton: "TG",
    location: "Hauptwil",
  },
  {
    npa: "9220",
    canton: "TG",
    location: "Bischofszell",
  },
  {
    npa: "9223",
    canton: "TG",
    location: "Schweizersholz",
  },
  {
    npa: "9223",
    canton: "TG",
    location: "Halden",
  },
  {
    npa: "9230",
    canton: "SG",
    location: "Flawil 2 Botsberg",
  },
  {
    npa: "9231",
    canton: "SG",
    location: "Egg (Flawil)",
  },
  {
    npa: "9604",
    canton: "SG",
    location: "Lütisburg",
  },
  {
    npa: "9242",
    canton: "SG",
    location: "Oberuzwil",
  },
  {
    npa: "9245",
    canton: "SG",
    location: "Oberbüren",
  },
  {
    npa: "9304",
    canton: "SG",
    location: "Bernhardzell",
  },
  {
    npa: "9305",
    canton: "SG",
    location: "Berg SG",
  },
  {
    npa: "9312",
    canton: "SG",
    location: "Häggenschwil",
  },
  {
    npa: "9313",
    canton: "SG",
    location: "Muolen",
  },
  {
    npa: "9314",
    canton: "TG",
    location: "Steinebrunn",
  },
  {
    npa: "9320",
    canton: "TG",
    location: "Stachen",
  },
  {
    npa: "9322",
    canton: "TG",
    location: "Egnach",
  },
  {
    npa: "9325",
    canton: "TG",
    location: "Roggwil TG",
  },
  {
    npa: "9404",
    canton: "SG",
    location: "Rorschacherberg",
  },
  {
    npa: "9405",
    canton: "AR",
    location: "Wienacht-Tobel",
  },
  {
    npa: "9410",
    canton: "AR",
    location: "Heiden",
  },
  {
    npa: "9411",
    canton: "AR",
    location: "Reute AR",
  },
  {
    npa: "9413",
    canton: "AI",
    location: "Oberegg",
  },
  {
    npa: "9423",
    canton: "SG",
    location: "Altenrhein",
  },
  {
    npa: "9424",
    canton: "SG",
    location: "Rheineck",
  },
  {
    npa: "9425",
    canton: "SG",
    location: "Thal",
  },
  {
    npa: "9428",
    canton: "AR",
    location: "Platz AR",
  },
  {
    npa: "9435",
    canton: "SG",
    location: "Heerbrugg",
  },
  {
    npa: "9444",
    canton: "SG",
    location: "Diepoldsau",
  },
  {
    npa: "9450",
    canton: "SG",
    location: "Altstätten SG",
  },
  {
    npa: "9451",
    canton: "SG",
    location: "Kriessern",
  },
  {
    npa: "9470",
    canton: "SG",
    location: "Buchs SG Räfis",
  },
  {
    npa: "9476",
    canton: "SG",
    location: "Weite",
  },
  {
    npa: "9479",
    canton: "SG",
    location: "Oberschan",
  },
  {
    npa: "9487",
    canton: "FL",
    location: "Gamprin-Bendern",
  },
  {
    npa: "9488",
    canton: "FL",
    location: "Schellenberg",
  },
  {
    npa: "9490",
    canton: "FL",
    location: "Vaduz",
  },
  {
    npa: "9491",
    canton: "FL",
    location: "Ruggell",
  },
  {
    npa: "9497",
    canton: "FL",
    location: "Triesenberg",
  },
  {
    npa: "9500",
    canton: "SG",
    location: "Wil SG",
  },
  {
    npa: "9502",
    canton: "TG",
    location: "Braunau",
  },
  {
    npa: "9507",
    canton: "TG",
    location: "Stettfurt",
  },
  {
    npa: "9526",
    canton: "SG",
    location: "Zuckenriet",
  },
  {
    npa: "9534",
    canton: "SG",
    location: "Gähwil",
  },
  {
    npa: "9536",
    canton: "SG",
    location: "Schwarzenbach SG",
  },
  {
    npa: "9545",
    canton: "TG",
    location: "Wängi",
  },
  {
    npa: "9553",
    canton: "TG",
    location: "Bettwiesen",
  },
  {
    npa: "9555",
    canton: "TG",
    location: "Tobel",
  },
  {
    npa: "9565",
    canton: "TG",
    location: "Schmidshof",
  },
  {
    npa: "9573",
    canton: "TG",
    location: "Littenheid",
  },
  {
    npa: "9602",
    canton: "SG",
    location: "Müselbach",
  },
  {
    npa: "9614",
    canton: "SG",
    location: "Libingen",
  },
  {
    npa: "9615",
    canton: "SG",
    location: "Dietfurt",
  },
  {
    npa: "9621",
    canton: "SG",
    location: "Oberhelfenschwil",
  },
  {
    npa: "9631",
    canton: "SG",
    location: "Ulisbach",
  },
  {
    npa: "9643",
    canton: "SG",
    location: "Krummenau",
  },
  {
    npa: "9651",
    canton: "SG",
    location: "Ennetbühl",
  },
  {
    npa: "9656",
    canton: "SG",
    location: "Alt St. Johann",
  },
  {
    npa: "9658",
    canton: "SG",
    location: "Wildhaus",
  },
  {
    npa: "3185",
    canton: "FR",
    location: "Schmitten FR",
  },
  {
    npa: "6303",
    canton: "ZG",
    location: "Zug",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano Stazione",
  },
  {
    npa: "1734",
    canton: "FR",
    location: "Tentlingen",
  },
  {
    npa: "1029",
    canton: "VD",
    location: "Villars-Ste-Croix",
  },
  {
    npa: "2301",
    canton: "NE",
    location: "La Chaux-de-Fonds 1 Dépôt",
  },
  {
    npa: "1295",
    canton: "VD",
    location: "Mies",
  },
  {
    npa: "1295",
    canton: "VD",
    location: "Tannay",
  },
  {
    npa: "6370",
    canton: "NW",
    location: "Oberdorf NW",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "St-Jean VS",
  },
  {
    npa: "3380",
    canton: "BE",
    location: "Walliswil b. Niederbipp",
  },
  {
    npa: "2715",
    canton: "BE",
    location: "Monible",
  },
  {
    npa: "2827",
    canton: "BE",
    location: "Schelten",
  },
  {
    npa: "2572",
    canton: "BE",
    location: "Mörigen",
  },
  {
    npa: "1773",
    canton: "FR",
    location: "Chandon",
  },
  {
    npa: "1534",
    canton: "FR",
    location: "Chapelle (Broye)",
  },
  {
    npa: "1483",
    canton: "FR",
    location: "Frasses",
  },
  {
    npa: "1470",
    canton: "FR",
    location: "Lully FR",
  },
  {
    npa: "1541",
    canton: "FR",
    location: "Morens FR",
  },
  {
    npa: "1565",
    canton: "FR",
    location: "Vallon",
  },
  {
    npa: "1483",
    canton: "FR",
    location: "Vesin",
  },
  {
    npa: "1675",
    canton: "FR",
    location: "Blessens",
  },
  {
    npa: "1608",
    canton: "FR",
    location: "Chapelle (Glâne)",
  },
  {
    npa: "1670",
    canton: "FR",
    location: "Esmonts",
  },
  {
    npa: "1673",
    canton: "FR",
    location: "Gillarens",
  },
  {
    npa: "1681",
    canton: "FR",
    location: "Hennens",
  },
  {
    npa: "1688",
    canton: "FR",
    location: "Lieffrens",
  },
  {
    npa: "1675",
    canton: "FR",
    location: "Mossel",
  },
  {
    npa: "1686",
    canton: "FR",
    location: "La Neirigue",
  },
  {
    npa: "1694",
    canton: "FR",
    location: "Villargiroud",
  },
  {
    npa: "1669",
    canton: "FR",
    location: "Lessoc",
  },
  {
    npa: "1625",
    canton: "FR",
    location: "Maules",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "La Corbaz",
  },
  {
    npa: "1754",
    canton: "FR",
    location: "Corjolens",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "Cormagens",
  },
  {
    npa: "1727",
    canton: "FR",
    location: "Magnedens",
  },
  {
    npa: "1726",
    canton: "FR",
    location: "Posat",
  },
  {
    npa: "1695",
    canton: "FR",
    location: "Rueyres-St-Laurent",
  },
  {
    npa: "1724",
    canton: "FR",
    location: "Senèdes",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Villarsel-sur-Marly",
  },
  {
    npa: "1624",
    canton: "FR",
    location: "Grattavache",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Chavannes-le-Veyron",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Naz",
  },
  {
    npa: "1421",
    canton: "VD",
    location: "Grandevent",
  },
  {
    npa: "1423",
    canton: "VD",
    location: "Romairon",
  },
  {
    npa: "1134",
    canton: "VD",
    location: "Chigny",
  },
  {
    npa: "1041",
    canton: "VD",
    location: "Montaubion-Chardonney",
  },
  {
    npa: "1513",
    canton: "VD",
    location: "Rossenges",
  },
  {
    npa: "1608",
    canton: "VD",
    location: "Bussigny-sur-Oron",
  },
  {
    npa: "1607",
    canton: "VD",
    location: "Les Thioleyres",
  },
  {
    npa: "1180",
    canton: "VD",
    location: "Tartegnin",
  },
  {
    npa: "1436",
    canton: "VD",
    location: "Chamblon",
  },
  {
    npa: "1432",
    canton: "VD",
    location: "Gressy",
  },
  {
    npa: "1404",
    canton: "VD",
    location: "Villars-Epeney",
  },
  {
    npa: "2933",
    canton: "JU",
    location: "Damphreux",
  },
  {
    npa: "2953",
    canton: "JU",
    location: "Fregiécourt",
  },
  {
    npa: "3429",
    canton: "BE",
    location: "Hellsau",
  },
  {
    npa: "3324",
    canton: "BE",
    location: "Mötschwil",
  },
  {
    npa: "3424",
    canton: "BE",
    location: "Oberösch",
  },
  {
    npa: "3256",
    canton: "BE",
    location: "Bangerten b. Dieterswil",
  },
  {
    npa: "3303",
    canton: "BE",
    location: "Münchringen",
  },
  {
    npa: "3671",
    canton: "BE",
    location: "Herbligen",
  },
  {
    npa: "3274",
    canton: "BE",
    location: "Bühl b. Aarberg",
  },
  {
    npa: "3274",
    canton: "BE",
    location: "Merzligen",
  },
  {
    npa: "3632",
    canton: "BE",
    location: "Niederstocken",
  },
  {
    npa: "3629",
    canton: "BE",
    location: "Jaberg",
  },
  {
    npa: "3116",
    canton: "BE",
    location: "Noflen BE",
  },
  {
    npa: "3128",
    canton: "BE",
    location: "Rümligen",
  },
  {
    npa: "3376",
    canton: "BE",
    location: "Berken",
  },
  {
    npa: "6277",
    canton: "LU",
    location: "Lieli LU",
  },
  {
    npa: "8777",
    canton: "GL",
    location: "Betschwanden",
  },
  {
    npa: "3212",
    canton: "FR",
    location: "Kleingurmels",
  },
  {
    npa: "4584",
    canton: "SO",
    location: "Gächliwil",
  },
  {
    npa: "4566",
    canton: "SO",
    location: "Oekingen",
  },
  {
    npa: "4436",
    canton: "BL",
    location: "Liedertswil",
  },
  {
    npa: "9057",
    canton: "AI",
    location: "Schwende",
  },
  {
    npa: "7130",
    canton: "GR",
    location: "Schnaus",
  },
  {
    npa: "7423",
    canton: "GR",
    location: "Portein",
  },
  {
    npa: "1820",
    canton: "VD",
    location: "Veytaux",
  },
  {
    npa: "8586",
    canton: "TG",
    location: "Engishofen",
  },
  {
    npa: "8553",
    canton: "TG",
    location: "Mettendorf TG",
  },
  {
    npa: "8274",
    canton: "TG",
    location: "Gottlieben",
  },
  {
    npa: "8505",
    canton: "TG",
    location: "Dettighofen",
  },
  {
    npa: "8259",
    canton: "TG",
    location: "Rheinklingen",
  },
  {
    npa: "8572",
    canton: "TG",
    location: "Graltshausen",
  },
  {
    npa: "8585",
    canton: "TG",
    location: "Klarsreuti",
  },
  {
    npa: "8556",
    canton: "TG",
    location: "Engwang",
  },
  {
    npa: "8556",
    canton: "TG",
    location: "Illhart",
  },
  {
    npa: "6963",
    canton: "TI",
    location: "Cureggia",
  },
  {
    npa: "6814",
    canton: "TI",
    location: "Lamone",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Ritzingen",
  },
  {
    npa: "3989",
    canton: "VS",
    location: "Selkingen",
  },
  {
    npa: "3940",
    canton: "VS",
    location: "Steg VS",
  },
  {
    npa: "9498",
    canton: "FL",
    location: "Planken",
  },
  {
    npa: "2933",
    canton: "JU",
    location: "Lugnez",
  },
  {
    npa: "1624",
    canton: "FR",
    location: "Progens",
  },
  {
    npa: "4584",
    canton: "SO",
    location: "Lüterswil",
  },
  {
    npa: "8905",
    canton: "AG",
    location: "Arni AG",
  },
  {
    npa: "8427",
    canton: "ZH",
    location: "Freienstein",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Aminona",
  },
  {
    npa: "5053",
    canton: "AG",
    location: "Wittwil",
  },
  {
    npa: "1754",
    canton: "FR",
    location: "Avry-sur-Matran",
  },
  {
    npa: "2052",
    canton: "NE",
    location: "La Vue-des-Alpes",
  },
  {
    npa: "1709",
    canton: "FR",
    location: "Fribourg Beaumont",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon La Villette",
  },
  {
    npa: "8118",
    canton: "ZH",
    location: "Pfaffhausen",
  },
  {
    npa: "1428",
    canton: "VD",
    location: "Provence",
  },
  {
    npa: "9028",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "9027",
    canton: "SG",
    location: "St. Gallen Mona Versand",
  },
  {
    npa: "6600",
    canton: "TI",
    location: "Solduno",
  },
  {
    npa: "3609",
    canton: "BE",
    location: "Thun",
  },
  {
    npa: "1723",
    canton: "FR",
    location: "Marly 1",
  },
  {
    npa: "1775",
    canton: "FR",
    location: "Grandsivaz",
  },
  {
    npa: "8926",
    canton: "ZH",
    location: "Hauptikon",
  },
  {
    npa: "8926",
    canton: "ZH",
    location: "Uerzlikon",
  },
  {
    npa: "1026",
    canton: "VD",
    location: "Echandens",
  },
  {
    npa: "8050",
    canton: "ZH",
    location: "Zürich Postauto Zürich",
  },
  {
    npa: "5406",
    canton: "AG",
    location: "Rütihof",
  },
  {
    npa: "8106",
    canton: "ZH",
    location: "Adlikon b. Regensdorf",
  },
  {
    npa: "9400",
    canton: "SG",
    location: "Rorschach Zustellung",
  },
  {
    npa: "6343",
    canton: "ZG",
    location: "Holzhäusern ZG",
  },
  {
    npa: "1400",
    canton: "VD",
    location: "Yverdon Distribution",
  },
  {
    npa: "3415",
    canton: "BE",
    location: "Hasle b. Burgdorf",
  },
  {
    npa: "6363",
    canton: "NW",
    location: "Fürigen",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Rigi Scheidegg",
  },
  {
    npa: "8775",
    canton: "GL",
    location: "Luchsingen-Hätzingen",
  },
  {
    npa: "4040",
    canton: "BL",
    location: "Basel",
  },
  {
    npa: "6500",
    canton: "TI",
    location: "Bellinzona centro servici",
  },
  {
    npa: "8522",
    canton: "TG",
    location: "Aawangen",
  },
  {
    npa: "8574",
    canton: "TG",
    location: "Oberhofen TG",
  },
  {
    npa: "6260",
    canton: "LU",
    location: "Hintermoos",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 71 CS CP",
  },
  {
    npa: "6383",
    canton: "NW",
    location: "Wiesenberg",
  },
  {
    npa: "6021",
    canton: "LU",
    location: "Emmenbrücke 1",
  },
  {
    npa: "6281",
    canton: "LU",
    location: "Hochdorf",
  },
  {
    npa: "6011",
    canton: "LU",
    location: "Kriens",
  },
  {
    npa: "6431",
    canton: "SZ",
    location: "Schwyz",
  },
  {
    npa: "8725",
    canton: "SG",
    location: "Gebertingen",
  },
  {
    npa: "1260",
    canton: "VD",
    location: "Nyon 2",
  },
  {
    npa: "9501",
    canton: "SG",
    location: "Wil SG 1",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Sarnen 2",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 84 Votations",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Interlaken Postauto BE Ober",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Postauto Zentralschw",
  },
  {
    npa: "4070",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "6156",
    canton: "LU",
    location: "Luthern Bad",
  },
  {
    npa: "8510",
    canton: "TG",
    location: "Frauenfeld Kant. Verwaltung",
  },
  {
    npa: "6900",
    canton: "TI",
    location: "Lugano 4",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 60 UPD",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg Caserne",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève 26 Caserne",
  },
  {
    npa: "6616",
    canton: "TI",
    location: "Losone Caserma",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Kaserne",
  },
  {
    npa: "3250",
    canton: "BE",
    location: "Lyss Kaserne",
  },
  {
    npa: "8887",
    canton: "SG",
    location: "Mels Kaserne",
  },
  {
    npa: "6802",
    canton: "TI",
    location: "Rivera Caserma",
  },
  {
    npa: "6370",
    canton: "NW",
    location: "Stans Kaserne",
  },
  {
    npa: "6780",
    canton: "TI",
    location: "Airolo Caserma",
  },
  {
    npa: "3000",
    canton: "BE",
    location: "Bern 22 Kaserne",
  },
  {
    npa: "6460",
    canton: "UR",
    location: "Altdorf UR 2 Rynächt",
  },
  {
    npa: "1068",
    canton: "VD",
    location: "Les Monts-de-Pully",
  },
  {
    npa: "8285",
    canton: "TG",
    location: "Kreuzlingen Ifolor AG",
  },
  {
    npa: "4039",
    canton: "BS",
    location: "Basel SSF",
  },
  {
    npa: "3600",
    canton: "BE",
    location: "Thun Dist Ba",
  },
  {
    npa: "1227",
    canton: "GE",
    location: "Carouge GE Distribution",
  },
  {
    npa: "1860",
    canton: "VD",
    location: "Aigle Distribution",
  },
  {
    npa: "1030",
    canton: "VD",
    location: "Bussigny Distribution",
  },
  {
    npa: "1023",
    canton: "VD",
    location: "Crissier Distribution",
  },
  {
    npa: "4142",
    canton: "BL",
    location: "Münchenstein 1 Zustellung",
  },
  {
    npa: "4104",
    canton: "BL",
    location: "Oberwil BL Zustellung",
  },
  {
    npa: "5620",
    canton: "AG",
    location: "Bremgarten AG 1 Zustellung",
  },
  {
    npa: "4310",
    canton: "AG",
    location: "Rheinfelden 1 Zustellung",
  },
  {
    npa: "6440",
    canton: "SZ",
    location: "Brunnen Zustellung",
  },
  {
    npa: "6030",
    canton: "LU",
    location: "Ebikon Zustellung",
  },
  {
    npa: "6390",
    canton: "OW",
    location: "Engelberg Zustellung",
  },
  {
    npa: "6410",
    canton: "SZ",
    location: "Goldau Zustellung",
  },
  {
    npa: "6102",
    canton: "LU",
    location: "Malters Zustellung",
  },
  {
    npa: "3280",
    canton: "FR",
    location: "Murten Zustellung",
  },
  {
    npa: "8134",
    canton: "ZH",
    location: "Adliswil 1 Zustellung",
  },
  {
    npa: "9320",
    canton: "TG",
    location: "Arbon Zustellung",
  },
  {
    npa: "8903",
    canton: "ZH",
    location: "Birmensdorf ZH Zustellung",
  },
  {
    npa: "8307",
    canton: "ZH",
    location: "Effretikon Zustellung",
  },
  {
    npa: "8708",
    canton: "ZH",
    location: "Männedorf Zustellung",
  },
  {
    npa: "8800",
    canton: "ZH",
    location: "Thalwil Zustellung",
  },
  {
    npa: "3360",
    canton: "BE",
    location: "Herzogenbuchsee Zustellung",
  },
  {
    npa: "8636",
    canton: "ZH",
    location: "Wald ZH Zustellung",
  },
  {
    npa: "8570",
    canton: "TG",
    location: "Weinfelden Zustellung",
  },
  {
    npa: "8126",
    canton: "ZH",
    location: "Zumikon Zustellung",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Zustellung",
  },
  {
    npa: "3053",
    canton: "BE",
    location: "Münchenbuchsee Zustellung",
  },
  {
    npa: "3700",
    canton: "BE",
    location: "Spiez Zustellung",
  },
  {
    npa: "3612",
    canton: "BE",
    location: "Steffisburg 1 Zustellung",
  },
  {
    npa: "1212",
    canton: "GE",
    location: "Grand-Lancy 1 Distribution",
  },
  {
    npa: "2400",
    canton: "NE",
    location: "Le Locle Distribution",
  },
  {
    npa: "2740",
    canton: "BE",
    location: "Moutier 1 Distribution",
  },
  {
    npa: "5018",
    canton: "AG",
    location: "Erlinsbach",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle 1 Distribution",
  },
  {
    npa: "7270",
    canton: "GR",
    location: "Davos Zustellung",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf Dist Fil",
  },
  {
    npa: "8325",
    canton: "ZH",
    location: "Effretikon Dist Ba",
  },
  {
    npa: "8520",
    canton: "TG",
    location: "Frauenfeld Paketzentrum",
  },
  {
    npa: "8520",
    canton: "TG",
    location: "Frauenfeld Dist Ba",
  },
  {
    npa: "2950",
    canton: "JU",
    location: "Courtemautruy",
  },
  {
    npa: "1148",
    canton: "VD",
    location: "Villars-Bozon",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle Dist Fil",
  },
  {
    npa: "8087",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "1033",
    canton: "VD",
    location: "Cheseaux-Laus Distribution",
  },
  {
    npa: "3960",
    canton: "VS",
    location: "Niouc",
  },
  {
    npa: "1912",
    canton: "VS",
    location: "Produit (Leytron)",
  },
  {
    npa: "1912",
    canton: "VS",
    location: "Montagnon (Leytron)",
  },
  {
    npa: "1914",
    canton: "VS",
    location: "Auddes-sur-Riddes",
  },
  {
    npa: "1932",
    canton: "VS",
    location: "Les Valettes (Bovernier)",
  },
  {
    npa: "1941",
    canton: "VS",
    location: "Cries (Vollèges)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Fontaine Dessus (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Dranse (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Chandonne (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Rive Haute (Liddes)",
  },
  {
    npa: "1945",
    canton: "VS",
    location: "Petit Vichères (Liddes)",
  },
  {
    npa: "1947",
    canton: "VS",
    location: "Champsec",
  },
  {
    npa: "1955",
    canton: "VS",
    location: "Grugnay (Chamoson)",
  },
  {
    npa: "1966",
    canton: "VS",
    location: "Blignou (Ayent)",
  },
  {
    npa: "1969",
    canton: "VS",
    location: "Liez (St-Martin)",
  },
  {
    npa: "1969",
    canton: "VS",
    location: "Suen (St-Martin)",
  },
  {
    npa: "1991",
    canton: "VS",
    location: "Pravidondaz (Salins)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Brignon (Nendaz)",
  },
  {
    npa: "1992",
    canton: "VS",
    location: "La Vernaz (Les Agettes)",
  },
  {
    npa: "1996",
    canton: "VS",
    location: "Saclentse",
  },
  {
    npa: "1873",
    canton: "VS",
    location: "Champoussin",
  },
  {
    npa: "2063",
    canton: "NE",
    location: "Saules",
  },
  {
    npa: "2610",
    canton: "BE",
    location: "St-Imier Distribution",
  },
  {
    npa: "1680",
    canton: "FR",
    location: "Romont FR Distribution",
  },
  {
    npa: "1880",
    canton: "VD",
    location: "Bex Distribution",
  },
  {
    npa: "1214",
    canton: "GE",
    location: "Vernier Distribution",
  },
  {
    npa: "3210",
    canton: "FR",
    location: "Kerzers Zustellung",
  },
  {
    npa: "3415",
    canton: "BE",
    location: "Hasle-Rüegsau Zustellung",
  },
  {
    npa: "3855",
    canton: "BE",
    location: "Brienz BE Zustellung",
  },
  {
    npa: "4460",
    canton: "BL",
    location: "Gelterkinden Zustellung",
  },
  {
    npa: "4512",
    canton: "SO",
    location: "Bellach Zustellung",
  },
  {
    npa: "4614",
    canton: "SO",
    location: "Hägendorf Zustellung",
  },
  {
    npa: "5070",
    canton: "AG",
    location: "Frick Zustellung",
  },
  {
    npa: "5722",
    canton: "AG",
    location: "Gränichen Zustellung",
  },
  {
    npa: "6060",
    canton: "OW",
    location: "Sarnen 2 Zustellung",
  },
  {
    npa: "6403",
    canton: "SZ",
    location: "Küssnacht am Rigi Zust",
  },
  {
    npa: "8965",
    canton: "AG",
    location: "Berikon 1 Zustellung",
  },
  {
    npa: "8808",
    canton: "SZ",
    location: "Pfäffikon SZ Zustellung",
  },
  {
    npa: "8840",
    canton: "SZ",
    location: "Einsiedeln Zustellung",
  },
  {
    npa: "8854",
    canton: "SZ",
    location: "Siebnen Zustellung",
  },
  {
    npa: "9230",
    canton: "SG",
    location: "Flawil 1 Zustellung",
  },
  {
    npa: "9053",
    canton: "AR",
    location: "Teufen AR Zustellung",
  },
  {
    npa: "9410",
    canton: "AR",
    location: "Heiden Zustellung",
  },
  {
    npa: "9470",
    canton: "SG",
    location: "Buchs SG 1 Zustellung",
  },
  {
    npa: "7503",
    canton: "GR",
    location: "Samedan Zustellung",
  },
  {
    npa: "9024",
    canton: "SG",
    location: "St. Gallen Presse-Serv.Güll",
  },
  {
    npa: "1772",
    canton: "FR",
    location: "Nierlet-les-Bois",
  },
  {
    npa: "1892",
    canton: "VD",
    location: "Morcles",
  },
  {
    npa: "2074",
    canton: "NE",
    location: "Marin-Centre",
  },
  {
    npa: "9479",
    canton: "SG",
    location: "Gretschins",
  },
  {
    npa: "9479",
    canton: "SG",
    location: "Malans SG",
  },
  {
    npa: "4640",
    canton: "SO",
    location: "Härkingen CIRCLE",
  },
  {
    npa: "4630",
    canton: "SO",
    location: "Härkingen CALL",
  },
  {
    npa: "1320",
    canton: "VD",
    location: "Daillens CIRCLE",
  },
  {
    npa: "6010",
    canton: "LU",
    location: "Kriens 1",
  },
  {
    npa: "1200",
    canton: "GE",
    location: "Genève 18",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Hattenhausen",
  },
  {
    npa: "8564",
    canton: "TG",
    location: "Gunterswilen",
  },
  {
    npa: "1806",
    canton: "VD",
    location: "St-Légier/Blonay Dist",
  },
  {
    npa: "1023",
    canton: "VD",
    location: "Crissier 2",
  },
  {
    npa: "1096",
    canton: "VD",
    location: "Villette (Lavaux)",
  },
  {
    npa: "6383",
    canton: "NW",
    location: "Wirzweli",
  },
  {
    npa: "2333",
    canton: "BE",
    location: "La Cibourg",
  },
  {
    npa: "2616",
    canton: "BE",
    location: "La Cibourg",
  },
  {
    npa: "8556",
    canton: "TG",
    location: "Lamperswil TG",
  },
  {
    npa: "2748",
    canton: "BE",
    location: "Les Ecorcheresses",
  },
  {
    npa: "5415",
    canton: "AG",
    location: "Nussbaumen AG Zustellung",
  },
  {
    npa: "3770",
    canton: "BE",
    location: "Zweisimmen Zustellung",
  },
  {
    npa: "1223",
    canton: "GE",
    location: "Cologny Distribution",
  },
  {
    npa: "3454",
    canton: "BE",
    location: "Sumiswald Zustellung",
  },
  {
    npa: "6963",
    canton: "TI",
    location: "Pregassona Distribuzione",
  },
  {
    npa: "3920",
    canton: "VS",
    location: "Zermatt Zustellung",
  },
  {
    npa: "3977",
    canton: "VS",
    location: "Granges VS Distribution",
  },
  {
    npa: "4034",
    canton: "BS",
    location: "Basel 34 Breite",
  },
  {
    npa: "8606",
    canton: "ZH",
    location: "Nänikon Zustellung",
  },
  {
    npa: "9463",
    canton: "SG",
    location: "Oberriet SG Zustellung",
  },
  {
    npa: "8472",
    canton: "ZH",
    location: "Seuzach Zustellung",
  },
  {
    npa: "8596",
    canton: "TG",
    location: "Münsterlingen",
  },
  {
    npa: "8068",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "9604",
    canton: "SG",
    location: "Unterrindal",
  },
  {
    npa: "2013",
    canton: "NE",
    location: "Boudry Dist Ba",
  },
  {
    npa: "3039",
    canton: "BE",
    location: "Bern PF Operations Center",
  },
  {
    npa: "4042",
    canton: "BL",
    location: "Basel PF Operations Center",
  },
  {
    npa: "3983",
    canton: "VS",
    location: "Greich",
  },
  {
    npa: "6043",
    canton: "LU",
    location: "Adligenswil Zustellung",
  },
  {
    npa: "6045",
    canton: "LU",
    location: "Meggen Zustellung",
  },
  {
    npa: "6055",
    canton: "OW",
    location: "Alpnach Zustellung",
  },
  {
    npa: "8081",
    canton: "ZH",
    location: "Zürich Helsana",
  },
  {
    npa: "8450",
    canton: "ZH",
    location: "Andelfingen Zustellung",
  },
  {
    npa: "8722",
    canton: "SG",
    location: "Kaltbrunn Zustellung",
  },
  {
    npa: "3041",
    canton: "BE",
    location: "Bern UBS",
  },
  {
    npa: "8488",
    canton: "ZH",
    location: "Turbenthal Zustellung",
  },
  {
    npa: "7050",
    canton: "GR",
    location: "Arosa Zustellung",
  },
  {
    npa: "1934",
    canton: "VS",
    location: "Le Châble VS Distribution",
  },
  {
    npa: "1071",
    canton: "VD",
    location: "Chexbres Distribution",
  },
  {
    npa: "1854",
    canton: "VD",
    location: "Leysin Distribution",
  },
  {
    npa: "8135",
    canton: "ZH",
    location: "Sihlwald",
  },
  {
    npa: "8152",
    canton: "ZH",
    location: "Glattpark (Opfikon)",
  },
  {
    npa: "5645",
    canton: "AG",
    location: "Fenkrieden",
  },
  {
    npa: "4075",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "8820",
    canton: "ZH",
    location: "Wädenswil Dist Ba",
  },
  {
    npa: "7430",
    canton: "GR",
    location: "Thusis Zustellung",
  },
  {
    npa: "7130",
    canton: "GR",
    location: "Ilanz Zustellung",
  },
  {
    npa: "1296",
    canton: "VD",
    location: "Coppet Distribution",
  },
  {
    npa: "1782",
    canton: "FR",
    location: "Formangueires",
  },
  {
    npa: "3940",
    canton: "VS",
    location: "Steg-Gampel",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens Centre Courrier",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich-Mülligen SPS AG",
  },
  {
    npa: "4702",
    canton: "SO",
    location: "Oensingen Hub SecurePost",
  },
  {
    npa: "1919",
    canton: "VS",
    location: "Martigny Groupe Mutuel",
  },
  {
    npa: "1001",
    canton: "VD",
    location: "Lausanne Sozialberatung",
  },
  {
    npa: "4808",
    canton: "AG",
    location: "Zofingen PostFinance",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen PostFinance",
  },
  {
    npa: "4807",
    canton: "AG",
    location: "Zofingen PostFinance",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich-Mülligen BZI",
  },
  {
    npa: "1311",
    canton: "VD",
    location: "Eclépens Scanning-Center",
  },
  {
    npa: "8060",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "6333",
    canton: "ZG",
    location: "Hünenberg See",
  },
  {
    npa: "4809",
    canton: "AG",
    location: "Zofingen Postfinance UBS",
  },
  {
    npa: "3400",
    canton: "BE",
    location: "Burgdorf PostFinance mob",
  },
  {
    npa: "8059",
    canton: "ZH",
    location: "Zürich 59 Exchange Office",
  },
  {
    npa: "8059",
    canton: "ZH",
    location: "Zürich 59 Ausland",
  },
  {
    npa: "8310",
    canton: "ZH",
    location: "Grafstal",
  },
  {
    npa: "3017",
    canton: "BE",
    location: "Bern Zustellung",
  },
  {
    npa: "8471",
    canton: "ZH",
    location: "Oberwil (Dägerlen)",
  },
  {
    npa: "3029",
    canton: "BE",
    location: "Bern",
  },
  {
    npa: "4621",
    canton: "SO",
    location: "Härkingen BZ Briefklinik",
  },
  {
    npa: "9050",
    canton: "AI",
    location: "Appenzell Meistersrüte",
  },
  {
    npa: "9108",
    canton: "AI",
    location: "Gontenbad",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern PF Fil",
  },
  {
    npa: "9108",
    canton: "AI",
    location: "Jakobsbad",
  },
  {
    npa: "7110",
    canton: "GR",
    location: "Peiden",
  },
  {
    npa: "8012",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens SAB",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich BZI DE",
  },
  {
    npa: "2802",
    canton: "JU",
    location: "Develier PostAuto AG",
  },
  {
    npa: "3206",
    canton: "BE",
    location: "Gammen",
  },
  {
    npa: "2360",
    canton: "JU",
    location: "Le Bémont JU",
  },
  {
    npa: "2362",
    canton: "JU",
    location: "Montfaucon",
  },
  {
    npa: "2363",
    canton: "JU",
    location: "Les Enfers",
  },
  {
    npa: "2364",
    canton: "JU",
    location: "St-Brais",
  },
  {
    npa: "3030",
    canton: "BE",
    location: "Bern KC PK 5",
  },
  {
    npa: "4600",
    canton: "SO",
    location: "Olten Personal",
  },
  {
    npa: "8002",
    canton: "ZH",
    location: "Zürich PF Fil Enge",
  },
  {
    npa: "1001",
    canton: "VD",
    location: "Lausanne PF 331-140",
  },
  {
    npa: "1001",
    canton: "VD",
    location: "Lausanne Arc Jurassien",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich Kanton Annahme",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich CS Annahme",
  },
  {
    npa: "3434",
    canton: "BE",
    location: "Landiswil",
  },
  {
    npa: "8970",
    canton: "ZH",
    location: "Urdorf Exchange",
  },
  {
    npa: "1985",
    canton: "VS",
    location: "La Forclaz VS",
  },
  {
    npa: "1401",
    canton: "VD",
    location: "Yverdon Conseil et vente",
  },
  {
    npa: "5405",
    canton: "AG",
    location: "Baden Täfern Gewerbe",
  },
  {
    npa: "9533",
    canton: "SG",
    location: "Dietschwil",
  },
  {
    npa: "2301",
    canton: "NE",
    location: "La Chx-de-F. Combe-à-l'Ours",
  },
  {
    npa: "8162",
    canton: "ZH",
    location: "Sünikon",
  },
  {
    npa: "8332",
    canton: "ZH",
    location: "Rumlikon",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen KC PK/GK 4",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen KC PK/GK 5",
  },
  {
    npa: "3072",
    canton: "BE",
    location: "Ostermundigen Oberdorf",
  },
  {
    npa: "7212",
    canton: "GR",
    location: "Seewis-Schmitten",
  },
  {
    npa: "9023",
    canton: "SG",
    location: "St. Gallen",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen KC PK 2",
  },
  {
    npa: "5000",
    canton: "AG",
    location: "Aarau Altstadt",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 10",
  },
  {
    npa: "1000",
    canton: "VD",
    location: "Lausanne 18",
  },
  {
    npa: "1800",
    canton: "VD",
    location: "Vevey Orient",
  },
  {
    npa: "6000",
    canton: "LU",
    location: "Luzern Altstadt",
  },
  {
    npa: "8574",
    canton: "TG",
    location: "Lengwil",
  },
  {
    npa: "1953",
    canton: "VS",
    location: "Sion RZ",
  },
  {
    npa: "8030",
    canton: "ZH",
    location: "Zürich",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen Abg. St",
  },
  {
    npa: "1345",
    canton: "VD",
    location: "Le Séchey",
  },
  {
    npa: "1721",
    canton: "FR",
    location: "Cournillens",
  },
  {
    npa: "3961",
    canton: "VS",
    location: "Mission",
  },
  {
    npa: "4604",
    canton: "SO",
    location: "Olten 4 Hammer",
  },
  {
    npa: "3030",
    canton: "BE",
    location: "Bern PL3",
  },
  {
    npa: "5506",
    canton: "AG",
    location: "Mägenwil PL3i",
  },
  {
    npa: "5606",
    canton: "AG",
    location: "Dintikon PL3",
  },
  {
    npa: "2305",
    canton: "NE",
    location: "La Chaux-de-Fonds",
  },
  {
    npa: "5312",
    canton: "AG",
    location: "Döttingen-Klingnau",
  },
  {
    npa: "4028",
    canton: "BS",
    location: "Basel",
  },
  {
    npa: "5313",
    canton: "AG",
    location: "Klingnau Wiesenweg",
  },
  {
    npa: "8953",
    canton: "ZH",
    location: "Dietikon Silbern",
  },
  {
    npa: "6875",
    canton: "TI",
    location: "Campora",
  },
  {
    npa: "6242",
    canton: "LU",
    location: "Wauwil Dorfstrasse",
  },
  {
    npa: "8411",
    canton: "ZH",
    location: "Winterthur Pflanzschulstr",
  },
  {
    npa: "8010",
    canton: "ZH",
    location: "Zürich SPS",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genf SPS",
  },
  {
    npa: "3001",
    canton: "BE",
    location: "Bern SPS",
  },
  {
    npa: "4002",
    canton: "BS",
    location: "Basel SPS",
  },
  {
    npa: "5001",
    canton: "AG",
    location: "Aarau SPS",
  },
  {
    npa: "6501",
    canton: "TI",
    location: "Bellinzona SPS",
  },
  {
    npa: "8620",
    canton: "ZH",
    location: "Wetzikon Kastellstrasse",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan IMPC",
  },
  {
    npa: "9442",
    canton: "SG",
    location: "Berneck Zustellung",
  },
  {
    npa: "6302",
    canton: "ZG",
    location: "Zug",
  },
  {
    npa: "3800",
    canton: "BE",
    location: "Interlaken Bönigstrasse",
  },
  {
    npa: "7500",
    canton: "GR",
    location: "St. Moritz Dorf",
  },
  {
    npa: "9320",
    canton: "TG",
    location: "Arbon Stickereistrasse",
  },
  {
    npa: "6403",
    canton: "SZ",
    location: "Küssnacht a.R. Dist Hub",
  },
  {
    npa: "8620",
    canton: "ZH",
    location: "Wetzikon Poststrasse",
  },
  {
    npa: "8330",
    canton: "ZH",
    location: "Pfäffikon ZH Bahnhofstrasse",
  },
  {
    npa: "8025",
    canton: "ZH",
    location: "Zürich Uraniastrasse",
  },
  {
    npa: "7606",
    canton: "GR",
    location: "Bondo",
  },
  {
    npa: "8015",
    canton: "ZH",
    location: "Zürich 15 Zust",
  },
  {
    npa: "9494",
    canton: "FL",
    location: "Schaan BZ Annahme",
  },
  {
    npa: "8352",
    canton: "ZH",
    location: "Elsau St. Gallerstrasse",
  },
  {
    npa: "5734",
    canton: "AG",
    location: "Reinach AG Postplatz",
  },
  {
    npa: "6030",
    canton: "LU",
    location: "Ebikon Mall of Switzerland",
  },
  {
    npa: "8604",
    canton: "ZH",
    location: "Volketswil Zentrum",
  },
  {
    npa: "1700",
    canton: "FR",
    location: "Fribourg 5 Pérolles",
  },
  {
    npa: "3017",
    canton: "BE",
    location: "Bern Grosskunden",
  },
  {
    npa: "8037",
    canton: "ZH",
    location: "Zürich Scheffelstrasse",
  },
  {
    npa: "1870",
    canton: "VS",
    location: "Monthey Avenue de la gare",
  },
  {
    npa: "4665",
    canton: "AG",
    location: "Oftringen PL ThermoCare",
  },
  {
    npa: "5303",
    canton: "AG",
    location: "Würenlingen Dorfstrasse",
  },
  {
    npa: "5200",
    canton: "AG",
    location: "Brugg AG Bahnhofstrasse",
  },
  {
    npa: "3963",
    canton: "VS",
    location: "Crans-sur-Sierre",
  },
  {
    npa: "3645",
    canton: "BE",
    location: "Gwatt (Thun) Lohnerstrasse",
  },
  {
    npa: "3250",
    canton: "BE",
    location: "Lyss Werkstrasse",
  },
  {
    npa: "6548",
    canton: "GR",
    location: "Sta. Domenica",
  },
  {
    npa: "6591",
    canton: "TI",
    location: "Cadenazzo Spartizione P",
  },
  {
    npa: "4127",
    canton: "BL",
    location: "Birsfelden Hauptstrasse",
  },
  {
    npa: "9500",
    canton: "SG",
    location: "Wil SG Hubstrasse",
  },
  {
    npa: "3600",
    canton: "BE",
    location: "Thun Aarestrasse",
  },
  {
    npa: "1930",
    canton: "VS",
    location: "Vétroz CALL",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle rue du Moulin",
  },
  {
    npa: "3071",
    canton: "BE",
    location: "Ostermundigen Sortierung",
  },
  {
    npa: "3018",
    canton: "BE",
    location: "Bern Freiburgstrasse",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle Gare TPF",
  },
  {
    npa: "4147",
    canton: "BL",
    location: "Aesch BL Hauptstrasse",
  },
  {
    npa: "5600",
    canton: "AG",
    location: "Lenzburg Murackerstrasse",
  },
  {
    npa: "6055",
    canton: "OW",
    location: "Alpnach Dorf Brünigstrasse",
  },
  {
    npa: "6883",
    canton: "TI",
    location: "Novazzano Via P. Bernasconi",
  },
  {
    npa: "1800",
    canton: "VD",
    location: "Vevey Av. du Général-Guisan",
  },
  {
    npa: "1211",
    canton: "GE",
    location: "Genève",
  },
  {
    npa: "1630",
    canton: "FR",
    location: "Bulle Jardins de la Pâla L",
  },
  {
    npa: "8027",
    canton: "ZH",
    location: "Zürich 27 Zustellung",
  },
  {
    npa: "1310",
    canton: "VD",
    location: "Daillens SPS",
  },
  {
    npa: "1920",
    canton: "VS",
    location: "Martigny rue des Avouillons",
  },
  {
    npa: "6593",
    canton: "TI",
    location: "Cadenazzo Fundstelle",
  },
  {
    npa: "8920",
    canton: "ZH",
    location: "Urdorf Paketzentrum",
  },
  {
    npa: "4133",
    canton: "BL",
    location: "Pratteln Sperrgut HUB",
  },
  {
    npa: "9472",
    canton: "SG",
    location: "Grabs Staatsstrasse",
  },
  {
    npa: "4800",
    canton: "AG",
    location: "Zofingen Untere Grabenstr",
  },
  {
    npa: "1110",
    canton: "VD",
    location: "Morges Rue des Charpentiers",
  },
  {
    npa: "6102",
    canton: "LU",
    location: "Malters Luzernstrasse",
  },
  {
    npa: "4415",
    canton: "BL",
    location: "Lausen Grammontstrasse",
  },
  {
    npa: "3015",
    canton: "BE",
    location: "Bern Weltpoststrasse",
  },
  {
    npa: "4665",
    canton: "AG",
    location: "Oftringen Tychboden",
  },
  {
    npa: "3752",
    canton: "BE",
    location: "Wimmis Herrenmattestr",
  },
  {
    npa: "6675",
    canton: "TI",
    location: "Cevio Distribuzione",
  },
  {
    npa: "3902",
    canton: "VS",
    location: "Glis Gliserallee",
  },
  {
    npa: "6003",
    canton: "LU",
    location: "Luzern Hirschengraben",
  },
  {
    npa: "6981",
    canton: "TI",
    location: "Beride di Bedigliora",
  },
  {
    npa: "3322",
    canton: "BE",
    location: "Urtenen-Schönbühl Z-Platz",
  },
  {
    npa: "5103",
    canton: "AG",
    location: "Wildegg Poststrasse",
  },
  {
    npa: "1300",
    canton: "VD",
    location: "Eclépens Revenue Protection",
  },
  {
    npa: "4036",
    canton: "BS",
    location: "Basel CALL",
  },
  {
    npa: "8140",
    canton: "ZH",
    location: "Rümlang CIRCLE",
  },
  {
    npa: "7270",
    canton: "GR",
    location: "Davos Platz Talstrasse",
  },
  {
    npa: "4142",
    canton: "BL",
    location: "Münchenstein Stöckackerstr",
  },
  {
    npa: "4009",
    canton: "BS",
    location: "Basel Ahornstrasse",
  },
  {
    npa: "4503",
    canton: "SO",
    location: "Solothurn Allmendstrasse",
  },
  {
    npa: "5050",
    canton: "AG",
    location: "Buchs AG CALL",
  },
  {
    npa: "4130",
    canton: "BL",
    location: "Pratteln CALL",
  },
  {
    npa: "8870",
    canton: "GL",
    location: "Niederurnen PF",
  },
];
