import React, { Component } from "react";
import { showLoading, hideLoading } from "../../../src/utils/Loaders";
import CandidateRecord from "../Component/CandidateRecord";
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import { showSnakerAlert } from "../../utils/Alerts";
import _ from "lodash";
import Pagination from "react-bootstrap/Pagination";
import { SwissPlz } from "../../utils/SwissData";
import { withTranslation } from 'react-i18next';
import { roleStatus, stringLength } from "../../ENUM/enum"

const invertDirection = {
  ASC: "DESC",
  DESC: "ASC",
};

class Candidate extends Component {
  constructor(props) {
    super(props);
    window.CandidateComponent = this;
  }
  state = {
    count: 0,
    activePage: 1,
    activePageSize: 50,
    id: 0,
    isUpdate: false,
    show: false,
    search: "",
    plzs: [],
    selectedPlz: "",
    searchedTerm: "",
    columnToSort: "",
    sortDirection: "ASC",
    plzsData: [],
    operation: false,
    cand_address: "",
    cand_canton: "",
    cand_city: "",
    cand_description: "",
    cand_drivinglicence: "",
    cand_firstname: "",
    cand_gender: "",
    cand_lastname: "",
    cand_mobile: "",
    cand_nationality: "",
    cand_zipcode: "",
    date_since: "",
    industry: "",
    is_deleted: "",
    job_avamcode: "",
    job_avamlabel: "",
    job_lastjob: "",
    job_wanted: "",
    candidates: [],
    buttonShow: true,
    is_blocked: true,
    countIndex: 0,
    indexx: 0,
    pag: true,
    setAllUsers: [],
    UserData: null,
    isEdit: false,
    isConfrimed: false,
    isDialogOpen: false,
    message: "",
    type: "",
    reason: "",
    confirmIsBlock: false,
    confirmId: null,
    disable: false,
    blockedChecked: false,
    assigneeCheck: false,
    currentUserType: null,
    isVisible: false,
    sortBy:"",
    type:"",
    filterBy:""
  };

  getAssignedUser = (v) => {
    this.state.setAllUsers.users.forEach(e => {
      if (v === e.id) {
        this.setState({ UserData: e });
        // return e.id
      }
    });
  }

  fromPag = (e) => {
    if (e === false) {
      this.setState({ show: false, pag: true });
    }
  }
  inputChange = (event) => {
    const { id, value } = event.target;
    if (id === "search") {
      this.getAllCandidates(
        value,
        this.state.activePage,
        this.state.activePageSize,
        this.state.sortBy,
        this.state.type,
        this.state.filterBy
      );
      this.setState({ searchedTerm: value });
      this.handleClose()
    }

    if (id === "deleteBlock") {
      this.setState({ reason: value });
    }
    if (id === "job_wanted") {
      let isReadOnly = event.target.checked;
      this.setState({ [id]: isReadOnly.toString() });
    } else if (id === "IS_ASSIGNED") {
      if(event.target.checked ===  true && this.state.blockedChecked === true){
        this.setState({ blockedChecked: false});
      }
      let getAssigned = event.target.checked ===  true ? id : ""
      this.setState({ assigneeCheck: event.target.checked , filterBy:getAssigned});
        this.getAllCandidates(
          this.state.searchedTerm,
          this.state.activePage,
          this.state.activePageSize,
          this.state.sortBy,
          this.state.type,
          getAssigned
        );
    }
    else if (id === "IS_BLOCKED") {
      if(event.target.checked ===  true && this.state.assigneeCheck === true){
        this.setState({ assigneeCheck: false});
      }
      let getAssigned = event.target.checked ===  true ? id : ""
      this.setState({ blockedChecked: event.target.checked, filterBy:getAssigned });
        this.getAllCandidates(
          this.state.searchedTerm,
          this.state.activePage,
          this.state.activePageSize,
          this.state.sortBy,
          this.state.type,
          getAssigned
        );
    } else {
      if (id === "job_avamcode") {
        this.setState({ [id]: parseFloat(value) });
      } else {
        this.setState({ [id]: value });
      }
    }
  };

  setPlz = (value) => {
    if (value !== "") {
      let record = value.split("-");
      let plz = record[0];
      let canton = record[1];
      let ort = record[2];

      this.setState({ plz, cand_canton: canton, cand_city: ort, selectedPlz: plz });
    }
  };
  addToMeCandidate = (candidate_id, user_id, user_Name) => {
    let url = baseURL + "/api/lead" + `?id=${candidate_id}`;
    let payload = { user_id, candidate_id, user_Name }
    if (candidate_id) {
      showLoading();
      Axios.post(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            showSnakerAlert(data.success, data.message);
            this.getAllCandidates(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type,
              this.state.filterBy
              )
          }
        })
        .catch((err) => {
          if (err.response.data.success === false) {
            showSnakerAlert(!err.response.data.success, err.response.data.message);
          } else {
            showSnakerAlert(false, "Internal Server Error");
          }
        })
        .finally(() => {
          hideLoading();
        });
    }
  }

  saveCandidate = () => {
    const { cand_address, cand_canton, cand_city, cand_description, cand_drivinglicence,
      cand_firstname, cand_gender, cand_lastname, cand_mobile, cand_nationality, cand_zipcode,
      id, industry, job_avamcode, job_avamlabel, job_lastjob, job_wanted,
    } = this.state;

    let payload = {
      cand_address, cand_canton, cand_city, cand_description, cand_drivinglicence, cand_firstname,
      cand_gender, cand_lastname, cand_mobile, cand_nationality, cand_zipcode, industry,
      job_avamcode, job_avamlabel, job_lastjob, job_wanted
    };

    if (this.state.isUpdate === true) {
      if (id) {
        let url = baseURL + "/api/candidates/" + id;
        showLoading();
        Axios.put(url, payload, httpConfig)
          .then((res) => {
            const { data } = res;
            if (data.success) {
              this.setState({ show: false });
              this.getAllCandidates(
                this.state.searchedTerm,
                this.state.activePage,
                this.state.activePageSize,
                this.state.sortBy,
                this.state.type,
                this.state.filterBy
              );
              this.resetField();
            }
            showSnakerAlert(data.success, data.message);
          })
          .catch((err) => {
            showSnakerAlert(false, "Internal Server Error");
          })
          .finally(() => {
            hideLoading();
          });
      }
    } else {
      let url = baseURL + "/api/candidates";
      showLoading();
      Axios.post(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.setState({ show: false });
            this.getAllCandidates(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy,
              this.state.type,
              this.state.filterBy
            );
            this.resetField();
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
        });
    }
  };

  resetField = () => {
    this.setState({
      cand_address: "",
      cand_canton: "",
      cand_city: "",
      cand_description: "",
      cand_drivinglicence: "",
      cand_firstname: "",
      cand_gender: "Select Gender",
      cand_lastname: "",
      cand_mobile: "",
      cand_nationality: "",
      cand_zipcode: "",
      date_since: "",
      id: "",
      industry: "",
      is_deleted: "",
      job_avamcode: "",
      job_avamlabel: "",
      job_lastjob: "",
      job_wanted: "",
      selectedPlz: "",
      isUpdate: false,
      is_blocked: true,
    });
  };
  handleMoveNext = (index) => {
    this.setState({ pag: false })
    if (this.state.candidates.length === index) {
      const { activePage, searchedTerm, activePageSize, sortBy, type ,filterBy} = this.state;
      if (this.state.candidates.length >= activePageSize - 1) {
        this.getAllCandidates(searchedTerm, activePage + 1, activePageSize, sortBy, type, filterBy)
      }
    } else {
      this.state.candidates.forEach(elementToSend => {
        if (elementToSend.index === index + 1) {
          this.editCandidate(elementToSend)
        }
      });
    }
  }
  handleMoveBack = (index) => {
    this.setState({ pag: false })
    if (index === 1) {
      const { activePage, searchedTerm, activePageSize, sortBy, type,filterBy} = this.state;
      if (activePage !== 1) {
        this.getAllCandidates(
          searchedTerm,
          activePage - 1,
          activePageSize,
          sortBy,
          type,
          filterBy
        )
      }
    } else {
      this.state.candidates.forEach(elementToSend => {
        if (elementToSend.index === index - 1) {
          this.editCandidate(elementToSend)
        }
      });
    }
  }
  editCandidate = (candidate) => {
    let {
      cand_address,
      cand_canton,
      cand_city,
      cand_description,
      cand_drivinglicence,
      cand_firstname,
      cand_gender,
      cand_lastname,
      cand_mobile,
      cand_nationality,
      cand_zipcode,
      date_since,
      id,
      industry,
      is_deleted,
      job_avamcode,
      job_avamlabel,
      job_lastjob,
      job_wanted,
      isUpdate,
      selectedPlz,
      indexx,
      isEdit
    } = this.state;

    // Mapping
    cand_firstname = candidate.cand_firstname
    cand_lastname = candidate.cand_lastname
    cand_address = candidate.cand_address
    selectedPlz = candidate.cand_zipcode
    cand_canton = candidate.cand_canton
    cand_description = candidate.cand_description
    job_avamcode = candidate.job_avamcode
    cand_city = candidate.cand_city
    cand_drivinglicence = candidate.cand_drivinglicence
    cand_gender = candidate.cand_gender
    cand_mobile = candidate.cand_mobile
    cand_nationality = candidate.cand_nationality
    date_since = candidate.date_since
    id = candidate.id
    industry = candidate.industry
    is_deleted = candidate.is_deleted
    job_avamlabel = candidate.job_avamlabel
    job_lastjob = candidate.job_lastjob
    job_wanted = candidate.job_wanted
    isUpdate = true
    indexx = candidate.index
    isEdit = true
    this.setState({
      id,
      cand_address,
      cand_canton,
      cand_city,
      cand_description,
      cand_drivinglicence,
      cand_firstname,
      cand_gender,
      cand_lastname,
      cand_mobile,
      cand_nationality,
      cand_zipcode,
      date_since,
      industry,
      is_deleted,
      job_avamcode,
      job_avamlabel,
      job_lastjob,
      job_wanted,
      selectedPlz,
      isUpdate,
      show: true,
      buttonShow: false,
      next: true,
      indexx,
      isEdit
    });
  };
  blockCandidate = (id, isBlocked) => {
    var getMessage = ""
    if (isBlocked === true) {
      getMessage = "block"
    } else {
      getMessage = "unblock"
    }
    this.setState({ isDialogOpen: true, message: getMessage, type: "candidate", confirmId: id, confirmIsBlock: isBlocked });
  };
  handlePopUp = () => {
    this.setState({ reason: "", isDialogOpen: false, message: "" });
  }
  confirm = (id, isBlocked) => {
    if (this.state.message === "block" || this.state.message === "unblock") {
      this.confirmBlockCandidate(id, isBlocked)
    }
  };

  confirmBlockCandidate = (id, isBlocked) => {
    if (this.state.reason !== "") {
      const { reason } =
        this.state;

      let payload = {
        reason
      };
      showLoading();
      const url =
        baseURL +
        "/api/candidates/changeActiveStatus/" +
        id +
        `?isBlocked=${isBlocked}`;

      Axios.patch(url, payload, httpConfig)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            this.getAllCandidates(
              this.state.searchedTerm,
              this.state.activePage,
              this.state.activePageSize,
              this.state.sortBy, 
              this.state.type,
              this.state.filterBy
            );
          }
          showSnakerAlert(data.success, data.message);
        })
        .catch((err) => {
          showSnakerAlert(false, "Internal Server Error");
        })
        .finally(() => {
          hideLoading();
          this.handlePopUp()
        });
    } else {
      showSnakerAlert(false, "Please enter the reason");
    }
  }
  componentDidMount() {
    let isLogedin = !!localStorage.getItem("token");
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser);
    if (!isLogedin) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (isUser.roleId !== roleStatus.Admin) {
      this.setState({ disable: true });
    }
    this.setState({ plzsData: SwissPlz, currentUserType: isUser.roleId });
    this.getAllCandidates(
      this.state.searchedTerm,
      this.state.activePage,
      this.state.activePageSize,
      this.state.sortBy, 
      this.state.type,
      this.state.filterBy
    );
    this.getAllUsers()
  }
  getAllUsers(term = "") {
    Axios.get(baseURL + `/api/users?term=` + term, httpConfig)
      .then((res) => {
        // const { setAllUsers } = ;
        this.setState({ setAllUsers: res.data });
      })
  }

  getAllCandidates = (term = "", pageNo = 1, pageSize = 50, sortBy, type , filterBy="") => {
    this.setState({ activePage: pageNo, activePageSize: pageSize, sortBy : sortBy, type: type , filterBy: filterBy});
    showLoading();
    Axios.get(
      baseURL +
      "/api/candidates?term=" +
      term +
      "&pageNo=" +
      pageNo +
      "&pageSize=" +
      pageSize +
      "&sortBy=" +
      sortBy +
      "&type="+
      type+
      "&filterBy="+
      filterBy,
      httpConfig
    )
      .then((res) => {
        this.state.countIndex = 0
        var filteredData = [];
        res.data.candidates.forEach(element => {
          if(this.state.currentUserType === roleStatus.User){
            if( element.is_blocked !== true) {
              this.state.countIndex = this.state.countIndex + 1
              element["index"] = this.state.countIndex
              filteredData.push(element)
            }
          }else{
            this.state.countIndex = this.state.countIndex + 1
            element["index"] = this.state.countIndex
            filteredData.push(element)
          }
        });
        res.data.candidates = []
        res.data.candidates = filteredData
        const { candidates, count } = res.data;
        
        if (candidates?.length > 0) {
          this.setState({ isVisible: true });
        } else {
          this.setState({ isVisible: false });
        }
        this.setState({ candidates, count });
        if (this.state.pag === false) {
          if (this.state.indexx === this.state.countIndex) {
            this.state.indexx = 0
            this.handleMoveNext(this.state.indexx)
          } else if (this.state.indexx === 1) {
            this.state.indexx = this.state.countIndex + 1
            this.handleMoveBack(this.state.indexx)
          }
        }
      })
      .catch((err) => {
        showSnakerAlert(false, "Internal Server Error");
      })
      .finally(hideLoading());
  };

  getPLZDetail = (plz) => {
    const { plzsData } = this.state;
    if (plz.indexOf("-") < 0) {
      this.setState({ selectedPlz: plz });
    }
    if (plz !== 0) {
      const data = plzsData.filter((d) => String(d.npa).includes(String(plz)));
      const plzs = [];
      for (let item of data) {
        plzs.push(`${item.npa}-${item.canton}-${item.location}`);
      }
      this.setState({ plzs });
    }
  };

  handleClose = () => {
    this.setState({ show: false, isUpdate: false, pag: true, isEdit: false });
    this.resetField();
  };

  handleShow = () => this.setState({ show: true, buttonShow: true });

  handleSort = (columnName) => {
    let sortDirection =
      this.state.columnToSort === columnName
        ? invertDirection[this.state.sortDirection]
        : "ASC";

    // const candidates = _.orderBy(
    //   this.state.candidates,
    //   [`${columnName}`],
    //   [`${sortDirection}`]
    // );

    this.setState({ columnToSort: columnName, sortDirection, sortBy:columnName, type:sortDirection });
    this.getAllCandidates("", this.state.activePage,  this.state.activePageSize, columnName, sortDirection , this.state.filterBy);
  };

  pagination = (c, m) => {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  onPageSizeChange = (e) => {
    const activePageSize = e.target.value;
    const { searchedTerm, sortBy, type, filterBy} = this.state;
    this.getAllCandidates(searchedTerm, 1, activePageSize, sortBy, type, filterBy);
    this.setState({ activePageSize, activePage: 1 });
  };

  render() {
    const { activePage, searchedTerm, count, activePageSize, sortBy, type, filterBy} = this.state;
    const pages = Math.ceil(count / this.state.activePageSize);
    const paginationArray = this.pagination(activePage, pages);
    let items = [];
    let countj = 0;

    for (const number of paginationArray) {
      countj++;
      if (typeof number === "number") {
        items.push(
          <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() =>
              this.getAllCandidates(searchedTerm, number, activePageSize, sortBy, type , filterBy)
            }
          >
            {number}
          </Pagination.Item>
        );
      } else {
        items.push(<Pagination.Ellipsis key={"Ellipsis" + countj} />);
      }
    }

    return (
      <div className="mt-10">
        <CandidateRecord
          handleSort={this.handleSort}
          handleClose={this.handleClose}
          handleShow={this.handleShow}
          state={this.state}
          candidates={this.state.candidates}
          inputChange={this.inputChange}
          saveCandidate={this.saveCandidate}
          editCandidate={this.editCandidate}
          blockCandidate={this.blockCandidate}
          plzs={this.state.plzs}
          setPlz={this.setPlz}
          getPLZDetail={this.getPLZDetail}
          selectedPlz={this.state.selectedPlz}
          UserRole={roleStatus}
          handleMoveBack={this.handleMoveBack}
          handleMoveNext={this.handleMoveNext}
          addToMeCandidate={this.addToMeCandidate}
          getAssignedUser={this.getAssignedUser}
          handlePopUp={this.handlePopUp}
          confirm={this.confirm}
        />
        <br />
        <div className="d-flex justify-content-between">
            <>
              <Pagination>
                {activePage !== 1 && (
                  <>
                    <Pagination.First
                      onClick={() => {
                        this.fromPag(false);
                        this.getAllCandidates(searchedTerm, 1, activePageSize, sortBy, type , filterBy)
                      }
                      }
                    />
                    <Pagination.Prev
                      onClick={() => {
                        this.fromPag(false);
                        this.getAllCandidates(searchedTerm, activePage - 1, activePageSize, sortBy, type, filterBy)
                      }
                      }
                    />
                  </>
                )}
                {items}
                {activePage !== pages && (
                  <>
                    <Pagination.Next
                      onClick={() => {
                        this.fromPag(false);
                        this.getAllCandidates(searchedTerm, activePage + 1, activePageSize, sortBy, type, filterBy)
                      }
                      }
                    />
                    <Pagination.Last
                      onClick={() => {
                        this.fromPag(false);
                        this.getAllCandidates(searchedTerm, pages, activePageSize, sortBy, type, filterBy)
                      }
                      }
                    />
                  </>
                )}
              </Pagination>
              <select
                className="form-control"
                style={{ width: "100px" }}
                onChange={(e) => {
                  this.fromPag(false);
                  this.onPageSizeChange(e)
                }
                }
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select></>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Candidate);
