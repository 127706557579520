import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BlockIcon from "@material-ui/icons/Block";
import ReStoreIcon from "@material-ui/icons/Restore";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {faSortUp ,faSortDown, faSort} from "@fortawesome/free-solid-svg-icons";
import { faUserCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Drawer from 'react-modern-drawer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Combobox from "react-widgets/Combobox";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next'
import Dialog from "react-dialog";
import "../../utils/DeleteBlockcss.css";
import { stringLength } from "../../ENUM/enum";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CandidateRecord(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const {
    candidates,
    inputChange,
    saveCandidate,
    handleClose,
    handleShow,
    state,
    editCandidate,
    blockCandidate,
    plzs,
    setPlz,
    getPLZDetail,
    selectedPlz,
    handleSort,
    UserRole,
    handleMoveBack,
    handleMoveNext,
    addToMeCandidate,
    handlePopUp,
    confirm
  } = props;

  let isLogedin = !!localStorage.getItem("token");
  let getIsUser = localStorage.getItem("isUser");
  let isUser = JSON.parse(getIsUser);


  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between">
          <input
            className="form-control mt-1"
            placeholder={ (i18n.t('candidate.candidateSearch'))} 
            id="search"
            style={ { width: "300px" }}
            onChange={(e) => inputChange(e)}
            value={state.search}
          />
          {
            isUser.roleId !== UserRole.User ?
              <label className="mt-3 ml-1 fontWeight">{i18n.t('candidate.Blocked')}</label> : ""}
          {
            isUser.roleId !== UserRole.User ?
              <input
                type="checkbox"
                className="mt-2 mx-2 checkSize"
                id="IS_BLOCKED"
                onChange={(e) => inputChange(e)}
                checked={state.blockedChecked}
              /> : ""}
          <label className="mt-3 ml-1 fontWeight">{i18n.t('candidate.Assigned')}</label>
          <input
            type="checkbox"
            className="mt-2 mx-2 checkSize"
            id="IS_ASSIGNED"
            onChange={(e) => inputChange(e)}
            checked={state.assigneeCheck}
          />
        </div>
        {(
          <>
            <Button
              onClick={() => handleShow()}
              variant="contained"
              className={[classes.button, "Button-bg-Color"]}
              startIcon={<AddIcon />}
            >
              {i18n.t('candidate.AddCandidate')}
            </Button>
          </>
        )}
      </div>
      {<Drawer open={state.show} size={400} className="drawer" direction='right' enableOverlay={false}>
        <div className="ml-1 d-flex justify-content-between mt-5 divHeight" >
          {state.isEdit === false ?
            <h5 className="mt-4 ml-2" >{i18n.t('candidate.CreateCandidate')}</h5>
            :
            <h5 className="mt-4 ml-2" >{i18n.t('candidate.UpdateCandidate')}</h5>
          }
          <FontAwesomeIcon
            icon={faTimes}
            className="mr-3 mt-4 pt-1 awesomeIconSize"
            onClick={() => handleClose()}
          />
        </div>
        <hr className="hrStyle" />
        {state.buttonShow === true ?
          null
          :
          <div className="row mx-1">
            <div className="col-sm-12 ml-1 d-flex justify-content-between">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mt-0 awesomeIconSizeRight"
                onClick={() => handleMoveBack(state.indexx)}
                disabled={state.disable}
              />
              <FontAwesomeIcon
                icon={faArrowRight}
                className="mt-0 awesomeIconSizeRight"
                onClick={() => handleMoveNext(state.indexx)}
                disabled={state.disable}
              />
            </div>
          </div>
        }
        <div className="row mx-1">
          <div className="col-sm-6">
            {
              state.isEdit === true ?
                <label className={"mb-0"}>{i18n.t('candidate.firstName')} </label> : ""
            }

            <input
              type="text"
              className={state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.firstName')}
              id="cand_firstname"
              onChange={(e) => inputChange(e)}
              value={state.cand_firstname}
              disabled={state.disable}
            />
          </div>
          <div className="col-sm-6">
            {
              state.isEdit === true ?
                <label className={"mb-0"}>{i18n.t('candidate.LastName')} </label> : ""
            }
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.LastName')}
              id="cand_lastname"
              onChange={(e) => inputChange(e)}
              value={state.cand_lastname}
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.address')} </label> : ""
           }
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.address')}
              id="cand_address"
              onChange={(e) => inputChange(e)}
              value={state.cand_address}
              autoComplete="new-password"
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-6">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('Company.searchZipcode')} </label> : ""
           }
            <Combobox
              style={{ marginTop: "5px" }}
              hideCaret
              hideEmptyPopup
              data={plzs}
              defaultValue={state.plz}
              value={selectedPlz}
              placeholder={i18n.t('Company.searchZipcode')}
              onSelect={(value) => setPlz(value)}
              onChange={(value) => getPLZDetail(value)}
              disabled={state.disable}
            />
          </div>
          <div className="col-sm-3 pr-0 pl-0">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.city')} </label> : ""
           }
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.city')}
              id="cand_city"
              onChange={(e) => inputChange(e)}
              value={state.cand_city}
              autoComplete="new-password"
              readOnly
              disabled={state.disable}
            />
          </div>
          <div className="col-sm-3 pl-1">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('Company.i18n_district')} </label> : ""
           }
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('Company.i18n_district')}
              id="cand_canton"
              onChange={(e) => inputChange(e)}
              value={state.cand_canton}
              autoComplete="new-password"
              readOnly
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.description')} </label> : ""
           }
            <textarea
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.description')}
              id="cand_description"
              onChange={(e) => inputChange(e)}
              value={state.cand_description}
              disabled={state.disable}
              rows = "4"
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>AVAM code </label> : ""
           } 
            <input
              type="number"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder="AVAM code"
              id="job_avamcode"
              onChange={(e) => inputChange(e)}
              value={state.job_avamcode}
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.drivinglicence')} </label> : ""
           }
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.drivinglicence')}
              id="cand_drivinglicence"
              onChange={(e) => inputChange(e)}
              value={state.cand_drivinglicence}
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-6">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.selectGender')} </label> : ""
           }
            <select className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              onChange={(e) => inputChange(e)}
              value={state.cand_gender} id="cand_gender"
              disabled={state.disable}>
              <option id="cand_gender" value="">{i18n.t('candidate.selectGender')}</option>
              <option id="cand_gender" value="male">male</option>
              <option id="cand_gender" value="female">female</option>
            </select>
          </div>
          <div className="col-sm-6">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>Mobile No.</label> : ""
           }
            <input
              type="number"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder='Mobile No.'
              id="cand_mobile"
              onChange={(e) => inputChange(e)}
              value={state.cand_mobile}
              disabled={state.disable}
            />
          </div>
        </div>

        <div className="row mx-1">
          <div className="col-sm-6">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.nationality')} </label> : ""
           } 
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.nationality')}
              id="cand_nationality"
              onChange={(e) => inputChange(e)}
              value={state.cand_nationality}
              disabled={state.disable}
            />
          </div>
          <div className="col-sm-6">
          {
            state.isEdit === true ? 
                 <label className={"mb-0"}>{ i18n.t('candidate.industry')} </label> : ""
           } 
            <input
              type="text"
              className= {state.isEdit === false ? "form-control mt-1" : "form-control "}
              placeholder={i18n.t('candidate.industry')}
              id="industry"
              onChange={(e) => inputChange(e)}
              value={state.industry}
              disabled={state.disable}
            />
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-sm-12">
            <label >{"Wanted"}</label>
            <input
              type="checkbox"
              className="mt-2 mx-2"
              id="job_wanted"
              onChange={(e) => inputChange(e)}
              checked={state.job_wanted}
              disabled={state.disable}
            />
          </div>
        </div>
        {
          isUser.roleId === UserRole.Admin ?
            <div className="row mx-1">
              <div className="col-sm-12 ml-1 d-flex  justify-content-end mb-1">
                <Button
                  className={[classes.button, "Button-bg-Color"]}
                  onClick={() => saveCandidate()}
                >
                  {i18n.t('Company.save')}
                </Button>
              </div>
            </div> : ""
        }
      </Drawer>
      }

      {
        state.isDialogOpen === true ?
          <Dialog
            modal={true}
            buttons={
              [
                {
                  text: "yes",
                  className: "firstbutton",
                  onClick: () => confirm(state.confirmId, state.confirmIsBlock)
                },
                {
                  text: "Close",
                  onClick: () => handlePopUp()
                },
              ]
            }>

            <div className="TextDiv">
              <p className="textContainer">{'Why do you want to ' + state.message + ' this ' + state.type + ' ?'}</p>
            </div>
            <div>
              <input
                className="form-control mt-1"
                id="deleteBlock"
                placeholder={i18n.t('candidate.reason')}
                onChange={(e) => inputChange(e)}
                value={state.reason}
              />
            </div>
          </Dialog>
          :
          null
      }
    { state.isVisible ? 
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
          {candidates.length !== stringLength.tableViewCond ?
            <TableRow>
              <TableCell onClick={() => handleSort("FIRST_NAME")}>
                {i18n.t('candidate.firstName')}
                <FontAwesomeIcon icon={state.columnToSort ==="FIRST_NAME" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1" />
              </TableCell>
              <TableCell onClick={() => handleSort("LAST_NAME")}>
                {i18n.t('candidate.LastName')}
                <FontAwesomeIcon icon={state.columnToSort ==="LAST_NAME" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              <TableCell onClick={() => handleSort("NATIONALITY")}>
                {i18n.t('candidate.Nationality')}
                <FontAwesomeIcon icon={state.columnToSort ==="NATIONALITY" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              <TableCell onClick={() => handleSort("CANTON")}>
                {i18n.t('Company.i18n_district')}
                <FontAwesomeIcon icon={state.columnToSort ==="CANTON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              <TableCell onClick={() => handleSort("INDUSTRY")}>
                {i18n.t('candidate.Industry')}
                <FontAwesomeIcon icon={state.columnToSort ==="INDUSTRY" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              <TableCell onClick={() => handleSort("CREATED_ON")}>
                {i18n.t('Company.i18n_created_at')}
                <FontAwesomeIcon icon={state.columnToSort ==="CREATED_ON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              <TableCell onClick={() => handleSort("DATE_SINCE")}>
                {i18n.t('candidate.DateSince')}
                <FontAwesomeIcon icon={state.columnToSort ==="DATE_SINCE" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
              </TableCell>
              {isUser.roleId !== UserRole.User && <TableCell>{i18n.t('Company.i18n_action')}</TableCell>}
            </TableRow> : null }
          </TableHead>
          <TableBody>
            {candidates
              ? candidates.map((row) => (
                <TableRow
                  key={row.id}
                  className={`${row.is_assigned? row.is_blocked ? "blockedrow" : "assignedrow" : row.is_blocked ? "blockedrow" : " "}`}
                >
                  <TableCell component="th" scope="row">
                    {row.cand_firstname}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cand_lastname}
                  </TableCell>
                  <TableCell>{row.cand_nationality}</TableCell>
                  <TableCell>{row.cand_canton}</TableCell>
                  <TableCell>{row.industry}</TableCell>
                  <TableCell>
                    {moment(row.updated_on).format("yyyy-MM-DD")}
                  </TableCell>
                  <TableCell>
                    {moment(row.date_since).format("yyyy-MM-DD")}
                  </TableCell>
                  {!isLogedin && (
                    <TableCell>
                      {row.is_blocked ? "blockiert" : "aktiv"}
                    </TableCell>
                  )}
                  {(
                    <TableCell>
                      {row.is_blocked ? 
                        <Button 
                          className={[classes.button, "Button-edit-bg-Color", "mr-2"]}
                          style={{ opacity: 0 }}
                          disabled>
                        <EditIcon />
                        </Button> :
                        <Button
                          title={"Edit " + row.cand_firstname}
                          className={[classes.button, "Button-edit-bg-Color", "mr-2"]}
                          onClick={() => editCandidate(row)}
                        >
                          { isUser.roleId === UserRole.Admin ?<EditIcon /> : <VisibilityIcon />}
                        </Button>
                      }
                      <Button
                        title={
                          `${!row.is_blocked ? "Block" : "UnBlock"}` + " " + row.cand_firstname}
                        className={row.is_blocked ? [classes.button, "Button-bg-Color"] : [classes.button, "Button-delete-bg-Color"]}
                        onClick={() => blockCandidate(row.id, !row.is_blocked)}>
                        {row.is_blocked ? <ReStoreIcon /> : <BlockIcon />}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      {row.is_blocked === true && row.is_assigned === true ?
                        <Button
                          title={row.is_assigned === true ? i18n.t('candidate.assignTo') + row.assigned_to : i18n.t('candidate.addToMe')}
                          className={[classes.button, "Button-bg-assign-Color"]}
                        >
                          <FontAwesomeIcon className="addToMesize" icon={row.is_assigned === true ? faUserCheck : faUserPlus} />
                        </Button>
                        :
                        null
                      }
                      {row.is_blocked === false ?
                        <Button
                          title={row.is_assigned === true ? i18n.t('candidate.assignTo') + row.assigned_to : i18n.t('candidate.addToMe')}
                          className={row.is_assigned === true ? "is_assigned_button" : [classes.button, "Button-bg-Color"]}
                          onClick={() => addToMeCandidate(row.id, isUser.id)}
                        >
                          <FontAwesomeIcon className="addToMesize" icon={row.is_assigned === true ? faUserCheck : faUserPlus} />
                        </Button>
                        : null
                      }
                      {row.is_blocked === false && row.is_assigned === true ?
                        <Button
                          title={row.is_assigned === true ? i18n.t('candidate.assignTo') + row.assigned_to : i18n.t('candidate.addToMe')}
                          className={[classes.button, "Button-bg-assign-Color"]}
                          onClick={() => addToMeCandidate(row.id, isUser.id)}
                        >
                          <FontAwesomeIcon className="addToMesize" icon={row.is_assigned === true ? faUserCheck : faUserPlus} />
                        </Button>
                        : null
                      }

                    </TableCell>
                    )}
                </TableRow>
              ))
              : []}
          </TableBody>
        </Table>
      </TableContainer>: null }
    </div>
  );
}
