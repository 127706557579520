import React, { Component, Fragment } from "react";
import SignIn from "../Component/Sign-in";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { baseURL } from "../../utils/Constants";
import {
  showLoading,
  hideLoading,
  LoadingSpinner,
} from "../../../src/utils/Loaders";


class Login extends Component {
  state = {
    username: "",
    password: "",
    errorMessage: "",
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loginSumit();
    }
  }

  inputChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value });
  };

  loginSumit = () => {
    console.log(baseURL);
    console.log(process.env);
    const { username, password } = this.state;
    showLoading();
    axios
      .post(baseURL + "/api/account/login", {
        username,
        password,
      })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          const { accessToken, isManager, isAdmin, isReadOnly, user, settings } = data;
          const langOptions = {
            en: { value: 'en', label: 'English' },
            ger: { value: 'ger', label: 'German' }
          }
          let users = JSON.stringify(user)
          localStorage.setItem("token", accessToken);
          localStorage.setItem("isManager", isManager);
          localStorage.setItem("isAdmin", isAdmin);
          localStorage.setItem("isReadOnly", isReadOnly);
          localStorage.setItem("isUser", users);
          localStorage.setItem("Language" , JSON.stringify(langOptions[settings?.language]) ?? JSON.stringify(langOptions.en));
          window.location.href = "/company";
        } else {
          this.setState({
            errorMessage: data.message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          errorMessage: "Internal Server Error",
        });
      })
      .finally(() => {
        hideLoading();
      });
  };

  render() {
    return (
      <Fragment>
        <SignIn
          state={this.state}
          inputChange={this.inputChange}
          loginSumit={this.loginSumit}
          errorMessage={this.state.errorMessage}
          _handleKeyDown={this._handleKeyDown}
        />
        <LoadingSpinner />
      </Fragment>
    );
  }
}

export default withRouter(Login);
