import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from "moment";
import Drawer from 'react-modern-drawer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import { faSort, faSortDown, faSortUp, faStore } from "@fortawesome/free-solid-svg-icons";
import { faStoreSlash } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-modern-calendar-datepicker";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Combobox from "react-widgets/Combobox";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Dialog from "react-dialog";
import "../../utils/DeleteBlockcss.css";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function MyLeadsRecord(props) {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const {
        myLeads,
        inputChange,
        state,
        handleSort,
        UserRole,
        toggleDrawer,
        goBack,
        setDate,
        renderCustomInput,
        submitForm,
        deleteAssignee,
        seeAssignee,
        handleMoveNext,
        handleMoveBack,
        plzs,
        selectedPlz,
        confirm,
        handlePopUp
    } = props;

    let isLogedin = !!localStorage.getItem("token");
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser)

    return (
        <div>
            <div className="d-flex justify-content-between">
                <input
                    className="form-control mt-1"
                    placeholder={i18n.t('candidate.candidateSearch')}
                    id="search"
                    style={{ width: "300px" }}
                    onChange={(e) => inputChange(e)}
                    value={state.search}
                />
            </div>
            <Drawer open={state.openDrawer} size={300} className="drawer" direction='right' enableOverlay={false}>
                <div className="ml-1 d-flex justify-content-between mt-5 divHeight" >
                    <h5 className="mt-4 ml-2">{i18n.t("lowercase." + state.headerData)}</h5>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="mr-3 mt-4 awesomeIconSize"
                        onClick={() => goBack(state.intrestField)}
                    />
                </div>
                <hr className="hrStyle" />
                {state.withdatePicer ?
                    <DatePicker 
                    selected= {state.selectedDay}
                    shouldHighlightWeekends 
                    onChange={(e) => setDate(e)} 
                    renderInput={renderCustomInput}
                    />
                    : []
                }
                <div className="row d-flex justify-content-end mx-2 mt-2" style={{ margin: "8px"}}>
                    <Button
                        onClick={() => submitForm(state.intrestField, state.rowdata )}
                        className={[useStyles.button, 'Button-bg-Color']}
                    >
                        {i18n.t('user.submit')}
                    </Button>
                </div>
            </Drawer>
            {<Drawer open={state.show} size={400} className="drawer" direction='right' enableOverlay={false}>
                <div className="ml-1 d-flex justify-content-between mt-5 divHeight" >
                    <h5 className="mt-4 ml-2" >{i18n.t('Profile.Candidate')}</h5>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="mr-3 mt-4 pt-1 awesomeIconSize"
                        onClick={() => goBack()}
                    />
                </div>
                <hr className="hrStyle" />
                <div className="row mx-1">
                    <div className="col-sm-12 ml-1 d-flex justify-content-between">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="mt-0 awesomeIconSizeRight"
                            onClick={() => handleMoveBack(state.indexx)}
                        />
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            className="mt-0 awesomeIconSizeRight"
                            onClick={() => handleMoveNext(state.indexx)}
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.firstName')} </label>
                        <input
                            type="text"
                            className="form-control "
                            placeholder={i18n.t('candidate.firstName')}
                            id="cand_firstname"
                            disabled={state.disable}
                            // onChange={(e) => inputChange(e)}
                            value={state.cand_firstname}
                        />
                    </div>
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.LastName')} </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={i18n.t('candidate.LastName')}
                            id="cand_lastname"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_lastname}
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-12">
                    <label className={"mb-0"}>{ i18n.t('Company.street&number')} </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={i18n.t('Company.street&number')}
                            id="cand_address"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_address}
                            autoComplete="new-password"
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.ZipCode')} </label>
                        <Combobox
                            style={{ marginTop: "5px" }}
                            hideCaret
                            hideEmptyPopup
                            data={plzs}
                            defaultValue={state.plz}
                            value={selectedPlz}
                            placeholder={i18n.t('candidate.ZipCode')}
                            // onSelect={(value) => setPlz(value)}
                            // onChange={(value) => getPLZDetail(value)}
                            disabled={state.disable}
                        />
                    </div>
                    <div className="col-sm-3 pr-0 pl-0">
                    <label className={"mb-0"}>{ i18n.t('candidate.city')} </label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder="City"
                            id="cand_city"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_city}
                            autoComplete="new-password"
                            readOnly
                        />
                    </div>
                    <div className="col-sm-3 pl-1">
                    <label className={"mb-0"}>{ i18n.t('candidate.kanton')} </label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder={i18n.t('candidate.kanton')}
                            id="cand_canton"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_canton}
                            autoComplete="new-password"
                            readOnly
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-12">
                    <label className={"mb-0"}>{ i18n.t('candidate.description')} </label>
                        <textarea
                            type="text"
                            className="form-control mt-1"
                            placeholder={ i18n.t('candidate.description')}
                            id="cand_description"
                            disabled={state.disable}
                            value={state.cand_description}
                            rows= "4"
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-12">
                    <label className={"mb-0"}>{ 'AVAM code'} </label>
                        <input
                            type="number"
                            className="form-control mt-1"
                            placeholder="AVAM code"
                            id="job_avamcode"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.job_avamcode}
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-12">
                    <label className={"mb-0"}>{ i18n.t('candidate.drivinglicence')} </label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder={i18n.t('candidate.drivinglicence')}
                            id="cand_drivinglicence"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_drivinglicence}
                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.selectGender')} </label>
                        <select className="form-control mt-1"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_gender} id="cand_gender">
                            <option id="cand_gender" value="">Select gender</option>
                            <option id="cand_gender" value="male">male</option>
                            <option id="cand_gender" value="female">female</option>
                        </select>
                    </div>
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ 'Mobile No.'} </label>
                        <input
                            type="number"
                            className="form-control mt-1"
                            placeholder='Mobile No.'
                            id="cand_mobile"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_mobile}
                        />
                    </div>
                </div>

                <div className="row mx-1">
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.nationality')} </label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder={ i18n.t('candidate.nationality')}
                            id="cand_nationality"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.cand_nationality}

                        />
                    </div>
                    <div className="col-sm-6">
                    <label className={"mb-0"}>{ i18n.t('candidate.industry')} </label>
                        <input
                            type="text"
                            className="form-control mt-1"
                            placeholder={ i18n.t('candidate.industry')}
                            id="industry"
                            // onChange={(e) => inputChange(e)}
                            disabled={state.disable}
                            value={state.industry}

                        />
                    </div>
                </div>
                <div className="row mx-1">
                    <div className="col-sm-12">
                        <label >{"Wanted"}</label>
                        <input
                            type="checkbox"
                            className="mt-2 mx-2"
                            id="job_wanted"
                            // onChange={(e) => inputChange(e)}
                            checked={state.job_wanted}
                        />
                    </div>
                </div>
            </Drawer>
            }
            {
                state.isDialogOpen === true ?
                    <Dialog
                        modal={true}
                        buttons={
                            [
                                {
                                    text: "yes",
                                    className: "firstbutton",
                                    onClick: () => confirm(state.confirmId)
                                },
                                {
                                    text: "Close",
                                    onClick: () => handlePopUp()
                                },
                            ]
                        }>

                        <div className="TextDiv">
                            <p className="textContainer">{'Why do you want to ' + state.message + ' this ' + state.type + ' ?'}</p>
                        </div>
                        <div>
                            <input
                                className="form-control mt-1"
                                id="deleteBlock"
                                placeholder={i18n.t('candidate.reason')}
                                onChange={(e) => inputChange(e)}
                                value={state.reason}
                            />
                        </div>
                    </Dialog>
                    :
                    null
            }
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    {myLeads.length > 0 ? 
                        <TableRow>
                            <TableCell onClick={() => handleSort("FIRST_NAME")}>
                                {i18n.t('candidate.firstName')}
                                <FontAwesomeIcon icon={state.columnToSort ==="FIRST_NAME" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>
                            <TableCell onClick={() => handleSort("LAST_NAME")}>
                                {i18n.t('candidate.LastName')}
                                <FontAwesomeIcon icon={state.columnToSort ==="LAST_NAME" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>
                            <TableCell onClick={() => handleSort("NATIONALITY")}>
                                {i18n.t('candidate.Nationality')}
                                <FontAwesomeIcon icon={state.columnToSort ==="NATIONALITY" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>
                            <TableCell onClick={() => handleSort("CANTON")}>
                                {i18n.t('Company.i18n_district')}
                                <FontAwesomeIcon icon={state.columnToSort ==="CANTON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>
                            <TableCell onClick={() => handleSort("INDUSTRY")}>
                              {i18n.t('candidate.Industry')}
                              <FontAwesomeIcon icon={state.columnToSort ==="INDUSTRY" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>

                            <TableCell onClick={() => handleSort("CREATED_ON")}>
                              {i18n.t('Company.i18n_created_at')}
                              <FontAwesomeIcon icon={state.columnToSort ==="CREATED_ON" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>
                            <TableCell onClick={() => handleSort("DATE_SINCE")}>
                              {i18n.t('candidate.DateSince')}
                              <FontAwesomeIcon icon={state.columnToSort ==="DATE_SINCE" ?(state.sortDirection === "ASC") ? faSortUp : faSortDown :faSort } className="ml-1"/>
                            </TableCell>

                            {isUser.roleId !== UserRole.User && <TableCell>{i18n.t('Company.i18n_action')}</TableCell>}
                        </TableRow>: "" }
                    </TableHead>
                    <TableBody>
                        {myLeads
                            ? myLeads.map((row) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell component="th" scope="row" >
                                        {row.cand_firstname}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.cand_lastname}
                                    </TableCell>
                                    <TableCell>{row.cand_nationality}</TableCell>
                                    <TableCell>{row.cand_canton}</TableCell>
                                    <TableCell>{row.industry}</TableCell>

                                    <TableCell>
                                        {moment(row.updated_on).format("yyyy-MM-DD")}
                                    </TableCell>
                                    <TableCell>{moment(row.date_since).format("yyyy-MM-DD")}</TableCell>

                                    {!isLogedin && (
                                        <TableCell>
                                            {row.is_blocked ? "blockiert" : "aktiv"}
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Button
                                            title={i18n.t('lowercase.intrest')}
                                            className={[classes.button, "Button-edit-bg-Color"]}
                                            onClick={() => toggleDrawer('1', row)}
                                        >
                                            <FontAwesomeIcon className="addToMesize" icon={faStore} />
                                        </Button>


                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            title={i18n.t('lowercase.recall')}
                                            className={[classes.button, "Button-edit-bg-Color"]}
                                            onClick={() => toggleDrawer('2', row)}
                                        >
                                            <FontAwesomeIcon className="addToMesize" icon={faCircleNotch} />
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            title={i18n.t('lowercase.notintrest')}
                                            className={[classes.button, "Button-edit-bg-Color"]}
                                            onClick={() => toggleDrawer('3', row)}
                                        >
                                            <FontAwesomeIcon className="addToMesize" icon={faStoreSlash} />
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            title={i18n.t('myLeads.View')}
                                            className={[classes.button, "Button-edit-bg-Color"]}
                                            onClick={() => seeAssignee(row)}
                                        >
                                            <VisibilityIcon />
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            title={i18n.t('user.i18n_delete')}
                                            className={[classes.button, "Button-delete-bg-Color"]}
                                            onClick={() => deleteAssignee(row.id)}
                                        >
                                            <DeleteForeverIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                            : []}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
