import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./views/Container/Login";
import Layout from "./views/Container/Layout";
import Company from "./views/Container/Company";
import User from "./views/Container/User";
import MyLeads from "./views/Container/MyLeads";
import Candidate from "./views/Container/Candidate"
import "./App.css";
import "react-widgets/styles.css";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                <Layout>
                    <Route path="/company" component={Company} />
                    <Route path="/user" component={User} />
                    <Route path="/candidate" component={Candidate} />
                    <Route path="/my-leads" component={MyLeads} />
                </Layout>
            </Switch>
        </Router>
    );
}

export default App;
