import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { baseURL } from "../../utils/Constants";
import Avatar from "@material-ui/core/Avatar";
import i18n from 'i18next';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
});

export default function UserRecord(props) {
  const { users, editUser, deleteUser, isAdmin } = props;
  const classes = useStyles();
  let isLogedin = !!localStorage.getItem("token");

  return (
    <>
      {users
        ? users.map((user, index) => {
            return (
              <div className="row mt-3" key={index}>
                <div className="col-sm-12">
                  <Card className={classes.root}>
                    <CardContent
                      style={{ paddingBottom: "5px", paddingTop: "5px" }}
                    >
                      <div className="row">
                        <div className="col-sm-2 d-flex justify-content-center">
                          <Avatar
                            src={baseURL + "/" + user.profileImagePath}
                            alt={user.name}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                        <div className="col-sm-10 m-auto">
                          <div className="row">
                            <div className="col-sm-5">
                              <Typography
                                color="textSecondary"
                                variant="h6"
                                component="h6"
                              >
                                <b>{i18n.t('user.i18n_firstname')} </b> {user.name}
                              </Typography>
                            </div>
                            <div className="col-sm-5">
                              <Typography
                                color="textSecondary"
                                variant="h6"
                                component="h6"
                              >
                                <b> {i18n.t('user.i18n_telefon')}</b> {user.phone}
                              </Typography>
                            </div>
                            <div className="col-sm-2">
                              {isAdmin && isLogedin && (
                                <Button
                                  size="small"
                                  className="Button-bg-Color"
                                  onClick={() => editUser(user)}
                                >
                                  {i18n.t('user.i18n_edit')}
                                </Button>
                              )}
                            </div>
                            <div className="col-sm-5">
                              <Typography
                                color="textSecondary"
                                variant="h6"
                                component="h6"
                              >
                                <b>{i18n.t('user.i18n_email')} </b> {user.email}
                              </Typography>
                            </div>
                            <div className="col-sm-5">
                              <Typography
                                color="textSecondary"
                                variant="h6"
                                component="h6"
                              >
                                <b> {i18n.t('user.i18n_created_at')}</b>{" "}
                                {moment(user.createdOn).format("yyyy-MM-DD")}
                              </Typography>
                            </div>
                            <div className="col-sm-2">
                              {isAdmin && isLogedin && (
                                <Button
                                  size="small"
                                  className="Button-delete-bg-Color"
                                  onClick={() => deleteUser(user.id)}
                                >
                                   {i18n.t('user.i18n_delete')}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            );
          })
        : []}
    </>
  );
}
