import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import Header from "../Component/Header";
import { LoadingSpinner } from "../../utils/Loaders";
import Snackbar from "@material-ui/core/Snackbar";
import Axios from "axios";
import { baseURL, httpConfig } from "../../utils/Constants";
import { showSnakerAlert } from "../../utils/Alerts";
import Drawer from 'react-modern-drawer'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { showLoading, hideLoading } from "../../../src/utils/Loaders";
import { withTranslation } from "react-i18next";

class Layout extends Component {
  constructor(props) {
    super(props);
    window.layoutComponent = this;
    this.startTokenValidator();
    this.state = {
      showLoader: false,
      snakerOpen: false,
      snakerMessage: "",
      snakerSuccess: false,
      inputToken: false,
      token: "",
      user_id: null,
    };
    localStorage.setItem("pathName", props.location.pathname);

  }

  validateLogin = () => {
    let isLogedin = !!localStorage.getItem("token");
    if (isLogedin) {
      Axios.get(baseURL + `/api/account/logedin/user`, httpConfig)
        .then((response) => { })
        .catch((error) => {
          if (isLogedin) {
            showSnakerAlert(false, "Login session time out");
            this.signOut();
          }
        });
    }
  };

  handleSnakerOpen = (success, message) => {
    this.setState({
      snakerOpen: true,
      snakerMessage: message,
      snakerSuccess: success,
    });
  };

  signOut = () => {
    debugger;
    for (let key in localStorage) {
      if (key !== "Language") {
        localStorage.removeItem(key);
      }
    }
    window.location.href = "/";
  };

  goto = (url) => {
    const { history } = this.props;
    history.push(url);
    this.resetField();
  };

  TokenDrawer = () => {
    let getIsUser = localStorage.getItem("isUser");
    let isUser = JSON.parse(getIsUser)
    this.setState({ inputToken: true, user_id: isUser.id });
  }

  handleClose = () => {
    this.setState({ inputToken: false });
    this.resetField();
  };
  resetField = () => {
    this.setState({
      token: "",
      inputToken: false
    });
  };

  startTokenValidator = () => {
    setInterval(() => {
      this.validateLogin();
    }, 10000);
  };

  submitToken = () => {
    const { user_id, token } = this.state;
    let payload = { user_id, token };

    showLoading();
    let url = baseURL + "/api/bToken";
    Axios.post(url, payload, httpConfig)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          this.setState({ show: false });
          this.resetField();
        }
        showSnakerAlert(data.success, data.message);
      })
      .catch((err) => {
        showSnakerAlert(false, "Internal Server Error");
      })
      .finally(() => {
        hideLoading();
      });
  };
  inputChange = (e) => {
    const { id, value } = e.target;
    if (id === "name") {
      this.setState({ token: value });
    }
  };
  render() {
    const { i18n } = this.props;
    const useStyles = makeStyles({
      table: {
        minWidth: 650,
      },
    });
    return (
      <>
        <Container>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.snakerOpen}
            onClose={() => this.setState({ snakerOpen: false })}
            autoHideDuration={6000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <div
              className={
                this.state.snakerSuccess
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
              role="alert"
            >
              <p>
                <b>{this.state.snakerMessage}</b>
              </p>
            </div>
          </Snackbar>
          <LoadingSpinner />

          <Header logOut={this.signOut} goto={this.goto} tokenInput={this.TokenDrawer} />
          {this.props.children}
        </Container>

        <Drawer open={this.state.inputToken} size={400} className="drawer" direction='right' enableOverlay={false}>
          <div className="mx-1 ml-2 d-flex justify-content-between mt-5 divHeight" >
            <h5 className="mt-4"> {i18n.t('Bearer.Token')}</h5>
            <FontAwesomeIcon
              icon={faTimes}
              className="mt-4  mr-2 awesomeIconSize"
              onClick={() => this.handleClose()}
            />
          </div>
          <hr className="hrStyle" />

          <div className="row mx-1">
            <input
              type="text"
              className="form-control mt-1 mx-1"
              placeholder={i18n.t('Bearer.BearerToken')}
              id="name"
              onChange={(e) => this.inputChange(e)}
              value={this.state.token}
            />
          </div>
          <div className="row d-flex justify-content-end mx-2">
            <Button
              className={[useStyles.button, "Button-bg-Color", "bottomBtn"]}
              onClick={() => this.submitToken()}
            >
              {i18n.t('Bearer.Submit')}
            </Button>
          </div>
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(withRouter(Layout));
